import React from 'react';

import AnimationAboutNumber from './AnimationAboutNumber';
import AnimationAboutSheet from './AnimationAboutSheet';
import AnimationAboutContainer from './AnimationAboutContainer';
import AnimationAboutHeaderSplit from './AnimationAboutHeaderSplit';
import AnimationAboutHeaderTitle from './AnimationAboutHeaderTitle';
import AnimationAboutHeaderText from './AnimationAboutHeaderText';
import AnimationAboutTile from './AnimationAboutTile';
import AnimationAboutSheetBackground from './AnimationAboutSheetBackground';
import AnimationAboutSlider from './AnimationAboutSlider';
import AnimationAboutSimpleCarousel from './AnimationAboutSimpleCarousel';
import FAQ from './FAQ';

import config from '../../../../config/config.json';

import i18n from '../i18n';

const COMPARE = {
    rohoz: i18n.t('about.section08.rohoz', { returnObjects: true }),
    voda: i18n.t('about.section08.voda', { returnObjects: true }),
    plyn: i18n.t('about.section08.plyn', { returnObjects: true })
};

function AnimationAboutSection8()
{
    return (
        <div className="AnimationAboutSection AnimationAboutSection8">
            <AnimationAboutNumber>08</AnimationAboutNumber>
            <AnimationAboutSheet kartoteka="tmava" theme="dark">
                <AnimationAboutContainer width="header" >
                    <div>
                        <AnimationAboutHeaderTitle>
                            {i18n.t('about.section08.title')}
                        </AnimationAboutHeaderTitle>
                        <AnimationAboutHeaderText>
                            <span className="AnimationAbout--a1">
                                {i18n.t('about.section08.text')}<sup>*</sup>
                            </span>
                        </AnimationAboutHeaderText>
                    </div>
                </AnimationAboutContainer>
            </AnimationAboutSheet>
            <AnimationAboutSheet kartoteka="seda" kartotekaPushed theme="gray--gradient" title={<span className="AnimationAbout--a1"><span className="AnimationAbout--orange">Heated.</span> vs. <span className="AnimationAbout--white">{ i18n.t('comparison.title01') }</span></span>} collapsible initialyCollapsed={true}>
                <AnimationAboutContainer>
                    <div className="AnimationAbout__compare">
                        <div className="AnimationAbout__compare__header">
                            <div className="AnimationAbout--orange">Heated.</div>
                            <div className="AnimationAbout--9a">{i18n.t('general.params')}</div>
                            <div className="AnimationAbout--white">{ i18n.t('comparison.title01') }</div>
                        </div>
                        <div className="AnimationAbout__compare__content">
                            {COMPARE.rohoz.map((line, index) => {
                                return (
                                    <div key={index} className="AnimationAbout__compare__line">
                                        <div>
                                            <div>{line[0][0]}</div>
                                            <div>{line[0][1]}</div>
                                        </div>
                                        <div>
                                            <div>{line[1][0]}</div>
                                            <div>{line[1][1]}</div>
                                        </div>
                                        <div>
                                            <div>{line[2][0]}</div>
                                            <div>{line[2][1]}</div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </AnimationAboutContainer>
            </AnimationAboutSheet>
            <AnimationAboutSheet kartoteka="seda" kartotekaPushed theme="gray--gradient" title={<span className="AnimationAbout--a1"><span className="AnimationAbout--orange">Heated.</span> vs. <span className="AnimationAbout--white">{ i18n.t('comparison.title02Long') }</span></span>} collapsible initialyCollapsed={true}>
                <AnimationAboutContainer>
                    <div className="AnimationAbout__compare">
                        <div className="AnimationAbout__compare__header">
                            <div className="AnimationAbout--orange">Heated.</div>
                            <div className="AnimationAbout--9a">{i18n.t('general.params')}</div>
                            <div className="AnimationAbout--white">{ i18n.t('comparison.title02') }</div>
                        </div>
                        <div className="AnimationAbout__compare__content">
                            {COMPARE.voda.map((line, index) => {
                                return (
                                    <div key={index} className="AnimationAbout__compare__line">
                                        <div>
                                            <div>{line[0][0]}</div>
                                            <div>{line[0][1]}</div>
                                        </div>
                                        <div>
                                            <div>{line[1][0]}</div>
                                            <div>{line[1][1]}</div>
                                        </div>
                                        <div>
                                            <div>{line[2][0]}</div>
                                            <div>{line[2][1]}</div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </AnimationAboutContainer>
            </AnimationAboutSheet>
            <AnimationAboutSheet kartoteka="seda" kartotekaPushed theme="gray--gradient" title={<span className="AnimationAbout--a1"><span className="AnimationAbout--orange">Heated.</span> vs. <span className="AnimationAbout--white">{ i18n.t('comparison.title03Long') }</span></span>} collapsible initialyCollapsed={true}>
                <AnimationAboutContainer>
                    <div className="AnimationAbout__compare">
                        <div className="AnimationAbout__compare__header">
                            <div className="AnimationAbout--orange">Heated.</div>
                            <div className="AnimationAbout--9a">{i18n.t('general.params')}</div>
                            <div className="AnimationAbout--white">{ i18n.t('comparison.title03Tiny') }</div>
                        </div>
                        <div className="AnimationAbout__compare__content">
                            {COMPARE.plyn.map((line, index) => {
                                return (
                                    <div key={index} className="AnimationAbout__compare__line">
                                        <div>
                                            <div>{line[0][0]}</div>
                                            <div>{line[0][1]}</div>
                                        </div>
                                        <div>
                                            <div>{line[1][0]}</div>
                                            <div>{line[1][1]}</div>
                                        </div>
                                        <div>
                                            <div>{line[2][0]}</div>
                                            <div>{line[2][1]}</div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </AnimationAboutContainer>
            </AnimationAboutSheet>
        </div>
    );
}

export default AnimationAboutSection8;
