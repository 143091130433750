import * as React from 'react';
import config from '../../../../config/config.json';
import {Link} from 'react-router-dom';
import {MobileMenu} from '../components/MobileMenu';
import {postOffers, getConfig} from '../api.js';
import Slider, {createSliderWithTooltip} from 'rc-slider';
import '../../../../node_modules/rc-slider/assets/index.css';
import {getPageSEO} from '../functions';
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed';

import i18n from '../i18n';

class MobilePage extends React.Component {
  constructor(props) {
    super(props);

    this.pageSEO = getPageSEO();

    document.title = this.pageSEO.title;
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute('content', this.pageSEO.keywords);
    document
      .querySelector('meta[name="description"]')
      .setAttribute('content', this.pageSEO.description);

    document.querySelector('meta[name=\"title\"]').setAttribute('content', this.pageSEO.metaTitle);

    this.state = {
      show_more: '',
      show_more_button: i18n.t('general.showOtherProps'),
      scroll_display: {display: 'block'},
      slider_value: 120,
      name: '',
      city: '',
      tel: '',
      email: '',
      note: '',
      phase: '',
      sources: '',
      fve: false,
      inteligent_control: false,
      floor_structure: false,
      floor: false,
      submitted: '',
      valid_email: '',
      shift_left: '',
      shift_right: '',
      config_area: 0,
      config_buildings: 0,

      form_response: "",
      sent: false,
      sending: false,
    };
    this.scrollToSection = this.scrollToSection.bind(this);
    this.sliderHandle = this.sliderHandle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.checkNumber = this.checkNumber.bind(this);
    this.posted = this.posted.bind(this);
    this.shiftLeft = this.shiftLeft.bind(this);
    this.showMoreFeatures = this.showMoreFeatures.bind(this);
    this.shiftRight = this.shiftRight.bind(this);
    this.toTop = this.toTop.bind(this);
    this.setConfig = this.setConfig.bind(this);

    this.sectionTop = React.createRef();
    this.section1 = React.createRef();
    this.section2 = React.createRef();
    this.section3 = React.createRef();
    this.section4 = React.createRef();
    this.section5 = React.createRef();
  }

  componentDidMount() {
    // window.screen.orientation.lock("portrait-primary");
    getConfig.request(this.setConfig);
    window.addEventListener('scroll', this.onScroll, {passive: true});
  }

  componentWillUnmount() {
    // window.screen.unlockOrientation();
    window.removeEventListener('scroll', this.onScroll);
  }
  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (target.type == 'select-one' && value == 'Mám projekt') {
      this.setState({
        file_input: {display: 'block'},
      });
    }
    if (target.type == 'select-one' && value != 'Mám projekt') {
      this.setState({
        file_input: {display: 'none'},
      });
    }
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value) && name == 'email') {
      this.setState({valid_email: ''});
    }

    this.setState({
      [name]: value,
    });
  }
  setConfig(response) {
    this.setState({
      config_area: response.data.area_sum,
      config_buildings: response.data.building_sum
    });
  }
  handleSubmit(e) {
    e.preventDefault();

    const data = {
      type: 'Cenová nabídka',
      origin: 'Mobile',
      name: this.state.name,
      city: this.state.city,
      phone_number: this.state.tel,
      email: this.state.email,
      note: this.state.note,
      area: this.state.slider_value,
      stage: this.state.phase,
      intelligent_controll: this.state.inteligent_control,
      fve: this.state.fve,
      floor_structure: this.state.floor_structure,
      sources: this.state.sources,
    };
    const telRegex = i18n.language === 'cs' ? /^[0-9a-zA-Z]{9}$/ : /^[0-9a-zA-Z]{10}$/;
    if (
        !this.state.sent &&
        this.state.name != '' &&
        this.state.city != '' &&
        this.state.tel != '' &&
        this.state.email != '' &&
        telRegex.test(this.state.tel) &&
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email) &&
        this.state.slider_value != ''
        // this.state.phase != ""
    ) {
        this.setState({
            sending: true,
            title: i18n.t('general.sendingForm'),
            form_response: i18n.t('general.sendingForm'),
        });
        smoothScrollIntoView(this.section4.current, {
            behavior: 'smooth',
            block: 'start',
            duration: 1300,
        });
        postOffers.request(data, this.posted);
    } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email)) {
        this.setState({ valid_email: 'not_valid' });
    }
    // else if (this.state.phase == "") {
    //   this.setState({ select_empty: "empty" });
    // }
    else {
        this.setState({ submitted: 'not_valid' });
    }
  }
  posted(response) {
    if (response.status == 200 || response.status == 201) {
      this.setState({
        title: i18n.t('mobilePage.responseTitle'),
        slider_value: 120,
        name: '',
        city: '',
        tel: '',
        email: '',
        note: '',
        phase: '',
        sources: '',
        fve: false,
        inteligent_control: false,
        floor: false,
        floor_structure: false,
        submitted: true,
        form_response: i18n.t('general.takeCareASAP'),
        sent: true,
        sending: false,
      });

      window.dataLayer.push({
        'event': 'lead',
        'formLocation': 'mobile',
      });
    } else if (response.status == 429) {
      this.setState({
        title: i18n.t('general.sendingFailed'),
        sending: false,
      });
    } else {
      this.setState({
        title: i18n.t('general.sendingFailed'),
        sending: false,
      });
    }
    localConsoleLog(response);
  }

  //   setAsyncData(data) {
  //     this.setState({
  //       list: data
  //     });
  //   }

  //   getListItems(data) {
  //     let result = [];

  //     for (let i = 0; i < data.length; i++) {
  //       result.push(<li key={i}>{data[i]}</li>);
  //     }

  //     return result;
  //   }

  scrollToSection(section_mobile_selected) {
    var id_of_section = '';
    // if (section_mobile_selected == "next") {
    //   id_of_section = this.state.next_section;
    // } else {
    // }
    const scrollDuration = 1300;
    if (section_mobile_selected == 'top') {
      smoothScrollIntoView(this.sectionTop.current, {
        behavior: 'smooth',
        block: 'start',
        duration: scrollDuration,
      });
      return;
    }
    id_of_section = section_mobile_selected.substring(
      0,
      section_mobile_selected.length - 5,
    );
    // this.setState({ selected_section: id_of_section });

    if (id_of_section == 'section_mobile_1') {
      smoothScrollIntoView(this.section1.current, {
        behavior: 'smooth',
        block: 'start',
        duration: scrollDuration,
      });
      // this.section1.current.scrollIntoView({
      //   behavior: "smooth",
      //   block: "start"
      // });
    } else if (id_of_section == 'section_mobile_2') {
      smoothScrollIntoView(this.section2.current, {
        behavior: 'smooth',
        block: 'start',
        duration: scrollDuration,
      });
      // this.section2.current.scrollIntoView({
      //   behavior: "smooth",
      //   block: "start"
      // });
    } else if (id_of_section == 'section_mobile_3') {
      smoothScrollIntoView(this.section3.current, {
        behavior: 'smooth',
        block: 'start',
        duration: scrollDuration,
      });
      // this.section3.current.scrollIntoView({
      //   behavior: "smooth",
      //   block: "start"
      // });
    } else if (id_of_section == 'section_mobile_4') {
      smoothScrollIntoView(this.section4.current, {
        behavior: 'smooth',
        block: 'start',
        duration: scrollDuration,
      });
      // this.section4.current.scrollIntoView({
      //   behavior: "smooth",
      //   block: "start"
      // });
    } else {
      smoothScrollIntoView(this.section5.current, {
        behavior: 'smooth',
        block: 'start',
        duration: scrollDuration,
      });
      // this.section5.current.scrollIntoView({
      //   behavior: "smooth",
      //   block: "start"
      // });
    }
  }

  sliderHandle(value) {
    this.setState({slider_value: value});
  }
  shiftLeft() {
    if (this.state.shift_left == '') {
      this.setState({shift_left: 'open-left'});
    } else {
      this.setState({shift_left: ''});
    }
  }
  shiftRight() {
    if (this.state.shift_right == '') {
      this.setState({shift_right: 'open-right'});
    } else {
      this.setState({shift_right: ''});
    }
  }
  showMoreFeatures() {
    // console.log("show more");
    if (this.state.show_more != '') {
      this.setState({
        show_more: '',
        show_more_button: i18n.t('general.showOtherProps'),
      });
    } else {
      this.setState({
        show_more: 'show_more',
        show_more_button: i18n.t('general.hideProps'),
      });
    }
  }
  toTop(e) {
    this.props.onToTopBtnClick();
  }
  checkNumber(e) {
    let key = Number(e.key);
    if (isNaN(key) || e.key === null) {
      e.preventDefault();
    }
  }

  render() {
    // HEAD ENERGY FUSION BACKGROUNDS

    var ef_left_bg_path =
      config.paths.backgrounds + 'img_homepage_background_ef_left.png';
    var ef_left_bg = {
      background: 'url(' + ef_left_bg_path + ')',
      backgroundPosition: '0 0',
      backgroundSize: 'auto',
    };

    var ef_right_bg_path =
      config.paths.backgrounds + 'img_homepage_background_ef_right.png';
    var ef_right_bg = {
      background: 'url(' + ef_right_bg_path + ')',
      backgroundPosition: '100% 0',
      backgroundSize: 'auto',
    };

    var ef_bg = {
      background: 'url(' + ef_left_bg_path + '), url(' + ef_right_bg_path + ')',
      backgroundPosition: '0% 0%, 100% 0%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '65% auto, 60% auto',
    };
    var slider_handle = config.paths.icons + 'ic_handle_orange.png';
    var slider_head = config.paths.icons + 'ic_mobile_slider_bg_active.png';
    var slider_tail = config.paths.icons + 'ic_mobile_slider_bg.png';

    return (
      <div className="mobile-container" ref={this.sectionTop}>
        <MobileMenu onMenuClick={this.scrollToSection} />
        <div className="mobile-top-section" style={{ backgroundImage: `url('/assets/images/backgrounds/img_mobile_background_${i18n.language}.png')` }}>
          <img
            className="mobile-top-bg"
            src={config.paths.backgrounds + 'img_mobile_background2.png'}
            alt=""
          />
          <div className="mobile-top-logo-cont">
            <img
              className="mobile-top-logo"
              src={config.paths.brands + 'logo_ch.svg'}
              alt=""
            />
          </div>
          <div className="mobile-head-text">
            <h1 className="mobile-head-heading"  dangerouslySetInnerHTML={{ __html: i18n.t('home.prezentationTitle') }}></h1>
          </div>
          {/* <img
            className="mobile-head-bg"
            src={config.paths.backgrounds + "img_mobile_background_cs.png"}
            alt=""
          /> */}
        </div>
        <div
          className="mobile-section-two"
          ref={this.section1}
          id="section_mobile_1">
          <div className="mobile-section-content">
            <div className="mobile-section-number-block">
              <div className="mobile-section-line" />
              &nbsp;&nbsp;01
            </div>
            <h1 className="mobile-section-heading" dangerouslySetInnerHTML={{ __html: i18n.t('mobilePage.section01.title') }}></h1>
            <div className="mobile-section-intro" dangerouslySetInnerHTML={{ __html: i18n.t('mobilePage.section01.text') }}></div>
          </div>
        </div>
        <div className="mobile-section-three">
          <div className="mobile-section-content">
            <div className="mobile-feature-container">
              <div className="mobile-feature-block">
                <span className="mobile-feature-number">01</span>
                <div className="mobile-section-number-block">
                  <div className="mobile-section-line" />
                  &nbsp;&nbsp;01
                </div>
                <h3 className="mobile-feature-heading">
                  {i18n.t('mobilePage.section01.title01')}
                </h3>
              </div>
              <img
                className="mobile-feature-img"
                src={config.paths.images + 'img_mobile_feature1.png'}
                alt=""
              />
              <div className="mobile-feature-text">
              {i18n.t('mobilePage.section01.text01')}
              </div>
            </div>

            <div className="mobile-feature-container">
              <div className="mobile-feature-block">
                <span className="mobile-feature-number">02</span>
                <div className="mobile-section-number-block">
                  <div className="mobile-section-line" />
                  &nbsp;&nbsp;02
                </div>
                <h3 className="mobile-feature-heading">
                {i18n.t('mobilePage.section01.title02')}
                </h3>
              </div>
              <img
                className="mobile-feature-img"
                src={config.paths.images + 'img_mobile_feature2.svg'}
                alt=""
              />
            </div>
            <div className="mobile-feature-text">
            {i18n.t('mobilePage.section01.text02')}
            </div>

            <div className="mobile-feature-container">
              <div className="mobile-feature-block">
                <span className="mobile-feature-number">03</span>
                <div className="mobile-section-number-block">
                  <div className="mobile-section-line" />
                  &nbsp;&nbsp;03
                </div>
                <h3 className="mobile-feature-heading">
                {i18n.t('mobilePage.section01.title03')}
                </h3>
              </div>
              <img
                className="mobile-feature-img"
                src={config.paths.images + 'img_mobile_feature3.png'}
                alt=""
              />
            </div>
            <div className="mobile-feature-text">
            {i18n.t('mobilePage.section01.text03')}
            </div>

            <div className="mobile-feature-container">
              <div className="mobile-feature-block">
                <span className="mobile-feature-number">04</span>
                <div className="mobile-section-number-block">
                  <div className="mobile-section-line" />
                  &nbsp;&nbsp;04
                </div>
                <h3 className="mobile-feature-heading">
                  {i18n.t('mobilePage.section01.title04')}
                </h3>
              </div>
              <img
                className="mobile-feature-img"
                src={config.paths.images + 'img_mobile_more_bezporuchove.svg'}
                alt=""
              />
            </div>
            <div className="mobile-feature-text">
            {i18n.t('mobilePage.section01.text04')}
            </div>

            <div
              className={'mobile-features-show-more ' + this.state.show_more}>
              <div className="mobile-feature-container">
                <div className="mobile-feature-block">
                  <span className="mobile-feature-number">05</span>
                  <div className="mobile-section-number-block">
                    <div className="mobile-section-line" />
                    &nbsp;&nbsp;05
                  </div>
                  <h3 className="mobile-feature-heading">
                  {i18n.t('mobilePage.section01.title05')}
                  </h3>
                </div>
              </div>
              <div className="mobile-feature-text">
              {i18n.t('mobilePage.section01.text05')}
              </div>

              <div className="mobile-feature-container">
                <div className="mobile-feature-block">
                  <span className="mobile-feature-number">06</span>
                  <div className="mobile-section-number-block">
                    <div className="mobile-section-line" />
                    &nbsp;&nbsp;06
                  </div>
                  <h3 className="mobile-feature-heading">
                  {i18n.t('mobilePage.section01.title06')}
                  </h3>
                </div>
                {/* <img
                  className="mobile-feature-img"
                  src={config.paths.images + 'img_mobile_feature1.png'}
                  alt=""
                /> */}
              </div>
              <div className="mobile-feature-text">
              {i18n.t('mobilePage.section01.text06')}
            </div>

            <div className="mobile-feature-container">
                <div className="mobile-feature-block">
                  <span className="mobile-feature-number">07</span>
                  <div className="mobile-section-number-block">
                    <div className="mobile-section-line" />
                    &nbsp;&nbsp;07
                  </div>
                  <h3 className="mobile-feature-heading">
                  {i18n.t('mobilePage.section01.title07')}
                  </h3>
                </div>
                {/* <img
                  className="mobile-feature-img"
                  src={config.paths.images + 'img_mobile_feature1.png'}
                  alt=""
                /> */}
              </div>
              <div className="mobile-feature-text">
              {i18n.t('mobilePage.section01.text07')}
              </div>

              <div className="mobile-feature-container">
                <div className="mobile-feature-block">
                  <span className="mobile-feature-number">08</span>
                  <div className="mobile-section-number-block">
                    <div className="mobile-section-line" />
                    &nbsp;&nbsp;08
                  </div>
                  <h3 className="mobile-feature-heading">
                  {i18n.t('mobilePage.section01.title08')}
                  </h3>
                </div>
                {/* <img
                  className="mobile-feature-img"
                  src={config.paths.images + 'img_mobile_feature1.png'}
                  alt=""
                /> */}
              </div>
              <div className="mobile-feature-text">
              {i18n.t('mobilePage.section01.text08')}
              </div>

              <div className="mobile-feature-container">
                <div className="mobile-feature-block">
                  <span className="mobile-feature-number">09</span>
                  <div className="mobile-section-number-block">
                    <div className="mobile-section-line" />
                    &nbsp;&nbsp;09
                  </div>
                  <h3 className="mobile-feature-heading">
                  {i18n.t('mobilePage.section01.title09')}
                  </h3>
                </div>
                {/* <img
                  className="mobile-feature-img"
                  src={config.paths.images + 'img_mobile_feature1.png'}
                  alt=""
                /> */}
              </div>
              <div className="mobile-feature-text">
              {i18n.t('mobilePage.section01.text09')}
              </div>

              <div className="mobile-feature-container">
                <div className="mobile-feature-block">
                  <span className="mobile-feature-number">10</span>
                  <div className="mobile-section-number-block">
                    <div className="mobile-section-line" />
                    &nbsp;&nbsp;10
                  </div>
                  <h3 className="mobile-feature-heading">
                  {i18n.t('mobilePage.section01.title10')}
                  </h3>
                </div>
                {/* <img
                  className="mobile-feature-img"
                  src={config.paths.images + 'img_mobile_feature1.png'}
                  alt=""
                /> */}
              </div>
              <div className="mobile-feature-text">
              {i18n.t('mobilePage.section01.text10')}
              </div>

              <div className="mobile-feature-container">
                <div className="mobile-feature-block">
                  <span className="mobile-feature-number">11</span>
                  <div className="mobile-section-number-block">
                    <div className="mobile-section-line" />
                    &nbsp;&nbsp;11
                  </div>
                  <h3 className="mobile-feature-heading">
                  {i18n.t('mobilePage.section01.title11')}
                  </h3>
                </div>
                {/* <img
                  className="mobile-feature-img"
                  src={config.paths.images + 'img_mobile_feature1.png'}
                  alt=""
                /> */}
              </div>
              <div className="mobile-feature-text">
              {i18n.t('mobilePage.section01.text11')}
              </div>

              <div className="mobile-feature-container">
                <div className="mobile-feature-block">
                  <span className="mobile-feature-number">12</span>
                  <div className="mobile-section-number-block">
                    <div className="mobile-section-line" />
                    &nbsp;&nbsp;12
                  </div>
                  <h3 className="mobile-feature-heading">
                  {i18n.t('mobilePage.section01.title12')}
                  </h3>
                </div>
                {/* <img
                  className="mobile-feature-img"
                  src={config.paths.images + 'img_mobile_feature1.png'}
                  alt=""
                /> */}
              </div>
              <div className="mobile-feature-text">
              {i18n.t('mobilePage.section01.text12')}
              </div>

              <div className="mobile-feature-container">
                <div className="mobile-feature-block">
                  <span className="mobile-feature-number">13</span>
                  <div className="mobile-section-number-block">
                    <div className="mobile-section-line" />
                    &nbsp;&nbsp;14
                  </div>
                  <h3 className="mobile-feature-heading">
                  {i18n.t('mobilePage.section01.title14')}
                  </h3>
                </div>
                {/* <img
                  className="mobile-feature-img"
                  src={config.paths.images + 'img_mobile_feature1.png'}
                  alt=""
                /> */}
              </div>
              <div className="mobile-feature-text">
              {i18n.t('mobilePage.section01.text14')}
              </div>
            </div>


            <div
              className={
                'mobile-features-button ' +
                (this.state.show_more != '' ? 'features-minus' : '')
              }
              onClick={this.showMoreFeatures}>
              {this.state.show_more_button}
              <img
                className="mobile-button-plus"
                src={config.paths.icons + 'ic_mobile_plus.png'}
                alt=""
              />
            </div>
          </div>
        </div>
        <div
          className="mobile-section-four"
          ref={this.section2}
          id="section_mobile_2">
          <img
            className="mobile-section-four-bg"
            src={config.paths.backgrounds + 'img_mobile_dark_bg.png'}
            alt=""
          />
          <div className="mobile-section-content">
            <div className="mobile-section-four-heading">
              <div className="mobile-section-number-block">
                <div className="mobile-section-line" />
                &nbsp;&nbsp;02
              </div>
              <h1 className="mobile-section-heading" dangerouslySetInnerHTML={{ __html: i18n.t('mobilePage.section02.title') }}></h1>
            </div>
            <div className="mobile-section-four-block">
              <div className="mobile-section-number-block">
                <div className="mobile-section-line" />
                &nbsp;&nbsp;01
              </div>
              <h3>
                <b>{i18n.t('mobilePage.section02.title01')}</b>
              </h3>
              <div className="mobile-section-text">{i18n.t('mobilePage.section02.text01')}</div>
            </div>
            <img
              className="mobile-section-four-img"
              src={config.paths.images + `img_mobile_porovnani1_${i18n.language}.png`}
              alt=""
            />
            <div className="mobile-section-four-block" style={{ paddingBottom: '50px' }}>
              <div className="mobile-section-number-block">
                <div className="mobile-section-line" />
                &nbsp;&nbsp;02
              </div>
              <h3 dangerouslySetInnerHTML={{ __html: i18n.t('mobilePage.section02.title02') }}></h3>
              <div className="mobile-section-text">{i18n.t('mobilePage.section02.text02')}</div>
            </div>
            {/* <img
              className="mobile-section-four-img graph"
              src={config.paths.images + `img_mobile_porovnani2_${i18n.language}.png`}
              alt=""
            /> */}
            {/* <div className="mobile-section-four-block">
              <div className="mobile-section-text mobile-section-text-orange" dangerouslySetInnerHTML={{ __html: i18n.t('mobilePage.section02.note') }}></div>
            </div> */}
          </div>
        </div>
        <div
          className="mobile-section-five"
          ref={this.section3}
          id="section_mobile_3">
          <img
            className="mobile-section-five-bg"
            src={config.paths.backgrounds + 'img_mobile_grey_bg.png'}
            alt=""
          />
          <div className="mobile-section-content">
            <div className="mobile-section-five-heading">
              <div className="mobile-section-number-block">
                <div className="mobile-section-line" />
                &nbsp;&nbsp;03
              </div>
              <h1 className="mobile-section-heading" dangerouslySetInnerHTML={{ __html: i18n.t('mobilePage.section03.title') }}></h1>
              <div className="mobile-section-five-motto">
                {i18n.t('mobilePage.section03.text')}
              </div>
            </div>
            <div className="mobile-section-five-body">
              <div className="mobile-section-five-block">
                <img
                  className="mobile-section-five-block-icon"
                  src={config.paths.icons + 'ic_mobile_geometry_orange.png'}
                  alt=""
                />
                <div className="mobile-section-five-block-main">
                  <b>{this.state.config_area}</b> m<sup>2</sup>
                </div>
                <div className="mobile-section-five-block-desc">
                {i18n.t('mobilePage.section03.subtext01')}
                </div>
              </div>
              <div className="mobile-section-five-block">
                <img
                  className="mobile-section-five-block-icon"
                  src={config.paths.icons + 'ic_house.png'}
                  alt=""
                />
                <div className="mobile-section-five-block-main">
                  <b>{this.state.config_buildings}</b>
                </div>
                <div className="mobile-section-five-block-desc">
                {i18n.t('mobilePage.section03.subtext02')}
                </div>
              </div>
              <div className="mobile-section-five-block">
                <img
                  className="mobile-section-five-block-icon"
                  src={config.paths.icons + 'ic_building.png'}
                  alt=""
                />
                <div className="mobile-section-five-block-main">
                  <b>36</b>
                </div>
                <div className="mobile-section-five-block-desc">
                {i18n.t('mobilePage.section03.subtext03')}
                </div>
              </div>
            </div>
            <img
              className="mobile-section-five-bg-bottom"
              src={
                config.paths.backgrounds + 'img_mobile_background_reference.png'
              }
              alt=""
            />
          </div>
        </div>
        <div
          className="mobile-section-six"
          ref={this.section4}
          id="section_mobile_4">
          <img
            className="mobile-section-six-bg"
            src={config.paths.backgrounds + 'img_mobile_dark_bg2.png'}
            alt=""
          />
          <div className="mobile-section-content">
            <div className="mobile-section-six-heading">
              <div className="mobile-section-number-block">
                <div className="mobile-section-line" />
                &nbsp;&nbsp;04
              </div>
              <h1 className="mobile-section-heading" dangerouslySetInnerHTML={{ __html: i18n.t('mobilePage.section04.title') }}></h1>
            </div>
            <div className={"sent-wrapper" + " " + (this.state.sent ? "sent" : "")}>
              <h2 className="section-heading">
                <img
                    className="odeslano-icon"
                    src={config.paths.icons + "ic_checked_orange.png"}
                    alt={i18n.t('general.sended')}
                    style={{marginBottom: "1em"}}
                  />
                <b style={{display: "block"}}>{this.state.title}</b>
              </h2>
              <div className="odeslano-text">
                {this.state.form_response}
              </div>{" "}
            </div>
            {/* <div className={"sent-wrapper" + " " + ((this.state.sending && !this.state.sent) ? "sent" : "")}>
              <h2 className="section-heading">
                <img
                    className={"odeslano-icon" + " sending"}
                    src={config.paths.icons + "ic_blesk_orange.png"}
                    alt="Odesílám"
                    style={{marginBottom: "1em"}}
                  />
                <b style={{display: "block"}}>{this.state.title}</b>
              </h2>{" "}
            </div> */}
            <div className="mobile-section-six-block" style={{display: (this.state.sent || this.state.sending) ? 'none' : 'block'}}>
              <div className="mobile-section-six-text">{i18n.t('mobilePage.section04.text')}</div>
              <div className="mobile-section-six-form-container">
                <form
                  action=""
                  className={
                    'mobile-nabidka ' +
                    this.state.submitted +
                    ' ' +
                    this.state.valid_email
                  }
                  onSubmit={this.handleSubmit}>
                  <div className="form-field">
                    <input
                      type="text"
                      name="name"
                      id="form_name_nabidka"
                      required
                      onChange={this.handleChange}
                      value={this.state.name}
                    />
                    <label htmlFor="form_name_nabidka">
                      {i18n.t('general.fullname')} °
                    </label>
                    <i className="form-field-check"></i>
                  </div>
                  <div className="form-field">
                    <input
                      type="text"
                      name="city"
                      id="form_city_nabidka"
                      required
                      onChange={this.handleChange}
                      value={this.state.city}
                    />
                    <label htmlFor="form_city_nabidka">{i18n.t('general.city')} °</label>
                    <i className="form-field-check"></i>
                  </div>
                  <div className="form-field">
                    <input
                      type="text"
                      name="tel"
                      id="form_tel"
                      pattern={`[0-9a-zA-Z]{${i18n.language === 'cs' ? '9,9' : '10,10'}}`}
                      onKeyPress={this.checkNumber}
                      required
                      onChange={this.handleChange}
                      value={this.state.tel}
                    />
                    <label htmlFor="form_tel">{i18n.t('general.phone')} °</label>
                    <i className="form-field-check"></i>
                  </div>
                  <div className="form-field">
                    <input
                      type="email"
                      name="email"
                      id="form_email_nabidka"
                      className="email"
                      required
                      onChange={this.handleChange}
                      value={this.state.email}
                    />
                    <label htmlFor="form_email_nabidka">email °</label>
                    <i className="form-field-check"></i>
                  </div>

                  <div className="mobile-slider">
                    <div className="mobile-slider-legend-cont">
                      <span className="mobile-form-label">
                        {i18n.t('general.totalArea')} °
                      </span>
                      <span className="mobile-form-area">
                        <b>{this.state.slider_value}</b> m<sup>2</sup>
                      </span>
                    </div>
                    <div className="mobile-slider-cont">
                      <Slider
                        id="slider_7"
                        className="mobile-slider-range"
                        defaultValue={120}
                        min={1}
                        max={500}
                        step={1}
                        onChange={this.sliderHandle}
                        trackStyle={{
                          backgroundImage: "url('" + slider_head + "')",
                          height: 24,
                        }}
                        handleStyle={{
                          borderColor: 'blue',
                          height: 44,
                          width: 26,
                          marginLeft: -9,
                          marginTop: -9,
                          backgroundImage: "url('" + slider_handle + "')",
                        }}
                        railStyle={{
                          backgroundImage: "url('" + slider_tail + "')",
                          height: 24,
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-field">
                    <select
                      name="phase"
                      className={this.state.select_empty}
                      id="form_faze_nabidka"
                      onChange={this.handleChange}
                      value={this.state.phase}>
                      <option value="" defaultValue>
                        {i18n.t('general.projectPhase')}
                      </option>
                      <option value="Nemám projekt">{i18n.t('general.noProject')}</option>
                      <option value="Mám projekt, ale nemám ho u sebe">
                        {i18n.t('general.haveProject')}
                      </option>
                    </select>
                    {/* <input type="text" name="faze" id="form_faze" required />
                    <label htmlFor="form_faze">{i18n.t('general.projectPhase')} °</label> */}
                  </div>
                  <div className="form-field">
                    <span className="form-additional-heading">
                      {i18n.t('general.additionalOptions')}
                    </span>
                    <div className="form-checkbox">
                       <span dangerouslySetInnerHTML={{ __html: i18n.t('general.priceComplete') }}></span>
                      <input
                        type="checkbox"
                        name="inteligent_control"
                        id="ovladani_nabidka"
                        checked={this.state.inteligent_control}
                        onChange={this.handleChange}
                      />
                      <label htmlFor="ovladani_nabidka" />
                    </div>
                    <div className="form-checkbox">
                      <span dangerouslySetInnerHTML={{ __html: i18n.t('general.priceInteligent') }}></span>
                      <input
                        type="checkbox"
                        name="fve"
                        id="solar_nabidka"
                        checked={this.state.fve}
                        onChange={this.handleChange}
                      />
                      <label htmlFor="solar_nabidka" />
                    </div>
                    <div className="form-checkbox">
                      <span dangerouslySetInnerHTML={{ __html: i18n.t('general.priceSolar') }}></span>
                      <input
                        type="checkbox"
                        name="floor_structure"
                        id="skladba_nabidka"
                        checked={this.state.floor_structure}
                        onChange={this.handleChange}
                      />
                      <label htmlFor="skladba_nabidka" />
                    </div>
                  </div>
                  <div className="form-field">
                    <input
                      type="submit"
                      value={i18n.t('general.toSend')}
                      onClick={this.handleSubmit}
                    />
                    {!!(this.state.valid_email || this.state.submitted) && <div className="validation-message">{i18n.t('general.validationMessage')}</div>}
                    <span className="form-submit-note">
                      {i18n.t('general.sandingPrivaciPolicyAgreement')}
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div
          className="mobile-section-seven"
          ref={this.section5}
          id="section_mobile_5">
          <img
            className="mobile-section-seven-bg"
            src={config.paths.backgrounds + 'img_mobile_orange_bg_shape.png'}
            alt=""
          />
          <div className="mobile-section-content">
            <div className="mobile-section-seven-heading">
              <div className="mobile-section-number-block">
                <div className="mobile-section-line" />
                &nbsp;&nbsp;04
              </div>
              <h1 className="mobile-section-heading">
                <b>{i18n.t('contact.title')}</b>
                <br />
                Heated.
              </h1>
            </div>
            <div className="mobile-section-seven-body">
              <div className="mobile-section-seven-block">
                <div className="mobile-kontakty-inside">
                  <div className="mobile-section-number-block">
                    <div className="mobile-section-line" />
                    &nbsp;&nbsp;Ostrava
                  </div>
                  <h3 className="mobile-kontakty-title">{i18n.t('contact.companyHeadQuarter')}</h3>
                  <div className="mobile-kontakty-name">
                  {i18n.t('contact.contact2.title2')}
                  </div>
                  <div className="mobile-kontakty-data">
                    <span className="mobile-kontakty-line">
                      T/&nbsp;&nbsp;&nbsp;&nbsp;
                      {i18n.language === 'cs' ? <a href='tel:+420602702417'><span className="thin">+420</span> 602 702 417</a> : <a href='tel:+420602702417'><span className="thin">+420</span> 602 702 417</a>}
                    </span>
                    <br />
                    <span className="mobile-kontakty-line">
                      E/&nbsp;&nbsp;&nbsp;&nbsp;
                      {i18n.language === 'cs' ? <a href='mailto:info@heated.cz'>info<span className="thin">@heated.cz</span></a> : <a href='mailto:info@heated.cz'>info<span className="thin">@heated.cz</span></a>}
                    </span>
                  </div>
                  <div className="mobile-kontakty-detail">
                    <div className="mobile-kontakty-detail-title">
                      Efektivní dům s.r.o.
                    </div>
                    <div className="mobile-kontakty-flex">
                      <div className="kontakty-left">A/</div>
                      <div className="kontakty-right">
                        {i18n.language === 'cs' ? 
                        <>Hlučínská 1177 <br />702 00 Ostrava</> :
                        <>Černyševského 10 <br/>851 01 Bratislava</>
                        }
                        
                        <br />
                        <br />
                        <span className="thin">{i18n.t('contact.contact2.openingDays')}</span> {i18n.t('contact.contact2.opening')}
                        <br />
                        <br />
                        <br />
                        <span className="thin">{i18n.t('contact.id')}</span>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{i18n.language === 'cs' ? '27855252' : '47247550'}
                        <br />
                        <span className="thin">{i18n.t('contact.vid')}</span>
                        &nbsp;&nbsp;&nbsp;{i18n.language === 'cs' ? 'CZ27855252' : '2023739531'}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mobile-section-block-city">{i18n.language === 'cs' ? 'Ostrava' : 'SR'}</div>
              </div>

              <div className="mobile-section-seven-block">
                <div className="mobile-kontakty-inside block-praha">
                  <div className="mobile-section-number-block">
                    <div className="mobile-section-line" />
                    &nbsp;&nbsp;Praha
                  </div>
                  <div className="mobile-kontakty-name">
                  <h3 className="mobile-kontakty-title">{i18n.t('contact.contact3.subtitle')}</h3>
                  {i18n.t('contact.contact3.title2')}
                  </div>
                  <div className="mobile-kontakty-data">
                    <span className="mobile-kontakty-line">
                      T/&nbsp;&nbsp;&nbsp;&nbsp;
                      <a href="tel:+420773333780"><span className="thin">+420</span> 773 333 780</a>
                    </span>
                    <br />
                    <span className="mobile-kontakty-line">
                      E/&nbsp;&nbsp;&nbsp;&nbsp;
                      <a href="mailto:info@heated.cz">info<span className="thin">@heated.cz</span></a>
                    </span>
                  </div>
                  <div className="mobile-kontakty-detail">
                    <div className="mobile-kontakty-detail-title">
                      Efektivní dům s.r.o.
                    </div>
                    <div className="mobile-kontakty-flex">
                      <div className="kontakty-left">A/</div>
                      <div className="kontakty-right">
                        Křižíkova 703/97D
                        <br />
                        186 00 <span className="thin">Praha 8 - Karlín</span>
                        <br />
                        <br />
                        <span className="thin">{i18n.t('contact.contact3.openingDays')}</span> {i18n.t('contact.contact3.opening')}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mobile-section-block-city">{i18n.language === 'cs' ? 'Praha' : 'CZ'}</div>
              </div>
            </div>
          </div>
          <div className="mobile-section-footer">
            <div className="mobile-section-scroll-top" onClick={this.toTop}>
              <span>{i18n.t('general.backToTop')}</span>
              <img src={config.paths.icons + 'ic_mobile_up.png'} alt="" />
            </div>
            <img
              className="mobile-section-seven-bg-bottom"
              src={
                config.paths.backgrounds + 'img_mobile_background_bottom.jpg'
              }
              alt=""
            />
          </div>
        </div>
      </div>
    );
  }
}

export default MobilePage;
