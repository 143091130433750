export default {
    translation: {
        general: {
            hideCs: 'hidden',
            currency: 'Kč',
            yourMessage: 'Vaše zpráva',
            haveProject: 'Mám projekt',
            haveProjectNoDocumentation: 'Mám projekt, ale podklady nemám u sebe',
            noProject: 'Nemám projekt',
            completeProjectDoc: 'Kompletní projektová dokumentace',
            edit: 'Upravit',
            close: 'Zavřít',
            toHide: 'Skrýt',
            skip: 'Přeskočit',
            showAnotherPros: 'Zobrazit další výhody',
            showMoreParameters: 'Zobrazit další parametry',
            showMore: 'Zobrazit více',
            sending: 'Odesílám...',
            sended: 'Odesláno',
            toSend: 'Odeslat',
            sendingFailed: 'Odeslání se nepovedlo.',
            sendingForm: 'Odesílám formulář',
            backToTop: 'Zpět na úvod',
            back: 'Zpět',
            intro: 'Úvod',
            heatComparison: 'Porovnání vytápění',
            further: 'Dále',
            whatsHeatflow: 'co je',
            interestInOffer: '<span style="font-weight: 300">mám zájem o</span> <br />cenovou nabídku',
            playAgain: 'Přehrát znovu',
            here: 'zde',
            age_0: 'rok',
            age_1: 'roky',
            age_2: 'let',
            remove: 'Odebrat',
            max5files: 'Max. 5 souborů, každý 10MB.',
            params: 'Parametry',
            posts: 'Články',
            service: 'Služba',
            prezentation: 'Prezentace',
            aboutProduct: 'O produktu',
            priceCalc: 'Cenová kalkulace',
            freePriceCalc: 'Cenová kalkulace zdarma',
            freeOffer: 'Cenová nabídka <br />Heated. zdarma',
            freeOfferText: 'Naše specializované projekční oddělení zpracuje cenovou nabídku na míru Vašemu domu včetně odhadu roční spotřeby.',
            moreAboutService: 'Více o službě',
            contractDesc: 'Popis zakázky',
            floorComposition: 'Skladba podlahy',
            hotWaterDistr: 'Teplovodní rozvody',
            realizationDesc: 'Popis realizace',
            monitorConsump: 'Monitorovaná spotřeba',
            compareHeat: 'Porovnání vytápění',
            loadingFiles: 'Nahráváme Vaše podklady.',
            howHeats: 'Jak topí',
            filesTooLarge: 'Vámi přiložené soubory jsou příliš velké.',
            takeCareASAP: 'Vaši žádost vyřídíme v co možná nejkratší době.',
            contactUsViaEmail: 'Prosím, kontaktujte nás pomocí emailu, ktery naleznete v patičce.',
            emailForAnswer: 'E-MAIL PRO ZASLÁNÍ ODPOVĚDI',
            questionSentThankYou: 'Dotaz odeslán, děkujeme!',
            infoModalHeading: 'Oznámení',
            selectionFits: 'Vašemu výběru odpovídá',
            selectionDoesntFit: 'Vašemu výběru neodpovídá',
            sandingPrivaciPolicyAgreement: 'Odesláním souhlasíte se zpracováním osobních údajů.',
            sendingAgree1: 'Odesláním souhlasíte se',
            sendingAgree2: 'zpracováním osobních údajů',
            uploadNote: '<b>přetáhněte váš projekt</b><br />nebo klikněte pro výběr projektu.<br/><div class="legend">.PDF, .JPG, .PNG, .DOC, .XLS, .DWG<br/>Max. 5 souborů, každý 10MB.</div>',
            gdprContent: `
            <p>
                <b>Tímto uděluji</b> společnosti <b>Efektivní dům s.r.o.</b>, IČO: 27855252, se sídlem: Hlučínská 1177, Přívoz, 702 00 Ostrava, zapsaná v obchodním rejstříku u Krajského soudu v Ostravě, sp. zn. C 31815 (dále jen „<b>Správce</b>“) <b>souhlas</b> se zpracováním osobních údajů v rozsahu:
            </p>
            <ul class="gdpr-list dotted">
                <li class="gdpr-list-item">jméno a příjmení a titul</li>
                <li class="gdpr-list-item">město</li>
                <li class="gdpr-list-item">emailová adresa</li>
                <li class="gdpr-list-item">telefonní číslo</li>
            </ul>
            <p>
                (dále jen „<b>Osobní údaje</b>“).
            </p>
            <p>Osobní údaje budou použity za účelem zpracování nabídky produktů dle kritérií zadaných subjektem údajů, ke kontaktování subjektu údajů e-mailem či telefonicky a k dalšímu jednání o produktech a dále k zasílání informací produktů, služeb, novinek a aktualit týkajících se oboru stavebnictví a technologií, tj. newsletterů.</p>
            <p>
                Dále beru na vědomí, že tento souhlas uděluji svobodně a dobrovolně a můžu jej kdykoliv odvolat formou písemné informace zaslané na adresu Správce nebo na e-mailovou adresu <a href="mailto:info@heated.cz">info@heated.cz</a>, a to i před uplynutím doby, na níž jsem souhlas udělil/(a). Odvoláním tohoto souhlasu není dotčena zákonnost zpracování osobních údajů v době před odvoláním souhlasu. Více informací o zpracování mých osobních údajů je uvedeno v informačním
                dokumentu, který je k dispozici na webové adrese www.heated.cz. V případě dotazů týkajících se zpracování mých osobních údajů se mohu obrátit na správce osobních údajů, a to prostřednictvím emailové adresy <a href="mailto:info@heated.cz">info@heated.cz</a>.
            </p>`,
            infoModalContent: `
            <p>
                Během 10 let působení na trhu jsme vlastními silami z produktu Heatflow vybudovali úspěšnou značku, jak ji dnes znáte. Jak jsme již dříve informovali, na základě neshod, jsme se 1.4.2022 rozhodli ukončit spolupráci se slovenským distributorem.
            </p>
            <p>
            Jelikož činíme 70% celosvětového odběru a máme za sebou více než 3000 realizací, nebyl pro nás problém domluvit se přímo s výrobcem v Jižní Korei. To se pochopitelně nelíbí našemu bývalému distributorovi, který skrze naše bývalé zaměstnance šíří pomluvy a lživá tvrzení ve snaze poškodit naši firmu. Na základě nekalého jednání naší konkurence jsme se mimo právní kroky, rozhodli, že nebudeme značku Heatflow dále propagovat a rozvíjet. Uvádíme na trh tento výrobek pod vlastní značkou. Samotná technologie i výrobní proces zůstanou stejné jako Heatflow, již však pod našim vlastním názvem <b>Carbon Heated</b>.
            </p>
            <p>
                Z těchto důvodů jsme od 1.11.22 zastavili veškerou propagaci produktu Heatflow a soustředíme se na produkt Carbon Heated. a projekt <a href="https://muj-ed.cz/" target="_blank">Efektivní dům</a>, kde naleznete přes 70 technologií od více než 20 výrobců, pod jednou střechou.
            </p>
            <p><b>Co tvrdí náš bývalý distributor skrze své prostředníky a jaká je skutečnost:</b></p>
            <p>
                Že prodáváme padělky<br />  
                Jedná se o produkt ze stejné továrny, od stejného výrobce, ze stejné výrobní linky s absolutně stejnými vlastnostmi a stejným výrobním certifikátem.
            </p>
            <p>
                Že jsou naše produkty nebezpečné<br />  
                Náš produkt prošel veškerými testy, navíc jsme si před uvedením na trh nechali v České republice udělat certifikaci zdravotní nezávadnosti.
            </p>
            <p>
                Že nemáme potřebné certifikace<br />  
                Certifikace výrobku jsou dodávány výrobcem (shodný u obou produktů)
            </p>
            <p>
                Že je v našich produktech méně uhlíku a budou méně topit<br />  
               Jelikož se jedná o shodné produkty, je pochopitelně stejná také výhřevnost, typ použitého uhlíku i hmotnost výrobku. Za tímto účelem jsme nechali udělat nezávislé měření ČVUT, které naše tvrzení o shodnosti vlastností potvrzují.
            </p>
            <p>
                Že fólie nemontujeme správně<br />  
                Veškeré montáže provádíme podle platných legislativních norem pro ČR, náš certifikovaný montážní postup vznikl za účasti TIČR.
            </p>
            <p>
                Že jsme dlužníci a krachujeme<br />  
                V roce 2022 naše společnost eviduje rekordní nárůst (300%), blížíme se hranici 100 zaměstnanců a aktuálně nabíráme lidi pro více než 25 pozic.
            </p>
            <p>
                Že jsme se 1.6. přejmenovali, protože máme problémy<br />  
                Přechod na novou identitu jsme plánovali přes 2 roky. Vzhledem k širokému spektru produktů a technologií, které máme aktuálně v nabídce, jsme i s ohledem na působnost výhradně na českém trhu chtěli název a identitu, která náš koncept lépe vyzdvihne. Navíc, mimo název, jsme nezměnili vůbec nic, ponechali jsme vše - adresu, IČO, vlastnickou strukturu i jednatele.
            </p>
            <p>Topná fólie, kterou dodáváme, má naprosto stejné vlastnosti jako výrobek topná fólie Heatflow.<br />
                <ul>
                    <li>Je od stejného výrobce</li>
                    <li>Ze stejné továrny</li>
                    <li>Má stejné certifikace</li>
                    <li>Stejnou hmotnost</li>
                    <li>Stejnou životnost</li>
                    <li>Certifikát zdravotní nezávadnosti</li>
                    <li>Bylo provedeno nezávislé měření ČVUT (které tato fakta potvrzuje)</li>
                </ul>
            </p>
            <p>
                Na veškeré naše realizace (od roku 2012 více jak 3000 realizací) samozřejmě dále držíme záruku a pro naše bývalé současné ani budoucí zákazníky se nic nemění.
            </p>
            <p>
                Tým Carbon Heated.
            </p>
            `,
            fullname: 'Jméno a příjmení',
            city: 'Město',
            phone: 'Telefon',
            phoneShortcut: 'Tel',
            note: 'Poznámka',
            totalArea: 'Celková plocha',
            requiredField: 'Tento údaj je povinný',
            wrongFormate: 'Špatný formát',
            projectPhase: 'Fáze projektu',
            projectPhaseAndDocuments: 'Fáze projektu a podklady',
            additionalOptions: 'Doplňující možnosti',
            priceComplete: 'Chci nacenit <b>kompletní skladbu podlahy</b>',
            priceInteligent: 'Chci nacenit <b>rekuperaci</b>',
            priceSolar: 'Chci nacenit <b>solární elektrárnu (FVE)</b>',
            projectVisual: 'Visualizace projektu',
            customerFeedback: 'Zpětná vazba zákazníka',
            customerFeedbackBr: 'Zpětná vazba <br />zákazníka',
            realization: 'Realizace',
            selectedProjects: 'vybraných projektů',
            floorArea: 'Podl. plocha',
            totalFloorArea: 'Celková podlahová plocha',
            floorIsolation: 'Izolace v podlaze',
            floor: 'podlaha',
            heatLosses: 'Tepelná ztráta',
            costsPerMonth: 'Náklady / Měsíc',
            partOfDelivery: 'Součást naší dodávky',
            partOfDeliveryHeating: 'vytápění',
            moreAboutReference: 'Více o referenci',
            showProject: 'Zobrazit projekt',
            homeCount: 'Počet domů',
            loadMore: 'Načíst více',
            readMore: 'Přečíst více',
            open: 'Otevřít',
            totalRealizeArea: 'Celková zrealizovaná plocha',
            weRecommend: 'Doporučujeme',
            weDontRecommend: 'Nedoporučujeme',
            recommendThick: 'Doporučujeme tloušťku',
            goodIsolationLowPrice: 'Dobré izolační vlastnosti za nízkou cenu',
            greaterDemandHeight: 'Větší nároky na výšku skladby',
            beterIsolationLowerDemand: 'Lepší izolační vlastnosti než EPS, menší nároky na výšku skladby',
            betterIsolationEPS: 'Lepší izolační vlastnosti než bílý EPS',
            mezzanineInsulation: 'Tato varianta je vhodná pouze jako izolace do mezipatra',
            keepsFloorWarmer: 'Déle udrží teplou podlahu',
            lowerHeatCapacity: 'Nižší tepelná jímavost než anhydrit (déle akumuluje teplo)',
            heigherHeatCapacity: 'Vyšší tepelná jímavost než cementový potěr, kratší doba zrání',
            lowerInertiaConcreat: 'Nižší setrvačnost udržení tepla než cementový potěr',
            lowerInertiaFry: 'Nižší setrvačnost udržení tepla než u litých potěrů',
            dryProcess: 'Suchý proces (bez doby zrání), nízké nároky na výšku skladby podlahy',
            heighVariability: 'Vysoká variabilita vzhledu',
            industrialSpaces: 'Hodí se především do industriálních prostor (místnost působí chladně)',
            heigSelectionLowPrice: 'Vysoký výběr, nízká cena',
            lowServiceLife: 'Nízká životnost',
            softLayer: 'Měkká nášlapná vrstva',
            heighSelectionHeighCapacity: 'Vysoký výběr, vysoká jímavost tepla',
            heighPricePavement: 'U kvalitní dlažby vysoká cena, křehkost',
            heatMaterialServiceLife: 'Déle udrží teplou podlahu, velký výběr materiálů, dlouhá životnost',
            lowCapacityHeighPrice: 'Nízká jímavost tepla, u kvalitních materiálů vyšší cena',

            pros: 'Výhody',
            cons: 'Nevýhoda',
            heigherPrice: 'Vyšší cena',

            spreadingLayer: 'Roznášecí vrstva',
            finalLayer: 'finální vrstva',
            conclusion: 'Shrnutí',
            evaluation: 'Vyhodnocení',

            wrongBrowserVideo: 'Váš prohlížeč nepodporuje video formát. Doporučujeme upgradovat na novější prohlížeč.',

            showOtherProps: 'Zobrazit další vlastnosti',
            hideProps: 'skrýt vlastnosti',

            weWillNeed: 'Ještě budeme potřebovat',

            countHouse: 'dům',
            countHouseMultiple: 'domů',
            countNoHouse: 'žádný dům',

            countWoodenHouse: 'dřevostavba',
            countWoodenHouseMultiple: 'dřevostaveb',
            countNoWoodenHouse: 'žádná dřevostavba',

            countBrickHouse: 'zděný dům',
            countBrickHouseMultiple: 'zděných domů',
            countNoBrickHouse: 'žádný zděný dům',

            countReconstruction: 'rekonstrukce',
            countReconstructionMultiple: 'rekonstrukcí',
            countNoReconstruction: 'žádná rekonstrukce',

            validationMessage: 'Vyplňte všechna povinná pole',

            projectWarningCosts: '*Měsíční náklady jsou odvozeny od ceny energií v době realizace',

            regions: {
                title: 'Kraj',
                praha: 'Hlavní město Praha',
                stredocesky: 'Středočeský kraj',
                jihocesky: 'Jihočeský kraj',
                plzensky: 'Plzeňský kraj',
                karlovarsky: 'Karlovarský kraj',
                ustecky: 'Ústecký kraj',
                liberecky: 'Liberecký kraj',
                kralovehradecky: 'Královéhradecký kraj',
                pardubicky: 'Pardubický kraj',
                vysocina: 'Kraj Vysočina',
                jihomoravsky: 'Jihomoravský kraj',
                olomoucky: 'Olomoucký kraj',
                zlinsky: 'Zlínský kraj',
                moravskoslezsky: 'Moravskoslezský kraj',
            }
        },
        home: {
            header: "<b>Za kolik budu topit</b><br />se skladbou Heated.?",
            headerText1: 'Sestavením skladby podlahy zjistíte',
            headerText2: 'pořizovací náklady',
            headerText3: 'a současně Vám vypočítáme',
            headerText4: 'kolik protopíte',
            headerButton: 'Sestavit skladbu',
            turnkeyHouses: 'Domy na <br />klíč',
            turnkeyHousesNoBr: 'Domy na klíč',
            devCompanies: 'Develop. / stav. <br />firmy',
            specialists: 'Specialisté',
            floorIsolation: 'Izolace podlahy',
            floorIsolatonBr: 'izolace <br /> podlahy',
            startPrezentation: 'Spustit prezentaci',
            prezentationTitle: `<b>Za 10 let působení</b>
                <br />
                přes 3000 realizací vytápění`,
            skipVideo: 'Přeskočit video',
            configurator: 'Konfigurátor',
            materialSelection: 'Volba materiálu',
            whitePolystyrene: 'Bílý polystyrén',
            grayPolystyrene: 'Šedý polystyrén',
            cementScreed: 'Cementový<br /> potěr',
            pir: 'pir',
            wool: 'Vata',
            anhydrit: 'Anhydrit',
            gypsumFiberBoards: 'Sádrovláknité<br />desky',
            polishedConcrete: 'Leštěný<br />beton',
            laminatedFloor: 'Laminátová<br />podlaha',
            thickness: 'Tloušťka',
            pvc: 'PVC',
            pavement: 'Dlažba',
            solidWood: 'Dřevo masiv',
            cementScreedNoBr: 'Cementový potěr',
            gypsumFiberBoardsNoBr: 'Sádrovláknité desky',
            polishedConcreteNoBr: 'Leštěný beton',
            laminatedFloorNoBr: 'Laminátová podlaha',
            isolationFloorLayer: 'Izolační vrstva podlahy',
            totalAreaBuilding: 'Celková plocha vaší stavby',
            heightOfLayers: 'Výška vaší skladby',
            floorSpreadingLayer: 'Roznášecí vrstva podlahy',
            floorFinalLayer: 'Finální vrstva podlahy',
            comparison: {
                breadcrumb: 'Srovnání vytápění',
                title: '<b>porovnání Heated.</b> <br />s jinými systémy',
                item1: 'vyšší rychlost <br />nátopu',
                item2: 'topná <br />plocha',
                item3: 'náklady na <br />servis',
            },
            energyFusion: {
                breadcrumb: 'Efektivní Dům',
                title: '<b>inteligentní</b><br /> řešení',
                text: 'Podívejte se také na naše další produkty v podobě automatizace domácnosti a fotovoltaiku s bateriovým úložištěm',
                label: 'Nastavte plochu vašeho domu',
                avgSavings: 'průměrná <b>roční úspora</b>',
                box1: {
                    title: 'fotovoltaika <br />s úložištěm',
                    text: 'Spojením nejkvalitnějších komponentů technologie fotovoltaické elektrárny, bateriového úložiště a jejich regulace vznikl systém, který dokáže maximálně využívat veškerou vyrobenou energii.'
                },
                box2: {
                    title: 'automatizace <br /> domácnosti',
                    text: 'Loxone usnadňuje ovládání a inteligentní automatizaci projektů všeho druhu: od malých systémů pro chytré domácnosti (smart home) až po rozsáhlé komerční nemovitosti.'
                },
            }
        },
        about: {
            title: 'Co je Heated.',
            title2: 'O společnosti',
            header: {
                title: 'O produktu',
                itemTitle1: 'Pokrytí topného prvku',
                itemTitle2: 'Náklady',
                itemTitle3: 'Počet realizací',
                itemText1: 'užitné plochy podlahy',
                itemText2: 'na servis a údržbu',
                itemText3: 'od roku 2014',
                itemValue1: 'až 90%',
                itemValue2: '0 Kč'
            },
            section01: {
                title: 'Nadstandardní komfort',
                text: '<span class="AnimationAbout--orange">Využitím technologie Heated.</span><span class="AnimationAbout--a1"> se celá podlaha Vašeho domu promění v ideální zdroj tepla.</span>',
                chartDesc: 'Srovnání délky nátopu o 1°C',
                valueTitle1: 'Topné rohože',
                valueTitle2: 'Teplovodní vytápění',
                box1: {
                    title: 'Rovnoměrný zdroj tepla',
                    text: 'Plocha topného systému je určujícím faktorem efektivity vytápěním v novostavbách. Heated. využívá celoplošnou technologii a topný prvek tak pokrývá až 90% podlahové plochy.',
                    note: 'Srovnání pokrytí podlahy topnou plochou systému',
                },
                box2: {
                    title: 'Rychlá změna teplot',
                    text: 'Uhlíková technologie Heated. dokáže okamžitě zvýšit teplotu svého povrchu a nahřeje tak skladbu podlahy, která sálá teplo dál do místnosti.',
                    note: 'Srovnání rychlosti nahřátí interiéru <strong class="AnimationAbout--black">z 19°C na 23°C',
                },
                box3: {
                    title: 'Ideální klima',
                    text: 'Technologie rovnoměrné distribuce tepla v celém prostoru vytváří v novostavbách dokonalé teplotní klima',
                    note: 'Odchylka měřených teplot při regulaci systémů',
                }
            },
            section02: {
                title: 'Zapomeňte na topení',
                text: 'Topná sezóna trvá 270 dní v roce. To znamená, že většinu doby využíváme vytápění, které přímo ovlivňuje vnitřní klima ve vašem domě.',
                chartTitle: 'Srovnání kolísání teplot v průběhu dne (regulace nastavena na 23°C)',
                chartNote: 'Orientační hodnoty ze vzorových domů',
                subsection01: {
                    title: 'Jak funguje Heated.',
                    box01: {
                        title: 'Okamžité zvýšení povrchové teploty',
                        text: 'Systém Heated. okamžitě zvyšuje svou teplotu v celé své ploše, která kopíruje užitnou plochu podlahy.',
                        note: 'Teplota podlahy s Heated.',
                    },
                    box02: {
                        title: 'Akumulace podlahové vrstvy',
                        text: 'Rovnoměrně prohřátá podlahová vrstva je komfortním zdrojem sálání tepla do prostoru.',
                        note: 'Teplota podlahy s Heated.',
                    },
                    box03: {
                        title: 'Rovnoměrná teplota v prostoru',
                        text: 'Celý prostor si udržuje stálou teplotu a ideálně tak ohřívá vzduch',
                        note: 'Teplota vzduchu s Heated.',
                    },
                    box04: {
                        title: 'Příjemné vnitřní klima',
                        text: 'Rovnoměrně ohřátý vzduch a stálé ideální teplota podlahy tvoří příjemné vnitřní klima. Bez přetápění vzduchu, bez kolísání teplot.',
                        note: 'Srovnání rozmezí teplot s Heated.',
                    },
                },
                subsection02: {
                    title: 'Porovnání přenosu tepla u běžných systémů',
                    valueTitle1: 'Teplota podlahy',
                    valueTitle2: 'Teplota vzduchu',
                    box01: {
                        title: 'Teplovodní vytápění',
                        text: 'Teplovodní soustava je tvořena sítí vodních trubek s malou topnou plochou a dlouhým oběhem. Distribuce tepla do prostoru trpí nežádoucí setrvačností a není rovnoměrná, což zejména u novostaveb způsobuje kolísání teplot podlahy i vzduchu.',
                        note: 'Srovnání rozmezí teplot s teplovodním systémem',
                    },
                    box02: {
                        title: 'Topné rohože',
                        text: 'Topný prvek má velmi malou plochu o minimálním průměru s vysokou povrchovou teplotou, což může mít za následek degradaci materiálů podlahové skladby. Podlaha je nárazově přehřívána nebo naopak trpí nedostatečnou výhřevností.',
                        note: 'Srovnání rozmezí teplot s elektrickou rohoží',
                    },
                },
                subsection03: {
                    title: 'Srovnání nátopu a udržení teploty v průběhu 24 hodin',
                    text1: 'Maximální topná plocha, okamžitá reakce a přesná regulace tvoří společně systém, který ideálně reaguje na potřeby domu a udržuje stálou ideální teplotu prostoru bez zásahu uživatele.',
                    text2: 'Teplovodní systém složitě obíhá skrze celý dům s rozdílnými teplotami na vstupu a výstupu, což ma v nízkoenergetickém prostoru za následek kolísání teplot podlahy i vzduchu',
                    text3: 'Topný prvek v podobě odporového vodiče o velmi tenkém průměru. Dosahuje velmi vysokých povrchových teplot a krátkodobě přetápí podlahovou skladbu. Vlivem své malé topné plochy dochází ke složité regulaci.',
                    valueTitle1: 'Udržení teploty',
                    valueTitle2: 'Kolísání teploty',
                }
            },
            section03: {
                title: 'Optimální vliv na okolní materiály',
                text: 'Vlastnosti topného systému zásadně ovlivňují životnost a případnou degradaci roznášecí vrstvy i finálních krytin.',
                months: 'měs.',
                subsection01: {
                    title: 'Vliv na skladbu podlahy',
                    text: 'Pro ideální životnost jednotlivých částí podlahové skladby je vyžadován šetrný provoz bez zásadních teplotních výkyvů. Lité potěry i naprostá většina typů finálních krytin mají stanovené maximální limity teplotního zatížení. V případě jejich porušování pak postupně dochází k degradaci vlastností, přenosu tepla a zejména vzhledu.'
                },
                subsection02: {
                    title: 'Provozní teploty podlahových systémů',
                    text: 'Vlastnosti systému Heated. jsou optimální pro lité potěry i naprostou většinu finálních krytin. Vlivem maximální topné plochy systém pracuje s nižší provozní povrchovou teplotou.'
                },
                subsection03: {
                    title: 'Pozitivní vliv na konstrukci domu',
                    text: 'Ve většině novostaveb dochází k mokrým procesům, které způsobí navlhnutí konstrukce. Vlivem extrémních izolačních vlastností stavby pak dochází k velmi složitému zbavování se vnitřní vlhkosti, což přináší řadu nevýhod. Systém Heated. má díky rovnoměrné akumulaci pozitivní vliv na zásadní zkrácení procesu vysoušení.',
                    note: 'Jak dlouho trvá proces odvlhčení konstrukce budovy?',
                    valueTitle1: 'Elektrické rohože',
                    valueTitle2: 'Teplovodní vytápění',
                },
            },
            section04: {
                title: 'Nároky technologie',
                text: 'Systém Heated. neobsahuje žádné mechanické komponenty vyžadující údržbu, a neomezuje dispozice vaší stavby technickou místností.',
                subsection01: {
                    title: 'Bezúdržbová technologie',
                    text: 'Systém neobsahuje žádné mechanické součástky. Díky tomu nevyžaduje žádný servis ani údržbu. Systém Heated. se skládá z uhlíkového topného prvku a rozvodu napájení.'
                },
                subsection02: {
                    title: 'Servisní náklady',
                    titleNote: 'v horizontu 15 let',
                    options: ['Heated.', 'Teplovodní vytápění', 'Elektrické rohože'],
                    label: 'Pravidelná roční údržba',
                    box1: {
                        text: 'Se systémem Heated. nejsou žádné náklady na servis ani údržbu. Nečekají vás tedy žádné další nečekané výdaje.',
                        column1: {
                            title: '',
                            value: '0 Kč',
                            note: 'Systém je zároveň zdrojem tepla a nemá externí zdroj vyžadující pravidelnou údržbu.',
                            subtitle: ''
                        },
                        column2: {
                            label: 'Průměrné roční náklady na servis',
                            title: '',
                            value: '0 Kč',
                            note: '',
                            subtitle: ''
                        },
                    },
                    box2: {
                        text: 'Náklady na pravidelnou údržbu zdroje, náklady na čištění rozvodů a velmi vysoká pravděpodobnost neočekávaných nákladů v podobě repasování zdroje.',
                        column1: {
                            title: 'Tep. rozvody + plynový kotel',
                            value: '2 000 Kč',
                            note: 'Servisní náklady / výměna komponentů',
                            subtitle: 'Výměna kotle'
                        },
                        column2: {
                            label: 'Pravidelná roční údržba',
                            title: 'Tep. rozvody + tepelné čerpadlo',
                            value: '3 000 Kč',
                            note: 'Servisní náklady / výměna komponentů',
                            subtitle: 'Výměna kompresoru'
                        },
                    },
                    box3: {
                        text: 'Velmi vysoká pravděpodobnost degradace finálních krytin násobně zkracuje jejich životnost. ',
                        column1: {
                            title: '',
                            value: '0 Kč',
                            note: 'Systém je zároveň zdrojem tepla a nemá externí zdroj vyžadující údržbu.',
                            subtitle: ''
                        },
                        column2: {
                            label: 'Průměrné roční náklady na servis',
                            title: '',
                            value: '0 Kč*',
                            note: '*Riziko vzniku dalších nákladů vlivem degradace skladby podlahy teplotními rozdíly.',
                            subtitle: ''
                        },
                    },
                },
                subsection03: {
                    title: 'Prostor pro život',
                    text: 'Bez nároků na technickou místnost. Průměrná cena zastavěné plochy je +35 000 Kč. Průměrná velikost technické místnosti je +4 m2. Jedná se tedy o průměrnou úsporu +150 000 Kč, která se dá využít lépe než jako zázemí pro zdroj tepla.'
                },
            },
            section05: {
                title: 'Zpětná vazba klientů',
                text1: 'Vybrané',
                text2: 's vytápěním.',
                linkText: 'zkušenosti našich zákazníků',
                sideNote: 'Realizací vytápění',
                showMore: 'Zobrazit reference',
            },
            section06: {
                title: 'Časté dotazy',
                text: 'Výběr nejčastěji kladených otázek k systému Heated.',
            },
            section07: {
                text: 'Nahlédněte do procesu rozhodování rodiny, která si prošla tím, čím si pravděpodobně právě procházíte i Vy. Od počátečních informací až po výběr dodavatele.',
            },
            section08: {
                title: 'Porovnání systémů vytápění',
                text: 'Srovnání vlastností Heated. s běžnými systémy vytápění.',
                rohoz: [
                    [
                        ['60-90%', 'Vysoké pokrytí užitné plochy podlahy'],
                        ['Pokrytí podlahy', 'Plocha podlahy pokrytá topným prvkem systému'],
                        ['6-12%', 'Malé pokrytí užitné plochy podlahy'],
                    ],
                    [
                        ['10 MIN', 'Roznášecí vrstva je zahřívána rychle a plošně'],
                        ['Rychlost nátopu (1°C)', 'Čas za jaký se ohřeje vzduch v místnosti o 1°C'],
                        ['30 MIN', 'Pomalý nátop způsoben malým pokrytím topného prvku'],
                    ],
                    [
                        ['NE', 'Neobsahuje součástky náchylné k poškození'],
                        ['Poruchové součástky', 'Součástky náchylné k poškození, vyžadující údržbu a servis'],
                        ['NE', 'Neobsahuje součástky náchylné k poškození'],
                    ],
                    [
                        ['13 000 Kč', 'Výkon systému není nijak ovlivněn vekovní teplotou'],
                        ['Provozní náklady', 'Kalkulace pro stavbu 140 m2 s tepelnou ztrátou 5,6 kW na 15 let'],
                        ['22 000 Kč', 'Počítáno s produktem běžné kvality'],
                    ],
                    [
                        ['0 Kč / rok', 'Jedná se o bezúdržbový systém'],
                        ['Náklady na údržbu', 'Způsobené opotřebením mechanických součástek a dalších komponentů'],
                        ['0 Kč / rok', 'Jedná se o bezúdržbový systém'],
                    ],
                    [
                        ['0 Kč / rok', 'Jedná se o systém s životností přes 30 let bez ztráty účinnosti'],
                        ['Náklady na servis', 'roční průměr za servis, repas a výměny v horizontu 15 let'],
                        ['0 Kč / rok', 'Samotný systém není náchylný na poruchy'],
                    ],
                    [
                        ['NE', 'Bez zásahu do fasády'],
                        ['Zásah do fasády', 'narušení vzhledu stavby'],
                        ['NE', 'Bez zásahu do fasády'],
                    ],
                    [
                        ['NE', 'Možnost jiného využití prostoru - např. druhá koupelna'],
                        ['Technická místnost / omezení dispozic', 'Požadavek technologie na technickou místnost omezující využitelný prostor v interiéru'],
                        ['NE', 'systém bez technické místnosti'],
                    ],
                    [
                        ['NE', 'Topný prvek nepřesahuje teploty poškozující vrstvy podlahy'],
                        ['Přehřívání podlahy', 'Zahřívání roznášecí a finální vrstvy podlahy nad teploty povolené výrobci'],
                        ['ANO', 'Topný prvek přesahuje teploty povolené výrobci vrstev podlahy'],
                    ],
                    [
                        ['NE', 'Topný prvek nepřesahuje teploty poškozující vrstvy podlahy'],
                        ['Degradace vrstev podlahy', 'Poškozování roznášecí a finální vrstvy podlahy vlivem zahřívání nad výrobci povolené teploty'],
                        ['ANO', 'Topný prvek přesahuje teploty povolené výrobci vrstev podlahy'],
                    ],
                    [
                        ['NE', 'Rovnoměrné prohřátí konstrukce vlivem celoplošnosti a vlnové délky'],
                        ['Rozdílné teploty v konstrukci stavby', 'Rozdíl teplot v konstrukci vede k vlhnutí a znamená riziko degradace stavby'],
                        ['ANO', 'Systém neprohřívá rovnoměrně celou konstrukci'],
                    ],
                    [
                        ['NE', 'Systém předchází vlhnutí rovnoměrným nahřátím konstrukce'],
                        ['Vlhkost v konstrukci', 'Vznik vlhkosti vedoucí k degradaci konstrukce, vzniku bakterií a plísní'],
                        ['ANO', 'Nerovnoměrné prohřátí vede k vlhnutí konstrukce'],
                    ],
                    [
                        ['NE', 'Konstantní teplota vlivem celoplošnosti a umístěním senzorů'],
                        ['Rozdíl teplot v interiéru', 'Rozdílné teploty podlah a vzduchu'],
                        ['ANO', 'Intervaly přehřívání a chladnutí'],
                    ],
                    [
                        ['NE', 'Vlivem velkého pokrytí podlahy nedochází k rozdílům teplot'],
                        ['Rozdíl teplot na podlaze', 'Teplá a chladná místa na podlaze'],
                        ['ANO', 'Vlivem nízkého pokrytí podlahy'],
                    ],
                    [
                        ['NE', ''],
                        ['Hlučnost systému', 'Systém se zdrojem zvuku'],
                        ['NE', ''],
                    ],
                    [
                        ['NE', 'Systém nepřehřívá podlahu a předchází vysoušení vzduchu'],
                        ['Vysoušení vzduchu', 'Vysoušení vzduchu je způsobené přehřátím podlahy'],
                        ['ANO', 'Systém přehřívá podlahu a vysušuje tak vzduch'],
                    ],
                    [
                        ['ANO', 'Systém nepřehřívá podlahu a předchází víření prachu'],
                        ['Vhodnost pro alergiky (vířivost prachu)', 'Vliv technologie na vířivost prachu a následná dráždivost pro alergiky'],
                        ['NE', 'Systém přehřívá podlahu za účelem nahřátí vzduchu, a způsobuje tak víření prachu'],
                    ],
                    [
                        ['NE', 'Vlivem rovnoměrného prohřátí konstrukce zamezuje vzniku bakterií a plísní'],
                        ['Vznik bakterií a plísní', 'Vznik bakterií a plísní vlivem vlhkosti v konstrukci'],
                        ['ANO', 'Vlivem nerovnoměrného prohřátí konstrukce a vlhnutím'],
                    ],
                    [
                        ['ANO', 'Bez komplikací'],
                        ['Možnost inteligentní regulace', 'Možnost napojení na systém smart home'],
                        ['ANO', 'Bez komplikací'],
                    ],
                    [
                        ['ANO', 'Bez komplikací'],
                        ['Možnost regulovatelných okruhů', 'Možnost nastavení samostatně regulovatelných okruhů'],
                        ['ANO', 'Bez komplikací'],
                    ],
                    [
                        ['ANO', 'Moderní technologie připravená pro využití s FVE'],
                        ['Možnost napojení FVE', 'Propojení technologe s fotovoltaickou elektrárnou (FVE)'],
                        ['ANO', 'V závislosti na provedení a propojení dodavatelů rohoží a FVE je systém schopný využít energii z vlastní výroby'],
                    ],
                    [
                        ['ANO', 'Bez komplikací'],
                        ['Možnost stropního vytápění', 'Možnost instalace technologie jako stropního vytápění'],
                        ['NE', 'Není možné'],
                    ],
                    [
                        ['NE', 'Systém sám vodu neohřívá Ve vývoji'],
                        ['Ohřev teplé vody', 'Schopnost systému ohřívat teplou vodu pro domácnost'],
                        ['NE', 'Systém sám vodu neohřívá'],
                    ],
                ],
                voda: [
                    [
                        ['60-90%', 'Vysoké pokrytí užitné plochy podlahy'],
                        ['Pokrytí podlahy', 'Plocha podlahy pokrytá topným prvkem systému'],
                        ['10-16%', 'Malé pokrytí užitné plochy podlahy'],
                    ],
                    [
                        ['10 MIN', 'Roznášecí vrstva je zahřívána rychle a plošně'],
                        ['Rychlost nátopu (1°C)', 'Čas za jaký se ohřeje vzduch v místnosti o 1°C'],
                        ['75 MIN', 'Pomalý nátop způsoben malým pokrytím topného prvku'],
                    ],
                    [
                        ['NE', 'Neobsahuje součástky náchylné k poškození'],
                        ['Poruchové součástky', 'Součástky náchylné k poškození, vyžadující údržbu a servis'],
                        ['ANO', 'Obsahuje součástky náchylné k poškození'],
                    ],
                    [
                        ['13 000 Kč', 'Výkon systému není nijak ovlivněn vekovní teplotou'],
                        ['Provozní náklady', 'Kalkulace pro stavbu 140 m2 s tepelnou ztrátou 5,6 kW na 15 let'],
                        ['10 000 Kč', '(vzduch-voda), počítáno s teplotami pod bodem mrazu'],
                    ],
                    [
                        ['0 Kč / rok', 'Jedná se o bezúdržbový systém'],
                        ['Náklady na údržbu', 'Způsobené opotřebením mechanických součástek a dalších komponentů'],
                        ['1 200 Kč / rok', 'Průměrné roční náklady'],
                    ],
                    [
                        ['0 Kč / rok', 'Jedná se o systém s životností přes 30 let bez ztráty účinnosti'],
                        ['Náklady na servis', 'roční průměr za servis, repas a výměny v horizontu 15 let'],
                        ['5100 Kč / rok', 'Průměrné roční náklady'],
                    ],
                    [
                        ['NE', 'Bez zásahu do fasády'],
                        ['Zásah do fasády', 'narušení vzhledu stavby'],
                        ['ANO', 'Venkovní jednotka narušuje fasádu a může být zdrojem hluku'],
                    ],
                    [
                        ['NE', 'Možnost jiného využití prostoru - např. druhá koupelna'],
                        ['Technická místnost / omezení dispozic', 'Požadavek technologie na technickou místnost omezující využitelný prostor v interiéru'],
                        ['ANO', 'ano, systém vyžaduje technickou místnost'],
                    ],
                    [
                        ['NE', 'Topný prvek nepřesahuje teploty poškozující vrstvy podlahy'],
                        ['Přehřívání podlahy', 'Zahřívání roznášecí a finální vrstvy podlahy nad teploty povolené výrobci'],
                        ['NE', 'Topný prvek nepřesahuje teploty poškozující vrstvy podlahy'],
                    ],
                    [
                        ['NE', 'Topný prvek nepřesahuje teploty poškozující vrstvy podlahy'],
                        ['Degradace vrstev podlahy', 'Poškozování roznášecí a finální vrstvy podlahy vlivem zahřívání nad výrobci povolené teploty'],
                        ['NE', 'Topný prvek nepřesahuje teploty poškozující vrstvy podlahy'],
                    ],
                    [
                        ['NE', 'Rovnoměrné prohřátí konstrukce vlivem celoplošnosti a vlnové délky'],
                        ['Rozdílné teploty v konstrukci stavby', 'Rozdíl teplot v konstrukci vede k vlhnutí a znamená riziko degradace stavby'],
                        ['ANO', 'Systém neprohřívá rovnoměrně celou konstrukci'],
                    ],
                    [
                        ['NE', 'Systém předchází vlhnutí rovnoměrným nahřátím konstrukce'],
                        ['Vlhkost v konstrukci', 'Vznik vlhkosti vedoucí k degradaci konstrukce, vzniku bakterií a plísní'],
                        ['ANO', 'Nerovnoměrné prohřátí vede k vlhnutí konstrukce'],
                    ],
                    [
                        ['NE', 'Konstantní teplota vlivem celoplošnosti a umístěním senzorů'],
                        ['Rozdíl teplot v interiéru', 'Rozdílné teploty podlah a vzduchu'],
                        ['ANO', 'Požadovaná teplota vzduchu a podlahy pouze 4 z 24 hodin'],
                    ],
                    [
                        ['NE', 'Vlivem velkého pokrytí podlahy nedochází k rozdílům teplot'],
                        ['Rozdíl teplot na podlaze', 'Teplá a chladná místa na podlaze'],
                        ['ANO', 'Rozdílné teploty na podlaze vlivem malého pokrytí'],
                    ],
                    [
                        ['NE', ''],
                        ['Hlučnost systému', 'Systém se zdrojem zvuku'],
                        ['ANO', ''],
                    ],
                    [
                        ['NE', 'Systém nepřehřívá podlahu a předchází vysoušení vzduchu'],
                        ['Vysoušení vzduchu', 'Vysoušení vzduchu je způsobené přehřátím podlahy'],
                        ['ANO', 'Systém přehřívá podlahu a vysušuje tak vzduch'],
                    ],
                    [
                        ['ANO', 'Systém nepřehřívá podlahu a předchází víření prachu'],
                        ['Vhodnost pro alergiky (vířivost prachu)', 'Vliv technologie na vířivost prachu a následná dráždivost pro alergiky'],
                        ['NE', 'Systém přehřívá podlahu za účelem nahřátí vzduchu, a způsobuje tak víření prachu'],
                    ],
                    [
                        ['NE', 'Vlivem rovnoměrného prohřátí konstrukce zamezuje vzniku bakterií a plísní'],
                        ['Vznik bakterií a plísní', 'Vznik bakterií a plísní vlivem vlhkosti v konstrukci'],
                        ['ANO', 'Vlivem nerovnoměrného prohřátí konstrukce a vlhnutím'],
                    ],
                    [
                        ['ANO', 'Bez komplikací'],
                        ['Možnost inteligentní regulace', 'Možnost napojení na systém smart home'],
                        ['ANO', 'Pro plnohodnotné využití zvyšuje vstupní investici smart home i rozvodů'],
                    ],
                    [
                        ['ANO', 'Bez komplikací'],
                        ['Možnost regulovatelných okruhů', 'Možnost nastavení samostatně regulovatelných okruhů'],
                        ['ANO', 'Složité. Spojené s výrazným nárůstem vstupních investic na rozvody hadice'],
                    ],
                    [
                        ['ANO', 'Moderní technologie připravená pro využití s FVE'],
                        ['Možnost napojení FVE', 'Propojení technologe s fotovoltaickou elektrárnou (FVE)'],
                        ['KOMPLIKOVANÉ', 'Náročné napojení'],
                    ],
                    [
                        ['ANO', 'Bez komplikací'],
                        ['Možnost stropního vytápění', 'Možnost instalace technologie jako stropního vytápění'],
                        ['NE', 'Není možné'],
                    ],
                    [
                        ['NE', 'Systém sám vodu neohřívá Ve vývoji'],
                        ['Ohřev teplé vody', 'Schopnost systému ohřívat teplou vodu pro domácnost'],
                        ['ANO', 'Zajišťuje ohřev. V případě poruchy systému je domácnost bez teplé vody'],
                    ],
                ],
                plyn: [
                    [
                        ['60-90%', 'Vysoké pokrytí užitné plochy podlahy'],
                        ['Pokrytí podlahy', 'Plocha podlahy pokrytá topným prvkem systému'],
                        ['10-16%', 'Malé pokrytí užitné plochy podlahy'],
                    ],
                    [
                        ['10 MIN', 'Roznášecí vrstva je zahřívána rychle a plošně'],
                        ['Rychlost nátopu (1°C)', 'Čas za jaký se ohřeje vzduch v místnosti o 1°C'],
                        ['75 MIN', 'Pomalý nátop způsoben malým pokrytím topného prvku'],
                    ],
                    [
                        ['NE', 'Neobsahuje součástky náchylné k poškození'],
                        ['Poruchové součástky', 'Součástky náchylné k poškození, vyžadující údržbu a servis'],
                        ['ANO', 'Obsahuje součástky náchylné k poškození'],
                    ],
                    [
                        ['13 000 Kč', 'Výkon systému není nijak ovlivněn vekovní teplotou'],
                        ['Provozní náklady', 'Kalkulace pro stavbu 140 m2 s tepelnou ztrátou 5,6 kW na 15 let'],
                        ['14 000 Kč', 'Počítáno s produktem běžné kvality'],
                    ],
                    [
                        ['0 Kč / rok', 'Jedná se o bezúdržbový systém'],
                        ['Náklady na údržbu', 'Způsobené opotřebením mechanických součástek a dalších komponentů'],
                        ['1 000 Kč / rok', 'Průměrné roční náklady'],
                    ],
                    [
                        ['0 Kč / rok', 'Jedná se o systém s životností přes 30 let bez ztráty účinnosti'],
                        ['Náklady na servis', 'roční průměr za servis, repas a výměny v horizontu 15 let'],
                        ['2800 Kč / rok', 'Průměrné roční náklady'],
                    ],
                    [
                        ['NE', 'Bez zásahu do fasády'],
                        ['Zásah do fasády', 'narušení vzhledu stavby'],
                        ['NE', 'Bez zásahu do fasády'],
                    ],
                    [
                        ['NE', 'Možnost jiného využití prostoru - např. druhá koupelna'],
                        ['Technická místnost / omezení dispozic', 'Požadavek technologie na technickou místnost omezující využitelný prostor v interiéru'],
                        ['ANO', 'ano, systém vyžaduje technickou místnost'],
                    ],
                    [
                        ['NE', 'Topný prvek nepřesahuje teploty poškozující vrstvy podlahy'],
                        ['Přehřívání podlahy', 'Zahřívání roznášecí a finální vrstvy podlahy nad teploty povolené výrobci'],
                        ['NE', 'Topný prvek nepřesahuje teploty poškozující vrstvy podlahy'],
                    ],
                    [
                        ['NE', 'Topný prvek nepřesahuje teploty poškozující vrstvy podlahy'],
                        ['Degradace vrstev podlahy', 'Poškozování roznášecí a finální vrstvy podlahy vlivem zahřívání nad výrobci povolené teploty'],
                        ['NE', 'Topný prvek nepřesahuje teploty poškozující vrstvy podlahy'],
                    ],
                    [
                        ['NE', 'Rovnoměrné prohřátí konstrukce vlivem celoplošnosti a vlnové délky'],
                        ['Rozdílné teploty v konstrukci stavby', 'Rozdíl teplot v konstrukci vede k vlhnutí a znamená riziko degradace stavby'],
                        ['ANO', 'Systém neprohřívá rovnoměrně celou konstrukci'],
                    ],
                    [
                        ['NE', 'Systém předchází vlhnutí rovnoměrným nahřátím konstrukce'],
                        ['Vlhkost v konstrukci', 'Vznik vlhkosti vedoucí k degradaci konstrukce, vzniku bakterií a plísní'],
                        ['ANO', 'Nerovnoměrné prohřátí vede k vlhnutí konstrukce'],
                    ],
                    [
                        ['NE', 'Konstantní teplota vlivem celoplošnosti a umístěním senzorů'],
                        ['Rozdíl teplot v interiéru', 'Rozdílné teploty podlah a vzduchu'],
                        ['ANO', 'Požadovaná teplota vzduchu a podlahy pouze 4 z 24 hodin'],
                    ],
                    [
                        ['NE', 'Vlivem velkého pokrytí podlahy nedochází k rozdílům teplot'],
                        ['Rozdíl teplot na podlaze', 'Teplá a chladná místa na podlaze'],
                        ['ANO', 'Rozdílné teploty na podlaze vlivem malého pokrytí'],
                    ],
                    [
                        ['NE', ''],
                        ['Hlučnost systému', 'Systém se zdrojem zvuku'],
                        ['NE', ''],
                    ],
                    [
                        ['NE', 'Systém nepřehřívá podlahu a předchází vysoušení vzduchu'],
                        ['Vysoušení vzduchu', 'Vysoušení vzduchu je způsobené přehřátím podlahy'],
                        ['ANO', 'Systém přehřívá podlahu a vysušuje tak vzduch'],
                    ],
                    [
                        ['ANO', 'Systém nepřehřívá podlahu a předchází víření prachu'],
                        ['Vhodnost pro alergiky (vířivost prachu)', 'Vliv technologie na vířivost prachu a následná dráždivost pro alergiky'],
                        ['NE', 'Systém přehřívá podlahu za účelem nahřátí vzduchu, a způsobuje tak víření prachu'],
                    ],
                    [
                        ['NE', 'Vlivem rovnoměrného prohřátí konstrukce zamezuje vzniku bakterií a plísní'],
                        ['Vznik bakterií a plísní', 'Vznik bakterií a plísní vlivem vlhkosti v konstrukci'],
                        ['ANO', 'Vlivem nerovnoměrného prohřátí konstrukce a vlhnutím'],
                    ],
                    [
                        ['ANO', 'Bez komplikací'],
                        ['Možnost inteligentní regulace', 'Možnost napojení na systém smart home'],
                        ['ANO', 'Pro plnohodnotné využití zvyšuje vstupní investici smart home i rozvodů'],
                    ],
                    [
                        ['ANO', 'Bez komplikací'],
                        ['Možnost regulovatelných okruhů', 'Možnost nastavení samostatně regulovatelných okruhů'],
                        ['ANO', 'Složité. Spojené s výrazným nárůstem vstupních investic na rozvody hadice'],
                    ],
                    [
                        ['ANO', 'Moderní technologie připravená pro využití s FVE'],
                        ['Možnost napojení FVE', 'Propojení technologe s fotovoltaickou elektrárnou (FVE)'],
                        ['NE', 'Napojení není možné'],
                    ],
                    [
                        ['ANO', 'Bez komplikací'],
                        ['Možnost stropního vytápění', 'Možnost instalace technologie jako stropního vytápění'],
                        ['NE', 'Není možné'],
                    ],
                    [
                        ['NE', 'Systém sám vodu neohřívá Ve vývoji'],
                        ['Ohřev teplé vody', 'Schopnost systému ohřívat teplou vodu pro domácnost'],
                        ['ANO', 'Zajišťuje ohřev. V případě poruchy systému je domácnost bez teplé vody'],
                    ],
                ],
            }
        },
        oldAbout: {
            title: 'Co pro Vás znamená <br /><strong>Tepelná pohoda</strong>?',
            box1: {
                title: 'Ideální teplota',
                text: 'Jaká pokojová teplova<br/>Vám vyhovuje?'
            },
            box2: {
                title: 'Počasí',
                text: 'Za jakého počasí se <br />doma cítíte nejlépe?'
            },
            box3: {
                title: 'Denní doba',
                text: 'V kolik hodin doma<br/>nejčastěji relaxujete?'
            },
            claim1: 'A nyní <strong>si představte</strong> místo...',
            claim2: 'kde cítíte <strong>dokonalé pohodlí</strong>...',
            claim3: 'a není vám horko <strong>ani zima</strong>.',
            claim4: 'zapomeňte na topení',
            claim5: 'poznejte princip Heated....',
            section01: {
                title: 'dokonalý <br /> komfort',
                text: 'V interiéru je přesně udržovaná stálá ideální teplota podlahy i vzduchu nezávisle na venkovních podmínkách.Systém tvoří dokonalé vnitřní klima bez nutnosti zásahu uživatele.',
                button: 'Graf nátopu',
                text1: 'Plocha topného systému, tedy část, která reálně nahřívá podlahu, je rozhodujícím faktorem přenosu tepla do prostoru. Díky velkému pokrytí topného prvku se snižuje potřeba na jeho povrchovou teplotu, což ovlivňuje celkový způsob provozu.Celoplošný systém spíná ve velice krátkých topných intervalech, kterými efektivně udržuje stálou ideální teplotu podlahy i prostoru.',
                text2: 'Systém s reálně malou topnou plochou, která spočívá v tenkém průřezu trubky, pro svůj provoz nezbytně vyžaduje vyšší povrchovou teplotu, která je navíc díky délce oběhu rozdílná.V budovách s nízkou energetickou náročností má tato vlastnost za následek pomalý náběh teplot, velmi složitou regulaci, nežádoucí setrvačnost a provoz v podobě střídání fází natápění/ chladnutí, díky kterým pak v průběhu dne kolísají teploty podlahy i vzduchu.'
            },
            section02: {
                title: 'roznášecí <br /> vrstva',
                text: 'Využitelnost se všemi běžnými typy roznášecích vrstev. Heated. je jako jediný systém svého druhu oficiálně zahrnut v certifikovaných skladbách podlahy hlavních výrobců roznášecích vrstev na trhu.Systém je instalován pod roznášecí vrstvu anhydritového/ cementového potěru nebo sádrovláknité desky Fermacell a ideálně tak využívá její akumulační vlastnosti.',
                button: 'Certifikované skladby',
                title1: 'certifikované <br /> skladby',
                text1: 'Využitelnost se všemi běžnými typy roznášecích vrstev. Heated. je jako jediný systém svého druhu oficiálně zahrnut v certifikovaných skladbách podlahy hlavních výrobců roznášecích vrstev na trhu.Systém je instalován pod roznášecí vrstvu anhydritového/ cementového potěru nebo sádrovláknité desky Fermacell a ideálně tak využívá její akumulační vlastnosti.',
                button1: 'Katalog Heated.',
                button2: 'Katalog Heated.',
            },
            section03: {
                title: 'celoplošné <br /> pokrytí',
                text: 'Topný prvek pokrývá okolo 90 % reálně využívané podlahové plochy. Velikost topné plochy je v dnešních moderních budovách klíčovým parametrem pro efektivní přenos tepla do prostoru.',
                button: 'Infrasnímek',
            },
            section04: {
                title: 'bezporuchový <br /> systém',
                text: 'Topný systém Heated. neobsahuje žádné komponenty náchylné na poškození ani údržbu a nevyžaduje tak žádný servis. Životnost systému překonává běžnou životnost podlahy samotné.',
                button: 'Porovnání nákladů',
            },
            section05: {
                title: 'další výhody',
                box1: {
                    title: 'Vysoká rychlost <br /> nátopu',
                    text: 'Technologie Heated. je složena z uhlíkových vláken, které mají výbornou tepelnou vodivost a zajišťují okamžitý nárůst teploty na svém povrchu.'
                },
                box2: {
                    title: 'Bez rozdílů teplot <br />v konstrukci',
                    text: 'Systém rovnoměrně akumuluje celou obálku budovy a zabraňuje tak tvorbě vlhkosti a s ní spojenému vzniku prostředí pro bakterie a plísně.'
                },
                box3: {
                    title: 'Rychlá a přesná <br /> regulace',
                    text: 'Systém Heated. vyniká rychlým a naprosto přesným řízením teploty podlahy i vzduchu.'
                },
                box4: {
                    title: 'Nízké provozní <br /> náklady',
                    text: 'Díky ideálnímu přenosu tepla do prostoru vykazuje systém velice nízké provozní náklady.'
                }
            },
            section06: {
                title: 'srovnání nátopu na <br /> vašich ideálních',
                box: {
                    title: 'srovnání nátopu',
                    itemTitle: 'tepelné <br /> čerpadlo',
                    label1: 'teplota vzduchu',
                    label2: 'Délka nátopu',
                    label3: 'T. ČERPADLO',
                    label4: 'teplota podlahy',
                    label5: 'doba <br /> nátopu',
                    label6: 'topná <br /> plocha',
                    label7: 'Požadovaná <br /> teplota (24h)',
                }
            },
            section07: {
                title: 'porovnání nákladů',
                subtitle: 'pořizovací - servisní - provozní',
                label3: 'Délka porovnání'
            }
        },
        mobilePage: {
            responseTitle: 'Žádost byla odeslána',
            section01: {
                title: 'vlastnosti <br/><b>Heated.</b>',
                text: 'Vlivem rychlého vývoje stavebních materiálů a legislativních požadavků se vlastnosti budov v posledních letech výrazně změnily. Novostavby dnes vykazují velmi specifické nároky na technologie, které je nezbytné volit přímo v souvislosti s vlastnostmi daného typu stavby. Jsou to totiž právě technologie, zejména vytápění, které vytváří klíčový rozdíl v kvalitě a komfortu života uvnitř budovy.<br/><br/> Vlastnosti technologie Heated. jsou navrženy přesně pro využití v moderních domech a dokonale splňují veškeré jejich potřeby.<br><br>Zapomeňte na topení – poznejte Heated.',
                title01: 'Tvoří stálou tepelnou pohodu',
                text01: 'V interiéru je přesně udržovaná stálá ideální teplota podlahy i vzduchu nezávisle na venkovních podmínkách. Systém tvoří dokonalé vnitřní klima bez nutnosti zásahu uživatele.',
                title02: 'Pokrývá celou plochu podlahy',
                text02: 'Topný prvek pokrývá až 90 % reálně využívané podlahové plochy. Velikost topné plochy je klíčovým parametrem pro efektivní přenos tepla do prostoru v dnešních moderních budovách.',
                title03: 'Instalace pod roznášecí vrstvou',
                text03: 'Systém je instalován pod roznášecí vrstvu anhydritového/cementového potěru nebo sádrovláknité desky Fermacell a ideálně tak využívá její akumulační vlastnosti.',
                title04: 'Bezúdržbový a bezporuchový systém',
                text04: 'Topný systém Heated. neobsahuje žádné komponenty náchylné na poškození ani údržbu a nevyžaduje tak žádný servis. Životnost systému překonává běžnou životnost podlahy samotné.',
                title05: 'Nízké náklady',
                text05: 'Při využití systému Heated. v moderní budově je jeho velkou výhodou velmi nízký průměr celkových nákladů. Díky vysoké rychlosti ohřevu, maximální topné ploše a ideálnímu přenosu tepla do prostoru vykazuje systém nízké provozní náklady, které pak v součtu s pořizovacími a nulovými servisními náklady vytváří velmi nízký průměr celkových relevantních nákladů na vytápění.',
                title06: 'Rychlost nátopu',
                text06: 'Technologie Heated. je složena z uhlíkových vláken, které mají výbornou tepelnou vodivost a zajišťují okamžitý nárůst teploty na svém povrchu. V kombinaci s ostatními vlastnostmi pak v případě potřeby v provozu dochází k velmi rychlému náběhu teploty, který je tak využíván pro krátký a efektivní topný interval.',
                title07: 'Přesná regulace',
                text07: 'Velmi důležitou vlastností vytápění při využití v moderních budovách je jeho regulovatelnost. Systém Heated. vyniká naprosto přesným řízením, které zabraňuje nežádoucí/pomalé reakci na změnu a zbytečné teplotní setrvačnosti. Systém je řízen pomocí podlahových i vzdušných čidel, která zajišťují naprosto přesnou regulaci.',
                title08: 'Nepřetápí vzduch',
                text08: 'Díky kombinaci všech vlastností technologie Heated. nedochází při (běžném) konstantním provozu k tvorbě rozdílných teplot povrchu podlahy mezi dobou, kdy je systém sepnut a kdy ne. Díky tomu tak nevzniká nežádoucí přetápění vzduchu a nadměrné víření prachu.',
                title09: 'Vhodná pro skladbu podlahy',
                text09: 'Technologie Heated. je vhodná pro využití se všemi finálními krytinami, které výrobce obecně určil pro možnost aplikace na podlahové vytápění. Maximální teploty topného prvku Heated. jsou omezeny tak, aby nedocházelo k degradaci roznášecí ani finální vrstvy jako u běžných elektrických podlahových systémů.',
                title10: 'Využití s FVE nebo jiným zdrojem',
                text10: 'Vzhledem k možnostem zapojení je systém Heated. kompatibilní s vlastním zdrojem výroby energie, který má omezenou kapacitu tak, aby při sepnutí nedošlo k okamžitému odběru veškeré vyráběné nebo úložené energie.',
                title11: 'Integrace do Smart Home',
                text11: 'Technologii Heated. lze snadno integrovat do Smart Home systému, který pak nabízí řešení vzdáleného ovládání, komplexní automatizace i individuálního nastavení odběru energie – vhodného právě pro využití např. s domácí fotovoltaickou elektrárnou.',
                title12: 'Bez hluku a náročnosti na prostor',
                text12: 'Kompletní technologie je zabudovaná v podlaze, nevyžaduje tak žádný nárok na vlastní prostor v interiéru a v budově vzniká místo pro vhodnější využití zastavěné plochy. Navíc systém samozřejmě neobsahuje žádnou venkovní jednotku, a nenarušuje tak design fasády a neobtěžuje hlukem.',
                title13: 'Certifikované skladby',
                text13: 'Systém Heated. jako jediný svého druhu je oficiálně zařazen v certifikovaných skladbách podlahy litých potěrů Cemex i suché skladby Fermacell.',
                title14: 'Zabraňuje vlhnutí konstrukce',
                text14: 'Systém rovnoměrně akumuluje celou obálku budovy a zabraňuje tak tvorbě vlhkosti a s ní spojenému vzniku prostředí pro bakterie a plísně.',
            },
            section02: {
                title: '<b>Porovnání</b><br />vytápění',
                title01: 'Vyšší topná plocha.',
                text01: 'Systém Heated. se vyznačuje mnohonásobně vyšší topnou plochou. Díky tomu dosáhnete velice rychle požadované teploty v místnosti.',
                title02: '<b>Úspora na vstupní investici</b> a srovnání nákladů.',
                text02: 'Kvalita tepelného čerpadla se přímo odvíjí od jeho ceny. Stejně tak rozvody a možnost regulovat každou místnost zvlášť. U systému Heated. nepřemýšlíte nad variantou, jedná se vždy o bezporuchový a bezúdržbový produkt bez dalších nákladů.',
                note: 'Porovnání Heated. s dalšími systémy vytápění naleznate na našem hlavním webu.'
            },
            section03: {
                title: '<b>Realizace</b>',
                text: 'Od roku 2015 jsme zrealizovali...',
                subtext01: 'podlahových ploch',
                subtext02: 'rodinných domů',
                subtext03: 'developerských projektů',
            },
            section04: {
                title: '<b>Cenová nabídka</b><br />Heated.',
                text: 'Naše specializované projekční oddělení zpracuje cenovou nabídku na míru Vašemu domu včetně odhadu roční spotřeby.',
            },
        },
        priceOffer: {
            title: 'Cenová nabídka',
            header: 'Cenová nabídka <b>Heated. zdarma</b>',
            calculationRequest: 'Žádost o cenovou kalkulaci zdarma'
        },
        caseStudy: {
            title_01: 'Naše cesta',
            title_02: 'k Heated.',
            section01: {
                title: '"<span class="orange">Rozhodli jsme se koupit dům</span> v menším developerského projektu, kde náš soused v totožném domě topí tepelným čerpadlem."',
                text: `"S manželem jsme žili přes 10 let v centru Brna a před 2 lety se nám narodilo dítě, což pro nás byl impuls odstěhovat se mimo město.<br/><br/>
                Rozhodli jsme se pro menší developerský projekt za Brnem. Sedm totožných domů, z toho 3 již obydlené a čtyři v procesu přípravy."`
            },
            section02: {
                title: 'Proč jste začali zvažovat změnu projektu?',
                text: `"Vlastně jsme v začátku žádnou změnu nezvažovali, v projektu bylo tepelné čerpadlo. Při pravidelné návštěvě stavby jsme si všimli hluku z vedlejšího domu. Bylo nám řečeno, že je to zcela normální, že se jedná o kompresor čerpadla. <span class="orange">Říkali jsme si, že musí existovat elegantnější řešení</span>, a tak začala naše zvědavost ohledně všech druhů vytápění."`
            },
            section03: {
                title: 'Jaké otázky byly pro Vás zásadní při volbě technologie vytápění?',
                text: `"Trvalo nám, než jsme si uvědomili, že systém vytápění je jednou z hlavních technologií v domě. Jedná se o vybavení, které člověk zdánlivě nevidí, nicméně využívá jej po většinu část roku. Pokud je tedy nevyhovující, muže to být zásadní problém. Nehledě na fakt, že do stavby vložíte celoživotní úspory a na velkou část života se zadlužíte. <b>Chtěli jsme tedy být v každém ohledu spokojení a dělat co nejméně kompromisů.</b> Proto jsme vedle pořizovacích nákladů zohledňovali řadu dalších faktorů."`,
                tableTitle1: 'Radiátory',
                tableTitle2: 'Odporové kabely',
                tableTitle3: 'Tepelné čerpadlo',
                question1: 'Chcete mít nízké provozní náklady?',
                question2: 'Chcete mít bezporuchovou technologii bez servisu?',
                question3: 'Chcete mít rychle požadovanou teplotu?',
                question4: 'Chcete mít doma komfort bez rozdílů teplot?',
                question5: 'Nechcete mít v domě technickou místnost a hlučnou technologii?',
            },
            section04: {
                title: 'Které technologie jste v průběhu zvažovali?',
                text: `"Musím říct, že to bylo od začátku velice matoucí. Každý nám doporučoval jinou technologii, většinou s nejasnými nebo neúplnými argumenty. Nejprve jsme hledali čerpadlo, které nebude dělat hluk. Postupem času jsme tepelné čerpadlo vyloučili jako nenávratnou investici se spoustou starostí. Stavební firma nám doporučila odporové kabely, což se nám zamlouvalo do doby výpočtu nákladů, a především upozornění dodavatelů podlah, na vysokou teplotu a možné poškození podlahy. Na internetu jsme narazili na systém Heated., který se nám zamlouval v každém zmíněném ohledu."`
            },
            section05: {
                title: 'Prvotní schůzka ohledně Heated.',
                text: `"Po úvodním telefonu jsme navštívili pobočku se showroomem. Na schůzce jsme podrobně probrali jednotlivé aspekty všech druhů vytápění a porovnali je s Heated. Do té doby se vždy mluvilo pouze o jednom produktu. Přehled informací na nás udělal dojem a pochopili jsme, že pro kompletní informace je dobré zajít za specializovanou firmou na vytápění. Konkrétně v této společnosti to bylo v řádech tisíců projektů ročně."`,
                column1: {
                    title: 'Architekt / Projektant',
                    text: 'Architekti a projektanti jsou bráni jako autorita při návrhu domu. Parametry domu se však s legislativou velice rychle mění a s ní také požadavky na technologii vytápění.',
                    claim: '1-50 projektů ročně'
                },
                column2: {
                    title: 'Stavební firmy',
                    text: 'Stavební firmy mají přehled o nejnovějších materiálech a dokážou Vám poradit s parametry domu. Technologie jako vytápění mají však na tyto parametry velký vliv a je potřeba je dokonale znát.',
                    claim: '20-50 projektů ročně'
                },
                column3: {
                    title: 'Specialista vytápění Heated.',
                    text: 'Našich 7 specializovaných oddělení každoročně posoudí tisíce staveb. Známe tak veškerá úskalí moderních novostaveb, dokážeme tak navrhnout vytápění na míru Vašich potřeb.',
                    claim: '1000+ projektů ročně'
                },
            },
            section06: {
                title: 'Byl pro Vás výběr dodavatele stejně důležitý?',
                text: `"Až na osobní schůzce ve firmě Efektivní dům, jsme se poprvé setkali s tím, že někdo dokázal zodpovědět veškeré naše dotazy. Tím opadly veškeré naše obavy a <span class="orange">v tu chvíli jsme věděli, že můžeme věnovat pozornost samotnému systému.</span>"`
            },
            section07: {
                title: 'Co pro Vás byl ten rozhodující argument pro zvolení Heated. ?',
                text: `"Asi to nebyl jeden konkrétní argument, ale spojení všech faktorů. Čím více jsme toho o různých druzích vytápění věděli, tím jsme byli více bezradní. <b>Když se nad tím zpětně zamyslím, nejvíce nás přesvědčila osobní schůzka.</b> Samozřejmě nás zajímaly také finance. Provozní náklady vycházely u tepelného čerpadla lépe, vstupní investice a servisní náklady však z tepelného čerpadla udělaly nevratnou investici. Naopak u topných kabelů byl nižší pořizovací náklad ale 2x vyšší provozní náklady. <b>Heated. je zlatá střední cesta s nespočtem výhod.</b>"`,
                box01: {
                    title: 'Bezporuchový <br />systém',
                    text: '"Bezporuchovost nám přišla jako hlavní výhoda. V průběhu let nevznikají žádné další náklady na servis ani údržbu. Výrobce garantuje životnost přes 30 let bez ztráty účinnosti výkonu. Samotná firma Efektivní dům poskytuje záruku 10-15 let."'
                },
                box02: {
                    title: 'Nejvýhodnější <br />v součtu nákladů',
                    text: '"Při porovnání všech nákladů (vstupní, provozní, servisní) poráží Heated. v průběhu 15 let veškeré konkurenční systémy. Znamená to tedy, že nejpozději od poloviny hypotéky oproti ostatním systémům spoříte."'
                },
                box03: {
                    title: 'Komfortní teplota <br />a vnitřní klima',
                    text: '"Celoplošný systém zaručuje rychlý náběh požadované teploty jak na podlaze, tak v místnosti. Nedochází tak k přetápění podlahy, ani vzduchu."'
                },
                box04: {
                    title: 'Bez hlučného zdroje <br />a technické místnosti',
                    text: '"V původním projektu zabírala technická místnost 8 m2. Dispozičně jsme změnili technickou místnost na druhou koupelnu v pokoji pro hosty."'
                },
                box05: {
                    title: 'Celoplošnost',
                    text: 'Topný prvek pokrývá více než 90 % reálně využívané podlahové plochy. Velikost topné plochy je klíčovým parametrem pro efektivní přenos tepla do prostoru v dnešních moderních budovách.'
                },
                box06: {
                    title: 'Rychlost nátopu',
                    text: 'Technologie Heated. je složena z uhlíkových vláken, které mají výbornou tepelnou vodivost a zajišťují okamžitý nárůst teploty na svém povrchu. V kombinaci s ostatními vlastnostmi pak v případě potřeby v provozu dochází k velmi rychlému náběhu teploty, který je tak využíván pro krátký a efektivní topný interval.'
                },
                box07: {
                    title: 'Akumulace podlahy',
                    text: 'Systém je instalován pod roznášecí vrstvu anhydritového/cementového potěru, nebo sádro-vláknité desky Fermacell a ideálně tak využívá její akumulační vlastnosti.'
                },
                box08: {
                    title: 'Přesná regulace',
                    text: 'Velmi důležitou vlastností vytápění při využití v moderních budovách je jeho regulovatelnost. Systém Heated. vyniká naprosto přesným řízením, které zabraňuje nežádoucí/pomalé reakci na změnu a zbytečné teplotní setrvačnosti. Systém je řízen pomocí podlahových i vzdušných čidel, která zajišťují naprosto přesnou regulaci.'
                },
                box09: {
                    title: 'Vhodné pro alergiky',
                    text: 'Díky kombinaci všech vlastností technologie Heated. nedochází při (běžném) konstantním provozu k tvorbě rozdílných teplot povrchu podlahy mezi dobou, kdy je systém sepnut a kdy ne. Díky tomu tak nevzniká nežádoucí přetápění vzduchu a nadměrné víření prachu.'
                },
                box10: {
                    title: 'Vhodná pro skladbu podlahy',
                    text: 'Technologie Heated. je vhodná pro využití se všemi finálními krytinami, které výrobce obecně určil pro možnost aplikace na podlahové vytápění. Maximální teploty topného prvku Heated. jsou omezeny tak, aby nedocházelo k degradaci roznášecí ani finální vrstvy jako u běžných elektrických podlahových systémů.'
                },
                box11: {
                    title: 'Zabraňuje vlhnutí konstrukce a vzniku bakterií i plísní',
                    text: 'Systém rovnoměrně akumuluje celou obálku budovy a zabraňuje tak tvorbě vlhkosti a s ní spojenému vzniku prostředí pro bakterie a plísně.'
                },
            },
            section08: {
                title: 'Jak probíhaly přípravy na samotnou realizaci a jak na to reagovala stavební firma?',
                text: '"To vlastně ani nevíme. Předali jsme veškeré podklady a <span class="orange">Efektivní dům navázalo kontakt se všemi účastníky stavby.</span> Vlastně byli jediní, s kým jsme nemuseli být denně na telefonu a neustále o něčem rozhodovat."'
            },
            section09: {
                client: '<b>Petříkovi</b><br /> z pohledu klienta',
                heatlow: '<b>Pavel Dědouch</b><br /> odborník na vytápění Heated.',
                title01: 'Jak probíhala změna projektu na systém Heated.?',
                textClient01: '"Změny jsme se trochu obávali, protože se jednalo o systém, který nám developer v projektu nenabízel. Heat Energy však bylo díky svému technickému a projekčnímu oddělení schopné vyřešit veškeré aspekty přímo s architektem a stavební firmou."',
                textHeatflow01: '"Díky specializovaným oddělením a skvěle informovaným obchodně-technickým zástupcům není změna projektu žádný problém. Svědčí o tom naše spolupráce se stovkami architektů a projektantů po celé ČR. Ve většině případů stačí jedna osobní schůzka, kdy jsou účastníci stavby seznámeni s naším systémem a vše ostatní již dořeší jednotlivá specializovaná oddělení."',
                title02: 'Jak dlouhý proces byla samotná realizace Heated.?',
                textClient02: '"Realizace vytápění Heated. proběhla naprosto bez problémů. Obávali jsme se zdlouhavé koordinace se stavební firmou, architektem a elektrikářem, ale tým Heated. se postaral, aby bylo vše připravené. Samotná instalace zabrala pouze jeden den. O nic jsme se nemuseli starat."',
                textHeatflow02: '"V procesu návrhu máme vlastní oddělení specializovaných projektantů, kteří navrhnou řešení na míru klienta. Následně pro elektrikáře na stavbě vygenerujeme připravenost elektro, díky které snadno připraví vše potřebné před samotnou instalací Heated. Disponujeme také oddělením přípravy, které se stará o to, aby v den montáže bylo vše připraveno a předání stavby se zbytečně neprotahovalo. Samotná instalace Heated. v domě do 150 m2 trvá pouze jeden den."',
            },
            section10: {
                title: 'Jak se žije v domě s celoplošným systémem vytápění Heated.?',
                text: 'Většina našich zákazníků si myslí, že se jedná o neodzkoušenou novinku na trhu. <span class="orange">Opak je pravdou, na trhu jsme již 11. rokem a za tu dobu jsme zrealizovali vytápění do více než 3000 staveb.</span> Jsme schopni určit, jak se bude Vaše stavba chovat a díky tomu poměrně přesně odhadnou provozní náklady. Odhad je součástí každé cenové kalkulace.',
                client: 'Petříkovi',
                box01: {
                    title: 'Jaké máte zkušenosti z Vaší první topné sezóny?',
                    text: '"Samozřejmě jsme byli na první vyúčtování velice zvědaví – už kvůli tomu, že jsme dostali předběžný odhad spotřeby a přišel nám poměrně nízký. <span class="orange">Musíme však říct, že odhad byl téměř přesný</span> (byli jsme upozorněni, že náklady na vytápění můžou být během první sezóny cca o 20 % vyšší z důvodu zvýšeného množství vody v konstrukci)."'
                },
                box02: {
                    title: 'Zmiňovali jste komfortní teplotu a vnitřní klima',
                    text: '"Ano, zpětně to považuji za největší překvapení, na které jsme při výběru nebrali takový ohled. Bylo nám řečeno, že komfort je hlavní předností, ale jak si něco takového představit ? Kdybych to měla popsat, <span class="orange">řekla bych, že člověku není ani horko ani zima, zkrátka na topení vůbec nemyslíte.</span> Je to velice těžko popsatelný pocit, který s dřívější zkušeností nelze vůbec rovnat."'
                },
                box03: {
                    title: 'Kdybyste měli nyní porovnat vytápění se sousedem, co byste řekli?',
                    text: '"Soused se neustále oháněl koeficientem účinnosti. Když však přišla zima, měl srovnatelné náklady. <span class="orange">Na návštěvě jsme okamžitě poznali velký teplotní rozdíl na podlaze a ve vzduchu.</span> Nehledě na to, že v určitých místech má na podlaze studená místa. Celkově jsme rádi, že jsme nekývli na první návrh jen proto, že to soused má taky."'
                },
            },
            section11: {
                title: '<span class="sluzba-expertise-link">Nedělejte kompromisy</span> - stejně jako my',
                subtitle: 'Naše produkty i služba jsou postaveny na Vaší <span class="sluzba-expertise-link">maximální spokojenosti</span>'
            }
        },
        reference: {
            title: 'Reference',
            count: 'Počet referencí',
            similar: 'Podobné reference',
            caseStudy: 'Případová studie',
            caseStudyBr: 'Případová <br/>studie',
            caseStudyText: `Nahlédněte do procesu rozhodování rodiny, která si prošla tím, čím si pravděpodobně právě procházíte i Vy. Od počátečních informací až po výběr dodavatele.`,
            caseStudyComparison: 'Případová studie - srovnání',
            interestingProjectsBr: 'Zajímavé <br /> projekty',
            interestingProjects: 'Zajímavé projekty',
            portfolioShowcaseInterestingProjects: 'Ukázka z portfolia realizací zajímavých projektů.',
            housesBr: 'Rodinné <br /> domy',
            houses: 'Rodinné domy',
            housesTextP1: 'Podlahové vytápění ',
            housesTextP2: ' jsme realizovali po dobu 10 let. Za tu dobu jsme zvládli nainstalovat vytápění do více než 3000 rodinných domů. Od roku 2022 jsme se s výrobcem v Jížní Korei dohodli, že nám tuto fólii vyrobí pod vlastní značkou. Jedná se o produkt se shodnou certifikací a vlastnostmi. Díky tomu nejsme závislí na distributorovi a můžeme kontrolovat kvalitu a především rychlost dodání zboží. <b>Tak vznikl produkt Carbon Heated</b>.',
            portfolioShowcaseHouses: 'Ukázka z portfolia realizací rodinných domů.',
            mayInterestYou: 'Mohlo by Vás také zajímat',
            ourRealization: 'naše <b>realizace</b>',
            familyHouses: {
                type: {
                    title: 'Typ stavby',
                    option1: 'Na typu nezáleží',
                    option2: 'Zděný dům',
                    option3: 'Dřevostavba',
                    option4: 'Rekonstrukce',
                },
                area: {
                    title: 'Užitná plocha stavby',
                    option1: 'Na ploše nezáleží',
                    option2: 'Do 100 m²',
                    option3: '101-150 m²',
                    option4: 'Nad 150 m²',
                },
                costs: {
                    title: 'Měsíční náklady na vytápění',
                    option1: 'Na nákladech nezáleží',
                    option2: 'Do 1000 Kč',
                    option3: 'Do 2000 Kč',
                    option4: 'Do 3000 Kč',
                    option5: 'Nad 3000 Kč',
                }
            }
        },
        partners: {
            title: 'Partneři',
            weCooperate: 's kým <b>spolupracujeme</b>',
            architects: 'Architekti'
        },
        faq: {
            titleShort: 'FAQ',
            title: 'Často kladené otázky',
            formTitle: 'Máte jiný dotaz? Napište nám.',
            write: 'Napsat zprávu',
            note: 'Poznámka: odeslaný e-mail půjde na email: oravec@h-e.cz',
            noResult: '<b>Nenašli jste odpověď na Vaši otázku?</b> Kontaktujte náš tým.'
        },
        terms_conditions: {
            title: 'Obchodní podmínky',
            content: '<p><strong>Informační povinnost o mimosoudním řešení spotřebitelských sporů</strong><br />\n' +
                '    Dle ust. § 14 zákona č. 634/1992 Sb., o ochraně spotřebitele, Vás informujeme, že subjektem mimosoudního řešení spotřebitelských sporů (tj. dle ust. § 20d zákona č. 634/1992 Sb., o ochraně spotřebitele spor z kupní smlouvy nebo ze smlouvy o poskytování služeb) je Česká obchodní inspekce (ČOI), internetová adresa subjektu: www.coi.cz. Bližší informace naleznete rovněž na internetových stránkách http://adr.coi.cz.</p>'
        },
        contact: {
            title: 'Kontakty',
            largeTitle: '<b> zvolte váš <br />kraj</b> pro zobrazení kontaktů',
            id: 'IČ',
            vid: 'DIČ',
            headquarter: 'Sídlo',
            companyHeadQuarter: 'Sídlo společnosti',
            email: 'info@muj-ed.cz',
            address: 'Strojírenská 259/16, 155 21, Praha 5 - Zličín',
            contact1: {
                text: 'Generální dodavatel systému Heated. do ČR'
            },
            contact2: {
                title: 'Morava a slezsko',
                title2: 'Zastoupení pro Moravu a Slezsko',
                text: 'Působíme po celé České republice a Slovensku',
                opening: '8-16 hod.',
                openingDays: 'Po-Pá'
            },
            contact3: {
                title: 'Čechy',
                title2: 'Zastoupení pro Čechy',
                subtitle: 'Pobočka a showroom',
                opening: '9-17 hod.',
                openingDays: 'Po-Pá'
            },
        },
        download: {
            breadcrumb: 'Ke stažení',
            title: 'Dokumenty <b>ke stažení</b>',
            toDownload: 'Stáhnout',
            item01: 'Technický list',
            item02: 'Návod termostat NTC',
        },
        comparison: {
            titleShort: 'porovnání',
            title01: 'Elektrická rohož',
            title02: 'Tepelné čerpadlo',
            title02Long: 'Teplovodní rozvody + tepelné čerpadlo',
            title03: 'Vodní vytápění (Plynový kotel)',
            title03Tiny: 'Kotel',
            title03Short: 'Plynový kotel',
            title03Long: 'Teplovodní rozvody + kotel (el./plyn)',
            textTitle01: 'Nižší vstup náklady',
            textTitle02: 'Nižší spotřeba',
            textTitle03: 'Topná plocha',
            textTitle04: 'Bez rozdílů teplot',
            textTitle05: 'Rychlost nátopu',
            textTitle06: 'Životnost technologie',
            texts: [
                'U vstupní investice je potřeba zvážit více faktorů a to především náklady na servis, údržbu a případnou výměnu komponentů, v průběhu let. Heated. je naprosto bezporuchový systém, nevyžaduje v průběhu let žádné další investice.',
                'Energetická náročnost budov se neustále snižuje, znamená to, že rozdíl nákladů jednotlivých systémů se bude v průběhu let snižovat.',
                'Topná plocha je jednou z nejdůležitějších vlastností pro rychlé a přesné docílení požadované teploty.',
                'Heated. udržuje stejnou teplotu v podlaze, ve vzduchu, ale také v konstrukci, zabraňuje tak přetápění vzduchu a kondenzaci vody.',
                'Se systémem Heated. dosáhnete požadované teploty v řádu několika minut. Běžné systémy vytápění jsou mnohonásobně pomalejší.',
                'Životnost systému Heated. je přes 30 let BEZ ZTRÁTY ÚČINNOSTI. Znamená to, že převyšuje životnost podlah samotných.'
            ],
            tableTitle: 'Podrobný rozpis',
            tableSection01: {
                title: 'Vlastnosti systému',
                row01: {
                    text1: 'Vysoké pokrytí užitné plochy podlahy',
                    title2: 'Pokrytí podlahy',
                    text2: 'Plocha podlahy pokrytá topným prvkem systému',
                    text3: 'Malé pokrytí užitné plochy podlahy'
                },
                row02: {
                    text1: 'Roznášecí vrstva je zahřívána rychle a plošně',
                    title2: 'Rychlost nátopu',
                    text2: 'Čas za jaký se ohřeje vzduch v místnosti o 1°C',
                    text3: 'Pomalý nátop způsoben malým pokrytím topného prvku'
                },
                row03: {
                    title1: 'NE',
                    text1: 'Neobsahuje součástky náchylné k poškození',
                    title2: 'Poruchové součástky',
                    text2: 'Součástky náchylné k poškození, vyžadující údržbu a servis',
                    title3: 'NE',
                    title3_01: 'ANO',
                    text3: 'Neobsahuje součástky náchylné k poškození',
                    text3_01: 'Obsahuje součástky náchylné k poškození'
                }
            },
            tableSection02: {
                title: 'Náklady na servis a údržbu',
                row01: {
                    title1: '13 000 Kč',
                    text1: 'Výkon systému není nijak ovlivněn vekovní teplotou',
                    title2: 'Provozní náklady',
                    text2: 'Kalkulace pro stavbu 140 m<sup>2</sup> s tepelnou ztrátou 5,6 kW na 15 let',
                    title3_01: '22 000 Kč',
                    title3_02: '10 000 Kč',
                    title3_03: '14 000 Kč',
                    text3_01: 'Počítáno s produktem běžné kvality',
                    text3_02: '(vzduch-voda), počítáno s teplotami pod bodem mrazu'
                },
                row02: {
                    title1: '0 Kč / rok',
                    text1: 'Jedná se o bezúdržbový systém',
                    title2: 'Náklady na údržbu',
                    text2: 'Způsobené opotřebením mechanických součástek a dalších komponentů',
                    title3_01: '0 Kč / rok',
                    title3_02: '1 200 Kč / rok',
                    title3_03: '1 000 Kč / rok',
                    text3_01: 'Jedná se o bezúdržbový systém',
                    text3_02: 'Průměrné roční náklady'
                },
                row03: {
                    title1: '0 Kč / rok',
                    text1: 'Jedná se o systém s životností přes 30 let bez ztráty účinnosti',
                    title2: 'Náklady na servis',
                    text2: 'roční průměr za servis, repas a výměny v horizontu 15 let',
                    title3_01: '0 Kč / rok',
                    title3_02: '5 100 Kč / rok',
                    title3_03: '2 800 Kč / rok',
                    text3_01: 'Samotný systém není náchylný na poruchy',
                    text3_02: 'Průměrné roční náklady'
                },
            },
            tableSection03: {
                title: 'Nároky technologie',
                row01: {
                    title1: 'NE',
                    text1: 'Bez zásahu do fasády',
                    title2: 'Zásah do fasády',
                    text2: 'narušení vzhledu stavby',
                    title3_01: 'NE',
                    title3_02: 'ANO',
                    text3_01: 'Bez zásahu do fasády',
                    text3_02: 'Venkovní jednotka narušuje fasádu a může být zdrojem hluku'
                },
                row02: {
                    title1: 'NE',
                    text1: 'Možnost jiného využití prostoru - např. druhá koupelna',
                    title2: 'Technická místnost / omezení dispozic',
                    text2: 'Požadavek technologie na technickou místnost omezující využitelný prostor v interiéru',
                    title3_01: 'NE',
                    title3_02: 'ANO',
                    text3_01: 'systém bez technické místnosti',
                    text3_02: 'ano, systém vyžaduje technickou místnost'
                },
            },
            tableSection04: {
                title: 'Vliv technologie na materiály',
                row01: {
                    title1: 'NE',
                    text1: 'Topný prvek nepřesahuje teploty poškozující vrstvy podlahy',
                    title2: 'Přehřívání podlahy',
                    text2: 'Zahřívání roznášecí a finální vrstvy podlahy nad teploty povolené výrobci',
                    title3_01: 'ANO',
                    title3_02: 'NE',
                    text3_01: 'Topný prvek přesahuje teploty povolené výrobci vrstev podlahy',
                    text3_02: 'Topný prvek nepřesahuje teploty poškozující vrstvy podlahy'
                },
                row02: {
                    title1: 'NE',
                    text1: 'Topný prvek nepřesahuje teploty poškozující vrstvy podlahy',
                    title2: 'Degradace vrstev podlahy',
                    text2: 'Poškozování roznášecí a finální vrstvy podlahy vlivem zahřívání nad výrobci povolené teploty',
                    title3_01: 'ANO',
                    title3_02: 'NE',
                    text3_01: 'Topný prvek přesahuje teploty povolené výrobci vrstev podlahy',
                    text3_02: 'Topný prvek nepřesahuje teploty poškozující vrstvy podlahy'
                },
                row03: {
                    title1: 'NE',
                    text1: 'Rovnoměrné prohřátí konstrukce vlivem celoplošnosti a vlnové délky',
                    title2: 'Rozdílné teploty v konstrukci stavby',
                    text2: 'Rozdíl teplot v konstrukci vede k vlhnutí a znamená riziko degradace stavby',
                    title3: 'ANO',
                    text3: 'Systém neprohřívá rovnoměrně celou konstrukci',
                },
                row04: {
                    title1: 'NE',
                    text1: 'Systém předchází vlhnutí rovnoměrným nahřátím konstrukce',
                    title2: 'Vlhkost v konstrukci',
                    text2: 'Vznik vlhkosti vedoucí k degradaci konstrukce, vzniku bakterií a plísní',
                    title3: 'ANO',
                    text3: 'Nerovnoměrné prohřátí vede k vlhnutí konstrukce',
                },
            },
            tableSection05: {
                title: 'Ovlivnění komfortu domácnosti',
                row01: {
                    title1: 'NE',
                    text1: 'Konstantní teplota vlivem celoplošnosti a umístěním senzorů',
                    title2: 'Rozdíl teplot v interiéru',
                    text2: 'Rozdílné teploty podlah a vzduchu',
                    title3: 'ANO',
                    text3_01: 'Intervaly přehřívání a chladnutí',
                    text3_02: 'Požadovaná teplota vzduchu a podlahy pouze 4 z 24 hodin'
                },
                row02: {
                    title1: 'NE',
                    text1: 'Vlivem velkého pokrytí podlahy nedochází k rozdílům teplot',
                    title2: 'Rozdíl teplot na podlaze',
                    text2: 'Teplá a chladná místa na podlaze',
                    title3: 'ANO',
                    text3_01: 'Vlivem nízkého pokrytí podlahy',
                    text3_02: 'Rozdílné teploty na podlaze vlivem malého pokrytí'
                },
                row03: {
                    title1: 'NE',
                    title2: 'Hlučnost systému',
                    text2: 'Systém se zdrojem zvuku',
                    title3_01: 'NE',
                    title3_02: 'ANO',
                },
            },
            tableSection06: {
                title: 'Ovlivnění zdraví',
                row01: {
                    title1: 'NE',
                    text1: 'Systém nepřehřívá podlahu a předchází vysoušení vzduchu',
                    title2: 'Vysoušení vzduchu',
                    text2: 'Vysoušení vzduchu je způsobené přehřátím podlahy',
                    title3: 'ANO',
                    text3: 'Systém přehřívá podlahu a vysušuje tak vzduch',
                },
                row02: {
                    title1: 'ANO',
                    text1: 'Systém nepřehřívá podlahu a předchází víření prachu',
                    title2: 'Vhodnost pro alergiky (vířivost prachu)',
                    text2: 'Vliv technologie na vířivost prachu a následná dráždivost pro alergiky',
                    title3: 'NE',
                    text3: 'Systém přehřívá podlahu za účelem nahřátí vzduchu, a způsobuje tak víření prachu',
                },
                row03: {
                    title1: 'NE',
                    text1: 'Vlivem rovnoměrného prohřátí konstrukce zamezuje vzniku bakterií a plísní',
                    title2: 'Vznik bakterií a plísní',
                    text2: 'Vznik bakterií a plísní vlivem vlhkosti v konstrukci',
                    title3: 'ANO',
                    text3: 'Vlivem nerovnoměrného prohřátí konstrukce a vlhnutím',
                },
            },
            tableSection07: {
                title: 'Další možnosti technologie',
                row01: {
                    title1: 'ANO',
                    text1: 'Bez komplikací',
                    title2: 'Možnost inteligentní regulace',
                    text2: 'Možnost napojení na systém smart home',
                    title3: 'ANO',
                    text3_01: 'Bez komplikací',
                    text3_02: 'Pro plnohodnotné využití zvyšuje vstupní investici smart home i rozvodů',
                },
                row02: {
                    title1: 'ANO',
                    text1: 'Bez komplikací',
                    title2: 'Možnost regulovatelných okruhů',
                    text2: 'Možnost nastavení samostatně regulovatelných okruhů',
                    title3: 'ANO',
                    text3_01: 'Bez komplikací',
                    text3_02: 'Složité. Spojené s výrazným nárůstem vstupních investic na rozvody hadice',
                },
                row03: {
                    title1: 'ANO',
                    text1: 'Moderní technologie připravená pro využití s FVE',
                    title2: 'Možnost napojení FVE',
                    text2: 'Propojení technologe s fotovoltaickou elektrárnou (FVE)',
                    title3_01: 'ANO',
                    title3_02: 'KOMPLIKOVANÉ',
                    title3_03: 'NE',
                    text3_01: 'V závislosti na provedení a propojení dodavatelů rohoží a FVE je systém schopný využít energii z vlastní výroby',
                    text3_02: 'Náročné napojení',
                    text3_03: 'Napojení není možné'
                },
                row04: {
                    title1: 'ANO',
                    text1: 'Bez komplikací',
                    title2: 'Možnost stropního vytápění',
                    text2: 'Možnost instalace technologie jako stropního vytápění',
                    title3: 'NE',
                    text3: 'Není možné',
                },
                row05: {
                    title1: 'NE',
                    text1: 'Systém sám vodu neohřívá Ve vývoji',
                    title2: 'Ohřev teplé vody',
                    text2: 'Schopnost systému ohřívat teplou vodu pro domácnost',
                    title3_01: 'NE',
                    title3_02: 'ANO',
                    text3_01: 'Systém sám vodu neohřívá',
                    text3_02: 'Zajišťuje ohřev. V případě poruchy systému je domácnost bez teplé vody',
                },
            },
        },
        assessment: {
            title: 'Posouzení projektu',
            freeAsessment: 'Posouzení projektu zdarma',
            freeAsessmentBr: 'Posouzení projektu<br /> zdarma',
            freeAsessmentText: 'Posouzení současného vytápění <br /> ve Vašem projektu.',
            wantTo: 'chci <b>posoudit projekt</b>'
        },
        audit: {
            title: 'Zpracování PENB',
            titleBr: 'Zpracování <br />PENB',
            titleText: 'Vypracování energetického <br />štítku pro Váš projekt.',
            errorMessage: 'Pro nezávazné nacenění projektu zašlete kompletní projektovou dokumentaci, bude Vás kontaktovat naše projekční oddělení.'
        },
        mobileMenu: {

        },
        modelHouse: {
            title: 'Vzorový dům',
            titleBr: 'Vzorový <br />dům',
            headerTitle: 'SMART HOME s Heated.',
            headerSubtitle: '<b>Heated.</b> namísto<br /> tepelného čerpadla..',
            section01: {
                title: 'Argumenty ke změně',
                text: '“V této stavbě mělo být původně kvalitní tepelné čerpadlo. Po zvážení všech parametrů však nedávalo tepelné čerpadlo smysl. Ušetřili jsme tak 50% na vstupní investici, <span> za kterou jsme automatizovali celý dům</span>”',
                item01: 'Celoplošný systém vytápění',
                item02: 'Bez hlučného zdroje',
                item03: 'Nižší pořizovací náklady',
                item04: 'Rychlá a přesná regulace',
                item05: 'Výhodnější v celkovém součtu nákladů',
                item06: 'Bezporuchový systém',
                item07: 'Technologie pro nízkoenergetické stavby',
                item08: 'Bez technické místnosti',
            },
            section02: {
                title: '<b>celoplošnost</b><br />a druhá koupelna',
                text: '"Pokud budeme počítat, že 1m2 domu nás vyjde na 25 000 Kč, technická místnost nás vyjde na 210 000 Kč." <b>My jsme tento stejný prostor využili pro druhou koupelnu.</b>”',
                text2: '"Tepelné čerpadlo se vyplatí do starších novostaveb nebo rekonstrukcí. U dnešních novostaveb s nízkou tepelnou ztrátou, <b>je potřeba dávkovat teplo rychle a plošně.</b>”'
            },
            section03: {
                title: '<b>ÚSPORA NA <br/>VSTUPNÍ INVESTICI<br/></b>A POROVNÁNÍ NÁKLADŮ',
                text: '“Kvalita tepelného čerpadla se přímo odvíjí od jeho ceny. Stejně tak rozvody a možnost regulovat každou místnost zvlášť. U systému Heated. nepřemýšlíte nad variantou, <b>jedná se vždy o bezporuchový a bezúdržbový produkt, bez dalších nákladů.</b>”',
                note: 'Počítáno kvalitní TČ (vzduch-voda), rozvody v systémové desce, ohřev teplé vody.'
            },
            section04: {
                title: 'KOMPLEXNÍ ŘEŠENÍ Efektivní dům',
                subtitle: 'Do domu jsme dodali také...',
                title2: 'KOMPLEXNÍ SKLADBA PODLAHY',
                text: 'Součástí našeho portfolia je kompletní dodávka podlahové skladby – <b>pokládka izolace - Heated. - litého potěru.</b>',
                button: 'Nacenit skladbu podlahy'
            },
            section05: {
                title: 'AUTOMATIZACE DOMÁCNOSTI',
                text: 'Představte si domácnost, která <b>zná Vaše potřeby a návyky.</b> Loxone Vám <b>každodenně usnadňuje život</b> automatickými prvky jako je řízení světla, žaluzií, osvětlení, zabezpečení a mnoho dalšího. <b>Vše řídíte pohodlně</b> ze svého telefonu nebo pomoci inteligentních senzorů a spínačů, rozmístěných všude v domě.'
            },
            section06: {
                title: 'FVE S BATERIOVÝM ÚLOŽIŠTĚM',
                text: 'Díky kombinaci systému Loxone a bateriového úložiště je systém MCore schopen <b>efektivně využít veškerou vyrobenou energii.</b> Návratnost systému se tak zásadně zkracuje.'
            },
            section07: {
                title: 'TENTO DŮM NALEZNETE TAKÉ VE ČTYŘDÍLNÉM POŘADU O BYDLENÍ',
                item1: 'POROVNÁNÍ - Heated.  A TEPELNÉ ČERPADLO',
                item2: 'LOXONE - AUTOMATIZACE DOMÁCNOSTI',
                item3: 'Heated.  - SLUŽBA NA MÍRU',
                item4: 'JAKÉ VYTÁPĚNÍ ZVOLIT DO NOVOSTAVBY',
            },
            freeCalculation: 'KALKULACE ZDARMA'
        },
        service: {
            header: {
                title: '<span class="orange">Unikátní služba</span><br/> na míru Vašeho projektu',
                box1: 'Počet realizací',
                box2: 'Počet specializovaných oddělení',
                box3: 'Počet členů týmu',
            },
            section01: {
                title: 'Obchodně-technické oddělení',
                text: 'Náš tým obchodně-technických poradců pokrývá celou Českou i Slovenskou republiku a klientům poskytuje maximální servis ve fázi výběru technologie, zaměřený zejména na poradenství a prezentaci produktu.',
                image1: 'technický poradce',
                image2: 'schůzek ročně',
            },
            section02: {
                title: 'Co vše má obchodní oddělení na starost?',
                text: 'Snažíme se působit profesionálně v každém ohledu. Ať už jedou naši obchodně-techničtí zástupci za Vámi na schůzku nebo třeba na kontrolu stavby.'
            },
            section03: {
                title: 'Návrh projektu',
                text: 'Specializovaný tým projekce má na starosti dimenzace optimálního výkonu, návrhy topných okruhů a ideální skladby podlahy i veškeré projekční výstupy vč. možnosti tvorby PENB',
                subtext: 'Vypracování energetického štítku pro Váš projekt',
                image1: 'vysokoškolský projektant',
                image2: 'zpracovaných projektů',
            },
            section04: {
                title: 'Jak probíhá návrh projektu?',
                text: 'Veškerá naše specializovaná oddělení máme na jednom místě. Díky tomu máme přehled o všem, co se děje, a můžeme tak udržet vysoký standard poskytovaných služeb.'
            },
            section05: {
                title: 'Příprava realizace',
                text: 'Příprava má za úkol kompletní organizaci před i po realizační fázi instalace. Kontroluje stavební připravenost, poskytuje poradenství a koordinuje ostatní řemesla na stavbě tak, aby montáž i následné uvedení do provozu proběhlo zcela bez starostí klienta.',
                image1: 'technik přípravář',
                image2: 'realizací',
            },
            section06: {
                title: 'Co zajišťuje přípravné oddělení?',
                box1: {
                    title: 'I. Komunikace s architekty',
                    text: 'Pokud není architekt obeznámen s naším systémem, může si vyžádat schůzku s obchodně-technickým oddělením. Následně vše ohledně podkladů komunikuje oddělení přípravy.'
                },
                box2: {
                    title: 'II. Komunikace se stav. firmou',
                    text: 'Oddělení komunikuje se všemi předchozími a navazujícími řemesly. Eliminujeme tak časté chyby vznikající nedůsledným předávání díla z jedné firmy na druhou.'
                },
                box3: {
                    title: 'III. Instalace elektra',
                    text: 'Pokud to není součástí naší dodávky, vygenerujeme pro Vašeho elektrikáře přehledné instrukce jak postupovat, aby bylo připraveno vše podle potřeb pro následnou instalaci Heated.'
                },
            },
            section07: {
                title: 'Montáž systému',
                text: 'Veškerá montáž je prováděna výhradně naším vlastním zkušeným montážním týmem. Díky dlouholetým zkušenostem a kvalitě našich zaměstnanců pak při montáži nedochází ke komplikacím. Většinu zakázek tak zvládneme realizovat za jediný den.',
                image1: 'technik Heated.',
                image2: 'zrealizováno podlahových ploch',
            },
            section08: {
                title: 'Montáž celoplošného systému Heated.',
                box1: {
                    title: 'I. Kročejová izolace',
                    text: 'Klademe důraz na kvalitu a efektivitu kompletní systémové skladby. Proto používáme pouze ty nejkvalitnější osvědčené materiály.'
                },
                box2: {
                    title: 'II. Systém Heated.',
                    text: 'Heated. instalujeme v celé ploše místností tak, aby pokrýval celou plochu. Neinstalujeme pod neměnné dispozice jako např. vestavěné skříně.'
                },
                box3: {
                    title: 'III. Ochranná fólie',
                    text: 'Součástí každé instalace je bezpečnostní PET folie, která odděluje systém Heated. od litého potěru nebo sádrovláknité desky.'
                },
            },
            quote01: 'Již od roku 2012 rozvíjíme způsoby, jak <span class="h-black">našim klientům usnadňovat proces stavby.</span> Současně každý den přemýšlíme jak zlepšit Vaši životní úroveň <span class="h-black">skrze nejmodernější technologie.</span>',
            section09: {
                title: 'Komplexní dodávka podlahy',
                text: 'Součástí našeho portfolia je kompletní dodávka podlahové skladby – pokládka, izolace a litý potěr. Díky tomu nedochází k případným komplikacím při předávání stavby mezi různými subjekty a celkovou zodpovědnost za podlahu nese naše společnost.',
                note1: 'položení izolace',
                note2: 'instalace Heated.',
                note3: 'roznášecí vrstva',
                image1: 'technik podlah',
                image2: 'komplexních dodávek',
                cta: 'CENOVÁ KALKULACE ZDARMA'
            },
            section10: {
                title: 'Proč zvolit komplexní dodávku od Heated.'
            },
            section11: {
                title: 'Chytrá domácnost LOXONE',
                text: 'Součástí našeho portfolia je automatizace domácnosti v tom pravém slova smyslu. Rakouská firma Loxone věří – tak jako my – v kvalitní produkt s řadou inovativních řešení. Díky neúnavné práci se z nás v roce 2019 stal platinový partner.',
                image1: 'technik Loxone',
                image2: 'realizací Loxone',
                cta: 'CENOVÁ KALKULACE ZDARMA',
                cta2: 'více o Loxone'
            },
            section12: {
                title: 'Články týkající se loxone',
                item1: '<b>Technologie -</b> Specializované projekční oddělení se zabývá detailním návrhem a dimenzací topného systému pro každý topný okruh zvlášť.Součástí našeho porfólia je i vyhotovení PENB.',
                item2: '<b>Bezpečí -</b> Specializované projekční oddělení se zabývá detailním návrhem a dimenzací topného systému pro každý topný okruh zvlášť.Součástí našeho porfólia je i vyhotovení PENB.',
                item3: '<b>Komfort -</b> Specializované projekční oddělení se zabývá detailním návrhem a dimenzací topného systému pro každý topný okruh zvlášť.Součástí našeho porfólia je i vyhotovení PENB.',
            },
            section13: {
                title: 'Fotovoltaika MCore',
                text: 'V roce 2019 jsme na žádost našich klientů vyvinuli fotovoltaický systém, který skvěle zapadá do našeho portfolia. Spojením nejkvalitnějších komponentů technologie fotovoltaické elektrárny a bateriového úložiště vznikl systém, který dokáže maximálně využívat veškerou vyrobenou energii.',
                image1: 'technik MCore',
                image2: 'realizací MCore',
                cta: 'CENOVÁ KALKULACE ZDARMA',
                cta2: 'více o MCore'
            },
            section14:{
                title: 'Články týkající se MCore',
                item1: '<b>Konfigurovatelne reseni pro rodinne domy -</b> Specializované projekční oddělení se zabývá detailním návrhem a dimenzací topného systému pro každý topný okruh zvlášť. Součástí našeho porfólia je i vyhotovení PENB.',
                item2: '<b>Konfigurovatelne reseni pro rodinne domy -</b> Specializované projekční oddělení se zabývá detailním návrhem a dimenzací topného systému pro každý topný okruh zvlášť. Součástí našeho porfólia je i vyhotovení PENB.',
                item3: '<b>Konfigurovatelne reseni pro rodinne domy -</b> Specializované projekční oddělení se zabývá detailním návrhem a dimenzací topného systému pro každý topný okruh zvlášť. Součástí našeho porfólia je i vyhotovení PENB.',
            },
            section15:{
                title: '<span class="sluzba-expertise-link">Nedělejte kompromisy</span> - stejně jako my',
                subtitle: 'Naše produkty i služba jsou postaveny na Vaší <span class="sluzba-expertise-link">maximální spokojenosti</span>'
            },
        },
        footer: {
            title1: 'Více informací',
            title2: 'Působíme po celé čr',
            about: 'Od roku 2012 dodáváme technologie s jasným cílem - poskytnout maximální komfort bydlení a úsporu energie. Zapomeňte na topení - <b>poznejte Heated.</b>',
            mcore: 'Fotovoltaika s bateriovým úložištěm navržená tak, aby Váš dům využíval maximální potenciál vyrobené energie',
            lxn: 'Inteligence do Vašeho nového domu',
            allRightReserved: 'Všechna práva vyhrazena.',
            contact1: 'Čechy',
            contact2: 'Morava a slezsko'
        },
        gdpr: {
            title: 'Zásady ochrany osobních údajů',
            titleShort: 'Ochrana soukromí',
            titleShortcuts: 'GDPR',
            content: `
                <p>
              Tyto Zásady ochrany osobních údajů jsme vyhotovili s cílem
              seznámit Vás s tím, jak shromažďujeme, zpracováváme, používáme a
              chráníme osobní údaje. Při zabezpečení ochrany soukromí a osobních
              údajů postupujeme <b>výhradně v souladu s platnou legislativou</b>
              , tj. s Nařízením Evropského parlamentu a Rady (EU) 2016/679 ze
              dne 27. dubna 2016 o ochraně fyzických osob v souvislosti se
              zpracováním osobních údajů a o volném pohybu těchto údajů a o
              zrušení směrnice 95/46/ES (obecné nařízení o ochraně osobních
              údajů) (dále jen „Nařízení“) a dalšími právními předpisy tuto
              oblast upravující.
            </p>
            <h3 class="gdpr-heading">
              1. Kdo je správcem osobních údajů a jaké jsou kategorie subjektů
              osobních údaj, jejichž osobní údaje zpracováváme?
            </h3>

            <h4 class="gdpr-subheading">Správce osobních údajů:</h4>
            <ul class="gdpr-list">
              <li class="gdpr-list-item">
                Efektivní dům s.r.o., IČO: 27855252, se sídlem: Hlučínská 1177,
                Přívoz, 702 00 Ostrava, zapsaná v obchodním rejstříku u
                Krajského soudu v Ostravě, sp. zn. C 31815.
              </li>
            </ul>

            <h4 class="gdpr-subheading">Kategorie subjektů údajů:</h4>
            <ul class="gdpr-list">
              <li class="gdpr-list-item">
                zákazníci Správce, tj. osoby, které se Správcem uzavřeli smlouvu
                o poskytnutí služeb či zboží;
              </li>
              <li class="gdpr-list-item">
                zaměstnanci Správce, uchazeči o zaměstnání;
              </li>
              <li class="gdpr-list-item">
                dodavatelé služeb a osoby činné pro Správce na základě jiného
                smluvního vztahu.
              </li>
            </ul>
            <h3 class="gdpr-heading"></h3>

            <ul class="gdpr-list">
              <li class="gdpr-list-item"></li>
              <li class="gdpr-list-item"></li>
              <li class="gdpr-list-item"></li>
            </ul>
            <h3 class="gdpr-heading">
              2. Jaké osobní údaje o subjektech údajů zpracováváme?
            </h3>
            <p>
              O zákaznících Správce zpracováváme tyto osobní údaje: osobní
              údaje, které slouží k jednoznačné identifikaci subjektu údajů, tj.
              jméno, příjmení, datum narození, adresa trvalého pobytu; v případě
              že je zákazník fyzickou osobou podnikající dle zvláštních právních
              předpisů, zpracováváme dále i jeho obchodní firmu, IČO a DIČ. Dále
              za účelem komunikace se zákazníkem při odebírání zboží, potažmo
              při vyřizování objednávky zpracováváme jeho kontaktní údaje, jako
              je telefonní číslo a e-mailová adresa. Za účelem platebním dále
              můžeme zpracovávat číslo bankovního účtu zákazníka.
            </p>
            <p>
              O ostatních subjektech zpracováváme tyto osobní údaje:
              identifikační údaje – osobní údaje, které slouží k jednoznačné
              identifikaci subjektu údajů, tj. jméno, příjmení, titul, datum
              narození, adresa trvalého pobytu; u dodavatele – fyzické osoby
              podnikatele též obchodní firma, IČO a DIČ. Dále zpracováváme
              kontaktní údaje – osobní údaje, které umožňují kontakt se
              subjektem údajů v rámci plnění smluvních povinností (kontaktní
              adresa, emailová adresa, číslo telefonu, popř. platební údaje –
              číslo bankovního účtu).
            </p>
            <p>
              Další osobní údaje (nad rámec výše uvedeného), můžeme zpracovávat
              <b> se souhlasem</b> subjektu údajů za účelem např. marketingu.
            </p>
            <h3 class="gdpr-heading">
              3. Z jakých zdrojů osobní údaje získáváme?
            </h3>

            <p>
              Osobní údaje získáváme přímo od subjektu údajů, v rámci uzavírání
              smlouvy o poskytování služeb a dále při jednání o uzavření jiného
              smluvního vztahu (se zaměstnancem, při uzavírání smlouvy s
              dodavatelem apod.), příp. od třetích osob v souladu s Nařízením, a
              to především z veřejných zdrojů při respektování účelu zpracování
              osobních údajů.
            </p>
            <h3 class="gdpr-heading">
              4. Jakým způsobem a po jakou dobu osobní údaje zpracováváme?
            </h3>

            <p>
              Zpracování osobních údajů vykonává Správce. Zpracování probíhá v
              provozovně Správce pověřenými zaměstnanci Správce, případně
              zpracovateli. Ke zpracování osobních údajů dochází prostřednictvím
              výpočetní techniky, případně i manuálním způsobem v případě
              osobních údajů v listinné podobě, a to za dodržování všech
              bezpečnostních zásad pro správu a zpracování osobních údajů. Při
              zpracování osobních údajů nedochází k žádnému automatizovanému
              rozhodování (tedy bez ingerence člověka), včetně profilování.
              Osobní údaje jsou po celou dobu chráněny před neoprávněným
              zásahem, ztrátou, zničením či jejich zneužitím. Všechny osoby,
              které přicházejí s údaji do kontaktu, jsou vázány povinností
              mlčenlivosti, zejména naši zaměstnanci a zpracovatelé.
            </p>
            <p>
              Osobní údaje uchováváme po dobu plnění smluvního vztahu a dále po
              dobu nezbytně nutnou k zajištění všech práv a povinností
              plynoucích ze závazkového vztahu, tj. po dobu existence možných
              nároků vyplývajících z uzavřené smlouvy (zákonné promlčecí doby) a
              dále po dobu, kterou jsme povinni je uchovávat podle obecně
              závazných právních předpisů.{" "}
            </p>
            <h3 class="gdpr-heading">
              5. Na základě jakého právního titulu a za jakým účelem
              zpracováváme osobní údaje?
            </h3>

            <p>
              Osobní údaje zákazníků jsou zpracovávány za účelem plnění smlouvy
              o poskytování služeb či zboží, dle čl. 6 odst. 1 písm. b)
              Nařízení.
            </p>
            <p>
              V rámci jednání o uzavření smlouvy nebo pro plnění smlouvy s
              dalšími subjekty údajů (zaměstnanci, dodavatelé) jsou osobní údaje
              zpracovávány v souladu s čl. 6 odst. 1 písm. b{")"} Nařízení, tedy
              za účelem plnění této smlouvy.{" "}
            </p>
            <p>
              Osobní údaje mohou být dále zpracovávány za účelem ochrany
              oprávněných zájmů Správce v souladu s čl. 6 odst. 1 písm. f{")"}
              Nařízení, a to zejména vymáhání případných vzniklých pohledávek ze
              smluv uzavřených se subjektem údajů. Dále mohou být osobní údaje
              subjektů údajů zpracovávány k využití přímého marketingu (tj. k
              zasílání nabídek o produktech, informací o nových produktech), a
              to na základě souhlasu subjektu údajů dle čl. 6 odst. 1 písm. a
              {")"}
              Nařízení.
            </p>
            <p>
              Osobní údaje jsou zpracovávány ze strany Správce také za účelem
              plnění právních povinností v souladu s čl. 6 odst. 1, písm. c{")"}
              Nařízení, např. ke státním orgánům (např. správcům daně pro výkon
              správy daní, soudům, exekutorům, notářům), pro plnění zákonných
              povinností vyplývajících ze zvláštních předpisů.
            </p>
            <h3 class="gdpr-heading">
              6. Jaká má subjekt údajů zákonná práva při zpracování osobních
              údajů?
            </h3>

            <h4 class="gdpr-subheading">
              Právo na přístup k osobním údajům:
            </h4>
            <p>
              Subjekt údajů má právo získat od Správce potvrzení, zda zpracovává
              jeho osobní údaje, a pokud tomu tak je, má právo získat přístup k
              těmto osobním údajům a k informacím uvedeným v čl. 15 Nařízení.
            </p>
            <h4 class="gdpr-subheading">
              Právo na opravu nebo výmaz, popřípadě omezení zpracování:{" "}
            </h4>
            <p>
              Subjekt údajů má právo (v případech stanovených Nařízením) požádat
              Správce o opravu nebo doplnění nesprávných, resp. neúplných
              osobních údajů, požádat o výmaz osobních údajů, pokud odpadl nebo
              není dán důvod pro jejich zpracování, případně požádat o omezení
              zpracování osobních údajů v souvislosti s řešením okolností
              zpracování osobních údajů ze strany Správce.
            </p>
            <h4 class="gdpr-subheading">Právo vznést námitku: </h4>
            <p>
              Subjekt údajů má z důvodů týkajících se jeho konkrétní situace
              právo kdykoli vznést u Správce námitku proti zpracování jeho
              osobních údajů zpracovávaných pro účely oprávněných zájmů Správce
              nebo jiných osob (dle Nařízení); oprávněnými zájmy dle Nařízení
              můžou být zejména případy ochrany práv a právních nároků Správce.
            </p>
            <h4 class="gdpr-subheading">Právo na přenositelnost údajů: </h4>
            <p>
              Subjekt údajů má (za podmínek stanovených v Nařízení) právo získat
              své osobní údaje od Správce a předat je jinému správci osobních
              údajů.
            </p>
            <h4 class="gdpr-subheading">
              Právo podat stížnost u dozorového úřadu:{" "}
            </h4>
            <p>
              Subjekt údajů má právo podat stížnost u dozorového úřadu, pokud se
              domnívá, že zpracováním jeho osobních údajů bylo porušeno
              Nařízení. Tímto dozorovým úřadem je Úřad pro ochranu osobních
              údajů se sídlem v Praze.
            </p>
            <p>
              <b>
                Jestliže je právo zpracovávat osobní údaje založeno na udělení
                souhlasu ke zpracování osobních údajů, může tento souhlas
                subjekt údajů kdykoliv odvolat;
              </b>{" "}
              jedná se např. o marketingový souhlas. Odvoláním tohoto souhlasu
              však není dotčena zákonnost zpracování vycházejícího ze souhlasu,
              který byl dán před jeho odvoláním.{" "}
            </p>
            <p>
              K realizaci všech svých práv může subjekt údajů využít kontaktní
              údaje uvedené níže v bodě 7 těchto Zásad. Správce bez zbytečného
              odkladu informujeme subjekt údajů o vyřízení jeho žádosti a o
              přijatých opatřeních (Nařízení k tomu Správci dává lhůtu 1 měsíce
              od obdržení žádosti).
            </p>
            <h3 class="gdpr-heading">
              7. Kde může uplatnit subjekt údajů svá práva či případné námitky
              ke zpracování osobních údajů?
            </h3>
            <p>
              Uplatnit případné námitky proti zpracování osobních údajů, odvolat
              udělený souhlas či změnit jeho rozsah, či uplatnit jakékoliv své
              právo může subjekt údajů realizovat některým z těchto způsobů:
            </p>
            <ul class="gdpr-list">
              <li class="gdpr-list-item">
                písemně na adrese Hlučínská 1177, Přívoz, 702 00 Ostrava
              </li>
              <li class="gdpr-list-item">
                e-mailovou zprávou na info@heated.cz
              </li>
            </ul>
            <p>
              Těmito výše uvedenými cestami může subjekt údajů Správce
              kontaktovat s jakoukoliv žádostí či dotazem týkajícím se
              zpracování osobních údajů.
            </p>
            <h3 class="gdpr-heading">8. Komu poskytujeme osobní údaje?</h3>
            <p>
              Osobní údaje jsou zpracovávány prostřednictvím zaměstnanců Správce
              nebo Správcem pověřených zpracovatelů na základě příslušné smlouvy
              v souladu s čl. 28 Nařízení. Ve všech případech však Správce dbá
              na to, aby byly zachovány všechny povinnosti, které Správci a jeho
              zpracovatelům vyplývají z platné legislativy a při předání dbá,
              aby nedošlo k ohrožení bezpečnosti předaných osobních údajů nebo k
              jejich zneužití.
            </p>
            <p>Zpracovateli/příjemci osobních údajů jsou zejména:</p>
            <ul class="gdpr-list">
              <li class="gdpr-list-item detached">
                státní orgány a další instituce v rámci zákonných povinností,
                zejména orgány státní správy, soudy, správce daně, orgány činné
                v trestním řízení, orgány sociálního zabezpečení, exekutoři,
                notáři, Česká asociace pojišťoven, pojišťovny;
              </li>
              <li class="gdpr-list-item detached">
                externí subjekty, které poskytují Správci služby, jedná se
                zejména o: <br />
                <ul class="gdpr-list">
                  <li class="gdpr-list-item">
                    subjekt spravující informační systém Správce
                  </li>
                  <li class="gdpr-list-item">
                    externí účetní, daňoví poradci, auditoři
                  </li>
                  <li class="gdpr-list-item">
                    subjekt poskytující služby v oblasti hlídání areálu Správce
                    na adrese sídla Správce
                  </li>
                  <li class="gdpr-list-item">přepravci, dopravci</li>
                  <li class="gdpr-list-item">
                    subjekty poskytující služby v oblasti bezpečnosti a ochrany
                    zdraví při práci, požární ochrany
                  </li>
                  <li class="gdpr-list-item">
                    subjekty poskytující služby v oblasti školení, vzdělávání
                  </li>
                </ul>
              </li>
              <li class="gdpr-list-item detached">
                s předchozím souhlasem nebo na příkaz subjektu údajů mohou být
                osobní údaje poskytnuty dalším subjektům;
              </li>
            </ul>
            <h3 class="gdpr-heading">
              9. Co znamenají pojmy obsažené v těchto Zásadách?
            </h3>
            <p>
              <b>Osobní údaj</b> - jakákoliv informace týkající se určeného nebo
              určitelného subjektu údajů; subjekt údajů se považuje za určený
              nebo určitelný, pokud lze subjekt údajů přímo či nepřímo
              identifikovat;
            </p>
            <p>
              <b>Subjekt údajů</b> – fyzická osoba, jehož osobní údaje jsou
              Správcem zpracovávány;
            </p>
            <p>
              <b>Správce</b> – subjekt, který určuje účel a prostředky
              zpracování osobních údajů, provádí zpracování a odpovídá za něj;
            </p>
            <p>
              <b>Zpracovatel</b> – každý subjekt, který na základě zvláštního
              zákona nebo pověření správcem zpracovává osobní údaje;
            </p>
            <p>
              <b>Příjemce</b> – každý subjekt, kterému jsou osobní údaje
              zpřístupněny;
            </p>
            <p>
              <b>Zpracování</b> – jakákoliv operace nebo soustavy operací, které
              správce nebo zpracovatel systematicky provádějí s osobními údaji,
              a to automatizovaně nebo jinými prostředky; zejména se jedná o
              shromažďování, ukládání na nosiče informací, zpřístupňování,
              úprava nebo pozměňování, vyhledávání, používání, předávání,
              šíření, zveřejňování, uchovávání, výměna, třídění nebo
              kombinování, blokování, likvidace.
            </p>
            <p>
              <b>
                Tyto Zásady ochrany osobních jsou platné a účinné ke dni
                25.5.2018. Aktuální platné znění těchto zásad je uveřejněno na
                našich webových stránkách www.heated.cz nebo je k dispozici v
                sídle naší společnosti na adrese Hlučínská 1177, Přívoz, 702 00
                Ostrava.
              </b>
            </p>
            `
        }
    }
}
