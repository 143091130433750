import * as React from "react";
import config from "../../../../config/config.json";

class Loader extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {

    const { showLoader, loadingStatus, loader_loaded } = this.props;

    return (
      <div className="loader-wrapper">
        <div className={showLoader ? 'animation-loader' : 'animation-loader is-loaded'} style={{ zIndex: 1000 }}>
        <img className={showLoader ? "loader-svg" : "loader-svg is-loaded"} src={config.paths.backgrounds + 'loader_logo.svg'} alt="Loader logo" />
          <span
            className={
              loadingStatus < 90 ? "text" : "text is-loaded"
            }
          >
            {loadingStatus}
            <small>%</small>
          </span>
          <div
            className={
              (showLoader
                ? "orange-loader"
                : "orange-loader is-loaded") +
              " " +
              // (loaderColor ? "black" : "") +
              " " +
              (loader_loaded ? "orange" : "")
            }
          >
            <div
              className="loader-fill"
              style={{ width: loadingStatus + "%" }}
            ></div>
          </div> 
        </div>
      </div>)
  }
}

export default Loader;