import * as React from "react";
import { getHTMLContent, localConsoleLog } from "../functions";
import config from "../../../../config/config.json";

import i18n from '../i18n';

class FormItemUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file_set: ""
    };

    this.getClassName = this.getClassName.bind(this);
    this.changeFormItemHandler = this.changeFormItemHandler.bind(this);
    // this.handleDelete = this.handleDelete.bind(this);
    this.inputClick = this.inputClick.bind(this);
  }

  getClassName() {
    let result = "form-item";

    if (this.props.value.name) {
      result = result + " " + "is-active";
    }

    if (this.props.error) {
      result = result + " " + "error";
    }

    return result;
  }

  parseFileSize(size) {
    let result = size;
    let unit = "B";

    if (size > 999 && size < 1000000) {
      result = size / 1000;
      unit = "KB";
    } else if (size > 999999) {
      result = size / 1000000;
      unit = "MB";
    }

    return Math.floor(result) + " " + unit;
  }

  changeFormItemHandler(event) {
    const dataFormItem = event.currentTarget.attributes.getNamedItem(
      "data-form-item"
    ).value;
    const files = Array.from(event.target.files);

    if (this.props.value.length < 5) {
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        if (file && file.size < 10000000) {
          const reader = new FileReader();
          this.setState({ file_set: "active" });
          reader.readAsDataURL(file);

          reader.onload = () => {
            this.props.actions.changeFormUploadItem(dataFormItem, {
              name: file.name,
              size: file.size,
              data: reader.result
            });
          };

          reader.onerror = error => {
            localConsoleLog("Error", error);
          };
        } else {
          this.props.actions.changeFormUploadItem("big", {
            name: "",
            size: 0,
            data: ""
          });
        }
      }
    }
  }
  inputClick(e) {
    if (this.state.file_set == "active") {
      if (e.target.getAttribute("data-index")) {
        e.preventDefault();
        const index = e.target.getAttribute("data-index");
        this.props.actions.changeFormUploadItem("delete", index);
      }
    }
  }

  render() {
    return (
      <div className={this.getClassName()}>
        <div className="input-drop-file">
          <label
            className={this.props.value.length > 0 ? "active" : ""}
            onClick={this.inputClick}
          >
            <div className={"icon " + this.props.icon}>
              <img src={config.paths.icons + "ic_pdf.svg"} alt="PDF" />
            </div>
            {this.props.value.length > 0 ? (
              <div className="file-input-text">
                <ul className="file-input-list">
                  {this.props.value.map((value, index) => {
                    return (
                      <li key={index} className="file-input-list-item">
                        <div className="file-name">{value.name}</div>
                        <div
                          onClick={this.handleDelete}
                          className="file-delete"
                          data-index={index}
                        >
                          {i18n.t('general.remove')}
                        </div>
                      </li>
                    );
                  })}
                  <li className="file-input-list-item last">
                    <div className="legend">
                      .PDF, .JPG, .PNG, .DOC, .XLS, .DWG
                      <br />
                      {i18n.t('general.max5files')}
                    </div>
                  </li>
                </ul>
              </div>
            ) : (
              <div
                className="file-input-text"
                dangerouslySetInnerHTML={getHTMLContent(this.props.text)}
              />
            )}

            {/* {this.props.value.name ? (
              <div className="file-input-text">
                <span className="file-name">{this.props.value.name}</span>
              </div>
            ) : (
              <div
                className="file-input-text"
                dangerouslySetInnerHTML={getHTMLContent(this.props.text)}
              />
            )} */}
            <input
              type="file"
              name={this.props.dataFormItem}
              data-form-item={this.props.dataFormItem}
              onChange={this.changeFormItemHandler}
              className="files-field"
              multiple
            />
          </label>
          {this.props.error && (
            <span className="error">{this.props.error}</span>
          )}
        </div>
      </div>
    );
  }
}

export { FormItemUpload };
