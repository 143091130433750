import * as React from "react";
import config from "../../../../config/config.json";
import { Menu } from "../components/Menu";
import { Link } from "react-router-dom";
import { Navigation } from "../components/Navigation";
import { getExample } from "../api";
import Slider, { createSliderWithTooltip } from "rc-slider";
import "../../../../node_modules/rc-slider/assets/index.css";
import { FormItemUpload } from "../components/FormItemUpload.jsx";
import { postOffers, getConfig } from "../api.js";
import CountUp, { startAnimation } from "react-countup";
import { Mediabuffer } from "../mediabuffer.js";
// import WheelReact from "wheel-react";
// import smoothscroll from "smoothscroll-polyfill";
import scrollIntoView from "scroll-into-view-if-needed";
import { CircleGraph } from "../components/CircleGraph.jsx";
import smoothScrollIntoView from "smooth-scroll-into-view-if-needed";
import {
    disableBodyScroll,
    enableBodyScroll,
    clearAllBodyScrollLocks
} from "body-scroll-lock";
import { localConsoleLog, numberWithSpaces } from "../functions.js";
import { NProgress } from "@tanem/react-nprogress";
// import intro from "../../videos/intro.mp4";

import i18n from '../i18n'

import * as Scroll from "react-scroll";
import {
    Element,
    Events,
    animateScroll as scroll,
    scrollSpy,
    scroller
} from "react-scroll";
import {getPageSEO} from "../functions";
import i18next from "../i18n";

class Home extends React.Component {
    constructor(props) {
        super(props);

        this.pageSEO = getPageSEO();

        document.title = this.pageSEO.title;
        document.querySelector('meta[name=\"keywords\"]').setAttribute('content', this.pageSEO.keywords);
        document.querySelector('meta[name=\"description\"]').setAttribute('content', this.pageSEO.description);
        document.querySelector('meta[name=\"title\"]').setAttribute('content', this.pageSEO.metaTitle);

        // smoothscroll.polyfill();
        // this.setAsyncData = this.setAsyncData.bind(this);

        this.state = {
            introVideo: {
                progress: 0,
            },
            selected_section: 'section_1',
            next_section: 'section_2',
            can_continue: false,
            steps: true,
            scroll_display: { display: 'none', opacity: 0 },
            slider_value: 120,
            conf_slider_value: 200,
            conf_slider_value_roznos: 50,
            conf_slider_value_final: 5,
            conf_slider_value_total: 30,
            shift_left: '',
            shift_right: '',
            area_value: 120,
            vyska: 22,
            conf_slider: 0,
            config_area: 0,
            config_buildings: 0,
            furthers_conf_slider: 0,
            conf: 1,
            max_conf: 1,
            izolace: 0,
            roznos: 0,
            final: 3,
            izolace_hover: 0,
            roznos_hover: 0,
            final_hover: 3,
            prev_conf: 1,
            slider_disabled: true,
            spol_logos: null,
            didViewCountUp: false,
            video_loaded: false,
            intro_playing: false,
            show_play_btn: false,
            porovnani: false,
            intelligent_solution_right: true,
            intelligent_solution_left: true,
            sent: false,
            name: '',
            city: '',
            email: '@',
            phase: '',
            sources: '',
            submitted: '',
            valid_email: '',
            valid_phone: '',
            file_input: { display: 'none' },
            slider_visibility: { visibility: 'hidden' },
            select_empty: '',
            formItems: {
                podklady: {
                    value: '',
                    error: '',
                },
            },
            spolupracujeme: '',
            duration: 24,
            video_muted: 'ic_sound_off',
            submit_title: '',
            video_ended: false,
            video_clicked: false,
            show_controls: false,
            loader_loaded: false,
            hp_loaded: false,
            disable_scroll: true,
            missing_files: false,
            showLoader: false,
            error_message: i18n.t('general.weWillNeed'),
            scaleThumb: 'scale-thumb',
            form_response: i18n.t('general.takeCareASAP'),
            startX: undefined,
            endX: undefined,
            startTime: undefined,
            endTime: undefined,
            startY: undefined,
            endY: undefined,
        };
        this.scrollToSection = this.scrollToSection.bind(this);
        this.onScroll = this.onScroll.bind(this);
        this.sliderHandle = this.sliderHandle.bind(this);
        this.areaHandle = this.areaHandle.bind(this);
        this.changeFormUploadItem = this.changeFormUploadItem.bind(this);

        this.onTouchStart = this.onTouchStart.bind(this);
        this.onTouchEnd = this.onTouchEnd.bind(this);
        this.onTouchMove = this.onTouchMove.bind(this);

        this.handleChange = this.handleChange.bind(this);
        this.shiftLeft = this.shiftLeft.bind(this);
        this.shiftRight = this.shiftRight.bind(this);
        this.startConf = this.startConf.bind(this);
        this.confSliderHandle = this.confSliderHandle.bind(this);
        this.confSliderHandleRoznos = this.confSliderHandleRoznos.bind(this);
        this.confSliderHandleFinal = this.confSliderHandleFinal.bind(this);
        this.confSliderHandleTotal = this.confSliderHandleTotal.bind(this);
        this.izolaceClick = this.izolaceClick.bind(this);
        this.roznosClick = this.roznosClick.bind(this);
        this.finalClick = this.finalClick.bind(this);
        this.isMacintosh = this.isMacintosh.bind(this);
        this.detectMouseWheelDirection = this.detectMouseWheelDirection.bind(this);

        this.videoLoaded = this.videoLoaded.bind(this);
        this.playIntro = this.playIntro.bind(this);

        this.finalOn = this.finalOn.bind(this);
        this.finalOut = this.finalOut.bind(this);

        this.izolaceOn = this.izolaceOn.bind(this);
        this.izolaceOut = this.izolaceOut.bind(this);

        this.roznosOn = this.roznosOn.bind(this);
        this.roznosOut = this.roznosOut.bind(this);

        this.stepBack = this.stepBack.bind(this);
        this.toStep = this.toStep.bind(this);
        this.duringStep = this.duringStep.bind(this);

        this.markAcquired = this.markAcquired.bind(this);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.posted = this.posted.bind(this);

        this.spolClick = this.spolClick.bind(this);
        this.spolCollapse = this.spolCollapse.bind(this);

        this.preventEnter = this.preventEnter.bind(this);

        this.ready = this.ready.bind(this);
        this.introVideoEnded = this.introVideoEnded.bind(this);
        this.videoPlay = this.videoPlay.bind(this);

        this.muteVideo = this.muteVideo.bind(this);
        this.skipIntro = this.skipIntro.bind(this);

        this.loaderLoaded = this.loaderLoaded.bind(this);

        this.setConfig = this.setConfig.bind(this);

        this.section1 = React.createRef();
        this.section2 = React.createRef();
        this.section3 = React.createRef();
        this.section4 = React.createRef();
        this.section5 = React.createRef();
        this.section6 = React.createRef();
        this.section7 = React.createRef();

        this.isSafari =
            /constructor/i.test(window.HTMLElement) ||
            (function(p) {
                return p.toString() === "[object SafariRemoteNotification]";
            })(
                !window["safari"] ||
                (typeof safari !== "undefined" && safari.pushNotification)
            );

        // if (this.state.can_continue && this.state.steps) {
        //   if (this.isMacintosh()) {
        //     WheelReact.config({
        //       up: () => {
        //         this.scrollToSection("next");
        //       },
        //       down: () => {
        //         this.scrollToSection("prev");
        //       }
        //     });
        //   } else {
        //     WheelReact.config({
        //       down: () => {
        //         this.scrollToSection("next");
        //       },
        //       up: () => {
        //         this.scrollToSection("prev");
        //       }
        //     });
        //   }
        // }

        this.izolace_names = [i18n.t('home.whitePolystyrene'), i18n.t('home.pir'), i18n.t('home.wool'), i18n.t('home.grayPolystyrene')];
    this.roznos_names = [i18n.t('home.cementScreedNoBr'), i18n.t('home.anhydrit'), i18n.t('home.gypsumFiberBoardsNoBr')];
    this.final_names = [
        i18n.t('home.polishedConcreteNoBr'),
        i18n.t('home.laminatedFloorNoBr'),
        i18n.t('home.pvc'),
        i18n.t('home.pavement'),
        i18n.t('home.solidWood'),
    ];

        this.izolace_tloustka_values = [
            [50, 300, 10],
            [30, 200, 10],
            [20, 100, 10],
            [50, 250, 10]
        ];
        this.roznos_tloustka_values = [[50, 80, 5], [40, 60, 5], [25, 50, 25]];
        this.final_tloustka_values = [
            [5, 10, 1],
            [5, 10, 1],
            [5, 10, 1],
            [5, 10, 1],
            [5, 20, 1]
        ];

        this.izolace_tloustka_recommended = [200, 130, 50, 150];
        this.roznos_tloustka_recommended = [50, 45, 25];
        this.final_tloustka_recommended = [5, 5, 5, 5, 10];

        this.izolace_desc = [
            <div className="section-info">
                <div className="section-line">
                    <img
                        className="conf-icon"
                        src={config.paths.icons + "ic_conf_info.svg"}
                        alt={i18n.t('general.weRecommend')}
                    />{" "}
                    {i18n.t('general.recommendThick')}{" "}
                    <span className="orange">
            {this.izolace_tloustka_recommended[0]} mm
          </span>
                </div>
                <div className="section-line">
                    <img
                        className="conf-icon"
                        src={config.paths.icons + "ic_conf_plus.svg"}
                        alt={i18n.t('general.pros')}
                    />{" "}
                    {i18n.t('general.goodIsolationLowPrice')}
                </div>
                <div className="section-line">
                    <img
                        className="conf-icon"
                        src={config.paths.icons + "ic_conf_minus.svg"}
                        alt={i18n.t('general.cons')}
                    />{" "}
                    {i18n.t('general.greaterDemandHeight')}
                </div>
            </div>,
            <div className="section-info">
                <div className="section-line">
                    <img
                        className="conf-icon"
                        src={config.paths.icons + "ic_conf_info.svg"}
                        alt={i18n.t('general.weRecommend')}
                    />{" "}
                    {i18n.t('general.recommendThick')}{" "}
                    <span className="orange">
            {this.izolace_tloustka_recommended[1]} mm
          </span>
                </div>
                <div className="section-line">
                    <img
                        className="conf-icon"
                        src={config.paths.icons + "ic_conf_plus.svg"}
                        alt={i18n.t('general.pros')}
                    />{" "}
                    {i18n.t('general.beterIsolationLowerDemand')}
                </div>
                <div className="section-line">
                    <img
                        className="conf-icon"
                        src={config.paths.icons + "ic_conf_minus.svg"}
                        alt={i18n.t('general.cons')}
                    />{" "}
                    {i18n.t('general.heigherPrice')}
                </div>
            </div>,
            <div className="section-info">
                <div className="section-line">
                    <img
                        className="conf-icon"
                        src={config.paths.icons + "ic_conf_info.svg"}
                        alt={i18n.t('general.weRecommend')}
                    />{" "}
                    {i18n.t('general.mezzanineInsulation')}
                </div>

                <div className="section-line">
                    <img
                        className="conf-icon"
                        src={config.paths.icons + "ic_conf_minus.svg"}
                        alt={i18n.t('general.cons')}
                    />{" "}
                    {i18n.t('general.weDontRecommend')}
                </div>
            </div>,
            <div className="section-info">
                <div className="section-line">
                    <img
                        className="conf-icon"
                        src={config.paths.icons + "ic_conf_info.svg"}
                        alt={i18n.t('general.weRecommend')}
                    />{" "}
                    {i18n.t('general.recommendThick')}{" "}
                    <span className="orange">
            {this.izolace_tloustka_recommended[3]} mm
          </span>
                </div>
                <div className="section-line">
                    <img
                        className="conf-icon"
                        src={config.paths.icons + "ic_conf_plus.svg"}
                        alt={i18n.t('general.pros')}
                    />{" "}
                    {i18n.t('general.betterIsolationEPS')}
                </div>
                <div className="section-line">
                    <img
                        className="conf-icon"
                        src={config.paths.icons + "ic_conf_minus.svg"}
                        alt={i18n.t('general.cons')}
                    />{" "}
                    {i18n.t('general.heigherPrice')}
                </div>
            </div>
        ];
        this.izolace_tloustka = [true, true, true, true];

        this.roznos_desc = [
            <div className="section-info">
                <div className="section-line">
                    <img
                        className="conf-icon"
                        src={config.paths.icons + "ic_conf_info.svg"}
                        alt={i18n.t('general.weRecommend')}
                    />{" "}
                    {i18n.t('general.recommendThick')}{" "}
                    <span className="orange">
            {this.roznos_tloustka_recommended[0]} mm
          </span>
                </div>
                <div className="section-line">
                    <img
                        className="conf-icon"
                        src={config.paths.icons + "ic_conf_plus.svg"}
                        alt={i18n.t('general.pros')}
                    />{" "}
                    {i18n.t('general.keepsFloorWarmer')}
                </div>
                <div className="section-line">
                    <img
                        className="conf-icon"
                        src={config.paths.icons + "ic_conf_minus.svg"}
                        alt={i18n.t('general.cons')}
                    />{" "}
                    {i18n.t('general.lowerHeatCapacity')}
                </div>
            </div>,
            <div className="section-info">
                <div className="section-line">
                    <img
                        className="conf-icon"
                        src={config.paths.icons + "ic_conf_info.svg"}
                        alt={i18n.t('general.weRecommend')}
                    />{" "}
                    {i18n.t('general.recommendThick')}{" "}
                    <span className="orange">
            {this.roznos_tloustka_recommended[1]} mm
          </span>
                </div>
                <div className="section-line">
                    <img
                        className="conf-icon"
                        src={config.paths.icons + "ic_conf_plus.svg"}
                        alt={i18n.t('general.pros')}
                    />{" "}
                    {i18n.t('general.heigherHeatCapacity')}
                </div>
                <div className="section-line">
                    <img
                        className="conf-icon"
                        src={config.paths.icons + "ic_conf_minus.svg"}
                        alt={i18n.t('general.cons')}
                    />{" "}
                    {i18n.t('general.lowerInertiaConcreat')}
                </div>
            </div>,
            <div className="section-info">
                <div className="section-line">
                    <img
                        className="conf-icon"
                        src={config.paths.icons + "ic_conf_info.svg"}
                        alt={i18n.t('general.weRecommend')}
                    />{" "}
                    {i18n.t('general.recommendThick')}{" "}
                    <span className="orange">
            min. {this.roznos_tloustka_recommended[2]} mm
          </span>
                </div>
                <div className="section-line">
                    <img
                        className="conf-icon"
                        src={config.paths.icons + "ic_conf_plus.svg"}
                        alt={i18n.t('general.pros')}
                    />{" "}
                    {i18n.t('general.dryProcess')}
                </div>
                <div className="section-line">
                    <img
                        className="conf-icon"
                        src={config.paths.icons + "ic_conf_minus.svg"}
                        alt={i18n.t('general.cons')}
                    />{" "}
                    {i18n.t('general.lowerInertiaFry')}
                </div>
            </div>
        ];
        this.roznos_tloustka = [true, true, true];

        this.final_desc = [
            <div className="section-info">
                <div className="section-line">
                    <img
                        className="conf-icon"
                        src={config.paths.icons + "ic_conf_plus.svg"}
                        alt={i18n.t('general.pros')}
                    />{" "}
                    {i18n.t('general.heighVariability')}
                </div>
                <div className="section-line">
                    <img
                        className="conf-icon"
                        src={config.paths.icons + "ic_conf_minus.svg"}
                        alt={i18n.t('general.cons')}
                    />{" "}
                    {i18n.t('general.industrialSpaces')}
                </div>
            </div>,
            <div className="section-info">
                <div className="section-line">
                    <img
                        className="conf-icon"
                        src={config.paths.icons + "ic_conf_plus.svg"}
                        alt={i18n.t('general.pros')}
                    />{" "}
                    {i18n.t('general.heigSelectionLowPrice')}
                </div>
                <div className="section-line">
                    <img
                        className="conf-icon"
                        src={config.paths.icons + "ic_conf_minus.svg"}
                        alt={i18n.t('general.cons')}
                    />{" "}
                    {i18n.t('general.lowServiceLife')}
                </div>
            </div>,
            <div className="section-info">
                <div className="section-line">
                    <img
                        className="conf-icon"
                        src={config.paths.icons + "ic_conf_plus.svg"}
                        alt={i18n.t('general.pros')}
                    />{" "}
                    {i18n.t('general.heigSelectionLowPrice')}
                </div>
                <div className="section-line">
                    <img
                        className="conf-icon"
                        src={config.paths.icons + "ic_conf_minus.svg"}
                        alt={i18n.t('general.cons')}
                    />{" "}
                    {i18n.t('general.softLayer')}
                </div>
            </div>,
            <div className="section-info">
                <div className="section-line">
                    <img
                        className="conf-icon"
                        src={config.paths.icons + "ic_conf_plus.svg"}
                        alt={i18n.t('general.pros')}
                    />{" "}
                    {i18n.t('general.heighSelectionHeighCapacity')}
                </div>
                <div className="section-line">
                    <img
                        className="conf-icon"
                        src={config.paths.icons + "ic_conf_minus.svg"}
                        alt={i18n.t('general.cons')}
                    />{" "}
                    {i18n.t('general.heighPricePavement')}
                </div>
            </div>,
            <div className="section-info">
                <div className="section-line">
                    <img
                        className="conf-icon"
                        src={config.paths.icons + "ic_conf_plus.svg"}
                        alt={i18n.t('general.pros')}
                    />{" "}
                    {i18n.t('general.heatMaterialServiceLife')}
                </div>
                <div className="section-line">
                    <img
                        className="conf-icon"
                        src={config.paths.icons + "ic_conf_minus.svg"}
                        alt={i18n.t('general.cons')}
                    />{" "}
                    {i18n.t('general.lowCapacityHeighPrice')}
                </div>
            </div>
        ];
        this.final_tloustka = [true, true, true, true, true];
        this.body = null;
        this.marks = {
            0: (
                <div className="conf-slider-label conf-slider-1" dangerouslySetInnerHTML={{ __html: i18n.t('home.floorIsolatonBr') }}></div>
            ),
            25: (
                <div className="conf-slider-label conf-slider-2">{i18n.t('general.spreadingLayer')}</div>
            ),
            50: <div className="conf-slider-label conf-slider-3">{i18n.t('general.finalLayer')}</div>,
            75: <div className="conf-slider-label conf-slider-4">{i18n.t('general.conclusion')}</div>,
            100: <div className="conf-slider-label conf-slider-5">{i18n.t('general.evaluation')}</div>
        };

        this.scrolling = true;
    }

    onTouchStart(e) {
        this.setState({
            startX: e.touches[0].clientX,
            startY: e.touches[0].clientY,
            startTime: Date.now(),
        });
    }

    onTouchEnd(e) {
        if (this.state.startX !== undefined && this.state.startTime !== undefined && this.state.endX !== undefined && this.state.endTime !== undefined && this.state.startY !== undefined && this.state.endY !== undefined) {
            const distanceGreaterThanLimit = (Math.abs(this.state.endY - this.state.startY) > 225);
            const timeLessThanLimit = (Math.abs(this.state.endTime - this.state.startTime) <= 250);
            const distanceLowerThanLimit = (Math.abs(this.state.startX - this.state.endX) < 100);

            if (distanceGreaterThanLimit && timeLessThanLimit && distanceLowerThanLimit) {
                if (this.state.startY < this.state.endY) {
                    // up
                    this.scrollToSection("prev");
                } else {
                    // down
                    this.scrollToSection("next");
                }
            }
        }

        this.setState({
            startX: undefined,
            endX: undefined,
            startTime: undefined,
            endTime: undefined,
            startY: undefined,
            endY: undefined,
        });
    }

    onTouchMove(e) {
        if (this.state.startX !== undefined && this.state.startTime !== undefined && this.state.startY !== undefined) {
            this.setState({
                endX: e.touches[0].clientX,
                endY: e.touches[0].clientY,
                endTime: Date.now(),
            });
        }
    }

    componentDidMount() {

        this.body = document.querySelector("body");

        window.addEventListener("keydown", this.preventEnter);
        window.addEventListener("touchstart", this.onTouchStart);
        window.addEventListener("touchend", this.onTouchEnd);
        window.addEventListener("touchmove", this.onTouchMove);

        document.body.style.overflowY = "hidden";
        // disableBodyScroll(this.body);
        if (!this.props.introPlay) {

            const video = document.querySelector("video");

            const interval = setInterval(() => {
                if (this.state.introVideo.progress < 100) {
                    this.setState({
                        introVideo: {
                            progress: this.state.introVideo.progress + 1
                        }
                    });
                } else {
                    clearInterval(interval);
                    this.videoLoaded();
                }
            }, 10);

            video.addEventListener("ended", event => {
                this.introVideoEnded();
            });
        }
        setTimeout(() => {
            var scroll_check = this.section1.current.getBoundingClientRect().top;

            if (scroll_check != 0) {
                this.setState({
                    // video_ended: true,
                    // show_controls: false,
                    disable_scroll: false,
                    steps: false,
                    can_continue: true
                });
                document.body.style.overflowY = "scroll";
                // this.skipIntro();
                setTimeout(() => {
                    this.setState({ scroll_display: { display: "block", opacity: 1 } });
                }, 100);
            }
        }, 1400);
        // if (scroll_check != 0) {
        //   this.setState({
        //     video_ended: true,
        //     show_controls: false,
        //     can_continue: true
        //   });
        //   setTimeout(() => {
        //     this.setState({ scroll_display: { display: "block", opacity: 1 } });
        //   }, 10);
        // }
        // video.addEventListener("durationchange", event => {
        //   this.setState({ duration: video.duration });
        // });
        // var intro_video = new Mediabuffer(video, this.progressShow, this.ready);
        // intro_video.load();
        window.addEventListener("scroll", this.onScroll, { passive: false });
        if (window.addEventListener) {
            window.addEventListener(
                "DOMMouseScroll",
                this.detectMouseWheelDirection,
                {
                    passive: false
                }
            );
        }
        window.addEventListener("wheel", this.detectMouseWheelDirection, {
            passive: false
        });
        if (this.props.location.state) {
            const section_id = this.props.location.state.section;
            const scroll_to_section = "section_" + section_id + "_link";
            setTimeout(() => {
                this.scrollToSection(scroll_to_section);
            }, 100);
        }
        this.setState({ showLoader: true });
        if (this.props.introPlay) {
            this.skipIntro();
            this.setState({
                can_continue: true,
                video_ended: true,
                videoLoaded: true,
                showLoader: false
            });
        }

        getConfig.request(this.setConfig);
    }
    setConfig(response) {
        this.setState({
            config_area: response.data.area_sum,
            config_buildings: response.data.building_sum
        });
    }
    preventEnter(e) {
        if (e.keyCode == 13) {
            e.preventDefault();
            return false;
        }
    }
    progressShow(percentBuffered) {
        // console.log("Buffering: " + percentBuffered + "%");
    }
    ready() {
        this.refs.intro.play();
    }

    componentWillUnmount() {
        document.body.style.overflowY = "scroll";
        clearAllBodyScrollLocks();
        window.removeEventListener("scroll", this.onScroll);
        window.removeEventListener("wheel", this.detectMouseWheelDirection);
        window.removeEventListener(
            "DOMMouseScroll",
            this.detectMouseWheelDirection
        );
        // WheelReact.clearTimeout();
        window.removeEventListener("touchstart", this.onTouchStart);
        window.removeEventListener("touchend", this.onTouchEnd);
        window.removeEventListener("touchmove", this.onTouchMove);
    }
    confSliderHandle(value) {
        this.setState({ conf_slider_value: value });
    }
    confSliderHandleRoznos(value) {
        this.setState({ conf_slider_value_roznos: value });
    }
    confSliderHandleFinal(value) {
        this.setState({ conf_slider_value_final: value });
    }
    confSliderHandleTotal(value) {
        this.setState({ conf_slider_value_total: value });
    }

    detectMouseWheelDirection(e) {
        if (e.deltaX != 0) {
            return;
        }
        var scroll_check = this.section1.current.getBoundingClientRect().top;

        if (!this.state.video_ended && scroll_check != 0) {
            this.setState({
                // video_ended: true,
                // show_controls: false,
                disable_scroll: false,
                steps: false,
                can_continue: true
            });
            document.body.style.overflowY = "scroll";
            setTimeout(() => {
                this.setState({ scroll_display: { display: "block", opacity: 1 } });
            }, 100);
        }
        if (this.state.disable_scroll) {
            // && !this.scrolling
            e.preventDefault();
            // this.scrolling = true;
            // setTimeout(()=> {
            //   this.scrolling = true
            // }, 1500)
        }

        var delta = null;
        var direction = false;
        if (!e) {
            // if the event is not provided, we get it from the window object
            e = window.event;
        }
        if (e.wheelDelta) {
            // will work in most cases
            delta = e.wheelDelta / 60;
        } else if (e.detail) {
            // fallback for Firefox
            delta = -e.detail / 2;
        }

        if (delta !== null) {
            direction =
                delta > 0 ? this.scrollToSection("prev") : this.scrollToSection("next");
        } else {
            delta = e.deltaY;
            direction =
                delta < 0 ? this.scrollToSection("prev") : this.scrollToSection("next");
        }

        // return direction;
        // if
    }

    isMacintosh() {
        return navigator.platform.indexOf("Mac") > -1;
    }

    changeFormUploadItem(name, file) {
        if (name == "delete") {
            var temp = this.state.sources;
            temp.splice(file, 1);

            this.setState({
                sources: temp,
                formItems: {
                    podklady: {
                        value: temp,
                        error: ""
                    }
                }
            });
        } else {
            if (this.state[name]) {
                var temp = this.state[name];
                temp.push(file);

                this.setState({
                    [name]: temp,
                    formItems: {
                        podklady: {
                            value: temp,
                            error: ""
                        }
                    }
                });
            } else {
                this.setState({
                    [name]: [file],
                    formItems: {
                        podklady: {
                            value: [file],
                            error: ""
                        }
                    }
                });
            }
        }
    }

    //   setAsyncData(data) {
    //     this.setState({
    //       list: data
    //     });
    //   }

    //   getListItems(data) {
    //     let result = [];

    //     for (let i = 0; i < data.length; i++) {
    //       result.push(<li key={i}>{data[i]}</li>);
    //     }

    //     return result;
    //   }
    onScroll(e) {
        if(this.state.selected_section == "section_5"){
            this.setState({
                fadein: true,
                didViewCountUp: true,
            })
        }

        var scroll_check = this.section1.current.getBoundingClientRect().top;

        if (!this.state.video_ended && scroll_check != 0) {
            this.setState({
                // video_ended: true,
                // show_controls: false,
                disable_scroll: false,
                steps: false,
                can_continue: true
            });
            document.body.style.overflowY = "scroll";
            setTimeout(() => {
                this.setState({ scroll_display: { display: "block", opacity: 1 } });
            }, 100);
        }
        if (this.state.can_continue) {
            e.preventDefault();
        }
        if (this.state.disable_scroll)
            var st = window.pageYOffset || document.documentElement.scrollTop;
        if (st > 0) {
            // downscroll code
        } else {
            // upscroll code
        }
        var breakpoint = parseInt(
            this.section1.current.getBoundingClientRect().height / 2
        );
        var top_sec_1 = this.section1.current.getBoundingClientRect().top;
        var top_sec_2 = this.section2.current.getBoundingClientRect().top;
        var top_sec_3 = this.section3.current.getBoundingClientRect().top;
        var top_sec_4 = this.section4.current.getBoundingClientRect().top;
        var top_sec_5 = this.section5.current.getBoundingClientRect().top;
        var top_sec_6 = this.section6.current.getBoundingClientRect().top;
        var top_sec_7 = this.section7.current.getBoundingClientRect().top;

        if (
            top_sec_1 > -breakpoint &&
            top_sec_1 < breakpoint &&
            this.state.selected_section != "section_1"
        ) {
            this.setState({
                selected_section: "section_1",
                next_section: "section_2",
                scroll_display: { display: "block", opacity: 1 }
            });
        } else if (
            top_sec_2 > -breakpoint &&
            top_sec_2 < breakpoint &&
            this.state.selected_section != "section_2"
        ) {
            this.setState({
                selected_section: "section_2",
                next_section: "section_3"
            });
        } else if (
            top_sec_3 > -breakpoint &&
            top_sec_3 < breakpoint &&
            this.state.selected_section != "section_3"
        ) {
            this.setState({
                selected_section: "section_3",
                next_section: "section_4",
                scroll_display: { display: "block", opacity: 1 }
            });
        } else if (
            top_sec_4 > -breakpoint &&
            top_sec_4 < breakpoint &&
            this.state.selected_section != "section_4"
        ) {
            this.setState({
                selected_section: "section_4",
                porovnani: true,
                next_section: "section_5"
            });
        } else if (
            top_sec_5 > -breakpoint &&
            top_sec_5 < breakpoint &&
            this.state.selected_section != "section_5"
        ) {
            this.setState({
                selected_section: "section_5",
                next_section: "section_6"
            });
        } else if (
            top_sec_6 > -breakpoint &&
            top_sec_6 < breakpoint &&
            this.state.selected_section != "section_6"
        ) {
            this.setState({
                selected_section: "section_6",
                next_section: "section_7",
                scroll_display: { display: "block", opacity: 1 }
            });
        } else if (
            top_sec_7 > -breakpoint &&
            top_sec_7 < breakpoint &&
            this.state.selected_section != "section_7"
        ) {
            this.setState({
                selected_section: "section_7",
                scroll_display: { display: "none", opacity: 0 },
                steps: false,
                disable_scroll: false
            });
        } else {
        }
        if (!this.state.can_continue) {
            e.preventDefault();
        }
    }

    scrollToSection(section_selected, mouse_click = 0) {
        if (this.state.can_continue) {
            var id_of_section = "";
            var fromNavigation = false;
            if (section_selected == "next") {
                id_of_section = this.state.next_section;
                // fromNavigation = true;
            } else if (
                section_selected == "prev" &&
                this.state.next_section != "section_2"
            ) {
                const cur = this.state.selected_section.substring(
                    0,
                    this.state.selected_section.length - 1
                );
                var count =
                    parseInt(
                        this.state.selected_section.substring(
                            this.state.selected_section.length - 1
                        )
                    ) - 1;
                id_of_section = cur + count;
            } else {
                fromNavigation = true;
                id_of_section = section_selected.substring(
                    0,
                    section_selected.length - 5
                );
                if (id_of_section == "") {
                    return;
                }
            }
            this.setState({ selected_section: id_of_section });
            const scrollDuration = 1300;

            if (this.state.steps || fromNavigation || mouse_click == 1) {
                if (id_of_section == "section_1") {
                    smoothScrollIntoView(this.section1.current, {
                        behavior: "smooth",
                        block: "end",
                        duration: scrollDuration
                    });
                    // this.section1.current.scrollIntoView({
                    //   behavior: "smooth",
                    //   block: "end"
                    // });
                } else if (id_of_section == "section_2") {

                    smoothScrollIntoView(this.section2.current, {
                        behavior: "smooth",
                        block: "end",
                        duration: scrollDuration
                    });
                    // this.section2.current.scrollIntoView({
                    //   behavior: "smooth",
                    //   block: "end"
                    // });
                } else if (id_of_section == "section_3") {
                    smoothScrollIntoView(this.section3.current, {
                        behavior: "smooth",
                        block: "end",
                        duration: scrollDuration
                    });
                    // this.section3.current.scrollIntoView({
                    //   behavior: "smooth",
                    //   block: "end"
                    // });
                }
                else if (id_of_section == "section_4") {
                    smoothScrollIntoView(this.section4.current, {
                        behavior: "smooth",
                        block: "end",
                        duration: scrollDuration
                    });
                    // this.section4.current.scrollIntoView({
                    //   behavior: "smooth",
                    //   block: "end"
                    // });
                }
                else if (id_of_section == "section_5") {
                    console.log('section 5');
                    smoothScrollIntoView(this.section5.current, {
                        behavior: "smooth",
                        block: "end",
                        duration: scrollDuration
                    });
                    // this.section5.current.scrollIntoView({
                    //   behavior: "smooth",
                    //   block: "end"
                    // });
                } else if (id_of_section == "section_6") {
                    smoothScrollIntoView(this.section6.current, {
                        behavior: "smooth",
                        block: "end",
                        duration: scrollDuration
                    });
                    // this.section6.current.scrollIntoView({
                    //   behavior: "smooth",
                    //   block: "end"
                    // });
                } else {
                    smoothScrollIntoView(this.section7.current, {
                        behavior: "smooth",
                        block: "end",
                        duration: scrollDuration
                    });
                    // this.section7.current.scrollIntoView({
                    //   behavior: "smooth",
                    //   block: "end"
                    // });
                    setTimeout(() => {
                        document.body.style.overflowY = "scroll";
                    }, scrollDuration);
                    // document.body.style.overflowY = "scroll";

                    // enableBodyScroll(this.body);
                    this.setState({ steps: false, disable_scroll: false });
                }
                if (this.state.steps) {
                    this.wait(1500);
                }
            }
        }
    }
    areaHandle(value) {
        this.setState({ area_value: value });
    }
    wait(time) {
        this.setState({
            can_continue: false
        });
        setTimeout(() => {
            this.setState({
                can_continue: true
            });
        }, time);
    }
    handleChange(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        if (target.type == "select-one" && value == "Mám projekt") {
            this.setState({
                file_input: { display: "block" }
            });
        }
        if (target.type == "select-one" && value != "Mám projekt") {
            this.setState({
                file_input: { display: "none" }
            });
        }

        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value) && name == "email") {
            this.setState({ valid_email: "" });
        }

        if (i18n.language === 'cs') {
            if (value && /(\+\d{3})?\d{9}/.test(value) && name == "city") {
                this.setState({ valid_phone: "" });
            }
        } else {
            if (value && /(\+\d{3})?\d{10}/.test(value) && name == "city") {
                this.setState({ valid_phone: "" });
            }
        }

        this.setState({
            [name]: value
        });
    }
    handleSubmit(e) {
        e.preventDefault();

        //console.log(this.state.slider_value);

        const data = {
            type: "Konfigurátor podlah",
            name: this.state.name,
            phone_number: this.state.city,
            email: this.state.email,
            area: this.state.area_value,
            stage: this.state.phase,
            sources: this.state.sources,
            floor_config: [
                {
                    name: this.izolace_names[this.state.izolace],
                    height: this.state.conf_slider_value
                },
                {
                    name: this.roznos_names[this.state.roznos],
                    height: this.state.conf_slider_value_roznos
                },
                {
                    name: this.final_names[this.state.final],
                    height: this.state.conf_slider_value_final
                }
            ]
        };
        let failed = false;

        if (this.state.phase == "Mám projekt" && this.state.sources == "") {
            this.setState({ missing_files: true });

            failed = true;
        }

        if (this.state.city !== '') {
            if (i18n.language === 'cs') {
                if (! /(\+\d{3})?\d{9}/.test(this.state.city)) {
                    this.setState({ valid_phone: "not_valid" });

                    failed = true;
                }
            } else {
                if (! /(\+\d{3})?\d{10}/.test(this.state.city)) {
                    this.setState({ valid_phone: "not_valid" });

                    failed = true;
                }
            }
        }

        if (! /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email)) {
            this.setState({ valid_email: "not_valid" });

            failed = true;
        }

        if (
            false === failed &&
            this.state.name != "" &&
            this.state.email != "" &&
            this.state.area_value != ""
        ) {
            postOffers.request(data, this.posted);
            const step = 7;
            this.setState({
                conf: step,
                slider_disabled: true,
                submit_title: i18n.t('general.sending'),
                form_response: i18n.t('general.loadingFiles')
            });
            setTimeout(() => {
                this.setState({
                    prev_conf: step,
                    sent: true
                });
            }, 500);
            // const step = 7;
            // this.setState({ conf: step });
            // setTimeout(() => {
            //   this.setState({ prev_conf: step });
            // }, 500);
        }
            // else if (this.state.phase == "") {
            //   this.setState({ select_empty: "empty" });
        // }
        else {
            this.setState({ submitted: true });
        }
    }
    posted(response) {
        if (response == "Too large") {
            this.setState({
                title: "",
                conf: 6,
                error_message: "Přiložené soubory jsou příliš velké."
            });
            setTimeout(() => {
                this.setState({
                    prev_conf: 6
                });
            }, 500);
            return;
        }
        if (response.status == 200 || response.status == 201) {
            // const step = 7;
            this.setState({
                submit_title: i18n.t('general.sended'),
                form_response: i18n.t('general.takeCareASAP')
            });

            window.dataLayer.push({
                'event': 'lead',
                'formLocation': 'skladba-uvod',
            });

            setTimeout(() => {
                this.setState({
                    scroll_display: { display: "block", opacity: 1 }
                });
            }, 500);
        } else if (response.status == 429) {
            this.setState({
                title: i18n.t('general.sendingFailed')
            });
        } else {
        }
    }

    sliderHandle(value) {
        this.setState({ slider_value: value });
    }
    shiftLeft() {
        if (this.state.shift_left == "") {
            this.setState({
                shift_left: 'open-left',
                intelligent_solution_left: false,
                shift_right: '',
                intelligent_solution_right: true,
            });
        } else {
            this.setState({ shift_left: "", intelligent_solution_left: true });
        }
    }
    shiftRight() {
        if (this.state.shift_right == "") {
            this.setState({
                shift_right: 'open-right',
                intelligent_solution_right: false,
                shift_left: '',
                intelligent_solution_left: true,
            });
        } else {
            this.setState({ shift_right: "", intelligent_solution_right: true });
        }
    }
    startConf() {
        // const step = this.state.conf;
        // this.setState({conf: step+1})
        // setTimeout(()=>{
        //   this.setState({prev_conf: step+1})
        // },500)
        this.setState({
            slider_disabled: false,
            conf_slider: 0,
            conf: 2,
            max_conf: 2,
            scroll_display: { display: "none" },
            slider_visibility: { visibility: "visible" }
        });
        setTimeout(() => {
            this.setState({ prev_conf: 2 });
        }, 500);
    }
    stepBack() {
        const step = this.state.conf;
        if (step != 1) {
            this.setState({ conf: step - 1 });
            setTimeout(() => {
                this.setState({ prev_conf: step - 1 });
            }, 500);
        }
        this.markAcquired();
    }
    duringStep(new_step) {
        if (
            new_step <= this.state.furthers_conf_slider + 25 ||
            this.state.furthers_conf_slider == 75
        ) {
            this.setState({ conf_slider: new_step });
        }
        this.markAcquired();
    }
    toStep(new_step) {
        const val = this.state.conf_slider;
        var temp = new_step;
        if (
            new_step != 0 ||
            new_step != 25 ||
            new_step != 50 ||
            new_step != 75 ||
            new_step != 100
        ) {
            if (new_step < 12) {
                temp = 0;
            } else if (new_step >= 12 && new_step < 37) {
                temp = 25;
            } else if (new_step >= 37 && new_step < 62) {
                temp = 50;
            } else if (new_step >= 62 && new_step < 77) {
                temp = 75;
            }
                // else if (new_step>=77) {
                //   temp = 100;
            // }
            else {
                temp = 100;
            }
        }

        if (
            !(temp > val + 26) ||
            this.state.furthers_conf_slider >= temp ||
            this.state.furthers_conf_slider == 75
        ) {
            if (this.state.furthers_conf_slider <= temp) {
                this.setState({ furthers_conf_slider: temp });
            }
            this.setState({ conf_slider: temp });
            const step = parseInt(temp / 25) + 2;
            this.setState({ conf: step, max_conf: Math.max(step, this.state.max_conf) });
            this.markAcquired();
            setTimeout(() => {
                this.setState({ prev_conf: step });
            }, 500);
        }
    }

    markAcquired() {
        const el = document.querySelector('#progress-slider');

        let dots = el.querySelectorAll('.rc-slider-dot');

        Array.prototype.slice.call(dots).slice(0, Math.max(this.state.max_conf - 2, 0)).forEach((el) => {
            el.classList.add("rc-slider-dot-active");
        });

        let labels = el.querySelectorAll('.rc-slider-mark-text');

        Array.prototype.slice.call(labels).slice(0, Math.max(this.state.max_conf - 2, 0)).forEach((el) => {
            el.classList.add("rc-slider-mark-text-active");
        });
    }

    izolaceClick(e) {
        if (this.state.izolace != e.currentTarget.getAttribute("data-num")) {
            var num = e.currentTarget.getAttribute("data-num");
            this.setState({
                izolace: num,
                conf_slider_value: this.izolace_tloustka_recommended[num]
            });
        }
    }
    roznosClick(e) {
        if (this.state.roznos != e.currentTarget.getAttribute("data-num")) {
            var num = e.currentTarget.getAttribute("data-num");
            this.setState({
                roznos: num,
                conf_slider_value_roznos: this.roznos_tloustka_recommended[num]
            });
        }
    }
    finalClick(e) {
        if (this.state.final != e.currentTarget.getAttribute("data-num")) {
            var num = e.currentTarget.getAttribute("data-num");
            this.setState({
                final: num,
                conf_slider_value_final: this.final_tloustka_recommended[num]
            });
        }
    }

    izolaceOn(e) {
        this.setState({ izolace_hover: e.currentTarget.getAttribute("data-num") });
    }
    izolaceOut(e) {
        this.setState({ izolace_hover: this.state.izolace });
    }
    roznosOn(e) {
        this.setState({ roznos_hover: e.currentTarget.getAttribute("data-num") });
    }
    roznosOut(e) {
        this.setState({ roznos_hover: this.state.roznos });
    }
    finalOn(e) {
        this.setState({ final_hover: e.currentTarget.getAttribute("data-num") });
    }
    finalOut(e) {
        this.setState({ final_hover: this.state.final });
    }

    spolClick(e) {
        if (this.state.spol_logos != e.currentTarget.getAttribute("data-num")) {
            this.setState({
                spol_logos: e.currentTarget.getAttribute("data-num"),
                spolupracujeme: e.currentTarget.getAttribute("data-name"),
                scroll_display: { display: "none", opacity: 0 }
            });
        }
    }
    spolCollapse() {
        this.setState({
            spol_logos: null,
            scroll_display: { display: "block", opacity: 1 }
        });
    }

    videoLoaded(e) {
        console.log('videoloaded func');
        this.muteVideo();
        this.setState({ video_loaded: true });
    }
    videoPlay(e) {
        if (
            this.state.intro_playing &&
            !this.state.video_ended &&
            e.target.className != "intro-skip" &&
            e.target.className != "intro-sound"
        ) {
            this.setState({
                video_clicked: true,
                show_play_btn: false,
                show_controls: true
            });
            if (this.refs.intro.paused) {
                this.refs.intro.play();
            }
        }
    }
    muteVideo() {
        if (this.refs.intro.muted) {
            this.refs.intro.muted = false;
            this.setState({ video_muted: "ic_sound_on" });
        } else {
            this.refs.intro.muted = true;
            this.setState({ video_muted: "ic_sound_off" });
        }
    }
    skipIntro() {
        if (!this.props.introPlay) {
            this.refs.intro.currentTime = Math.floor(this.refs.intro.duration);
            this.refs.intro.pause();
        }
        // else {
        //   setTimeout(() => {
        //     this.refs.intro.currentTime = Math.floor(this.refs.intro.duration);
        //     this.refs.intro.pause();
        //   }, 1000);
        // }
        setTimeout(() => {
            this.introVideoEnded();
        }, 400);

        this.setState({ can_continue: true });
    }
    playIntro() {
        // this.refs.intro.click();
        // while (this.refs.intro.paused) {
        //   this.refs.intro.click();
        // }
        console.log('playIntro');
        if (!this.state.intro_playing) {
            // this.refs.intro.play();
            // var media = document.getElementById("YourVideo");
            const playPromise = this.refs.intro.play();
            if (!!playPromise) {
                playPromise
                .then(_ => {console.log('then');})
                .catch(error => {
                    console.log('catch');
                    this.skipIntro();
                });
                // playPromise.catch().then(() => {
                //   this.refs.intro.play();
                // });
            } else {
                this.skipIntro();
            }
            // setTimeout(() => {
            //   this.refs.intro.muted = false;
            // }, 10);
            setTimeout(() => {
                if (this.refs.intro.paused) {
                    this.setState({ show_play_btn: !!playPromise && true });
                } else {
                    this.setState({ show_controls: !!playPromise && true });
                }
            }, 30);
            this.setState({ intro_playing: true });
            setTimeout(() => {
                this.setState({ showLoader: false });
            }, 1500);
        }
    }
    introVideoEnded() {
        this.props.introFinished();
        this.setState({
            video_ended: true,
            show_controls: false,
            can_continue: true
        });
        setTimeout(() => {
            this.setState({ scroll_display: { display: "block", opacity: 1 } });
        }, 700);

        setTimeout(() => {
            if (this.section1.current.getBoundingClientRect().top == 0) {
                this.scrollToSection("next");
            }
        }, 3000);
    }
    loaderLoaded(e) {
        this.setState({ loader_loaded: true });
    }

    render() {
        // HEAD ENERGY FUSION BACKGROUNDS

        var ef_left_bg_path =
            config.paths.backgrounds + "img_homepage_background_ef_left.png";
        var ef_left_bg = {
            background: "url(" + ef_left_bg_path + ")",
            backgroundPosition: "0 0",
            backgroundSize: "auto"
        };

        var ef_right_bg_path =
            config.paths.backgrounds + "img_homepage_background_ef_right.png";
        var ef_right_bg = {
            background: "url(" + ef_right_bg_path + ")",
            backgroundPosition: "100% 0",
            backgroundSize: "auto"
        };

        var ef_bg = {
            background: "url(" + ef_left_bg_path + "), url(" + ef_right_bg_path + ")",
            backgroundPosition: "0% 0%, 100% 0%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "65% auto, 60% auto"
        };
        var slider_handle = config.paths.icons + "ic_handle_bluegreen_gradient.png";
        var slider_head = config.paths.backgrounds + "img_slider_bg_active.png";
        var slider_tail = config.paths.backgrounds + "img_slider_bg.png";

        return (
            <div
                className="main-container"
                onScroll={this.onScroll}
                // {...WheelReact.events}
            >
                <img className="control-load" src={config.paths.backgrounds + 'texture-loader-transparent.svg'} alt="" onLoad={this.loaderLoaded} style={{ display: 'none' }} />
                {this.state.showLoader ? (
                    <NProgress isAnimating>
                        {({ animationDuration, isFinished, progress }) => {
                            const mult = 200;
                            const cond = Math.ceil(progress * mult) > 20 && this.state.video_loaded;

                            if (cond) {
                                this.playIntro();
                            }
                            return (
                                <div className="animation-overflow">
                                    <div className={!cond ? 'animation-loader loader-home' : 'animation-loader loader-home is-loaded'}>
                                        <div
                                            className={!cond ? 'loader-svg-home' : 'loader-svg-home is-loaded'}
                                            style={{
                                                backgroundImage: "url('/assets/images/backgrounds/texture-loader-transparent.svg')",
                                            }}
                                        />
                                        {this.state.introVideo.progress < 100 && <span className={Math.ceil(progress * mult) < 93 ? 'text' : 'text is-loaded'}>
                                            {this.state.introVideo.progress}
                                            <small>%</small>
                                        </span>}
                                        <div className={(!cond ? 'orange-loader ' : 'orange-loader is-loaded ') + (this.state.loader_loaded ? 'orange' : '')}>
                                            <div className="loader-fill" style={{ width: this.state.introVideo.progress + '%' }} />
                                        </div>
                                    </div>
                                </div>
                            );
                        }}
                    </NProgress>
                ) : (
                    ''
                )}
                {/* <NProgress isAnimating>
          {({ animationDuration, isFinished, progress }) => {
            const cond =
              Math.ceil(progress * 300) > 95 && this.state.video_loaded;
            if (cond) {
              this.playIntro();
            }
            return (
              <div
                className={
                  !cond ? "animation-loader" : "animation-loader is-loaded"
                }
              >
                <div
                  className={!cond ? "loader-svg" : "loader-svg is-loaded"}
                  style={{
                    backgroundImage:
                      "url('/assets/images/backgrounds/texture-loader-transparent.svg')"
                  }}
                ></div>
                <span
                  className={
                    Math.ceil(progress * 300) < 93 ? "text" : "text is-loaded"
                  }
                >
                  {Math.ceil(progress * 300)}
                  <small>%</small>
                </span>
                <div
                  className={
                    !cond ? "orange-loader" : "orange-loader is-loaded"
                  }
                >
                  <div
                    className="loader-fill"
                    style={{ width: Math.ceil(progress * 300) + "%" }}
                  ></div>
                </div>
              </div>
            );
          }}
        </NProgress> */}
                {/* <div
          className={
            this.state.showLoader
              ? "animation-loader"
              : "animation-loader is-loaded"
          }
        >
          <div
            className={
              this.state.showLoader ? "loader-svg" : "loader-svg is-loaded"
            }
          ></div>
          <span
            className={
              this.state.loadingStatus < 90 ? "text" : "text is-loaded"
            }
          >
            {this.state.loadingStatus}
            <small>%</small>
          </span>
          <div
            className={
              this.state.showLoader
                ? "orange-loader"
                : "orange-loader is-loaded"
            }
          >
            <div
              className="loader-fill"
              style={{ width: this.state.loadingStatus + "%" }}
            ></div>
          </div>
        </div> */}
                <div className="main-desktop">
                    <Navigation onNavigationClick={this.scrollToSection} selected_section={this.state.selected_section} style={this.state.scroll_display} />
                    <div
                        className="scroll-cont"
                        onClick={() => {
                            this.scrollToSection('next', 1);
                        }}
                        style={this.state.scroll_display}
                    >
                        <img className="scroll-icon" src={config.paths.icons + 'ic_scroll_grey.png'} alt="Pokračovat" />
                    </div>
                    <div className="page-section" ref={this.section1} id="section_1" onClick={this.videoPlay}>
                        {/* <img
              className="intro-image"
              src={config.paths.backgrounds + "img_intro_video_last_frame.png"}
              alt="Heatflow"
            /> */}
                        {this.props.introPlay ? (
                            <div className="intro-image"></div>
                        ) : (
                            <video poster={config.paths.backgrounds + 'home-video-poster.jpg'} className="intro-video" preload="auto" ref="intro">
                                <source

                                    src={config.paths.videos + 'intro_video.mp4'}
                                    // src={intro}
                                    type="video/mp4"
                                />
                                {i18n.t('general.wrongBrowserVideo')}
                            </video>
                        )}

                        <div className="intro-overlay">
                            <div
                                className={
                                    'intro-safari ' +
                                    // (this.isSafari &&
                                    // this.state.intro_playing &&
                                    // this.refs.intro.paused &&
                                    // !this.state.video_clicked
                                    (this.state.show_play_btn ? 'show' : '')
                                }
                            >
                                {i18n.t('home.startPrezentation')}
                            </div>
                            <div className={'intro-gradient ' + (this.state.video_ended ? 'show' : '')} />
                            <div className={'intro-text ' + (this.state.video_ended ? 'show' : '')} dangerouslySetInnerHTML={{ __html: i18n.t('home.prezentationTitle') }}></div>

                            <div className={'intro-controls ' + (this.state.show_controls ? 'show' : '')}>
                                <img className="intro-sound" src={config.paths.icons + this.state.video_muted + '.svg'} alt="Zvuk" onClick={this.muteVideo} />
                                <div className="intro-skip" onClick={this.skipIntro}>
                                    {i18n.t('home.skipVideo')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="page-section" ref={this.section2} id="section_2">
                        <div className="section-content">
                            <img className="conf-shadow" src={config.paths.images + 'img_conf_shadow.png'} alt={i18n.t('home.headerButton')} />
                            <img className="conf-odlesk" src={config.paths.images + 'img_conf_odlesk.png'} alt={i18n.t('home.headerButton')} />
                            <div className="section-conf-shadow"></div>
                            <div className="section-left">
                                {/* INTRO */}

                                <div className={'conf-slide conf-1 ' + (this.state.conf == 1 ? 'active' : '')}>
                                    <span className="section-route">
                                        <b>heated. &gt;</b> {i18n.t('home.configurator')}
                                    </span>
                                    <h2 className="section-heading" dangerouslySetInnerHTML={{ __html: i18next.t('home.header') }}></h2>
                                    <div className="section-start-text">
                                        {i18n.t('home.headerText1')}{' '}
                                        <span className="conf-start-link" onClick={this.startConf}>
                                            {i18n.t('home.headerText2')}
                                        </span>
                                        <br />{i18n.t('home.headerText3')},{' '}
                                        <span className="conf-start-link" onClick={this.startConf}>
                                            {i18n.t('home.headerText4')}
                                        </span>
                                        .
                                    </div>

                                    <div className="section-btn" onClick={this.startConf}>
                                        {i18n.t('home.headerButton')}
                                    </div>
                                </div>

                                {/* IZOLACE */}

                                <div className={'conf-slide conf-2 ' + (this.state.conf == 2 && this.state.prev_conf == 2 ? 'active' : '')}>
                                    <h2 className="section-heading">
                                        <b>{i18n.t('home.floorIsolation')}</b>
                                    </h2>
                                    <div className="section-prop-name">{i18n.t('home.materialSelection')}</div>
                                    <div className="selection-cont">
                                        <div className="option">
                                            <div onMouseEnter={this.izolaceOn} onMouseLeave={this.izolaceOut} className={'option-wheel b_styr ' + (this.state.izolace == 0 ? 'selected' : '')} onClick={this.izolaceClick} data-num="0">
                                                <div className="option-image" />
                                                <div className="option-inside">
                                                    <div className="option-i">i</div>
                                                </div>
                                            </div>
                                            <div className="option-title">{i18n.t('home.whitePolystyrene')}</div>
                                        </div>
                                        <div className="option">
                                            <div onMouseEnter={this.izolaceOn} onMouseLeave={this.izolaceOut} className={'option-wheel pir ' + (this.state.izolace == 1 ? 'selected' : '')} onClick={this.izolaceClick} data-num="1">
                                                <div className="option-image" />
                                                <div className="option-inside">
                                                    <div className="option-i">i</div>
                                                </div>
                                            </div>
                                            <div className="option-title">{i18n.t('home.pir')}</div>
                                        </div>
                                        <div className="option">
                                            <div onMouseEnter={this.izolaceOn} onMouseLeave={this.izolaceOut} className={'option-wheel vata ' + (this.state.izolace == 2 ? 'selected' : '')} onClick={this.izolaceClick} data-num="2">
                                                <div className="option-image" />
                                                <div className="option-inside">
                                                    <div className="option-i">i</div>
                                                </div>
                                            </div>
                                            <div className="option-title">{i18n.t('home.wool')}</div>
                                        </div>
                                        <div className="option">
                                            <div onMouseEnter={this.izolaceOn} onMouseLeave={this.izolaceOut} className={'option-wheel s_styr ' + (this.state.izolace == 3 ? 'selected' : '')} onClick={this.izolaceClick} data-num="3">
                                                <div className="option-image" />
                                                <div className="option-inside">
                                                    <div className="option-i">i</div>
                                                </div>
                                            </div>
                                            <div className="option-title">{i18n.t('home.grayPolystyrene')}</div>
                                        </div>
                                    </div>
                                    <div className={'slider-tloustka ' + (this.izolace_tloustka[this.state.izolace] ? 'active' : '')}>
                                        <div className="tloustka-legend-cont">
                                            <span className="tloustka-form-label">{i18n.t('home.thickness')}</span>
                                            <span className="tloustka-form-area">
                                                <b>{this.state.conf_slider_value}</b> mm
                                            </span>
                                        </div>
                                        <div className="tloustka-slider-cont">
                                            <Slider
                                                id="tloustka_slider"
                                                className="tloustka-slider-range"
                                                defaultValue={this.state.conf_slider_value}
                                                value={this.state.conf_slider_value}
                                                min={this.izolace_tloustka_values[this.state.izolace][0]}
                                                max={this.izolace_tloustka_values[this.state.izolace][1]}
                                                step={this.izolace_tloustka_values[this.state.izolace][2]}
                                                onChange={this.confSliderHandle}
                                                trackStyle={{
                                                    backgroundImage: "url('/assets/images/icons/ic_mobile_slider_bg_active.png')",
                                                    height: 24,
                                                }}
                                                handleStyle={{
                                                    borderColor: 'blue',
                                                    height: 44,
                                                    width: 26,
                                                    marginLeft: -9,
                                                    marginTop: -9,
                                                    backgroundImage: "url('/assets/images/icons/ic_handle_orange.png')",
                                                }}
                                                railStyle={{
                                                    backgroundImage: "url('/assets/images/icons/ic_mobile_slider_bg.png')",
                                                    height: 24,
                                                }}
                                            />
                                        </div>
                                    </div>

                                    {this.izolace_desc[this.state.izolace_hover]}
                                </div>

                                {/* ROZNÁŠECÍ VRSTVA */}

                                <div className={'conf-slide conf-3 ' + (this.state.conf == 3 && this.state.prev_conf == 3 ? 'active' : '')}>
                                    <h2 className="section-heading">
                                        <b>{i18n.t('general.spreadingLayer')}</b>
                                    </h2>
                                    <div className="section-prop-name">{i18n.t('home.materialSelection')}</div>
                                    <div className="selection-cont">
                                        <div className="option">
                                            <div onMouseEnter={this.roznosOn} onMouseLeave={this.roznosOut} className={'option-wheel beton ' + (this.state.roznos == 0 ? 'selected' : '')} onClick={this.roznosClick} data-num="0">
                                                <div className="option-image" />
                                                <div className="option-inside">
                                                    <div className="option-i">i</div>
                                                </div>
                                            </div>
                                            <div className="option-title" dangerouslySetInnerHTML={{ __html: i18n.t('home.cementScreed') }}></div>
                                        </div>
                                        <div className="option">
                                            <div onMouseEnter={this.roznosOn} onMouseLeave={this.roznosOut} className={'option-wheel anhydrit ' + (this.state.roznos == 1 ? 'selected' : '')} onClick={this.roznosClick} data-num="1">
                                                <div className="option-image" />
                                                <div className="option-inside">
                                                    <div className="option-i">i</div>
                                                </div>
                                            </div>
                                            <div className="option-title">{i18n.t('home.anhydrit')}</div>
                                        </div>
                                        <div className="option">
                                            <div onMouseEnter={this.roznosOn} onMouseLeave={this.roznosOut} className={'option-wheel deska ' + (this.state.roznos == 2 ? 'selected' : '')} onClick={this.roznosClick} data-num="2">
                                                <div className="option-image" />
                                                <div className="option-inside">
                                                    <div className="option-i">i</div>
                                                </div>
                                            </div>
                                            <div className="option-title" dangerouslySetInnerHTML={{ __html: i18n.t('home.gypsumFiberBoards') }}></div>
                                        </div>
                                    </div>
                                    <div className={'slider-tloustka ' + (this.roznos_tloustka[this.state.roznos] ? 'active' : '')}>
                                        <div className="tloustka-legend-cont">
                                            <span className="tloustka-form-label">{i18n.t('home.thickness')}</span>
                                            <span className="tloustka-form-area">
                                                <b>{this.state.conf_slider_value_roznos}</b> mm
                                            </span>
                                        </div>
                                        <div className="tloustka-slider-cont">
                                            <Slider
                                                id="tloustka_slider"
                                                className="tloustka-slider-range"
                                                defaultValue={this.state.conf_slider_value_roznos}
                                                value={this.state.conf_slider_value_roznos}
                                                min={this.roznos_tloustka_values[this.state.roznos][0]}
                                                max={this.roznos_tloustka_values[this.state.roznos][1]}
                                                step={this.roznos_tloustka_values[this.state.roznos][2]}
                                                onChange={this.confSliderHandleRoznos}
                                                trackStyle={{
                                                    backgroundImage: "url('/assets/images/icons/ic_mobile_slider_bg_active.png')",
                                                    height: 24,
                                                }}
                                                handleStyle={{
                                                    borderColor: 'blue',
                                                    height: 44,
                                                    width: 26,
                                                    marginLeft: -9,
                                                    marginTop: -9,
                                                    backgroundImage: "url('/assets/images/icons/ic_handle_orange.png')",
                                                }}
                                                railStyle={{
                                                    backgroundImage: "url('/assets/images/icons/ic_mobile_slider_bg.png')",
                                                    height: 24,
                                                }}
                                            />
                                        </div>
                                    </div>

                                    {this.roznos_desc[this.state.roznos_hover]}
                                </div>

                                {/* FINÁLNÍ VRSTVA */}

                                <div className={'conf-slide conf-4 ' + (this.state.conf == 4 && this.state.prev_conf == 4 ? 'active' : '')}>
                                    <h2 className="section-heading">
                                        <b>{i18n.t('general.finalLayer')}</b>
                                    </h2>
                                    <div className="section-prop-name">{i18n.t('home.materialSelection')}</div>
                                    <div className="selection-cont">
                                        <div className="option">
                                            <div onMouseEnter={this.finalOn} onMouseLeave={this.finalOut} className={'option-wheel beton ' + (this.state.final == 0 ? 'selected' : '')} onClick={this.finalClick} data-num="0">
                                                <div className="option-image" />
                                                <div className="option-inside">
                                                    <div className="option-i">i</div>
                                                </div>
                                            </div>
                                            <div className="option-title" dangerouslySetInnerHTML={{ __html: i18n.t('home.polishedConcrete') }}></div>
                                        </div>
                                        <div className="option">
                                            <div onMouseEnter={this.finalOn} onMouseLeave={this.finalOut} className={'option-wheel laminat ' + (this.state.final == 1 ? 'selected' : '')} onClick={this.finalClick} data-num="1">
                                                <div className="option-image" />
                                                <div className="option-inside">
                                                    <div className="option-i">i</div>
                                                </div>
                                            </div>
                                            <div className="option-title" dangerouslySetInnerHTML={{ __html: i18n.t('home.laminatedFloor') }}></div>
                                        </div>
                                        <div className="option">
                                            <div onMouseEnter={this.finalOn} onMouseLeave={this.finalOut} className={'option-wheel lino ' + (this.state.final == 2 ? 'selected' : '')} onClick={this.finalClick} data-num="2">
                                                <div className="option-image" />
                                                <div className="option-inside">
                                                    <div className="option-i">i</div>
                                                </div>
                                            </div>
                                            <div className="option-title">{i18n.t('home.pvc')}</div>
                                        </div>
                                        <div className="option">
                                            <div onMouseEnter={this.finalOn} onMouseLeave={this.finalOut} className={'option-wheel dlazba ' + (this.state.final == 3 ? 'selected' : '')} onClick={this.finalClick} data-num="3">
                                                <div className="option-image" />
                                                <div className="option-inside">
                                                    <div className="option-i">i</div>
                                                </div>
                                            </div>
                                            <div className="option-title">{i18n.t('home.pavement')}</div>
                                        </div>
                                        <div className="option">
                                            <div onMouseEnter={this.finalOn} onMouseLeave={this.finalOut} className={'option-wheel drevo ' + (this.state.final == 4 ? 'selected' : '')} onClick={this.finalClick} data-num="4">
                                                <div className="option-image" />
                                                <div className="option-inside">
                                                    <div className="option-i">i</div>
                                                </div>
                                            </div>
                                            <div className="option-title">{i18n.t('home.solidWood')}</div>
                                        </div>
                                    </div>
                                    <div className={'slider-tloustka ' + (this.final_tloustka[this.state.final] ? 'active' : '')}>
                                        <div className="tloustka-legend-cont">
                                            <span className="tloustka-form-label">{i18n.t('home.thickness')}</span>
                                            <span className="tloustka-form-area">
                                                <b>{this.state.conf_slider_value_final}</b> mm
                                            </span>
                                        </div>
                                        <div className="tloustka-slider-cont">
                                            <Slider
                                                id="tloustka_slider"
                                                className="tloustka-slider-range"
                                                defaultValue={this.state.conf_slider_value_final}
                                                value={this.state.conf_slider_value_final}
                                                min={this.final_tloustka_values[this.state.final][0]}
                                                max={this.final_tloustka_values[this.state.final][1]}
                                                step={this.final_tloustka_values[this.state.final][2]}
                                                onChange={this.confSliderHandleFinal}
                                                trackStyle={{
                                                    backgroundImage: "url('/assets/images/icons/ic_mobile_slider_bg_active.png')",
                                                    height: 24,
                                                }}
                                                handleStyle={{
                                                    borderColor: 'blue',
                                                    height: 44,
                                                    width: 26,
                                                    marginLeft: -9,
                                                    marginTop: -9,
                                                    backgroundImage: "url('/assets/images/icons/ic_handle_orange.png')",
                                                }}
                                                railStyle={{
                                                    backgroundImage: "url('/assets/images/icons/ic_mobile_slider_bg.png')",
                                                    height: 24,
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {/* <div className="section-text">
                    <b>{i18n.t('general.recommendThick')} </b>
                    <span className="orange">min. 30 mm</span>.
                  </div> */}

                                    {this.final_desc[this.state.final_hover]}
                                </div>

                                {/* SHRNUTÍ */}

                                <div className={'conf-slide conf-5 ' + (this.state.conf == 5 && this.state.prev_conf == 5 ? 'active' : '')}>
                                    <h2 className="section-heading">
                                        <b>{i18n.t('general.conclusion')}</b>
                                    </h2>

                                    <div className="shrnuti-cont">
                                        <div className="shrnuti-row">
                                            <div className="shrnuti-wheel">
                                                <div
                                                    className="shrnuti-image"
                                                    style={{
                                                        backgroundImage: "url('/assets/images/img/img_conf_l2_" + this.state.izolace + "_icon.png')",
                                                    }}
                                                />
                                            </div>
                                            <div className="shrnuti-slider-cont">
                                                <div className="shrnuti-slider-text">
                                                    <div className="shrnuti-slider-left">
                                                        <span className="shrnuti-type">{i18n.t('home.isolationFloorLayer')}</span>
                                                        <br />
                                                        <span className="shrnuti-type-name">{this.izolace_names[this.state.izolace]}</span>
                                                    </div>
                                                    <div className="shrnuti-slider-right">
                                                        <b>{this.state.conf_slider_value}</b> mm
                                                    </div>
                                                </div>
                                                <Slider
                                                    id="shrnuti-izolace"
                                                    className="shrnuti-slider-range"
                                                    defaultValue={this.state.conf_slider_value}
                                                    value={this.state.conf_slider_value}
                                                    min={this.izolace_tloustka_values[this.state.izolace][0]}
                                                    max={this.izolace_tloustka_values[this.state.izolace][1]}
                                                    step={this.izolace_tloustka_values[this.state.izolace][2]}
                                                    disabled={true}
                                                    onChange={this.confSliderHandleRoznos}
                                                    trackStyle={{
                                                        backgroundImage: "url('/assets/images/icons/ic_mobile_slider_bg_active.png')",
                                                        height: 24,
                                                    }}
                                                    handleStyle={{
                                                        borderColor: 'blue',
                                                        height: 44,
                                                        width: 26,
                                                        marginLeft: -9,
                                                        marginTop: -9,
                                                        opacity: 0,
                                                        backgroundImage: "url('/assets/images/icons/ic_handle_orange.png')",
                                                    }}
                                                    railStyle={{
                                                        backgroundImage: "url('/assets/images/icons/ic_mobile_slider_bg.png')",
                                                        height: 24,
                                                    }}
                                                />
                                            </div>
                                            <div className="shrnuti-slider-upravit" onClick={() => this.toStep(0)}>
                                                {i18n.t('general.edit')}
                                            </div>
                                        </div>

                                        <div className="shrnuti-row">
                                            <div className="shrnuti-wheel">
                                                <div
                                                    className="shrnuti-image"
                                                    style={{
                                                        backgroundImage: "url('/assets/images/img/img_conf_l3_" + this.state.roznos + "_icon.png')",
                                                    }}
                                                />
                                            </div>
                                            <div className="shrnuti-slider-cont">
                                                <div className="shrnuti-slider-text">
                                                    <div className="shrnuti-slider-left">
                                                        <span className="shrnuti-type">{i18n.t('home.floorSpreadingLayer')}</span>
                                                        <br />
                                                        <span className="shrnuti-type-name">{this.roznos_names[this.state.roznos]}</span>
                                                    </div>
                                                    <div className="shrnuti-slider-right">
                                                        <b>{this.state.conf_slider_value_roznos}</b> mm
                                                    </div>
                                                </div>
                                                <Slider
                                                    id="shrnuti-izolace"
                                                    className="shrnuti-slider-range"
                                                    defaultValue={this.state.conf_slider_value_roznos}
                                                    value={this.state.conf_slider_value_roznos}
                                                    min={this.roznos_tloustka_values[this.state.roznos][0]}
                                                    max={this.roznos_tloustka_values[this.state.roznos][1]}
                                                    step={this.roznos_tloustka_values[this.state.roznos][2]}
                                                    disabled={true}
                                                    onChange={this.confSliderHandleRoznos}
                                                    trackStyle={{
                                                        backgroundImage: "url('/assets/images/icons/ic_mobile_slider_bg_active.png')",
                                                        height: 24,
                                                    }}
                                                    handleStyle={{
                                                        borderColor: 'blue',
                                                        height: 44,
                                                        width: 26,
                                                        marginLeft: -9,
                                                        marginTop: -9,
                                                        opacity: 0,
                                                        backgroundImage: "url('/assets/images/icons/ic_handle_orange.png')",
                                                    }}
                                                    railStyle={{
                                                        backgroundImage: "url('/assets/images/icons/ic_mobile_slider_bg.png')",
                                                        height: 24,
                                                    }}
                                                />
                                            </div>
                                            <div className="shrnuti-slider-upravit" onClick={() => this.toStep(25)}>
                                                {i18n.t('general.edit')}
                                            </div>
                                        </div>

                                        <div className="shrnuti-row">
                                            <div className="shrnuti-wheel">
                                                <div
                                                    className="shrnuti-image"
                                                    style={{
                                                        backgroundImage: "url('/assets/images/img/img_conf_l4_" + this.state.final + "_icon.png')",
                                                    }}
                                                />
                                            </div>
                                            <div className="shrnuti-slider-cont">
                                                <div className="shrnuti-slider-text">
                                                    <div className="shrnuti-slider-left">
                                                        <span className="shrnuti-type">{i18n.t('home.floorFinalLayer')}</span>
                                                        <br />
                                                        <span className="shrnuti-type-name">{this.final_names[this.state.final]}</span>
                                                    </div>
                                                    <div className="shrnuti-slider-right">
                                                        <b>{this.state.conf_slider_value_final}</b> mm
                                                    </div>
                                                </div>
                                                <Slider
                                                    id="shrnuti-izolace"
                                                    className="shrnuti-slider-range"
                                                    defaultValue={this.state.conf_slider_value_final}
                                                    value={this.state.conf_slider_value_final}
                                                    min={this.final_tloustka_values[this.state.final][0]}
                                                    max={this.final_tloustka_values[this.state.final][1]}
                                                    step={this.final_tloustka_values[this.state.final][2]}
                                                    disabled={true}
                                                    onChange={this.confSliderHandleRoznos}
                                                    trackStyle={{
                                                        backgroundImage: "url('/assets/images/icons/ic_mobile_slider_bg_active.png')",
                                                        height: 24,
                                                    }}
                                                    handleStyle={{
                                                        borderColor: 'blue',
                                                        height: 44,
                                                        width: 26,
                                                        marginLeft: -9,
                                                        marginTop: -9,
                                                        opacity: 0,
                                                        backgroundImage: "url('/assets/images/icons/ic_handle_orange.png')",
                                                    }}
                                                    railStyle={{
                                                        backgroundImage: "url('/assets/images/icons/ic_mobile_slider_bg.png')",
                                                        height: 24,
                                                    }}
                                                />
                                            </div>
                                            <div
                                                className="shrnuti-slider-upravit"
                                                onClick={() => this.toStep(50)}
                                                // style={{ visibility: "hidden" }}
                                            >
                                                {i18n.t('general.edit')}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* VYHODNOCENÍ */}

                                <div className={'conf-slide conf-6 ' + (this.state.conf == 6 && this.state.prev_conf == 6 ? 'active' : '')}>
                                    <h2 className="section-heading" style={this.state.sent ? { fontSize: '32px' } : {}}>
                                        {this.state.error_message}
                                    </h2>
                                    <div className="form-container">
                                        <form action="" className={'form-nabidka ' + (this.state.submitted ? 'submitted' : '') + ' ' + this.state.valid_email + ' ' + this.state.valid_phone} onSubmit={this.handleSubmit}>
                                            <div className="form-row form-row--konfigurator--first">
                                                <div className="nabidka-slider">
                                                    <div className="nabidka-legend-cont">
                                                        <span className="nabidka-form-label"> {i18n.t('home.totalAreaBuilding')} °</span>
                                                        <span className="nabidka-form-area">
                                                            <b>{this.state.area_value}</b> m<sup>2</sup>
                                                        </span>
                                                    </div>
                                                    <div className="nabidka-slider-cont">
                                                        <Slider
                                                            id="slider_7"
                                                            className="nabidka-slider-range"
                                                            defaultValue={this.state.area_value}
                                                            min={1}
                                                            max={500}
                                                            step={1}
                                                            onChange={this.areaHandle}
                                                            trackStyle={{
                                                                backgroundImage: "url('/assets/images/icons/ic_mobile_slider_bg_active.png')",
                                                                height: 24,
                                                            }}
                                                            handleStyle={{
                                                                borderColor: 'blue',
                                                                height: 44,
                                                                width: 26,
                                                                marginLeft: -9,
                                                                marginTop: -9,
                                                                backgroundImage: "url('/assets/images/icons/ic_handle_orange.png')",
                                                            }}
                                                            railStyle={{
                                                                backgroundImage: "url('/assets/images/icons/ic_mobile_slider_bg.png')",
                                                                height: 24,
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-field one-col">
                                                    <select name="phase" className={this.state.select_empty} id="form_faze_nabidka" onChange={this.handleChange} value={this.state.phase}>
                                                        <option value="" defaultValue>
                                                            {i18n.t('general.projectPhase')}
                                                        </option>
                                                        <option value="Nemám projekt">{i18n.t('general.noProject')}</option>
                                                        <option value="Mám projekt">{i18n.t('general.haveProject')}</option>
                                                        <option value="Mám projekt, ale nemám ho u sebe">{i18n.t('general.haveProjectNoDocumentation')}</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="form-row three-col form-row--konfigurator--second">
                                                <div className="form-field">
                                                    <input type="text" name="name" id="form_name_nabidka" required onChange={this.handleChange} value={this.state.name} />
                                                    <label htmlFor="form_name_nabidka">{i18n.t('general.fullname')} °</label>
                                                    <i className="form-field-check"></i>
                                                    <span className="required" style={this.state.name != '' ? { display: 'none' } : {}}>
                                                        {i18n.t('general.requiredField')}
                                                    </span>
                                                </div>
                                                <div className="form-field">
                                                    {i18n.language === 'cs' ?
                                                    <>
                                                    <input type="text" name="city" id="form_city_nabidka" onChange={this.handleChange} value={this.state.city} pattern="(\+\d{3})?\d{9}" />
                                                    <label htmlFor="form_city_nabidka">{i18n.t('general.phone')}</label>
                                                    <i className="form-field-check"></i>
                                                    {this.state.city != '' && !/(\+\d{3})?\d{9}/.test(this.state.city) ? <span className="required">{i18n.t('general.wrongFormate')}</span> : ''}
                                                    </> :
                                                    <>
                                                    <input type="text" name="city" id="form_city_nabidka" onChange={this.handleChange} value={this.state.city} pattern="(\+\d{3})?\d{10}" />
                                                    <label htmlFor="form_city_nabidka">{i18n.t('general.phone')}</label>
                                                    <i className="form-field-check"></i>
                                                    {this.state.city != '' && !/(\+\d{3})?\d{10}/.test(this.state.city) ? <span className="required">{i18n.t('general.wrongFormate')}</span> : ''}
                                                    </>}
                                                    {/*
                          <span
                            className="required"
                            style={
                              this.state.city != "" ? { display: "none" } : {}
                            }
                          >
                            {i18n.t('general.requiredField')}
                          </span>
                          */}
                                                </div>
                                                <div className="form-field">
                                                    <input type="email" name="email" id="form_email_nabidka" className={'email ' + (this.state.email != '' ? 'not-empty' : '')} required onChange={this.handleChange} value={this.state.email} />
                                                    <label htmlFor="form_email_nabidka">email °</label>
                                                    <i className="form-field-check"></i>
                                                    <span className="required" style={this.state.email != '' ? { display: 'none' } : {}}>
                                                        {i18n.t('general.requiredField')}
                                                    </span>
                                                    {this.state.email != '' && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email) ? <span className="required">{i18n.t('general.wrongFormate')}</span> : ''}
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className={'form-field files ' + (this.state.missing_files ? 'missing' : '')} style={this.state.file_input}>
                                                    <FormItemUpload
                                                        {...{
                                                            text: i18n.t('general.uploadNote'),
                                                            dataFormItem: 'sources',
                                                            value: this.state.formItems.podklady.value,
                                                            error: this.state.formItems.podklady.error,
                                                            actions: {
                                                                changeFormUploadItem: this.changeFormUploadItem,
                                                            },
                                                        }}
                                                    />
                                                </div>
                                                <div className="form-field">
                                                    <button type="submit" onClick={this.handleSubmit}>
                                                        {i18n.t('general.toSend')}
                                                    </button>
                                                </div>
                                            </div>

                                            {/* <div className="form-bottom">
                        <div className="form-field">
                          <button type="submit" onClick={this.handleSubmit}>
                            {i18n.t('general.toSend')}
                          </button>
                        </div>
                      </div> */}
                                        </form>
                                    </div>
                                </div>

                                {/* ODESLÁNO */}

                                <div className={'conf-slide conf-7 ' + (this.state.conf == 7 && this.state.prev_conf == 7 ? 'active' : '')}>
                                    <h2 className="section-heading">
                                        {this.state.submit_title == i18n.t('general.sended') ? <img className="odeslano-icon" src={config.paths.icons + 'ic_checked_orange.png'} alt={i18n.t('general.sended')} /> : ''}

                                        <b>{this.state.submit_title}</b>
                                    </h2>
                                    <div className="odeslano-text">{this.state.form_response}</div>
                                </div>
                            </div>

                            <div className={'section-config-container ' + (this.state.conf > 1 ? 'active' : '') + ' ' + (this.state.conf == 2 && this.state.prev_conf == 2 ? 'sec2' : '') + ' ' + (this.state.conf == 3 ? 'sec3' : '') + ' ' + (this.state.conf == 4 ? 'sec4' : '') + ' ' + (this.state.conf == 5 ? 'sec5' : '') + ' ' + (this.state.conf == 6 ? 'sec6' : '') + ' ' + (this.state.conf == 7 ? 'sec7' : '')}>
                                <img className="layer section-config-vrsta-0" src={config.paths.images + 'img_conf_l1.png'} alt="Základní vrstva" />
                                <img className="layer section-config-vrsta-1" src={config.paths.images + 'img_conf_l2_' + this.state.izolace + '.png'} alt="Izolace" />
                                <img className="layer section-config-vrsta-2" src={config.paths.images + 'img_conf_l3.png'} alt="Heatflow" />
                                <img className="layer section-config-vrsta-3" src={config.paths.images + 'img_conf_l3_' + this.state.roznos + '.png'} alt="Roznášecí vrstva" />
                                <img className="layer section-config-vrsta-4" src={config.paths.images + 'img_conf_l4_' + this.state.final + '.png'} alt="Finální vrstva" />
                            </div>

                            <div id={'progress-slider'} className={'section-slider-cont ' + (this.state.slider_disabled ? '' : 'active')} style={this.state.slider_visibility}>
                                <div className={'conf-slider-cont ' + this.state.scaleThumb}>
                                    <Slider
                                        handleStyle={{
                                            borderColor: 'transparent',
                                            height: 44,
                                            width: 44,
                                            marginLeft: -12,
                                            marginTop: -10,
                                            backgroundImage: "url('/assets/images/icons/circle-handler-primary.png')",
                                        }}
                                        min={0}
                                        marks={this.marks}
                                        value={this.state.conf_slider}
                                        max={100}
                                        step={1}
                                        onChange={this.duringStep}
                                        onAfterChange={this.toStep}
                                        defaultValue={0}
                                    />
                                </div>
                                <div className="conf-height-cont">
                                    <div className="height-number">{Math.round((this.state.conf_slider_value + this.state.conf_slider_value_roznos + this.state.conf_slider_value_final) / 10)}</div>
                                    cm
                                    <br />
                                     {i18n.t('home.heightOfLayers')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="page-section" ref={this.section3} id="section_3">
                        <div className="section-content">
                            <div className="section-middle">
                                <span className="section-route">
                                    {i18n.t('about.title')}
                                </span>
                                <h2 className="section-heading">
                                    <b>{i18n.t('general.aboutProduct')} </b>heated.
                                </h2>

                                <Link to={config.pages.heatflow_slides.url} className="section-btn">
                                    {i18n.t('home.startPrezentation')}
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="page-section" ref={this.section4} id="section_4">
                        <div className="section-content">
                            <div className="section-middle">
                                <span className="section-route">
                                    {i18n.t('home.comparison.breadcrumb')}
                                </span>
                                <h2 className="section-heading" dangerouslySetInnerHTML={{ __html: i18n.t('home.comparison.title') }}></h2>
                                {this.isSafari ? (
                                    <img className="porovnani-fallback" src={config.paths.images + 'img_porovnani_fallback.svg'} alt="" />
                                ) : (
                                    <div className="circle-porovnani-wrapper">
                                        {this.state.porovnani ? (
                                            <div className="circle-porovnani-container">
                                                <div className="circle-graph-controller home-page nasobek-par">
                                                    <CircleGraph
                                                        type="nasobek"
                                                        max_value={99.9} //{this.state.srovnani_percentage1}
                                                    />
                                                </div>
                                                <div className="circle-graph-controller home-page plocha-par">
                                                    <CircleGraph
                                                        type="plocha"
                                                        max_value={90} //{this.state.srovnani_percentage1}
                                                    />
                                                </div>
                                                <div className="circle-graph-controller home-page naklady-par">
                                                    <CircleGraph
                                                        type="naklady"
                                                        max_value={99.9} //{this.state.srovnani_percentage1}
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="porovnani-helper"></div>
                                        )}
                                    </div>
                                )}

                                {/* <Link to={config.pages.porovnani_vytapeni.url} className="section-btn">
                                    {i18n.t('general.compareHeat')}
                                </Link> */}
                            </div>
                        </div>
                    </div>
                    <div className="page-section" ref={this.section5} id="section_5">
                        <div className="section-content">
                            {/* <img
                className="section-bg-left"
                src={
                  config.paths.backgrounds + "img_homepage_realizace_left.png"
                }
                alt=""
              />
              <img
                className="section-bg-right"
                src={
                  config.paths.backgrounds + "img_homepage_realizace_right.png"
                }
                alt=""
              /> */}
                            <div className="section-left">
                                <Link to={config.pages.ref_projekty.url} className="section-side-link">
                                    <h3 className="section-side-heading"  dangerouslySetInnerHTML={{ __html: i18n.t('reference.interestingProjectsBr') }}></h3>
                                    <div className="section-side-text">{i18n.t('reference.portfolioShowcaseInterestingProjects')}</div>
                                    <div className="section-side-arrow" />
                                </Link>
                            </div>
                            <div className="section-right">
                                <Link to={config.pages.ref_domy.url} className="section-side-link">
                                    <h3 className="section-side-heading" dangerouslySetInnerHTML={{ __html: i18n.t('reference.housesBr') }}></h3>
                                    <div className="section-side-text">{i18n.t('reference.portfolioShowcaseHouses')}</div>
                                    <div className="section-side-arrow" />
                                </Link>
                            </div>
                            <div className="section-middle">
                                <span className="section-route">
                                    {i18n.t('reference.title')}
                                </span>
                                <h2 className="section-heading" dangerouslySetInnerHTML={{ __html: i18n.t('reference.ourRealization') }}></h2>
                                <span className="section-data">{i18n.t('reference.count')}</span>
                                <h2 className="section-data-value">
                                    <div className={'fade-show ' + (this.state.didViewCountUp ? 'show' : '')}>
                                        <b>
                                            {
                                                <CountUp
                                                    start={0}
                                                    end={this.state.didViewCountUp ? +this.state.config_buildings.replace(/\s/g, '') : 0}
                                                    duration={3}
                                                    separator=" "
                                                    useGrouping={true}
                                                    ref={(countUp) => {
                                                        this.myCountUp = countUp;
                                                    }}
                                                />
                                            }
                                            {/*{numberWithSpaces(this.state.config_buildings)}*/}
                                        </b>
                                    </div>
                                </h2>
                                <span className="section-data">{i18n.t('general.totalRealizeArea')}</span>
                                <h2 className="section-data-value">
                                    <div className={'fade-show ' + (this.state.didViewCountUp ? 'show' : '')}>
                                        <b>
                                            {
                                                <CountUp
                                                    start={0}
                                                    end={this.state.didViewCountUp ? +this.state.config_area.replace(/\s/g, '') : 0}
                                                    duration={3}
                                                    separator=" "
                                                    useGrouping={true}
                                                    ref={(countUp) => {
                                                        this.myCountUp = countUp;
                                                    }}
                                                />
                                            }
                                            {/*{numberWithSpaces(this.state.config_area)}*/}
                                        </b>{' '}
                                        m<sup>2</sup>
                                    </div>
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="page-section" ref={this.section6} id="section_6">
                        <div className="section-content">
                            <div className="section-middle">
                                <span className="section-route">
                                    {i18n.t('partners.title')}
                                </span>
                                <h2 className="section-heading"  dangerouslySetInnerHTML={{ __html: i18n.t('partners.weCooperate') }}></h2>
                                <div className={'spol-close ' + (this.state.spol_logos ? 'active' : '')} onClick={this.spolCollapse}>
                                    {this.state.spolupracujeme} <img className="spol-close-icon" src={config.paths.icons + 'ic_minus_orange.png'} alt="Sbalit" />
                                </div>
                            </div>

                            <div className="spoluprace-cont">
                                <div className={'spol-section ' + (this.state.spol_logos == 0 ? 'active' : '')} onClick={this.spolClick} data-num="0" data-name="Architekti 1">
                                    <div className="spol-section-grad" />
                                    <div className="spol-section-inside">
                                        <span className="spol-title">{i18n.t('partners.architects')}1</span>
                                        <div className="spol-plus" />
                                    </div>
                                    <div className="spol-modal">
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'architekti1/DESIGN PLAN.png'} alt="DESIGN PLAN" />
                                        </div>
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'architekti1/ATELIER 38.png'} alt="Atelier 38" />
                                        </div>
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'architekti1/Petit Atelier.png'} alt="Petit Atelier" />
                                        </div>
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'architekti1/TERAKON.png'} alt="TERAKON" />
                                        </div>
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'architekti1/monom architekti.png'} alt="monom architekti" />
                                        </div>
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'architekti1/AKK.png'} alt="AKK" />
                                        </div>
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'architekti1/KOHL.png'} alt="KOHL" />
                                        </div>
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'architekti1/zelinkovi.png'} alt="zelinkovi" />
                                        </div>
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'architekti1/MIKULIK.png'} alt="MIKULIK" />
                                        </div>
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'architekti1/SVOBODA PLAN.png'} alt="SVOBODA PLAN" />
                                        </div>
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'architekti1/KOTAS.png'} alt="KOTAS" />
                                        </div>
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'architekti1/DW projekt.png'} alt="DW projekt" />
                                        </div>
                                    </div>
                                </div>
                                <div className={'spol-section ' + (this.state.spol_logos == 1 ? 'active' : '')} onClick={this.spolClick} data-num="1" data-name="Architekti 2">
                                    <div className="spol-section-grad" />
                                    <div className="spol-section-inside">
                                        <span className="spol-title">{i18n.t('partners.architects')}2</span>
                                        <div className="spol-plus" />
                                    </div>
                                    <div className="spol-modal">
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'architekti2/FANDAMENT.png'} alt="FANDAMENT" />
                                        </div>
                                        {/* <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'architekti2/JANOUT.png'} alt="JANOUT" />
                                        </div> */}
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'architekti2/ESTE.png'} alt="ESTE" />
                                        </div>
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'architekti2/TALASA.png'} alt="TALASA" />
                                        </div>
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'architekti2/Master Design.png'} alt="Master Design" />
                                        </div>
                                        {/*<div className="spol-logo">*/}
                                        {/*    <img className="spol-logo-img" src={config.paths.brands + 'architekti2/FAID.png'} alt="FAID" />*/}
                                        {/*</div>*/}
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'architekti2/RACLAVSKY.png'} alt="RACLAVSKY" />
                                        </div>
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'architekti2/CO ARCHITECTS.png'} alt="CO ARCHITECTS" />
                                        </div>
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'architekti2/KEDDESIGN.png'} alt="KEDDESIGN" />
                                        </div>
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'architekti2/DOMKAR.png'} alt="DOMKAR" />
                                        </div>
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'architekti2/IRBER.png'} alt="IRBER" />
                                        </div>
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'architekti2/PAVLIK.png'} alt="PAVLIK" />
                                        </div>
                                    </div>
                                </div>
                                <div className={'spol-section ' + (this.state.spol_logos == 2 ? 'active' : '')} onClick={this.spolClick} data-num="2" data-name={i18n.t('home.turnkeyHousesNoBr')}>
                                    <div className="spol-section-grad" />
                                    <div className="spol-section-inside">
                                        <span className="spol-title" dangerouslySetInnerHTML={{ __html: i18n.t('home.turnkeyHouses') }}></span>
                                        <div className="spol-plus" />
                                    </div>
                                    <div className="spol-modal">
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'domy_na_klic/GOOPAN.png'} alt="GOOPAN" />
                                        </div>
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'domy_na_klic/MORAVSKE DREVOSTAVBY.png'} alt="MORAVSKE DREVOSTAVBY" />
                                        </div>
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'domy_na_klic/BLOKKI.png'} alt="BLOKKI" />
                                        </div>
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'domy_na_klic/EASY HOMES.png'} alt="EASY HOMES" />
                                        </div>
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'domy_na_klic/EUROPANEL.png'} alt="EUROPANEL" />
                                        </div>
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'domy_na_klic/FREEDOMKY.png'} alt="FREEDOMKY" />
                                        </div>
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'domy_na_klic/PURLIVE.png'} alt="PURLIVE" />
                                        </div>
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'domy_na_klic/NATUR.png'} alt="NATUR" />
                                        </div>
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'domy_na_klic/VESPER.png'} alt="VESPER" />
                                        </div>
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'domy_na_klic/WOOD SYSTEM.png'} alt="WOOD SYSTEM" />
                                        </div>
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'domy_na_klic/MA GROUP.png'} alt="MA GROUP" />
                                        </div>
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'domy_na_klic/RD RYMAROV.png'} alt="RD RYMAROV" />
                                        </div>
                                    </div>
                                </div>
                                <div className={'spol-section ' + (this.state.spol_logos == 3 ? 'active' : '')} onClick={this.spolClick} data-num="3" data-name="Develop. / Stav. firmy">
                                    <div className="spol-section-grad" />
                                    <div className="spol-section-inside">
                                        <span className="spol-title" dangerouslySetInnerHTML={{ __html: i18n.t('home.devCompanies') }}></span>
                                        <div className="spol-plus" />
                                    </div>
                                    <div className="spol-modal">
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'stavebni_firmy/GETBERG.png'} alt="GETBERG" />
                                        </div>
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'stavebni_firmy/Home Deluxe Logo.png'} alt="Home Deluxe Logo" />
                                        </div>
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'stavebni_firmy/STATUS.png'} alt="STATUS" />
                                        </div>
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'stavebni_firmy/SSKA.png'} alt="SSKA" />
                                        </div>
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'stavebni_firmy/ABAKON.png'} alt="ABAKON" />
                                        </div>
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'stavebni_firmy/SIMPLEPROGRESS.png'} alt="SIMPLEPROGRESS" />
                                        </div>
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'stavebni_firmy/DOBRE STAVBY.png'} alt="DOBRE STAVBY" />
                                        </div>
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'stavebni_firmy/SMIDSTAV.png'} alt="SMIDSTAV" />
                                        </div>
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'stavebni_firmy/VEXTA.png'} alt="VEXTA" />
                                        </div>
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'stavebni_firmy/NAJDETE DOMOV.png'} alt="NAJDETE DOMOV" />
                                        </div>
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'stavebni_firmy/TOSOSTAV.png'} alt="TOSOSTAV" />
                                        </div>
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'stavebni_firmy/FACHKAS.png'} alt="FACHKAS" />
                                        </div>
                                    </div>
                                </div>
                                <div className={'spol-section ' + (this.state.spol_logos == 4 ? 'active' : '')} onClick={this.spolClick} data-num="4" data-name={i18n.t('home.specialists')}>
                                    <div className="spol-section-grad" />
                                    <div className="spol-section-inside">
                                        <span className="spol-title">{i18n.t('home.specialists')}</span>
                                        <div className="spol-plus" />
                                    </div>
                                    <div className="spol-modal">
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'specialiste/LOXONE.png'} alt="LOXONE" />
                                        </div>
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'specialiste/FERMACELL.png'} alt="FERMACELL" />
                                        </div>
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'specialiste/CONSULTORA.png'} alt="CONSULTORA" />
                                        </div>
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'specialiste/V-PODLAHY.png'} alt="V-PODLAHY" />
                                        </div>
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'specialiste/PROJECT DESIGN.png'} alt="PROJECT DESIGN" />
                                        </div>
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'specialiste/CEMEX.png'} alt="CEMEX" />
                                        </div>
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'specialiste/POPELKA.png'} alt="POPELKA" />
                                        </div>
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'specialiste/PRINCE PARKET.png'} alt="PRINCE PARKET" />
                                        </div>
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'specialiste/Videopodlahy.png'} alt="Videopodlahy" />
                                        </div>
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'specialiste/ATAWA.png'} alt="ATAWA" />
                                        </div>
                                        {/*<div className="spol-logo">*/}
                                        {/*    <img className="spol-logo-img" src={config.paths.brands + 'specialiste/naos.png'} alt="naos" />*/}
                                        {/*</div>*/}
                                        <div className="spol-logo">
                                            <img className="spol-logo-img" src={config.paths.brands + 'specialiste/GC.png'} alt="GC" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="page-section" ref={this.section7} id="section_7">
                        <div className="section-content" style={ef_bg}>
                            <div className={'section-mcore-block ' + this.state.shift_right}>
                                <img className="section-side-logo" src={config.paths.brands + 'logo_MCore_white.png'} alt="MCORE" />
                                <h3 className="section-side-heading" dangerouslySetInnerHTML={{ __html: i18n.t('home.energyFusion.box1.title') }}></h3>
                                <p className="section-side-desc">{i18n.t('home.energyFusion.box1.text')}</p>
                                <a href="http://www.mcore.cz/" target="_blank" className="section-side-btn">
                                    mcore.cz
                                </a>
                            </div>
                            <div className={'section-loxone-block ' + this.state.shift_left}>
                                <img className="section-side-logo" src={config.paths.brands + 'logo_LOXONE.png'} alt="LOXONE" />
                                <h3 className="section-side-heading" dangerouslySetInnerHTML={{ __html: i18n.t('home.energyFusion.box2.title') }}></h3>
                                <p className="section-side-desc">{i18n.t('home.energyFusion.box2.text')}</p>
                                <a href="http://www.lxn.cz" target="_blank" className="section-side-btn">
                                    lxn.cz
                                </a>
                            </div>
                            <div className={'section-shift ' + this.state.shift_left + ' ' + this.state.shift_right}>
                                <div className="section-lines">
                                    <svg viewBox="0 0 2996.39 855.33" className="section-7-svg">
                                        <defs>
                                            <linearGradient id="prefix__H" x1={4598.79} y1={934} x2={6096.73} y2={934} gradientTransform="rotate(180 3797.575 486.67)" xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__a" x1={3521.18} y1={545.67} x2={5019.18} y2={545.67} gradientTransform="matrix(1 0 0 -1 -3521.18 973.34)" gradientUnits="userSpaceOnUse">
                                                <stop offset={0.02} stopColor="#37e678" stopOpacity={0} />
                                                <stop offset={0.03} stopColor="#36e57a" stopOpacity={0.2} />
                                                <stop offset={0.07} stopColor="#33e280" stopOpacity={0.7} />
                                                <stop offset={0.09} stopColor="#32e184" />
                                                <stop offset={0.21} stopColor="#1fccb3" />
                                                <stop offset={0.37} stopColor="#0bb6e3" />
                                                <stop offset={0.43} stopColor="#09b4e8" stopOpacity={0.85} />
                                                <stop offset={0.57} stopColor="#05b0f2" stopOpacity={0.53} />
                                                <stop offset={0.69} stopColor="#02adf9" stopOpacity={0.3} />
                                                <stop offset={0.78} stopColor="#01abfd" stopOpacity={0.15} />
                                                <stop offset={0.83} stopColor="#0af" stopOpacity={0.1} />
                                            </linearGradient>
                                            <linearGradient id="prefix__c" x1={3521.18} y1={569.94} x2={5019.18} y2={569.94} xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__d" x1={4598.79} y1={569.94} x2={6096.73} y2={569.94} gradientTransform="rotate(180 3797.575 486.67)" xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__e" x1={3521.18} y1={594.21} x2={5019.18} y2={594.21} xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__f" x1={4598.79} y1={594.21} x2={6096.73} y2={594.21} gradientTransform="rotate(180 3797.575 486.67)" xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__g" x1={3521.18} y1={618.52} x2={5019.18} y2={618.52} xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__h" x1={4598.79} y1={618.48} x2={6096.73} y2={618.48} gradientTransform="rotate(180 3797.575 486.67)" xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__i" x1={3521.18} y1={642.75} x2={5019.18} y2={642.75} xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__j" x1={4598.79} y1={642.75} x2={6096.73} y2={642.75} gradientTransform="rotate(180 3797.575 486.67)" xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__k" x1={3521.18} y1={667.05} x2={5019.18} y2={667.05} xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__l" x1={4598.79} y1={667.01} x2={6096.73} y2={667.01} gradientTransform="rotate(180 3797.575 486.67)" xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__m" x1={3521.18} y1={691.29} x2={5019.18} y2={691.29} xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__n" x1={4598.79} y1={691.29} x2={6096.73} y2={691.29} gradientTransform="rotate(180 3797.575 486.67)" xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__o" x1={3521.18} y1={715.57} x2={5019.18} y2={715.57} xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__p" x1={4598.79} y1={715.57} x2={6096.73} y2={715.57} gradientTransform="rotate(180 3797.575 486.67)" xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__q" x1={3521.18} y1={739.84} x2={5019.18} y2={739.84} xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__r" x1={4598.79} y1={739.84} x2={6096.73} y2={739.84} gradientTransform="rotate(180 3797.575 486.67)" xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__s" x1={3521.18} y1={764.11} x2={5019.18} y2={764.11} xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__t" x1={4598.79} y1={764.11} x2={6096.73} y2={764.11} gradientTransform="rotate(180 3797.575 486.67)" xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__u" x1={3521.18} y1={788.38} x2={5019.18} y2={788.38} xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__v" x1={4598.79} y1={788.38} x2={6096.73} y2={788.38} gradientTransform="rotate(180 3797.575 486.67)" xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__w" x1={3521.18} y1={812.69} x2={5019.18} y2={812.69} xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__x" x1={4598.79} y1={812.65} x2={6096.73} y2={812.65} gradientTransform="rotate(180 3797.575 486.67)" xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__y" x1={3521.18} y1={836.92} x2={5019.18} y2={836.92} xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__z" x1={4598.79} y1={836.92} x2={6096.73} y2={836.92} gradientTransform="rotate(180 3797.575 486.67)" xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__A" x1={3521.18} y1={861.19} x2={5019.18} y2={861.19} xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__B" x1={4598.79} y1={861.19} x2={6096.73} y2={861.19} gradientTransform="rotate(180 3797.575 486.67)" xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__C" x1={3521.18} y1={885.46} x2={5019.18} y2={885.46} xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__D" x1={4598.79} y1={885.46} x2={6096.73} y2={885.46} gradientTransform="rotate(180 3797.575 486.67)" xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__E" x1={3521.18} y1={909.73} x2={5019.18} y2={909.73} xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__F" x1={4598.79} y1={909.73} x2={6096.73} y2={909.73} gradientTransform="rotate(180 3797.575 486.67)" xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__G" x1={3521.18} y1={934} x2={5019.18} y2={934} xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__b" x1={4598.76} y1={545.67} x2={6096.76} y2={545.67} gradientTransform="rotate(180 3797.575 486.67)" xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__I" x1={3521.18} y1={521.4} x2={5019.18} y2={521.4} xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__J" x1={4598.82} y1={521.4} x2={6096.76} y2={521.4} gradientTransform="rotate(180 3797.575 486.67)" xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__K" x1={3521.18} y1={497.13} x2={5019.18} y2={497.13} xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__L" x1={4598.82} y1={497.13} x2={6096.76} y2={497.13} gradientTransform="rotate(180 3797.575 486.67)" xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__M" x1={3521.18} y1={472.86} x2={5019.18} y2={472.86} xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__N" x1={4598.82} y1={472.86} x2={6096.76} y2={472.86} gradientTransform="rotate(180 3797.575 486.67)" xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__O" x1={3521.18} y1={448.59} x2={5019.18} y2={448.59} xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__P" x1={4598.82} y1={448.59} x2={6096.76} y2={448.59} gradientTransform="rotate(180 3797.575 486.67)" xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__Q" x1={3521.18} y1={424.34} x2={5019.18} y2={424.34} xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__R" x1={4598.82} y1={424.34} x2={6096.76} y2={424.34} gradientTransform="rotate(180 3797.575 486.67)" xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__S" x1={3521.18} y1={400.04} x2={5019.18} y2={400.04} xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__T" x1={4598.82} y1={400.04} x2={6096.76} y2={400.04} gradientTransform="rotate(180 3797.575 486.67)" xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__U" x1={3521.18} y1={375.78} x2={5019.18} y2={375.78} xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__V" x1={4598.82} y1={375.78} x2={6096.76} y2={375.78} gradientTransform="rotate(180 3797.575 486.67)" xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__W" x1={3521.18} y1={351.5} x2={5019.18} y2={351.5} xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__X" x1={4598.82} y1={351.5} x2={6096.76} y2={351.5} gradientTransform="rotate(180 3797.575 486.67)" xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__Y" x1={3521.18} y1={327.23} x2={5019.18} y2={327.23} xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__Z" x1={4598.82} y1={327.23} x2={6096.76} y2={327.23} gradientTransform="rotate(180 3797.575 486.67)" xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__aa" x1={3521.18} y1={302.96} x2={5019.18} y2={302.96} xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__ab" x1={4598.82} y1={302.96} x2={6096.76} y2={302.96} gradientTransform="rotate(180 3797.575 486.67)" xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__ac" x1={3521.18} y1={278.69} x2={5019.18} y2={278.69} xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__ad" x1={4598.82} y1={278.69} x2={6096.76} y2={278.69} gradientTransform="rotate(180 3797.575 486.67)" xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__ae" x1={3521.18} y1={254.42} x2={5019.18} y2={254.42} xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__af" x1={4598.82} y1={254.42} x2={6096.76} y2={254.42} gradientTransform="rotate(180 3797.575 486.67)" xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__ag" x1={3521.18} y1={230.15} x2={5019.18} y2={230.15} xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__ah" x1={4598.82} y1={230.19} x2={6096.76} y2={230.19} gradientTransform="rotate(180 3797.575 486.67)" xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__ai" x1={3521.18} y1={205.88} x2={5019.18} y2={205.88} xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__aj" x1={4598.82} y1={205.88} x2={6096.76} y2={205.88} gradientTransform="rotate(180 3797.575 486.67)" xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__ak" x1={3521.18} y1={181.61} x2={5019.18} y2={181.61} xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__al" x1={4598.82} y1={181.61} x2={6096.76} y2={181.61} gradientTransform="rotate(180 3797.575 486.67)" xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__am" x1={3521.18} y1={157.34} x2={5019.18} y2={157.34} xlinkHref="#prefix__a" />
                                            <linearGradient id="prefix__an" x1={4598.82} y1={157.34} x2={6096.76} y2={157.34} gradientTransform="rotate(180 3797.575 486.67)" xlinkHref="#prefix__a" />
                                        </defs>
                                        <title>{'Datov\xFD zdroj 1'}</title>
                                        <g data-name="Vrstva 2">
                                            <g data-name="Vrstva 1">
                                                <g data-name="Vrstva 1-2">
                                                    <path fill="url(#prefix__a)" d="M0 426.67h1498v2H0z" />
                                                    <path fill="url(#prefix__b)" d="M1498.39 426.67h1498v2h-1498z" />
                                                    <path d="M664 402.4c-24.59-1.18-50-2.4-91-2.4H0v2h573c40.91 0 66.32 1.22 90.89 2.39s50 2.4 91 2.4H1498v-2H754.87c-40.87 0-66.32-1.22-90.87-2.39z" fill="url(#prefix__c)" />
                                                    <path d="M2423.36 400c-40.94 0-66.37 1.22-91 2.4s-50 2.39-90.87 2.39h-743.1v2h743.1c40.94 0 66.37-1.22 91-2.4s50-2.39 90.87-2.39h573v-2z" fill="url(#prefix__d)" />
                                                    <path d="M664.05 378.13c-24.61-2.36-50-4.8-91-4.8H0v2h573c40.87 0 66.27 2.44 90.82 4.79s50 4.8 91 4.8H1498v-2H754.87c-40.87 0-66.27-2.44-90.82-4.79z" fill="url(#prefix__e)" />
                                                    <path d="M2423.36 373.33c-41 0-66.41 2.44-91 4.8s-50 4.79-90.82 4.79h-743.1v2h743.1c41 0 66.41-2.44 91-4.8s49.95-4.79 90.82-4.79h573v-2z" fill="url(#prefix__f)" />
                                                    <path d="M664.09 353.83c-24.61-3.54-50.07-7.2-91.06-7.2H0v2h573c40.85 0 66.23 3.65 90.78 7.17s50.07 7.2 91.06 7.2H1498v-2H754.87c-40.87 0-66.23-3.65-90.78-7.17z" fill="url(#prefix__g)" />
                                                    <path d="M2423.36 346.67c-41 0-66.45 3.66-91.06 7.2s-49.93 7.17-90.78 7.17h-743.1v2h743.1c41 0 66.45-3.66 91.06-7.2s49.93-7.17 90.78-7.17h573v-2z" fill="url(#prefix__h)" />
                                                    <path d="M664.12 329.6C639.5 324.88 614 320 573 320H0v2h573c40.84 0 66.21 4.86 90.75 9.57s50.09 9.6 91.12 9.6H1498v-2H754.87c-40.87 0-66.21-4.86-90.75-9.57z" fill="url(#prefix__i)" />
                                                    <path d="M2423.36 320c-41 0-66.48 4.88-91.11 9.6s-49.91 9.57-90.73 9.57h-743.1v2h743.1c41 0 66.48-4.88 91.11-9.6s49.91-9.57 90.73-9.57h573v-2z" fill="url(#prefix__j)" />
                                                    <path d="M664.18 305.32c-24.63-5.92-50.11-12-91.15-12H0v2h573c40.8 0 66.16 6.09 90.69 12s50.11 12 91.15 12H1498v-2H754.87c-40.8-.03-66.16-6.12-90.69-12z" fill="url(#prefix__k)" />
                                                    <path d="M2423.36 293.33c-41 0-66.52 6.11-91.15 12s-49.89 12-90.69 12h-743.1v2h743.1c41 0 66.52-6.11 91.15-12s49.89-12 90.69-12h573v-2z" fill="url(#prefix__l)" />
                                                    <path d="M664.23 281.08c-24.65-7.08-50.14-14.41-91.2-14.41H0v2h573c40.78 0 66.13 7.29 90.64 14.34s50.14 14.41 91.2 14.41H1498v-2H754.87c-40.78 0-66.13-7.29-90.64-14.34z" fill="url(#prefix__m)" />
                                                    <path d="M2423.36 266.67c-41.06 0-66.55 7.33-91.2 14.41s-49.86 14.34-90.64 14.34h-743.1v2h743.1c41.06 0 66.55-7.33 91.2-14.41s49.86-14.34 90.64-14.34h573v-2z" fill="url(#prefix__n)" />
                                                    <path d="M664.25 256.82C639.6 248.55 614.1 240 573 240H0v2h573c40.77 0 66.11 8.5 90.62 16.72s50.15 16.82 91.25 16.82H1498v-2H754.87c-40.77 0-66.11-8.54-90.62-16.72z" fill="url(#prefix__o)" />
                                                    <path d="M2423.36 240c-41.08 0-66.58 8.55-91.24 16.82-24.5 8.22-49.84 16.72-90.6 16.72h-743.1v2h743.1c41.08 0 66.58-8.55 91.24-16.82 24.5-8.22 49.84-16.72 90.6-16.72h573v-2z" fill="url(#prefix__p)" />
                                                    <path d="M664.31 232.57c-24.67-9.46-50.17-19.24-91.28-19.24H0v2h573c40.74 0 66.07 9.71 90.56 19.1 24.67 9.46 50.18 19.24 91.28 19.24H1498v-2H754.87c-40.73 0-66.07-9.67-90.56-19.1z" fill="url(#prefix__q)" />
                                                    <path d="M2423.36 213.33c-41.11 0-66.61 9.78-91.28 19.24-24.5 9.39-49.83 19.1-90.56 19.1h-743.1v2h743.1c41.11 0 66.61-9.78 91.28-19.24 24.49-9.39 49.83-19.1 90.56-19.1h573v-2z" fill="url(#prefix__r)" />
                                                    <path d="M664.35 208.31c-24.68-10.64-50.19-21.64-91.32-21.64H0v2h573c40.71 0 66 10.92 90.52 21.48 24.68 10.64 50.19 21.64 91.32 21.64H1498v-2H754.87c-40.71 0-66.04-10.92-90.52-21.48z" fill="url(#prefix__s)" />
                                                    <path d="M2423.36 186.67c-41.13 0-66.64 11-91.32 21.64-24.48 10.56-49.81 21.48-90.52 21.48h-743.1v2h743.1c41.13 0 66.64-11 91.32-21.64 24.48-10.56 49.81-21.48 90.52-21.48h573v-2z" fill="url(#prefix__t)" />
                                                    <path d="M664.37 184.06C639.69 172.23 614.16 160 573 160H0v2h573c40.71 0 66 12.13 90.5 23.86 24.69 11.83 50.21 24.06 91.37 24.06H1498v-2H754.87c-40.71 0-66.02-12.13-90.5-23.86z" fill="url(#prefix__u)" />
                                                    <path d="M2423.36 160c-41.15 0-66.67 12.23-91.35 24.06-24.48 11.73-49.8 23.86-90.49 23.86h-743.1v2h743.1c41.15 0 66.67-12.23 91.35-24.06 24.48-11.73 49.8-23.86 90.49-23.86h573v-2z" fill="url(#prefix__v)" />
                                                    <path d="M664.42 159.76c-24.69-13-50.22-26.47-91.39-26.47H0v2h573c40.67 0 66 13.34 90.45 26.24 24.69 13 50.22 26.47 91.39 26.47H1498v-2H754.87c-40.67 0-65.98-13.34-90.45-26.24z" fill="url(#prefix__w)" />
                                                    <path d="M2423.36 133.33c-41.17 0-66.7 13.46-91.39 26.47-24.47 12.9-49.78 26.24-90.45 26.24h-743.1v2h743.1c41.17 0 66.7-13.46 91.39-26.47 24.47-12.9 49.78-26.24 90.45-26.24h573v-2z" fill="url(#prefix__x)" />
                                                    <path d="M664.45 135.55c-24.7-14.2-50.23-28.88-91.42-28.88H0v2h573c40.65 0 66 14.55 90.42 28.62 24.7 14.2 50.23 28.88 91.42 28.88H1498v-2H754.87c-40.65 0-65.95-14.55-90.42-28.62z" fill="url(#prefix__y)" />
                                                    <path d="M2423.36 106.67c-41.19 0-66.72 14.68-91.42 28.88-24.47 14.07-49.77 28.62-90.42 28.62h-743.1v2h743.1c41.19 0 66.72-14.68 91.42-28.88 24.47-14.07 49.77-28.62 90.42-28.62h573v-2z" fill="url(#prefix__z)" />
                                                    <path d="M664.46 111.3C639.76 95.91 614.22 80 573 80H0v2h573c40.65 0 65.94 15.76 90.41 31 24.7 15.39 50.24 31.3 91.46 31.3H1498v-2H754.87c-40.65-.01-65.94-15.77-90.41-31z" fill="url(#prefix__A)" />
                                                    <path d="M2423.36 80c-41.21 0-66.75 15.91-91.45 31.3-24.46 15.23-49.76 31-90.39 31h-743.1v2h743.1c41.21 0 66.75-15.91 91.45-31.3 24.46-15.23 49.76-31 90.39-31h573v-2z" fill="url(#prefix__B)" />
                                                    <path d="M664.51 87C639.8 70.47 614.25 53.33 573 53.33H0v2h573c40.62 0 65.91 17 90.36 33.38 24.71 16.57 50.26 33.71 91.48 33.71H1498v-2H754.87c-40.62 0-65.87-16.97-90.36-33.42z" fill="url(#prefix__C)" />
                                                    <path d="M2423.36 53.33c-41.22 0-66.77 17.14-91.48 33.71-24.45 16.41-49.74 33.38-90.36 33.38h-743.1v2h743.1c41.22 0 66.77-17.14 91.48-33.71 24.45-16.41 49.74-33.38 90.36-33.38h573v-2z" fill="url(#prefix__D)" />
                                                    <path d="M664.53 62.79C639.82 45 614.27 26.67 573 26.67H0v2h573c40.6 0 65.88 18.17 90.34 35.75 24.71 17.76 50.26 36.12 91.5 36.12H1498v-2H754.87c-40.6 0-65.87-18.17-90.34-35.75z" fill="url(#prefix__E)" />
                                                    <path d="M2423.36 26.67c-41.24 0-66.79 18.36-91.5 36.12-24.46 17.58-49.74 35.75-90.34 35.75h-743.1v2h743.1c41.24 0 66.79-18.36 91.5-36.12 24.46-17.58 49.74-35.75 90.34-35.75h573v-2z" fill="url(#prefix__F)" />
                                                    <path d="M664.54 38.54C639.83 19.59 614.27 0 573 0H0v2h573c40.6 0 65.88 19.38 90.33 38.13C688 59.08 713.6 78.67 754.87 78.67H1498v-2H754.87c-40.6 0-65.87-19.38-90.33-38.13z" fill="url(#prefix__G)" />
                                                    <path d="M2423.36 0c-41.26 0-66.81 19.59-91.53 38.54-24.45 18.75-49.73 38.13-90.31 38.13h-743.1v2h743.1c41.26 0 66.81-19.59 91.53-38.54C2357.5 21.38 2382.78 2 2423.36 2h573V0z" fill="url(#prefix__H)" />
                                                    <path d="M663.87 450.94c-24.57 1.17-50 2.39-90.87 2.39H0v2h573c40.94 0 66.37-1.22 91-2.4s50-2.39 90.87-2.39H1498v-2H754.84c-40.94 0-66.37 1.22-90.97 2.4z" fill="url(#prefix__I)" />
                                                    <path d="M2423.33 453.33c-40.9 0-66.3-1.22-90.87-2.39s-50-2.4-91-2.4h-743.1v2h743.1c40.9 0 66.3 1.22 90.87 2.39s50 2.4 91 2.4h573v-2z" fill="url(#prefix__J)" />
                                                    <path d="M663.82 475.21c-24.55 2.36-50 4.79-90.82 4.79H0v2h573c41 0 66.41-2.44 91-4.79s50-4.79 90.82-4.79H1498v-2H754.84c-40.97 0-66.41 2.44-91.02 4.79z" fill="url(#prefix__K)" />
                                                    <path d="M2423.33 480c-40.87 0-66.27-2.43-90.82-4.79s-50.05-4.79-91-4.79h-743.1v2h743.1c40.87 0 66.27 2.43 90.82 4.79s50.05 4.79 91 4.79h573v-2z" fill="url(#prefix__L)" />
                                                    <path d="M663.78 499.49c-24.55 3.53-49.93 7.18-90.78 7.18H0v2h573c41 0 66.45-3.66 91.06-7.2s49.93-7.18 90.78-7.18H1498v-2H754.84c-40.99 0-66.45 3.71-91.06 7.2z" fill="url(#prefix__M)" />
                                                    <path d="M2423.33 506.67c-40.85 0-66.23-3.65-90.78-7.18s-50.07-7.2-91.06-7.2h-743.1v2h743.1c40.85 0 66.23 3.65 90.78 7.18s50.07 7.2 91.06 7.2h573v-2z" fill="url(#prefix__N)" />
                                                    <path d="M663.73 523.77c-24.53 4.7-49.9 9.56-90.73 9.56H0v2h573c41 0 66.48-4.88 91.11-9.6s49.9-9.56 90.73-9.56H1498v-2H754.84c-41.01 0-66.48 4.88-91.11 9.6z" fill="url(#prefix__O)" />
                                                    <path d="M2423.33 533.33c-40.83 0-66.2-4.86-90.73-9.56s-50.1-9.6-91.11-9.6h-743.1v2h743.1c40.83 0 66.2 4.86 90.73 9.56s50.1 9.6 91.11 9.6h573v-2z" fill="url(#prefix__P)" />
                                                    <path d="M663.69 548c-24.53 5.88-49.89 12-90.69 12H0v2h573c41 0 66.52-6.11 91.15-12s49.89-12 90.69-12H1498v-2H754.84c-41.04 0-66.52 6.11-91.15 12z" fill="url(#prefix__Q)" />
                                                    <path d="M2423.33 560c-40.8 0-66.16-6.09-90.69-12s-50.11-12-91.15-12h-743.1v2h743.1c40.8 0 66.16 6.09 90.69 12s50.11 12 91.15 12h573v-2z" fill="url(#prefix__R)" />
                                                    <path d="M663.64 572.33c-24.51 7-49.86 14.34-90.64 14.34H0v2h573c41.06 0 66.55-7.33 91.2-14.41s49.86-14.34 90.64-14.34H1498v-2H754.84c-41.06 0-66.55 7.33-91.2 14.41z" fill="url(#prefix__S)" />
                                                    <path d="M2423.33 586.67c-40.78 0-66.13-7.29-90.64-14.34s-50.14-14.41-91.2-14.41h-743.1v2h743.1c40.78 0 66.13 7.29 90.64 14.34s50.14 14.41 91.2 14.41h573v-2z" fill="url(#prefix__T)" />
                                                    <path d="M663.6 596.61c-24.5 8.22-49.84 16.72-90.6 16.72H0v2h573c41.08 0 66.58-8.55 91.24-16.82 24.5-8.22 49.84-16.72 90.6-16.72H1498v-2H754.84c-41.08 0-66.58 8.55-91.24 16.82z" fill="url(#prefix__U)" />
                                                    <path d="M2423.33 613.33c-40.76 0-66.1-8.5-90.6-16.72-24.66-8.27-50.16-16.82-91.24-16.82h-743.1v2h743.1c40.76 0 66.1 8.5 90.6 16.72 24.66 8.27 50.16 16.82 91.24 16.82h573v-2z" fill="url(#prefix__V)" />
                                                    <path d="M663.56 620.9C639.07 630.29 613.73 640 573 640H0v2h573c41.1 0 66.61-9.78 91.28-19.23 24.49-9.39 49.82-19.1 90.56-19.1H1498v-2H754.84c-41.11 0-66.61 9.78-91.28 19.23z" fill="url(#prefix__W)" />
                                                    <path d="M2423.33 640c-40.74 0-66.07-9.71-90.56-19.1-24.67-9.45-50.18-19.23-91.28-19.23h-743.1v2h743.1c40.73 0 66.07 9.71 90.56 19.1 24.67 9.45 50.17 19.23 91.28 19.23h573v-2z" fill="url(#prefix__X)" />
                                                    <path d="M663.52 645.19C639 655.75 613.71 666.67 573 666.67H0v2h573c41.13 0 66.64-11 91.32-21.65 24.48-10.56 49.81-21.48 90.52-21.48H1498v-2H754.84c-41.13 0-66.64 11-91.32 21.65z" fill="url(#prefix__Y)" />
                                                    <path d="M2423.33 666.67c-40.71 0-66-10.92-90.52-21.48-24.68-10.65-50.19-21.65-91.32-21.65h-743.1v2h743.1c40.71 0 66 10.92 90.52 21.48 24.68 10.65 50.19 21.65 91.32 21.65h573v-2z" fill="url(#prefix__Z)" />
                                                    <path d="M663.49 669.47C639 681.2 613.69 693.33 573 693.33H0v2h573c41.15 0 66.67-12.23 91.35-24.05 24.48-11.73 49.8-23.86 90.49-23.86H1498v-2H754.84c-41.15 0-66.67 12.23-91.35 24.05z" fill="url(#prefix__aa)" />
                                                    <path d="M2423.33 693.33c-40.69 0-66-12.13-90.49-23.86-24.68-11.82-50.2-24.05-91.35-24.05h-743.1v2h743.1c40.69 0 66 12.13 90.49 23.86 24.68 11.82 50.2 24.05 91.35 24.05h573v-2z" fill="url(#prefix__ab)" />
                                                    <path d="M663.45 693.76C639 706.66 613.67 720 573 720H0v2h573c41.17 0 66.7-13.46 91.39-26.47 24.47-12.9 49.78-26.24 90.45-26.24H1498v-2H754.84c-41.17 0-66.7 13.46-91.39 26.47z" fill="url(#prefix__ac)" />
                                                    <path d="M2423.33 720c-40.67 0-66-13.34-90.45-26.24-24.69-13-50.22-26.47-91.39-26.47h-743.1v2h743.1c40.67 0 66 13.34 90.45 26.24 24.69 13 50.22 26.47 91.39 26.47h573v-2z" fill="url(#prefix__ad)" />
                                                    <path d="M663.42 718.05C639 732.12 613.65 746.67 573 746.67H0v2h573c41.19 0 66.72-14.68 91.42-28.88 24.47-14.07 49.77-28.62 90.42-28.62H1498v-2H754.84c-41.19 0-66.72 14.68-91.42 28.88z" fill="url(#prefix__ae)" />
                                                    <path d="M2423.33 746.67c-40.65 0-65.95-14.55-90.42-28.62-24.7-14.2-50.23-28.88-91.42-28.88h-743.1v2h743.1c40.65 0 66 14.55 90.42 28.62 24.7 14.2 50.23 28.88 91.42 28.88h573v-2z" fill="url(#prefix__af)" />
                                                    <path d="M663.39 742.34c-24.46 15.23-49.76 31-90.39 31H0v2h573c41.21 0 66.75-15.91 91.45-31.3 24.46-15.23 49.76-31 90.39-31H1498v-2H754.84c-41.21-.04-66.75 15.96-91.45 31.3z" fill="url(#prefix__ag)" />
                                                    <path d="M2423.33 773.29c-40.63 0-65.93-15.76-90.39-31-24.7-15.39-50.24-31.3-91.45-31.3h-743.1v2h743.1c40.63 0 65.93 15.76 90.39 31 24.7 15.39 50.24 31.3 91.45 31.3h573v-2z" fill="url(#prefix__ah)" />
                                                    <path d="M663.36 766.63C638.91 783 613.62 800 573 800H0v2h573c41.22 0 66.77-17.14 91.48-33.71 24.45-16.41 49.74-33.37 90.36-33.37H1498v-2H754.84c-41.22 0-66.77 17.14-91.48 33.71z" fill="url(#prefix__ai)" />
                                                    <path d="M2423.33 800c-40.62 0-65.91-17-90.36-33.37-24.71-16.57-50.26-33.71-91.48-33.71h-743.1v2h743.1c40.62 0 65.91 17 90.36 33.37 24.71 16.57 50.26 33.71 91.48 33.71h573v-2z" fill="url(#prefix__aj)" />
                                                    <path d="M663.34 790.92c-24.46 17.58-49.74 35.75-90.34 35.75H0v2h573c41.24 0 66.79-18.37 91.5-36.13 24.5-17.54 49.74-35.75 90.34-35.75H1498v-2H754.84c-41.24 0-66.79 18.37-91.5 36.13z" fill="url(#prefix__ak)" />
                                                    <path d="M2423.33 826.67c-40.6 0-65.88-18.17-90.34-35.75-24.71-17.76-50.26-36.13-91.5-36.13h-743.1v2h743.1c40.6 0 65.88 18.17 90.34 35.75 24.71 17.76 50.26 36.13 91.5 36.13h573v-2z" fill="url(#prefix__al)" />
                                                    <path d="M663.31 815.21C638.86 834 613.58 853.33 573 853.33H0v2h573c41.26 0 66.81-19.59 91.53-38.54 24.45-18.74 49.73-38.12 90.31-38.12H1498v-2H754.84c-41.26 0-66.84 19.59-91.53 38.54z" fill="url(#prefix__am)" />
                                                    <path d="M2423.33 853.33c-40.58 0-65.86-19.38-90.31-38.12-24.72-19-50.27-38.54-91.53-38.54h-743.1v2h743.1c40.58 0 65.86 19.38 90.31 38.12 24.72 19 50.27 38.54 91.53 38.54h573v-2z" fill="url(#prefix__an)" />
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <div className="section-side">
                                    <img className="section-side-image" src={config.paths.images + 'img_homepage_mcore.png'} alt="MCORE" />
                                    <img className={'section-side-icon'} src={config.paths.icons + (this.state.intelligent_solution_right ? 'ic_plus_white.svg' : 'ic_minus_white.svg')} alt={i18n.t('general.showMore')} onClick={this.shiftRight} />
                                </div>
                                <div className="section-text">
                                    <span className="section-route">
                                        {i18n.t('home.energyFusion.breadcrumb')}
                                    </span>
                                    <h2 className="section-heading" dangerouslySetInnerHTML={{ __html: i18n.t('home.energyFusion.title') }}></h2>
                                    <p className="section-desc">{i18n.t('home.energyFusion.text')}</p>
                                    <div className="section-7-slider">
                                        <img className="section-7-slider-arrow" src={config.paths.icons + 'ic_double_arrow_bluegreen_gradient.png'} alt="Nastavit" />
                                        <br />
                                        <span className="section-7-slider-desc">{i18n.t('home.energyFusion.label')}</span>
                                        <div className="section-7-slider-cont">
                                            <Slider
                                                id="slider_7"
                                                className="section-7-slider-range"
                                                defaultValue={120}
                                                min={40}
                                                max={300}
                                                step={4}
                                                onChange={this.sliderHandle}
                                                trackStyle={{
                                                    backgroundImage: "url('" + slider_head + "')",
                                                    height: 10,
                                                }}
                                                handleStyle={{
                                                    borderColor: 'blue',
                                                    height: 28,
                                                    width: 18,
                                                    marginLeft: -9,
                                                    marginTop: -9,
                                                    backgroundImage: "url('" + slider_handle + "')",
                                                }}
                                                railStyle={{
                                                    backgroundImage: "url('" + slider_tail + "')",
                                                    height: 10,
                                                }}
                                            />
                                            <div className="section-7-slider-label">
                                                <div className="slider-info-left">
                                                    <span className="slider-info-text">
                                                        <b>{i18n.t('general.totalArea')}</b>
                                                    </span>
                                                    <br />
                                                    <span className="slider-info-number">
                                                        <b>{this.state.slider_value}</b>
                                                    </span>
                                                    m<sup>2</sup>
                                                </div>
                                                <div className="slider-info-right">
                                                    <span className="slider-info-text" dangerouslySetInnerHTML={{ __html: i18n.t('home.energyFusion.avgSavings') }}></span>
                                                    <br />
                                                    <span className="slider-info-number">
                                                        <b>{numberWithSpaces(this.state.slider_value * 198.75)}</b> Kč
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <Link to={config.pages.home.url} className="section-7-btn">
                    energy fusion
                  </Link> */}
                                </div>
                                <div className="section-side">
                                    <img className="section-side-image" src={config.paths.images + 'img_homepage_iphone.png'} alt="LOXONE" />
                                    <img className="section-side-icon" src={config.paths.icons + (this.state.intelligent_solution_left ? 'ic_plus_white.svg' : 'ic_minus_white.svg')} alt={i18n.t('general.showMore')} onClick={this.shiftLeft} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <img className="preload" style={{ display: 'none' }} src={config.paths.backgrounds + 'img_intro_video_last_frame.png'} />
                </div>
                {/* <div className="mobile-container"></div> */}
            </div>
        );
    }
}

export default Home;
