
import * as React from 'react';
import Main from './Main';

class App extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Main />
        );
    }
}

export default App;