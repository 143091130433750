import * as React from "react";
import config from "../../../../config/config.json";
import { Menu } from "../components/Menu";
import { Link } from "react-router-dom";
import Slider, { createSliderWithTooltip } from "rc-slider";
import "../../../../node_modules/rc-slider/assets/index.css";
import { postOffers } from "../api.js";
import { FormItemUpload } from "../components/FormItemUpload.jsx";
import { localConsoleLog } from "../functions.js";
import smoothScrollIntoView from "smooth-scroll-into-view-if-needed";
import { getPageSEO } from "../functions";

import i18n from '../i18n';
import Loader from "../components/Loader";

class PripadovaStudie extends React.Component {
  constructor(props) {
    super(props);

    this.pageSEO = getPageSEO();

    document.title = this.pageSEO.title;
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", this.pageSEO.keywords);
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", this.pageSEO.description);

    document.querySelector('meta[name=\"title\"]').setAttribute('content', this.pageSEO.metaTitle);

    this.state = {
      slider_value: 120,
      name: "",
      city: "",
      tel: "",
      email: "",
      note: "",
      phase: "",
      sources: "",
      fve: false,
      inteligent_control: false,
      floor_structure: false,
      clicked: false,
      submitted: false,
      valid_email: "",
      file_input: { display: "none" },
      region: "",
      formItems: {
        podklady: {
          value: "",
          error: ""
        }
      },
      sent: false,
      error_message: "",

      missing_files: false,
      intro_loaded: false,

      otazky_clanky: false,
      otazky_clanky_hiding: false,

      otazky2_clanky: false,
      otazky2_clanky_hiding: false,

      otazky3_clanky: false,
      otazky3_clanky_hiding: false,

      otazky4_clanky: false,
      otazky4_clanky_hiding: false,

      arguments_clanky: false,
      arguments_clanky_hiding: false,

      loxone_clanky: false,
      loxone_clanky_hiding: false,

      mcore_clanky: false,
      mcore_clanky_hiding: false,

      why_heatflow_clanky: false,
      why_heatflow_clanky_hiding: false,

      montaz_clanky: false,
      montaz_clanky_hiding: false,

      priprava_clanky: false,
      priprava_clanky_hiding: false,

      navrh_clanky: false,
      navrh_clanky_hiding: false,

      poradce_clanky: false,
      poradce_clanky_hiding: false,

      mapa_ended: false,
      projekt02_ended: false,
      projekt03_ended: false,
      projekt04_ended: false,

      mapa_played: false,
      projekt02_played: false,
      projekt03_played: false,
      projekt04_played: false,
      dodavka_animation_played: false,

      dodavka_animation_state: 0,

      loaded: 0,
      loader_loaded: false,
      showLoader: true,
      loadingStatus: 0,

      gdpr_modal: false
    };
    this.mapa = React.createRef();
    this.projekt02 = React.createRef();
    this.projekt03 = React.createRef();
    this.projekt04 = React.createRef();
    this.dodavka_section = React.createRef();

    this.sliderHandle = this.sliderHandle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.posted = this.posted.bind(this);
    this.onScroll = this.onScroll.bind(this);
    this.checkNumber = this.checkNumber.bind(this);
    this.expandSection = this.expandSection.bind(this);
    this.showAgain = this.showAgain.bind(this);
    this.changeFormUploadItem = this.changeFormUploadItem.bind(this);

    this.loaderLoaded = this.loaderLoaded.bind(this);
    this.resourceLoaded = this.resourceLoaded.bind(this);

    this.openPrivacy = this.openPrivacy.bind(this);
    this.modalClicked = this.modalClicked.bind(this);

    this.scrollToMapa = this.scrollToMapa.bind(this);
    this.preventEnter = this.preventEnter.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll, { passive: true });
    window.addEventListener("keydown", this.preventEnter);
    document.body.style.overflowY = "hidden";

    // var load = 0;
    // this.loader = setInterval(() => {
    //   this.setState({
    //     loadingStatus: Math.round((load / 13) * 100)
    //   });
    //   load = load + 1;
    //   if (load == 13) {
    //     this.setState({ showLoader: false });
    //     setTimeout(() => {
    //       document.body.style.overflowY = "auto";
    //       // this.setState({ intro_loaded: true });
    //     }, 500);
    //   }
    // }, 150);

    // setTimeout(() => {
    //   clearInterval(this.loader);
    //   this.setState({ intro_loaded: true });
    // }, 2500);

    // setTimeout(() => {
    //   this.setState({ intro_loaded: true });
    // }, 1000);
    this.expandSection("otazky2");
    this.expandSection("otazky3");
    this.expandSection("otazky4");

    let modals = Array.prototype.slice.call(document.querySelectorAll('.gdpr-modal'));

    modals.forEach(element => {
      document.body.appendChild(element);
    });
  }

  componentWillUnmount() {
    document.body.style.overflowY = "scroll";
    window.removeEventListener("scroll", this.onScroll);
  }
  preventEnter(e) {
    if (e.keyCode == 13) {
      e.preventDefault();
      return false;
    }
  }
  modalClicked(e) {
    if (e.target.dataset.name == "modal") {
      this.openPrivacy();
    }
  }
  openPrivacy() {
    if (this.state.gdpr_modal) {
      document.body.style.overflowY = "scroll";
      this.setState({
        gdpr_modal: false
      });
    } else {
      document.body.style.overflowY = "hidden";
      this.setState({
        gdpr_modal: true
      });
    }
  }
  resourceLoaded() {
    var temp = this.state.loaded;

    this.setState({
      loaded: temp + 1,
      loadingStatus: Math.round(((temp + 1) / 4) * 100)
    });
    if (temp == 3) {
      this.setState({ showLoader: false });
      document.body.style.overflowY = "scroll";
      setTimeout(() => {
        this.setState({ intro_loaded: true });
      }, 500);
    }
  }
  scrollToMapa() {
    smoothScrollIntoView(this.mapa.current, {
      behavior: "smooth",
      block: "start",
      duration: 1000
    });
  }
  onScroll(e) {}

  expandSection(section) {
    if (section == "otazky") {
      if (this.state.otazky_clanky) {
        this.setState({ otazky_clanky_hiding: true });
        setTimeout(() => {
          this.setState({ otazky_clanky: false, otazky_clanky_hiding: false });
        }, 700);
      } else {
        this.setState({ otazky_clanky: true });
      }
    } else if (section == "otazky2") {
      if (this.state.otazky2_clanky) {
        this.setState({ otazky2_clanky_hiding: true });
        setTimeout(() => {
          this.setState({
            otazky2_clanky: false,
            otazky2_clanky_hiding: false
          });
        }, 700);
      } else {
        this.setState({ otazky2_clanky: true });
      }
    } else if (section == "otazky3") {
      if (this.state.otazky3_clanky) {
        this.setState({ otazky3_clanky_hiding: true });
        setTimeout(() => {
          this.setState({
            otazky3_clanky: false,
            otazky3_clanky_hiding: false
          });
        }, 700);
      } else {
        this.setState({ otazky3_clanky: true });
      }
    } else if (section == "otazky4") {
      if (this.state.otazky4_clanky) {
        this.setState({ otazky4_clanky_hiding: true });
        setTimeout(() => {
          this.setState({
            otazky4_clanky: false,
            otazky4_clanky_hiding: false
          });
        }, 700);
      } else {
        this.setState({ otazky4_clanky: true });
      }
    } else if (section == "arguments") {
      if (this.state.arguments_clanky) {
        this.setState({ arguments_clanky_hiding: true });
        setTimeout(() => {
          this.setState({
            arguments_clanky: false,
            arguments_clanky_hiding: false
          });
        }, 700);
      } else {
        this.setState({ arguments_clanky: true });
      }
    } else {
    }
  }

  sliderHandle(value) {
    this.setState({ slider_value: value });
  }
  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (target.type == "select-one" && value == "Mám projekt") {
      this.setState({
        file_input: { display: "block" }
      });
    }
    if (target.type == "select-one" && value != "Mám projekt") {
      this.setState({
        file_input: { display: "none" }
      });
    }
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value) && name == "email") {
      this.setState({ valid_email: "" });
    }

    this.setState({
      [name]: value
    });
  }
  handleSubmit(e) {
    e.preventDefault();

    const data = {
      type: "Cenová nabídka",
      origin: "Případová studie",
      name: this.state.name,
      city: this.state.region,
      phone_number: this.state.tel,
      email: this.state.email,
      note: this.state.note,
      area: this.state.slider_value,
      stage: this.state.phase,
      intelligent_controll: this.state.inteligent_control,
      floor_structure: this.state.floor_structure,
      fve: this.state.fve,
      sources: this.state.sources
    };

    if (this.state.phase == "Mám projekt" && this.state.sources == "") {
      this.setState({ submitted: true, missing_files: true });
      return false;
    }

    if (
      this.state.name != "" &&
      this.state.region != "" &&
      this.state.tel != "" &&
      this.state.email != "" &&
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email) &&
      this.state.slider_value != ""
      // this.state.phase != ""
    ) {
      if (!this.state.clicked) {
        this.setState({
          clicked: true,
          sent: true,
          title: i18n.t('general.sending'),
          form_response: i18n.t('general.loadingFiles')
        });
        postOffers.request(data, this.posted);
      }
    } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email)) {
      this.setState({ valid_email: "not_valid", submitted: true });
    }
    else {
      this.setState({ submitted: true });
    }
  }
  posted(response) {
    if (response == "Too large") {
      this.setState({
        title: "",
        error_message: i18n.t('general.filesTooLarge'),
        clicked: false,
        sent: false
      });
      return;
    }
    if (response.status == 200 || response.status == 201) {
      this.setState({
        title: i18n.t('general.sended'),
        slider_value: 120,
        name: "",
        city: "",
        region: "",
        tel: "",
        email: "",
        note: "",
        phase: "",
        sources: "",
        form_response: i18n.t('general.takeCareASAP'),
        fve: false,
        inteligent_control: false,
        floor_structure: false,
        submitted: true,
        sent: true
      });

      window.dataLayer.push({
        'event': 'lead',
        'formLocation': 'pripadova-studie',
      });
    } else {
      this.setState({
        title: i18n.t('general.sendingFailed'),
        form_response:
          i18n.t('general.contactUsViaEmail'),
        clicked: false
      });
    }
    localConsoleLog(response);
  }

  showAgain() {
    this.setState({ submitted: false, clicked: false });
  }

  changeFormUploadItem(name, file) {
    if (name == "delete") {
      var temp = this.state.sources;
      temp.splice(file, 1);

      this.setState({
        sources: temp,
        formItems: {
          podklady: {
            value: temp,
            error: ""
          }
        }
      });
    } else {
      this.setState({ missing_files: false });
      if (this.state[name]) {
        var temp = this.state[name];
        temp.push(file);

        this.setState({
          [name]: temp,
          formItems: {
            podklady: {
              value: temp,
              error: ""
            }
          }
        });
      } else {
        this.setState({
          [name]: [file],
          formItems: {
            podklady: {
              value: [file],
              error: ""
            }
          }
        });
      }
    }
  }
  checkNumber(e) {
    let key = Number(e.key);
    if (isNaN(key) || e.key === null) {
      e.preventDefault();
    }
  }
  loaderLoaded(e) {
    this.setState({ loader_loaded: true });
  }

  render() {
    var slider_handle = config.paths.icons + "ic_handle_orange.png";
    var slider_head = config.paths.icons + "ic_mobile_slider_bg_active.png";
    var slider_tail = config.paths.icons + "ic_mobile_slider_bg.png";

    return (
      <div className="main-container" id="main-container-studie2">
        <img
          className="control-load"
          src={config.paths.backgrounds + "texture-loader-transparent.svg"}
          alt=""
          onLoad={this.loaderLoaded}
          style={{ display: "none" }}
        />
        <Loader 
          showLoader={this.state.showLoader}
          loadingStatus={this.state.loadingStatus}
          loader_loaded={this.state.loader_loaded}
        />
        <div
          className={
            "studie2-section studie2-intro " +
            (this.state.intro_loaded ? "loaded" : "")
          }
        >
          <div className="studie2-intro-bg"></div>
          <div className="intro-text">
            <div className="intro-route">
              <b>heated. &gt;</b> {i18n.t('reference.caseStudy')}
            </div>
            <div
              className="intro-heading"
              onClick={() => {
                if (this.state.intro_loaded) {
                  this.setState({ intro_loaded: false });
                } else {
                  this.setState({ intro_loaded: true });
                }
              }}
            >
              <span className="orange">
                <b>{ i18n.t('caseStudy.title_01') }</b>
              </span>{" "}
              { i18n.t('caseStudy.title_02') }
              ...
            </div>
            <div className="intro-info">
              {/* <div className="intro-info-top">
                <div className="intro-info-block">počet realizací</div>
                <div className="intro-info-block">
                  specializovaných oddělení
                </div>
                <div className="intro-info-block">členů týmu</div>
                <div className="clear"></div>
              </div> */}
              <img
                className="intro-info-img"
                src={config.paths.images + "img_studie2_intro_line_" + i18n.language + ".svg"}
                alt={i18n.t('general.further')}
              />
              {/* <div className="intro-info-bottom orange">
                <div className="intro-info-block">2300+</div>
                <div className="intro-info-block">7</div>
                <div className="intro-info-block">35+</div>
                <div className="clear"></div>
              </div> */}
            </div>

            <img
              className="sluzba-scroll-down h-arrow-down"
              src={config.paths.icons + "ic_arrow_down_white.png"}
              alt={i18n.t('general.further')}
              onClick={this.scrollToMapa}
            />
          </div>
        </div>

        <div
          className="studie2-section studie2-section2 mapa-section"
          ref={this.mapa}
        >
          <div className="section-content">
            <div className="section-content-wrapper">
              <div className="section-block-left">
                <div className="section-heading" dangerouslySetInnerHTML={{ __html: i18n.t('caseStudy.section01.title') }}></div>
                <div className="section-prubeh-desc" dangerouslySetInnerHTML={{ __html: i18n.t('caseStudy.section01.text') }}>
                </div>
              </div>

              <div className="section-block-right">
                <img
                  className="section-block-srovnani"
                  src={
                    config.paths.images + "img_studie2_srovnani_info_darker_" + i18n.language + ".svg"
                  }
                  alt="Srovnání"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="studie2-section sluzba-prubeh">
          <div className="image-bg"></div>
          <div className="color-bg"></div>
          <div className="section-content">
            <div className="section-content-wrapper">
              <div className="section-block-left">
                <div className="section-heading">
                  { i18n.t('caseStudy.section02.title') }
                </div>
                <div className="section-prubeh-divider">
                  ------------------------------------
                </div>
                <div className="section-prubeh-desc" dangerouslySetInnerHTML={{ __html: i18n.t('caseStudy.section02.text') }}></div>
              </div>
            </div>
          </div>
        </div>
        <div className="studie2-section studie2-white-section">
          <div className="wave-bg"></div>
          <div className="section-content">
            <div className="section-heading">
            { i18n.t('caseStudy.section03.title') }
            </div>
            <div className="section-prubeh-desc" dangerouslySetInnerHTML={{ __html: i18n.t('caseStudy.section03.text') }}></div>
            <div className="section-otazky-table">
              <div className="section-otazky-row">
                <div className="section-otazky-block">
                  <img
                    className="section-otazky-table-head"
                    src={config.paths.icons + "ic_radiator.png"}
                    alt=""
                  />
                  <div className="section-otazky-block-title">{ i18n.t('caseStudy.section03.tableTitle1') }</div>
                </div>
                <div className="section-otazky-block">
                  <img
                    className="section-otazky-table-head"
                    src={config.paths.icons + "ic_kabely.png"}
                    alt=""
                  />
                  <div className="section-otazky-block-title">
                    { i18n.t('caseStudy.section03.tableTitle2') }
                  </div>
                </div>
                <div className="section-otazky-block">
                  <img
                    className="section-otazky-table-head"
                    src={config.paths.icons + "ic_cerpadlo.png"}
                    alt=""
                  />
                  <div className="section-otazky-block-title">
                    { i18n.t('caseStudy.section03.tableTitle3') }
                  </div>
                </div>
                <div className="section-otazky-block">
                  <img
                    className="section-otazky-table-head"
                    src={config.paths.icons + "ic_heatflow.png"}
                    alt=""
                  />
                  <div className="section-otazky-block-title orange">
                    Heated.
                  </div>
                </div>
              </div>
              <div className="section-otazky-question">
              { i18n.t('caseStudy.section03.question1') }
              </div>
              <div className="section-otazky-row">
                <div className="section-otazky-block">
                  <img
                    className=""
                    src={config.paths.icons + "ic-close-grey.png"}
                    alt=""
                  />
                </div>
                <div className="section-otazky-block">
                  <img
                    className=""
                    src={config.paths.icons + "ic-close-grey.png"}
                    alt=""
                  />
                </div>
                <div className="section-otazky-block">
                  <img
                    className=""
                    src={config.paths.icons + "ic-check-grey.png"}
                    alt=""
                  />
                </div>
                <div className="section-otazky-block">
                  <img
                    className=""
                    src={config.paths.icons + "ic-check-orange.png"}
                    alt=""
                  />
                </div>
              </div>
              <div className="section-otazky-question">
              { i18n.t('caseStudy.section03.question2') }
              </div>
              <div className="section-otazky-row">
                <div className="section-otazky-block">
                  <img
                    className=""
                    src={config.paths.icons + "ic-close-grey.png"}
                    alt=""
                  />
                </div>
                <div className="section-otazky-block">
                  <img
                    className=""
                    src={config.paths.icons + "ic-check-grey.png"}
                    alt=""
                  />
                </div>
                <div className="section-otazky-block">
                  <img
                    className=""
                    src={config.paths.icons + "ic-close-grey.png"}
                    alt=""
                  />
                </div>
                <div className="section-otazky-block">
                  <img
                    className=""
                    src={config.paths.icons + "ic-check-orange.png"}
                    alt=""
                  />
                </div>
              </div>
              <div className="section-otazky-question">
              { i18n.t('caseStudy.section03.question3') }
              </div>
              <div className="section-otazky-row">
                <div className="section-otazky-block">
                  <img
                    className=""
                    src={config.paths.icons + "ic-close-grey.png"}
                    alt=""
                  />
                </div>
                <div className="section-otazky-block">
                  <img
                    className=""
                    src={config.paths.icons + "ic-close-grey.png"}
                    alt=""
                  />
                </div>
                <div className="section-otazky-block">
                  <img
                    className=""
                    src={config.paths.icons + "ic-close-grey.png"}
                    alt=""
                  />
                </div>
                <div className="section-otazky-block">
                  <img
                    className=""
                    src={config.paths.icons + "ic-check-orange.png"}
                    alt=""
                  />
                </div>
              </div>

              <div
                className={
                  "section-more-otazky " +
                  (this.state.otazky_clanky ? "opening" : "") +
                  " " +
                  (this.state.otazky_clanky_hiding ? "hiding" : "")
                }
              >
                <div className="section-otazky-question">
                { i18n.t('caseStudy.section03.question4') }
                </div>
                <div className="section-otazky-row">
                  <div className="section-otazky-block">
                    <img
                      className=""
                      src={config.paths.icons + "ic-close-grey.png"}
                      alt=""
                    />
                  </div>
                  <div className="section-otazky-block">
                    <img
                      className=""
                      src={config.paths.icons + "ic-close-grey.png"}
                      alt=""
                    />
                  </div>
                  <div className="section-otazky-block">
                    <img
                      className=""
                      src={config.paths.icons + "ic-close-grey.png"}
                      alt=""
                    />
                  </div>
                  <div className="section-otazky-block">
                    <img
                      className=""
                      src={config.paths.icons + "ic-check-orange.png"}
                      alt=""
                    />
                  </div>
                </div>
                <div className="section-otazky-question">
                { i18n.t('caseStudy.section03.question5') }
                </div>
                <div className="section-otazky-row">
                  <div className="section-otazky-block">
                    <img
                      className=""
                      src={config.paths.icons + "ic-close-grey.png"}
                      alt=""
                    />
                  </div>
                  <div className="section-otazky-block">
                    <img
                      className=""
                      src={config.paths.icons + "ic-check-grey.png"}
                      alt=""
                    />
                  </div>
                  <div className="section-otazky-block">
                    <img
                      className=""
                      src={config.paths.icons + "ic-close-grey.png"}
                      alt=""
                    />
                  </div>
                  <div className="section-otazky-block">
                    <img
                      className=""
                      src={config.paths.icons + "ic-check-orange.png"}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div
                className={
                  "section-otazky-show-more " +
                  (this.state.otazky_clanky ? "opened" : "")
                }
                onClick={() => {
                  this.expandSection("otazky");
                }}
              >
                {this.state.otazky_clanky ? (
                  <div>{ i18n.t('general.toHide') }</div>
                ) : (
                  <div>{ i18n.t('general.showMoreParameters') }</div>
                )}
                <img
                  className="expandable-control"
                  src={config.paths.icons + "ic_sluzba_open.png"}
                  alt={i18n.t('general.open')}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="studie2-section sluzba-gray-section" style={{ marginTop: '-45px', zIndex: '330' }}>
          <div
            className={"gray-bg " + (this.state.otazky2_clanky ? "opened" : "")}
          ></div>
          <div className="image-bg"></div>
          <div className="section-content">
            <div
              className={
                "sluzba-expandable " +
                (this.state.otazky2_clanky ? "opened" : "")
              }
              onClick={() => {
                this.expandSection("otazky2");
              }}
            >
              <div className="expandable-heading">
                <span>{ i18n.t('caseStudy.section04.title') }</span>
              </div>
              <img
                className="expandable-control"
                src={config.paths.icons + "ic_sluzba_open.png"}
                alt={i18n.t('general.open')}
              />
              <div className="clear"></div>
            </div>
            <div
              className={
                "sluzba-clanky-container full-images " +
                (this.state.otazky2_clanky ? "opening" : "") +
                " " +
                (this.state.otazky2_clanky_hiding ? "hiding" : "")
              }
              // style={{ display: "none" }}
            >
              <div className="sluzba-expandable__text">
                { i18n.t('caseStudy.section04.text') }
              </div>
              <img
                className="sluzba-clanek-full-img"
                src={config.paths.images + "img_ktere_technologie_" + i18n.language + ".svg"}
                alt="Jak probíhá návrh projektu?"
              />
            </div>
          </div>
        </div>
        <div className="studie2-section sluzba-gray-section sluzba-grey-bottom">
          <div
            className={"gray-bg " + (this.state.otazky3_clanky ? "opened" : "")}
          ></div>
          <div className="image-bg"></div>
          <div className="bottom-bg"></div>
          <div className="section-content">
            <div
              className={
                "sluzba-expandable " +
                (this.state.otazky3_clanky ? "opened" : "")
              }
              onClick={() => {
                this.expandSection("otazky3");
              }}
            >
              <div className="expandable-heading">
                <span>{ i18n.t('caseStudy.section05.title') }</span>
              </div>
              <img
                className="expandable-control"
                src={config.paths.icons + "ic_sluzba_open.png"}
                alt={i18n.t('general.open')}
              />
              <div className="clear"></div>
            </div>
            <div
              className={
                "sluzba-clanky-container studie2-expandable-margin full-images " +
                (this.state.otazky3_clanky ? "opening" : "") +
                " " +
                (this.state.otazky3_clanky_hiding ? "hiding" : "")
              }
              // style={{ display: "none" }}
            >
              <div className="sluzba-expandable__text">
              { i18n.t('caseStudy.section05.text') }
              </div>
              <img
                className="sluzba-clanek-full-img rozlozeny_dum_kulicky"
                src={config.paths.images + "img_rozlozeny_dum_kulicky_" + i18n.language + ".png"}
                alt="Kdo je autorita na trhu?"
              />

              <div className="sluzba-expandable__3_columns">
                <div className="sluzba-expandable__3_columns__column">
                  <span className="sluzba-expandable__3_columns__header">
                    { i18n.t('caseStudy.section05.column1.title') }
                  </span>
                  <p className="sluzba-expandable__3_columns__text">
                  { i18n.t('caseStudy.section05.column1.text') }
                  </p>
                  <span className="sluzba-expandable__3_columns__footer">
                  { i18n.t('caseStudy.section05.column1.claim') }
                  </span>
                </div>

                <div className="sluzba-expandable__3_columns__column sluzba-expandable__3_columns__column--dimmed">
                  <span className="sluzba-expandable__3_columns__header">
                    { i18n.t('caseStudy.section05.column2.title') }
                  </span>
                  <p className="sluzba-expandable__3_columns__text">
                  { i18n.t('caseStudy.section05.column2.text') }
                  </p>
                  <span className="sluzba-expandable__3_columns__footer">
                  { i18n.t('caseStudy.section05.column2.claim') }
                  </span>
                </div>

                <div className="sluzba-expandable__3_columns__column sluzba-expandable__3_columns__column--orange">
                  <span className="sluzba-expandable__3_columns__header">
                  { i18n.t('caseStudy.section05.column3.title') }
                  </span>
                  <p className="sluzba-expandable__3_columns__text">
                  { i18n.t('caseStudy.section05.column3.text') } <Link to={config.pages.sluzba.url}>({ i18n.t('general.moreAboutService') })</Link>
                  </p>
                  <span className="sluzba-expandable__3_columns__footer">
                  { i18n.t('caseStudy.section05.column3.claim') }
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="number-container">
            <div className="part-number orange">02</div>
          </div>
        </div>

        <div className="studie2-section sluzba-prubeh studie2-number-2">
          <div className="image-bg"></div>
          <div className="color-bg"></div>
          <div className="section-content">
            <div className="section-content-wrapper">
              <div className="section-block-left">
                <div className="section-heading">
                { i18n.t('caseStudy.section06.title') }
                </div>
                <div className="section-prubeh-divider">
                  ------------------------------------
                </div>
                <div className="section-prubeh-desc" dangerouslySetInnerHTML={{ __html: i18n.t('caseStudy.section06.text') }}></div>
              </div>
            </div>
          </div>
        </div>

        <div className="studie2-section studie2-white-section studie2-arguments-section">
          <div className="wave-bg"></div>
          <div className="bottom-bg"></div>
          <div className="section-content">
            <div className="section-heading">
            { i18n.t('caseStudy.section07.title') }
            </div>
            <div className="section-prubeh-desc" dangerouslySetInnerHTML={{ __html: i18n.t('caseStudy.section07.text') }}>
            </div>

            <div className="section-arguments-wrapper">
              <div className="section-argument-block">
                <div className="section-argument-top">
                  <div className="section-argument-title" dangerouslySetInnerHTML={{ __html: i18n.t('caseStudy.section07.box01.title') }}>
                  </div>
                  <img
                    className="section-argument-icon"
                    src={config.paths.icons + "ic_studie2_system.png"}
                    alt=""
                  />
                </div>
                <div className="section-argument-bottom">
                { i18n.t('caseStudy.section07.box01.text') }
                </div>
              </div>
              <div className="section-argument-block">
                <div className="section-argument-top">
                  <div className="section-argument-title" dangerouslySetInnerHTML={{ __html: i18n.t('caseStudy.section07.box02.title') }}>
                  </div>
                  <img
                    className="section-argument-icon"
                    src={config.paths.icons + "ic_valuable.png"}
                    alt=""
                  />
                </div>
                <div className="section-argument-bottom">
                { i18n.t('caseStudy.section07.box02.text') }
                </div>
              </div>
              <div className="section-argument-block">
                <div className="section-argument-top">
                  <div className="section-argument-title" dangerouslySetInnerHTML={{ __html: i18n.t('caseStudy.section07.box03.title') }}>
                  </div>
                  <img
                    className="section-argument-icon"
                    src={config.paths.icons + "ic_temperature.png"}
                    alt=""
                  />
                </div>
                <div className="section-argument-bottom">{ i18n.t('caseStudy.section07.box03.text') }</div>
              </div>
              <div className="section-argument-block">
                <div className="section-argument-top">
                  <div className="section-argument-title" dangerouslySetInnerHTML={{ __html: i18n.t('caseStudy.section07.box04.title') }}>
                  </div>
                  <img
                    className="section-argument-icon"
                    src={config.paths.icons + "ic_noise.png"}
                    alt=""
                  />
                </div>
                <div className="section-argument-bottom">{ i18n.t('caseStudy.section07.box04.text') }</div>
              </div>

              <div
                className={
                  "section-argument-line more-argument " +
                  (this.state.arguments_clanky ? "opening" : "") +
                  " " +
                  (this.state.arguments_clanky_hiding ? "hiding" : "")
                }
              >
                <div className="section-argument-title">
                  { i18n.t('caseStudy.section07.box05.title') }
                </div>
                <p>{ i18n.t('caseStudy.section07.box05.text') }</p>
              </div>

              <div
                className={
                  "section-argument-line more-argument " +
                  (this.state.arguments_clanky ? "opening" : "") +
                  " " +
                  (this.state.arguments_clanky_hiding ? "hiding" : "")
                }
              >
                <div className="section-argument-title">
                  { i18n.t('caseStudy.section07.box06.title') }
                </div>
                <p>{ i18n.t('caseStudy.section07.box06.text') }</p>
              </div>

              <div
                className={
                  "section-argument-line more-argument " +
                  (this.state.arguments_clanky ? "opening" : "") +
                  " " +
                  (this.state.arguments_clanky_hiding ? "hiding" : "")
                }
              >
                <div className="section-argument-title">
                  { i18n.t('caseStudy.section07.box07.title') }
                </div>
                <p>{ i18n.t('caseStudy.section07.box07.text') }</p>
              </div>

              <div
                className={
                  "section-argument-line more-argument " +
                  (this.state.arguments_clanky ? "opening" : "") +
                  " " +
                  (this.state.arguments_clanky_hiding ? "hiding" : "")
                }
              >
                <div className="section-argument-title">
                  { i18n.t('caseStudy.section07.box08.title') }
                </div>
                <p>{ i18n.t('caseStudy.section07.box08.text') }</p>
              </div>

              {/* <div
                className={
                  "section-argument-line more-argument " +
                  (this.state.arguments_clanky ? "opening" : "") +
                  " " +
                  (this.state.arguments_clanky_hiding ? "hiding" : "")
                }
              >
                <div className="section-argument-title">
                  { i18n.t('caseStudy.section07.box9.title') }
                </div>
                <p>{ i18n.t('caseStudy.section07.box9.text') }</p>
              </div> */}

              <div
                className={
                  "section-argument-line more-argument " +
                  (this.state.arguments_clanky ? "opening" : "") +
                  " " +
                  (this.state.arguments_clanky_hiding ? "hiding" : "")
                }
              >
                <div className="section-argument-title">
                  { i18n.t('caseStudy.section07.box10.title') }
                </div>
                <p>{ i18n.t('caseStudy.section07.box10.text') }</p>
              </div>

              <div
                className={
                  "section-argument-line more-argument " +
                  (this.state.arguments_clanky ? "opening" : "") +
                  " " +
                  (this.state.arguments_clanky_hiding ? "hiding" : "")
                }
              >
                <div className="section-argument-title">
                  { i18n.t('caseStudy.section07.box11.title') }
                </div>
                <p>
                { i18n.t('caseStudy.section07.box11.text') }
                </p>
              </div>
            </div>
            <div
              className={
                "section-arguments-show-more " +
                (this.state.arguments_clanky ? "opened" : "")
              }
              onClick={() => {
                this.expandSection("arguments");
              }}
            >
              {this.state.arguments_clanky ? (
                <div>{ i18n.t('general.toHide') }</div>
              ) : (
                <div>{ i18n.t('general.showAnotherPros') }</div>
              )}
              <img
                className="expandable-control"
                src={config.paths.icons + "ic_sluzba_open.png"}
                alt={i18n.t('general.open')}
              />
            </div>
          </div>
          <div className="number-container">
            <div className="part-number orange">03</div>
          </div>
        </div>

        <div className="studie2-section sluzba-prubeh studie2-number-3">
          <div className="image-bg"></div>
          <div className="color-bg"></div>
          <div className="section-content">
            <div className="section-content-wrapper">
              <div className="section-block-left">
                <div className="section-heading">
                { i18n.t('caseStudy.section08.title') }
                </div>
                <div className="section-prubeh-divider">
                  ------------------------------------
                </div>
                <div className="section-prubeh-desc" dangerouslySetInnerHTML={{ __html: i18n.t('caseStudy.section08.text') }}></div>
              </div>
            </div>
          </div>
        </div>

        <div className="studie2-section studie2-white-section studie2-realizace-section">
          <div className="wave-bg"></div>
          <div className="section-content">
            <div className="section-heading">
            { i18n.t('caseStudy.section09.title01') }
            </div>
            <div className="section-realizace-wrapper">
              <div className="section-realizace-row top-row">
                  <div className="section-realizace-left" dangerouslySetInnerHTML={{ __html: i18n.t('caseStudy.section09.client') }}>
                  </div>
                  <div className="section-realizace-right" dangerouslySetInnerHTML={{ __html: i18n.t('caseStudy.section09.heatlow') }}>
                  </div>
                </div>
              <div className="section-realizace-row">
                <img
                  className="section-realizace-linka"
                  src={config.paths.images + "img_studie2_realizace_linka.png"}
                  alt=""
                />
              </div>
              <div className="section-realizace-row bottom-row">
                <div className="section-realizace-left">
                { i18n.t('caseStudy.section09.textClient01') }
                </div>
                <div className="section-realizace-right">
                { i18n.t('caseStudy.section09.textHeatflow01') } <Link to={config.pages.sluzba.url}>({ i18n.t('general.moreAboutService') })</Link>
                </div>
              </div>
              <div className="section-realizace-row last-row">
                <img
                  className="section-realizace-linka"
                  src={config.paths.images + "img_studie2_realizace_info_" + i18n.language + ".svg"}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="studie2-section sluzba-gray-section sluzba-grey-bottom2 studie2-zmena-section">
          <div
            className={"gray-bg " + (this.state.otazky4_clanky ? "opened" : "")}
          ></div>
          <div className="image-bg"></div>
          <div className="bottom-bg"></div>
          <div className="section-content">
            <div
              className={
                "sluzba-expandable " +
                (this.state.otazky4_clanky ? "opened" : "")
              }
              onClick={() => {
                this.expandSection("otazky4");
              }}
            >
              <div className="expandable-heading">
                <span>{ i18n.t('caseStudy.section09.title02') }</span>
              </div>
              <img
                className="expandable-control"
                src={config.paths.icons + "ic_sluzba_open.png"}
                alt={i18n.t('general.open')}
              />
              <div className="clear"></div>
            </div>
            <div
              className={
                "sluzba-clanky-container studie2-expandable-margin full-images " +
                (this.state.otazky4_clanky ? "opening" : "") +
                " " +
                (this.state.otazky4_clanky_hiding ? "hiding" : "")
              }
              // style={{ display: "none" }}
            >
              <div className="section-realizace-wrapper">
                <div className="section-realizace-row top-row">
                  <div className="section-realizace-left" dangerouslySetInnerHTML={{ __html: i18n.t('caseStudy.section09.client') }}>
                  </div>
                  <div className="section-realizace-right" dangerouslySetInnerHTML={{ __html: i18n.t('caseStudy.section09.heatlow') }}>
                  </div>
                </div>
                <div className="section-realizace-row">
                  <img
                    className="section-realizace-linka"
                    src={
                      config.paths.images + "img_studie2_realizace_linka.png"
                    }
                    alt=""
                  />
                </div>
                <div className="section-realizace-row bottom-row">
                  <div className="section-realizace-left">
                  { i18n.t('caseStudy.section09.textClient02') }
                  </div>
                  <div className="section-realizace-right">
                  { i18n.t('caseStudy.section09.textHeatflow02') }
                  </div>
                </div>
                <div className="section-realizace-row last-row">
                  <img
                    className="section-realizace-linka section-realizace-linka--zmena"
                    src={config.paths.images + "img_jak_probihala_zmena_" + i18n.language + ".svg"}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="number-container">
            <div className="part-number orange">04</div>
          </div>
        </div>

        <div className="studie2-section sluzba-prubeh studie2-number-4">
          <div className="image-bg"></div>
          <div className="color-bg"></div>
          <div className="section-content">
            <div className="section-content-wrapper">
              <div className="section-block-left">
                <div className="section-heading">
                { i18n.t('caseStudy.section10.title') }
                </div>
                <div className="section-prubeh-divider">
                  ------------------------------------
                </div>
                <div className="section-prubeh-desc" dangerouslySetInnerHTML={{ __html: i18n.t('caseStudy.section10.text') }}></div>
              </div>
            </div>

            <div className="section-forum-wrapper">
              <div className="section-forum-block">
                <img
                  className="section-forum-icon"
                  src={config.paths.icons + "ic_forum.png"}
                  alt=""
                />
                <div className="section-forum-right">
                  <div className="section-forum-zastupitel">
                    <div className="section-otazka">
                    { i18n.t('caseStudy.section10.box01.title') }
                    </div>
                  </div>
                  <div className="section-forum-client">
                    { i18n.t('caseStudy.section10.client') }:{" "}
                    <div className="client-answer" dangerouslySetInnerHTML={{ __html: i18n.t('caseStudy.section10.box01.text') }}></div>
                  </div>
                </div>
              </div>
              <img
                className="section-forum-separator"
                src={config.paths.icons + "ic_three_dots.png"}
                alt=""
              />
              <div className="section-forum-block">
                <img
                  className="section-forum-icon"
                  src={config.paths.icons + "ic_forum.png"}
                  alt=""
                />
                <div className="section-forum-right">
                  <div className="section-forum-zastupitel">
                    <div className="section-otazka">
                    { i18n.t('caseStudy.section10.box02.title') }
                    </div>
                  </div>
                  <div className="section-forum-client">
                    { i18n.t('caseStudy.section10.client') }:{" "}
                    <div className="client-answer" dangerouslySetInnerHTML={{ __html: i18n.t('caseStudy.section10.box02.text') }}></div>
                  </div>
                </div>
              </div>
              <img
                className="section-forum-separator"
                src={config.paths.icons + "ic_three_dots.png"}
                alt=""
              />
              <div className="section-forum-block">
                <img
                  className="section-forum-icon"
                  src={config.paths.icons + "ic_forum.png"}
                  alt=""
                />
                <div className="section-forum-right">
                  <div className="section-forum-zastupitel">
                    <div className="section-otazka">
                    { i18n.t('caseStudy.section10.box03.title') }
                    </div>
                  </div>
                  <div className="section-forum-client">
                    { i18n.t('caseStudy.section10.client') }:{" "}
                    <div className="client-answer" dangerouslySetInnerHTML={{ __html: i18n.t('caseStudy.section10.box03.text') }}></div>
                  </div>
                </div>
              </div>
              <img
                className="section-forum-separator"
                src={config.paths.icons + "ic_three_dots.png"}
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="sluzba-section sluzba-expertise" style={{marginTop: '-170px', zIndex: 381, backgroundColor: '#303030'}}>
          <img
            className="sluzba-expertise-img"
            src={config.paths.backgrounds + "img_sluzba_footer_bg.png"}
            alt="Expertise"
          />
          <div className="sluzba-expertise-text">
            <h2 dangerouslySetInnerHTML={{ __html: i18n.t('caseStudy.section11.title') }}></h2>
            <h4 dangerouslySetInnerHTML={{ __html: i18n.t('caseStudy.section11.subtitle') }}></h4>
          </div>
        </div>

        <div className="sluzba-section sluzba-kontakt">
          <div className="form-container">
            {this.state.sent ? (
              <div className="form-sent">
                <div className="sent-wrapper">
                  <h2 className="section-heading">
                    {this.state.title == i18n.t('general.sended') ? (
                      <img
                        className="odeslano-icon"
                        src={config.paths.icons + "ic_checked_orange.png"}
                        alt={i18n.t('general.sended')}
                      />
                    ) : (
                      ""
                    )}
                    <b>{this.state.title}</b>
                  </h2>
                  <div className="odeslano-text">
                    {this.state.form_response}
                  </div>{" "}
                </div>
              </div>
            ) : (
              <div className="form-container">
                <div className="error_message">{this.state.error_message}</div>
                <form
                  action=""
                  className={
                    "form-sluzba-nabidka " +
                    this.state.valid_email +
                    " " +
                    (this.state.sent ? "sent" : "") +
                    " " +
                    (this.state.submitted ? "submitted" : "")
                  }
                  onSubmit={this.handleSubmit}
                >
                  <div className="form-side">
                    <div className="form-row two-col">
                      <div className="form-field">
                        <input
                          type="text"
                          name="name"
                          id="form_name_sluzba_nabidka"
                          required
                          onChange={this.handleChange}
                          value={this.state.name}
                        />
                        <label htmlFor="form_name_sluzba_nabidka">
                          {i18n.t('general.fullname')} °
                        </label>
                        <i className="form-field-check"></i>
                        <span
                          className="required"
                          style={
                            this.state.name != "" ? { display: "none" } : {}
                          }
                        >
                          {i18n.t('general.requiredField')}
                        </span>
                      </div>
                      <div className="form-field">
                      <select
                          name="region"
                          id="form_city_sluzba_nabidka"
                          onChange={this.handleChange}
                          value={this.state.region}
                          required
                          style={{top: '10px', marginBottom: '26px'}}
                        >
                          <option value="" defaultValue>
                            { i18n.t('general.regions.title') }
                          </option>
                          <option value="Hlavní město Praha">{ i18n.t('general.regions.praha') }</option>
                          <option value="Středočeský kraj">{ i18n.t('general.regions.stredocesky') }</option>
                          <option value="Jihočeský kraj">{ i18n.t('general.regions.jihocesky') }</option>
                          <option value="Plzeňský kraj">{ i18n.t('general.regions.plzensky') }</option>
                          <option value="Karlovarský kraj">{ i18n.t('general.regions.karlovarsky') }</option>
                          <option value="Ústecký kraj">{ i18n.t('general.regions.ustecky') }</option>
                          <option value="Liberecký kraj">{ i18n.t('general.regions.liberecky') }</option>
                          <option value="Královéhradecký kraj">{ i18n.t('general.regions.kralovehradecky') }</option>
                          <option value="Pardubický kraj">{ i18n.t('general.regions.pardubicky') }</option>
                          <option value="Kraj Vysočina">{ i18n.t('general.regions.vysocina') }</option>
                          <option value="Jihomoravský kraj">{ i18n.t('general.regions.jihomoravsky') }</option>
                          <option value="Olomoucký kraj">{ i18n.t('general.regions.olomoucky') }</option>
                          <option value="Zlínský kraj">{ i18n.t('general.regions.zlinsky') }</option>
                          <option value="Moravskoslezský kraj">{ i18n.t('general.regions.moravskoslezsky') }</option>
                        </select>
                        <span
                          className="required"
                          style={
                            this.state.region != "" ? { display: "none" } : {}
                          }
                        >
                          {i18n.t('general.requiredField')}
                        </span>
                      </div>
                    </div>
                    <div className="form-row two-col">
                      <div className="form-field">
                        <input
                          type="text"
                          name="tel"
                          id="form_tel"
                          pattern={`[0-9a-zA-Z]{${i18n.language === 'cs' ? '9,9' : '10,10'}}`}
                          onKeyPress={this.checkNumber}
                          required
                          onChange={this.handleChange}
                          value={this.state.tel}
                        />
                        <label htmlFor="form_tel">{ i18n.t('general.phone') } °</label>
                        <i className="form-field-check"></i>
                        <span
                          className="required"
                          style={
                            this.state.tel != "" ? { display: "none" } : {}
                          }
                        >
                          {i18n.t('general.requiredField')}
                        </span>
                      </div>
                      <div className="form-field">
                        <input
                          type="email"
                          name="email"
                          id="form_email_sluzba_nabidka"
                          className={
                            "email " +
                            (this.state.email != "" ? "not-empty" : "")
                          }
                          required
                          onChange={this.handleChange}
                          value={this.state.email}
                        />
                        <label htmlFor="form_email_sluzba_nabidka">
                          email °
                        </label>
                        <i className="form-field-check"></i>
                        {this.state.submitted &&
                        !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email) ? (
                          <span className="required required_email">
                            {i18n.t('general.wrongFormate')}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="form-row  one-col">
                      <div className="form-field">
                        {/* <input
                      type="text"
                      name="note"
                      id="form_note_sluzba_nabidka"
                      onChange={this.handleChange}
                      value={this.state.note}
                    /> */}
                        <textarea
                          // type="text"
                          name="note"
                          id="form_note_sluzba_nabidka"
                          onChange={this.handleChange}
                          value={this.state.note}
                          className={this.state.note != "" ? "filled" : ""}
                        ></textarea>
                        <label htmlFor="form_note_sluzba_nabidka">
                          {i18n.t('general.note')}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-side">
                    <div className="sluzba-nabidka-slider">
                      <div className="sluzba-nabidka-legend-cont">
                        <span className="sluzba-nabidka-form-label">
                          {i18n.t('general.totalArea')} °
                        </span>
                        <span className="sluzba-nabidka-form-area">
                          <b>{this.state.slider_value}</b> m<sup>2</sup>
                        </span>
                      </div>
                      <div className="sluzba-nabidka-slider-cont">
                        <Slider
                          id="slider_7"
                          className="sluzba-nabidka-slider-range"
                          defaultValue={this.state.slider_value}
                          min={1}
                          max={500}
                          step={1}
                          onChange={this.sliderHandle}
                          trackStyle={{
                            backgroundImage: "url('" + slider_head + "')",
                            height: 24
                          }}
                          handleStyle={{
                            borderColor: "blue",
                            height: 44,
                            width: 26,
                            marginLeft: -9,
                            marginTop: -9,
                            backgroundImage: "url('" + slider_handle + "')"
                          }}
                          railStyle={{
                            backgroundImage: "url('" + slider_tail + "')",
                            height: 24
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-row  one-col">
                      <div className="form-field">
                        <select
                          name="phase"
                          id="form_faze_sluzba_nabidka"
                          onChange={this.handleChange}
                          value={this.state.phase}
                        >
                          <option value="" defaultValue>
                            {i18n.t('general.projectPhaseAndDocuments')}
                          </option>
                          <option value="Nemám projekt">{i18n.t('general.noProject')}</option>
                          <option value="Mám projekt">{i18n.t('general.haveProject')}</option>
                          <option value="Mám projekt, ale nemám ho u sebe">
                            {i18n.t('general.haveProjectNoDocumentation')}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div
                      className="form-row  one-col"
                      style={this.state.file_input}
                    >
                      <div
                        className={
                          "form-field files " +
                          (this.state.missing_files ? "missing" : "")
                        }
                      >
                        <FormItemUpload
                          {...{
                            text:
                              i18n.t('general.uploadNote'),
                            dataFormItem: "sources",
                            value: this.state.formItems.podklady.value,
                            error: this.state.formItems.podklady.error,
                            actions: {
                              changeFormUploadItem: this.changeFormUploadItem
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-additional">
                    <span className="form-additional-heading">
                      {i18n.t('general.additionalOptions')}
                    </span>
                    <div className="form-field">
                      <div className="form-checkbox">
                        <span dangerouslySetInnerHTML={{ __html: i18n.t('general.priceComplete') }}></span>
                        <input
                          type="checkbox"
                          name="floor_structure"
                          id="ovladani_skladba"
                          checked={this.state.floor_structure}
                          onChange={this.handleChange}
                        />
                        <label htmlFor="ovladani_skladba" />
                      </div>
                      <div className="form-checkbox">
                        <span dangerouslySetInnerHTML={{ __html: i18n.t('general.priceInteligent') }}></span>
                        <input
                          type="checkbox"
                          name="inteligent_control"
                          id="ovladani_sluzba_nabidkaX"
                          checked={this.state.inteligent_control}
                          onChange={this.handleChange}
                        />
                        <label htmlFor="ovladani_sluzba_nabidkaX" />
                      </div>
                      <div className="form-checkbox">
                        <span dangerouslySetInnerHTML={{ __html: i18n.t('general.priceSolar') }}></span>
                        <input
                          type="checkbox"
                          name="fve"
                          id="solar_sluzba_nabidkaX"
                          checked={this.state.fve}
                          onChange={this.handleChange}
                        />
                        <label htmlFor="solar_sluzba_nabidkaX" />
                      </div>
                    </div>
                  </div>
                  <div className="form-bottom">
                    <div className="form-field">
                      <button type="submit" onClick={this.handleSubmit}>
                        {i18n.t('general.toSend')}
                      </button>
                      <span className="form-submit-note">
                        {i18n.t('general.sendingAgree1')}{" "}
                        <span
                          className="privacy-link"
                          onClick={this.openPrivacy}
                        >
                          {i18n.t('general.sendingAgree2')}
                        </span>
                        .
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
        <div
            className="gdpr-modal"
            onClick={this.modalClicked}
            data-name="modal"
            style={{
              display: this.state.gdpr_modal ? "block" : "none"
            }}
          >
            <div className="gdpr-cont">
              <div className="gdpr-close" onClick={this.openPrivacy}>
                <img
                  className=""
                  src={config.paths.icons + "ic_close_grey.png"}
                  alt=""
                />
              </div>
              <div className="gdpr-wrapper">
                <h1 className="gdpr-nadpis">
                  {i18n.t('general.privaciPolicyAgreement')}
                </h1>
                <div className="gdpr-content" dangerouslySetInnerHTML={{ __html: i18n.t('general.gdprContent') }}></div>
              </div>
            </div>
          </div>
        <img
          style={{ display: "none" }}
          src={config.paths.backgrounds + "img_studie2_intro_bg.png"}
          onLoad={this.resourceLoaded}
        />
        <img
          style={{ display: "none" }}
          src={config.paths.backgrounds + "img_studie2_otazky_bg.png"}
          onLoad={this.resourceLoaded}
        />
        <img
          style={{ display: "none" }}
          src={config.paths.backgrounds + "img_studie2_celoplosny_bg.png"}
          onLoad={this.resourceLoaded}
        />
        <img
          style={{ display: "none" }}
          src={config.paths.backgrounds + "img_studie2_arguments_bg.png"}
          onLoad={this.resourceLoaded}
        />
      </div>
    );
  }
}

export default PripadovaStudie;
