import React from 'react';

function HeatflowButton({children, theme, type, disabled})
{
    return (
        <button type={type || 'button'} className={[
            'HeatflowButton',
            theme && `HeatflowButton--${theme}`,
        ].filter((el) => el).join(' ')} disabled={disabled}>
            {children}
        </button>
    );
}

export default HeatflowButton;
