import React from 'react';

import AnimationAboutNumber from './AnimationAboutNumber';
import AnimationAboutSheet from './AnimationAboutSheet';
import AnimationAboutContainer from './AnimationAboutContainer';
import AnimationAboutHeaderSplit from './AnimationAboutHeaderSplit';
import AnimationAboutHeaderTitle from './AnimationAboutHeaderTitle';
import AnimationAboutHeaderText from './AnimationAboutHeaderText';
import AnimationAboutTile from './AnimationAboutTile';
import AnimationAboutSheetBackground from './AnimationAboutSheetBackground';
import AnimationAboutSlider from './AnimationAboutSlider';
import AnimationAboutSimpleCarousel from './AnimationAboutSimpleCarousel';
import AnimationAboutTabs from './AnimationAboutTabs';

import config from '../../../../config/config.json';

import i18n from '../i18n';

function AnimationAboutSection2()
{
    return (
        <section className="AnimationAboutSection AnimationAboutSection2">
            <AnimationAboutNumber>02</AnimationAboutNumber>
            <AnimationAboutSheet kartoteka="tmava" theme="dark">
                <AnimationAboutContainer width="header">
                    <AnimationAboutHeaderSplit id="gap-417">
                        <div>
                            <AnimationAboutHeaderTitle>{i18n.t('about.section02.title')}</AnimationAboutHeaderTitle>
                            <AnimationAboutHeaderText>
                                <span className="AnimationAbout--a1" dangerouslySetInnerHTML={{ __html: i18n.t('about.section02.text') }}></span>
                            </AnimationAboutHeaderText>
                        </div>
                        <div>
                            <div className="AnimationAbout--83" style={{ fontSize: '1.01562vw', lineHeight: '2.1875vw', marginBottom: '2.60416vw' }}>
                                {i18n.t('about.section02.chartTitle')}
                            </div>
                            <img src={config.paths.about + "SVG _ S02_" + i18n.language + ".svg"} alt="Srovnání délky nátopu" />
                            <div className="AnimationAbout--83" style={{ fontSize: '1.01562vw', lineHeight: '1', marginTop: '2.60416vw' }}>
                                <sup>*</sup> {i18n.t('about.section02.chartNote')}
                            </div>
                        </div>
                    </AnimationAboutHeaderSplit>
                </AnimationAboutContainer>
            </AnimationAboutSheet>
            <AnimationAboutSheet
                kartoteka="seda"
                kartotekaPushed
                collapsible
                title={
                    <div className="AnimationAbout--white">
                        {i18n.t('about.section02.subsection01.title')}?
                    </div>
                }
                theme="gray"
            >
                <AnimationAboutContainer>
                    <AnimationAboutTile>
                        <div className="AnimationAboutTile__text-content">
                            <h4 className="AnimationAboutTile__title AnimationAboutTile__title--number">
                                <span className="AnimationAbout--d0">01.</span>
                                <span className="AnimationAbout--white">{i18n.t('about.section02.subsection01.box01.title')}</span>
                            </h4>
                            <div className="AnimationAbout--line-medium AnimationAbout--d0">{i18n.t('about.section02.subsection01.box01.text')}</div>
                            <div className="AnimationAbout--d0">
                                {i18n.t('about.section02.subsection01.box01.note')}
                                -
                            </div>
                            <div className="AnimationAboutTile__degrees">
                                <span className="AnimationAbout--orange">23,5°C</span>
                                <span className="AnimationAbout--white">± 0,2°C</span>
                            </div>
                        </div>
                        <div>
                            <img src={config.paths.about + 'img-5.png'} alt="Podlaha Heated" />
                        </div>
                    </AnimationAboutTile>
                    <AnimationAboutTile reversed>
                        <div>
                            <img src={config.paths.about + 'akumulace.png'} alt="Podlaha Heated" />
                        </div>
                        <div className="AnimationAboutTile__text-content">
                            <h4 className="AnimationAboutTile__title AnimationAboutTile__title--number">
                                <span className="AnimationAbout--d0">02.</span>
                                <span className="AnimationAbout--white">{i18n.t('about.section02.subsection01.box02.title')}</span>
                            </h4>
                            <div className="AnimationAbout--line-medium AnimationAbout--d0">{i18n.t('about.section02.subsection01.box02.text')}</div>
                            <div className="AnimationAbout--d0">
                                {i18n.t('about.section02.subsection01.box02.note')}
                                -
                            </div>
                            <div className="AnimationAboutTile__degrees">
                                <span className="AnimationAbout--orange">23,5°C</span>
                                <span className="AnimationAbout--white">± 0,2°C</span>
                            </div>
                        </div>
                    </AnimationAboutTile>
                    <AnimationAboutTile>
                        <div className="AnimationAboutTile__text-content">
                            <h4 className="AnimationAboutTile__title AnimationAboutTile__title--number">
                                <span className="AnimationAbout--d0">03.</span>
                                <span className="AnimationAbout--white">{i18n.t('about.section02.subsection01.box03.title')}</span>
                            </h4>
                            <div
                                className="AnimationAbout--line-medium AnimationAbout--d0"
                                dangerouslySetInnerHTML={{ __html: i18n.t('about.section02.subsection01.box03.text') }}
                            ></div>
                            <div className="AnimationAbout--d0">
                                {i18n.t('about.section02.subsection01.box03.note')}
                                -
                            </div>
                            <div className="AnimationAboutTile__degrees">
                                <span className="AnimationAbout--orange">22,7°C</span>
                                <span className="AnimationAbout--white">± 0,5°C</span>
                            </div>
                        </div>
                        <div>
                            <img src={config.paths.about + 'salani_sten.png'} alt="Podlaha HeatFlow" />
                        </div>
                    </AnimationAboutTile>
                    <AnimationAboutTile reversed>
                        <div>
                            <img src={config.paths.about + 'teplotni_rozdily.png'} alt="Podlaha HeatFlow" />
                        </div>
                        <div className="AnimationAboutTile__text-content">
                            <h4 className="AnimationAboutTile__title AnimationAboutTile__title--number">
                                <span className="AnimationAbout--d0">04.</span>
                                <span className="AnimationAbout--white">{i18n.t('about.section02.subsection01.box04.title')}</span>
                            </h4>
                            <div className="AnimationAbout--line-medium AnimationAbout--d0">{i18n.t('about.section02.subsection01.box04.text')}</div>
                            <div className="AnimationAbout--d0">
                                {i18n.t('about.section02.subsection01.box04.note')}
                                -
                            </div>
                            <div className="AnimationAboutTile__doubles">
                                <div className="AnimationAboutTile__double">
                                    <div className="AnimationAbout--white AnimationAbout--semi">
                                        {i18n.t('about.section02.subsection02.valueTitle1')}
                                    </div>
                                    <div className="AnimationAboutTile__degrees">
                                        <span className="AnimationAbout--white">23,5°C</span>
                                        <span className="AnimationAbout--orange">± 0,2°C</span>
                                    </div>
                                </div>
                                <div className="AnimationAboutTile__double">
                                    <div className="AnimationAbout--white AnimationAbout--semi">
                                        {i18n.t('about.section02.subsection02.valueTitle2')}
                                    </div>
                                    <div className="AnimationAboutTile__degrees">
                                        <span className="AnimationAbout--white">22,7°C</span>
                                        <span className="AnimationAbout--orange">± 0,5°C</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </AnimationAboutTile>
                </AnimationAboutContainer>
            </AnimationAboutSheet>
            <AnimationAboutSheet
                kartoteka="tmava"
                collapsible
                title={<div className="AnimationAbout--white">{i18n.t('about.section02.subsection02.title')}</div>}
                theme="dark"
            >
                <AnimationAboutContainer>
                    <AnimationAboutTile>
                        <div className="AnimationAboutTile__text-content">
                            <h4 className="AnimationAboutTile__title">
                                <span className="AnimationAbout--white">{i18n.t('about.section02.subsection02.box01.title')}</span>
                            </h4>
                            <div
                                className="AnimationAbout--line-medium AnimationAbout--d0"
                                dangerouslySetInnerHTML={{ __html: i18n.t('about.section02.subsection02.box01.text') }}
                            ></div>
                            <div className="AnimationAbout--d0">{i18n.t('about.section02.subsection02.box01.note')}</div>
                            <div className="AnimationAboutTile__doubles">
                                <div className="AnimationAboutTile__double">
                                    <div className="AnimationAbout--white AnimationAbout--semi">
                                        {i18n.t('about.section02.subsection02.valueTitle1')}
                                    </div>
                                    <div className="AnimationAboutTile__degrees">
                                        <span className="AnimationAbout--white">24,5°C</span>
                                        <span className="AnimationAbout--orange">± 2°C</span>
                                    </div>
                                </div>
                                <div className="AnimationAboutTile__double">
                                    <div className="AnimationAbout--white AnimationAbout--semi">
                                        {i18n.t('about.section02.subsection02.valueTitle2')}
                                    </div>
                                    <div className="AnimationAboutTile__degrees">
                                        <span className="AnimationAbout--white">20,5°C</span>
                                        <span className="AnimationAbout--orange">± 2°C</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <img src={config.paths.about + 'teplovod.png'} alt="Podlaha HeatFlow" />
                        </div>
                    </AnimationAboutTile>
                    <AnimationAboutTile reversed>
                        <div>
                            <img src={config.paths.about + 'rohoz.png'} alt="Podlaha HeatFlow" />
                        </div>
                        <div className="AnimationAboutTile__text-content">
                            <h4 className="AnimationAboutTile__title">
                                <span className="AnimationAbout--white">{i18n.t('about.section02.subsection02.box02.title')}</span>
                            </h4>
                            <div
                                className="AnimationAbout--line-medium AnimationAbout--d0"
                                dangerouslySetInnerHTML={{ __html: i18n.t('about.section02.subsection02.box02.text') }}
                            ></div>
                            <div className="AnimationAbout--d0">{i18n.t('about.section02.subsection02.box02.note')}</div>
                            <div className="AnimationAboutTile__doubles">
                                <div className="AnimationAboutTile__double">
                                    <div className="AnimationAbout--white AnimationAbout--semi">
                                        {i18n.t('about.section02.subsection02.valueTitle1')}
                                    </div>
                                    <div className="AnimationAboutTile__degrees">
                                        <span className="AnimationAbout--white">24,5°C</span>
                                        <span className="AnimationAbout--orange">± 2°C</span>
                                    </div>
                                </div>
                                <div className="AnimationAboutTile__double">
                                    <div className="AnimationAbout--white AnimationAbout--semi">
                                        {i18n.t('about.section02.subsection02.valueTitle2')}
                                    </div>
                                    <div className="AnimationAboutTile__degrees">
                                        <span className="AnimationAbout--white">21,5°C</span>
                                        <span className="AnimationAbout--orange">± 2°C</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </AnimationAboutTile>
                </AnimationAboutContainer>
            </AnimationAboutSheet>
            <AnimationAboutSheet
                kartoteka="seda"
                title={
                    <span className="AnimationAbout--white">
                        {i18n.t('about.section02.subsection03.title')} (<span className="AnimationAbout--orange">23°C</span>)
                    </span>
                }
                theme="gray"
            >
                <AnimationAboutContainer>
                    <AnimationAboutSlider
                        count={3}
                        render={({ current, prev, next, go }) => {
                            return (
                                <div>
                                    <AnimationAboutTabs
                                        current={current}
                                        go={go}
                                        theme="dark"
                                        options={['Heated.', i18n.t('about.section02.subsection02.box01.title'), i18n.t('about.section02.subsection02.box02.title')]}
                                    />
                                    <AnimationAboutTile single>
                                        <div className="AnimationAbout--line-medium AnimationAbout--d0">
                                            {current === 0 && (
                                                <div dangerouslySetInnerHTML={{ __html: i18n.t('about.section02.subsection03.text1') }}></div>
                                            )}
                                            {current === 1 && <div>{i18n.t('about.section02.subsection03.text2')}</div>}
                                            {current === 2 && <div>{i18n.t('about.section02.subsection03.text3')}</div>}
                                        </div>
                                    </AnimationAboutTile>
                                    <div className="AnimationAboutSection2__graph">
                                        <div>
                                            {current === 0 && <img src={config.paths.about + 'graf_1_' + i18n.language + (i18n.language === 'cs' ? '.svg' : '.png')} alt="" />}
                                            {current === 1 && <img src={config.paths.about + 'graf_2_' + i18n.language + '.png'} alt="" />}
                                            {current === 2 && <img src={config.paths.about + 'graf_3_' + i18n.language + '.png'} alt="" />}
                                        </div>
                                        <div>
                                            <div className="AnimationAboutTile__doubles AnimationAboutTile__doubles--vertical">
                                                <div className="AnimationAboutTile__double">
                                                    <div className="AnimationAbout--white AnimationAbout--semi">
                                                        {i18n.t('about.section02.subsection03.valueTitle1')}
                                                    </div>
                                                    <div className="AnimationAboutTile__degrees">
                                                        <span className="AnimationAbout--orange">
                                                            {[23, 6, 9][current]}h<span className="AnimationAbout--black">/24h</span>
                                                        </span>
                                                        <span className="AnimationAbout--orange"></span>
                                                    </div>
                                                </div>
                                                <div className="AnimationAboutTile__double">
                                                    <div className="AnimationAbout--white AnimationAbout--semi">
                                                        {i18n.t('about.section02.subsection03.valueTitle2')}
                                                    </div>
                                                    <div className="AnimationAboutTile__degrees">
                                                        <span className="AnimationAbout--orange">±{[0.5, 3, 2][current]}°C</span>
                                                        <span className="AnimationAbout--orange"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        }}
                    />
                </AnimationAboutContainer>
            </AnimationAboutSheet>
            <AnimationAboutSheet theme="dark" kartoteka="tmava" kartotekaPushed noPaddingBottom noPaddingTop>
                <AnimationAboutSheetBackground gradientDark image={config.paths.about + 'img-11.png'} />
            </AnimationAboutSheet>
        </section>
    );
}

export default AnimationAboutSection2;
