import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { indexReferences } from "../api.js";

import AnimationAboutNumber from './AnimationAboutNumber';
import AnimationAboutSheet from './AnimationAboutSheet';
import AnimationAboutContainer from './AnimationAboutContainer';
import AnimationAboutHeaderSplit from './AnimationAboutHeaderSplit';
import AnimationAboutHeaderTitle from './AnimationAboutHeaderTitle';
import AnimationAboutHeaderText from './AnimationAboutHeaderText';
import AnimationAboutTile from './AnimationAboutTile';
import AnimationAboutSheetBackground from './AnimationAboutSheetBackground';
import AnimationAboutSlider from './AnimationAboutSlider';
import AnimationAboutSimpleCarousel from './AnimationAboutSimpleCarousel';
import HeatflowButton from './HeatflowButton';
import AnimationAboutRefferenceCarousel from './AnimationAboutRefferenceCarousel';

import config from '../../../../config/config.json';

import i18n from '../i18n';

function AnimationAboutSection5()
{
    const [references, setReferences] = useState([]);

    const setData = useCallback((response) => {
        setReferences(response.data);
    }, [setReferences]);

    useEffect(() => {
        indexReferences.request('CJH', null, null, setData);

        return () => {
            indexReferences.cancel();
        };
    }, [setData]);

    /*
    const references = useMemo(() => {
        return [
            {
                id: 1,
                main_image: config.paths.about + 'obyvak.png',
                stars: 4,
                first_quote: '<div>„There are many variations of pass ages of Lorem gole ipsum gole ipsum gole ipsum available.“</div>',
                second_quote: '<div>„Lorem ipsum dolor sit amet, consectetuer. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as foredo opposed to using.“</div>',
                date: 2016,
                floor_area: 176,
                heat_loss: 5.4,
                expenses_per_month: 1410,
                description: '<p>Projekt novostavby zděného dvoupodlažního domu bez podsklepení, s podlahovou plochou 176 m2, byl realizován v roce 2017 ve Stachovicích u Fulneku. Podlahové vytápění HeatFlow zde pokrývá dohromady 105m2, tedy 60% celkové podlahové plochy. Dům je plně automatizován pomocí inteligentní technologie Loxone, která ovládá kromě jiného i vytápění. Izolace je zajištěna polystyrenem o tloušťce 150mm a jako roznášecí vrstva byl použit cementový litý potěr o tloušťce 60mm. Celkové náklady na vytápění celého domu vychází v průměru na 1410 Kč/ měsíc.</p>',
            },
            {
                id: 2,
                main_image: config.paths.about + 'obyvak.png',
                stars: 4,
                first_quote: '<div>„There are many variations of pass ages of Lorem gole ipsum gole ipsum gole ipsum available.“</div>',
                second_quote: '<div>„Lorem ipsum dolor sit amet, consectetuer. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as foredo opposed to using.“</div>',
                date: 2016,
                floor_area: 176,
                heat_loss: 5.4,
                expenses_per_month: 1410,
                description: '<p>Projekt novostavby zděného dvoupodlažního domu bez podsklepení, s podlahovou plochou 176 m2, byl realizován v roce 2017 ve Stachovicích u Fulneku. Podlahové vytápění HeatFlow zde pokrývá dohromady 105m2, tedy 60% celkové podlahové plochy. Dům je plně automatizován pomocí inteligentní technologie Loxone, která ovládá kromě jiného i vytápění. Izolace je zajištěna polystyrenem o tloušťce 150mm a jako roznášecí vrstva byl použit cementový litý potěr o tloušťce 60mm. Celkové náklady na vytápění celého domu vychází v průměru na 1410 Kč/ měsíc.</p>',
            },
            {
                id: 3,
                main_image: config.paths.about + 'obyvak.png',
                stars: 4,
                first_quote: '<div>„There are many variations of pass ages of Lorem gole ipsum gole ipsum gole ipsum available.“</div>',
                second_quote: '<div>„Lorem ipsum dolor sit amet, consectetuer. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as foredo opposed to using.“</div>',
                date: 2016,
                floor_area: 176,
                heat_loss: 5.4,
                expenses_per_month: 1410,
                description: '<p>Projekt novostavby zděného dvoupodlažního domu bez podsklepení, s podlahovou plochou 176 m2, byl realizován v roce 2017 ve Stachovicích u Fulneku. Podlahové vytápění HeatFlow zde pokrývá dohromady 105m2, tedy 60% celkové podlahové plochy. Dům je plně automatizován pomocí inteligentní technologie Loxone, která ovládá kromě jiného i vytápění. Izolace je zajištěna polystyrenem o tloušťce 150mm a jako roznášecí vrstva byl použit cementový litý potěr o tloušťce 60mm. Celkové náklady na vytápění celého domu vychází v průměru na 1410 Kč/ měsíc.</p>',
            }
        ];
    }, []);
    */

    return (
        <section className="AnimationAboutSection AnimationAboutSection5">
            <AnimationAboutNumber>05</AnimationAboutNumber>
            <AnimationAboutSheet kartoteka="tmava" theme="dark">
                <AnimationAboutContainer width="header" >
                    <AnimationAboutHeaderSplit id="gap-0">
                        <div>
                            <AnimationAboutHeaderTitle>
                                {i18n.t('about.section05.title')}
                            </AnimationAboutHeaderTitle>
                            <AnimationAboutHeaderText>
                                <div className="AnimationAbout--a1">{i18n.t('about.section05.text1')} <a href={config.pages.ref_databaze.url} className="AnimationAbout--orange">{i18n.t('about.section05.linkText')}</a> {i18n.t('about.section05.text2')}</div>
                            </AnimationAboutHeaderText>
                        </div>
                        <div style={{textAlign: 'right'}}>
                            <div className="AnimationAbout--orange AnimationAbout--semi" style={{fontSize: '5.20833vw', lineHeight: '7.55280vw', marginBottom: '0.25em'}}>
                                3000+
                            </div>
                            <div className="AnimationAbout--white AnimationAbout--semi AnimationAbout--line-medium" style={{fontSize: '1.30208vw'}}>
                                {i18n.t('about.section05.sideNote')}
                            </div>
                        </div>
                    </AnimationAboutHeaderSplit>
                </AnimationAboutContainer>
            </AnimationAboutSheet>
            <AnimationAboutSheet kartoteka="bila" kartotekaPushed theme="light">
                <AnimationAboutContainer>
                    <AnimationAboutSlider count={references.length} render={({current, prev, next}) => {
                        console.log(references.length)
                        return (
                            <AnimationAboutRefferenceCarousel references={references} current={current} prev={prev} next={next} />
                        );
                    }} />
                    <div style={{textAlign: 'center', marginTop: '0'}}>
                        <Link to={config.pages.ref_projekty.url}>
                            <HeatflowButton theme="gradient">
                                {i18n.t('about.section05.showMore')}
                            </HeatflowButton>
                        </Link>
                    </div>
                </AnimationAboutContainer>
            </AnimationAboutSheet>
        </section>
    );
}

export default AnimationAboutSection5;
