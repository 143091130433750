import * as React from "react";
import ReactDOM from "react-dom";
import config from "../../../../config/config.json";
import { Link } from "react-router-dom";
import { postLink } from "../api.js";
import { getPageSEO } from "../functions";

import i18n from '../i18n';

class KeStazeni extends React.Component {
  constructor(props) {
    super(props);

    this.pageSEO = getPageSEO();

    document.title = this.pageSEO.title;
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", this.pageSEO.keywords);
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", this.pageSEO.description);

    document.querySelector('meta[name=\"title\"]').setAttribute('content', this.pageSEO.metaTitle);

    this.state = {
      detail: "",
      num: "1",
      from: "3",
      data: [],
      more_data: [],
      carousel: ""
    };

    this.loadMore = this.loadMore.bind(this);
    this.dowloadClicked = this.dowloadClicked.bind(this);
    this.posted = this.posted.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll, { passive: true });

    // getReferences.request(this.setData);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
    document.body.style.overflowY = "scroll";
  }

  loadMore() {
    this.setState({
      more_data: this.state.data.slice(9),
      load_more_option: { display: "none" }
    });
  }
  dowloadClicked(e) {
    var file = e.currentTarget.dataset.name;

    const data = {
      name: file
    };

    postLink.request(data, this.posted);
  }
  posted(response) {
    // console.log(response);
  }

  render() {
    return (
        <div className="main-container" id="main-container-ke-stazeni">
            <div className="stazeni-container">
                <div className="stazeni-route">
                    <b>Heated. &gt; </b>
                    {i18n.t('download.breadcrumb')}
                </div>
                <h1 className="stazeni-nadpis" title dangerouslySetInnerHTML={{ __html: i18n.t('download.title') }}></h1>
                <div className="stazeni-list-cont">
                    <ul className="stazeni-list">
                        <li className="stazeni-list-item">
                            <div className="doc-name">
                                <b>{i18n.t('download.item01')}</b> - Heated.
                            </div>
                            <a className="download" href={config.paths.files + 'HEATED_technicky_list_2023.pdf'} onClick={this.dowloadClicked} data-name="Technický list - Heated..pdf" download>
                                <div>{i18n.t('download.toDownload')}</div> <img className="download-icon" src={config.paths.icons + 'ic_arrow_down_orange.png'} alt={i18n.t('download.toDownload')} />
                            </a>
                        </li>
                        <li className="stazeni-list-item">
                            <div className="doc-name">
                                <b>{i18n.t('download.item02')}</b> - Heated.
                            </div>
                            <a className="download" href={config.paths.files + 'HEATED_navod_termostat_CZ.pdf'} onClick={this.dowloadClicked} data-name="Návod termostat NTC - Heated..pdf" download>
                                <div>{i18n.t('download.toDownload')}</div> <img className="download-icon" src={config.paths.icons + 'ic_arrow_down_orange.png'} alt={i18n.t('download.toDownload')} />
                            </a>
                        </li>
                    </ul>
                </div>

                {/* <div className="stazeni-footer">
            <div
              className="stazeni-nacist-vice"
              style={this.state.load_more_option}
              onClick={this.loadMore}
            >
              {i18n.t('general.loadMore')}
            </div>
          </div> */}
            </div>
        </div>
    );
  }
}

export default KeStazeni;
