import * as React from "react";
import { Link } from "react-router-dom";
import config from "../../../../config/config.json";

import i18n from '../i18n';

class MobileMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile_menu: { display: "none" }
    };
    this.handleClick = this.handleClick.bind(this);
    this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
    this.changeLanguage = this.changeLanguage.bind(this);
  }

  componentDidMount() {}

  handleClick(e) {
    e.preventDefault();
    this.setState({ selected: e.currentTarget.id });
    this.props.onMenuClick(e.currentTarget.id);
    this.toggleMobileMenu(false);
  }

  changeLanguage(lang) {
    localStorage.setItem('lang', lang);
    location.reload();
  }

  toggleMobileMenu(open) {
    if (open) {
      this.setState({ mobile_menu: { display: "block" } });
      document.body.style.overflowY = "hidden";
    } else {
      this.setState({ mobile_menu: { display: "none" } });
      document.body.style.overflowY = "scroll";
    }
  }
  handleClickPreventDefault(e) {
    e.preventDefault();
  }

  render() {
    // Mobile menu background

    var mobile_menu =
      config.paths.backgrounds + "img_mobile_menu_background.png";
    var mobile_menu_style = {
      background: "url(" + mobile_menu + ")",
      //   backgroundPosition: "90% 100%",
      backgroundSize: "100% 100%",
      backgroundRepeat: "no-repeat"
    };

    return (
      <div className="menu-mobile">
        <img
          src={config.paths.icons + "ic_mobile_hamburger.png"}
          alt="Otevřít menu"
          className="menu-mobile-trigger"
          onClick={() => {
            this.toggleMobileMenu(true);
          }}
        />
        <Link
          to="#root"
          className="menu-mobile-home-link"
          onClick={() => {
            this.props.onMenuClick("top");
          }}
        >
          <img
            src={config.paths.icons + "ic_home_black.png"}
            alt="Otevřít menu"
            className="menu-mobile-home"
          />
        </Link>
        <div className="menu-mobile-opened" style={this.state.mobile_menu}>
          <div className="menu-mobile-cont" style={mobile_menu_style}>
            <div className="menu-mobile-block">
              <img
                src={config.paths.icons + "ic_close_white.png"}
                alt="Otevřít menu"
                className="menu-mobile-close"
                onClick={() => {
                  this.toggleMobileMenu(false);
                }}
              />
              <img
                src={config.paths.brands + "logo_ch-white.svg"}
                alt="heated"
                className="menu-mobile-logo"
              />

              <Link
                to="#root"
                className="menu-mobile-home-link-open"
                onClick={() => {
                  this.toggleMobileMenu(false);
                  window.scrollTo(0, 0);
                }}
              >
                <img
                  src={config.paths.icons + "ic_home_black.png"}
                  alt="Otevřít menu"
                  className="menu-mobile-home"
                />
              </Link>
              <ul className="menu-mobile-list">
                <li className="menu-mobile-list-item">
                  <Link
                    to={config.pages.home.url}
                    className="menu-mobile-link"
                    onClick={this.handleClick}
                    id="section_mobile_1_link"
                  >
                    <span style={{ fontWeight: "300" }}>{i18n.t('general.whatsHeatflow')}</span> Heated.
                  </Link>
                </li>
                <li className="menu-mobile-list-item">
                  <Link
                    to={config.pages.home.url}
                    className="menu-mobile-link"
                    onClick={this.handleClick}
                    id="section_mobile_2_link"
                  >
                    {i18n.t('comparison.titleShort')}
                  </Link>
                </li>
                <li className="menu-mobile-list-item">
                  <Link
                    to={config.pages.home.url}
                    className="menu-mobile-link"
                    onClick={this.handleClick}
                    id="section_mobile_3_link"
                  >
                    {i18n.t('general.realization')}
                  </Link>
                </li>
                <li className="menu-mobile-list-item">
                  <Link
                    to={config.pages.home.url}
                    className="menu-mobile-link"
                    onClick={this.handleClick}
                    id="section_mobile_5_link"
                  >
                    {i18n.t('contact.title')}
                  </Link>
                </li>
                <li className="menu-mobile-list-item">
                    <button className={`menu-mobile-link is-lang${i18n.language === 'cs' ? ' selected' : ''}`} onClick={() => this.changeLanguage('cs')}>CZ</button>
                    <span className="menu-mobile-link">&nbsp;|&nbsp;</span>
                    <button className={`menu-mobile-link is-lang${i18n.language === 'sk' ? ' selected' : ''}`}  onClick={() => this.changeLanguage('sk')}>SK</button>
                </li>
              </ul>
            </div>
          </div>
          <div className="menu-mobile-nabidka">
            <Link
              to={config.pages.home.url}
              className="menu-mobile-nabidka-link"
              onClick={this.handleClick}
              id="section_mobile_4_link"
            >
              <span dangerouslySetInnerHTML={{ __html: i18n.t('general.interestInOffer') }}></span>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export { MobileMenu };
