import * as React from "react";
import config from "../../../../config/config.json";
import { Link } from "react-router-dom";
// import Slider, { createSliderWithTooltip } from "rc-slider";
import "../../../../node_modules/rc-slider/assets/index.css";
import smoothScrollIntoView from "smooth-scroll-into-view-if-needed";
import { getPageSEO } from "../functions";

import i18n from '../i18n';

class PorovnaniVytapeni extends React.Component {
  constructor(props) {
    super(props);

    this.pageSEO = getPageSEO();

    document.title = this.pageSEO.title;
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", this.pageSEO.keywords);
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", this.pageSEO.description);

    document.querySelector('meta[name=\"title\"]').setAttribute('content', this.pageSEO.metaTitle);

    this.state = {
      scroll_display: { display: "flex" },
      type: 0,
      prev_type: 0,
      graph: null,
      fixed: true,
      showLoader: true,
      loader_color: { backgroundColor: "rgba(240,91,40, 1)" }
    };
    this.types = [
      <span>{ i18n.t('comparison.title01') }</span>,
      <span>{ i18n.t('comparison.title02') }</span>,
      <span>{ i18n.t('comparison.title03') }</span>,
    ];
    this.main_number = [58, 52, 58];
    this.columns = [
      [[60, 80], [70, 40], [90, 30], [100, 60], [90, 60], [100, 80]],
      [[60, 40], [70, 90], [90, 40], [100, 60], [90, 40], [100, 40]],
      [[60, 70], [70, 60], [90, 40], [100, 60], [90, 40], [100, 40]]
    ];
    this.prop_0 = ["5 - 10%", "6 - 12%", "4 - 6%"];
    this.prop_1 = ["1°C | 15 MIN", "1°C | 15 HOD", "5°C | 15 LET"];
    this.prop_2 = ["NE", "možná", "určitě ne"];
    this.prop_3 = ["5 - 10%", "6 - 12%", "4 - 6%"];
    this.prop_4 = ["5 - 10%", "6 - 12%", "4 - 6%"];
    this.prop_5 = ["NE", "kéž by", "za 20 let"];

    this.sliderHandle = this.sliderHandle.bind(this);
    this.changeType = this.changeType.bind(this);
    this.graphMouseEnter = this.graphMouseEnter.bind(this);
    this.graphMouseLeave = this.graphMouseLeave.bind(this);
    this.onScroll = this.onScroll.bind(this);
    this.scrollToContent = this.scrollToContent.bind(this);

    this.sectionContent = React.createRef();

    this.graph_descs = i18n.t('comparison.texts', { returnObjects: true });
  }

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll, { passive: true });
    if (this.props.location.state) {
      const type = this.props.location.state.type;
      // console.log(type);

      this.setState({ type: type });
    }
    this.setState({ showLoader: false });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  }

  // componentWillReceiveProps() {
  //   if (this.props.location.state) {
  //     const type = this.props.location.state.type;
  //     // console.log(type);

  //     this.setState({ type: type });
  //     this.forceUpdate();
  //   }
  // }

  componentDidUpdate(prevProps) {
    if (this.props.location.state) {
      const type = this.props.location.state.type;

      if (this.state.type != type) {
        this.setState({ type: type });
        setTimeout(() => {
          this.setState({ prev_type: type });
        }, 1400);
        // this.fromMenu(project_id);
      }
    }
    // console.log(this.props);
    // if (this.props.location.state.project_id != this.state.project_id) {
    //   const project_id = this.props.location.state.project_id;
    //   this.setState({ project_id: project_id });
    //   // this.setData(this.state.res);
    //   getReferences.request(this.setData);
    // }
    // console.log(prevProps);
  }

  sliderHandle(value) {
    this.setState({ slider_value: value });
  }
  changeType(e) {
    e.preventDefault();
    const type_clicked = e.currentTarget.getAttribute("data-type");
    console.log("Clicked " + type_clicked);
    console.log(" Type " + this.state.type);
    if (type_clicked != this.state.type) {
      this.setState({ type: type_clicked });
      setTimeout(() => {
        this.setState({ prev_type: type_clicked });
      }, 1400);
    }
  }
  graphMouseEnter(e) {
    e.preventDefault();
    const graph_entered = e.currentTarget.getAttribute("data-number");
    this.setState({ graph: graph_entered });
  }
  graphMouseLeave(e) {
    e.preventDefault();
    this.setState({ graph: null });
  }
  onScroll(e) {
    const limit = window.innerHeight * (28 / 100);

    if (window.pageYOffset > limit && this.state.fixed) {
      this.setState({ fixed: false, scroll_display: { display: "none" } });
    }
    if (window.pageYOffset < limit && !this.state.fixed) {
      this.setState({ fixed: true, scroll_display: { display: "flex" } });
    }
  }

  scrollToContent() {
    smoothScrollIntoView(this.sectionContent.current, {
      behavior: "smooth",
      block: "start",
      duration: 1000
    });
    // this.sectionContent.current.scrollIntoView({
    //   behavior: "smooth",
    //   block: "end"
    // });
  }

  render() {
    var slider_handle = config.paths.icons + "ic_handle_orange.png";
    var slider_head = config.paths.icons + "ic_mobile_slider_bg_active.png";
    var slider_tail = config.paths.icons + "ic_mobile_slider_bg.png";

    return (
      <div className="main-porovnani-container" onScroll={this.onScroll}>
        <div className="porovnani-container">
          <div className={"porovnani-top " + (this.state.fixed ? "" : "abs")}>
            <div className="porovnani-left">
              <img
                className="heatflow-logo"
                src={config.paths.brands + "logo_heatflow_porovnani.svg"}
                alt="Heatflow"
              />
              <div className="porovnani-number">85%</div>
            </div>
            <div className="porovnani-middle">
              <div className="graphs-wrapper">
                <div className="graphs-cont">
                  <div
                    className="graph"
                    onMouseEnter={this.graphMouseEnter}
                    onMouseLeave={this.graphMouseLeave}
                    data-number="0"
                  >
                    <div className="column-bars">
                      <div className={"column-bar-left "}>
                        <div
                          className="column-bar-placeholder"
                          style={{
                            height:
                              100 - this.columns[this.state.type][0][0] + "%"
                          }}
                        />
                        <div
                          className="column-bar"
                          style={{
                            height: this.columns[this.state.type][0][0] + "%"
                          }}
                        />
                        <div className="column-shadow" />
                      </div>
                      <div className={"column-bar-right "}>
                        <div
                          className="column-bar-placeholder"
                          style={{
                            height:
                              100 - this.columns[this.state.type][0][1] + "%"
                          }}
                        />
                        <div
                          className="column-bar"
                          style={{
                            height: this.columns[this.state.type][0][1] + "%"
                          }}
                        />
                        <div className="column-shadow" />
                      </div>
                    </div>
                    <div className="column-title">{i18n.t('comparison.textTitle01')}</div>
                  </div>
                  <div
                    className="graph"
                    onMouseEnter={this.graphMouseEnter}
                    onMouseLeave={this.graphMouseLeave}
                    data-number="1"
                  >
                    <div className="column-bars">
                      <div className={"column-bar-left "}>
                        <div
                          className="column-bar-placeholder"
                          style={{
                            height:
                              100 - this.columns[this.state.type][1][0] + "%"
                          }}
                        />
                        <div
                          className="column-bar"
                          style={{
                            height: this.columns[this.state.type][1][0] + "%"
                          }}
                        />
                        <div className="column-shadow" />
                      </div>
                      <div className={"column-bar-right "}>
                        <div
                          className="column-bar-placeholder"
                          style={{
                            height:
                              100 - this.columns[this.state.type][1][1] + "%"
                          }}
                        />
                        <div
                          className="column-bar"
                          style={{
                            height: this.columns[this.state.type][1][1] + "%"
                          }}
                        />
                        <div className="column-shadow" />
                      </div>
                    </div>
                    <div className="column-title">{i18n.t('comparison.textTitle02')}</div>
                  </div>
                  <div
                    className="graph"
                    onMouseEnter={this.graphMouseEnter}
                    onMouseLeave={this.graphMouseLeave}
                    data-number="2"
                  >
                    <div className="column-bars">
                      <div className={"column-bar-left "}>
                        <div
                          className="column-bar-placeholder"
                          style={{
                            height:
                              100 - this.columns[this.state.type][2][0] + "%"
                          }}
                        />
                        <div
                          className="column-bar"
                          style={{
                            height: this.columns[this.state.type][2][0] + "%"
                          }}
                        />
                        <div className="column-shadow" />
                      </div>
                      <div className={"column-bar-right "}>
                        <div
                          className="column-bar-placeholder"
                          style={{
                            height:
                              100 - this.columns[this.state.type][2][1] + "%"
                          }}
                        />
                        <div
                          className="column-bar"
                          style={{
                            height: this.columns[this.state.type][2][1] + "%"
                          }}
                        />
                        <div className="column-shadow" />
                      </div>
                    </div>
                    <div className="column-title">{i18n.t('comparison.textTitle03')}</div>
                  </div>
                  <div
                    className="graph"
                    onMouseEnter={this.graphMouseEnter}
                    onMouseLeave={this.graphMouseLeave}
                    data-number="3"
                  >
                    <div className="column-bars">
                      <div className={"column-bar-left "}>
                        <div
                          className="column-bar-placeholder"
                          style={{
                            height:
                              100 - this.columns[this.state.type][3][0] + "%"
                          }}
                        />
                        <div
                          className="column-bar"
                          style={{
                            height: this.columns[this.state.type][3][0] + "%"
                          }}
                        />
                        <div className="column-shadow" />
                      </div>
                      <div className={"column-bar-right "}>
                        <div
                          className="column-bar-placeholder"
                          style={{
                            height:
                              100 - this.columns[this.state.type][3][1] + "%"
                          }}
                        />
                        <div
                          className="column-bar"
                          style={{
                            height: this.columns[this.state.type][3][1] + "%"
                          }}
                        />
                        <div className="column-shadow" />
                      </div>
                    </div>
                    <div className="column-title">{i18n.t('comparison.textTitle04')}</div>
                  </div>
                  <div
                    className="graph"
                    onMouseEnter={this.graphMouseEnter}
                    onMouseLeave={this.graphMouseLeave}
                    data-number="4"
                  >
                    <div className="column-bars">
                      <div className={"column-bar-left "}>
                        <div
                          className="column-bar-placeholder"
                          style={{
                            height:
                              100 - this.columns[this.state.type][4][0] + "%"
                          }}
                        />
                        <div
                          className="column-bar"
                          style={{
                            height: this.columns[this.state.type][4][0] + "%"
                          }}
                        />
                        <div className="column-shadow" />
                      </div>
                      <div className={"column-bar-right "}>
                        <div
                          className="column-bar-placeholder"
                          style={{
                            height:
                              100 - this.columns[this.state.type][4][1] + "%"
                          }}
                        />
                        <div
                          className="column-bar"
                          style={{
                            height: this.columns[this.state.type][4][1] + "%"
                          }}
                        />
                        <div className="column-shadow" />
                      </div>
                    </div>
                    <div className="column-title">{i18n.t('comparison.textTitle05')}</div>
                  </div>
                  <div
                    className="graph"
                    onMouseEnter={this.graphMouseEnter}
                    onMouseLeave={this.graphMouseLeave}
                    data-number="5"
                  >
                    <div className="column-bars">
                      <div className={"column-bar-left "}>
                        <div
                          className="column-bar-placeholder"
                          style={{
                            height:
                              100 - this.columns[this.state.type][5][0] + "%"
                          }}
                        />
                        <div
                          className="column-bar"
                          style={{
                            height: this.columns[this.state.type][5][0] + "%"
                          }}
                        />
                        <div className="column-shadow" />
                      </div>
                      <div className={"column-bar-right "}>
                        <div
                          className="column-bar-placeholder"
                          style={{
                            height:
                              100 - this.columns[this.state.type][5][1] + "%"
                          }}
                        />
                        <div
                          className="column-bar"
                          style={{
                            height: this.columns[this.state.type][5][1] + "%"
                          }}
                        />
                        <div className="column-shadow" />
                      </div>
                    </div>
                    <div className="column-title">{i18n.t('comparison.textTitle06')}</div>
                  </div>
                </div>
                <div className="line-cont">
                  <div
                    className={
                      "graph-line " +
                      (this.state.graph ? "pos" + this.state.graph : "")
                    }
                  />
                </div>
                <div
                  className={"column-desc " + (this.state.graph ? "show" : "")}
                >
                  {this.graph_descs[this.state.graph || 0]}
                </div>
              </div>
            </div>
            <div className="porovnani-right">
              <Link
                to={{
                  pathname: config.pages.porovnani_vytapeni.url,
                  state: {
                    type: 0
                  }
                }}
                className={
                  "porovnani-type-cont " +
                  (this.state.type == 0 ? "selected" : "")
                }
              >
                {/* <div
                className={
                  "porovnani-type-cont " +
                  (this.state.type == 0 ? "selected" : "")
                }
                onClick={this.changeType}
                data-type="0"
              > */}
                <div className="porovnani-type-name">{ i18n.t('comparison.title01') }</div>
                <img
                  className="porovnani-selection-image"
                  src={config.paths.images + "img_porovnani_option_1.png"}
                  alt=""
                />
                <div className="porovnani-selection-gradient"></div>
                {/* <div className="porovnani-type-line" /> */}
                {/* </div> */}
              </Link>

              <Link
                to={{
                  pathname: config.pages.porovnani_vytapeni.url,
                  state: {
                    type: 1
                  }
                }}
                className={
                  "porovnani-type-cont " +
                  (this.state.type == 1 ? "selected" : "")
                }
              >
                {/* <div
                className={
                  "porovnani-type-cont " +
                  (this.state.type == 1 ? "selected" : "")
                }
                onClick={this.changeType}
                data-type="1"
              > */}
                <div className="porovnani-type-name">{ i18n.t('comparison.title02') }</div>
                <img
                  className="porovnani-selection-image"
                  src={config.paths.images + "img_porovnani_option_2.png"}
                  alt=""
                />
                <div className="porovnani-selection-gradient"></div>
                {/* <div className="porovnani-type-line" /> */}
                {/* </div> */}
              </Link>
              <Link
                to={{
                  pathname: config.pages.porovnani_vytapeni.url,
                  state: {
                    type: 2
                  }
                }}
                className={
                  "porovnani-type-cont " +
                  (this.state.type == 2 ? "selected" : "")
                }
              >
                {/* <div
                className={
                  "porovnani-type-cont " +
                  (this.state.type == 2 ? "selected" : "")
                }
                onClick={this.changeType}
                data-type="2"
              > */}
                <div className="porovnani-type-name">
                { i18n.t('comparison.title03Short') }
                </div>
                <img
                  className="porovnani-selection-image"
                  src={config.paths.images + "img_porovnani_option_3.png"}
                  alt=""
                />
                <div className="porovnani-selection-gradient"></div>
                {/* <div className="porovnani-type-line" /> */}
                {/* </div> */}
              </Link>
              <div className="porovnani-number">
                {this.main_number[this.state.type]}%
              </div>
            </div>
          </div>
          <div className="porovnani-pictures">
            <div className="porovnani-pic-left" />
            <div className="porovnani-pic-right">
              <div
                className={
                  "pic-rohoz " +
                  (this.state.type == 0 ? "active" : "") +
                  " " +
                  (this.state.prev_type == 0 ? "prev" : "")
                }
              />
              <div
                className={
                  "pic-cerpadlo " +
                  (this.state.type == 1 ? "active" : "") +
                  " " +
                  (this.state.prev_type == 1 ? "prev" : "")
                }
              />
              <div
                className={
                  "pic-kotel " +
                  (this.state.type == 2 ? "active" : "") +
                  " " +
                  (this.state.prev_type == 2 ? "prev" : "")
                }
              />
            </div>
          </div>
          <div className="porovnani-grey" />
          <div className="porovnani-content" ref={this.sectionContent}>
            <h2 className="porovnani-content-heading">{i18n.t('comparison.tableTitle')}</h2>
            <ul className="porovnani-list">
              <li className="porovnani-list-item">
                <div className="li-part li-left">
                  <img
                    className="li-logo"
                    src={config.paths.brands + "logo_heatflow_porovnani.svg"}
                    alt=""
                  />
                </div>
                <div className="li-part li-middle" />
                <div className="li-part li-right">
                  {this.types[this.state.type]}
                </div>
              </li>
              <li className="porovnani-list-item section-heading h-no-margin">
                <div className="li-part li-left"></div>
                <div className="li-part li-middle">{ i18n.t('comparison.tableSection01.title') }</div>
                <div className="li-part li-right"></div>
              </li>
              <li className="porovnani-list-item">
                <div className="li-part li-left">
                  <span className="li-value">60-90%</span>

                  <span className="li-name">{ i18n.t('comparison.tableSection01.row01.text1') }</span>
                </div>
                <div className="li-part li-middle">
                  {" "}
                  <span className="li-value">{ i18n.t('comparison.tableSection01.row01.title2') }</span>
                  <span className="li-name">
                    { i18n.t('comparison.tableSection01.row01.text2') }
                  </span>
                </div>
                <div className="li-part li-right">
                  {" "}
                  <span className="li-value">
                    {this.state.type == 0
                      ? "6-12%"
                      : this.state.type == 1
                      ? "10-16%"
                      : "10-16%"}
                  </span>
                  <span className="li-name">
                    {this.state.type == 0
                      ? i18n.t('comparison.tableSection01.row01.text3')
                      : this.state.type == 1
                      ? i18n.t('comparison.tableSection01.row01.text3')
                      : i18n.t('comparison.tableSection01.row01.text3')}
                  </span>
                </div>
              </li>
              <li className="porovnani-list-item">
                <div className="li-part li-left">
                  <span className="li-value">10 min</span>

                  <span className="li-name">
                  { i18n.t('comparison.tableSection01.row02.text1') }
                  </span>
                </div>
                <div className="li-part li-middle">
                  {" "}
                  <span className="li-value">
                    { i18n.t('comparison.tableSection01.row02.title2') }{" "}
                    <span style={{ fontWeight: "400" }}>(1°C)</span>
                  </span>
                  <span className="li-name">
                  { i18n.t('comparison.tableSection01.row02.text2') }
                  </span>
                </div>
                <div className="li-part li-right">
                  {" "}
                  <span className="li-value">
                    {this.state.type == 0
                      ? "30 min"
                      : this.state.type == 1
                      ? "75 min"
                      : "75 min"}
                  </span>
                  <span className="li-name">
                    {this.state.type == 0
                      ? i18n.t('comparison.tableSection01.row02.text2')
                      : this.state.type == 1
                      ? i18n.t('comparison.tableSection01.row02.text2')
                      : i18n.t('comparison.tableSection01.row02.text2')}
                  </span>
                </div>
              </li>
              <li className="porovnani-list-item">
                <div className="li-part li-left">
                  <span className="li-value">{ i18n.t('comparison.tableSection01.row03.title1') }</span>

                  <span className="li-name">
                  { i18n.t('comparison.tableSection01.row03.text1') }
                  </span>
                </div>
                <div className="li-part li-middle">
                  {" "}
                  <span className="li-value">{ i18n.t('comparison.tableSection01.row03.title2') }</span>
                  <span className="li-name">
                  { i18n.t('comparison.tableSection01.row03.text2') }
                  </span>
                </div>
                <div className="li-part li-right">
                  {" "}
                  <span className="li-value">
                    {this.state.type == 0
                      ? i18n.t('comparison.tableSection01.row03.title3')
                      : this.state.type == 1
                      ? i18n.t('comparison.tableSection01.row03.title3_01')
                      : i18n.t('comparison.tableSection01.row03.title3_01')}
                  </span>
                  <span className="li-name">
                    {this.state.type == 0
                      ? i18n.t('comparison.tableSection01.row03.text3')
                      : this.state.type == 1
                      ? i18n.t('comparison.tableSection01.row03.text3_01')
                      : i18n.t('comparison.tableSection01.row03.text3_01')}
                  </span>
                </div>
              </li>

              <li className="porovnani-list-item section-heading">
                <div className="li-part li-left"></div>
                <div className="li-part li-middle">
                  { i18n.t('comparison.tableSection02.title') }
                </div>
                <div className="li-part li-right"></div>
              </li>
              <li className="porovnani-list-item">
                <div className="li-part li-left">
                  <span className="li-value" style={{textTransform: "none"}}>
                    { i18n.t('comparison.tableSection02.row01.title1')}
                  </span>

                  <span className="li-name">{i18n.t('comparison.tableSection02.row01.text1')}</span>
                </div>
                <div className="li-part li-middle">
                  {" "}
                  <span className="li-value">{i18n.t('comparison.tableSection02.row01.title2')}</span>
                  <span className="li-name" dangerouslySetInnerHTML={{ __html: i18n.t('comparison.tableSection02.row01.text2') }}></span>
                </div>
                <div className="li-part li-right">
                  {" "}
                  <span className="li-value">
                    {this.state.type == 0 ? (
                      <span style={{textTransform: "none"}}>
                        {i18n.t('comparison.tableSection02.row01.title3_01')}
                      </span>
                    ) : this.state.type == 1 ? (
                      <span style={{textTransform: "none"}}>
                        {i18n.t('comparison.tableSection02.row01.title3_02')}
                      </span>
                    ) : (
                      <span style={{textTransform: "none"}}>
                        {i18n.t('comparison.tableSection02.row01.title3_03')}
                      </span>
                    )}
                  </span>
                  <span className="li-name">
                    {this.state.type == 0
                      ? i18n.t('comparison.tableSection02.row01.text3_01')
                      : this.state.type == 1
                      ? i18n.t('comparison.tableSection02.row01.text3_02')
                      : i18n.t('comparison.tableSection02.row01.text3_01')}
                  </span>
                </div>
              </li>
              <li className="porovnani-list-item">
                <div className="li-part li-left">
                  <span className="li-value" style={{textTransform: "none"}}>
                    {i18n.t('comparison.tableSection02.row02.title1')}
                  </span>

                  <span className="li-name">
                  {i18n.t('comparison.tableSection02.row02.text1')}
                  </span>
                </div>
                <div className="li-part li-middle">
                  {" "}
                  <span className="li-value">{i18n.t('comparison.tableSection02.row02.title2')}</span>
                  <span className="li-name">
                  {i18n.t('comparison.tableSection02.row02.text2')}
                  </span>
                </div>
                <div className="li-part li-right">
                  {" "}
                  <span className="li-value">
                    {this.state.type == 0 ? (
                      <span style={{textTransform: "none"}}>
                        {i18n.t('comparison.tableSection02.row02.title3_01')} 
                      </span>
                    ) : this.state.type == 1 ? (
                      <span style={{textTransform: "none"}}>
                        1{i18n.t('comparison.tableSection02.row02.title3_02')} 
                      </span>
                    ) : (
                      <span style={{textTransform: "none"}}>
                        {i18n.t('comparison.tableSection02.row02.title3_03')} 
                      </span>
                    )}
                  </span>
                  <span className="li-name">
                    {this.state.type == 0
                      ? i18n.t('comparison.tableSection02.row02.text3_01')
                      : this.state.type == 1
                      ? i18n.t('comparison.tableSection02.row02.text3_02')
                      : i18n.t('comparison.tableSection02.row02.text3_02')}
                  </span>
                </div>
              </li>
              <li className="porovnani-list-item">
                <div className="li-part li-left">
                  <span className="li-value" style={{textTransform: "none"}}>
                    {i18n.t('comparison.tableSection02.row03.title1')}
                  </span>

                  <span className="li-name">
                  {i18n.t('comparison.tableSection02.row03.text1')}
                  </span>
                </div>
                <div className="li-part li-middle">
                  {" "}
                  <span className="li-value">
                  {i18n.t('comparison.tableSection02.row03.title2')}
                  </span>
                  <span className="li-name">
                  {i18n.t('comparison.tableSection02.row03.text2')}
                  </span>
                </div>
                <div className="li-part li-right">
                  {" "}
                  <span className="li-value">
                    {this.state.type == 0 ? (
                      <span style={{textTransform: "none"}}>
                        {i18n.t('comparison.tableSection02.row03.title3_01')}
                      </span>
                    ) : this.state.type == 1 ? (
                      <span style={{textTransform: "none"}}>
                        {i18n.t('comparison.tableSection02.row03.title3_02')}
                      </span>
                    ) : (
                      <span style={{textTransform: "none"}}>
                        {i18n.t('comparison.tableSection02.row03.title3_03')}
                      </span>
                    )}
                  </span>
                  <span className="li-name">
                    {this.state.type == 0
                      ? i18n.t('comparison.tableSection02.row03.text3_01')
                      : this.state.type == 1
                      ? i18n.t('comparison.tableSection02.row03.text3_02')
                      : i18n.t('comparison.tableSection02.row03.text3_02')}
                  </span>
                </div>
              </li>
              {/*
              <li className="porovnani-list-item">
                <div className="li-part li-left">
                  <span className="li-value">NE</span>

                  <span className="li-name">
                    Bez součástek náchylných k opotřebení a havárii
                  </span>
                </div>
                <div className="li-part li-middle">
                  {" "}
                  <span className="li-value">Poruchovost</span>
                  <span className="li-name">
                    Součástky náchylné k poškození až selhání systému
                  </span>
                </div>
                <div className="li-part li-right">
                  {" "}
                  <span className="li-value">
                    {this.state.type == 0
                      ? "NE"
                      : this.state.type == 1
                      ? "ANO"
                      : "ANO"}
                  </span>
                  <span className="li-name">
                    {this.state.type == 0
                      ? "Bez součástek náchylných k opotřebení a havárii"
                      : this.state.type == 1
                      ? "Opotřebení mechanických komponentů je běžné především u levnějších systémů"
                      : "Opotřebení mechanických části zákonitě vyžaduje servis"}
                  </span>
                </div>
              </li>
              <li className="porovnani-list-item">
                <div className="li-part li-left">
                  <span className="li-value">ANO</span>

                  <span className="li-name"></span>
                </div>
                <div className="li-part li-middle">
                  {" "}
                  <span className="li-value">Snížená cena energie</span>
                  <span className="li-name">
                    Nárok na sníženou cenu energie
                  </span>
                </div>
                <div className="li-part li-right">
                  {" "}
                  <span className="li-value">
                    {this.state.type == 0
                      ? "ANO"
                      : this.state.type == 1
                      ? "ANO"
                      : "NE"}
                  </span>
                  <span className="li-name">
                    {this.state.type == 0 ? "" : this.state.type == 1 ? "" : ""}
                  </span>
                </div>
              </li>
              */}

              <li className="porovnani-list-item section-heading">
                <div className="li-part li-left"></div>
                <div className="li-part li-middle">{ i18n.t('comparison.tableSection03.title') }</div>
                <div className="li-part li-right"></div>
              </li>
              <li className="porovnani-list-item">
                <div className="li-part li-left">
                  <span className="li-value">{i18n.t('comparison.tableSection03.row01.title1')}</span>

                  <span className="li-name">{i18n.t('comparison.tableSection03.row01.text1')}</span>
                </div>
                <div className="li-part li-middle">
                  {" "}
                  <span className="li-value">{i18n.t('comparison.tableSection03.row01.title2')}</span>
                  <span className="li-name">{i18n.t('comparison.tableSection03.row01.text2')}</span>
                </div>
                <div className="li-part li-right">
                  {" "}
                  <span className="li-value">
                    {this.state.type == 0
                      ? i18n.t('comparison.tableSection03.row01.title3_01')
                      : this.state.type == 1
                      ? i18n.t('comparison.tableSection03.row01.title3_02')
                      : i18n.t('comparison.tableSection03.row01.title3_01')}
                  </span>
                  <span className="li-name">
                    {this.state.type == 0
                      ? i18n.t('comparison.tableSection03.row01.text3_01')
                      : this.state.type == 1
                      ? i18n.t('comparison.tableSection03.row01.text3_02')
                      : i18n.t('comparison.tableSection03.row01.text3_01')}
                  </span>
                </div>
              </li>
              <li className="porovnani-list-item">
                <div className="li-part li-left">
                  <span className="li-value">{i18n.t('comparison.tableSection03.row02.title1')}</span>

                  <span className="li-name">
                  {i18n.t('comparison.tableSection03.row02.text1')}
                  </span>
                </div>
                <div className="li-part li-middle">
                  {" "}
                  <span className="li-value">
                  {i18n.t('comparison.tableSection03.row02.title2')}
                  </span>
                  <span className="li-name">
                  {i18n.t('comparison.tableSection03.row02.text2')}
                  </span>
                </div>
                <div className="li-part li-right">
                  {" "}
                  <span className="li-value">
                    {this.state.type == 0
                      ? i18n.t('comparison.tableSection03.row02.title3_01')
                      : this.state.type == 1
                      ? i18n.t('comparison.tableSection03.row02.title3_02')
                      : i18n.t('comparison.tableSection03.row02.title3_02')}
                  </span>
                  <span className="li-name">
                    {this.state.type == 0
                      ? i18n.t('comparison.tableSection03.row02.text3_01')
                      : this.state.type == 1
                      ? i18n.t('comparison.tableSection03.row02.text3_02')
                      : i18n.t('comparison.tableSection03.row02.text3_02')}
                  </span>
                </div>
              </li>

              <li className="porovnani-list-item section-heading">
                <div className="li-part li-left"></div>
                <div className="li-part li-middle">
                  { i18n.t('comparison.tableSection04.title') }
                </div>
                <div className="li-part li-right"></div>
              </li>

              <li className="porovnani-list-item">
                <div className="li-part li-left">
                  <span className="li-value">{i18n.t('comparison.tableSection04.row01.title1')}</span>

                  <span className="li-name">
                    {i18n.t('comparison.tableSection04.row01.text1')}
                  </span>
                </div>
                <div className="li-part li-middle">
                  {" "}
                  <span className="li-value">{i18n.t('comparison.tableSection04.row01.title2')}</span>
                  <span className="li-name">
                    {i18n.t('comparison.tableSection04.row01.text2')}
                  </span>
                </div>
                <div className="li-part li-right">
                  {" "}
                  <span className="li-value">
                    {this.state.type == 0
                      ? i18n.t('comparison.tableSection04.row01.title3_01')
                      : this.state.type == 1
                      ? i18n.t('comparison.tableSection04.row01.title3_02')
                      : i18n.t('comparison.tableSection04.row01.title3_02')}
                  </span>
                  <span className="li-name">
                    {this.state.type == 0
                      ? i18n.t('comparison.tableSection04.row01.text3_01')
                      : this.state.type == 1
                      ? i18n.t('comparison.tableSection04.row01.text3_02')
                      : i18n.t('comparison.tableSection04.row01.text3_02')}
                  </span>
                </div>
              </li>
              <li className="porovnani-list-item">
                <div className="li-part li-left">
                  <span className="li-value">{i18n.t('comparison.tableSection04.row02.title1')}</span>

                  <span className="li-name">
                    {i18n.t('comparison.tableSection04.row02.text1')}
                  </span>
                </div>
                <div className="li-part li-middle">
                  {" "}
                  <span className="li-value">{i18n.t('comparison.tableSection04.row02.title2')}</span>
                  <span className="li-name">
                    {i18n.t('comparison.tableSection04.row02.text2')}
                  </span>
                </div>
                <div className="li-part li-right">
                  {" "}
                  <span className="li-value">
                    {this.state.type == 0
                      ? i18n.t('comparison.tableSection04.row02.title3_01')
                      : this.state.type == 1
                      ? i18n.t('comparison.tableSection04.row02.title3_02')
                      : i18n.t('comparison.tableSection04.row02.title3_02')}
                  </span>
                  <span className="li-name">
                    {this.state.type == 0
                      ? i18n.t('comparison.tableSection04.row02.text3_01')
                      : this.state.type == 1
                      ? i18n.t('comparison.tableSection04.row02.text3_02')
                      : i18n.t('comparison.tableSection04.row02.text3_02')}
                  </span>
                </div>
              </li>
              <li className="porovnani-list-item">
                <div className="li-part li-left">
                  <span className="li-value">{i18n.t('comparison.tableSection04.row03.title1')}</span>

                  <span className="li-name">
                    {i18n.t('comparison.tableSection04.row03.text1')}
                  </span>
                </div>
                <div className="li-part li-middle">
                  {" "}
                  <span className="li-value">
                    {i18n.t('comparison.tableSection04.row03.title2')}
                  </span>
                  <span className="li-name">
                    {i18n.t('comparison.tableSection04.row03.text2')}
                  </span>
                </div>
                <div className="li-part li-right">
                  {" "}
                  <span className="li-value">
                    {this.state.type == 0
                      ? i18n.t('comparison.tableSection04.row03.title3')
                      : this.state.type == 1
                      ? i18n.t('comparison.tableSection04.row03.title3')
                      : i18n.t('comparison.tableSection04.row03.title3')}
                  </span>
                  <span className="li-name">
                    {this.state.type == 0
                      ? i18n.t('comparison.tableSection04.row03.text3')
                      : this.state.type == 1
                      ? i18n.t('comparison.tableSection04.row03.text3')
                      : i18n.t('comparison.tableSection04.row03.text3')}
                  </span>
                </div>
              </li>
              <li className="porovnani-list-item">
                <div className="li-part li-left">
                  <span className="li-value">{i18n.t('comparison.tableSection04.row04.title1')}</span>

                  <span className="li-name">
                    {i18n.t('comparison.tableSection04.row04.text1')}
                  </span>
                </div>
                <div className="li-part li-middle">
                  {" "}
                  <span className="li-value">{i18n.t('comparison.tableSection04.row04.title2')}</span>
                  <span className="li-name">
                    {i18n.t('comparison.tableSection04.row04.text2')}
                  </span>
                </div>
                <div className="li-part li-right">
                  {" "}
                  <span className="li-value">
                    {this.state.type == 0
                      ? i18n.t('comparison.tableSection04.row04.title3')
                      : this.state.type == 1
                      ? i18n.t('comparison.tableSection04.row04.title3')
                      : i18n.t('comparison.tableSection04.row04.title3')}
                  </span>
                  <span className="li-name">
                    {this.state.type == 0
                      ? i18n.t('comparison.tableSection04.row04.text3')
                      : this.state.type == 1
                      ? i18n.t('comparison.tableSection04.row04.text3')
                      : i18n.t('comparison.tableSection04.row04.text3')}
                  </span>
                </div>
              </li>

              <li className="porovnani-list-item section-heading">
                <div className="li-part li-left"></div>
                <div className="li-part li-middle">
                  { i18n.t('comparison.tableSection05.title') }
                </div>
                <div className="li-part li-right"></div>
              </li>

              <li className="porovnani-list-item">
                <div className="li-part li-left">
                  <span className="li-value">{i18n.t('comparison.tableSection05.row01.title1')}</span>

                  <span className="li-name">
                    {i18n.t('comparison.tableSection05.row01.text1')}
                  </span>
                </div>
                <div className="li-part li-middle">
                  {" "}
                  <span className="li-value">{i18n.t('comparison.tableSection05.row01.title2')}</span>
                  <span className="li-name">
                    {i18n.t('comparison.tableSection05.row01.text2')}
                  </span>
                </div>
                <div className="li-part li-right">
                  {" "}
                  <span className="li-value">
                    {this.state.type == 0
                      ? i18n.t('comparison.tableSection05.row01.title3')
                      : this.state.type == 1
                      ? i18n.t('comparison.tableSection05.row01.title3')
                      : i18n.t('comparison.tableSection05.row01.title3')}
                  </span>
                  <span className="li-name">
                    {this.state.type == 0
                      ? i18n.t('comparison.tableSection05.row01.text3_01')
                      : this.state.type == 1
                      ? i18n.t('comparison.tableSection05.row01.text3_02')
                      : i18n.t('comparison.tableSection05.row01.text3_02')}
                  </span>
                </div>
              </li>
              <li className="porovnani-list-item">
                <div className="li-part li-left">
                  <span className="li-value">{i18n.t('comparison.tableSection05.row02.title1')}</span>

                  <span className="li-name">
                    {i18n.t('comparison.tableSection05.row02.text1')}
                  </span>
                </div>
                <div className="li-part li-middle">
                  {" "}
                  <span className="li-value">{i18n.t('comparison.tableSection05.row02.title2')}</span>
                  <span className="li-name">
                    {i18n.t('comparison.tableSection05.row02.text2')}
                  </span>
                </div>
                <div className="li-part li-right">
                  {" "}
                  <span className="li-value">
                    {this.state.type == 0
                      ? i18n.t('comparison.tableSection05.row02.title3')
                      : this.state.type == 1
                      ? i18n.t('comparison.tableSection05.row02.title3')
                      : i18n.t('comparison.tableSection05.row02.title3')}
                  </span>
                  <span className="li-name">
                    {this.state.type == 0
                      ? i18n.t('comparison.tableSection05.row02.text3_01')
                      : this.state.type == 1
                      ? i18n.t('comparison.tableSection05.row02.text3_02')
                      : i18n.t('comparison.tableSection05.row02.text3_02')}
                  </span>
                </div>
              </li>
              <li className="porovnani-list-item">
                <div className="li-part li-left">
                  <span className="li-value">{i18n.t('comparison.tableSection05.row03.title1')}</span>

                  <span className="li-name"></span>
                </div>
                <div className="li-part li-middle">
                  {" "}
                  <span className="li-value">{i18n.t('comparison.tableSection05.row03.title2')}</span>
                  <span className="li-name">{i18n.t('comparison.tableSection05.row03.text2')}</span>
                </div>
                <div className="li-part li-right">
                  {" "}
                  <span className="li-value">
                    {this.state.type == 0
                      ? i18n.t('comparison.tableSection05.row03.title3_01')
                      : this.state.type == 1
                      ? i18n.t('comparison.tableSection05.row03.title3_02')
                      : i18n.t('comparison.tableSection05.row03.title3_01')}
                  </span>
                  <span className="li-name">
                    {this.state.type == 0 ? "" : this.state.type == 1 ? "" : ""}
                  </span>
                </div>
              </li>

              <li className="porovnani-list-item section-heading">
                <div className="li-part li-left"></div>
                <div className="li-part li-middle">{ i18n.t('comparison.tableSection06.title') }</div>
                <div className="li-part li-right"></div>
              </li>

              <li className="porovnani-list-item">
                <div className="li-part li-left">
                  <span className="li-value">{i18n.t('comparison.tableSection06.row01.title1')}</span>

                  <span className="li-name">
                    {i18n.t('comparison.tableSection06.row01.text1')}
                  </span>
                </div>
                <div className="li-part li-middle">
                  {" "}
                  <span className="li-value">{i18n.t('comparison.tableSection06.row01.title2')}</span>
                  <span className="li-name">
                    {i18n.t('comparison.tableSection06.row01.text2')}
                  </span>
                </div>
                <div className="li-part li-right">
                  {" "}
                  <span className="li-value">
                    {this.state.type == 0
                      ? i18n.t('comparison.tableSection06.row01.title3')
                      : this.state.type == 1
                      ? i18n.t('comparison.tableSection06.row01.title3')
                      : i18n.t('comparison.tableSection06.row01.title3')}
                  </span>
                  <span className="li-name">
                    {this.state.type == 0
                      ? i18n.t('comparison.tableSection06.row01.text3')
                      : this.state.type == 1
                      ? i18n.t('comparison.tableSection06.row01.text3')
                      : i18n.t('comparison.tableSection06.row01.text3')}
                  </span>
                </div>
              </li>
              <li className="porovnani-list-item">
                <div className="li-part li-left">
                  <span className="li-value">{i18n.t('comparison.tableSection06.row02.title1')}</span>

                  <span className="li-name">
                    {i18n.t('comparison.tableSection06.row02.text1')}
                  </span>
                </div>
                <div className="li-part li-middle">
                  {" "}
                  <span className="li-value">
                    {i18n.t('comparison.tableSection06.row02.title2')}
                  </span>
                  <span className="li-name">
                    {i18n.t('comparison.tableSection06.row02.text2')}
                  </span>
                </div>
                <div className="li-part li-right">
                  {" "}
                  <span className="li-value">
                    {this.state.type == 0
                      ? i18n.t('comparison.tableSection06.row02.title3')
                      : this.state.type == 1
                      ? i18n.t('comparison.tableSection06.row02.title3')
                      : i18n.t('comparison.tableSection06.row02.title3')}
                  </span>
                  <span className="li-name">
                    {this.state.type == 0
                      ? i18n.t('comparison.tableSection06.row02.text3')
                      : this.state.type == 1
                      ? i18n.t('comparison.tableSection06.row02.text3')
                      : i18n.t('comparison.tableSection06.row02.text3')}
                  </span>
                </div>
              </li>
              <li className="porovnani-list-item">
                <div className="li-part li-left">
                  <span className="li-value">{i18n.t('comparison.tableSection06.row03.title1')}</span>

                  <span className="li-name">
                    {i18n.t('comparison.tableSection06.row03.text1')}
                  </span>
                </div>
                <div className="li-part li-middle">
                  {" "}
                  <span className="li-value">{i18n.t('comparison.tableSection06.row03.title2')}</span>
                  <span className="li-name">
                    {i18n.t('comparison.tableSection06.row03.text2')}
                  </span>
                </div>
                <div className="li-part li-right">
                  {" "}
                  <span className="li-value">
                    {this.state.type == 0
                      ? i18n.t('comparison.tableSection06.row03.title3')
                      : this.state.type == 1
                      ? i18n.t('comparison.tableSection06.row03.title3')
                      : i18n.t('comparison.tableSection06.row03.title3')}
                  </span>
                  <span className="li-name">
                    {this.state.type == 0
                      ? i18n.t('comparison.tableSection06.row03.text3')
                      : this.state.type == 1
                      ? i18n.t('comparison.tableSection06.row03.text3')
                      : i18n.t('comparison.tableSection06.row03.text3')}
                  </span>
                </div>
              </li>

              <li className="porovnani-list-item section-heading">
                <div className="li-part li-left"></div>
                <div className="li-part li-middle">
                  { i18n.t('comparison.tableSection07.title') }
                </div>
                <div className="li-part li-right"></div>
              </li>

              <li className="porovnani-list-item">
                <div className="li-part li-left">
                  <span className="li-value">{i18n.t('comparison.tableSection07.row01.title1')}</span>

                  <span className="li-name">{i18n.t('comparison.tableSection07.row01.text1')}</span>
                </div>
                <div className="li-part li-middle">
                  {" "}
                  <span className="li-value">
                    {i18n.t('comparison.tableSection07.row01.title2')}
                  </span>
                  <span className="li-name">
                    {i18n.t('comparison.tableSection07.row01.text2')}
                  </span>
                </div>
                <div className="li-part li-right">
                  {" "}
                  <span className="li-value">
                    {this.state.type == 0
                      ? i18n.t('comparison.tableSection07.row01.title3')
                      : this.state.type == 1
                      ? i18n.t('comparison.tableSection07.row01.title3')
                      : i18n.t('comparison.tableSection07.row01.title3')}
                  </span>
                  <span className="li-name">
                    {this.state.type == 0
                      ? i18n.t('comparison.tableSection07.row01.text3_01')
                      : this.state.type == 1
                      ? i18n.t('comparison.tableSection07.row01.text3_02')
                      : i18n.t('comparison.tableSection07.row01.text3_02')}
                  </span>
                </div>
              </li>
              <li className="porovnani-list-item">
                <div className="li-part li-left">
                  <span className="li-value">{i18n.t('comparison.tableSection07.row02.title1')}</span>

                  <span className="li-name">{i18n.t('comparison.tableSection07.row02.text1')}</span>
                </div>
                <div className="li-part li-middle">
                  {" "}
                  <span className="li-value">
                    {i18n.t('comparison.tableSection07.row02.title2')}
                  </span>
                  <span className="li-name">
                    {i18n.t('comparison.tableSection07.row02.text2')}
                  </span>
                </div>
                <div className="li-part li-right">
                  {" "}
                  <span className="li-value">
                    {this.state.type == 0
                      ? i18n.t('comparison.tableSection07.row02.title3')
                      : this.state.type == 1
                      ? i18n.t('comparison.tableSection07.row02.title3')
                      : i18n.t('comparison.tableSection07.row02.title3')}
                  </span>
                  <span className="li-name">
                    {this.state.type == 0
                      ? i18n.t('comparison.tableSection07.row02.text3_01')
                      : this.state.type == 1
                      ? i18n.t('comparison.tableSection07.row02.text3_02')
                      : i18n.t('comparison.tableSection07.row02.text3_02')}
                  </span>
                </div>
              </li>
              <li className="porovnani-list-item">
                <div className="li-part li-left">
                  <span className="li-value">{i18n.t('comparison.tableSection07.row03.title1')}</span>

                  <span className="li-name">
                    {i18n.t('comparison.tableSection07.row03.text1')}
                  </span>
                </div>
                <div className="li-part li-middle">
                  {" "}
                  <span className="li-value">{i18n.t('comparison.tableSection07.row03.title2')}</span>
                  <span className="li-name">
                    {i18n.t('comparison.tableSection07.row03.text2')}
                  </span>
                </div>
                <div className="li-part li-right">
                  {" "}
                  <span className="li-value">
                    {this.state.type == 0
                      ? i18n.t('comparison.tableSection07.row03.title3_01')
                      : this.state.type == 1
                      ? i18n.t('comparison.tableSection07.row03.title3_02')
                      : i18n.t('comparison.tableSection07.row03.title3_03')}
                  </span>
                  <span className="li-name">
                    {this.state.type == 0
                      ? i18n.t('comparison.tableSection07.row03.text3_01')
                      : this.state.type == 1
                      ? i18n.t('comparison.tableSection07.row03.text3_02')
                      : i18n.t('comparison.tableSection07.row03.text3_03')}
                  </span>
                </div>
              </li>

              <li className="porovnani-list-item">
                <div className="li-part li-left">
                  <span className="li-value">{i18n.t('comparison.tableSection07.row04.title1')}</span>

                  <span className="li-name">{i18n.t('comparison.tableSection07.row04.text1')}</span>
                </div>
                <div className="li-part li-middle">
                  {" "}
                  <span className="li-value">{i18n.t('comparison.tableSection07.row04.title2')}</span>
                  <span className="li-name">
                    {i18n.t('comparison.tableSection07.row04.text2')}
                  </span>
                </div>
                <div className="li-part li-right">
                  {" "}
                  <span className="li-value">
                    {this.state.type == 0
                      ? i18n.t('comparison.tableSection07.row04.title3')
                      : this.state.type == 1
                      ? i18n.t('comparison.tableSection07.row04.title3')
                      : i18n.t('comparison.tableSection07.row04.title3')}
                  </span>
                  <span className="li-name">
                    {this.state.type == 0
                      ? i18n.t('comparison.tableSection07.row04.text3')
                      : this.state.type == 1
                      ? i18n.t('comparison.tableSection07.row04.text3')
                      : i18n.t('comparison.tableSection07.row04.text3')}
                  </span>
                </div>
              </li>
              <li className="porovnani-list-item">
                <div className="li-part li-left">
                  <span className="li-value">{i18n.t('comparison.tableSection07.row05.title1')}</span>

                  <span className="li-name">
                    {i18n.t('comparison.tableSection07.row05.text1')}
                  </span>
                </div>
                <div className="li-part li-middle">
                  {" "}
                  <span className="li-value">{i18n.t('comparison.tableSection07.row05.title2')}</span>
                  <span className="li-name">
                    {i18n.t('comparison.tableSection07.row05.text2')}
                  </span>
                </div>
                <div className="li-part li-right">
                  {" "}
                  <span className="li-value">
                    {this.state.type == 0
                      ? i18n.t('comparison.tableSection07.row05.title3_01')
                      : this.state.type == 1
                      ? i18n.t('comparison.tableSection07.row05.title3_02')
                      : i18n.t('comparison.tableSection07.row05.title3_02')}
                  </span>
                  <span className="li-name">
                    {this.state.type == 0
                      ? i18n.t('comparison.tableSection07.row05.text3_01')
                      : this.state.type == 1
                      ? i18n.t('comparison.tableSection07.row05.text2')
                      : i18n.t('comparison.tableSection07.row05.text3_02')}
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div
          className="scroll-cont"
          onClick={this.scrollToContent}
          style={this.state.scroll_display}
        >
          <img
            className="scroll-icon"
            src={config.paths.icons + "ic_scroll_grey.png"}
            alt="Pokračovat"
          />
          <span className="scroll-continue">{i18n.t('comparison.tableTitle')}</span>
        </div>
      </div>
    );
  }
}

export default PorovnaniVytapeni;
