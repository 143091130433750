import React, {useCallback} from 'react';

import AnimationAboutNumber from './AnimationAboutNumber';
import AnimationAboutSheet from './AnimationAboutSheet';
import AnimationAboutContainer from './AnimationAboutContainer';
import AnimationAboutHeaderSplit from './AnimationAboutHeaderSplit';
import AnimationAboutHeaderTitle from './AnimationAboutHeaderTitle';
import AnimationAboutHeaderText from './AnimationAboutHeaderText';
import AnimationAboutTile from './AnimationAboutTile';
import AnimationAboutSheetBackground from './AnimationAboutSheetBackground';
import AnimationAboutSlider from './AnimationAboutSlider';
import AnimationAboutSimpleCarousel from './AnimationAboutSimpleCarousel';

import config from '../../../../config/config.json';

import i18n from '../i18n';

function AnimationAboutSection3()
{
    const scrollDown = useCallback(() => {
        const el = document.querySelector('.AnimationAboutSection4');

        if (el) {
            el.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
        }
    }, []);

    return (
        <section className="AnimationAboutSection AnimationAboutSection3">
            <AnimationAboutNumber>03</AnimationAboutNumber>
            <AnimationAboutSheet kartoteka="tmava" theme="dark">
                <AnimationAboutContainer width="header">
                    <div>
                        <AnimationAboutHeaderTitle>{i18n.t('about.section03.title')}</AnimationAboutHeaderTitle>
                        <AnimationAboutHeaderText>
                            <span className="AnimationAbout--a1" dangerouslySetInnerHTML={{ __html: i18n.t('about.section03.text') }}></span>
                        </AnimationAboutHeaderText>
                    </div>
                </AnimationAboutContainer>
            </AnimationAboutSheet>
            <AnimationAboutSheet kartoteka="bila" kartotekaPushed theme="light">
                <AnimationAboutContainer>
                    <AnimationAboutSlider
                        count={2}
                        render={({ current, prev, next }) => {
                            return (
                                <AnimationAboutTile>
                                    <div className="AnimationAboutTile__text-content AnimationAbout--69">
                                        <h4 className="AnimationAboutTile__title AnimationAbout--black">{i18n.t('about.section03.subsection01.title')}</h4>
                                        <div className="AnimationAbout--line-medium" dangerouslySetInnerHTML={{ __html: i18n.t('about.section03.subsection01.text') }}></div>
                                        <div>
                                            <img style={{ width: '2.23958vw' }} className="AnimationAbout__direction" role="button" tabIndex="-1" onClick={scrollDown} onKeyDown={scrollDown} src={config.paths.about + 'direction_down--dark.png'} alt="Sjěďte dolů" />
                                        </div>
                                    </div>
                                    <div>
                                        <AnimationAboutSimpleCarousel animated current={current} prev={prev} next={next}>
                                            <img src={config.paths.about + "heatflow_vs_teplovod_2_" + i18n.language + ".png"} alt="Podlaha HeatFlow" />
                                            <img src={config.paths.about + 'heatflow_vs_rohoz_2.png'} alt="Podlaha HeatFlow" />
                                        </AnimationAboutSimpleCarousel>
                                    </div>
                                </AnimationAboutTile>
                            );
                        }}
                    />
                </AnimationAboutContainer>
            </AnimationAboutSheet>
            <AnimationAboutSheet
                kartoteka="svetla"
                theme="gray--light"
                title={
                    <span>
                        {i18n.t('about.section03.subsection02.title')}
                        <sup>*</sup>
                    </span>
                }
            >
                <AnimationAboutContainer>
                    <AnimationAboutTile single>
                        <div className="AnimationAbout--line-medium AnimationAbout--69" dangerouslySetInnerHTML={{ __html: i18n.t('about.section03.subsection02.text') }}></div>
                    </AnimationAboutTile>
                    <div style={{ marginTop: '2.94270vw' }}>
                        <img src={config.paths.about + "SVG _ PROVOZNI TEPLOTY_" + i18n.language + ".svg"} alt="graf" />
                    </div>
                </AnimationAboutContainer>
            </AnimationAboutSheet>
            <AnimationAboutSheet kartoteka="bila" kartotekaPushed noPaddingBottom theme="light">
                <AnimationAboutContainer>
                    <AnimationAboutTile reversed>
                        <div>
                            <img src={config.paths.about + 'vlhkost.png'} alt="Boj s vlhkostí" />
                        </div>
                        <div className="AnimationAboutTile__text-content AnimationAbout--69">
                            <h4 className="AnimationAboutTile__title AnimationAbout--black">{i18n.t('about.section03.subsection03.title')}</h4>
                            <div className="AnimationAbout--line-medium" dangerouslySetInnerHTML={{ __html: i18n.t('about.section03.subsection03.text') }}></div>
                            <div>{i18n.t('about.section03.subsection03.note')}</div>
                            <div className="AnimationAboutTile__triples">
                                <div className="AnimationAboutTile__triple AnimationAbout--83">
                                    <div className="AnimationAboutTile__triple__title">{i18n.t('about.section03.subsection03.valueTitle1')}</div>
                                    <div className="AnimationAboutTile__triple__content">18 {i18n.t('about.section03.months')}</div>
                                </div>
                                <div className="AnimationAboutTile__triple AnimationAbout--orange">
                                    <div className="AnimationAboutTile__triple__title">
                                        Heated.
                                    </div>
                                    <div className="AnimationAboutTile__triple__content">9 {i18n.t('about.section03.months')}</div>
                                </div>
                                <div className="AnimationAboutTile__triple AnimationAbout--83">
                                    <div className="AnimationAboutTile__triple__title">{i18n.t('about.section03.subsection03.valueTitle2')}</div>
                                    <div className="AnimationAboutTile__triple__content">24 {i18n.t('about.section03.months')}</div>
                                </div>
                            </div>
                        </div>
                    </AnimationAboutTile>
                </AnimationAboutContainer>
                <AnimationAboutSheetBackground image={config.paths.about + 'podlaha_vrstvy.png'} />
            </AnimationAboutSheet>
        </section>
    );
}

export default AnimationAboutSection3;
