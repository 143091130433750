import * as React from "react";
import config from "../../../../config/config.json";
import { Menu } from "../components/Menu";
import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import styles from "react-responsive-carousel/lib/styles/carousel.min.css";
import { showReference } from "../api.js";
import { getPageSEO } from "../functions";
import { RENDERER_TYPE } from "pixi.js";

import i18n from '../i18n';

class ZajimavyProjekt extends React.Component {
  constructor(props) {
    super(props);

    this.pageSEO = getPageSEO();

    document.title = this.pageSEO.title;

    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", this.pageSEO.keywords);
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", this.pageSEO.description);

    document.querySelector('meta[name=\"title\"]').setAttribute('content', this.pageSEO.metaTitle);

    this.state = {
      gallery: "",

      loxone: "",
      heatflow: "",
      fve: "",
      podlaha: "",

      data: {},
      related: [],

      description: null,
      year: null,

      length: 1,
    };

    this.closeGallery = this.closeGallery.bind(this);
    this.openGallery = this.openGallery.bind(this);

    this.setData = this.setData.bind(this);

    this.capitalize = this.capitalize.bind(this);

    this.modalClicked = this.modalClicked.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll, { passive: true });

    this.fetchReference();

    //showReference.request("Zajímavé projekty", this.setData, 1);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);

    document.body.style.overflowY = "scroll";

    this.cancelReference();
  }

  fetchReference() {

    const id  = this.props.match.params.id;

    showReference.request(id, this.setData);
  }

  cancelReference() {
    if (showReference.running) {
      showReference.cancel();
    }
  }

  setData(response) {
    this.closeGallery();

    const isHtml = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/.test(response.data.description || '');

    this.setState({
      data: response.data,
      related: response.data.related || [],

      fve: response.data.includes_fve ? "active" : "",
      heatflow: response.data.includes_heatflow ? "active" : "",
      loxone: response.data.includes_hoxone ? "active" : "",
      podlaha: response.data.includes_floor ? "active" : "",

      length: Object.keys(response.data.aditional_images).length,
      htmlDesc: isHtml,
      description: isHtml ? response.data.description : ( response.data.description || '' ).split(/(\r?\n){2,}/g)
                                            .map((desc, key) => {
                                                return <p key={key}>{desc}</p>;
                                            }),
      year: (response.data.realization_date || '----' ).slice(0, 4),
    });
  }

  closeGallery() {
    this.setState({ gallery: "" });

    document.body.style.overflowY = "scroll";
  }

  openGallery() {
    document.body.style.overflowY = "hidden";

    const additional_photos = this.state.data.aditional_images;

    var more_photos = Object.keys(additional_photos).map(function(key) {
      return additional_photos[key];
    });

    var photos = more_photos.map((value, index) => {
      return (
        <div key={index}>
          <img className="projekt-gallery-image" src={value} alt="" />
        </div>
      );
    });

    var carousel = (
      <div>
        <Carousel
          statusFormatter={(current, total) => {
            return current + "/" + total;
          }}
          useKeyboardArrows
        >
          {photos}
        </Carousel>
      </div>
    );

    this.setState({ carousel: carousel, gallery: "open" });
  }

  capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  modalClicked(e) {
    if (e.target.dataset.name == "modal") {
      this.closeGallery();
    }
  }

  render() {
    return (
      <div className="main-container-projekt" id="">
        <div
          className={
            "loading-container show"
          }
        >
          <div className="projekt-bg-image-container">
            <img
              className="projekt-bg-image"
              src={this.state.data.main_image}
              alt={i18n.t('general.projectVisual')}
            />
            <div className="projekt-bg-image-shadow" />
          </div>
          {this.state.gallery == "open" ? (
            <div
              className={"projekt-gallery-modal " + this.state.gallery}
              data-name="modal"
              onClick={this.modalClicked}
            >
              <div className="projekt-gallery-container">
                <div className="gallery-close" onClick={this.closeGallery}>
                  <img
                    className="projekt-gallery-image"
                    src={config.paths.icons + "ic_plus_active.png"}
                    alt=""
                  />
                </div>
                {this.state.carousel}
              </div>
            </div>
          ) : (
            ""
          )}
          {/* <div
            className={"projekt-gallery-modal " + this.state.gallery}
            data-name="modal"
            onClick={this.modalClicked}
          >
            <div className="projekt-gallery-container">
              <div className="gallery-close" onClick={this.closeGallery}>
                <img
                  className="projekt-gallery-image"
                  src={config.paths.icons + "ic_plus_active.png"}
                  alt=""
                />
              </div>
              {this.state.carousel}
            </div>
          </div> */}
          <div className="projekt-container">
            <div className="projekt-top">
              <div className="projekt-name">
                <span className="projekt-name-orange">
                  {this.state.data.name !== null ? this.state.data.name : "-"}
                </span>
                <br />
                <Link
                  to={config.pages.ref_projekty.url}
                  className="projekt-back"
                >
                  <span>{i18n.t('reference.interestingProjects')}</span>
                </Link>
                <span className="projekt-name-location">
                  {this.state.data.subtitle}
                </span>
              </div>
              <div className="projekt-gallery-controls">
                <div className="projekt-gallery-info">
                  1/{this.state.length}
                  <img
                    className="projekt-gallery-icon-grid"
                    src={config.paths.icons + "ic_ref_projekty_grid_orange.png"}
                    alt="Otevřít galerii"
                    onClick={this.openGallery}
                  />
                </div>
              </div>
            </div>
            <div className="projekt-data">
              {/*<div className="projekt-data-block">*/}
              {/*  <span className="projekt-data-block-content">*/}
              {/*    <b>{this.state.year}</b>*/}
              {/*  </span>*/}
              {/*  <br />*/}
              {/*  <span className="projekt-data-block-heading">{i18n.t('general.realization')}</span>*/}
              {/*</div>*/}
              <div className="projekt-data-block">
                <span className="projekt-data-block-content">
                  <b>
                    {this.state.data.floor_area !== null
                      ? this.state.data.floor_area
                      : "-"}{" "}
                  </b>
                  m<sup>2</sup>
                </span>
                <br />
                <span className="projekt-data-block-heading">{i18n.t('general.floorArea')}</span>
              </div>
              <div className="projekt-data-block">
                <span className="projekt-data-block-content">
                  <b>
                    {this.state.data.floor_insulation !== null
                      ? this.state.data.floor_insulation
                      : "-"}
                  </b>{" "}
                  cm
                </span>
                <br />
                <span className="projekt-data-block-heading">
                  {i18n.t('general.floorIsolation')}
                </span>
              </div>
              <div className="projekt-data-block">
                <span className="projekt-data-block-content">
                  <b>
                    {this.state.data.heat_loss !== null
                      ? this.state.data.heat_loss
                      : "-"}
                  </b>{" "}
                  kW
                </span>
                <br />
                <span className="projekt-data-block-heading">
                  {i18n.t('general.heatLosses')}
                </span>
              </div>
              {this.state.data.type ? (
                <div className="projekt-data-block">
                  <span className="projekt-data-block-content">
                    <b>
                      {this.state.data.type_cm ? this.state.data.type_cm : "-"}
                    </b>{" "}
                    cm
                  </span>
                  <br />
                  <span className="projekt-data-block-heading">
                    {this.capitalize(this.state.data.type)}
                  </span>
                </div>
              ) : (
                ""
              )}
              {/* <div className="projekt-data-block">
              <span className="projekt-data-block-content">
                <b>6</b> cm
              </span>
              <br />
              <span className="projekt-data-block-heading">
                {this.capitalize(this.state.data.type)}
              </span>
            </div> */}
              <div className="projekt-data-block">
                <span className="projekt-data-block-content">
                  <b>
                    {this.state.data.expenses_per_month
                      ? this.state.data.expenses_per_month
                      : "-"}
                  </b>{" "}
                  {i18n.t('general.currency')}
                </span>
                <br />
                <span className="projekt-data-block-heading">
                  {i18n.t('general.costsPerMonth')}
                </span>
              </div>
            </div>
            <div className="projekt-content">
              <div className="projekt-desc">
                <div className="projekt-desc-left">
                  <h3 className="projekt-desc-heading">{i18n.t('general.contractDesc')}</h3>
                  {
                    this.state.htmlDesc ? (
                      <div className="projekt-desc-text" dangerouslySetInnerHTML={{__html: this.state.description}} />
                    ) : (
                      <div className="projekt-desc-text">
                        {this.state.description}
                      </div>
                    )
                  }
                </div>
                <div className="projekt-desc-right">
                  <h3 className="projekt-desc-heading">{i18n.t('general.partOfDelivery')}</h3>
                  <div className="projekt-desc-text">
                    {/* <img
                    className="projekt-desc-img"
                    src={config.paths.images + "img_projekt_dodavka.png"}
                    alt="Součást dodávky"
                  /> */}
                    <div className="projekt-dodavka">
                      <div
                        className={"dodavka-square top " + this.state.heatflow}
                      />
                      <div
                        className={"dodavka-square left " + this.state.podlaha}
                      />
                      <div
                        className={"dodavka-square right " + this.state.loxone}
                      />
                      <div
                        className={"dodavka-square bottom " + this.state.fve}
                      />
                      <div
                        className={"dodavka-text top " + this.state.heatflow}
                      >
                        {i18n.t('general.partOfDeliveryHeating')}
                      </div>
                      <div
                        className={"dodavka-text left " + this.state.podlaha}
                      >
                        {i18n.t('general.floor')}
                      </div>
                      <div
                        className={"dodavka-text right " + this.state.loxone}
                      >
                        loxone
                      </div>
                      <div className={"dodavka-text bottom " + this.state.fve}>
                        fve
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h3 className="projekt-reference-heading">{i18n.t('reference.similar')}</h3>
              <div className="projekt-reference">
                {this.state.related.map((value) => {
                  return (
                    <div key={value.id} className="projekty-one-cont">
                      <img
                        className="projekt-bg-image"
                        src={value.thumb_image || value.main_image}
                        alt="Reference thumb"
                      />
                      <Link
                        to={{
                          pathname: config.pages.ref_projekt.url.replace(':id', value.slug),
                        }}
                        className="projekt-link"
                      >
                        <div className="projekt-bottom">
                          <div className="arrow-wrapper">
                            <div className="projekt-vice-info">
                              <span>{i18n.t('general.moreAboutReference')}</span>
                              <img
                                className="projekt-vice-arrow"
                                src={
                                  config.paths.icons +
                                  "ic_arrow_right_orange.png"
                                }
                                alt={i18n.t('general.showProject')}
                              />
                            </div>
                            <div className="projekt-vice-info">
                              <img
                                className="projekt-vice-arrow"
                                src={
                                  config.paths.icons +
                                  "ic_arrow_right_white.png"
                                }
                                alt={i18n.t('general.showProject')}
                              />
                            </div>
                          </div>
                          <div className="projekt-name">
                            <span className="projekt-name-orange">
                              {value.name}
                            </span>
                            <br />
                            <span className="projekt-name-location">
                              {value.subtitle}
                            </span>
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ZajimavyProjekt;
