import React, {useState, useCallback} from 'react';

import config from '../../../../config/config.json';

import i18n from '../i18n';

const SHOW = 'Odpověď';
const CLOSE = i18n.t('general.close');

function FAQItem({title, initialyCollapsed, children, openLabel, closeLabel, grayTitle})
{
    const [collapsed, setCollapsed] = useState(initialyCollapsed === undefined ? true : initialyCollapsed);

    const toggle = useCallback(() => {
        setCollapsed((state) => !state);
    }, [setCollapsed]);

    return (
        <div className={[
            "FAQItem",
            collapsed && "FAQItem--collapsed",
        ].filter((el) => el).join(' ')}>
            <div role="button" tabIndex="-1" onClick={toggle} onKeyDown={toggle} className="FAQItem__header">
                <div className={[
                    "FAQItem__title",
                    grayTitle && 'AnimationAbout--69',
                ].filter((el) => el).join(' ')}>
                    {title}
                </div>
                <div className="FAQItem__collapse">
                    <span>
                        {collapsed && (openLabel ? openLabel : SHOW)}
                        {!collapsed && (closeLabel ? closeLabel : CLOSE)}
                    </span>
                    <img style={{width: '2.70833vw'}} className="FAQItem__collapse__icon" src={config.paths.about + 'collapse.png'} alt={ i18n.t('general.toHide') } />
                </div>
            </div>
            <div className="FAQItem__content">
                {children}
            </div>
        </div>
    );
}

export default FAQItem;
