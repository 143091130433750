import * as React from "react";

import i18n from '../i18n';

class TotalCount extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  buildingType(building_type, has = true, multiple = true) {
      if (building_type) {
        return (
            ' ' +
            {
                drevo: has ? (multiple ? i18n.t('general.countWoodenHouseMultiple') : i18n.t('general.countWoodenHouse')) : i18n.t('general.countNoWoodenHouse'),
                kamen: has ? (multiple ? i18n.t('general.countBrickHouseMultiple') : i18n.t('general.countBrickHouse')) : i18n.t('general.countNoBrickHouse'),
                rekonstrukce: has ? (multiple ? i18n.t('general.countReconstructionMultiple') : i18n.t('general.countReconstruction')) : i18n.t('general.countNoReconstruction'),
            }[building_type]
        );
      }

      return (
          ' ' +
          (has
              ? multiple
                  ? i18n.t('general.countHouseMultiple')
                  : i18n.t('general.countHouse')
              : i18n.t('general.countNoHouse'))
      );
  }

  floorArea(flooar_area) {
    return ({
        '': '',
        '-100': ' do 100 m²',
        '-150': ' 101-150 m²',
        '+150': ' nad 150 m²',
    }[flooar_area]);
  }

  expenses(expenses_per_month) {
    return ({
        '': '',
        '-1000': i18n.language === 'cs' ? ' do 1000 Kč' : ' do 40 €',
        '-2000': i18n.language === 'cs' ? ' do 2000 Kč' : ' do 80 €',
        '-3000': i18n.language === 'cs' ? ' do 3000 Kč' : ' do 120 €',
        '+3000': i18n.language === 'cs' ? ' nad 3000 Kč' : ' nad 120 €',
    }[expenses_per_month]);
  }

  render() {
    if (!this.props.running && (this.props.total_count !== undefined && this.props.total_count !== null)) {
        if (this.props.total_count === 0) {
            return (
                <div className="total-count">
                    {i18n.t('general.selectionDoesntFit')} {this.buildingType(this.props.building_type, false)}.
                </div>
            );
        }

        return (
            <div className="total-count">
                {i18n.t('general.selectionFits')} <span className={'total-count__count'}> {this.props.total_count} </span>
                {this.buildingType(this.props.building_type, true, this.props.total_count > 1)}{this.floorArea(this.props.floor_area)}{this.expenses(this.props.expenses_per_month)}.
            </div>
        );
    }

    return null;
  }
}

export default TotalCount;
