import * as React from "react";
import config from "../../../../config/config.json";
import { Link } from "react-router-dom";
import Slider, { createSliderWithTooltip } from "rc-slider";
import "../../../../node_modules/rc-slider/assets/index.css";
import { postOffers } from "../api.js";
import { FormItemUpload } from "../components/FormItemUpload.jsx";
import { localConsoleLog } from "../functions.js";
import smoothScrollIntoView from "smooth-scroll-into-view-if-needed";
import { getPageSEO } from "../functions";

import i18n from '../i18n'

class CenovaNabidka extends React.Component {
  constructor(props) {
    super(props);

    this.pageSEO = getPageSEO();

    document.title = this.pageSEO.title;
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", this.pageSEO.keywords);
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", this.pageSEO.description);

    document.querySelector('meta[name=\"title\"]').setAttribute('content', this.pageSEO.metaTitle);

    this.state = {
      scroll_display: { display: "block" },
      slider_value: 120,
      name: "",
      city: "",
      tel: "",
      email: "",
      note: "",
      phase: "",
      sources: "",
      fve: false,
      inteligent_control: false,
      floor_structure: false,
      sent: false,
      clicked: false,
      valid_email: "",
      file_input: { display: "none" },
      select_empty: "",
      formItems: {
        podklady: {
          value: "",
          error: ""
        }
      },
      title: "",
      error_message: "",
      submitted: false,
      missing_files: false,
      gdpr_modal: false
    };

    this.wrapper = React.createRef();

    this.sliderHandle = this.sliderHandle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.checkNumber = this.checkNumber.bind(this);
    this.posted = this.posted.bind(this);
    this.showAgain = this.showAgain.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
    this.preventEnter = this.preventEnter.bind(this);
    this.openPrivacy = this.openPrivacy.bind(this);
    this.modalClicked = this.modalClicked.bind(this);
    this.changeFormUploadItem = this.changeFormUploadItem.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll, { passive: true });
    window.addEventListener("keydown", this.preventEnter);
    document.body.style.overflowY = "scroll";
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
    document.body.style.overflowY = "scroll";
  }
  preventEnter(e) {
    if (e.keyCode == 13) {
      e.preventDefault();
      return false;
    }
  }
  modalClicked(e) {
    if (e.target.dataset.name == "modal") {
      this.openPrivacy();
    }
  }
  openPrivacy() {
    if (this.state.gdpr_modal) {
      document.body.style.overflowY = "scroll";
      this.setState({
        gdpr_modal: false
      });
    } else {
      document.body.style.overflowY = "hidden";
      this.setState({
        gdpr_modal: true
      });
    }
  }

  sliderHandle(value) {
    this.setState({ slider_value: value });
  }
  scrollToTop() {
    smoothScrollIntoView(this.wrapper.current, {
      behavior: "smooth",
      block: "start",
      duration: 1200
    });
  }
  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (target.type == "select-one" && value == "Mám projekt") {
      this.setState({
        file_input: { display: "block" }
      });
    }
    if (target.type == "select-one" && value != "Mám projekt") {
      this.setState({
        file_input: { display: "none" }
      });
    }

    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value) && name == "email") {
      this.setState({ valid_email: "" });
    }

    this.setState({
      [name]: value
    });
  }
  handleSubmit(e) {
    e.preventDefault();

    const data = {
      type: "Cenová nabídka",
      name: this.state.name,
      city: this.state.city,
      phone_number: this.state.tel,
      email: this.state.email,
      note: this.state.note,
      area: this.state.slider_value,
      stage: this.state.phase,
      intelligent_controll: this.state.inteligent_control,
      floor_structure: this.state.floor_structure,
      fve: this.state.fve,
      sources: this.state.sources
    };
    if (this.state.phase == "Mám projekt" && this.state.sources == "") {
      this.setState({ submitted: true, missing_files: true });
      return false;
    }

    if (
      this.state.name != "" &&
      this.state.city != "" &&
      this.state.tel != "" &&
      this.state.email != "" &&
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email) &&
      this.state.slider_value != ""
    ) {
      if (!this.state.clicked) {
        this.setState({
          clicked: true,
          sent: true,
          title: i18n.t('general.sending'),
          form_response: i18n.t('general.loadingFiles')
        });

        postOffers.request(data, this.posted);
        this.scrollToTop();
      }
    } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email)) {
      this.setState({ valid_email: "not_valid", submitted: true });
    }
    // else if (this.state.phase == "") {
    //   this.setState({ select_empty: "empty" });
    // }
    else {
      this.setState({ submitted: true });
    }
  }
  posted(response) {
    if (response == "Too large") {
      this.setState({
        title: "",
        error_message: i18n.t('general.filesTooLarge'),

        clicked: false,
        sent: false
      });
      return;
    }

    if (response.status == 200 || response.status == 201) {
      this.setState({
        title: i18n.t('general.sended'),
        slider_value: 120,
        name: "",
        city: "",
        tel: "",
        email: "",
        note: "",
        phase: "",
        sources: "",
        form_response: i18n.t('general.takeCareASAP'),
        fve: false,
        inteligent_control: false,
        floor_structure: false,
        sent: true
      });

      window.dataLayer.push({
        'event': 'lead',
        'formLocation': 'cenova-nabidka',
      });
    } else if (response.status == 429) {
      this.setState({
        title: i18n.t('general.sendingFailed'),
        form_response:
          i18n.t('general.contactUsViaEmail'),
        clicked: false
      });
    } else {
      this.setState({
        title: i18n.t('general.sendingFailed'),
        form_response:
          i18n.t('general.contactUsViaEmail'),
        clicked: false
      });
    }
  }
  changeFormUploadItem(name, file) {
    if (name == "big") {
    } else if (name == "delete") {
      var temp = this.state.sources;
      temp.splice(file, 1);

      this.setState({
        sources: temp,
        formItems: {
          podklady: {
            value: temp,
            error: ""
          }
        }
      });
    } else {
      this.setState({ missing_files: false });
      if (this.state[name]) {
        var temp = this.state[name];
        temp.push(file);

        this.setState({
          [name]: temp,
          formItems: {
            podklady: {
              value: temp,
              error: ""
            }
          }
        });
      } else {
        this.setState({
          [name]: [file],
          formItems: {
            podklady: {
              value: [file],
              error: ""
            }
          }
        });
      }
    }
  }

  checkNumber(e) {
    let key = Number(e.key);
    if (isNaN(key) || e.key === null) {
      e.preventDefault();
    }
  }
  showAgain() {
    this.setState({ sent: false, clicked: false });
  }
  render() {
    var slider_handle = config.paths.icons + "ic_handle_orange.png";
    var slider_head = config.paths.icons + "ic_mobile_slider_bg_active.png";
    var slider_tail = config.paths.icons + "ic_mobile_slider_bg.png";

    return (
        <div className="main-nabidka-container" ref={this.wrapper}>
            {this.state.gdpr_modal ? (
                <div className="gdpr-modal" onClick={this.modalClicked} data-name="modal">
                    <div className="gdpr-cont">
                        <div className="gdpr-close" onClick={this.openPrivacy}>
                            <img className="" src={config.paths.icons + 'ic_close_grey.png'} alt="" />
                        </div>
                        <div className="gdpr-wrapper">
                            <h1 className="gdpr-nadpis">{i18n.t('general.privaciPolicyAgreement')}</h1>
                            <div className="gdpr-content" dangerouslySetInnerHTML={{ __html: i18n.t('general.gdprContent') }}></div>
                        </div>
                    </div>
                </div>
            ) : (
                ''
            )}

            <div className="nabidka-container">
                <div className="page-content">
                    <div className="page-heading">
                        {!this.state.sent ? (
                            <div className="page-heading-wrapper">
                                <div className="nabidka-route">
                                    <b>Heated. </b>
                                    {i18n.t('priceOffer.title')}
                                </div>
                                {/* <h1 className="page-heading-title"> */}
                                {this.state.title == '' ? <h1 className="page-heading-title" dangerouslySetInnerHTML={{ __html: i18n.t('priceOffer.header') }}></h1> : <h1 className="page-heading-title odeslano">{this.state.title}</h1>}
                                {/* chci <b>cenovou nabídku</b> */}
                                {/* </h1> */}
                                {/* <div className="nabidka-route">
                    {i18n.t('priceOffer.header')}
                  </div> */}
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className="page-body">
                        {this.state.sent ? (
                            <div className="sent-wrapper">
                                <h2 className="section-heading">
                                    {this.state.title == i18n.t('general.sended') ? <img className="odeslano-icon" src={config.paths.icons + 'ic_checked_orange.png'} alt={i18n.t('general.sended')} /> : ''}
                                    <b>{this.state.title}</b>
                                </h2>
                                <div className="odeslano-text">{this.state.form_response}</div>{' '}
                            </div>
                        ) : (
                            <div className="error_message">{this.state.error_message}</div>
                        )}
                        {/* <div
                className={"form-again " + (this.state.sent ? "sent" : "")}
                onClick={this.showAgain}
              >
                Zadat další cenovou nabídku
              </div> */}
                        <div className="form-container">
                            <form action="" className={'form-nabidka ' + this.state.valid_email + ' ' + (this.state.sent ? 'sent' : '') + ' ' + (this.state.submitted ? 'submitted' : '')} onSubmit={this.handleSubmit}>
                                <div className="form-side">
                                    <div className="form-row two-col">
                                        <div className="form-field">
                                            <input type="text" name="name" id="form_name_nabidka" required onChange={this.handleChange} value={this.state.name} />
                                            <label htmlFor="form_name_nabidka">{i18n.t('general.fullname')} °</label>
                                            <i className="form-field-check"></i>
                                            <span className="required" style={this.state.name != '' ? { display: 'none' } : {}}>
                                                {i18n.t('general.requiredField')}
                                            </span>
                                        </div>
                                        <div className="form-field">
                                            <input type="text" name="city" id="form_city_nabidka" required onChange={this.handleChange} value={this.state.city} />
                                            <label htmlFor="form_city_nabidka">{i18n.t('general.city')} °</label>
                                            <i className="form-field-check"></i>
                                            <span className="required" style={this.state.city != '' ? { display: 'none' } : {}}>
                                                {i18n.t('general.requiredField')}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="form-row two-col">
                                        <div className="form-field">
                                            <input type="tel" pattern={`[0-9a-zA-Z]{${i18n.language === 'cs' ? '9,9' : '10,10'}}`} name="tel" id="form_tel" onKeyPress={this.checkNumber} required onChange={this.handleChange} value={this.state.tel} />
                                            <label htmlFor="form_tel">{i18n.t('general.phone')} °</label>
                                            <i className="form-field-check"></i>
                                            <span className="required" style={this.state.tel != '' ? { display: 'none' } : {}}>
                                                {i18n.t('general.requiredField')}
                                            </span>
                                        </div>
                                        <div className="form-field">
                                            <input type="email" name="email" id="form_email_nabidka" className={'email ' + (this.state.email != '' ? 'not-empty' : '')} required onChange={this.handleChange} value={this.state.email} />
                                            <label htmlFor="form_email_nabidka">email °</label>
                                            <i className="form-field-check"></i>
                                            {this.state.submitted && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email) ? <span className="required required_email">{i18n.t('general.wrongFormate')}</span> : ''}
                                        </div>
                                    </div>
                                    <div className="form-row  one-col">
                                        <div className="form-field">
                                            <textarea
                                                // type="text"
                                                name="note"
                                                id="form_note_nabidka"
                                                onChange={this.handleChange}
                                                value={this.state.note}
                                                className={this.state.note != '' ? 'filled' : ''}
                                            ></textarea>
                                            <label htmlFor="form_note_nabidka">{i18n.t('general.note')}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-side">
                                    <div className="nabidka-slider">
                                        <div className="nabidka-legend-cont">
                                            <span className="nabidka-form-label">{i18n.t('general.totalArea')} °</span>
                                            <span className="nabidka-form-area">
                                                <b>{this.state.slider_value}</b> m<sup>2</sup>
                                            </span>
                                        </div>
                                        <div className="nabidka-slider-cont">
                                            <Slider
                                                id="slider_7"
                                                className="nabidka-slider-range"
                                                defaultValue={this.state.slider_value}
                                                min={1}
                                                max={500}
                                                step={1}
                                                onChange={this.sliderHandle}
                                                trackStyle={{
                                                    backgroundImage: "url('" + slider_head + "')",
                                                    height: 24,
                                                }}
                                                handleStyle={{
                                                    borderColor: 'blue',
                                                    height: 44,
                                                    width: 26,
                                                    marginLeft: -9,
                                                    marginTop: -9,
                                                    backgroundImage: "url('" + slider_handle + "')",
                                                }}
                                                railStyle={{
                                                    backgroundImage: "url('" + slider_tail + "')",
                                                    height: 24,
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-row  one-col">
                                        <div className="form-field">
                                            <select name="phase" className={this.state.select_empty} id="form_faze_nabidka" onChange={this.handleChange} value={this.state.phase}>
                                                <option value="" defaultValue>
                                                    {i18n.t('general.projectPhaseAndDocuments')}
                                                </option>
                                                <option value="Nemám projekt">{i18n.t('general.noProject')}</option>
                                                <option value="Mám projekt">{i18n.t('general.haveProject')}</option>
                                                <option value="Mám projekt, ale nemám ho u sebe">{i18n.t('general.haveProjectNoDocumentation')}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-row  one-col" style={this.state.file_input}>
                                        <div className={'form-field files ' + (this.state.missing_files ? 'missing' : '')}>
                                            <FormItemUpload
                                                {...{
                                                    text: i18n.t('general.uploadNote'),
                                                    dataFormItem: 'sources',
                                                    value: this.state.formItems.podklady.value,
                                                    error: this.state.formItems.podklady.error,
                                                    actions: {
                                                        changeFormUploadItem: this.changeFormUploadItem,
                                                    },
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-row  one-col">
                                        <div className="form-field">
                                            <span className="form-additional-heading">{i18n.t('general.additionalOptions')}</span>
                                            <div className="form-checkbox">
                                                <span dangerouslySetInnerHTML={{ __html: i18n.t('general.priceComplete') }}></span>
                                                <input type="checkbox" name="floor_structure" id="ovladani_skladba" checked={this.state.floor_structure} onChange={this.handleChange} />
                                                <label htmlFor="ovladani_skladba" />
                                            </div>
                                            <div className="form-checkbox">
                                                <span dangerouslySetInnerHTML={{ __html: i18n.t('general.priceInteligent') }}></span>
                                                <input type="checkbox" name="inteligent_control" id="ovladani_nabidkaX" checked={this.state.inteligent_control} onChange={this.handleChange} />
                                                <label htmlFor="ovladani_nabidkaX" />
                                            </div>
                                            <div className="form-checkbox">
                                                <span dangerouslySetInnerHTML={{ __html: i18n.t('general.priceSolar') }}></span>
                                                <input type="checkbox" name="fve" id="solar_nabidkaX" checked={this.state.fve} onChange={this.handleChange} />
                                                <label htmlFor="solar_nabidkaX" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-bottom">
                                    <div className="form-field">
                                        <button type="submit" onClick={this.handleSubmit}>
                                            {i18n.t('general.toSend')}
                                        </button>
                                        <span className="form-submit-note">
                                            {i18n.t('general.sendingAgree1')}{' '}
                                            <span className="privacy-link" onClick={this.openPrivacy}>
                                                {i18n.t('general.sendingAgree2')}
                                            </span>
                                            .
                                        </span>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}

export default CenovaNabidka;
