import React from 'react';

import config from '../../../../config/config.json';

import i18n from '../i18n';

function AnimationAboutRefferenceCarousel({references, current, prev, next}) {
    return (
        <div className="AnimationAboutRefferenceCarousel">
            <div className="AnimationAboutRefferenceCarousel__slides">
                {references.map((reference, index) => {
                    return (
                        <div style={{transform: `translateX(-${current * 100}%)`}} key={reference.id} className="AnimationAboutRefferenceCarousel__reference">
                            <div className="AnimationAboutRefferenceCarousel__reference__image" style={{backgroundImage: `url(${reference.main_image})`}} />
                            <div className="AnimationAboutRefferenceCarousel__reference__header">
                                <div className="AnimationAboutRefferenceCarousel__reference__aside">
                                    <div className="AnimationAboutRefferenceCarousel__reference__index"><span className="AnimationAbout--69"><span className="AnimationAbout--black">{index + 1}</span>/{references.length}</span></div>
                                    <div className="AnimationAboutRefferenceCarousel__reference__stars">
                                        {Array(reference.stars).fill(null).map((_, index) => {
                                            return (
                                                <img key={index} src={config.paths.about + 'star.svg'} alt="Hvězda" />
                                            );
                                        })}
                                        {Array(5 - reference.stars).fill(null).map((_, index) => {
                                            return (
                                                <img key={index} src={config.paths.about + 'star_gray.svg'} alt="Hvězda" />
                                            );
                                        })}
                                    </div>
                                </div>
                                <div className="AnimationAboutRefferenceCarousel__reference__quotes">
                                    <div dangerouslySetInnerHTML={{__html: reference.first_quote}} />
                                    <div dangerouslySetInnerHTML={{__html: reference.second_quote}} />
                                </div>
                            </div>
                            <div className="AnimationAboutRefferenceCarousel__reference__main">
                                <div className="AnimationAboutRefferenceCarousel__reference__stats">
                                    <div className="AnimationAboutRefferenceCarousel__reference__stats__title">{i18n.t('general.realizationDesc')}</div>
                                    <div className="AnimationAboutRefferenceCarousel__reference__stats__data">
                                        {/*<div className="AnimationAboutRefferenceCarousel__reference__stats__stat">*/}
                                        {/*    <div><span className="AnimationAbout--semi">{reference.date ? reference.date.slice(0, 4) : (reference.realization_date ? reference.realization_date.slice(0, 4) : null)}</span></div>*/}
                                        {/*    <div>{i18n.t('general.realization')}</div>*/}
                                        {/*</div>*/}
                                        <div className="AnimationAboutRefferenceCarousel__reference__stats__stat">
                                            <div><span className="AnimationAbout--semi">{reference.floor_area}</span> m<sup>2</sup></div>
                                            <div>{i18n.t('general.floorArea')}</div>
                                        </div>
                                        <div className="AnimationAboutRefferenceCarousel__reference__stats__stat">
                                            <div><span className="AnimationAbout--semi">{reference.heat_loss}</span> kW</div>
                                            <div>{i18n.t('general.heatLosses')}</div>
                                        </div>
                                        <div className="AnimationAboutRefferenceCarousel__reference__stats__stat">
                                            <div><span className="AnimationAbout--semi">{reference.expenses_per_month}</span> Kč</div>
                                            <div>{i18n.t('general.costsPerMonth')}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="AnimationAboutRefferenceCarousel__reference__description" dangerouslySetInnerHTML={{__html: reference.description}} />
                            </div>
                        </div>
                    );
                })}
            </div>
            <img style={{width: '1.6vw'}} role="button" tabIndex="-1" onClick={prev} onKeyDown={prev} className="AnimationAboutRefferenceCarousel__controll AnimationAboutRefferenceCarousel__controll--prev" src={config.paths.about + 'direction_left.png'} alt="Předchozí" />
            <img style={{width: '1.6vw'}} role="button" tabIndex="-1" onClick={next} onKeyDown={next} className="AnimationAboutRefferenceCarousel__controll AnimationAboutRefferenceCarousel__controll--next" src={config.paths.about + 'direction_right.png'} alt="Následující" />
        </div>
    );
}

export default AnimationAboutRefferenceCarousel;
