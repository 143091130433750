import * as React from "react";
import config from "../../../../config/config.json";

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selected: this.props.selected_section + "_link" };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    this.setState({ selected: e.currentTarget.id });
    this.props.onNavigationClick(e.currentTarget.id);
  }

  static getDerivedStateFromProps(props, current_state) {
    if (current_state.selected !== props.selected_section + "_link") {
      return {
        selected: props.selected_section + "_link"
      };
    }
    return null;
  }

    // componentDidMount() {
    //     window.addEventListener('scroll', this.handleScroll);
    // }
    //
    // componentWillUnmount() {
    //     window.removeEventListener('scroll', this.handleScroll);
    // }
    //
    // handleScroll(event) {
    //     let scrollTop = event.srcElement.body.scrollTop,
    //         itemTranslate = Math.min(0, scrollTop/3 - 60);
    //
    //     this.setState({
    //         transform: itemTranslate
    //     });
    // }


  render() {
    return (
      <div className="page-navigation" style={this.props.style}>
        <div
          className={
            "page-navigation-block " +
            (this.state.selected == "section_1_link" ? "selected" : "")
          }
          id="section_1_link"
          onClick={this.handleClick}
        >
          <div className="page-navigation-block-number">01</div>
          <div className="page-navigation-block-line" />
        </div>
        <div
          className={
            "page-navigation-block " +
            (this.state.selected == "section_2_link" ? "selected" : "")
          }
          id="section_2_link"
          onClick={this.handleClick}
        >
          <div className="page-navigation-block-number">02</div>
          <div className="page-navigation-block-line" />
        </div>
        <div
          className={
            "page-navigation-block " +
            (this.state.selected == "section_3_link" ? "selected" : "")
          }
          id="section_3_link"
          onClick={this.handleClick}
        >
          <div className="page-navigation-block-number">03</div>
          <div className="page-navigation-block-line" />
        </div>
        <div
          className={
            "page-navigation-block " +
            (this.state.selected == "section_4_link" ? "selected" : "")
          }
          id="section_4_link"
          onClick={this.handleClick}
        >
          <div className="page-navigation-block-number">04</div>
          <div className="page-navigation-block-line" />
        </div>
        <div
          className={
            "page-navigation-block " +
            (this.state.selected == "section_5_link" ? "selected" : "")
          }
          id="section_5_link"
          onClick={this.handleClick}
        >
          <div className="page-navigation-block-number">05</div>
          <div className="page-navigation-block-line" />
        </div>
        <div
          className={
            "page-navigation-block " +
            (this.state.selected == "section_6_link" ? "selected" : "")
          }
          id="section_6_link"
          onClick={this.handleClick}
        >
          <div className="page-navigation-block-number">06</div>
          <div className="page-navigation-block-line" />
        </div>
        <div
          className={
            "page-navigation-block " +
            (this.state.selected == "section_7_link" ? "selected" : "")
          }
          id="section_7_link"
          onClick={this.handleClick}
        >
          <div className="page-navigation-block-number">07</div>
          <div className="page-navigation-block-line" />
        </div>
      </div>
    );
  }
}

export { Navigation };
