import {useState, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';

function AnimationAboutSlider({render, count, initial})
{
    const [current, setCurrent] = useState(initial);

    const go = useCallback((index) => {
        setCurrent(index);
    }, [setCurrent]);

    return render({ current: current, prev: () => setCurrent((current - 1 + count) % count), next: () => setCurrent((current + 1) % count), go: go });
}

AnimationAboutSlider.propTypes = {
    render: PropTypes.func.isRequired,
    count: PropTypes.number.isRequired,
    initial: PropTypes.number,
};

AnimationAboutSlider.defaultProps = {
    initial: 0,
};

export default AnimationAboutSlider;
