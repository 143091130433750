import * as React from "react";
import ReactDOM from "react-dom";
import config from "../../../../config/config.json";
import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import { getReferences } from "../api.js";
import { getPageSEO } from "../functions";

import i18n from '../i18n';

class FaqItem extends React.Component {
  constructor(props) {
    super(props);

    this.pageSEO = getPageSEO();

    document.title = this.pageSEO.title;
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", this.pageSEO.keywords);
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", this.pageSEO.description);

    document.querySelector('meta[name=\"title\"]').setAttribute('content', this.pageSEO.metaTitle);

    this.toggleClass = this.toggleClass.bind(this);
    this.state = {
      active: false
    };
  }
  toggleClass() {
    const currentState = this.state.active;
    this.setState({ active: !currentState });
  }

  render() {
    return (
      <li
        className={"faq-list-item " + (this.state.active ? "active" : "")}
        onClick={this.toggleClass}
      >
        <div className="faq-item-line-cont">
          <div className="faq-line" />

          <img
            className="faq-icon"
            src={
              config.paths.icons +
              (this.state.active
                ? "ic_arrow_down_orange.png"
                : "ic_arrow_down_grey.png")
            }
            alt={i18n.t('general.open')}
          />
        </div>
        <div className="faq-otazka">{this.props.question}</div>
        <div className="faq-item-content">
          {this.props.text}
          <div className="faq-precist-vice">{i18n.t('general.readMore')}</div>
        </div>
      </li>
    );
  }
}

class Faq extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      detail: "",
      num: "1",
      from: "3",
      data: [],
      more_data: [],
      carousel: ""
    };

    this.loadMore = this.loadMore.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll, { passive: true });

    // getReferences.request(this.setData);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
    document.body.style.overflowY = "scroll";
  }

  loadMore() {
    this.setState({
      more_data: this.state.data.slice(9),
      load_more_option: { display: "none" }
    });
  }

  render() {
    return (
      <div className="main-container" id="main-container-faq">
        <div className="faq-container">
          <div className="faq-route">
            <b>Heated. &gt; </b>{i18n.t('faq.titleShort')}
          </div>
          <h1 className="faq-nadpis">
            <b>{i18n.t('faq.title')}</b>
          </h1>
          <div className="faq-list-cont">
            <ul className="faq-list">
              <FaqItem
                text="Lorem ipsom dolor qabat estu gratisuno poliatis, es nonus
                  partis di paratis? Lorem ipsom dolor qabat estu gratisuno
                  poliatis, es nonus partis di paratis? Lorem ipsom dolor qabat
                  estu gratisuno poliatis, es nonus partis di paratis?"
                question=" Lorem ipsom dolor qabat estu gratisuno poliatis, es nonus
                  partis di paratis?"
              />
              <FaqItem
                text="Lorem ipsom dolor qabat estu gratisuno poliatis, es nonus
                  partis di paratis? Lorem ipsom dolor qabat estu gratisuno
                  poliatis, es nonus partis di paratis? Lorem ipsom dolor qabat
                  estu gratisuno poliatis, es nonus partis di paratis?"
                question=" Lorem ipsom dolor qabat estu gratisuno poliatis, es nonus
                  partis di paratis?"
              />
              <FaqItem
                text="Lorem ipsom dolor qabat estu gratisuno poliatis, es nonus
                  partis di paratis? Lorem ipsom dolor qabat estu gratisuno
                  poliatis, es nonus partis di paratis? Lorem ipsom dolor qabat
                  estu gratisuno poliatis, es nonus partis di paratis?"
                question=" Lorem ipsom dolor qabat estu gratisuno poliatis, es nonus
                  partis di paratis?"
              />
              <FaqItem
                text="Lorem ipsom dolor qabat estu gratisuno poliatis, es nonus
                  partis di paratis? Lorem ipsom dolor qabat estu gratisuno
                  poliatis, es nonus partis di paratis? Lorem ipsom dolor qabat
                  estu gratisuno poliatis, es nonus partis di paratis?"
                question=" Lorem ipsom dolor qabat estu gratisuno poliatis, es nonus
                  partis di paratis?"
              />
              <FaqItem
                text="Lorem ipsom dolor qabat estu gratisuno poliatis, es nonus
                  partis di paratis? Lorem ipsom dolor qabat estu gratisuno
                  poliatis, es nonus partis di paratis? Lorem ipsom dolor qabat
                  estu gratisuno poliatis, es nonus partis di paratis?"
                question=" Lorem ipsom dolor qabat estu gratisuno poliatis, es nonus
                  partis di paratis?"
              />
              <FaqItem
                text="Lorem ipsom dolor qabat estu gratisuno poliatis, es nonus
                  partis di paratis? Lorem ipsom dolor qabat estu gratisuno
                  poliatis, es nonus partis di paratis? Lorem ipsom dolor qabat
                  estu gratisuno poliatis, es nonus partis di paratis?"
                question=" Lorem ipsom dolor qabat estu gratisuno poliatis, es nonus
                  partis di paratis?"
              />
              <FaqItem
                text="Lorem ipsom dolor qabat estu gratisuno poliatis, es nonus
                  partis di paratis? Lorem ipsom dolor qabat estu gratisuno
                  poliatis, es nonus partis di paratis? Lorem ipsom dolor qabat
                  estu gratisuno poliatis, es nonus partis di paratis?"
                question=" Lorem ipsom dolor qabat estu gratisuno poliatis, es nonus
                  partis di paratis?"
              />
            </ul>
          </div>

          <div className="faq-footer">
            <span dangerouslySetInnerHTML={{ __html: i18n.t('faq.noResult') }}></span>  
            <div className="faq-nacist-vice">
              <Link className="kontakty-link" to={config.pages.kontakty.url}>
                {i18n.t('contact.title')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Faq;
