import React from 'react';
import { getPageSEO } from '../functions';

import AnimationAboutHeader from '../page_components/AnimationAboutHeader';
import AnimationAboutSection1 from '../page_components/AnimationAboutSection1';
import AnimationAboutSection2 from '../page_components/AnimationAboutSection2';
import AnimationAboutSection3 from '../page_components/AnimationAboutSection3';
import AnimationAboutSection4 from '../page_components/AnimationAboutSection4';
import AnimationAboutSection5 from '../page_components/AnimationAboutSection5';
import AnimationAboutSection6 from '../page_components/AnimationAboutSection6';
import AnimationAboutSection7 from '../page_components/AnimationAboutSection7';
import AnimationAboutSection8 from '../page_components/AnimationAboutSection8';

function AnimationAbout() {
    document.title = getPageSEO().title;

    return (
        <main className="AnimationAbout">
            <AnimationAboutHeader />
            <AnimationAboutSection1 />
            <AnimationAboutSection2 />
            <AnimationAboutSection3 />
            <AnimationAboutSection4 />
            <AnimationAboutSection5 />
            <AnimationAboutSection6 />
            <AnimationAboutSection7 />
            <AnimationAboutSection8 />
        </main>
    );
}

export default AnimationAbout;
