import React from 'react';

import config from '../../../../config/config.json';

function AnimationAboutSimpleCarousel({animated, current, next, prev, children})
{
    return (
        <div className="AnimationAboutSimpleCarousel">
            <div className={[
                    'AnimationAboutSimpleCarousel__slides',
                ].filter((el) => el).join(' ')}>
                {children.map((slide, index) => {
                    return (
                        <div key={index} style={{transform: `translateX(-${current * 100}%)`}} className={[
                                "AnimationAboutSimpleCarousel__slides__slide",
                                animated && "AnimationAboutSimpleCarousel__slides__slide--animated",
                        ].filter((el) => el).join(' ')}>
                            {slide}
                        </div>
                    );
                })}
            </div>
            <div className="AnimationAboutSimpleCarousel__controls">
                <img src={config.paths.about + 'direction_left.png'} alt="Slide" role="button" tabIndex="-1" onClick={prev} onKeyDown={prev} className="AnimationAboutSimpleCarousel__controls__control" />
                {children.map((_, index) => {
                    return (
                        <img key={index} src={config.paths.about + 'dot.svg'} alt="Slide" className={[
                            'AnimationAboutSimpleCarousel__dot',
                            current === index && 'AnimationAboutSimpleCarousel__dot--active',
                        ].filter((el) => el).join(' ')} />
                    );
                })}
                <img src={config.paths.about + 'direction_right.png'} alt="Slide" role="button" tabIndex="-1" onClick={next} onKeyDown={next} className="AnimationAboutSimpleCarousel__controls__control" />
            </div>
        </div>
    );
}

export default AnimationAboutSimpleCarousel;
