import React, {useCallback} from 'react';

import AnimationAboutNumber from './AnimationAboutNumber';
import AnimationAboutSheet from './AnimationAboutSheet';
import AnimationAboutContainer from './AnimationAboutContainer';
import AnimationAboutHeaderSplit from './AnimationAboutHeaderSplit';
import AnimationAboutHeaderTitle from './AnimationAboutHeaderTitle';
import AnimationAboutHeaderText from './AnimationAboutHeaderText';
import AnimationAboutTile from './AnimationAboutTile';
import AnimationAboutSheetBackground from './AnimationAboutSheetBackground';
import AnimationAboutSlider from './AnimationAboutSlider';
import AnimationAboutSimpleCarousel from './AnimationAboutSimpleCarousel';
import AnimationAboutTabs from './AnimationAboutTabs';

import config from '../../../../config/config.json';

import i18n from '../i18n';

function AnimationAboutSection4()
{
    const scrollDown = useCallback(() => {
        const el = document.querySelector('.AnimationAboutSection5');

        if (el) {
            el.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
        }
    }, []);

    return (
        <section className="AnimationAboutSection AnimationAboutSection4">
            <AnimationAboutNumber>04</AnimationAboutNumber>
            <AnimationAboutSheet kartoteka="tmava" theme="dark">
                <AnimationAboutContainer width="header" >
                    <div>
                        <AnimationAboutHeaderTitle>
                            {i18n.t('about.section04.title')}
                        </AnimationAboutHeaderTitle>
                        <AnimationAboutHeaderText>
                            <span className="AnimationAbout--a1" dangerouslySetInnerHTML={{ __html: i18n.t('about.section04.text') }}></span>
                        </AnimationAboutHeaderText>
                    </div>
                </AnimationAboutContainer>
            </AnimationAboutSheet>
            <AnimationAboutSheet kartoteka="bila" kartotekaPushed theme="light">
                <AnimationAboutContainer>
                    <AnimationAboutTile>
                        <div className="AnimationAboutTile__text-content AnimationAbout--69">
                            <h4 className="AnimationAboutTile__title AnimationAbout--black">{i18n.t('about.section04.subsection01.title')}</h4>
                            <div className="AnimationAbout--line-medium">
                                {i18n.t('about.section04.subsection01.text')}
                            </div>
                            <div>
                                <img style={{width: '2.23958vw'}} className="AnimationAbout__direction" role="button" tabIndex="-1" onClick={scrollDown} onKeyDown={scrollDown} src={config.paths.about + 'direction_down--dark.png'} alt="Sjěďte dolů" />
                            </div>
                        </div>
                        <div>
                            <img src={config.paths.about + 'podlaha_vsrtvy_mala.png'} alt="Bezúdržbové topení" />
                        </div>
                    </AnimationAboutTile>
                </AnimationAboutContainer>
            </AnimationAboutSheet>
            <AnimationAboutSheet kartoteka="svetla" collapsible title={<span className="AnimationAbout--black">{i18n.t('about.section04.subsection02.title')} <span className="AnimationAbout--83">({i18n.t('about.section04.subsection02.titleNote')})</span></span>} theme="gray--light">
                <AnimationAboutContainer>
                    <AnimationAboutSlider count={3} render={({current, prev, next, go}) => {
                        return (
                            <div>
                                <AnimationAboutTabs current={current} go={go} theme="light" options={i18n.t('about.section04.subsection02.options', { returnObjects: true })} />
                                <AnimationAboutTile single>
                                    <div className="AnimationAbout--line-medium AnimationAbout--83">
                                        {current === 0 && (
                                            <div>
                                                {i18n.t('about.section04.subsection02.box1.text')}
                                            </div>
                                        )}
                                        {current === 1 && (
                                            <div>
                                                {i18n.t('about.section04.subsection02.box2.text')}
                                            </div>
                                        )}
                                        {current === 2 && (
                                            <div>
                                                {i18n.t('about.section04.subsection02.box3.text')}
                                            </div>
                                        )}
                                    </div>
                                </AnimationAboutTile>
                                <div className="AnimationAboutSection4__graph">
                                    <div>
                                        {current === 1 && (
                                            <div className="AnimationAboutSection4__graph__title">
                                                {current === 0 && (i18n.t('about.section04.subsection02.box1.column1.title'))}
                                                {current === 1 && (i18n.t('about.section04.subsection02.box2.column1.title'))}
                                                {current === 2 && (i18n.t('about.section04.subsection02.box3.column1.title'))}
                                            </div>
                                        )}
                                        <div className="AnimationAboutSection4__graph__content">
                                            <div style={{fontSize: '0.80729vw'}}>{i18n.t('about.section04.subsection02.label')}</div>
                                            <div style={{marginTop: '1.35416vw', fontSize: '3.64583vw'}}>
                                                {current === 0 && (i18n.t('about.section04.subsection02.box1.column1.value'))}
                                                {current === 1 && (i18n.t('about.section04.subsection02.box2.column1.value'))}
                                                {current === 2 && (i18n.t('about.section04.subsection02.box3.column1.value'))}
                                            </div>
                                            <div style={{marginTop: '3.09895vw', fontSize: '0.80729vw', lineHeight: '1.5', maxWidth: '24em'}}>
                                                {current === 0 && (i18n.t('about.section04.subsection02.box1.column1.note'))}
                                                {current === 1 && (i18n.t('about.section04.subsection02.box2.column1.note'))}
                                                {current === 2 && (i18n.t('about.section04.subsection02.box3.column1.note'))}
                                            </div>
                                            <div style={{marginTop: '2.52604vw', fontSize: '1.82291vw'}}>
                                                {current === 0 && (i18n.t('about.section04.subsection02.box1.column1.subtitle'))}
                                                {current === 1 && (i18n.t('about.section04.subsection02.box2.column1.subtitle'))}
                                                {current === 2 && (i18n.t('about.section04.subsection02.box3.column1.subtitle'))}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        {current === 1 && (
                                            <div className="AnimationAboutSection4__graph__title">
                                                {current === 0 && (i18n.t('about.section04.subsection02.box1.column2.title'))}
                                                {current === 1 && (i18n.t('about.section04.subsection02.box2.column2.title'))}
                                                {current === 2 && (i18n.t('about.section04.subsection02.box3.column2.title'))}
                                            </div>
                                        )}
                                        <div className="AnimationAboutSection4__graph__content">
                                            <div style={{fontSize: '0.80729vw'}}>
                                                {/* {current === 0 && (i18n.t('about.section04.subsection02.box1.column2.label'))} */}
                                                {current === 1 && (i18n.t('about.section04.subsection02.box2.column2.label'))}
                                                {current === 2 && (i18n.t('about.section04.subsection02.box3.column2.label'))}
                                            </div>
                                            <div style={{marginTop: '1.35416vw', fontSize: '3.64583vw'}}>
                                                {/* {current === 0 && (i18n.t('about.section04.subsection02.box1.column2.value'))} */}
                                                {current === 1 && (i18n.t('about.section04.subsection02.box2.column2.value'))}
                                                {current === 2 && (i18n.t('about.section04.subsection02.box3.column2.value'))}
                                            </div>
                                            <div style={{marginTop: '3.09895vw', fontSize: '0.80729vw', lineHeight: '1.5', maxWidth: '24em'}}>
                                                {/* {current === 0 && (i18n.t('about.section04.subsection02.box1.column2.note'))} */}
                                                {current === 1 && (i18n.t('about.section04.subsection02.box2.column2.note'))}
                                                {current === 2 && (i18n.t('about.section04.subsection02.box3.column2.note'))}
                                            </div>
                                            <div style={{marginTop: '2.52604vw', fontSize: '1.82291vw'}}>
                                                {/* {current === 0 && (i18n.t('about.section04.subsection02.box1.column2.subtitle'))} */}
                                                {current === 1 && (i18n.t('about.section04.subsection02.box2.column2.subtitle'))}
                                                {current === 2 && (i18n.t('about.section04.subsection02.box3.column2.subtitle'))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    }} />
                </AnimationAboutContainer>
            </AnimationAboutSheet>
            <AnimationAboutSheet kartoteka="bila" noPaddingBottom theme="light">
                <AnimationAboutContainer>
                    <AnimationAboutTile reversed>
                        <div>
                            <img src={config.paths.about + 'technicka_mistnost.png'} alt="Technická místnost" />
                        </div>
                        <div className="AnimationAboutTile__text-content AnimationAbout--69">
                            <h4 className="AnimationAboutTile__title AnimationAbout--black">{i18n.t('about.section04.subsection03.title')}</h4>
                            <div className="AnimationAbout--line-medium" dangerouslySetInnerHTML={{ __html: i18n.t('about.section04.subsection03.text') }}></div>
                        </div>
                    </AnimationAboutTile>
                </AnimationAboutContainer>
                <AnimationAboutSheetBackground image={config.paths.about + 'zahrada.png'} />
            </AnimationAboutSheet>
        </section>
    );
}

export default AnimationAboutSection4;
