import * as React from "react";
import * as ReactDOM from "react-dom";
import CircularSlider from "react-circular-slider-bar";
// import CircularSlider from "../circularSlider.js";
// import { CircleSlider } from "react-circle-slider";
import config from "../../../../config/config.json";

class CircleSlider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: 0,
      value: 0,
      value_to_share: 19,
      angle: 90
    };
  }

  componentDidMount() {
    const component = ReactDOM.findDOMNode(this);

    this.setState({
      width: component.clientWidth
    });
    if (this.props.type == "temp") {
      this.setState({
        angle: 270
      });
    }
    if (this.props.value) {
      this.setState({ value: this.props.value });
    }
  }

  render() {
    // return (
    //   <CircularSlider
    //     r={this.state.width / 2}
    //     //   value={this.state.value}
    //     onChange={value => this.setState({ value })}
    //   />
    // );
    return (
      <div className="slider-wrapper">
        <img
          src={config.paths.icons + "ic_circle_indicator.png"}
          alt=""
          className={"direction-indicator angle" + this.state.angle}
        />
        <CircularSlider
          r={this.state.width / 2}
          initialAngle={this.state.angle}
          //   value={this.state.value}
          onChange={value => {
            this.setState({
              value: value,
              value_to_share: Math.floor((this.state.value / 100) * 9) + 19
            });
            if (this.props.type == "temp") {
              this.props.onValueChange(
                Math.floor((this.state.value / 100) * 9) + 19
              );
            }
            if (this.props.type == "time") {
              this.props.onValueChange(Math.floor(this.state.value));
            }
          }}
        />
        {/* <CircleSlider
          size={180}
          stepSize={1}
          // initialAngle={this.state.angle}
          knobRadius={15}
          value={this.state.value}
          circleWidth={25}
          knobColor="#fff"
          progressWidth={10}
          circleColor="transparent"
          gradientColorFrom="#f26527"
          gradientColorTo="#f99e36"
          //   value={this.state.value}
          onChange={value => {
            this.setState({
              value: value,
              value_to_share: Math.floor((this.state.value / 100) * 14) + 17
            });
            if (this.props.type == "temp") {
              this.props.onValueChange(
                Math.floor((this.state.value / 100) * 14) + 17
              );
            }
          }}
        /> */}

        {this.props.type == "temp" ? (
          <div className="temperature">
            {Math.floor((this.state.value / 100) * 9) + 19}
            <span className="celsius">°C</span>
          </div>
        ) : (
          <div className="time">
            {Math.floor((this.state.value / 100) * 12) + 12}
            <span className="minutes">:00</span>
          </div>
        )}
      </div>
    );
  }
}

export { CircleSlider };
