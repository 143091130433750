import * as React from "react";
import config from "../../../../config/config.json";
import { Menu } from "../components/Menu";
import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import styles from "react-responsive-carousel/lib/styles/carousel.min.css";
import { getPageSEO } from "../functions";

import i18n from '../i18n';

class VzorovyDum extends React.Component {
  constructor(props) {
    super(props);

    this.pageSEO = getPageSEO();

    document.title = this.pageSEO.title;
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", this.pageSEO.keywords);
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", this.pageSEO.description);

    document.querySelector('meta[name=\"title\"]').setAttribute('content', this.pageSEO.metaTitle);

    this.state = {
      detail: "",
      gallery: "",
      video: "",
      list: [
        "https://www.tvbydleni.cz/i/nastupce-tepelnych-cerpadel-porovnani-tepelnych-cerpadel-a-celoplosnych-topnych-folii/",
        "https://www.tvbydleni.cz/i/inteligentni-domacnost-neni-jen-o-ovladani-z-telefonu/",
        "https://www.tvbydleni.cz/i/podlahove-topeni-vyhnete-se-problemum-pri-realizaci/",
        "https://www.tvbydleni.cz/i/jake-vytapeni-zvolit-pro-novostavbu/"
      ]
    };
    this.closeGallery = this.closeGallery.bind(this);
    this.openGallery = this.openGallery.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll, { passive: true });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  }

  closeGallery() {
    this.setState({ gallery: "" });
    document.body.style.overflowY = "scroll";
  }

  openGallery(e) {
    this.setState({ gallery: "open", video: e.target.dataset.number });
    document.body.style.overflowY = "hidden";
  }

  render() {
    return (
      <div className="main-container" id="main-container-studie">
        <div className="studie-header-section studie-section">
          <div className="studie-content-wrapper">
            <div className="studie-route">
              <b>Heated. &gt; </b>{i18n.t('modelHouse.title')}
            </div>
            <h1>
              {i18n.t('modelHouse.headerTitle')}
            </h1>
            <img
              className="studie-header-parameters"
              src={config.paths.images + "img_studie_header_parameters_" + i18n.language + ".svg"}
              alt={i18n.t('general.close')}
            />
            <h1 className="studie-main-heading" dangerouslySetInnerHTML={{ __html: i18n.t('modelHouse.headerSubtitle') }}></h1>
            <div className="studie-block">
              <div className="studie-number-block">
                <div className="studie-line" />
                &nbsp;&nbsp;01
              </div>
              <span className="studie-block-text">{i18n.t('modelHouse.section01.title')}</span>
            </div>
          </div>
        </div>
        <div className="studie-druha-section studie-section">
          {/* <img
            className="studie-svg"
            src={config.paths.images + "img_studie_wave1.svg"}
            alt=""
          /> */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 2822.72 328.39"
            className="studie-svg"
          >
            {/* <style type="text/css">
        rect{fill:url(#MyGradient)}
      </style> */}
            {/* <defs>
              <linearGradient
                id="MyGradient"
                x1="100%"
                y1="100%"
                x2="0%"
                y2="0%"
              >
                <stop offset="0%" stopColor="#df5a04" />
                <stop offset="100%" stopColor="#eb6608" />
              </linearGradient>
            </defs> */}
            <g id="Vrstva_2" data-name="Vrstva 2">
              <path
                d="M2822.72,0H1093.16l-26.85,1.39L1036,2.7l-17,1-28,2.61L968.88,9.23,949.8,11.58,926.86,15l-24.92,3.4L882.2,22l-18.07,3.15-33.7,7.57-34.71,8.88L757.57,51.81,697.75,70.62,657.06,86l-21.71,8.11-31.61,13.32-51.94,24-64.16,31.38-82.93,39L284.25,256.63l-73.41,25L107.63,309.77,1.28,325.41a1.5,1.5,0,0,0,.22,3H2822.72a1.5,1.5,0,0,0,1.5-1.5V1.5A1.5,1.5,0,0,0,2822.72,0Z"
                id="Vrstva_1-2"
                data-name="Vrstva 1"
              />
            </g>
          </svg>
          {/* <div className="section-druha-overlay" /> */}
          {/* <img
            className="studie-druha-bg1"
            src={config.paths.backgrounds + "img_studie_21.png"}
            alt=""
          />
          <img
            className="studie-druha-bg2"
            src={config.paths.backgrounds + "img_studie_22.png"}
            alt=""
          /> */}
          <div className="studie-content-wrapper">
            <div className="studie-druha-quote" dangerouslySetInnerHTML={{ __html: i18n.t('modelHouse.section01.text') }}>
            </div>
            <div className="studie-druha-blocks">
              <div className="studie-druha-block">
                <div className="studie-druha-block-number">01</div>
                <div className="studie-druha-block-text">
                  {i18n.t('modelHouse.section01.item01')}
                </div>
              </div>
              <div className="studie-druha-block">
                <div className="studie-druha-block-number">02</div>
                <div className="studie-druha-block-text">
                  {i18n.t('modelHouse.section01.item02')}
                </div>
              </div>
              <div className="studie-druha-block">
                <div className="studie-druha-block-number">03</div>
                <div className="studie-druha-block-text">
                  {i18n.t('modelHouse.section01.item03')}
                </div>
              </div>
              <div className="studie-druha-block">
                <div className="studie-druha-block-number">04</div>
                <div className="studie-druha-block-text">
                  {i18n.t('modelHouse.section01.item04')}
                </div>
              </div>
              <div className="studie-druha-block">
                <div className="studie-druha-block-number">05</div>
                <div className="studie-druha-block-text">
                  {i18n.t('modelHouse.section01.item05')}
                </div>
              </div>
              <div className="studie-druha-block">
                <div className="studie-druha-block-number">06</div>
                <div className="studie-druha-block-text">
                  {i18n.t('modelHouse.section01.item06')}
                </div>
              </div>
              <div className="studie-druha-block">
                <div className="studie-druha-block-number">07</div>
                <div className="studie-druha-block-text">
                  {i18n.t('modelHouse.section01.item07')}
                </div>
              </div>
              <div className="studie-druha-block">
                <div className="studie-druha-block-number">08</div>
                <div className="studie-druha-block-text">
                  {i18n.t('modelHouse.section01.item08')}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="studie-treti-section studie-section">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 2418.88 284.41"
            className="studie-svg"
            preserveAspectRatio="none"
            width="100%"
          >
            <g id="Vrstva_2" data-name="Vrstva 2">
              <polygon
                points="0 0 485.04 0 1209.44 0 1418.43 0 1447.14 0 1479.29 2.86 1544.02 11.48 1602.48 22.64 1685.69 44.21 1716.9 54.32 1771.36 74.11 1835.27 102.27 1938.25 151.32 2061.99 208.41 2109.82 226.32 2206.26 254.75 2259.25 266.23 2323.95 276.7 2341.91 279.48 2362.08 281.59 2418.88 284.41 0 284.41 0 0"
                id="Vrstva_1-2"
                data-name="Vrstva 1"
              />
            </g>
          </svg>
          <div className="studie-content-wrapper">
            <div className="studie-treti-heading">
              <div className="studie-block">
                <div className="studie-number-block">
                  <div className="studie-line" />
                  &nbsp;&nbsp;02
                </div>
              </div>
              <h1 dangerouslySetInnerHTML={{ __html: i18n.t('modelHouse.section02.title') }}>
              </h1>
            </div>
            <img
              className="studie-treti-bg"
              src={config.paths.images + "img_studie_section3_" + i18n.language + ".png"}
              alt=""
            />
            <div className="studie-treti-right">
              <img
                className="studie-treti-logo"
                src={config.paths.brands + "logo_heatflow_white.png"}
                alt=""
              />
              <div className="studie-treti-text" dangerouslySetInnerHTML={{ __html: i18n.t('modelHouse.section02.text') }}></div>
            </div>
          </div>
        </div>
        <div className="studie-ctvrta-section studie-section">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 2479.22 284.09"
            className="studie-svg"
          >
            <g id="Vrstva_2" data-name="Vrstva 2">
              <polygon
                points="2479.22 0.78 2479.22 284.09 49.3 283.56 10.96 284.09 0 284.09 47.48 280.44 72 278.35 110.87 273.39 164.87 263.48 232.44 246 271.56 233.48 310.96 219.91 338.87 209.22 396.52 183.65 447.65 159.39 508.7 130.44 557.22 107.22 604.17 85.56 630.26 74.87 644.87 69.39 668.61 60.78 697.04 51.39 723.65 43.04 741.39 38.09 765.13 31.57 798 23.74 815.22 20.09 851.48 14.35 876.78 10.44 902.61 7.57 917.48 6.26 946.43 3.65 1000.7 1.04 1124.35 0.26 1225.83 0 1388.61 0 1507.57 0.78 2479.22 0.78"
                id="Vrstva_2-2"
                data-name="Vrstva 2"
              />
            </g>
          </svg>
          <div className="studie-content-wrapper">
            <div className="studie-ctvrta-center">
              <div className="studie-ctvrta-vs">VS</div>
              <div className="studie-ctvrta-porovnani">
                <Link to={config.pages.porovnani_vytapeni.url}>
                  {i18n.t('general.compareHeat')}
                </Link>
              </div>
            </div>
            <img
              className="studie-ctvrta-bg"
              src={config.paths.images + "img_studie_section4_" + i18n.language + ".png"}
              alt=""
            />
            <div className="studie-ctvrta-left">
              <div className="studie-ctvrta-legenda">
                {/* <img
                  className="studie-ctvrta-dot"
                  src={config.paths.icons + "ic_red_dot.png"}
                  alt=""
                />
                 */}
                <span className="studie-ctvrta-dot" />
                <h3>{ i18n.t('comparison.title02') }</h3>
              </div>
              <div className="studie-ctvrta-text" dangerouslySetInnerHTML={{ __html: i18n.t('modelHouse.section02.text2') }}></div>
            </div>
          </div>
        </div>
        <div className="studie-pata-section studie-section">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 2418.88 284.41"
            className="studie-svg"
            preserveAspectRatio="none"
            width="100%"
          >
            <g id="Vrstva_2" data-name="Vrstva 2">
              <polygon
                points="0 0 485.04 0 1209.44 0 1418.43 0 1447.14 0 1479.29 2.86 1544.02 11.48 1602.48 22.64 1685.69 44.21 1716.9 54.32 1771.36 74.11 1835.27 102.27 1938.25 151.32 2061.99 208.41 2109.82 226.32 2206.26 254.75 2259.25 266.23 2323.95 276.7 2341.91 279.48 2362.08 281.59 2418.88 284.41 0 284.41 0 0"
                id="Vrstva_1-2"
                data-name="Vrstva 1"
              />
            </g>
          </svg>
          <div className="studie-content-wrapper">
            <div className="studie-pata-heading">
              <div className="studie-block">
                <div className="studie-number-block">
                  <div className="studie-line" />
                  &nbsp;&nbsp;03
                </div>
              </div>
              <h1 dangerouslySetInnerHTML={{ __html: i18n.t('modelHouse.section03.title') }}>
              </h1>
            </div>
            <img
              className="studie-pata-bg"
              src={config.paths.images + "img_studie_section5_" + i18n.language + ".png"}
              alt=""
            />
            <div className="studie-pata-popis">
              <div className="studie-pata-text" dangerouslySetInnerHTML={{ __html: i18n.t('modelHouse.section03.text') }}></div>
              <div className="studie-pata-hvezdicka">
                * {i18n.t('modelHouse.section03.note')}
              </div>
            </div>
          </div>
        </div>
        <div className="studie-sesta-section studie-section">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1784.97 284.41"
            className="studie-svg"
          >
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <polygon
                  id="vlna7"
                  points="0 0 0 0 575.53 0 784.52 0 813.23 0 845.38 2.86 910.11 11.48 968.57 22.64 1051.78 44.21 1082.99 54.32 1137.45 74.11 1201.36 102.27 1304.34 151.32 1428.08 208.41 1475.91 226.32 1572.35 254.75 1625.33 266.23 1690.03 276.7 1708 279.48 1728.17 281.59 1784.97 284.41 0 284.41 0 0"
                />
              </g>
            </g>
          </svg>
          <div className="studie-content-wrapper">
            <div className="studie-sesta-heading">
              <div className="studie-block">
                <div className="studie-number-block">
                  <div className="studie-line" />
                  &nbsp;&nbsp;04
                </div>
              </div>
              <h1>
              {i18n.t('modelHouse.section04.title')}
              </h1>
              <span className="studie-sesta-dovetek">
                {i18n.t('modelHouse.section04.subtitle')}
              </span>
            </div>
            <div className="studie-sesta-middle">
              <div className="studie-block">
                <div className="studie-number-block">
                  <div className="studie-line" />
                </div>
              </div>
              <h2>{i18n.t('modelHouse.section04.title2')}</h2>

              <span className="studie-sesta-popis" dangerouslySetInnerHTML={{ __html: i18n.t('modelHouse.section04.text') }}></span>
            </div>
            <img
              className="studie-sesta-bg"
              src={config.paths.images + "img_studie_section6.png"}
              alt=""
            />
            <div className="studie-sesta-center">
              <div className="studie-sesta-porovnani">
                <Link to={config.pages.konfigurator.url}>
                  {i18n.t('modelHouse.section04.button')}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="studie-sedma-section studie-section">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 2479.22 284.09"
            className="studie-svg"
          >
            <g id="Vrstva_2" data-name="Vrstva 2">
              <polygon
                points="2479.22 0.78 2479.22 284.09 49.3 283.56 10.96 284.09 0 284.09 47.48 280.44 72 278.35 110.87 273.39 164.87 263.48 232.44 246 271.56 233.48 310.96 219.91 338.87 209.22 396.52 183.65 447.65 159.39 508.7 130.44 557.22 107.22 604.17 85.56 630.26 74.87 644.87 69.39 668.61 60.78 697.04 51.39 723.65 43.04 741.39 38.09 765.13 31.57 798 23.74 815.22 20.09 851.48 14.35 876.78 10.44 902.61 7.57 917.48 6.26 946.43 3.65 1000.7 1.04 1124.35 0.26 1225.83 0 1388.61 0 1507.57 0.78 2479.22 0.78"
                id="Vrstva_2-2"
                data-name="Vrstva 2"
              />
            </g>
          </svg>
          <img
            className="studie-sedma-bg"
            src={config.paths.images + "img_studie_section7bg.png"}
            alt=""
          />
          <div className="studie-content-wrapper">
            <div className="studie-sedma-heading">
              <h1>{i18n.t('modelHouse.section05.title')}</h1>
              <div className="studie-block">
                <div className="studie-number-block">
                  <div className="studie-line" />
                </div>
              </div>
            </div>

            <div className="studie-sedma-main">
              <div className="studie-sedma-left">
                <img
                  className="studie-sedma-logo"
                  src={config.paths.brands + "logo_loxone_black.png"}
                  alt=""
                />
                <div className="studie-sedma-left-text" dangerouslySetInnerHTML={{ __html: i18n.t('modelHouse.section05.text') }}></div>
              </div>
              <img
                className="studie-sedma-img"
                src={config.paths.images + "img_studie_section7_" + i18n.language + ".jpg"}
                alt=""
              />
            </div>
            <div className="studie-sedma-center">
              <div className="studie-sedma-porovnani">
                <a href="http://lxn.cz">lxn.cz</a>
              </div>
            </div>
          </div>
        </div>
        <div className="studie-osma-section studie-section">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1784.97 284.41"
            className="studie-svg"
            width="100%"
            height="100%"
            preserveAspectRatio="none"
          >
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <polygon
                  id="vlna7"
                  points="0 0 0 0 575.53 0 784.52 0 813.23 0 845.38 2.86 910.11 11.48 968.57 22.64 1051.78 44.21 1082.99 54.32 1137.45 74.11 1201.36 102.27 1304.34 151.32 1428.08 208.41 1475.91 226.32 1572.35 254.75 1625.33 266.23 1690.03 276.7 1708 279.48 1728.17 281.59 1784.97 284.41 0 284.41 0 0"
                />
              </g>
            </g>
          </svg>
          <img
            className="studie-osma-bg"
            src={config.paths.images + "img_studie_section8.png"}
            alt=""
          />
          <div className="studie-content-wrapper">
            <div className="studie-osma-heading">
              <div className="studie-block">
                <div className="studie-number-block">
                  <div className="studie-line" />
                </div>
              </div>
              <h1>{i18n.t('modelHouse.section06.title')}</h1>
            </div>

            <div className="studie-osma-main">
              <div className="studie-osma-middle">
                <img
                  className="studie-osma-info"
                  src={config.paths.images + "img_studie_section8info_" + i18n.language + ".png"}
                  alt=""
                />
                <div className="studie-osma-text" dangerouslySetInnerHTML={{ __html: i18n.t('modelHouse.section06.text') }}></div>
                <img
                  className="studie-osma-logo"
                  src={config.paths.brands + "logo_MCore_blue.png"}
                  alt=""
                />
              </div>
            </div>
            <img
              className="studie-osma-dials"
              src={config.paths.images + "img_studie_section8dials_" + i18n.language + ".png"}
              alt=""
            />
            <div className="studie-osma-center">
              <div className="studie-osma-porovnani">
                <a href="http://mcore.cz">mcore.cz</a>
              </div>
            </div>
          </div>
        </div>
        <div className="studie-devata-section studie-section">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 2479.22 284.09"
            className="studie-svg"
            width="100%"
            height="100%"
            preserveAspectRatio="none"
          >
            <g id="Vrstva_2" data-name="Vrstva 2">
              <polygon
                points="2479.22 0.78 2479.22 284.09 49.3 283.56 10.96 284.09 0 284.09 47.48 280.44 72 278.35 110.87 273.39 164.87 263.48 232.44 246 271.56 233.48 310.96 219.91 338.87 209.22 396.52 183.65 447.65 159.39 508.7 130.44 557.22 107.22 604.17 85.56 630.26 74.87 644.87 69.39 668.61 60.78 697.04 51.39 723.65 43.04 741.39 38.09 765.13 31.57 798 23.74 815.22 20.09 851.48 14.35 876.78 10.44 902.61 7.57 917.48 6.26 946.43 3.65 1000.7 1.04 1124.35 0.26 1225.83 0 1388.61 0 1507.57 0.78 2479.22 0.78"
                id="Vrstva_2-2"
                data-name="Vrstva 2"
              />
            </g>
          </svg>
          <div className="studie-content-wrapper">
            <div className="studie-devata-heading">
              <div className="studie-block">
                <div className="studie-number-block">
                  <div className="studie-line" />
                  &nbsp;&nbsp;05
                </div>
              </div>
              <h1>
              {i18n.t('modelHouse.section07.title')}
              </h1>
            </div>
            <div className="studie-devata-carousel">
              <Carousel>
                <div onClick={this.openGallery} data-number="1">
                  <img
                    className="studie-gallery-video"
                    src={config.paths.images + "img_studie_section9.png"}
                    alt=""
                  />

                  <p className="legend">
                  {i18n.t('modelHouse.section07.item1')}
                  </p>
                </div>
                <div onClick={this.openGallery} data-number="2">
                  <img
                    className="studie-gallery-video"
                    src={config.paths.images + "img_studie_section9.png"}
                    alt=""
                  />
                  <p className="legend">
                  {i18n.t('modelHouse.section07.item2')}
                  </p>
                </div>
                <div onClick={this.openGallery} data-number="3">
                  <img
                    className="studie-gallery-video"
                    src={config.paths.images + "img_studie_section9.png"}
                    alt=""
                  />
                  <p className="legend">
                  {i18n.t('modelHouse.section07.item3')}
                  </p>
                </div>
                <div onClick={this.openGallery} data-number="4">
                  <img
                    className="studie-gallery-video"
                    src={config.paths.images + "img_studie_section9.png"}
                    alt=""
                  />
                  <p className="legend">
                  {i18n.t('modelHouse.section07.item4')}
                  </p>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
        <div className="studie-last-section studie-section">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1442.26 284.41"
            className="studie-svg"
          >
            <g id="Vrstva_2" data-name="Vrstva 2">
              <g id="Vrstva_1-2" data-name="Vrstva 1">
                <polygon
                  id="vlna7"
                  points="1442.26 0 1442.26 0 1209.44 0 1000.45 0 971.74 0 939.59 2.86 874.86 11.48 816.4 22.64 733.19 44.21 701.98 54.32 647.52 74.11 583.61 102.27 480.63 151.32 356.89 208.41 309.06 226.32 212.62 254.75 159.63 266.23 94.94 276.7 76.97 279.48 56.8 281.59 0 284.41 1442.26 284.41 1442.26 0"
                />
              </g>
            </g>
          </svg>
          <div className="studie-content-wrapper">
            <div className="studie-last-heading">
              <div className="studie-block">
                <div className="studie-number-block">
                  <div className="studie-line" />
                  &nbsp;&nbsp;06
                </div>
              </div>
              <h1>
                <b>{i18n.t('modelHouse.freeCalculation')}</b>
              </h1>
            </div>
            <div className="studie-last-center">
              <div className="studie-last-porovnani">
                <Link to={config.pages.cenova_nabidka.url}>{i18n.t('priceOffer.title')}</Link>
              </div>
            </div>
          </div>
        </div>
        <div className={"studie-gallery-modal " + this.state.gallery}>
          <div className="studie-gallery-container">
            <div className="gallery-video">
              <iframe
                width="640"
                height="360"
                src={
                  this.state.gallery == ""
                    ? ""
                    : this.state.list[this.state.video - 1]
                }
                frameBorder="0"
              />
              {/* {this.state.list[this.state.video - 1]} */}
              <div className="gallery-close" onClick={this.closeGallery}>
                <img
                  className="studie-gallery-image"
                  src={config.paths.icons + "ic_plus_active.png"}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default VzorovyDum;
