import * as React from "react";
import ReactDOM from "react-dom";
import config from "../../../../config/config.json";
import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import { getConfig, indexReferences } from "../api.js";
import { getPageSEO } from "../functions";
import TotalCount from "../components/TotalCount";
import LoadingData from "../components/LoadingData";

import i18n from '../i18n';

const BUIDLING_TYPES = {
  'drevo': 'Dřevostavba',
  'kamen': 'Zděný dům',
  'rekonstrukce': 'Rekonstrukce'
};

class RodinneDomy extends React.Component {
  constructor(props) {
    super(props);

    this.pageSEO = getPageSEO();

    document.title = this.pageSEO.title;
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", this.pageSEO.keywords);
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", this.pageSEO.description);

    document.querySelector('meta[name=\"title\"]').setAttribute('content', this.pageSEO.metaTitle);

    this.state = {
      selected: null,
      detail: "",
      num: "1",
      from: "3",
      year: "",
      name: "",
      data: [],
      carousel: "",
      next_link: undefined,
      config_area: 0,
      config_buildings: 0,
      page: 1,
      loaded: false,
      building_type: '',
      floor_area: '',
      expenses_per_month: '',
      clear_on_get: true,
      total_count: undefined,
    };

    this.setData = this.setData.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.closeDetail = this.closeDetail.bind(this);
    this.openDetail = this.openDetail.bind(this);
    this.onChangeOf = this.onChangeOf.bind(this);
    this.setConfig = this.setConfig.bind(this);
    this.modalClicked = this.modalClicked.bind(this);
    this.imageLoaded = this.imageLoaded.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      clear_on_get: true,
      data: [],
      next_link: undefined,
      [name]: value
    }, this.loadMore);
  }

  componentDidMount() {
    this.loadMore();
    getConfig.request(this.setConfig);
  }

  componentWillUnmount() {
    document.body.style.overflowY = "scroll";

    if (indexReferences.running) {
      indexReferences.cancel();
    }
  }

  setConfig(response) {
    this.setState({
      config_area: response.data.area_sum,
      config_buildings: response.data.building_sum
    });
  }

  setData(response) {
    const rodinne_domy = response.data;

    if (!response.links.next) {
      this.setState({
        load_more_option: {
          display: "none"
        }
      });
    } else {
      this.setState({
        load_more_option: {
          display: "block"
        }
      });
    }

    this.setState({ next_link: response.links.next, total_count: response.meta.total });

    let temp = [];

    if (this.state.clear_on_get) {
      this.setState({
        data: [],
        clear_on_get: false,
      });

      temp = [];
    } else {
      temp = this.state.data;
    }

    rodinne_domy.forEach((el) => {
      temp.push(el);
    });

    this.setState({
      data: temp
    });
  }

  loadMore() {
    if (indexReferences.running) {
      indexReferences.cancel();
    }

    this.setState({
      load_more_option: {
        display: "none"
      }
    });

    if (undefined === this.state.next_link) {
      indexReferences.request("Rodinné domy", undefined, this.state, this.setData);
    } else {
      if (null !== this.state.next_link) {
        indexReferences.request("Rodinné domy", this.state.next_link, this.state, this.setData);
      }
    }
  }

  closeDetail() {
    this.setState({ detail: "" });
    document.body.style.overflowY = "scroll";
  }

  modalClicked(e) {
    if (e.target.dataset.name == "modal") {
      this.closeDetail();
    }
  }

  imageLoaded() {
    this.setState({ loaded: true });
  }

  openDetail(e) {
    e.preventDefault();

    const key = e.target.dataset.key;

    if (!key) {
      return;
    }

    // this.setState({ detail: "open" });
    document.body.style.overflowY = "hidden";

    const additional_photos = this.state.data[key].aditional_images;
    const year = this.state.data[key].realization_date.slice(0, 4);
    const name = this.state.data[key].name;

    this.setState({ selected: this.state.data[key], year: year, name: name, from: additional_photos.length });

    var photos = additional_photos.map((value, index) => {
      return (
        <div key={index}>
          <img className="dum-gallery-image" src={value} alt="" />
        </div>
      );
    });

    var carousel = (
      <div>
        <Carousel
          useKeyboardArrows
          statusFormatter={(current, total) => {
            return current + "/" + total;
          }}
          onChange={e => this.onChangeOf(e)}
          // onClickThumb={() => this.updateCount()}
        >
          {photos}
        </Carousel>
      </div>
    );
    this.setState({ carousel: carousel, detail: "open" });
  }

  onChangeOf(e) {
    this.setState({ num: e + 1 });
  }

  render() {
    return (
      <div className="main-container-domy" id="main-container-rodinne-domy">
        {
          this.state.detail !== 'open' ? '' : (
            <div className={"dum-gallery-modal " + this.state.detail}>
              <div
                className="dum-gallery-wrapper"
                data-name="modal"
                onClick={this.modalClicked}
              >
                <div className="dum-gallery-container">
                  <div className="gallery-close" onClick={this.closeDetail}>
                    <img
                      className="dum-gallery-image"
                      src={config.paths.icons + "ic_plus_active.png"}
                      alt=""
                    />
                  </div>
                  {this.state.detail == "open" ? this.state.carousel : ""}
                  <div className="gallery-info">
                    <div className="gallery-data-left">
                    <div className="projekt-data">
              <div className="projekt-data-block projekt-data-block--orange">
                <span className="projekt-data-block-content">
                  <b>{this.state.selected.building_type ? BUIDLING_TYPES[this.state.selected.building_type] : null || this.state.selected.name || '-'}</b>
                </span>
                <br />
                <span className="projekt-data-block-heading">{this.state.selected.building_type ? 'Typ reference' : 'Název'}</span>
              </div>
              </div>
                    </div>
                    <div className="gallery-data-right">
                    <div className="projekt-data">
              <div className="projekt-data-block">
              {/*  <span className="projekt-data-block-content">*/}
              {/*    <b>{this.state.selected.realization_date ? this.state.selected.realization_date.slice(0, 4) : '-'}</b>*/}
              {/*  </span>*/}
              {/*  <br />*/}
              {/*  <span className="projekt-data-block-heading">{i18n.t('general.realization')}</span>*/}
              </div>
              <div className="projekt-data-block">
                <span className="projekt-data-block-content">
                  <b>
                    {this.state.selected.floor_area !== null
                      ? this.state.selected.floor_area
                      : "-"}{" "}
                  m<sup>2</sup></b>
                </span>
                <br />
                <span className="projekt-data-block-heading">{i18n.t('general.floorArea')}</span>
              </div>
              <div className="projekt-data-block">
                <span className="projekt-data-block-content">
                  <b>
                    {this.state.selected.heat_loss !== null
                      ? this.state.selected.heat_loss
                      : "-"}
                  </b>{" "}
                  <b>kW</b>
                </span>
                <br />
                <span className="projekt-data-block-heading">
                  {i18n.t('general.heatLosses')}
                </span>
              </div>
              <div className="projekt-data-block">
                <span className="projekt-data-block-content">
                  <b>
                    {this.state.selected.expenses_per_month
                      ? this.state.selected.expenses_per_month
                      : "-"}
                  </b>{" "}
                  <b>{i18n.t('general.currency')}*</b>
                </span>
                <br />
                <span className="projekt-data-block-heading">
                  {i18n.t('general.costsPerMonth')}
                </span>
              </div>
            </div>
                      <span className="projekt-warning-costs">{i18n.t('general.projectWarningCosts')}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          )
        }
        <div className="domy-container" style={{display: 'grid'}}>
          {/* <div className="domy-header"> */}
          <div style={{display: 'inline-block', justifySelf: 'center'}}>
            <h1 className="domy-nadpis">{i18n.t('reference.houses')}</h1>
            <div className="domy-route">
              {i18n.t('reference.title')}
            </div>
            <p className="domy-podnadpis">
              <span dangerouslySetInnerHTML={{ __html: i18n.t('reference.housesTextP1') }}/>
              <span className="open-info-modal" onClick={this.props.toggleInfoModal}>HeatFlow</span>
              <span dangerouslySetInnerHTML={{ __html: i18n.t('reference.housesTextP2') }}/>
            </p>
          </div>
          <div className="domy-stats">
            <div className="domy-pocet">
              {i18n.t('general.homeCount')}
              <br />
              <span>
                {this.state.config_buildings
                  ? this.state.config_buildings
                  : "-"}
              </span>
            </div>
            <div className="domy-plocha">
              {i18n.t('general.totalFloorArea')}
              <br />
              <span>
                {this.state.config_area ? this.state.config_area : "-"}{" "}
                <small>m</small>
                <sup>2</sup>
              </span>
            </div>
          </div>
          {/* form */}
          <div className="form-container">
            <form>

              <div className="form-row" style={{width: '100%', display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridGap: '1em', alignItems: 'end', justifyItems: 'center'}}>
                <div className="form-field">
                  <label htmlFor="building_type">
                  {i18n.t('reference.familyHouses.type.title')}
                  </label>
                  <select
                    name="building_type"
                    id="building_type"
                    onChange={this.handleChange}
                    value={this.state.building_type}
                    className={this.state.building_type === '' ? '' : 'selected'}
                  >
                    <option value="" defaultValue>{i18n.t('reference.familyHouses.type.option1')}</option>
                    <option value="kamen">{i18n.t('reference.familyHouses.type.option2')}</option>
                    <option value="drevo">{i18n.t('reference.familyHouses.type.option3')}</option>
                    <option value="rekonstrukce">{i18n.t('reference.familyHouses.type.option4')}</option>
                  </select>
                </div>
                <div className="form-field">
                  <label htmlFor="floor_area">
                  {i18n.t('reference.familyHouses.area.title')}
                  </label>
                  <select
                    name="floor_area"
                    id="floor_area"
                    onChange={this.handleChange}
                    value={this.state.floor_area}
                    className={this.state.floor_area === '' ? '' : 'selected'}
                  >
                    <option value="" defaultValue>{i18n.t('reference.familyHouses.area.option1')}</option>
                    <option value="-100">{i18n.t('reference.familyHouses.area.option2')}</option>
                    <option value="-150">{i18n.t('reference.familyHouses.area.option3')}</option>
                    <option value="+150">{i18n.t('reference.familyHouses.area.option4')}</option>
                  </select>
                </div>
                <div className="form-field">
                  <label htmlFor="expenses_per_month">
                  {i18n.t('reference.familyHouses.costs.title')}
                  </label>
                  <select
                    name="expenses_per_month"
                    id="expenses_per_month"
                    onChange={this.handleChange}
                    value={this.state.expenses_per_month}
                    className={this.state.expenses_per_month === '' ? '' : 'selected'}
                  >
                    <option value="" defaultValue>{i18n.t('reference.familyHouses.costs.option1')}</option>
                    <option value="-1000">{i18n.t('reference.familyHouses.costs.option2')}</option>
                    <option value="-2000">{i18n.t('reference.familyHouses.costs.option3')}</option>
                    <option value="-3000">{i18n.t('reference.familyHouses.costs.option4')}</option>
                    <option value="+3000">{i18n.t('reference.familyHouses.costs.option5')}</option>
                  </select>
                </div>
              </div>

            </form>
          </div>
          <TotalCount running={indexReferences.running} total_count={this.state.total_count} building_type={this.state.building_type} floor_area={this.state.floor_area} expenses_per_month={this.state.expenses_per_month}/>
          {/* form */}

          <div
            className={"loading-container " + (this.state.loaded ? "show" : "")}
          >
            <div className="domy-list">
              {this.state.data.map((value, index) => {
                var image_path = value.main_image;
                if (!image_path) {
                  image_path = config.paths.images + "img_ref_dum.jpg";
                }

                return (
                  <div key={index} className="domy-one-cont" style={{'--delay': ((index % 12) * .1) + 's'}}>
                    <img
                      className="dum-bg-image"
                      src={image_path}
                      alt={value.name}
                      onLoad={index == 8 ? this.imageLoaded : null}
                    />
                    <Link
                      to={config.pages.ref_projekt.url}
                      className="dum-link"
                      data-key={index}
                      onClick={this.openDetail}
                    >
                      <div className="dum-vice-info" data-key={index}>
                        <span data-key={index}>{i18n.t('general.moreAboutReference')}</span>
                        <img
                          className="dum-vice-arrow"
                          src={
                            config.paths.icons + "ic_arrow_right_orange.png"
                          }
                          alt="Zobrazit dum"
                          data-key={index}
                        />
                      </div>
                      <div className="dum-vice-info" data-key={index}>
                        <span data-key={index}>{i18n.t('general.moreAboutReference')}</span>
                        <img
                          className="dum-vice-arrow"
                          src={
                            config.paths.icons + "ic_arrow_right_white.png"
                          }
                          alt="Zobrazit dum"
                          data-key={index}
                        />
                      </div>
                      <div className="dum-bottom" data-key={index}>
                        <div className="dum-name" data-key={index}>
                          <span className="dum-name-orange" data-key={index}>
                            {value.name ? value.name : "-"}
                          </span>
                          <br />
                          {/*<span*/}
                          {/*  className="dum-name-location"*/}
                          {/*  data-key={index}*/}
                          {/*>*/}
                          {/*  <b>*/}
                          {/*    {value.date ? value.date : (value.realization_date*/}
                          {/*      ? value.realization_date.slice(0, 4)*/}
                          {/*      : "-")}*/}
                          {/*  </b>*/}
                          {/*</span>*/}
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="domy-footer">
            <div
              className="domy-nacist-vice"
              style={this.state.load_more_option}
              onClick={this.loadMore}
            >
              {i18n.t('general.loadMore')}
            </div>
            <LoadingData label={"Načítám domy"} loading={indexReferences.running}/>
          </div>
        </div>
      </div>
    );
  }
}

export default RodinneDomy;
