import * as React from "react";
import config from "../../../../config/config.json";

class HeatflowNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selected: this.props.selected_section + "_link" };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    document.body.style.overflowY = "scroll";
    const id = e.currentTarget.id;
    console.log(id);
    this.setState({ selected: id });
    if (id == "section_6_link" || id == "section_7_link") {
      this.props.onNavigationClick(id);
    } else {
      this.props.onAnimationNavigationClick(id);
    }
  }

  static getDerivedStateFromProps(props, current_state) {
    console.log(props.selected_section);
    console.log(current_state.selected);
    if (current_state.selected !== props.selected_section + "_link") {
      return {
        selected: props.selected_section + "_link"
      };
    }
    return null;
  }

  render() {
    return (
      <div className="page-navigation" style={this.props.style}>
        <div
          className={
            "page-navigation-block " +
            (this.state.selected == "section_1_link" ? "selected" : "")
          }
          style={
              this.state.selected != "section_1_link" ? { display: "none" } : {}
          }
          id="section_1_link"
          onClick={this.handleClick}
        >
          <div className="page-navigation-block-number">01</div>
          <div className="page-navigation-block-line" />
        </div>
        <div
          className={
            "page-navigation-block " +
            (this.state.selected == "section_21_link" ||
            this.state.selected == "section_22_link" ||
            this.state.selected == "section_23_link" ||
            this.state.selected == "section_24_link"
              ? "selected"
              : "")
          }
          id="section_21_link"
          onClick={e => {
            if (
              this.state.selected != "section_21_link" &&
              this.state.selected != "section_22_link" &&
              this.state.selected != "section_23_link" &&
              this.state.selected != "section_24_link"
            ) {
              this.handleClick(e);
            } else {
              e.preventDefault();
            }
          }}
        >
          <div className="page-navigation-block-number">02</div>
          <div className="page-navigation-lines">
            <div
              className="page-navigation-block-line"
              id="section_21_link"
              onClick={this.handleClick}
            />
            <div
              className={
                "page-navigation-block-line " +
                (this.state.selected != "section_22_link" &&
                this.state.selected != "section_23_link" &&
                this.state.selected != "section_24_link"
                  ? "hidden"
                  : "")
              }
              id="section_22_link"
              onClick={this.handleClick}
            />
            <div
              className={
                "page-navigation-block-line " +
                (this.state.selected != "section_23_link" &&
                this.state.selected != "section_24_link"
                  ? "hidden"
                  : "")
              }
              id="section_23_link"
              onClick={this.handleClick}
            />
            <div
              className={
                "page-navigation-block-line " +
                (this.state.selected != "section_24_link" ? "hidden" : "")
              }
              id="section_24_link"
              onClick={this.handleClick}
            />
          </div>
        </div>
        <div
          className={
            "page-navigation-block " +
            (this.state.selected == "section_3_link" ? "selected" : "")
          }
          id="section_3_link"
          onClick={this.handleClick}
        >
          <div className="page-navigation-block-number">03</div>
          <div className="page-navigation-block-line" />
        </div>
        <div
          className={
            "page-navigation-block " +
            (this.state.selected == "section_4_link" ? "selected" : "")
          }
          id="section_4_link"
          onClick={this.handleClick}
        >
          <div className="page-navigation-block-number">04</div>
          <div className="page-navigation-block-line" />
        </div>
        <div
          className={
            "page-navigation-block page-navigation-block--d-none " +
            (this.state.selected == "section_5_link" ? "selected" : "")
          }
          id="section_5_link"
          onClick={this.handleClick}
        >
          <div className="page-navigation-block-number">05</div>
          <div className="page-navigation-block-line" />
        </div>
        <div
          className={
            "page-navigation-block " +
            (this.state.selected == "section_6_link" ? "selected" : "")
          }
          style={{ display: "none" }}
          id="section_6_link"
          onClick={this.handleClick}
        >
          <div className="page-navigation-block-number">06</div>
          <div className="page-navigation-block-line" />
        </div>
        <div
          className={
            "page-navigation-block " +
            (this.state.selected == "section_7_link" ? "selected" : "")
          }
          style={{ display: "none" }}
          id="section_7_link"
          onClick={this.handleClick}
        >
          <div className="page-navigation-block-number">07</div>
          <div className="page-navigation-block-line" />
        </div>
      </div>
    );
  }
}

export { HeatflowNavigation };
