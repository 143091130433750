import React from 'react';

function AnimationAboutTabs({current, go, options, theme})
{
    return (
        <div className={[
            'AnimationAboutTabs',
            `AnimationAboutTabs--${theme}`,
        ].filter((el) => el).join(' ')}>
            {options.map((option, index) => {
                return (
                    <div role="button" tabIndex="-1" onClick={() => {go(index)}} onKeyDown={() => {go(index)}} key={index} className={[
                        'AnimationAboutTabs__tab',
                        current === index && 'AnimationAboutTabs__tab--active',
                    ].filter((el) => el).join(' ')}>{option}</div>
                );
            })}
        </div>
    );
}

export default AnimationAboutTabs;
