import * as React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import config from "../../../config/config.json";
import Home from "./Pages/Home";
import MobilePage from "./Pages/MobilePage";
import ZajimaveProjekty from "./Pages/ZajimaveProjekty.jsx";
import ZajimavyProjekt from "./Pages/ZajimavyProjekt.jsx";
import RodinneDomy from "./Pages/RodinneDomy.jsx";
import Kontakty from "./Pages/Kontakty.jsx";
import { Menu } from "./components/Menu";
import { MobileMenu } from "./components/MobileMenu";
import { Footer } from "./components/Footer.jsx";
import CenovaNabidka from "./Pages/CenovaNabidka.jsx";
import PosouzeniProjektu from "./Pages/PosouzeniProjektu.jsx";
import TepelnyAudit from "./Pages/TepelnyAudit.jsx";
import Heatflow from "./Pages/Heatflow.jsx";
import AnimationAbout from './components/AnimationAbout';
import AnimationAboutOld from './components/AnimationAboutOld';
import KeStazeni from "./Pages/KeStazeni.jsx";
import Faq from "./Pages/Faq.jsx";
import PorovnaniVytapeni from "./Pages/PorovnaniVytapeni.jsx";
import Sluzba from "./Pages/Sluzba.jsx";
import smoothScrollIntoView from "smooth-scroll-into-view-if-needed";
import VzorovyDum from "./Pages/VzorovyDum.jsx";
import Privacy from "./Pages/Privacy.jsx";
import TermsConditions from "./Pages/TermsConditions.jsx";
import GDPR from "./Pages/GDPR.jsx";
import Konfigurator from "./Pages/Konfigurator.jsx";
import PripadovaStudie from "./Pages/PripadovaStudie.jsx";
import DatabazeRealizaci from "./Pages/DatabazeRealizaci.jsx";
import DatabazeRealizace from "./Pages/DatabazeRealizace.jsx";
import i18n from "./i18n";

class Main extends React.Component {
  constructor(props) {
    super(props);

    let seenInfoModal = window.localStorage.getItem("seenInfoModal") !== 'true';

    let path = (window.location.pathname)

    this.state = {
      mobile: this.checkIfMobile(),
      intro: false,
      menuOpened: false,
      info_modal: false,
    };
    this.scrollToTop = this.scrollToTop.bind(this);
    this.resize = this.resize.bind(this);
    this.introEnded = this.introEnded.bind(this);
    this.handleMenuOpen = this.handleMenuOpen.bind(this);
    this.handleMenuClose = this.handleMenuClose.bind(this);

    this.toggleInfoModal = this.toggleInfoModal.bind(this);

    this.main_window = React.createRef();

    this.language = i18n.language;
  }

  getPath(url) {

    const location = document.location.href;

    let result = url;

    if (location.charAt(location.length - 1) === '/') {
        result += '/';
    }

    return result;
  }

  toggleInfoModal() {
    const { info_modal } = this.state;
    this.setState({
      info_modal: (info_modal ? false : true)
    });
  }

  componentDidMount() {
    // window.addEventListener("scroll", this.onScroll, { passive: true });
    window.addEventListener("resize", this.resize);
    this.resize();
    // console.log(window.matchMedia("(orientation: landscape)").matches);
  }

  resize() {
    this.setState({
      mobile: this.checkIfMobile()
    });
  }

  checkIfMobile() {
      return (
        (/iPhone|iPod|Android/i.test(navigator.userAgent)) ||
        (window.innerWidth <= 767)
      );
  }

  introEnded() {
    let seenInfoModal = window.localStorage.getItem("seenInfoModal") !== 'true';
    this.setState({
      intro: true,
      info_modal: false
    });
  }

  handleMenuOpen() {
    this.setState({menuOpened: true});
  }

  handleMenuClose() {
    this.setState({menuOpened: false});
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  }
  scrollToTop() {
    // this.main_window.current.scrollIntoView({
    //   behavior: "smooth",
    //   block: "start"
    // });
    smoothScrollIntoView(this.main_window.current, {
      behavior: "smooth",
      block: "start",
      duration: 1200
    });
  }

  render() {
    return (
      <div className={"page-wrapper lang-"+this.language} ref={this.main_window}>
        {this.state.info_modal ? (
          <div className="info-modal" data-name="modal">
            <div className="info-cont">
              <div className="info-close" onClick={this.toggleInfoModal}>
                <img className="" src={config.paths.icons + 'ic_close_black.png'} alt="" />
              </div>
              <div className="info-wrapper">
                <h1 className="info-nadpis">{i18n.t('general.infoModalHeading')}</h1>
                <div className="info-content" dangerouslySetInnerHTML={{ __html: i18n.t('general.infoModalContent') }}></div>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
        <div className="page-row topbar" />
        {this.state.mobile ? (
          <div className="mobile">
            <MobilePage onToTopBtnClick={this.scrollToTop} />
          </div>
        ) : (
          <div className={["desktop", this.state.menuOpened ? 'desktop--dimmed' : null].filter((el) => el).join(' ')}>
            <Menu onOpenMenu={this.handleMenuOpen} onCloseMenu={this.handleMenuClose} />
            <Switch>
              <Route
                exact
                path={config.pages.home.url}
                render={props => (
                  <Home
                    {...props}
                    introPlay={this.state.intro}
                    introFinished={this.introEnded}
                  />
                )}
              />
              <Route
                exact
                path={this.getPath(config.pages.heatflow_slides.url)}
                component={Heatflow}
              />
              <Route
                exact
                path={this.getPath(config.pages.heatflow.url)}
                component={AnimationAbout}
              />
              <Route
                exact
                path={this.getPath(config.pages.ref_projekty.url)}
                component={ZajimaveProjekty}
              />
              <Route
                exact
                path={this.getPath(config.pages.ref_projekt.url)}
                key={window.location.pathname}
                component={ZajimavyProjekt}
              />
              <Route
                exact
                path={this.getPath(config.pages.konfigurator.url)}
                component={Konfigurator}
              />
              {/* <Route
              exact
              path={
                this.getBasePath() +
                config.pages.ref_projekt.url +
                "/:projekt_id"
              }
              component={ZajimavyProjekt}
            /> */}
              <Route
                exact
                path={this.getPath(config.pages.ref_domy.url)}
                render={props => (
                  <RodinneDomy
                    {...props}
                    toggleInfoModal={this.toggleInfoModal}
                  />
                )}
              />
              <Route
                exact
                path={this.getPath(config.pages.ref_databaze.url)}
                component={DatabazeRealizaci}
              />
              <Route
                exact
                path={this.getPath(config.pages.ref_databaze_show.url)}
                component={DatabazeRealizace}
                key={window.location.pathname}
              />
              <Route
                exact
                path={this.getPath(config.pages.kontakty.url)}
                component={Kontakty}
              />
              <Route
                exact
                path={this.getPath(config.pages.pripadova_studie.url)}
                component={PripadovaStudie}
              />
              {/*<Route*/}
              {/*  exact*/}
              {/*  path={this.getPath(config.pages.vzorovy_dum.url)}*/}
              {/*  component={VzorovyDum}*/}
              {/*/>*/}
              <Route
                exact
                path={this.getPath(config.pages.sluzba.url)}
                component={Sluzba}
              />
              <Route
                exact
                path={this.getPath(config.pages.cenova_nabidka.url)}
                component={CenovaNabidka}
              />
              <Route
                exact
                path={this.getPath(config.pages.posouzeni_projektu.url)}
                component={PosouzeniProjektu}
              />
              <Route
                exact
                path={this.getPath(config.pages.tepelny_audit.url)}
                component={TepelnyAudit}
              />
              <Route
                exact
                path={this.getPath(config.pages.ke_stazeni.url)}
                component={KeStazeni}
              />
              <Route
                exact
                path={this.getPath(config.pages.gdpr.url)}
                component={GDPR}
              />
              <Route
                exact
                path={this.getPath(config.pages.privacy.url)}
                component={Privacy}
              />
              <Route
                  exact
                  path={this.getPath(config.pages.terms_conditions.url)}
                  component={TermsConditions}
              />
              <Route
                exact
                path={this.getPath(config.pages.faq.url)}
                component={Faq}
              />

              <Redirect to='/' />
            </Switch>
            <Footer onToTopBtnClick={this.scrollToTop} />
          </div>
        )}
        {/* <div className="mobile">
          <MobilePage onToTopBtnClick={this.scrollToTop} />
        </div>
        <div className="desktop">
          <Menu />
          <Switch>
            <Route
              exact
              path={this.getBasePath() + config.pages.home.url}
              component={Home}
            />
            <Route
              exact
              path={this.getBasePath() + config.pages.heatflow.url}
              component={Heatflow}
            />
            <Route
              exact
              path={this.getBasePath() + config.pages.porovnani_vytapeni.url}
              component={PorovnaniVytapeni}
            />
            <Route
              exact
              path={this.getBasePath() + config.pages.ref_projekty.url}
              component={ZajimaveProjekty}
            />
            <Route
              exact
              path={this.getBasePath() + config.pages.ref_projekt.url}
              component={ZajimavyProjekt}
            />

            <Route
              exact
              path={this.getBasePath() + config.pages.ref_domy.url}
              component={RodinneDomy}
            />
            <Route
              exact
              path={this.getBasePath() + config.pages.kontakty.url}
              component={Kontakty}
            />
            <Route
              exact
              path={this.getBasePath() + config.pages.pripadova_studie.url}
              component={PripadovaStudie}
            />
            <Route
              exact
              path={this.getBasePath() + config.pages.sluzba.url}
              component={Sluzba}
            />
            <Route
              exact
              path={this.getBasePath() + config.pages.cenova_nabidka.url}
              component={CenovaNabidka}
            />
            <Route
              exact
              path={this.getBasePath() + config.pages.posouzeni_projektu.url}
              component={PosouzeniProjektu}
            />
            <Route
              exact
              path={this.getBasePath() + config.pages.tepelny_audit.url}
              component={TepelnyAudit}
            />
            <Route
              exact
              path={this.getBasePath() + config.pages.ke_stazeni.url}
              component={KeStazeni}
            />
            <Route
              exact
              path={this.getBasePath() + config.pages.faq.url}
              component={Faq}
            />
          </Switch>
          <Footer onToTopBtnClick={this.scrollToTop} />
        </div> */}
      </div>
    );
  }
}

export default Main;
