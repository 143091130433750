import * as React from "react";
import * as ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import config from "../../../../config/config.json";
import * as PIXI from "pixi.js";
import { CircleSlider } from "../components/CircleSlider.jsx";
import WheelReact from "wheel-react";
import CountUp, { startAnimation } from "react-countup";
import { CircleGraph } from "../components/CircleGraph.jsx";

import i18n from '../i18n';
import Loader from './Loader';

PIXI.utils.skipHello();

class AnimationAbout extends React.Component {
  constructor(props) {
    super(props);

    this.texturePhoto = PIXI.Texture.from(
      "/assets/images/backgrounds/texture-photo.png"
    );

    this.textureInterior = PIXI.Texture.from(
      "/assets/images/backgrounds/texture-interior.png"
    );

    this.textrueFurniture = PIXI.Texture.from(
      "/assets/images/backgrounds/texture-furniture.png"
    );

    this.textureSun = PIXI.Texture.from(
      "/assets/images/backgrounds/texture-sun.png"
    );

    this.textureRain = PIXI.Texture.from(
      "/assets/images/backgrounds/texture-rain.png"
    );

    this.textureSnow = PIXI.Texture.from(
      "/assets/images/backgrounds/texture-snow.png"
    );

    this.textureBottom = PIXI.Texture.from(
      "/assets/images/backgrounds/texture-bottom.png"
    );

    this.textureInfra = PIXI.Texture.from(
      "/assets/images/backgrounds/texture-infra.png"
    );

    this.textureLeftGrad = PIXI.Texture.from(
      "/assets/images/backgrounds/texture-left-grad.png"
    );

    this.textureRoznaseci = PIXI.Texture.from(
      "/assets/images/backgrounds/texture-roznaseci.png"
    );

    this.maxWeatherAlpha = 0.6;
    this.defaultZoom = 0.65;

    this.app = new PIXI.Application();
    this.alphaGlass = 0;
    this.alphaWeather = 0;
    this.textureGlass = null;
    this.textureWeather = null;
    this.fallSpeed = 0;
    this.saturate = 0;
    this.zoom = {
      front: 0,
      back: 0
    };
    this.bottomAlpha = 0;
    this.furnitureAlpha = 1;
    this.infraAlpha = 0;
    this.roznaseciAlpha = 0;
    this.night = 1;

    this.animation_ended = false;

    this.kratsi_graph = 1.5;
    this.delsi_graph = 12;

    this.furnitureUpTicker = false;
    this.furnitureDownTicker = false;

    this.infraUpTicker = false;
    this.infraDownTicker = false;

    this.roznaseciUpTicker = false;
    this.roznaseciDownTicker = false;

    this.state = {
      thirdWheelClicked: false,
      mounted: false,
      step: 0,
      showLoader: true,
      showSound: true,
      fade_intro: true,
      scale_out: false,
      section1: "",
      section2: "hidden",
      section3: "hidden",
      section2_line1: "",
      section2_line2: "",
      section2_line3: "",
      section2_line4: "",
      scroll_btn: "",
      section2_bottom: "",
      section3_bottom_glowing: "",
      section3_trubky: "",
      section3_cont: "",
      section3_number: "",
      section3_center: "",
      section3_1: "",
      section3_2: "",
      section3_3: "",
      section3_4: "",
      section3_5: "",
      section3_6: "",
      section3_7: "hidden",
      on_graph: false,
      canStart: false,
      section3_budiky1: "",
      section3_budiky2: "",
      section3_budiky3: "",
      section3_graphs: "",
      section3_wider: "", //section3_5
      srovnani_intro: "", //section3_6
      srovnani_block: "", //section3_6
      srovnani_graph: "", //section3_6
      sound_muted: "ic_sound_on",
      srovnani_percentage1: 0,
      srovnani_percentage2: 0,
      srovnani_cas1: 0,
      srovnani_cas2: 0,
      didViewCountUp: false,
      didViewBox: 0,
      didViewBoxBezporuchy: 0,
      didViewBoxRoznaseci: 0,
      vytapeni_sec: 59,
      vytapeni_min: 21,
      srovnani_numbers: "",
      loadingStatus: 0,
      temp_value: 0,
      time_value: 0,
      started: false,
      srovnaniGraph: false,
      columns_finished: false,
      heatflow_time: false,
      cerpadlo_time: false,
      scroll_continue: "",
      weather: "sun", // rain, sun, snow
      temperature: 20,
      first_circle: "active",
      second_circle: "",
      third_circle: "",
      can_continue: false,
      anim_count: 1,
      section1_style: {},
      animation_countdown: 3,
      loader_progress: 30,
      scrolling: false,
      loader_color: { backgroundColor: "rgb(0,0,0)" },
      showDesky: false,
      desky: "",
      infra: "",
      roznaseci: "",
      repeat_srovnani: "",
      repeat: true,
      scroll_hide: "",
      disable_scroll: true,
      show_clock: false,
      loaderColor: true,
      show_animation_controls: false,
      scroll_count_disappear: false,
      scroll_count: 3,
      show_scroll: false,
      loader_loaded: true,
      loaderLoaded: false,
      graph_comfort: 0,
      graph_roznaseci: 0,
      graph_pokryti: 0,
      graph_bezporuchy: 0,
      isSafari: false,
      startX: undefined,
      endX: undefined,
      startTime: undefined,
      endTime: undefined,
      startY: undefined,
      endY: undefined,
      lastPrev: undefined,
    };

    this.isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function(p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(
        !window["safari"] ||
          (typeof safari !== "undefined" && safari.pushNotification)
      );

    this.state1 = {
      section1: "",
      section2: "hidden",
      section3: "hidden",
      section2_line1: "",
      section2_line2: "",
      section2_line3: "",
      section2_line4: "",
      section1_style: {},
      section3_1: "",
      section3_2: "",
      section3_3: "",
      section3_4: "",
      section3_5: "",
      section3_6: "",
      section3_7: "hidden",
      scroll_count_disappear: false,
      show_scroll: true,
      scroll_btn: "show",
      section2_bottom: "",
      scale_out: false,
      showSound: false,
      show_animation_controls: false,
      started: false,
      section3_bottom_glowing: "",
      section3_trubky: "",
      section3_cont: "",
      section3_number: "",
      section3_center: "",
      on_graph: false,
      section3_budiky1: "",
      section3_budiky2: "",
      section3_budiky3: "",
      section3_graphs: "",
      section3_wider: "", //section3_5
      srovnani_intro: "", //section3_6
      srovnani_block: "", //section3_6
      srovnani_graph: "", //section3_6
      srovnani_percentage1: 0,
      srovnani_percentage2: 0,
      srovnani_cas1: 0,
      srovnani_cas2: 0,
      didViewCountUp: false,
      vytapeni_sec: 59,
      vytapeni_min: 21,
      srovnani_numbers: "",
      srovnaniGraph: false,
      columns_finished: false,
      heatflow_time: false,
      cerpadlo_time: false,
      anim_count: 1,
      animation_countdown: 3,
      showDesky: false,
      desky: "",
      infra: "",
      roznaseci: "",
      repeat_srovnani: "",
      repeat: true,
      //graph_comfort: 0,
      //graph_roznaseci: 0,
      graph_pokryti: 0,
      //graph_bezporuchy: 0,
      scroll_hide: "",
      scroll_continue: "",
      disable_scroll: true
    };
    this.state21 = {
      section1: "hidden",
      section2: "hidden",
      section3: "",
      section2_line1: "",
      section2_line2: "",
      section2_line3: "",
      section2_line4: "",
      section1_style: { display: "none" },
      section3_number: "show",
      section3_1: "active",
      section3_2: "",
      section3_3: "",
      section3_4: "",
      section3_5: "",
      section3_6: "",
      section3_7: "hidden",
      croll_count_disappear: false,
      show_scroll: true,
      scroll_btn: "show",
      section2_bottom: "show",
      scale_out: false,
      showSound: false,
      show_animation_controls: true,
      started: false,
      section3_bottom_glowing: "",
      section3_trubky: "",
      section3_cont: "show",
      section3_center: "",
      on_graph: false,
      section3_budiky1: "",
      section3_budiky2: "",
      section3_budiky3: "",
      section3_graphs: "",
      section3_wider: "", //section3_5
      srovnani_intro: "", //section3_6
      srovnani_block: "", //section3_6
      srovnani_graph: "", //section3_6
      srovnani_percentage1: 0,
      srovnani_percentage2: 0,
      srovnani_cas1: 0,
      srovnani_cas2: 0,
      didViewCountUp: false,
      vytapeni_sec: 59,
      vytapeni_min: 21,
      srovnani_numbers: "",
      srovnaniGraph: false,
      columns_finished: false,
      heatflow_time: false,
      cerpadlo_time: false,
      anim_count: 2,
      animation_countdown: 3,
      showDesky: false,
      desky: "",
      infra: "",
      roznaseci: "",
      repeat_srovnani: "",
      repeat: true,
      //graph_comfort: 0,
      //graph_roznaseci: 0,
      graph_pokryti: 0,
      //graph_bezporuchy: 0,
      scroll_hide: "",
      scroll_continue: "",
      disable_scroll: true
    };
    this.state22 = {
      section1: "hidden",
      section2: "hidden",
      section3: "",
      section2_line1: "",
      section2_line2: "",
      section2_line3: "",
      section2_line4: "",
      section1_style: { display: "none" },
      section3_number: "show",
      section3_1: "",
      section3_2: "active",
      section3_3: "",
      section3_4: "",
      section3_5: "",
      section3_6: "",
      section3_7: "hidden",
      croll_count_disappear: false,
      show_scroll: true,
      scroll_btn: "show",
      section2_bottom: "show",
      scale_out: false,
      showSound: false,
      show_animation_controls: true,
      started: false,
      section3_bottom_glowing: "",
      section3_trubky: "",
      section3_cont: "show",
      section3_center: "",
      on_graph: false,
      section3_budiky1: "",
      section3_budiky2: "",
      section3_budiky3: "",
      section3_graphs: "",
      section3_wider: "", //section3_5
      srovnani_intro: "", //section3_6
      srovnani_block: "", //section3_6
      srovnani_graph: "", //section3_6
      srovnani_percentage1: 0,
      srovnani_percentage2: 0,
      srovnani_cas1: 0,
      srovnani_cas2: 0,
      didViewCountUp: false,
      vytapeni_sec: 59,
      vytapeni_min: 21,
      srovnani_numbers: "",
      srovnaniGraph: false,
      columns_finished: false,
      heatflow_time: false,
      cerpadlo_time: false,
      anim_count: 3,
      animation_countdown: 3,
      showDesky: false,
      desky: "",
      infra: "",
      roznaseci: "",
      repeat_srovnani: "",
      repeat: true,
      //graph_comfort: 0,
      //graph_roznaseci: 0,
      graph_pokryti: 0,
      //graph_bezporuchy: 0,
      scroll_hide: "hidden",
      scroll_continue: "hidden",
      disable_scroll: true
    };
    this.state23 = {
      section1: "hidden",
      section2: "hidden",
      section3: "",
      section2_line1: "",
      section2_line2: "",
      section2_line3: "",
      section2_line4: "",
      section1_style: { display: "none" },
      section3_number: "show",
      section3_1: "",
      section3_2: "",
      section3_3: "active",
      section3_4: "",
      section3_5: "",
      section3_6: "",
      section3_7: "hidden",
      croll_count_disappear: false,
      show_scroll: true,
      scroll_btn: "show",
      section2_bottom: "show",
      scale_out: false,
      showSound: false,
      show_animation_controls: true,
      started: false,
      section3_bottom_glowing: "",
      section3_trubky: "",
      section3_cont: "show",
      section3_center: "",
      on_graph: false,
      section3_budiky1: "",
      section3_budiky2: "",
      section3_budiky3: "",
      section3_graphs: "",
      section3_wider: "", //section3_5
      srovnani_intro: "", //section3_6
      srovnani_block: "", //section3_6
      srovnani_graph: "", //section3_6
      srovnani_percentage1: 0,
      srovnani_percentage2: 0,
      srovnani_cas1: 0,
      srovnani_cas2: 0,
      didViewCountUp: false,
      vytapeni_sec: 59,
      vytapeni_min: 21,
      srovnani_numbers: "",
      srovnaniGraph: false,
      columns_finished: false,
      heatflow_time: false,
      cerpadlo_time: false,
      anim_count: 4,
      animation_countdown: 3,
      showDesky: false,
      desky: "",
      infra: "",
      roznaseci: "",
      repeat_srovnani: "",
      repeat: true,
      //graph_comfort: 0,
      //graph_roznaseci: 0,
      graph_pokryti: 0,
      //graph_bezporuchy: 0,
      scroll_hide: "hidden",
      scroll_continue: "hidden",
      disable_scroll: true
    };
    this.state24 = {
      section1: "hidden",
      section2: "hidden",
      section3: "",
      section2_line1: "",
      section2_line2: "",
      section2_line3: "",
      section2_line4: "",
      section1_style: { display: "none" },
      section3_number: "show",
      section3_1: "",
      section3_2: "",
      section3_3: "",
      section3_4: "active",
      section3_5: "",
      section3_6: "",
      section3_7: "hidden",
      croll_count_disappear: false,
      show_scroll: true,
      scroll_btn: "show",
      section2_bottom: "show",
      scale_out: false,
      showSound: false,
      show_animation_controls: true,
      started: false,
      section3_bottom_glowing: "",
      section3_trubky: "",
      section3_cont: "show",
      section3_center: "",
      on_graph: false,
      section3_budiky1: "",
      section3_budiky2: "",
      section3_budiky3: "",
      section3_graphs: "",
      section3_wider: "", //section3_5
      srovnani_intro: "", //section3_6
      srovnani_block: "", //section3_6
      srovnani_graph: "", //section3_6
      srovnani_percentage1: 0,
      srovnani_percentage2: 0,
      srovnani_cas1: 0,
      srovnani_cas2: 0,
      didViewCountUp: false,
      vytapeni_sec: 59,
      vytapeni_min: 21,
      srovnani_numbers: "",
      srovnaniGraph: false,
      columns_finished: false,
      heatflow_time: false,
      cerpadlo_time: false,
      anim_count: 5,
      animation_countdown: 3,
      showDesky: false,
      desky: "",
      infra: "",
      roznaseci: "",
      repeat_srovnani: "",
      repeat: true,
      //graph_comfort: 0,
      //graph_roznaseci: 0,
      graph_pokryti: 0,
      //graph_bezporuchy: 0,
      scroll_hide: "hidden",
      scroll_continue: "hidden",
      disable_scroll: true
    };
    this.state3 = {
      section1: "hidden",
      section2: "hidden",
      section3: "",
      section2_line1: "",
      section2_line2: "",
      section2_line3: "",
      section2_line4: "",
      section1_style: { display: "none" },
      section3_number: "",
      section3_1: "",
      section3_2: "",
      section3_3: "",
      section3_4: "",
      section3_5: "",
      section3_6: "",
      section3_7: "hidden",
      croll_count_disappear: false,
      show_scroll: true,
      scroll_btn: "show",
      section2_bottom: "show",
      scale_out: false,
      showSound: false,
      show_animation_controls: true,
      started: false,
      section3_bottom_glowing: "",
      section3_trubky: "",
      section3_cont: "show",
      section3_center: "",
      on_graph: false,
      section3_budiky1: "",
      section3_budiky2: "",
      section3_budiky3: "",
      section3_graphs: "",
      section3_wider: "wider", //section3_5
      srovnani_intro: "", //section3_6
      srovnani_block: "", //section3_6
      srovnani_graph: "", //section3_6
      srovnani_percentage1: 0,
      srovnani_percentage2: 0,
      srovnani_cas1: 0,
      srovnani_cas2: 0,
      didViewCountUp: false,
      vytapeni_sec: 59,
      vytapeni_min: 21,
      srovnani_numbers: "",
      srovnaniGraph: false,
      columns_finished: false,
      heatflow_time: false,
      cerpadlo_time: false,
      anim_count: 6,
      animation_countdown: 3,
      showDesky: false,
      desky: "",
      infra: "",
      roznaseci: "",
      repeat_srovnani: "",
      repeat: true,
      //graph_comfort: 0,
      //graph_roznaseci: 0,
      graph_pokryti: 0,
      //graph_bezporuchy: 0,
      scroll_hide: "hidden",
      scroll_continue: "hidden",
      disable_scroll: true
    };
    this.state4 = {
      section1: "hidden",
      section2: "hidden",
      section3: "",
      section2_line1: "",
      section2_line2: "",
      section2_line3: "",
      section2_line4: "",
      section1_style: { display: "none" },
      section3_number: "",
      section3_1: "",
      section3_2: "",
      section3_3: "",
      section3_4: "",
      section3_5: "",
      section3_6: "active",
      section3_7: "hidden",
      croll_count_disappear: false,
      show_scroll: true,
      scroll_btn: "show",
      section2_bottom: "show",
      scale_out: false,
      showSound: false,
      show_animation_controls: true,
      started: false,
      section3_bottom_glowing: "",
      section3_trubky: "show",
      section3_cont: "show",
      section3_center: "center",
      on_graph: true,
      section3_budiky1: "",
      section3_budiky2: "",
      section3_budiky3: "",
      section3_graphs: "",
      section3_wider: "wider", //section3_5
      srovnani_intro: "active", //section3_6
      srovnani_block: "", //section3_6
      srovnani_graph: "", //section3_6
      srovnani_percentage1: 0,
      srovnani_percentage2: 0,
      srovnani_cas1: 0,
      srovnani_cas2: 0,
      didViewCountUp: false,
      vytapeni_sec: 59,
      vytapeni_min: 21,
      srovnani_numbers: "active",
      srovnaniGraph: false,
      columns_finished: false,
      heatflow_time: false,
      cerpadlo_time: false,
      anim_count: 7,
      animation_countdown: 3,
      showDesky: false,
      desky: "",
      infra: "",
      roznaseci: "",
      repeat_srovnani: "",
      repeat: true,
      //graph_comfort: 0,
      //graph_roznaseci: 0,
      graph_pokryti: 0,
      //graph_bezporuchy: 0,
      scroll_hide: "",
      scroll_continue: "hidden",
      disable_scroll: true
    };
    this.state5 = {
      section1: "hidden",
      section2: "hidden",
      section3: "",
      section2_line1: "",
      section2_line2: "",
      section2_line3: "",
      section2_line4: "",
      section1_style: { display: "none" },
      section3_number: "",
      section3_1: "",
      section3_2: "",
      section3_3: "",
      section3_4: "",
      section3_5: "",
      section3_6: "active",
      section3_7: "hidden",
      croll_count_disappear: false,
      show_scroll: true,
      scroll_btn: "show",
      section2_bottom: "show",
      scale_out: false,
      showSound: false,
      show_animation_controls: true,
      started: false,
      section3_bottom_glowing: "",
      section3_trubky: "show",
      section3_cont: "show",
      section3_center: "center",
      on_graph: true,
      section3_budiky1: "",
      section3_budiky2: "",
      section3_budiky3: "",
      section3_graphs: "",
      section3_wider: "even_wider", //section3_5
      srovnani_intro: "active", //section3_6
      srovnani_block: "", //section3_6
      srovnani_graph: "", //section3_6
      srovnani_percentage1: 0,
      srovnani_percentage2: 0,
      srovnani_cas1: 0,
      srovnani_cas2: 0,
      didViewCountUp: false,
      vytapeni_sec: 59,
      vytapeni_min: 21,
      srovnani_numbers: "active",
      srovnaniGraph: false,
      columns_finished: false,
      heatflow_time: false,
      cerpadlo_time: false,
      disable_scroll: false,
      anim_count: 8,
      animation_countdown: 3,
      showDesky: false,
      desky: "",
      infra: "",
      roznaseci: "",
      repeat_srovnani: "",
      repeat: true,
      //graph_comfort: 0,
      //graph_roznaseci: 0,
      graph_pokryti: 0,
      //graph_bezporuchy: 0,
      scroll_hide: "",
      scroll_continue: "hidden"
    };

    this.handleThirdWheelClick = this.handleThirdWheelClick.bind(this);

    this.changeAnimationSettingsHandler = this.changeAnimationSettingsHandler.bind(
      this
    );
    this.changeAnimationSettings = this.changeAnimationSettings.bind(this);
    this.first_circle_changed = this.first_circle_changed.bind(this);
    this.second_circle_changed = this.second_circle_changed.bind(this);
    this.third_circle_changed = this.third_circle_changed.bind(this);
    this.next = this.next.bind(this);
    this.wait = this.wait.bind(this);
    this.waitAfterTransition = this.waitAfterTransition.bind(this);
    this.tempChanged = this.tempChanged.bind(this);
    this.timeChanged = this.timeChanged.bind(this);
    this.hideFurniture = this.hideFurniture.bind(this);
    this.showFurniture = this.showFurniture.bind(this);
    this.repeatSrovnani = this.repeatSrovnani.bind(this);
    this.isMacintosh = this.isMacintosh.bind(this);
    this.skipSettings = this.skipSettings.bind(this);
    this.muteSound = this.muteSound.bind(this);
    this.showGraphComfort = this.showGraphComfort.bind(this);
    this.showRoznaseci = this.showRoznaseci.bind(this);
    this.showPokryti = this.showPokryti.bind(this);
    this.showBezporuchy = this.showBezporuchy.bind(this);
    this.toggleInfra = this.toggleInfra.bind(this);
    this.toggleRoznaseci = this.toggleRoznaseci.bind(this);

    this.hideInfra = this.hideInfra.bind(this);
    this.hideRoznaseci = this.hideRoznaseci.bind(this);

    this.furnitureUp = this.furnitureUp.bind(this);
    this.furnitureDown = this.furnitureDown.bind(this);

    this.infraUp = this.infraUp.bind(this);
    this.infraDown = this.infraDown.bind(this);

    this.roznaseciUp = this.roznaseciUp.bind(this);
    this.roznaseciDown = this.roznaseciDown.bind(this);

    this.toSection1 = this.toSection1.bind(this);
    this.toSection21 = this.toSection21.bind(this);
    this.toSection22 = this.toSection22.bind(this);
    this.toSection23 = this.toSection23.bind(this);
    this.toSection24 = this.toSection24.bind(this);
    this.toSection3 = this.toSection3.bind(this);
    this.toSection4 = this.toSection4.bind(this);
    this.toSection5 = this.toSection5.bind(this);

    this.onTouchStart = this.onTouchStart.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    // this.repeatColumns = this.repeatColumns.bind(this);

    this.returnClockTemp = this.returnClockTemp.bind(this);
    this.returnClockTime = this.returnClockTime.bind(this);

    this.loaderLoaded = this.loaderLoaded.bind(this);

    this.detectMouseWheelDirection = this.detectMouseWheelDirection.bind(this);

    this.audio = new Audio("/assets/music/heatflow.mp3");

    this.audio.loop = true;

    this.section1 = React.createRef();

    this.onScroll = this.onScroll.bind(this);

    this.bottom_section = React.createRef();

    // if (this.isMacintosh()) {
    //   WheelReact.config({
    //     up: () => {
    //       this.next();
    //     }
    //   });
    // } else {
    //   WheelReact.config({
    //     down: () => {
    //       this.next();
    //     }
    //   });
    // }
  }

  handleThirdWheelClick () {
    if (this.state.thirdWheelClicked) {
      this.third_circle_changed();
      this.setState({ thirdWheelClicked: false })
    }
  }

  isInside(target, where) {
    if (target == undefined || target.parentElement == undefined ) {
      return false;
    }

    if (target.classList.contains(where)) {
      return true;
    }

    return this.isInside(target.parentElement, where);
  }

  prev() {
    if (this.state.can_continue) {
      this.waitAfterTransition();

      if (this.state.anim_count == 3) {
        this.toSection21();
      } else if (this.state.anim_count == 4) {
        this.toSection22();
      } else if (this.state.anim_count == 5) {
        this.toSection23();
      } else if (this.state.anim_count == 6) {
        this.toSection24();
      } else if (this.state.anim_count == 7) {
        this.toSection3();
      } else if (this.state.anim_count == 8) {
        this.toSection4();
      }
    }
  }

  onTouchStart(e) {
    if (!this.isInside(e.target, "controllers")) {
      this.setState({
        startX: e.touches[0].clientX,
        startY: e.touches[0].clientY,
        startTime: Date.now(),
      });
    } else {
      this.setState({
        startX: undefined,
        startY: undefined,
        startTime: undefined,
      });
    }
  }

  onTouchEnd(e) {
    if (this.state.startX !== undefined && this.state.startTime !== undefined && this.state.endX !== undefined && this.state.endTime !== undefined && this.state.startY !== undefined && this.state.endY !== undefined) {
      const distanceGreaterThanLimit = (Math.abs(this.state.endY - this.state.startY) > 225);
      const timeLessThanLimit = (Math.abs(this.state.endTime - this.state.startTime) <= 250);
      const distanceLowerThanLimit = (Math.abs(this.state.startX - this.state.endX) < 100);

      if (distanceGreaterThanLimit && timeLessThanLimit && distanceLowerThanLimit && this.state.disable_scroll === true) {
        if (this.state.startY < this.state.endY) {
          // up
          this.prev();
        } else {
          // down
          this.next();
        }
      }
    }

    this.setState({
      startX: undefined,
      endX: undefined,
      startTime: undefined,
      endTime: undefined,
      startY: undefined,
      endY: undefined,
    });
  }

  onTouchMove(e) {
    if (this.state.startX !== undefined && this.state.startTime !== undefined && this.state.startY !== undefined) {
      this.setState({
        endX: e.touches[0].clientX,
        endY: e.touches[0].clientY,
        endTime: Date.now(),
      });
    }
  }

  componentDidMount() {
      window.addEventListener('mouseup', this.handleThirdWheelClick);
    window.addEventListener("scroll", this.onScroll, { passive: false });
    this.setState({ isSafari: this.isSafari });



    // document.addEventListener("touchmove", function(event) {
    //   if (event.target.className != "circle-controller") { // Element that you don't want to be prevented default event.
    //     event.preventDefault();
    //     console.log("sxxsxssx");
    //   }
    // });

    window.addEventListener("touchstart", this.onTouchStart);
    window.addEventListener("touchmove", this.onTouchMove);
    window.addEventListener("touchend", this.onTouchEnd);

    if (window.addEventListener) {
      window.addEventListener(
        "DOMMouseScroll",
        this.detectMouseWheelDirection,
        {
          passive: false
        }
      );
    }
    window.addEventListener("wheel", this.detectMouseWheelDirection, {
      passive: false
    });

    this.setState({ show_clock: true });
    // document.body.style.overflowY = "hidden";
    const component = ReactDOM.findDOMNode(this);
    this.audio.volume = 0.2;

    this.app = new PIXI.Application({
      width: component.clientWidth,
      height: component.clientHeight,
      backgroundColor: 0xffffff,
      resolution: /*window.devicePixelRatio ||*/ 1
    });
    setTimeout(() => {
      this.setState({ loaderColor: false });
    }, 200);
    setTimeout(() => {
      var scroll_check = this.section1.current.getBoundingClientRect().top;

      if (scroll_check < 0) {
        this.setState({
          disable_scroll: false
        });
        document.body.style.overflowY = "scroll";
        // this.skipIntro();
        setTimeout(() => {
          this.setState({ scroll_display: { display: "block", opacity: 1 } });
        }, 100);
      }
    }, 1400);

    component.appendChild(this.app.view);

    let assets = [
      { texture: this.texturePhoto, loaded: false },
      { texture: this.textureInterior, loaded: false },
      { texture: this.textrueFurniture, loaded: false },
      { texture: this.textureSun, loaded: false },
      { texture: this.textureRain, loaded: false },
      { texture: this.textureSnow, loaded: false },
      { texture: this.textureInfra, loaded: false },
      { texture: this.textureRoznaseci, loaded: false },
      { texture: this.textureLeftGrad, loaded: false }
    ];

    const checkOtherAssets = () => {
      let result = true;

      for (let i = 0; i < assets.length; i++) {
        if (!assets[i].loaded) {
          result = false;
          break;
        }
      }

      return result;
    };

    const getLoaderValue = () => {
      let result = 0;

      for (let i = 0; i < assets.length; i++) {
        if (assets[i].loaded) {
          result += 1;
        }
      }
      const red = Math.floor((result * 240) / assets.length);
      const green = Math.floor((result * 91) / assets.length);
      const blue = Math.floor((result * 40) / assets.length);
      this.setState({
        loader_color: {
          backgroundColor: "rgb(" + red + "," + green + "," + blue + ")"
        }
      });

      return Math.floor(result * (100 / assets.length));
    };

    for (let i = 0; i < assets.length; i++) {
      if (assets[i].texture.baseTexture.valid) {
        assets[i].loaded = true;

        if (checkOtherAssets()) {
          this.animation();
          this.changeWeather("sun", this.state.weather);

          setTimeout(() => {
            this.setState({
              showLoader: false
            });
          }, 1400);
          setTimeout(() => {
            this.setState({
              fade_intro: false,
              scroll_btn: "show"
            });
            this.props.onLoad();
          }, 2000);
        }

        this.setState({
          loadingStatus: getLoaderValue()
        });
      } else {
        assets[i].texture.baseTexture.on("loaded", event => {
          assets[i].loaded = true;

          if (checkOtherAssets()) {
            this.animation();
            this.changeWeather("sun", this.state.weather);

            setTimeout(() => {
              this.setState({
                showLoader: false
              });
            }, 2000);
            setTimeout(() => {
              this.setState({
                fade_intro: false,
                scroll_btn: "show"
              });
              this.props.onLoad();
            }, 3000);
          }

          this.setState({
            loadingStatus: getLoaderValue()
          });
        });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('mouseup', this.handleThirdWheelClick);
    window.removeEventListener("scroll", this.onScroll);
    window.removeEventListener("wheel", this.detectMouseWheelDirection);
    window.removeEventListener(
      "DOMMouseScroll",
      this.detectMouseWheelDirection
    );
    // window.removeEventListener("scroll", this.onScroll);
    // WheelReact.clearTimeout();

    document.body.style.overflowY = "scroll";
    this.audio.pause();
    this.audio.src = "";
    this.audio.currentTime = 0;

    window.removeEventListener("touchstart", this.onTouchStart);
    window.removeEventListener("touchend", this.onTouchEnd);
    window.removeEventListener("touchmove", this.onTouchMove);
  }

  waitAfterTransition() {
    this.wait(2000);
  }

  componentDidUpdate(prevProps) {
    if (this.state.mounted && (this.props.changeSection != prevProps.changeSection)) {
      this.waitAfterTransition();

      if (this.props.changeSection == "1") {
        this.toSection1();
      } else if (this.props.changeSection == "21") {
        this.toSection21();
      } else if (this.props.changeSection == "22") {
        this.toSection22();
      } else if (this.props.changeSection == "23") {
        this.toSection23();
      } else if (this.props.changeSection == "24") {
        this.toSection24();
      } else if (this.props.changeSection == "3") {
        this.toSection3();
      } else if (this.props.changeSection == "4") {
        this.toSection4();
      } else if (this.props.changeSection == "5") {
        this.toSection5();
      }
    }
  }

  detectMouseWheelDirection(e) {
    if (this.state.disable_scroll) {
      // && !this.scrolling
      e.preventDefault();
      // this.scrolling = true;
      // setTimeout(()=> {
      //   this.scrolling = true
      // }, 1500)
    } else {
      return;
    }
    if (this.state.on_graph) {
      return;
    }
    var delta = null;
    var direction = false;
    if (!e) {
      // if the event is not provided, we get it from the window object
      e = window.event;
    }
    if (e.wheelDelta) {
      // will work in most cases
      delta = e.wheelDelta / 60;
    } else if (e.detail) {
      // fallback for Firefox
      delta = -e.detail / 2;
    }
    if (delta !== null) {
      // direction =
      //   if (delta <= 0 ?  : this.scrollToSection("next");
      if (delta <= 0) {
        this.next();
      } else {
        this.prev();
      }
    } else {
      delta = e.deltaY;
      if (delta > 0) {
        this.next();
      } else {
        this.prev();
      }
    }

    // return direction;
    // if
  }

  animation() {
    const loaderContainer = new PIXI.Container();
    const backgroundContainer = new PIXI.Container();
    const photoContainer = new PIXI.Container();
    const UIContainer = new PIXI.Container();

    const imagePhoto = new PIXI.Sprite(this.texturePhoto);
    const imageInterior = new PIXI.Sprite(this.textureInterior);
    const imageFurniture = new PIXI.Sprite(this.textrueFurniture);
    const imageWeatherTile = new PIXI.TilingSprite(this.textureSun, 0, 0);
    const imageGlass = new PIXI.Sprite(this.textureSun);
    const imageBottom = new PIXI.Sprite(this.textureBottom);
    const imageLeftGrad = new PIXI.Sprite(this.textureLeftGrad);
    const imageInfra = new PIXI.Sprite(this.textureInfra);
    const imageRoznaseci = new PIXI.Sprite(this.textureRoznaseci);

    const scaleOne = this.app.screen.width / imageInterior.width;
    const scaleTwo = this.app.screen.height / imageInterior.height;

    const scale = Math.max(scaleOne, scaleTwo);

    const photoColorMatrix = new PIXI.filters.ColorMatrixFilter();
    const filters = [photoColorMatrix];

    this.app.stage.addChild(photoContainer);
    this.app.stage.addChild(backgroundContainer);
    this.app.stage.addChild(UIContainer);
    this.app.stage.addChild(loaderContainer);

    imagePhoto.anchor.set(0.5);
    imagePhoto.x = this.app.screen.width / 2;
    imagePhoto.y = this.app.screen.height / 2;
    photoContainer.addChild(imagePhoto);

    imageWeatherTile.width = this.textureRain.width;
    imageWeatherTile.height = this.textureRain.height;
    imageWeatherTile.anchor.set(0.5);
    imageWeatherTile.x = this.app.screen.width / 2;
    imageWeatherTile.y = 0; //this.app.screen.height * 0.13;
    photoContainer.addChild(imageWeatherTile);

    imageGlass.anchor.set(0.5, 0);
    imageGlass.x = this.app.screen.width / 2;
    imageGlass.y = 0; //this.app.screen.height / 2;
    backgroundContainer.addChild(imageGlass);

    imageInterior.anchor.set(0.5);
    imageInterior.x = this.app.screen.width / 2;
    imageInterior.y = this.app.screen.height / 2;
    backgroundContainer.addChild(imageInterior);

    imageFurniture.anchor.set(0.5, 0.38);
    imageFurniture.x = this.app.screen.width / 1.88;
    imageFurniture.y = this.app.screen.height / 1.78; /// 1.52;
    backgroundContainer.addChild(imageFurniture);

    imageRoznaseci.anchor.set(0.5, 0);
    imageRoznaseci.x = this.app.screen.width / 2;
    imageRoznaseci.y = this.app.screen.height * 0.55; // / 1.85;
    backgroundContainer.addChild(imageRoznaseci);
    // imageInfra.alpha = 0;
    imageInfra.anchor.set(0.5, 0);
    imageInfra.x = this.app.screen.width / 2;
    imageInfra.y = this.app.screen.height / 1.85;
    backgroundContainer.addChild(imageInfra);

    imageBottom.anchor.set(0.5, 1);
    imageBottom.x = this.app.screen.width / 2;
    imageBottom.y = this.app.screen.height + imageBottom.height + 280;
    // backgroundContainer.addChild(imageBottom);
    imageLeftGrad.anchor.set(0, 0.5);
    imageLeftGrad.alpha = 0.7;
    imageLeftGrad.x = 0;
    imageLeftGrad.y = this.app.screen.height / 2;
    backgroundContainer.addChild(imageLeftGrad);

    var colorMatrix = [1, 0, 0, -0.5, 0, 1, 0, -0.5, 0, 0, 1, -0.5, 0, 0, 0, 1];

    const ticker = () => {
      imageGlass.alpha = this.alphaGlass !== null ? this.alphaGlass : 0;

      if (this.textureGlass !== null) {
        imageGlass.texture = this.textureGlass;
      }

      imageWeatherTile.alpha = this.alphaWeather;

      if (this.textureWeather !== null) {
        imageWeatherTile.texture = this.textureWeather;
      }

      photoColorMatrix.saturate(this.saturate, false);

      photoColorMatrix.brightness(this.night);

      if (this.state.weather == "snow") {
        photoColorMatrix.blackAndWhite(true);
      }

      imageWeatherTile.tilePosition.y += this.fallSpeed;

      imagePhoto.filters = filters;

      imagePhoto.scale.set(scale + scale * (this.defaultZoom - this.zoom.back));
      imageWeatherTile.scale.set(
        scale + scale * (this.defaultZoom - this.zoom.back)
      );
      imageGlass.scale.set(
        scale + scale * (this.defaultZoom - this.zoom.front)
      );
      imageInterior.scale.set(
        scale + scale * (this.defaultZoom - this.zoom.front) * 0.4
      );
      imageFurniture.scale.set(
        scale + scale * (this.defaultZoom - this.zoom.front)
      );
      imageInfra.scale.set(
        scale + scale * (this.defaultZoom - this.zoom.front)
      );
      imageRoznaseci.scale.set(
        scale + scale * (this.defaultZoom - this.zoom.front)
      );

      // if (this.state.showDesky) {
      imageFurniture.alpha = this.furnitureAlpha;
      // }
      // if (this.state.infra == "show") {
      imageInfra.alpha = this.infraAlpha;
      imageRoznaseci.alpha = this.roznaseciAlpha;
      // }
      // imageInfra.alpha = this.infraAlpha;
      // if (this.state.section2_bottom == "show") {
      //   imageBottom.alpha = this.bottomAlpha;
      //   backgroundContainer.addChild(imageBottom);
      // }
    };

    this.app.ticker.add(ticker);
  }

  changeWeather(actualWeather, newWeather) {
    let weatherChangeProgress = 0;

    if (actualWeather === "sun") {
      this.alphaGlass = 0;

      if (newWeather === "rain" || newWeather === "snow") {
        this.textureGlass = this.textureRain;
      }
    }

    const ticker = () => {
      weatherChangeProgress += 5;

      if (actualWeather === "sun") {
        if (weatherChangeProgress <= 100) {
          this.alphaGlass = weatherChangeProgress / 100;
        }
      }

      if (newWeather === "sun") {
        if (weatherChangeProgress <= 100) {
          this.alphaGlass = (100 - weatherChangeProgress) / 100;
          this.fallSpeed = weatherChangeProgress === 100 ? 0 : this.fallSpeed;
          this.alphaWeather =
            this.maxWeatherAlpha -
            weatherChangeProgress * (this.maxWeatherAlpha / 100);
        }

        if (actualWeather === "snow") {
          this.saturate = -1 + weatherChangeProgress / 100;
        }
      }

      if (newWeather === "rain") {
        if (weatherChangeProgress < 50 && actualWeather !== "sun") {
          this.alphaWeather =
            this.maxWeatherAlpha -
            weatherChangeProgress * (100 / 50) * (this.maxWeatherAlpha / 100);
        } else if (weatherChangeProgress === 50) {
          this.fallSpeed = 8;
          this.textureWeather = this.textureRain;
        } else {
          this.alphaWeather =
            weatherChangeProgress * (100 / 50) * (this.maxWeatherAlpha / 100) -
            this.maxWeatherAlpha;
        }

        if (actualWeather === "snow") {
         this.saturate = -1 + weatherChangeProgress / 100;
        }
      }

      if (newWeather === "snow") {
        if (weatherChangeProgress < 50 && actualWeather !== "sun") {
          this.alphaWeather =
            this.maxWeatherAlpha -
            weatherChangeProgress * (100 / 50) * (this.maxWeatherAlpha / 100);
        } else if (weatherChangeProgress === 50) {
          this.fallSpeed = 1;
          this.textureWeather = this.textureSnow;
        } else {
          this.alphaWeather =
            weatherChangeProgress * (100 / 50) * (this.maxWeatherAlpha / 100) -
            this.maxWeatherAlpha;
        }

        this.saturate = (weatherChangeProgress / 100) * -1;
      }

      if (weatherChangeProgress === 100) {
        this.app.ticker.remove(ticker);
      }
    };

    this.app.ticker.add(ticker);
  }

  hideFurniture() {
    this.setState({ showDesky: false, desky: "" });


    if (this.furnitureUpTicker) {
      this.furnitureUpTicker = false;
      this.app.ticker.remove(this.furnitureUp);
    }

    if (this.furnitureAlpha != 0 && !this.furnitureDownTicker) {
      this.furnitureDownTicker = true;
      this.app.ticker.add(this.furnitureDown);
    }
  }

  furnitureUp() {
    const step = 0.03;

    if (this.furnitureAlpha < 1) {
      this.furnitureAlpha += step;
    } else {
      this.furnitureAlpha = 1;
      this.app.ticker.remove(this.furnitureUp);
      this.furnitureUpTicker = false;
    }
  }

  furnitureDown() {
    const step = 0.03;

    if (this.furnitureAlpha > 0) {
      this.furnitureAlpha = this.furnitureAlpha - step;
    } else {
      this.furnitureAlpha = 0;
      this.app.ticker.remove(this.furnitureDown);
      this.furnitureDownTicker = false;
    }
  }

  showFurniture() {
    this.setState({ desky: "", showDesky: false });

    if (this.furnitureDownTicker) {
      this.furnitureDownTicker = false;
      this.app.ticker.remove(this.furnitureDown);
    }

    if (this.furnitureAlpha != 1 && !this.furnitureUpTicker) {
      this.furnitureUpTicker = true;
      this.app.ticker.add(this.furnitureUp);
    }
  }

  infraUp() {
    const step = 0.03;

    if (this.infraAlpha < 1) {
      this.infraAlpha = this.infraAlpha + step;
    } else {
      this.infraAlpha = 1;
      this.app.ticker.remove(this.infraUp);
      this.infraUpTicker = false;
    }
  }

  infraDown() {
    const step = 0.03;

    if (this.infraAlpha > 0) {
      this.infraAlpha = this.infraAlpha - step;
    } else {
      this.infraAlpha = 0;
      this.app.ticker.remove(this.infraDow);
      this.furnitureDownTicker = false;
    }
  }

  toggleInfra() {
    this.setState({
      infra: "show",
      showDesky: false,
      desky: "",
      section2_bottom: ""
    });

    if (this.infraDownTicker) {
      this.infraDownTicker = false;
      this.app.ticker.remove(this.infraDown);
    }

    if (this.infraAlpha != 1 && !this.infraUpTicker) {
      this.infraUpTicker = true;
      this.app.ticker.add(this.infraUp);
    }
  }

  hideInfra() {
    this.setState({
      infra: "",
      showDesky: false,
      desky: "",
      section2_bottom: ""
    });

    if (this.infraUpTicker) {
      this.infraUpTicker = false;
      this.app.ticker.remove(this.infraUp);
    }

    if (this.infraAlpha != 0 && !this.infraDownTicker) {
      this.infraDownTicker = true;
      this.app.ticker.add(this.infraDown);
    }
  }

  roznaseciUp() {
    const step = 0.03;

    if (this.roznaseciAlpha < 1) {
      this.roznaseciAlpha = this.roznaseciAlpha + step;
    } else {
      this.roznaseciAlpha = 1;
      this.app.ticker.remove(this.roznaseciUp);
      this.roznaseciUpTicker = false;
    }
  }

  roznaseciDown() {
    const step = 0.03;

    if (this.roznaseciAlpha > 0) {
      this.roznaseciAlpha = this.roznaseciAlpha - step;
    } else {
      this.roznaseciAlpha = 0;
      this.app.ticker.remove(this.roznaseciDown);
      this.roznaseciDownTicker = false;
    }
  }

  toggleRoznaseci() {
    this.hideFurniture();

    this.setState({
      roznaseci: "show",
      showDesky: false,
      desky: "",
      section2_bottom: ""
    });

    if (this.roznaseciDownTicker) {
      this.roznaseciDownTicker = false;
      this.app.ticker.remove(this.roznaseciDown);
    }

    if (this.roznaseciAlpha != 1 && !this.roznaseciUpTicker) {
      this.roznaseciUpTicker = true;
      this.app.ticker.add(this.roznaseciUp);
    }
  }

  hideRoznaseci() {
    this.showFurniture();

    this.setState({
      roznaseci: "",
      showDesky: false,
      desky: "",
      section2_bottom: "show"
    });

    if (this.roznaseciUpTicker) {
      this.roznaseciUpTicker = false;
      this.app.ticker.remove(this.roznaseciUp);
    }

    if (this.roznaseciAlpha != 0 && !this.roznaseciDownTicker) {
      this.roznaseciDownTicker = true;
      this.app.ticker.add(this.roznaseciDown);
    }
  }

  changeZoom() {
    const step = 0.001;

    const ticker = () => {
      if (this.zoom.front <= this.defaultZoom) {
        this.zoom.front += step;
        this.zoom.back += step / 2;
      } else {
        this.app.ticker.remove(ticker);
      }
    };

    this.app.ticker.add(ticker);
    setTimeout(() => {
      this.setState({
        section2_line1: "active",
        section2_line2: "",
        section2_line3: "",
        section2_line4: ""
      });
    }, 1000);
    setTimeout(() => {
      this.setState({
        section2_line1: "",
        section2_line2: "",
        section2_line3: "",
        section2_line4: ""
      });
    }, 2800);
    setTimeout(() => {
      this.setState({
        section2_line1: "",
        section2_line2: "active",
        section2_line3: "",
        section2_line4: "",
        section1_style: { display: "none" },
        scroll_count: 2
      });
    }, 3400);
    setTimeout(() => {
      this.setState({
        section2_line1: "",
        section2_line2: "",
        section2_line3: "",
        section2_line4: ""
      });
    }, 5400);
    setTimeout(() => {
      this.setState({
        section2_line1: "",
        section2_line2: "",
        section2_line3: "active",
        section2_line4: "",
        scroll_count: 1
      });
    }, 6000);
    setTimeout(() => {
      this.setState({
        section2_line1: "",
        section2_line2: "",
        section2_line3: "",
        section2_line4: "",
        scroll_count_disappear: true
      });
    }, 8200);
    setTimeout(() => {
      this.setState({
        section2_line1: "",
        section2_line2: "",
        section2_line3: "",
        section2_line4: "active part-one"
      });
    }, 8800);
    setTimeout(() => {
      this.setState({
        section2_line1: "",
        section2_line2: "",
        section2_line3: "",
        section2_line4: "active part-one-1"
      });
    }, 10600);
    setTimeout(() => {
      // this.changeBottomAlpha();
      this.setState({
        section2_line1: "",
        section2_line2: "",
        section2_line3: "",
        section2_line4: "active part-two",
        show_scroll: true,
        scroll_btn: "show",
        section2_bottom: "show",
        can_continue: true,
        mounted: true,
      });
    }, 11400);
  }

  changeAnimationSettingsHandler(event) {
    this.changeAnimationSettings(
      event.currentTarget.attributes.getNamedItem("data-settings").value,
      event.currentTarget.attributes.getNamedItem("data-value")
        ? event.currentTarget.attributes.getNamedItem("data-value").value
        : null
    );
  }

  changeAnimationSettingsCircleHandler(event) {
    // console.log(event);
  }

  changeAnimationSettings(dataSettings, value) {
    if (dataSettings === "weather") {
      this.changeWeather(this.state.weather, value);

      this.setState({
        weather: value
      });
    } else if (dataSettings === "zoom") {
      this.changeZoom();
    }
  }
  showGraphComfort(num) {
    this.setState({ graph_comfort: num });

    setTimeout(() => {
        this.setState({ didViewBox: num });
     }, 100);

  }

  showRoznaseci(num) {
    this.setState({ graph_roznaseci: num });

    setTimeout(() => {
      this.setState({ didViewBoxRoznaseci: num });

      console.log('STATUS', this.state.didViewBoxRoznaseci)
    }, 100);
  }
  showPokryti(num) {
    this.setState({ graph_pokryti: num });
    this.hideInfra();
  }
  showBezporuchy(num) {
    this.setState({ graph_bezporuchy: num });

    setTimeout(() => {
      this.setState({ didViewBoxBezporuchy: num });
    }, 100);
  }

  first_circle_changed() {
  }
  second_circle_changed() {
    if (this.state.second_circle == "active") {
      this.setState({ third_circle: "active", second_circle: "used" });
    }
  }
  third_circle_changed() {
    if (
      this.state.third_circle == "active" &&
      !this.state.started &&
      this.state.canStart
    ) {
      this.setState({ third_circle: "used", started: true });
      setTimeout(() => {
        this.setState({
          scale_out: true,
          showSound: false,
          show_animation_controls: true
        });
        this.changeZoom();
        this.muteSound();
      }, 1500);
      setTimeout(() => {
        this.setState({
          section1: "hidden"
        });
      }, 2000);
      setTimeout(() => {
        this.setState({
          section2: ""
        });
      }, 2500);
    }
  }
  tempChanged(temp) {
    setTimeout(() => {
      this.setState({ first_circle: "used", second_circle: "active" });
    }, 1000);

    /*
    setTimeout(() => {
      this.setState({ third_circle: "active", second_circle: "used" });
    }, 3000);
    */

    this.setState({ temp_value: temp });
  }
  timeChanged(time) {
    /*onMouseDown={e => {
                    if (this.state.third_circle == "active") {
                      this.setState({ canStart: true });
                    } else {
                      e.preventDefault();
                    }
                  }}
                  onTouchMove={e => {
                    if (this.state.third_circle == "active") {
                      this.setState({ canStart: true });
                    }
                  }}
                  onTouchEnd={this.third_circle_changed}
                  onMouseLeave={this.third_circle_changed}
                  onClick={this.third_circle_changed}
    */
    if (this.state.third_circle == "active") {
      this.setState({ canStart: true });
    }

    this.setState({ time_value: time });
    this.night = 1 - 0.8 * (time / 100);
  }

  isMacintosh() {
    return navigator.platform.indexOf("Mac") > -1;
  }
  skipSettings() {
    // 22 °C, sníh and 20:00
    if (!this.state.started) {
      this.night = 0.4;
      this.setState({
        first_circle: "used",
        second_circle: "used",
        third_circle: "used",
        temp_value: 22,
        time_value: 68,
        started: true,
      });
      setTimeout(() => {
        this.setState({
          scale_out: true,
          showSound: false,
          show_animation_controls: true
        });
        this.changeZoom();
        this.muteSound();
      }, 1500);
      setTimeout(() => {
        this.setState({
          section1: "hidden"
        });
      }, 2000);
      setTimeout(() => {
        this.setState({
          section2: ""
        });
      }, 2500);
    }
  }

  muteSound() {
    // if pause play
    if (this.audio.paused) {
      let promise = this.audio.play();

      if (promise !== undefined) {
        promise.catch(() => {}).then(() => {
          this.setState({ sound_muted: "ic_sound_on" });
        });
      }

    } else {
      // is playing
      this.audio.pause();
      this.setState({ sound_muted: "ic_sound_off" });
    }
  }

  next() {
    if (this.state.can_continue) {
      this.waitAfterTransition();

      if (this.state.anim_count == 1) {
        this.toSection21();
      } else if (this.state.anim_count == 2) {
        this.toSection22();
      } else if (this.state.anim_count == 3) {
        this.toSection23();
      } else if (this.state.anim_count == 4) {
        this.toSection24();
      } else if (this.state.anim_count == 5) {
        this.toSection3();
      } else if (this.state.anim_count == 6) {
        this.toSection4();
      } else if (this.state.anim_count == 7) {
        this.toSection5();
      }
    }

    return;
  }

  onScroll(e) {

    var scroll_check = this.section1.current.getBoundingClientRect().top;

    if (scroll_check < 0) {
      document.body.style.overflowY = "scroll";
    }
  }

  wait(time) {
    this.setState({
      can_continue: false
    });

    setTimeout(() => {
      this.setState({
        can_continue: true
      });
    }, time);
  }

  repeatSrovnani(e) {
    // this.setState({
    //   section3_1: "",
    //   section3_2: "",
    //   section3_3: "",
    //   section3_4: "",
    //   section3_5: "",
    //   section3_6: "active",
    //   section3_7: "",
    //   section3_trubky: "show",
    //   srovnani_intro: "active",
    //   section3_center: "center",
    //   srovnani_numbers: "active",
    //   srovnani_graph: "",
    //   srovnani_percentage1: "",
    //   srovnani_percentage2: "",
    //   srovnani_cas1: "",
    //   srovnani_cas2: "",
    //   srovnani_natop1: "",
    //   srovnani_natop2: "",
    //   anim_count: 7,
    //   section3_number: "",
    //   section3_wider: "wider",
    //   section3_bottom_glowing: "",
    //   section3_graphs: "",
    //   section3_budiky1: "",
    //   section3_budiky2: "",
    //   section3_budiky3: "",
    //   repeat_srovnani: "",
    //   columns_finished: false,
    //   didViewCountUp: false
    // });
    // setTimeout(() => {
    //   this.setState({
    //     srovnani_intro: "",
    //     srovnani_block: "active",
    //     can_continue: false
    //   });
    // }, 2000);
    // setTimeout(() => {
    //   this.setState({
    //     srovnani_graph: "active",
    //     section3_budiky1: "",
    //     section3_graphs: "active",
    //     section3_bottom_glowing: "show",
    //     didViewCountUp: true
    //   });
    // }, 2500);
    // this.wait(2000);
    this.setState({
      section3_1: "",
      section3_2: "",
      section3_3: "",
      section3_4: "",
      section3_5: "",
      section3_6: "active",
      section3_7: "hidden",
      section3_trubky: "show",
      srovnani_intro: "active",
      section3_center: "center",
      srovnani_numbers: "active",
      srovnani_graph: "",
      srovnani_percentage1: "",
      srovnani_percentage2: "",
      srovnani_cas1: "",
      srovnani_cas2: "",
      srovnani_natop1: "",
      srovnani_natop2: "",
      anim_count: 7,
      section3_number: "",
      section3_wider: "wider",
      section3_bottom_glowing: "",
      section3_graphs: "",
      section3_budiky1: "",
      section3_budiky2: "",
      section3_budiky3: "",
      repeat_srovnani: "",
      columns_finished: false,
      didViewCountUp: false,
      heatflow_time: false,
      cerpadlo_time: false,
      on_graph: true,
      scroll_hide: "",
      repeat: false
    });
    setTimeout(() => {
      this.setState({
        repeat: true
      });
    }, 100);
    setTimeout(() => {
      this.setState({
        srovnani_intro: "",
        srovnani_block: "active"
      });
    }, 2000);
    setTimeout(() => {
      this.setState({
        srovnani_graph: "active",
        section3_budiky1: "",
        section3_graphs: "active",
        section3_bottom_glowing: "show",
        didViewCountUp: true
      });
      setTimeout(() => {
        this.setState({
          heatflow_time: true
        });
      }, this.kratsi_graph * 1000);
      setTimeout(() => {
        this.setState({
          columns_finished: true,
          cerpadlo_time: true,
          on_graph: false
        });
      }, this.delsi_graph * 1000);
    }, 2500);
    setTimeout(() => {
      this.setState({
        srovnaniGraph: true
      });
    }, 2800);
    this.wait(9200);
  }

  returnClockTemp() {
    return (
      <CircleSlider
        className={
          (this.state.showLoader ? "" : "loaded") +
          " " +
          (this.state.isSafari ? "safari" : "")
        }
        onValueChange={this.tempChanged}
        type={"temp"}
        value={this.state.temp_value}
      />
    );
  }

  returnClockTime() {
    return (
      <CircleSlider
        type="time"
        data-settings="zoom"
        className="time-slider"
        onValueChange={this.timeChanged}
        value={this.state.time_value}
        onClick={this.changeAnimationSettingsHandler}
      />
    );
  }
  loaderLoaded(e) {
    this.setState({ loader_loaded: true });
  }

  toSection1() {
    this.setState(this.state1);

    this.zoom = {
      front: 0,
      back: 0
    };

    this.hideInfra();
    this.hideRoznaseci();

    this.animation_ended = false;
  }

  toSection21() {
    this.setState(this.state21);

    this.zoom = {
      front: 0.65,
      back: 0.325
    };

    this.hideInfra();
    this.hideRoznaseci();

    this.props.onSectionChange(2);

    this.animation_ended = false;
  }

  toSection22() {
    this.setState(this.state22);

    this.zoom = {
      front: 0.65,
      back: 0.325
    };

    this.hideInfra();
    this.hideRoznaseci();

    this.props.onSectionChange(3);

    this.animation_ended = false;
  }
  toSection23() {
    this.setState(this.state23);

    this.zoom = {
      front: 0.65,
      back: 0.325
    };

    this.hideInfra();
    this.toggleRoznaseci();

    this.props.onSectionChange(4);

    this.animation_ended = false;
  }
  toSection24() {
    this.setState(this.state24);

    this.zoom = {
      front: 0.65,
      back: 0.325
    };

    this.hideInfra();
    this.hideRoznaseci();

    this.props.onSectionChange(5);

    this.animation_ended = false;
  }
  toSection3() {
    this.setState(this.state3);

    this.zoom = {
      front: 0.65,
      back: 0.325
    };

    this.props.onSectionChange(6);

    this.hideInfra();
    this.hideRoznaseci();

    this.animation_ended = false;

    setTimeout(() => {
      this.setState({
        section3_5: "active"
      });
    }, 600);

    if (this.state.temp_value == 0 && this.state.time_value == 0) {
      this.setState({ temp_value: 22, time_value: 68 });
    }
  }
  toSection4() {
    this.setState(this.state4);

    if (this.state.temp_value == 0 && this.state.time_value == 0) {
      this.setState({ temp_value: 22, time_value: 68 });
    }

    this.zoom = {
      front: 0.65,
      back: 0.325
    };

    this.hideInfra();
    this.hideRoznaseci();

    this.props.onSectionChange(7);

    this.animation_ended = false;

    setTimeout(() => {
      this.setState({
        srovnani_intro: "",
        srovnani_block: "active"
      });
    }, 2000);

    setTimeout(() => {
      this.setState({
        srovnani_graph: "active",
        section3_budiky1: "",
        section3_graphs: "active",
        section3_bottom_glowing: "show",
        didViewCountUp: true
      });
      setTimeout(() => {
        this.setState({
          heatflow_time: true
        });
      }, this.kratsi_graph * 1000);
      setTimeout(() => {
        this.setState({
          columns_finished: true,
          cerpadlo_time: true,
          on_graph: false
        });
      }, this.delsi_graph * 1000);
    }, 2500);

    setTimeout(() => {
      this.setState({
        srovnaniGraph: true
      });
    }, 2800);

    //this.wait(9200);
  }

  toSection5() {
    this.setState(this.state5);

    this.zoom = {
      front: 0.65,
      back: 0.325
    };

    this.hideInfra();
    this.hideRoznaseci();

    this.props.onSectionChange(8);

    this.animation_ended = false;
  }

  render() {

    let className = 'inside-box';
    if (this.props.isActive) {
      className += ' menu-active';
    }

    return (
      <div
        // {...WheelReact.events}
        className="animation-wrapper"
        onScroll={this.onScroll}
      >
        <img
          className="control-load"
          src={config.paths.backgrounds + "texture-loader-transparent.svg"}
          alt=""
          onLoad={this.loaderLoaded}
          style={{ display: "none" }}
        />
        <div className="animation-content" ref={this.section1}>
        <Loader 
          showLoader={this.state.showLoader}
          loadingStatus={this.state.loadingStatus}
          loader_loaded={this.state.loader_loaded}
        />

          <div
            className={"animation-content-section " + this.state.section1}
            style={this.state.section1_style}
          >
            <span
              className={
                "section-subheader " +
                (this.state.fade_intro ? "" : "is-loaded ") +
                (this.state.scale_out ? "hidden" : "")
              }
            >
              <b>heated &gt;</b> {i18n.t('about.title')}
            </span>

            <h2
              className={
                "section-header " +
                (this.state.fade_intro ? "" : "is-loaded ") +
                (this.state.scale_out ? "hidden" : "")
              }
               dangerouslySetInnerHTML={{ __html: i18n.t('oldAbout.title') }}
            ></h2>

            <div
              className={
                "controllers " +
                (this.state.fade_intro ? "" : "is-loaded ") +
                (this.state.scale_out ? "hidden" : "")
              }
            >
              <div className={"circle-wrapper " + this.state.first_circle}>
                <div
                  className={
                    "circle-controller " + (this.state.isSafari ? "safari " : " ") + (this.state.first_circle)
                  }
                  data-controller="slider"
                  data-type="temp"
                  //onClick={this.first_circle_changed}
                >
                  {/* <CircleSlider
                    className={this.state.showLoader ? "" : "loaded"}
                    onValueChange={this.tempChanged}
                    type="temp"
                  /> */}
                  {this.state.show_clock ? this.returnClockTemp() : ""}
                </div>

                <div className="circle-title">{ i18n.t('oldAbout.box1.title') }</div>
                <div className="circle-text" dangerouslySetInnerHTML={{ __html: i18n.t('oldAbout.box1.text') }}></div>
              </div>
              <div className={"circle-wrapper " + this.state.second_circle}>
                <div
                  className={
                    "circle-controller " + (this.state.isSafari ? "safari" : "")
                  }
                  data-controller="touch"
                  onClick={this.second_circle_changed}
                >
                  <a
                    className={
                      "button icon-drops-" +
                      (this.state.weather === "rain"
                        ? "primary is-active"
                        : "white")
                    }
                    data-settings="weather"
                    data-value="rain"
                    onClick={this.changeAnimationSettingsHandler}
                  />
                  <a
                    className={
                      "button icon-sun-" +
                      (this.state.weather === "sun"
                        ? "primary is-active"
                        : "white")
                    }
                    data-settings="weather"
                    data-value="sun"
                    onClick={this.changeAnimationSettingsHandler}
                  />
                  <a
                    className={
                      "button icon-snowflake-" +
                      (this.state.weather === "snow"
                        ? "primary is-active"
                        : "white")
                    }
                    data-settings="weather"
                    data-value="snow"
                    onClick={this.changeAnimationSettingsHandler}
                  />
                  <div
                    className={
                      "h-block " +
                      (this.state.first_circle != "used" ? "blocked" : "")
                    }
                  ></div>
                </div>
                <div className="circle-title">{ i18n.t('oldAbout.box2.title') }</div>
                <div className="circle-text" dangerouslySetInnerHTML={{ __html: i18n.t('oldAbout.box2.text') }}></div>
              </div>
              <div className={"circle-wrapper " + this.state.third_circle}>
                <div
                  className={
                    "circle-controller " + (this.state.isSafari ? "safari " : " ") + (this.state.third_circle)
                  }
                  data-controller="slider"
                  data-type="time"
                  /*onMouseDown={e => {
                    if (this.state.third_circle == "active") {
                      this.setState({ canStart: true });
                    } else {
                      e.preventDefault();
                    }
                  }}
                  onTouchMove={e => {
                    if (this.state.third_circle == "active") {
                      this.setState({ canStart: true });
                    }
                  }}

                  onClick={this.third_circle_changed}*/

                //   onTouchEnd={this.third_circle_changed}
                //   onMouseUp={this.third_circle_changed}
                onMouseDown={() => this.setState({ thirdWheelClicked: true })}

                >
                  {/* <a
                    className="scale-out-btn"
                    data-settings="zoom"
                    onClick={this.changeAnimationSettingsHandler}
                  /> */}
                  {/* <CircleSlider
                    type="time"
                    data-settings="zoom"
                    className="time-slider"
                    onClick={this.changeAnimationSettingsHandler}
                  /> */}
                  {this.state.show_clock ? this.returnClockTime() : ""}
                  <div
                    className={
                      "h-block " +
                      (this.state.second_circle != "used" ? "blocked" : "")
                    }
                  ></div>
                </div>
                <div className="circle-title">{ i18n.t('oldAbout.box3.title') }</div>
                <div className="circle-text" dangerouslySetInnerHTML={{ __html: i18n.t('oldAbout.box3.text') }}></div>
              </div>
            </div>
          </div>
          <div
            className={
              "animation-content-section section2 " + this.state.section2
            }
          >
            <div className="section-header-cont">
              <h2
                className={
                  "section-header line-1 " +
                  this.state.section2_line1 +
                  (this.state.scale_out ? "" : "hidden")
                }
                 dangerouslySetInnerHTML={{ __html: i18n.t('oldAbout.claim1') }}
              ></h2>
              <h2
                className={
                  "section-header line-2 " +
                  this.state.section2_line2 +
                  (this.state.scale_out ? "" : "hidden")
                }
                 dangerouslySetInnerHTML={{ __html: i18n.t('oldAbout.claim2') }}
              ></h2>
              <h2
                className={
                  "section-header line-3 " +
                  this.state.section2_line3 +
                  (this.state.scale_out ? "" : "hidden")
                }
                 dangerouslySetInnerHTML={{ __html: i18n.t('oldAbout.claim3') }}
              ></h2>
              <h2
                className={
                  "section-header line-4 " +
                  this.state.section2_line4 +
                  (this.state.scale_out ? "" : "hidden")
                }
              >
                <span className="first-line">{ i18n.t('oldAbout.claim4') }</span>
                <br />
                <span className="second-line">
                  <strong>{ i18n.t('oldAbout.claim5') }</strong>
                </span>
              </h2>
            </div>
            {/* <img
              src={
                config.paths.backgrounds + "img_heatflow_section2_bg_bottom.jpg"
              }
              alt=""
              className={"section2-bottom-bg " + this.state.section2_bottom}
            /> */}
          </div>
          <div
            className={
              "animation-content-section section3 " + this.state.section3
            }
          >
            <div className={"section-count-left " + this.state.section3_number}>
              {this.state.anim_count < 6 ? this.state.anim_count - 1 : ""}
            </div>
            <div
              className={
                "section-data-cont " +
                this.state.section3_cont +
                " " +
                this.state.section3_wider +
                " " +
                this.state.section3_center
              }
            >
              <div className={"section-data-one " + this.state.section3_1}>
                {/* {this.state.graph_comfort == 0 ? ( */}
                <div
                  className={
                    "inside-box fade-show " + (this.state.didViewBox === 0 ? "show" : "")
                  }
                  style={
                    this.state.graph_comfort != 0 ? { display: "none" } : {}
                  }
                >
                  <img
                    src={config.paths.icons + "ic_klima.svg"}
                    alt=""
                    className={"section-data-icon"}
                  />
                  <h3 className="section-data-title" dangerouslySetInnerHTML={{ __html: i18n.t('oldAbout.section01.title') }}></h3>
                  <div className="section-data-text">
                    { i18n.t('oldAbout.section01.text') }
                  </div>
                </div>

                <div
                  className={
                    "inside-box fade-show " + (this.state.didViewBox === 1 ? "show" : "")
                  }
                  style={
                    this.state.graph_comfort != 1 ? { display: "none" } : {}
                  }
                >
                  <div className="section-data-block-heading">Heated.</div>
                  <img
                    src={
                      config.paths.images +
                      "img_heatflow_komfort_graph_heatflow_" + i18n.language + ".svg"
                    }
                    alt=""
                    className={"section-data-graph"}
                  />
                  <div className="section-data-text detailed">
                    { i18n.t('oldAbout.section01.text1') }
                  </div>
                </div>

                <div
                  className={
                    "inside-box fade-show " + (this.state.didViewBox === 2 ? "show" : "")
                  }
                  style={
                    this.state.graph_comfort != 2 ? { display: "none" } : {}
                  }
                >
                  <div className="section-data-block-heading">{ i18n.t('comparison.title02') }</div>
                  <img
                    src={
                      config.paths.images + "img_heatflow_komfort_graph_tc_" + i18n.language + ".svg"
                    }
                    alt=""
                    className={"section-data-graph"}
                  />

                  <div className="section-data-text detailed">
                    { i18n.t('oldAbout.section01.text2') }
                  </div>
                </div>

                {this.state.graph_comfort == 0 ? (
                  <div className="section-data-btn-wrapper">
                    <div
                      className="section-data-btn"
                      onClick={() => {
                        this.showGraphComfort(1);
                      }}
                    >
                      { i18n.t('oldAbout.section01.button') }
                    </div>
                  </div>
                ) : (
                  <div className="section-data-btn-wrapper">
                    <div
                      className="section-data-btn"
                      onClick={() => {
                        this.showGraphComfort(0);
                      }}
                    >
                      { i18n.t('general.back') }
                    </div>
                    {this.state.graph_comfort == 1 ? (
                      <div
                        className="section-data-btn"
                        onClick={() => {
                          this.showGraphComfort(2);
                        }}
                      >
                        { i18n.t('comparison.title02') }
                      </div>
                    ) : (
                      <div
                        className="section-data-btn"
                        onClick={() => {
                          this.showGraphComfort(1);
                        }}
                        >
                        Heated.
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className={"section-data-one " + this.state.section3_2}>

              <div className={"section-data-title fade-show "  + (this.state.didViewBoxRoznaseci === 0 ? "show" : "")}
                     style={
                       this.state.graph_roznaseci == 1 ? { display: "none" } : {}
                     }>
                  <img
                      src={config.paths.icons + "ic_roznaseci-new.svg"}
                      alt=""
                      className={"section-data-icon"}
                  />
                </div>

                <div className={"section-data-title fade-show "  + (this.state.didViewBoxRoznaseci === 1 ? "show" : "")}
                     style={
                       this.state.graph_roznaseci == 0 ? { display: "none" } : {}
                     }>
                  <img
                      src={config.paths.icons + "ic_roznaseci-new.svg"}
                      alt=""
                      className={"section-data-icon"}
                  />
                </div>


                  <h3 className={"section-data-title fade-show "  + (this.state.didViewBoxRoznaseci === 0 ? "show" : "")}
                      style={
                        this.state.graph_roznaseci == 1 ? { display: "none" } : {}
                      }
                     dangerouslySetInnerHTML={{ __html: i18n.t('oldAbout.section02.title') }}  
                    ></h3>

                <h3 className={"section-data-title fade-show "  + (this.state.didViewBoxRoznaseci === 1 ? "show" : "")}
                      style={
                        this.state.graph_roznaseci == 0 ? { display: "none" } : {}
                      }
                     dangerouslySetInnerHTML={{ __html: i18n.t('oldAbout.section02.title1') }}  
                    ></h3>


                  <div className={"section-data-text fade-show "  + (this.state.didViewBoxRoznaseci === 0 ? "show" : "")}
                       style={
                         this.state.graph_roznaseci == 1 ? { display: "none" } : {}
                       }>
                    { i18n.t('oldAbout.section02.text1') }
                  </div>

                <div className={"section-data-file fade-show "  + (this.state.didViewBoxRoznaseci === 1 ? "show" : "")}
                     style={
                       this.state.graph_roznaseci == 0 ? { display: "none" } : {}
                     }>
                    <div className="section-download-file">
                      <img src={config.paths.icons + "ic_pdf.svg"} alt="PDF" />
                      <a href={config.paths.files + "cemex-katalog.pdf"} className="download-file-link" download>
                        { i18n.t('oldAbout.section02.button1') }
                        <br />
                        <span style={{ color: "white" }}>& Cemex</span>
                      </a>
                    </div>
                    <div className="section-download-file">
                      <img src={config.paths.icons + "ic_pdf.svg"} alt="PDF" />
                      <a href={config.paths.files + "fermacell-katalog.pdf"} className="download-file-link" download>
                        { i18n.t('oldAbout.section02.button2') }
                        <br />
                        <span style={{ color: "white" }}>& fermacell</span>
                      </a>
                    </div>
                  </div>


                {this.state.graph_roznaseci == 0 ? (
                  <div
                    className="section-data-btn section-data-btn--d-none"
                    onClick={() => {
                      this.showRoznaseci(1);
                    }}
                  >
                    { i18n.t('oldAbout.section02.button') }
                  </div>
                ) : (
                  <div

                    className="section-data-btn"
                    onClick={() => {
                      this.showRoznaseci(0);
                    }}
                  >
                    { i18n.t('general.back') }
                  </div>
                )}
                {/* <div
                  className="section-data-btn"
                  onClick={() => {
                    this.showGraphComfort(1);
                  }}
                >
                  Certifikované skladby
                </div> */}
              </div>
              <div className={"section-data-one " + this.state.section3_3}>
                <img
                  src={config.paths.icons + "ic_celoploasnost.svg"}
                  alt=""
                  className={"section-data-icon"}
                />
                <h3 className="section-data-title" dangerouslySetInnerHTML={{ __html: i18n.t('oldAbout.section03.title') }}></h3>
                <div className="section-data-text">
                  { i18n.t('oldAbout.section03.text') }
                </div>
                {this.state.infra != 'show' ? (
                  <div
                    className="section-data-btn"
                    onClick={() => {
                      this.toggleInfra();
                    }}
                  >
                    { i18n.t('oldAbout.section03.button') }
                  </div>
                ) : (
                  <div
                    className="section-data-btn"
                    onClick={() => {
                      this.hideInfra();
                    }}
                  >
                    { i18n.t('general.back') }
                  </div>
                )}
              </div>
              <div className={"section-data-one " + this.state.section3_4}>
                <div
                    className={
                      "inside-box fade-show " + (this.state.didViewBoxBezporuchy === 0 ? "show" : "")
                    }
                  style={
                    this.state.graph_bezporuchy != 0 ? { display: "none" } : {}
                  }
                >
                  <img
                    src={config.paths.icons + "ic_system.svg"}
                    alt=""
                    className={"section-data-icon"}
                  />
                  <h3 className="section-data-title" dangerouslySetInnerHTML={{ __html: i18n.t('oldAbout.section04.title') }}></h3>
                  <div className="section-data-text">
                    { i18n.t('oldAbout.section04.text') }
                  </div>
                  <div
                    className="section-data-btn"
                    onClick={() => {
                      this.showBezporuchy(1);
                    }}
                  >
                    { i18n.t('oldAbout.section04.button') }
                  </div>
                </div>
                <div
                  className={
                    "inside-box h-full-height fade-show " + (this.state.didViewBoxBezporuchy === 1 ? "show" : "")
                  }
                  style={
                    this.state.graph_bezporuchy != 1 ? { display: "none" } : {}
                  }
                >
                  <img
                    src={config.paths.images + "img_heatflow_bezporuchy_" + i18n.language + ".svg"}
                    alt=""
                    className={"section-data-bezporuchy"}
                  />
                  <div
                    className="section-data-btn"
                    onClick={() => {
                      this.showBezporuchy(0);
                    }}
                  >
                    { i18n.t('general.back') }
                  </div>
                </div>
                {/* {this.state.graph_bezporuchy == 0 ? (
                  <div
                    className="section-data-btn"
                    onClick={() => {
                      this.showBezporuchy(1);
                    }}
                  >
                    { i18n.t('oldAbout.section04.button') }
                  </div>
                ) : (
                  <div
                    className="section-data-btn"
                    onClick={() => {
                      this.showBezporuchy(0);
                    }}
                  >
                    { i18n.t('general.back') }
                  </div>
                )} */}
              </div>
              <div className={"section-data-one " + this.state.section3_5}>
                <div className="section-data-block-heading">{ i18n.t('oldAbout.section05.title') }</div>
                <div className="section-data-one-block-wrapper">
                  <div className="section-data-one-block">
                    <img
                      src={config.paths.icons + "ic_heatflow1.png"}
                      alt=""
                      className={"section-data-block-icon"}
                    />
                    <h3 className="section-data-block-title" dangerouslySetInnerHTML={{ __html: i18n.t('oldAbout.section05.box1.title') }}></h3>
                    <div className="section-data-block-text">
                      { i18n.t('oldAbout.section05.box1.text') }
                    </div>
                  </div>
                  <div className="section-data-one-block">
                    <img
                      src={config.paths.icons + "ic_heatflow2.png"}
                      alt=""
                      className={"section-data-block-icon"}
                    />
                    <h3 className="section-data-block-title" dangerouslySetInnerHTML={{ __html: i18n.t('oldAbout.section05.box2.title') }}></h3>
                    <div className="section-data-block-text">
                      { i18n.t('oldAbout.section05.box2.text') }
                    </div>
                  </div>
                  <div className="section-data-one-block">
                    <img
                      src={config.paths.icons + "ic_heatflow3.png"}
                      alt=""
                      className={"section-data-block-icon"}
                    />
                    <h3 className="section-data-block-title" dangerouslySetInnerHTML={{ __html: i18n.t('oldAbout.section05.box3.title') }}></h3>
                    <div className="section-data-block-text">
                      { i18n.t('oldAbout.section05.box3.text') }
                    </div>
                  </div>
                  <div className="section-data-one-block">
                    <img
                      src={config.paths.icons + "ic_heatflow4.svg"}
                      alt=""
                      className={"section-data-block-icon"}
                    />
                    <h3 className="section-data-block-title" dangerouslySetInnerHTML={{ __html: i18n.t('oldAbout.section05.box4.title') }}></h3>
                    <div className="section-data-block-text">
                      { i18n.t('oldAbout.section05.box4.text') }
                    </div>
                  </div>
                </div>
              </div>

              {/* Porovnání */}
              <div className={"section-data-one " + this.state.section3_6}>
                <div
                  className={
                    "section-data-block-srovnani-intro " +
                    this.state.srovnani_intro
                  }
                >
                  <div className="srovnani-intro-heading" dangerouslySetInnerHTML={{ __html: i18n.t('oldAbout.section06.title') }}></div>
                  <div className="srovnani-intro-value">
                    {this.state.temp_value} <span>°C</span>
                  </div>
                </div>
                <div
                  className={
                    "section-data-srovnani-block " + this.state.srovnani_block
                  }
                >
                  <img
                    src={config.paths.icons + "ic_pokryti95_" + i18n.language + ".svg"}
                    alt=""
                    className={
                      "number-info-left " + this.state.srovnani_numbers
                    }
                  />
                  <img
                    src={config.paths.icons + "ic_pokryti5_" + i18n.language + ".svg"}
                    alt=""
                    className={
                      "number-info-right " + this.state.srovnani_numbers
                    }
                  />

                  <div className="section-data-block-heading">
                    { i18n.t('oldAbout.section06.box.title') }
                    <br />
                    <span
                      onClick={this.repeatSrovnani}
                      className={"repeat " + this.state.repeat_srovnani}
                    >
                      {i18n.t('general.playAgain')}
                    </span>
                  </div>

                  {/* Začátek bloku o srovnání nátopu  */}

                  {this.state.repeat ? (
                    <div className="section-data-block-wrapper">
                      <div
                        className={
                          "srovnani-row active " +
                          (this.state.section3_graphs == "active"
                            ? "top-row"
                            : "")
                        }
                      >
                        <div className="srovnani-column left-column">
                          <img
                            src={
                              config.paths.brands + "logo_ch.svg"
                            }
                            alt=""
                            className="srovnani-column-logo"
                          />
                        </div>
                        <div className="srovnani-column middle-column" />

                        <div className="srovnani-column right-column">
                          <div className="srovnani-column-title" dangerouslySetInnerHTML={{ __html: i18n.t('oldAbout.section06.box.itemTitle') }}></div>
                        </div>
                      </div>
                      <div
                        className={
                          "srovnani-row graph-row " + this.state.section3_graphs
                        }
                      >
                        <div className="srovnani-column left-column">
                          {/* <img
                        src={config.paths.brands + "logo_heatflow_orange.png"}
                        alt=""
                        className="srovnani-column-logo"
                      /> */}
                          <div className="heatflow-temp heatflow-top-temp">
                            <CountUp
                              start={19}
                              end={
                                this.state.srovnaniGraph
                                  ? this.state.temp_value
                                  : 0
                              }
                              useEasing={false}
                              duration={this.kratsi_graph}
                              ref={countUp => {
                                this.myCountUp = countUp;
                              }}
                            />
                            <span className="jednotka">°C</span>
                          </div>
                          <div
                            className={
                              "srovnani-graph-wrapper " +
                              this.state.srovnani_graph
                            }
                          >
                            <div className="srovnani-graph-placeholder" />
                            <div className="srovnani-graph" />
                          </div>
                          <div className="heatflow-temp heatflow-bottom-temp">
                            {/* {this.state.temp_value}
                             */}
                            <CountUp
                              start={17}
                              end={
                                this.state.srovnaniGraph
                                  ? this.state.temp_value + 2
                                  : 0
                              }
                              useEasing={false}
                              duration={this.kratsi_graph}
                              ref={countUp => {
                                this.myCountUp = countUp;
                              }}
                            />
                            <span className="jednotka">°C</span>
                          </div>
                        </div>
                        <div className="srovnani-column middle-column">
                          <div className="srovnani-column-middle-text top-text">
                            ····· { i18n.t('oldAbout.section06.box.label1') } ·····
                          </div>

                          <div className="srovnani-column-middle-time">
                            <span className="srovnani-column-middle-legend">
                              { i18n.t('oldAbout.section06.box.label2') }
                            </span>
                            <br />
                            <CountUp
                              start={0}
                              end={
                                this.state.didViewCountUp
                                  ? (this.state.temp_value - 17) * 75
                                  : 0
                              }
                              useEasing={false}
                              duration={this.delsi_graph}
                              ref={countUp => {
                                this.myCountUp = countUp;
                              }}
                            />
                            <sup>MIN</sup>
                            {"  "}
                            {this.state.cerpadlo_time ? (
                              <span>00</span>
                            ) : (
                              <CountUp
                                start={0}
                                end={
                                  this.state.didViewCountUp
                                    ? this.state.vytapeni_sec
                                    : 0
                                }
                                useEasing={false}
                                duration={this.delsi_graph}
                                ref={countUp => {
                                  this.myCountUp = countUp;
                                }}
                              />
                            )}
                            {/* <CountUp
                              start={0}
                              end={
                                this.state.didViewCountUp
                                  ? this.state.vytapeni_sec
                                  : 0
                              }
                              useEasing={false}
                              duration={this.delsi_graph}
                              ref={countUp => {
                                this.myCountUp = countUp;
                              }}
                            /> */}
                            <sup>SEC</sup>
                            <br />
                            <div className="srovnani-natopu">
                              <div
                                className={
                                  this.state.heatflow_time ? "show" : ""
                                }
                                style={{ color: "#73D15C" }}
                              >
                                HEATED - {(this.state.temp_value - 17) * 9}{" "}
                                min
                              </div>
                              <br />
                              <div
                                className={
                                  this.state.cerpadlo_time ? "show" : ""
                                }
                                style={{ color: "#C3281B" }}
                              >
                                { i18n.t('oldAbout.section06.box.label3') } -{" "}
                                {(this.state.temp_value - 17) * 75} min
                              </div>
                            </div>
                            {/* <span className="srovnani-column-middle-legend">
                            Délka nátopu
                          </span> */}
                            <div
                              className={
                                "section-data-btn " +
                                (this.state.columns_finished ? "show" : "")
                              }
                              onClick={() => {
                                this.repeatSrovnani();
                              }}
                            >
                              {i18n.t('general.playAgain')}
                            </div>
                          </div>
                          <div className="srovnani-column-middle-text bottom-text">
                            ····· { i18n.t('oldAbout.section06.box.label4') } ·····
                          </div>
                        </div>
                        <div className="srovnani-column right-column">
                          {/* <div className="srovnani-column-title">
                        tepelné
                        <br />
                        čerpadlo
                      </div> */}
                          <div className="heatflow-temp heatflow-top-right-temp">
                            {/* {this.state.temp_value} */}
                            <CountUp
                              start={17}
                              end={
                                this.state.srovnaniGraph
                                  ? this.state.temp_value
                                  : 0
                              }
                              useEasing={false}
                              duration={this.delsi_graph}
                              ref={countUp => {
                                this.myCountUp = countUp;
                              }}
                            />
                            <span className="jednotka">°C</span>
                          </div>
                          <div
                            className={
                              "srovnani-graph-wrapper " +
                              this.state.srovnani_graph
                            }
                          >
                            <div className="srovnani-graph-placeholder" />
                            <div className="srovnani-graph" />
                          </div>
                          <div className="heatflow-temp heatflow-bottom-right-temp">
                            {/* {this.state.temp_value} */}
                            <CountUp
                              start={17}
                              end={
                                this.state.srovnaniGraph
                                  ? this.state.temp_value + 6
                                  : 0
                              }
                              useEasing={false}
                              duration={this.delsi_graph}
                              ref={countUp => {
                                this.myCountUp = countUp;
                              }}
                            />
                            <span className="jednotka">°C</span>
                          </div>
                        </div>
                      </div>

                      {/* Srovnání budíky */}

                      <div
                        className={
                          "srovnani-row budiky " + this.state.section3_budiky1
                        }
                      >
                        <div className="srovnani-column left-column">
                          {/* <img
                          src={config.paths.icons + "ic_kryti.png"}
                          alt=""
                          className={"srovnani-budik"}
                        /> */}
                          {this.state.srovnani_cas1 != "" ? (
                            <div
                              className={
                                "circle-graph-controller " +
                                (this.state.isSafari ? "h-safari h-saf-1" : "")
                              }
                            >
                              <CircleGraph
                                type="time"
                                max_time={(this.state.temp_value - 17) * 75}
                                max_value={(this.state.temp_value - 17) * 26}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="srovnani-column middle-column" dangerouslySetInnerHTML={{ __html: i18n.t('oldAbout.section06.box.label5') }}></div>
                        <div className="srovnani-column right-column">
                          {this.state.srovnani_cas2 != "" ? (
                            <div
                              className={
                                "circle-graph-controller sedy " +
                                (this.state.isSafari ? "h-safari h-saf-2" : "")
                              }
                            >
                              <CircleGraph
                                type="time"
                                max_time={(this.state.temp_value - 17) * 75}
                                max_value={(this.state.temp_value - 17) * 75.18}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div
                        className={
                          "srovnani-row budiky " + this.state.section3_budiky2
                        }
                      >
                        <div className="srovnani-column left-column">
                          {this.state.srovnani_percentage1 != "" ? (
                            <div
                              className={
                                "circle-graph-controller " +
                                (this.state.isSafari ? "h-safari h-saf-3" : "")
                              }
                            >
                              <CircleGraph
                                type="percentage"
                                max_value={90} //{this.state.srovnani_percentage1}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="srovnani-column middle-column" dangerouslySetInnerHTML={{ __html: i18n.t('oldAbout.section06.box.label6') }}></div>
                        <div className="srovnani-column right-column">
                          {/* <img
                          src={config.paths.icons + "ic_kryti.png"}
                          alt=""
                          className={"srovnani-budik"}
                        /> */}
                          {this.state.srovnani_percentage2 != "" ? (
                            <div
                              className={
                                "circle-graph-controller sedy " +
                                (this.state.isSafari ? "h-safari h-saf-4" : "")
                              }
                            >
                              <CircleGraph type="percentage" max_value={16} />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div
                        className={
                          "srovnani-row budiky " + this.state.section3_budiky3
                        }
                      >
                        <div className="srovnani-column left-column ">
                          {this.state.section3_budiky3 == "active" ? (
                            <div
                              className={
                                "circle-graph-controller " +
                                (this.state.isSafari ? "h-safari h-saf-5" : "")
                              }
                            >
                              <CircleGraph
                                type="time_hod"
                                max_time={24}
                                max_value={23.5}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          {/* <img
                          src={config.paths.icons + "ic_blesk_orange.svg"}
                          alt=""
                          className={"srovnani-budik"}
                        />
                        <div className="blesk-text">
                          0,25
                          <br />
                          <span>kW</span>
                        </div> */}
                        </div>
                        <div className="srovnani-column middle-column" dangerouslySetInnerHTML={{ __html: i18n.t('oldAbout.section06.box.label7') }}></div>
                        <div className="srovnani-column right-column">
                          {this.state.section3_budiky3 == "active" ? (
                            <div
                              className={
                                "circle-graph-controller " +
                                (this.state.isSafari ? "h-safari h-saf-6" : "")
                              }
                            >
                              <CircleGraph
                                type="time_hod"
                                max_time={24}
                                max_value={9}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          {/* <img
                          src={config.paths.icons + "ic_blesk.svg"}
                          alt=""
                          className={"srovnani-budik"}
                        />
                        <div className="blesk-text">
                          0,92
                          <br />
                        </div>*/}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className={"section-data-one " + this.state.section3_7}>
                <div className="section-data-block-heading">
                  { i18n.t('oldAbout.section07.title') }
                </div>
                <div className="section-data-block-subheading">
                  { i18n.t('oldAbout.section07.subtitle') }
                </div>
                <div className="section-porovnani">
                  <div className="section-graph-top">
                    <div className="section-graph-top-block">
                      <span className="graph-data-value">140</span> m
                      <sup>2</sup>
                      <br />
                      <span className="graph-data-desc">{i18n.t('general.floorArea')}</span>
                    </div>
                    <div className="section-graph-top-block">
                      <span className="graph-data-value">6.8</span> kW
                      <br />
                      <span className="graph-data-desc">{i18n.t('general.heatLosses')}</span>
                    </div>
                    <div className="section-graph-top-block">
                      <span className="graph-data-value">15</span> {i18n.t('general.age', { count: 15 })}
                      <br />
                      <span className="graph-data-desc">{ i18n.t('oldAbout.section07.label3') }</span>
                    </div>
                  </div>
                  {/* <div className="section-graph-left">
                    <div className="icon-wrapper">
                      <img
                        className="porovnani-icon"
                        src={config.paths.icons + "ic_house_active_orange.png"}
                        alt=""
                      />
                      <br />
                      <span className="porovnani-data">Plocha stavby</span>
                      <br />
                      <span className="porovnani-value">
                        180 m<sup>2</sup>
                      </span>
                    </div>
                    <div className="icon-wrapper">
                      <img
                        className="porovnani-icon"
                        src={
                          config.paths.icons + "ic_house_non_active_orange.png"
                        }
                        alt=""
                      />
                      <br />
                      <span className="porovnani-data">{i18n.t('general.heatLosses')}</span>
                      <br />
                      <span className="porovnani-value">5,4 kW</span>
                    </div>
                    <div className="icon-wrapper">
                      <img
                        className="porovnani-icon"
                        src={config.paths.icons + "ic_timer_orange.png"}
                        alt=""
                      />
                      <br />
                      <span className="porovnani-data">Délka porovnání</span>
                      <br />
                      <span className="porovnani-value">15 let</span>
                    </div>
                  </div> */}
                  <div className="section-side-graph-wrapper">
                    <div className="section-side-graph"></div>
                  </div>
                  {/* <img
                    className="section-side-graph"
                    src={config.paths.images + "img_heatflow_porovnani.svg"}
                    alt={i18n.t('reference.caseStudy')}
                  /> */}
                  {/* <div className="section-side-graph" /> */}
                </div>

                <div className="section-porovnani-link">
                  <Link
                    className="porovnani-link"
                    to={{
                      pathname: config.pages.porovnani_vytapeni.url,
                      state: {
                        type: 1
                      }
                    }}
                  >
                    {i18n.t('general.compareHeat')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <img
            src={
              config.paths.backgrounds + "img_heatflow_section2_bg_bottom.png"
            }
            alt=""
            className={"section3-bottom-bg " + this.state.section2_bottom}
          />
          <img
            src={config.paths.backgrounds + "texture-desky.png"}
            alt=""
            className={"bg-desky " + this.state.desky}
          />
          {/* <img
            src={config.paths.backgrounds + "texture-infra.png"}
            alt=""
            className={"bg-desky " + this.state.infra}
          /> */}
          <img
            src={config.paths.backgrounds + "img_bottom_bg_trubky.png"}
            alt=""
            className={
              "section3-bottom-bg-trubky " + this.state.section3_trubky
            }
          />
          <img
            src={config.paths.backgrounds + "img_bottom_bg_glowing.png"}
            alt=""
            className={
              "section3-bottom-bg-glowing " + this.state.section3_bottom_glowing
            }
          />
          <img
            src={config.paths.backgrounds + "img_bottom_bg_roznaseci.png"}
            alt=""
            className={
              "section3-bottom-bg-roznaseci " + this.state.section3_2
            }
          />
          <div
            className={
              "scroll-cont " +
              this.state.scroll_btn +
              " " +
              this.state.scroll_hide
            }
            style={this.props.mouse}
          >
            <div
              className={
                "skip-settings " +
                (this.state.show_animation_controls ? "hide" : "")
              }
              onClick={this.skipSettings}
            >
              { i18n.t('general.skip') }
            </div>
            {/* <span className={"scroll-continue " + this.state.scroll_continue}>
              POKRAČOVAT
            </span> */}
            <div
              className={
                "animation-controls " +
                (this.state.show_animation_controls ? "show" : "")
              }
            >
              <div
                className={
                  "scroll-indicator " + (this.state.show_scroll ? "show" : "")
                }
                onClick={() => {
                  if (this.state.show_scroll) {
                    this.next();
                  }
                }}
              >
                {this.state.show_scroll ? (
                  <img
                    className={
                      "scroll-icon " + (this.state.show_scroll ? "show" : "")
                    }
                    src={config.paths.icons + "ic_scroll_grey.png"}
                    alt="Pokračovat"
                  />
                ) : (
                  <div
                    className={
                      "scroll-count-down " +
                      (this.state.scroll_count_disappear ? "hide" : "")
                    }
                  >
                    {this.state.scroll_count}
                  </div>
                )}
                {/* <img
                  className="scroll-icon"
                  src={config.paths.icons + "ic_scroll_grey.png"}
                  alt="Pokračovat"
                /> */}
              </div>
              {/* <div className="sound-indicator"> */}
              <img
                className="sound-icon"
                src={config.paths.icons + this.state.sound_muted + ".svg"}
                alt="Zvuk"
                onClick={this.muteSound}
              />
              {/* </div> */}
            </div>
            {/* <img
              className="scroll-icon"
              src={config.paths.icons + "ic_scroll_grey.png"}
              alt="Pokračovat"
            /> */}
          </div>
        </div>
      </div>
    );
  }
}

export default AnimationAbout;
