import React from 'react';

import AnimationAboutNumber from './AnimationAboutNumber';
import AnimationAboutSheet from './AnimationAboutSheet';
import AnimationAboutContainer from './AnimationAboutContainer';
import AnimationAboutHeaderSplit from './AnimationAboutHeaderSplit';
import AnimationAboutHeaderTitle from './AnimationAboutHeaderTitle';
import AnimationAboutHeaderText from './AnimationAboutHeaderText';
import AnimationAboutTile from './AnimationAboutTile';
import AnimationAboutSheetBackground from './AnimationAboutSheetBackground';
import AnimationAboutSlider from './AnimationAboutSlider';
import AnimationAboutSimpleCarousel from './AnimationAboutSimpleCarousel';
import FAQ from './FAQ';

import config from '../../../../config/config.json';

import i18n from '../i18n';

function AnimationAboutSection6()
{
    return (
        <section className="AnimationAboutSection AnimationAboutSection6">
            <AnimationAboutNumber>06</AnimationAboutNumber>
            <AnimationAboutSheet kartoteka="tmava" theme="dark">
                <AnimationAboutContainer width="header">
                    <div>
                        <AnimationAboutHeaderTitle>{i18n.t('about.section06.title')}</AnimationAboutHeaderTitle>
                        <AnimationAboutHeaderText>
                            <span
                                className="AnimationAbout--a1"
                                dangerouslySetInnerHTML={{ __html: i18n.t('about.section06.text') }}
                            ></span>
                        </AnimationAboutHeaderText>
                    </div>
                </AnimationAboutContainer>
            </AnimationAboutSheet>
            <AnimationAboutSheet smallPaddingBottom kartoteka="bila" kartotekaPushed theme="light">
                <AnimationAboutContainer>
                    <FAQ />
                </AnimationAboutContainer>
            </AnimationAboutSheet>
        </section>
    );
}

export default AnimationAboutSection6;
