import React from 'react';

function AnimationAboutTile({children, reversed, single}) {
    return (
        <article className={[
            'AnimationAboutTile',
            reversed && 'AnimationAboutTile--reversed',
            single && 'AnimationAboutTile--single',
        ].filter((el) => el).join(' ')}>{children}</article>
    );
}

export default AnimationAboutTile;
