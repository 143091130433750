import * as React from "react";
import { Link } from "react-router-dom";
import config from "../../../../config/config.json";
import { getHighlightedReferences, getReferences } from "../api.js";
import i18n from "../i18n";

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: "",
      co_je_menu: { display: "none" },
      nabidka_menu: { display: "none" },
      porovnani_menu: { display: "none" },
      reference_menu: { display: "none" },
      prezentace_menu: { display: "none" },
      menu_0: "",
      menu_1: "",
      menu_2: "",
      menu_3: "",
      menu_4: "",
      projekty: 0,
      domy: 0,
      zpetna_vazba: 0,
      highlighted_data: [],
      navbar_link_co_je: "",
      navbar_link_nabidka: "",
      navbar_link_porovnani: "",
      navbar_link_reference: "",
      navbar_link_prezentace: "",
      mobile_menu: { display: "none" }
    };

    this.menuItemClick = this.menuItemClick.bind(this);
    this.setDomy = this.setDomy.bind(this);
    this.setProjekty = this.setProjekty.bind(this);
    this.setZpetnaVazba = this.setZpetnaVazba.bind(this);
    this.navbarItemMouseEnter = this.navbarItemMouseEnter.bind(this);
    this.menuMouseLeave = this.menuMouseLeave.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.changeLanguage = this.changeLanguage.bind(this);

    this.setHighlightedData = this.setHighlightedData.bind(this);
  }

  componentDidMount() {
    getReferences.request("Rodinné domy", this.setDomy);
    getReferences.request("Zajímavé projekty", this.setProjekty);
    getReferences.request("Zpětná vazba zákazníka", this.setZpetnaVazba);
    getHighlightedReferences.request(this.setHighlightedData);
  }
  componentWillUnmount() {
    if (getReferences.running) {
      getReferences.cancel();
    }
    if (getHighlightedReferences.running) {
      getHighlightedReferences.cancel();
    }
  }
  changeLanguage(lang) {
    localStorage.setItem('lang', lang);
    location.reload();
  }
  setZpetnaVazba(response) {
    // console.log(response.data);
    // const rodinne_domy = response.data.filter(reference => {
    //   if (reference.category == "Rodinné domy") {
    //     return reference;
    //   }
    // });
    // const zajimave_projekty = response.data.filter(reference => {
    //   if (reference.category == "Zajímavé projekty") {
    //     return reference;
    //   }
    // });

    this.setState({
      zpetna_vazba: response.meta.total
    });
  }
  setDomy(response) {
    // console.log(response.data);
    // const rodinne_domy = response.data.filter(reference => {
    //   if (reference.category == "Rodinné domy") {
    //     return reference;
    //   }
    // });
    // const zajimave_projekty = response.data.filter(reference => {
    //   if (reference.category == "Zajímavé projekty") {
    //     return reference;
    //   }
    // });

    this.setState({
      domy: response.meta.total
    });
  }
  setProjekty(response) {
    // console.log(response.data);
    // const rodinne_domy = response.data.filter(reference => {
    //   if (reference.category == "Rodinné domy") {
    //     return reference;
    //   }
    // });
    // const zajimave_projekty = response.data.filter(reference => {
    //   if (reference.category == "Zajímavé projekty") {
    //     return reference;
    //   }
    // });

    this.setState({
      projekty: response.meta.total
    });
  }
  setHighlightedData(response) {
    // console.log(response.data);

    this.setState({
      highlighted_data: response.data
    });
  }

  navbarItemMouseEnter(menu) {
    // this.setState({ expanded: "show" });

    if (menu == 0) {
      this.setState({
        navbar_link_co_je: "selected",
        navbar_link_nabidka: "",
        navbar_link_porovnani: "",
        navbar_link_reference: "",
        navbar_link_prezentace: "",
        // co_je_menu: { display: "flex" },
        // nabidka_menu: { display: "none" },
        // reference_menu: { display: "none" },
        // porovnani_menu: { display: "none" }
      });
    } else if (menu == 1) {
      this.setState({
        navbar_link_co_je: "",
        navbar_link_nabidka: "",
        navbar_link_reference: "",
        navbar_link_porovnani: "selected",
        navbar_link_prezentace: "",
        // nabidka_menu: { display: "none" },
        // co_je_menu: { display: "none" },
        // reference_menu: { display: "none" },
        // porovnani_menu: { display: "flex" }
      });
    } else if (menu == 2) {
      this.setState({
        navbar_link_co_je: "",
        navbar_link_nabidka: "selected",
        navbar_link_porovnani: "",
        navbar_link_reference: "",
        navbar_link_prezentace: "",
        // porovnani_menu: { display: "none" },
        // nabidka_menu: { display: "flex" },
        // reference_menu: { display: "none" },
        // co_je_menu: { display: "none" }
      });
    } else if (menu == 3) {
      this.setState({
        navbar_link_co_je: "",
        navbar_link_nabidka: "",
        navbar_link_porovnani: "",
        navbar_link_reference: "selected",
        navbar_link_prezentace: "",
        // porovnani_menu: { display: "none" },
        // nabidka_menu: { display: "none" },
        // reference_menu: { display: "flex" },
        // co_je_menu: { display: "none" }
      });
    } else if (menu == 4) {
      this.setState({
        navbar_link_co_je: "",
        navbar_link_nabidka: "",
        navbar_link_porovnani: "",
        navbar_link_reference: "",
        navbar_link_prezentace: "selected",
        // porovnani_menu: { display: "none" },
        // nabidka_menu: { display: "none" },
        // reference_menu: { display: "flex" },
        // co_je_menu: { display: "none" }
      });
    } else {
      this.setState({
        // expanded: "",
        navbar_link_co_je: "",
        navbar_link_nabidka: "",
        navbar_link_porovnani: "",
        navbar_link_reference: "",
        navbar_link_prezentace: "",
        // porovnani_menu: { display: "none" },
        // nabidka_menu: { display: "none" },
        // reference_menu: { display: "none" },
        // co_je_menu: { display: "none" }
      });
    }
  }

  menuMouseLeave() {
    this.props.onCloseMenu();
    this.setState({
      navbar_link_co_je: "",
      navbar_link_nabidka: "",
      navbar_link_porovnani: "",
      navbar_link_reference: "",
      navbar_link_prezentace: "",
      menu_0: "",
      menu_1: "",
      menu_2: "",
      menu_3: "",
      menu_4: "",
    });
    setTimeout(() => {
      this.setState({
        expanded: "",
        co_je_menu: { display: "none" },
        nabidka_menu: { display: "none" },
        reference_menu: { display: "none" },
        porovnani_menu: { display: "none" },
        prezentace_menu: { display: "none" },
      });
    }, 500);
  }

  closeMenu() {
    this.props.onCloseMenu();
    this.setState({
      navbar_link_co_je: "",
      navbar_link_nabidka: "",
      navbar_link_porovnani: "",
      navbar_link_reference: "",
      navbar_link_prezentace: "",
      menu_0: "",
      menu_1: "",
      menu_2: "",
      menu_3: "",
      menu_4: "",
    });
    setTimeout(() => {
      this.setState({
        expanded: "",
        co_je_menu: { display: "none" },
        nabidka_menu: { display: "none" },
        reference_menu: { display: "none" },
        porovnani_menu: { display: "none" },
        prezentace_menu: { display: "none" },
      });
    }, 500);
  }
  menuItemClick(item) {
    this.setState({ expanded: "show" });
    if (item == 0) {
      this.setState({
        co_je_menu: { display: "flex" },
        nabidka_menu: { display: "none" },
        reference_menu: { display: "none" },
        porovnani_menu: { display: "none" },
        prezentace_menu: { display: "none" },
      });
      setTimeout(() => {
        this.props.onOpenMenu();
        this.setState({
          menu_0: "hidden",
          menu_1: "",
          menu_2: "",
          menu_3: "",
          menu_4: "",
        });
      }, 40);
    } else if (item == 1) {
      this.setState({
        nabidka_menu: { display: "none" },
        co_je_menu: { display: "none" },
        reference_menu: { display: "none" },
        porovnani_menu: { display: "flex" },
        prezentace_menu: { display: "none" },
      });
      setTimeout(() => {
        this.props.onOpenMenu();
        this.setState({
          menu_0: "",
          menu_1: "hidden",
          menu_2: "",
          menu_3: "",
          menu_4: "",
        });
      }, 40);
    } else if (item == 2) {
      this.setState({
        porovnani_menu: { display: "none" },
        nabidka_menu: { display: "flex" },
        reference_menu: { display: "none" },
        co_je_menu: { display: "none" },
        prezentace_menu: { display: "none" },
      });
      setTimeout(() => {
        this.props.onOpenMenu();
        this.setState({
          menu_0: "",
          menu_1: "",
          menu_2: "hidden",
          menu_3: "",
          menu_4: "",
        });
      }, 40);
    } else if (item == 3) {
      this.setState({
        porovnani_menu: { display: "none" },
        nabidka_menu: { display: "none" },
        reference_menu: { display: "flex" },
        co_je_menu: { display: "none" },
        prezentace_menu: { display: "none" },
      });
      setTimeout(() => {
        this.props.onOpenMenu();
        this.setState({
          menu_0: "",
          menu_1: "",
          menu_2: "",
          menu_3: "hidden",
          menu_4: "",
        });
      }, 40);
    } else if (item == 4) {
      this.setState({
        porovnani_menu: { display: "none" },
        nabidka_menu: { display: "none" },
        reference_menu: { display: "none" },
        co_je_menu: { display: "none" },
        prezentace_menu: { display: "flex" },
      });
      setTimeout(() => {
        this.props.onOpenMenu();
        this.setState({
          menu_0: "",
          menu_1: "",
          menu_2: "",
          menu_3: "",
          menu_4: "hidden",
        });
      }, 40);
    } else {
      this.props.onCloseMenu();
      this.setState({
        navbar_link_co_je: "",
        navbar_link_nabidka: "",
        navbar_link_porovnani: "",
        navbar_link_reference: "",
        navbar_link_prezentace: "",
        menu_0: "",
        menu_1: "",
        menu_2: "",
        menu_3: "",
        menu_4: "",
      });
      setTimeout(() => {
        this.setState({
          expanded: "",
          co_je_menu: { display: "none" },
          nabidka_menu: { display: "none" },
          reference_menu: { display: "none" },
          porovnani_menu: { display: "none" },
          prezentace_menu: { display: "none" },
        });
      }, 500);
    }
  }

  toggleMobileMenu(open) {
    if (open) {
      this.setState({ mobile_menu: { display: "block" } });
    } else {
      this.setState({ mobile_menu: { display: "none" } });
    }
  }
  handleClickPreventDefault(e) {
    e.preventDefault();
  }

  render() {
    // Background image in Co je heatflow menu
    var menu_co_je_heatflow1 =
      config.paths.images + "co_je_heatflow_menu_1.jpg";
    var co_je_block_style = {
      background: "url(" + menu_co_je_heatflow1 + ")",
      backgroundPosition: "center center",
      backgroundSize: "cover",
    };
    var menu_co_je_heatflow2 =
      config.paths.images + "co_je_heatflow_menu_2.jpg";
    var co_je_block_style_2 = {
      background: "url(" + menu_co_je_heatflow2 + ")",
      backgroundPosition: "center center",
      backgroundSize: "cover",
    };

    // Background images in Porovnani vytápění menu

    var menu_porovnani_1 =
      config.paths.backgrounds + "img_porovnani_vytapeni_bg_1.jpg";
    var menu_porovnani_style_1 = {
      background: "url(" + menu_porovnani_1 + ")",
      backgroundPosition: "100% 100%",
      backgroundSize: "cover"
    };
    var menu_porovnani_2 =
      config.paths.backgrounds + "img_porovnani_vytapeni_bg_2.jpg";
    var menu_porovnani_style_2 = {
      background: "url(" + menu_porovnani_2 + ")",
      backgroundPosition: "100% 100%",
      backgroundSize: "cover"
    };
    var menu_porovnani_3 =
      config.paths.backgrounds + "img_porovnani_vytapeni_bg_3.jpg";
    var menu_porovnani_style_3 = {
      background: "url(" + menu_porovnani_3 + ")",
      backgroundPosition: "100% 100%",
      backgroundSize: "cover"
    };

    // Background images in Cenová nabídka menu

    var menu_nabidka_1 = config.paths.images + "img_menu_cenova_nabidka1.png";
    var menu_nabidka_style_1 = {
      background: "url(" + menu_nabidka_1 + ")",
      backgroundPosition: "100% 100%",
      backgroundSize: "auto 90%",
      backgroundRepeat: "no-repeat"
    };
    var menu_nabidka_2 = config.paths.images + "img_menu_cenova_nabidka2.png";
    var menu_nabidka_style_2 = {
      background: "url(" + menu_nabidka_2 + ")",
      backgroundPosition: "100% 100%",
      backgroundSize: "auto 90%",
      backgroundRepeat: "no-repeat"
    };
    var menu_nabidka_3 = config.paths.images + "img_menu_cenova_nabidka3.png";
    var menu_nabidka_style_3 = {
      background: "url(" + menu_nabidka_3 + ")",
      backgroundPosition: "100% 100%",
      backgroundSize: "auto 90%",
      backgroundRepeat: "no-repeat"
    };

    reference_style_1;
    // Background image in Reference menu
    var menu_reference1 = config.paths.images + "img_menu_ref_1.jpg";
    var reference_style_1 = {
      background: "url(" + menu_reference1 + ")",
      backgroundPosition: "100% 100%",
      backgroundSize: "cover"
    };
    var menu_reference2 = config.paths.images + "img_menu_ref_2.jpg";
    var reference_style_2 = {
      background: "url(" + menu_reference2 + ")",
      backgroundPosition: "100% 100%",
      backgroundSize: "cover"
    };
    var menu_reference3 = config.paths.images + "img_menu_ref_3.jpg";
    var reference_style_3 = {
      background: "url(" + menu_reference3 + ")",
      backgroundPosition: "100% 100%",
      backgroundSize: "cover"
    };

    var menu_prezentace1 = config.paths.images + "menu_prezentace_1.jpg";
    var prezentace_style_1 = {
      background: "url(" + menu_prezentace1 + ")",
      backgroundPosition: "100% 100%",
      backgroundSize: "cover"
    };

    var menu_prezentace2 = config.paths.images + "menu_prezentace_2.jpg";
    var prezentace_style_2 = {
      background: "url(" + menu_prezentace2 + ")",
      backgroundPosition: "100% 100%",
      backgroundSize: "cover"
    };

    var menu_prezentace3 = config.paths.images + "menu_prezentace_3.jpg";
    var prezentace_style_3 = {
      background: "url(" + menu_prezentace3 + ")",
      backgroundPosition: "100% 100%",
      backgroundSize: "cover"
    };

    // Mobile menu background

    var mobile_menu =
      config.paths.backgrounds + "img_mobile_menu_background.png";
    var mobile_menu_style = {
      background: "url(" + mobile_menu + ")",
      //   backgroundPosition: "90% 100%",
      backgroundSize: "100% 100%",
      backgroundRepeat: "no-repeat"
    };

    return (
      <div className="menu">
        <div
          className="menu-desktop"
          onMouseLeave={() => {
            this.menuMouseLeave();
          }}
        >
          <div className="menu-navbar">
            <div className="menu-logo-wrapper">
              <Link to={config.pages.home.url} className="menu-logo-link">
                <img
                  className="menu-logo "
                  src={config.paths.brands + "logo_ch.svg"}
                  alt="heated"
                />
              </Link>
            </div>
            <div className="menu-links-wrapper">
              <Link
                to={config.pages.home.url}
                className={
                  "menu-link " +
                  (window.location.pathname == "/" ? "selected" : "")
                }
                onMouseEnter={() => {
                  this.navbarItemMouseEnter(4);
                }}
              >
                {i18n.t('general.intro')}
              </Link>
              <a
                to={config.pages.heatflow.url}
                //href="#0"
                className={
                  "menu-link " +
                  this.state.navbar_link_co_je +
                  " " +
                  (window.location.pathname ==
                    config.pages.pripadova_studie.url ||
                  window.location.pathname == config.pages.heatflow.url
                    ? "selected"
                    : "")
                }
                onMouseEnter={() => {
                  this.navbarItemMouseEnter(0);
                }}
                onClick={() => {
                  this.menuItemClick(0);
                }}
              >
                {i18n.t('about.title')}
              </a>
              {/* <a
                // to={config.pages.porovnani_vytapeni.url}
                //href="#0"
                className={
                  "menu-link " +
                  this.state.navbar_link_porovnani +
                  " " +
                  (window.location.pathname ==
                  config.pages.porovnani_vytapeni.url
                    ? "selected"
                    : "")
                }
                onMouseEnter={() => {
                  this.navbarItemMouseEnter(1);
                }}
                onClick={() => {
                  this.menuItemClick(1);
                }}
              >
                {i18n.t('general.heatComparison')}
              </a> */}
              <a
                // to={config.pages.cenova_nabidka.url}
                //href="#0"
                className={
                  "menu-link " +
                  this.state.navbar_link_nabidka +
                  " " +
                  (window.location.pathname ==
                    config.pages.cenova_nabidka.url ||
                  window.location.pathname ==
                    config.pages.posouzeni_projektu.url ||
                  window.location.pathname == config.pages.tepelny_audit.url
                    ? "selected"
                    : "")
                }
                onMouseEnter={() => {
                  this.navbarItemMouseEnter(2);
                }}
                onClick={() => {
                  this.menuItemClick(2);
                }}
              >
                {i18n.t('general.priceCalc')}
              </a>
              <a
                // to={config.pages.ref_projekty.url}
                //href="#0"
                className={
                  "menu-link " +
                  this.state.navbar_link_reference +
                  " " +
                  (window.location.pathname == config.pages.ref_projekt.url ||
                  window.location.pathname == config.pages.ref_projekty.url ||
                  window.location.pathname == config.pages.ref_domy.url
                    ? "selected"
                    : "")
                }
                onMouseEnter={() => {
                  this.navbarItemMouseEnter(3);
                }}
                onClick={() => {
                  this.menuItemClick(3);
                }}
              >
                {i18n.t('general.realization')}
              </a>
              {/* <Link
                to={config.pages.heatflow.url}
                className={
                  "menu-link " +
                  this.state.navbar_link_co_je +
                  " " +
                  (window.location.pathname ==
                    config.pages.pripadova_studie.url ||
                  window.location.pathname == config.pages.heatflow.url
                    ? "selected"
                    : "")
                }
                onMouseEnter={() => {
                  this.navbarItemMouseEnter(0);
                }}
                onClick={() => {
                  this.menuItemClick(0);
                }}
              >
                {i18n.t('about.title')}
              </Link>
              <Link
                to={config.pages.porovnani_vytapeni.url}
                className={
                  "menu-link " +
                  this.state.navbar_link_porovnani +
                  " " +
                  (window.location.pathname ==
                  config.pages.porovnani_vytapeni.url
                    ? "selected"
                    : "")
                }
                onMouseEnter={() => {
                  this.navbarItemMouseEnter(1);
                }}
                onClick={() => {
                  this.menuItemClick(1);
                }}
              >
                {i18n.t('general.compareHeat')}
              </Link>
              <Link
                to={config.pages.cenova_nabidka.url}
                className={
                  "menu-link " +
                  this.state.navbar_link_nabidka +
                  " " +
                  (window.location.pathname ==
                    config.pages.cenova_nabidka.url ||
                  window.location.pathname ==
                    config.pages.posouzeni_projektu.url ||
                  window.location.pathname == config.pages.tepelny_audit.url
                    ? "selected"
                    : "")
                }
                onMouseEnter={() => {
                  this.navbarItemMouseEnter(2);
                }}
                onClick={() => {
                  this.menuItemClick(2);
                }}
              >
                {i18n.t('general.priceCalc')}
              </Link>
              <Link
                to={config.pages.ref_projekty.url}
                className={
                  "menu-link " +
                  this.state.navbar_link_reference +
                  " " +
                  (window.location.pathname == config.pages.ref_projekt.url ||
                  window.location.pathname == config.pages.ref_projekty.url ||
                  window.location.pathname == config.pages.ref_domy.url
                    ? "selected"
                    : "")
                }
                onMouseEnter={() => {
                  this.navbarItemMouseEnter(3);
                }}
                onClick={() => {
                  this.menuItemClick(3);
                }}
              >
                {i18n.t('reference.title')}
              </Link> */}
              <a
                className={
                  "menu-link " +
                  this.state.navbar_link_prezentace +
                  " " +
                  ((window.location.pathname == config.pages.sluzba.url || window.location.pathname == config.pages.heatflow_slides.url)
                    ? "selected"
                    : "")
                }
                onMouseEnter={() => {
                  this.navbarItemMouseEnter(4);
                }}
                onClick={() => {
                  this.menuItemClick(4);
                }}
              >
                {i18n.t('general.prezentation')}
              </a>

              {i18n.language === 'cs' ? 
              <Link
                to={config.pages.kontakty.url}
                className={
                  "menu-link " +
                  (window.location.pathname == "/kontakty" ? "selected" : "")
                }
                onMouseEnter={() => {
                  this.navbarItemMouseEnter(5);
                }}
              >
                {i18n.t('contact.title')}
              </Link> : 
                <a href="tel:+420602702417" className="menu-link is-tel">+420 602 702 417</a>}

              <div className="lang-switcher">
                <button className={`lang-item${i18n.language === 'cs' ? ' selected' : ''}`} onMouseUp={() => this.changeLanguage('cs')}>CZ</button>
                <button className={`lang-item${i18n.language === 'sk' ? ' selected' : ''}`}  onMouseUp={() => this.changeLanguage('sk')}>SK</button>
              </div>
            </div>
          </div>
          <div
            className={
              "menu-expand " +
              this.state.expanded +
              " " +
              this.state.menu_0 +
              " " +
              this.state.menu_1 +
              " " +
              this.state.menu_2 +
              " " +
              this.state.menu_3 +
              " " +
              this.state.menu_4
            }
            onClick={this.closeMenu}
          >
            <div
              id="menu-exp-co-je"
              className={"menu-expand-content " + this.state.menu_0}
              style={this.state.co_je_menu}
            >
              <div className="co-je-col" style={co_je_block_style}>
                <Link to={config.pages.heatflow.url} className="co-je-link">
                  <h3>
                    {i18n.t('general.aboutProduct')}
                    <br />
                    heated.
                  </h3>

                  {/* <img
                    className="co-je-menu-white-arrow"
                    src={config.paths.icons + "ic_arrow_right_white.png"}
                    alt={i18n.t('general.aboutProduct') + ' heatflow'}
                  /> */}
                  <div className="reference-arrow-container">
                    <img
                      className="reference-menu-arrow"
                      src={config.paths.icons + "ic_arrow_right_white.png"}
                      alt={i18n.t('general.aboutProduct') + ' heatflow'}
                    />
                    <img
                      className="reference-menu-arrow-orange"
                      src={config.paths.icons + "ic_arrow_right_orange.png"}
                      alt={i18n.t('general.aboutProduct') + ' heatflow'}
                    />
                  </div>
                </Link>
              </div>
              <div className="co-je-col" style={co_je_block_style_2}>
                <Link
                  to={config.pages.pripadova_studie.url}
                  className="co-je-link"
                >
                  <h3 dangerouslySetInnerHTML={{ __html: i18n.t('reference.caseStudyBr') }}></h3>
                  <div className="reference-arrow-container">
                    <img
                      className="reference-menu-arrow"
                      src={config.paths.icons + "ic_arrow_right_white.png"}
                      alt={i18n.t('reference.houses')}
                    />
                    <img
                      className="reference-menu-arrow-orange"
                      src={config.paths.icons + "ic_arrow_right_orange.png"}
                      alt="Prohlédnout případovou studii"
                    />
                  </div>
                </Link>
              </div>
              <div className="co-je-col">
                <div className="co-je-col-five">
                  <Link
                    to={config.pages.cenova_nabidka.url}
                    className="co-je-link"
                  >
                    {i18n.t('general.freePriceCalc')}
                    <img
                      className="co-je-menu-vert-arrow"
                      src={config.paths.icons + "ic_next_gray.png"}
                      alt={i18n.t('general.freePriceCalc')}
                    />
                  </Link>
                </div>
                <div className="co-je-col-five">
                  <Link
                    to={config.pages.posouzeni_projektu.url}
                    className="co-je-link"
                  >
                    { i18n.t('assessment.freeAsessment') }
                    <img
                      className="co-je-menu-vert-arrow"
                      src={config.paths.icons + "ic_next_gray.png"}
                      alt={ i18n.t('assessment.freeAsessment') }
                    />
                  </Link>
                </div>

                <div className="co-je-col-five">
                  <Link to={config.pages.ke_stazeni.url} className="co-je-link">
                    {i18n.t('download.breadcrumb')}
                    <img
                      className="co-je-menu-vert-arrow"
                      src={config.paths.icons + "ic_next_gray.png"}
                      alt={i18n.t('download.breadcrumb')}
                    />
                  </Link>
                </div>
                <div className="co-je-col-five">
                <Link to={config.pages.konfigurator.url} className="co-je-link">
                    {i18n.t('general.floorComposition')}
                    <img
                      className="co-je-menu-vert-arrow"
                      src={config.paths.icons + "ic_next_gray.png"}
                      alt={i18n.t('general.floorComposition')}
                    />
                  </Link>
                </div>
              </div>
            </div>

            <div
              id="menu-exp-porovnani"
              className={"menu-expand-content " + this.state.menu_1}
              style={this.state.porovnani_menu}
            >
              <div className="porovnani-col" style={menu_porovnani_style_2}>
                <Link
                  to={{
                    pathname: config.pages.porovnani_vytapeni.url,
                    state: {
                      type: 1
                    }
                  }}
                  className="porovnani-link"
                >
                  <div className="orange heatflow-vs">Heated. VS.</div>
                  <h3>{ i18n.t('comparison.title02') } <span className="gray"> + {i18n.t('general.hotWaterDistr')}</span></h3>

                  <div className="reference-arrow-container">
                    <img
                      className="reference-menu-arrow"
                      src={config.paths.icons + "ic_arrow_right_white.png"}
                      alt={i18n.t('reference.houses')}
                    />
                    <img
                      className="reference-menu-arrow-orange"
                      src={config.paths.icons + "ic_arrow_right_orange.png"}
                      alt={i18n.t('reference.houses')}
                    />
                  </div>
                </Link>
              </div>
              <div className="porovnani-col" style={menu_porovnani_style_3}>
                <Link
                  to={{
                    pathname: config.pages.porovnani_vytapeni.url,
                    state: {
                      type: 0
                    }
                  }}
                  className="porovnani-link"
                >
                  <div className="orange heatflow-vs">Heated. VS.</div>
                  <h3>{ i18n.t('comparison.title01') }</h3>

                  <div className="reference-arrow-container">
                    <img
                      className="reference-menu-arrow"
                      src={config.paths.icons + "ic_arrow_right_white.png"}
                      alt={i18n.t('reference.houses')}
                    />
                    <img
                      className="reference-menu-arrow-orange"
                      src={config.paths.icons + "ic_arrow_right_orange.png"}
                      alt={i18n.t('reference.houses')}
                    />
                  </div>
                </Link>
              </div>
              <div className="porovnani-col" style={menu_porovnani_style_1}>
                <Link
                  to={{
                    pathname: config.pages.porovnani_vytapeni.url,
                    state: {
                      type: 2
                    }
                  }}
                  className="porovnani-link"
                >
                  <div className="orange heatflow-vs">Heated. VS.</div>
                  <h3>{ i18n.t('comparison.title03Short') } <span className="gray"> + {i18n.t('general.hotWaterDistr')}</span></h3>

                  <div className="reference-arrow-container">
                    <img
                      className="reference-menu-arrow"
                      src={config.paths.icons + "ic_arrow_right_white.png"}
                      alt={i18n.t('reference.houses')}
                    />
                    <img
                      className="reference-menu-arrow-orange"
                      src={config.paths.icons + "ic_arrow_right_orange.png"}
                      alt={i18n.t('reference.houses')}
                    />
                  </div>
                </Link>
              </div>
            </div>

            <div
              id="menu-exp-nabidka"
              className={"menu-expand-content " + this.state.menu_2}
              style={this.state.nabidka_menu}
            >
              <div className="nabidka-col">
                <Link
                  to={config.pages.cenova_nabidka.url}
                  className="nabidka-link"
                  style={menu_nabidka_style_1}
                >
                  <h3 dangerouslySetInnerHTML={{ __html: i18n.t('general.freeOffer') }}></h3>
                  <span className="uvodni" style={{ width: "70%" }}>
                    {i18n.t('general.freeOfferText')}
                  </span>
                  <div className="nabidka-arrow-container">
                    <img
                      className="nabidka-menu-arrow"
                      src={config.paths.icons + "ic_arrow_right_white.png"}
                      alt="Chci cenovou nabídku"
                    />
                    <img
                      className="nabidka-menu-arrow-orange"
                      src={config.paths.icons + "ic_arrow_right_orange.png"}
                      alt="Chci cenovou nabídku"
                    />
                  </div>
                </Link>
              </div>
              <div className="nabidka-col">
                <Link
                  to={config.pages.posouzeni_projektu.url}
                  className="nabidka-link"
                  style={menu_nabidka_style_2}
                >
                  <h4 dangerouslySetInnerHTML={{ __html: i18n.t('assessment.freeAsessmentBr') }}></h4>
                  <p dangerouslySetInnerHTML={{ __html: i18n.t('assessment.freeAsessmentText') }}></p>
                  <div className="nabidka-arrow-container">
                    <img
                      className="nabidka-menu-arrow"
                      src={config.paths.icons + "ic_arrow_right_white.png"}
                      alt="Porovnání s tepelným čerpadlem"
                    />
                    <img
                      className="nabidka-menu-arrow-orange"
                      src={config.paths.icons + "ic_arrow_right_orange.png"}
                      alt="Porovnání s tepelným čerpadlem"
                    />
                  </div>
                </Link>
              </div>
              <div className="nabidka-col">
                <Link
                  to={config.pages.tepelny_audit.url}
                  className="nabidka-link"
                  style={menu_nabidka_style_3}
                >
                  <h4 dangerouslySetInnerHTML={{ __html: i18n.t('audit.titleBr') }}></h4>
                  <p dangerouslySetInnerHTML={{ __html: i18n.t('audit.titleText') }}></p>
                  <div className="nabidka-arrow-container">
                    <img
                      className="nabidka-menu-arrow"
                      src={config.paths.icons + "ic_arrow_right_white.png"}
                      alt="Tepelný audit"
                    />
                    <img
                      className="nabidka-menu-arrow-orange"
                      src={config.paths.icons + "ic_arrow_right_orange.png"}
                      alt="Tepelný audit"
                    />
                  </div>
                </Link>
              </div>
            </div>
            <div
              id="menu-exp-reference"
              className={"menu-expand-content " + this.state.menu_3}
              style={this.state.reference_menu}
            >
              <div className="reference-col" style={reference_style_3}>
                <Link to={config.pages.ref_databaze.url} className="reference-link">
                  <h3 dangerouslySetInnerHTML={{ __html: i18n.t('general.customerFeedbackBr') }}></h3>

                  {/* <img
                    className="reference-menu-white-arrow"
                    src={config.paths.icons + "ic_arrow_right_white.png"}
                    alt={i18n.t('reference.houses')}
                  /> */}
                  <div className="reference-arrow-container">
                    <img
                      className="reference-menu-arrow"
                      src={config.paths.icons + "ic_arrow_right_white.png"}
                      alt={i18n.t('reference.houses')}
                    />
                    <img
                      className="reference-menu-arrow-orange"
                      src={config.paths.icons + "ic_arrow_right_orange.png"}
                      alt={i18n.t('reference.houses')}
                    />
                  </div>
                  <p className="reference-pocet">
                    <span className="reference-cislo">
                      {this.state.zpetna_vazba}
                    </span>{" "}
                    {i18n.t('general.selectedProjects')}
                  </p>
                </Link>
              </div>
              <div className="reference-col" style={reference_style_1}>
                <Link
                  to={config.pages.ref_projekty.url}
                  className="reference-link"
                >
                  <h3 dangerouslySetInnerHTML={{ __html: i18n.t('reference.interestingProjectsBr') }}></h3>

                  {/* <img
                    className="reference-menu-white-arrow"
                    src={config.paths.icons + "ic_arrow_right_orange.png"}
                    alt={i18n.t('reference.interestingProjects')}
                  /> */}
                  <div className="reference-arrow-container">
                    <img
                      className="reference-menu-arrow"
                      src={config.paths.icons + "ic_arrow_right_white.png"}
                      alt={i18n.t('reference.interestingProjects')}
                    />
                    <img
                      className="reference-menu-arrow-orange"
                      src={config.paths.icons + "ic_arrow_right_orange.png"}
                      alt={i18n.t('reference.interestingProjects')}
                    />
                  </div>
                  <p className="reference-pocet">
                    <span className="reference-cislo">
                      {this.state.projekty}
                    </span>{" "}
                    {i18n.t('general.selectedProjects')}
                  </p>
                </Link>
              </div>
              <div className="reference-col" style={reference_style_2}>
                <Link to={config.pages.ref_domy.url} className="reference-link">
                  <h3 dangerouslySetInnerHTML={{ __html: i18n.t('reference.housesBr') }}></h3>
                  {
                    /*
                    <span className="uvodni">
                    {i18n.t('reference.portfolioShowcaseHouses')}
                  </span>
                    */
                  }


                  {/* <img
                    className="reference-menu-white-arrow"
                    src={config.paths.icons + "ic_arrow_right_white.png"}
                    alt={i18n.t('reference.houses')}
                  /> */}
                  <div className="reference-arrow-container">
                    <img
                      className="reference-menu-arrow"
                      src={config.paths.icons + "ic_arrow_right_white.png"}
                      alt={i18n.t('reference.houses')}
                    />
                    <img
                      className="reference-menu-arrow-orange"
                      src={config.paths.icons + "ic_arrow_right_orange.png"}
                      alt={i18n.t('reference.houses')}
                    />
                  </div>
                  <p className="reference-pocet">
                    <span className="reference-cislo">{this.state.domy}</span>{" "}
                    {i18n.t('general.selectedProjects')}
                  </p>
                </Link>
              </div>
            </div>
            <div
              id="menu-exp-prezentace"
              className={"menu-expand-content " + this.state.menu_4}
              style={this.state.prezentace_menu}
            >
              <div className="reference-col" style={prezentace_style_1}>
                <Link to={config.pages.heatflow_slides.url} className="reference-link">
                  <h3>
                    {i18n.t('general.howHeats')}
                    <br />
                    heated.
                  </h3>
                  <div className="reference-arrow-container">
                    <img
                      className="reference-menu-arrow"
                      src={config.paths.icons + "ic_arrow_right_white.png"}
                      alt="Jak topí Heated."
                    />
                    <img
                      className="reference-menu-arrow-orange"
                      src={config.paths.icons + "ic_arrow_right_orange.png"}
                      alt="Jak topí Heated."
                    />
                  </div>
                </Link>
              </div>
              <div className="reference-col" style={prezentace_style_2}>
                <Link
                  to={config.pages.sluzba.url}
                  className="reference-link"
                >
                  <h3>
                    {i18n.t('general.service')}
                    <br />
                    heated.
                  </h3>
                  <div className="reference-arrow-container">
                    <img
                      className="reference-menu-arrow"
                      src={config.paths.icons + "ic_arrow_right_white.png"}
                      alt="Služba Heated."
                    />
                    <img
                      className="reference-menu-arrow-orange"
                      src={config.paths.icons + "ic_arrow_right_orange.png"}
                      alt="Služba Heated."
                    />
                  </div>
                </Link>
              </div>
              <div className="reference-col reference-col--disabled" style={prezentace_style_3}>
                <Link to={config.pages.vzorovy_dum.url} className="reference-link">
                  <h3 dangerouslySetInnerHTML={{ __html: i18n.t('modelHouse.titleBr') }}></h3>
                  <div className="reference-arrow-container">
                    <img
                      className="reference-menu-arrow"
                      src={config.paths.icons + "ic_arrow_right_white.png"}
                      alt={i18n.t('modelHouse.title')}
                    />
                    <img
                      className="reference-menu-arrow-orange"
                      src={config.paths.icons + "ic_arrow_right_orange.png"}
                      alt={i18n.t('modelHouse.title')}
                    />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export { Menu };
