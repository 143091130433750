const obj = {}

const requireModule = require.context(
    './', false, /^((?!index).)*\.js$/
)

requireModule.keys().forEach(moduleName => {
    const data = requireModule(moduleName)
    const name = moduleName.replace(/^\.\//, '').replace(/\.\w+$/, '')
    obj[name] = data.default
})

export default obj
