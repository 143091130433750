import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { getFaq, postQuestion } from "../api.js";

import LoadingData from '../components/LoadingData';

import HeatflowButton from './HeatflowButton';
import FAQItem from './FAQItem';

import config from '../../../../config/config.json';

import i18n from '../i18n';

function FAQ()
{
    const [faqs, setFaqs] = useState([]);

    const [email, setEmail] = useState('');

    const [question, setQuestion] = useState('');

    const [submited, setSubmited] = useState(false);

    const [ok, setOk] = useState(false);

    const setData = useCallback((response) => {
        setFaqs(response);
    }, [setFaqs]);

    useEffect(() => {
        getFaq.request(setData);

        return () => {
            getFaq.cancel();
        };
    }, [setData]);

    const questionSubmited = useCallback((response) => {
        if (response.status < 299 && response.status >= 200) {
            setEmail('');
            setQuestion('');
            setOk(true);
        } else {
            setSubmited(false);
        }
    }, [setSubmited, setEmail, setQuestion, setOk]);

    const submit = useCallback((event) => {
        event.preventDefault();

        setSubmited(true);

        postQuestion.request({email: email, question:question}, questionSubmited);
    }, [questionSubmited, email, question, setSubmited]);

    const setInput = useCallback((event) => {
        event.target.name === 'email' ? setEmail(event.target.value) : setQuestion(event.target.value);
    }, [setEmail, setQuestion]);

    return (
        <div className="FAQ">
            {faqs.map((f) => {
                return (
                    <FAQItem key={f.id} title={f.question}>
                        <div dangerouslySetInnerHTML={{__html: f.answer}} />
                    </FAQItem>
                );
            })}
            <FAQItem grayTitle initialyCollapsed={false} openLabel={i18n.t('faq.write')} closeLabel={i18n.t('faq.write')} title={i18n.t('faq.formTitle')}>
                {!ok && (
                    <>
                        <form className="FAQ__form" onSubmit={submit}>
                            <textarea disabled={submited} value={question} onChange={setInput} required placeholder={i18n.t('general.yourMessage') + '...'} name="question" />
                            <div className="FAQ__form__field">
                                <label htmlFor="email">{ i18n.t('general.emailForAnswer') }<sup>*</sup></label>
                                <input disabled={submited} value={email} onChange={setInput} required type="email" placeholder="@" name="email" />
                            </div>
                            <div style={{textAlign: 'center'}}>
                                <HeatflowButton type="submit" disabled={submited}>
                                    {i18n.t('general.toSend')}
                                </HeatflowButton>
                                {submited && <div style={{marginTop: '2vw'}}>
                                    <LoadingData label={i18n.t('general.sendingForm')} loading={submited} />
                                </div>}
                            </div>
                            <div style={{textAlign: 'center', marginTop: '.75rem'}}>
                                <Link className="FAQ__form__terms" to={config.pages.privacy.url}>{i18n.t('general.sandingPrivaciPolicyAgreement')}</Link>
                            </div>
                        </form>
                    </>
                )}
                {ok && (
                    <div style={{padding: '1.5em 0 0 0', lineHeight: 1.85, fontWight: 700, fontSize: '2vw', textAlign: 'center'}}>
                        { i18n.t('general.questionSentThankYou') }
                    </div>
                )}
            </FAQItem>
        </div>
    );
}

export default FAQ;
