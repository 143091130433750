import * as React from "react";
import * as ReactDOM from "react-dom";
import CircularSlider from "react-circular-slider-bar";
import config from "../../../../config/config.json";

import i18n from '../i18n';

class CircleGraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: 0,
      value: 0,
      value_to_share: 0,
      angle: 270
    };
    this.animate = 0;
  }

  componentDidMount() {
    const component = ReactDOM.findDOMNode(this);
    var max = this.props.max_value;

    //console.log(max);
    var out_of = this.props.max_time;
    if (this.props.type == "time" || this.props.type == "time_hod") {
      max = (this.props.max_value / out_of) * 100;
    }

    this.setState({
      width: component.clientWidth
    });
    this.animate = setInterval(() => {
      const val = this.state.value;

      if (max - 0.8 > val) {
        this.setState({ value: val + 0.5 });
      }
    }, 5);

    setTimeout(() => {
      clearInterval(this.animate);
      this.setState({ value: max - 0.5 });
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.animate);
  }

  render() {
    return (
      <div className="circle-graph-wrapper">
        <CircularSlider
          r={this.state.width / 2}
          initialAngle={this.state.angle}
          value={this.state.value}
        />

        {(() => {
          var type = this.props.type;
          if (type == "time") {
            return (
              <div className="time">
                {/*{this.props.max_value}*/}
                {Math.ceil((this.state.value / 100) * this.props.max_value)}
                <br />

                <span className="jednotka">min</span>
              </div>
            );
          } else if (type == "time_hod") {
            return (
              <div className="time">
                {Math.ceil((this.state.value / 100) * this.props.max_value)}
                {/*{this.props.max_value}*/}
                <span className="jednotka">h</span>
              </div>
            );
          } else if (type == "percentage") {
            return (
                <div className="percentage">
                  {Math.ceil(this.state.value)}
                  <span className="jednotka">%</span>
                </div>
            );
          }
          else if (type == "nasobek") {
            return (
              <div className="nasobek">
                <div className="graph-value">9</div>
                <span className="jednotka iks">x</span>
                <br />
                <div className="graph-description" dangerouslySetInnerHTML={{ __html: i18n.t('home.comparison.item1') }}></div>
              </div>
            );
          } else if (type == "plocha") {
            return (
              <div className="plocha">
                <div className="graph-value">90</div>
                <span className="jednotka">%</span>
                <br />
                <div className="graph-description" dangerouslySetInnerHTML={{ __html: i18n.t('home.comparison.item2') }}></div>
              </div>
            );
          } else if (type == "naklady") {
            return (
              <div className="naklady">
                <div className="graph-value">0</div>
                <span className="jednotka">Kč</span>
                <br />
                <div className="graph-description" dangerouslySetInnerHTML={{ __html: i18n.t('home.comparison.item3') }}></div>
              </div>
            );
          } else {
          }
        })()}

        {/* {this.props.type == "time" ? (
          <div className="time">
            {Math.ceil((this.state.value / 100) * 60)}
            <br />

            <span className="jednotka">min</span>
          </div>
        ) : (
          <div className="percentage">
            {Math.ceil(this.state.value)}
            <span className="jednotka">%</span>
          </div>
        )} */}
      </div>
    );
  }
}

export { CircleGraph };
