import Axios from 'axios';
import { localConsoleLog } from './functions';
import i18n from './i18n';

const apiUrl = 'https://app.heated.cz/api/';
const cancelToken = Axios.CancelToken;

const cancelRequest = function (message) {
    //
};

const postQuestionRequest = (data, callback) => {
    Axios({
        method: 'POST',
        url: apiUrl + 'questions',
        headers: {
            'Content-Type': 'application/json',
            'X-API-Key': 'HEATFLOW',
            'Accept-Language': i18n.language,
        },
        // params: data,
        data: data,
        cancelToken: new cancelToken(
            function executor(c) {
                postQuestion.running = true;
                postQuestion.cancel = c;
            }
        )
    }).then((response) => {
        postQuestion.running = false;
        callback(response);
    })
        .catch((error) => {
            callback(error);
            localConsoleLog('error', error);
        });
};

const getFaqRequest = (callback) => {
    Axios({
        method: 'GET',
        url: apiUrl + 'faq',
        headers: {
            'Content-Type': 'application/json',
            'X-API-Key': 'HEATFLOW',
            'Accept-Language': i18n.language,
        },
        cancelToken: new cancelToken(
            function executor(c) {
                getFaq.running = true;
                getFaq.cancel = c;
            }
        )
    }).then((response) => {
        getFaq.running = false;
        callback(response.data);
    }).catch((error) => {
        localConsoleLog('error', error);
    });
};

const getContactsRequest = (callback, region) => {

    Axios({
        method: 'GET',
        url: apiUrl + 'contacts?region=' + region,
        headers: {
            'Content-Type': 'application/json',
            'X-API-Key': 'HEATFLOW',
            'Accept-Language': i18n.language,
        },
        // params: data,
        cancelToken: new cancelToken(
            function executor(c) {
                getContacts.running = true;
                getContacts.cancel = c;
            }
        )
    }).then((response) => {
        getContacts.running = false;
        callback(response.data);
    }).catch((error) => {
        localConsoleLog('error', error);
    });
};
const getConfigRequest = (callback) => {

    Axios({
        method: 'GET',
        url: apiUrl + 'config',
        headers: {
            'Content-Type': 'application/json',
            'X-API-Key': 'HEATFLOW',
            'Accept-Language': i18n.language,
        },
        // params: data,
        cancelToken: new cancelToken(
            function executor(c) {
                getConfig.running = true;
                getConfig.cancel = c;
            }
        )
    }).then((response) => {
        getConfig.running = false;
        callback(response.data);
    }).catch((error) => {
        localConsoleLog('error', error);
    });

};

const indexReferencesRequest = (category = 'Rodinné domy', url = null, state = {}, callback) => {
    if (!url) {
        url = apiUrl + 'references?count=12&category=' + category;

        if (state !== null) {
            if (state.building_type != '') {
                url = url + '&building_type=' + state.building_type;
            }

            if (state.floor_area != '') {
                url = url + '&floor_area=' + state.floor_area;
            }

            if (state.expenses_per_month != '') {
                url = url + '&expenses_per_month=' + state.expenses_per_month;
            }
        }
    }

    Axios({
        method: 'GET',
        url: url,
        headers: {
            'Content-Type': 'application/json',
            'X-API-Key': 'HEATFLOW',
            'Accept-Language': i18n.language,
        },
        cancelToken: new cancelToken(
            function executor(c) {
                indexReferences.running = true;
                indexReferences.cancel = c;
            }
        )
    }).then((response) => {
        indexReferences.running = false;
        callback(response.data);
    }).catch((error) => {
    });

};


const getReferencesRequest = (category = 1, callback, all = 0) => {

    var url = "";
    // var all_url = "";
    if (category != 1) {
        url += "?category=" + category;
    }
    if (all != 0) {
        url += "&all=" + all;
    }
    Axios({
        method: 'GET',
        url: apiUrl + 'references' + url,
        headers: {
            'Content-Type': 'application/json',
            'X-API-Key': 'HEATFLOW',
            'Accept-Language': i18n.language,
        },
        // params: data,
        cancelToken: new cancelToken(
            function executor(c) {
                getReferences.running = true;
                getReferences.cancel = c;
            }
        )
    }).then((response) => {
        getReferences.running = false;
        callback(response.data);
    }).catch((error) => {
        localConsoleLog('error', error);
    });

};

const showReferenceRequest = (id, callback) => {
    Axios({
        method: 'GET',
        url: apiUrl + 'references/' + id,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-API-Key': 'HEATFLOW',
            'Accept-Language': i18n.language,
            'X-Requested-With': 'XMLHttpRequest',
        },
        cancelToken: new cancelToken(
            function executor(c) {
                showReference.running = true;
                showReference.cancel = c;
            }
        )
    }).then((response) => {
        showReference.running = false;

        callback(response.data);
    }).catch((error) => {
        localConsoleLog('error', error);
    });

};

const getNextReferencesRequest = (category, next, callback) => {

    // var url = "";
    // if(category != 1) {
    //     url = "?category=" + category;
    // }

    Axios({
        method: 'GET',
        url: next + "&category=" + category,
        headers: {
            'Content-Type': 'application/json',
            'X-API-Key': 'HEATFLOW',
            'Accept-Language': i18n.language,
        },
        // params: data,
        cancelToken: new cancelToken(
            function executor(c) {
                getNextReferences.running = true;
                getNextReferences.cancel = c;
            }
        )
    }).then((response) => {
        getNextReferences.running = false;
        callback(response.data);
    }).catch((error) => {
        localConsoleLog('error', error);
    });

};


const getHighlightedReferencesRequest = (callback) => {

    Axios({
        method: 'GET',
        url: apiUrl + 'references?highlighted=1',
        headers: {
            'Content-Type': 'application/json',
            'X-API-Key': 'HEATFLOW',
            'Accept-Language': i18n.language,
        },
        // params: data,
        cancelToken: new cancelToken(
            function executor(c) {
                getHighlightedReferences.running = true;
                getHighlightedReferences.cancel = c;
            }
        )
    }).then((response) => {
        getHighlightedReferences.running = false;
        callback(response.data);
    }).catch((error) => {
        localConsoleLog('error', error);
    });

};

const postOffersRequest = (data, callback) => {

    Axios({
        method: 'POST',
        url: apiUrl + 'offers',
        headers: {
            'Content-Type': 'application/json',
            'X-API-Key': 'HEATFLOW',
            'Accept-Language': i18n.language,
        },
        // params: data,
        data: data,
        cancelToken: new cancelToken(
            function executor(c) {
                postOffers.running = true;
                postOffers.cancel = c;
            }
        )
    }).then((response) => {
        postOffers.running = false;
        callback(response);
    })
        .catch((error) => {
            if (error.response.status == 413) {

                callback("Too large");
            }
            localConsoleLog('error', error);
        });

};
const postNewsletterRequest = (data, callback) => {

    Axios({
        method: 'POST',
        url: apiUrl + 'newsletter',
        headers: {
            'Content-Type': 'application/json',
            'X-API-Key': 'HEATFLOW',
            'Accept-Language': i18n.language,
        },
        // params: data,
        data: data,
        cancelToken: new cancelToken(
            function executor(c) {
                postNewsletter.running = true;
                postNewsletter.cancel = c;
            }
        )
    }).then((response) => {
        postNewsletter.running = false;
        callback(response);
    })
        .catch((error) => {
            callback(error);
            localConsoleLog('error', error);
        });
};

const postLinkRequest = (data, callback) => {

    Axios({
        method: 'POST',
        url: apiUrl + 'files',
        headers: {
            'Content-Type': 'application/json',
            'X-API-Key': 'HEATFLOW',
            'Accept-Language': i18n.language,
        },
        // params: data,
        data: data,
        cancelToken: new cancelToken(
            function executor(c) {
                postLink.running = true;
                postLink.cancel = c;
            }
        )
    }).then((response) => {
        postLink.running = false;
        callback(response);
    })
        .catch((error) => {
            localConsoleLog('error', error);
        });

};

const getContacts = {
    request: getContactsRequest,
    running: false,
    cancel: cancelRequest
};
const getConfig = {
    request: getConfigRequest,
    running: false,
    cancel: cancelRequest
};
const indexReferences = {
    request: indexReferencesRequest,
    running: false,
    cancel: cancelRequest
};
const getReferences = {
    request: getReferencesRequest,
    running: false,
    cancel: cancelRequest
};
const showReference = {
    request: showReferenceRequest,
    running: false,
    cancel: cancelRequest
};
const getNextReferences = {
    request: getNextReferencesRequest,
    running: false,
    cancel: cancelRequest
};
const getHighlightedReferences = {
    request: getHighlightedReferencesRequest,
    running: false,
    cancel: cancelRequest
};
const postOffers = {
    request: postOffersRequest,
    running: false,
    cancel: cancelRequest
};
const postNewsletter = {
    request: postNewsletterRequest,
    running: false,
    cancel: cancelRequest
};
const postLink = {
    request: postLinkRequest,
    running: false,
    cancel: cancelRequest
};
const getFaq = {
    request: getFaqRequest,
    running: false,
    cancel: cancelRequest
};
const postQuestion = {
    request: postQuestionRequest,
    running: false,
    cancel: cancelRequest
};

export {
    getContacts,
    getConfig,
    getReferences,
    getNextReferences,
    getHighlightedReferences,
    postOffers,
    postNewsletter,
    postLink,
    showReference,
    indexReferences,
    getFaq,
    postQuestion,
};
