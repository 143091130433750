import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import resources from './langs'

// en: {
//     translation: {
//         test: 'Hello',
//         key: 'number',
//         key_plural: 'numbers',
//     }
// },
// cs: {
//     translation: {
//         test: 'Ahoj',
//         key_0: '{{count}} číslo',
//         key_1: '{{count}} čísla',
//         key_2: '{{count}} čísel',
//     }
// },
// {i18n.t('key', { count: 0 })}
// {i18n.t('test')}
// <h2 className="section-heading" dangerouslySetInnerHTML={{ __html: i18n.t('home.header') }}></h2>

const hostSplit = window.location.host.split('.')
if (hostSplit[hostSplit.length - 1] === 'sk') {
    localStorage.setItem('lang', 'sk')
}

i18next.use(initReactI18next).init({
    resources,
    lng: localStorage.getItem('lang') || 'cs',

    // keySeparator: false,
    interpolation: {
        escapeValue: false
    }
});

export default i18next;