import React from 'react';

function AnimationAboutNumber({children})
{
    return (
        <div className="AnimationAboutNumber">
            {children}
        </div>
    );
}

export default AnimationAboutNumber;
