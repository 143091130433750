import * as React from "react";
import { Link } from "react-router-dom";
import config from "../../../../config/config.json";

import i18n from '../i18n';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.toTop = this.toTop.bind(this);
  }

  toTop(e) {
    this.props.onToTopBtnClick();
  }

  render() {
    return (
      <div className="footer">
        <div className="footer-cont">
          <div className="footer-cell">
            <div className="footer-cell-menu">
              <h4 className="footer-cell-heading">{i18n.t('footer.title1')}</h4>
              <ul className="footer-cell-menu-list">
                {/* <li className="footer-cell-menu-list-item">
                  <Link to={config.pages.home.url} className="footer-cell-link">
                    {i18n.t('about.title2')}
                  </Link>
                </li> */}
                <li className="footer-cell-menu-list-item">
                  <a href="#" className="footer-cell-link disabled">
                    {i18n.t('about.title2')}
                  </a>
                </li>
                <li className="footer-cell-menu-list-item">
                  <Link
                    to={config.pages.ke_stazeni.url}
                    className="footer-cell-link"
                  >
                    {i18n.t('download.breadcrumb')}
                  </Link>
                </li>
                <li className="footer-cell-menu-list-item">
                  <a href="#" className="footer-cell-link disabled">
                    {i18n.t('general.posts')}
                  </a>
                </li>
                {/* <li className="footer-cell-menu-list-item">
                  <Link to={config.pages.faq.url} className="footer-cell-link">
                    {i18n.t('faq.titleShort')}
                  </Link>
                </li> */}
                <li className="footer-cell-menu-list-item">
                  <a href="#" className="footer-cell-link disabled">
                    {i18n.t('faq.titleShort')}
                  </a>
                </li>
                <li className="footer-cell-menu-list-item">
                  <Link to={config.pages.gdpr.url} className="footer-cell-link">
                    {i18n.t('gdpr.titleShortcuts')}
                  </Link>
                </li>
                <li className="footer-cell-menu-list-item">
                  <Link
                    to={config.pages.privacy.url}
                    className="footer-cell-link"
                  >
                    {i18n.t('gdpr.titleShort')}
                  </Link>
                </li>
                <li className="footer-cell-menu-list-item">
                  <Link
                      to={config.pages.terms_conditions.url}
                      className="footer-cell-link"
                  >
                    {i18n.t('terms_conditions.title')}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer-cell-map-container">
              <h4 className="footer-cell-heading">{i18n.t('footer.title2')}</h4>
              <div className="footer-cell-kontakt">
                <div className="footer-cell-kontakt-half">
                  <span className="footer-cell-kontakt-heading">{i18n.t('contact.contact3.title')}</span>
                  <br />
                  <span className="footer-cell-kontakt-telephone">
                    T:&nbsp;&nbsp;
                  </span>
                  <a href="tel:+420773333780">+420 773 333 780</a>
                  <br />
                  <span className="footer-cell-kontakt-telephone">
                    E:&nbsp;&nbsp;
                  </span>
                  <a href="mailto:praha@heated.cz">praha@heated.cz</a>
                </div>
                <div className="footer-cell-kontakt-half">
                  <span className="footer-cell-kontakt-heading">
                    {i18n.t('contact.contact2.title')}
                  </span>
                  <br />
                  <span className="footer-cell-kontakt-telephone">
                    T:&nbsp;&nbsp;
                  </span>
                  <a href="tel:+420602702417">+420 602 702 417</a>
                  <br />
                  <span className="footer-cell-kontakt-telephone">
                    E:&nbsp;&nbsp;
                  </span>
                  <a href="mailto:info@heated.cz">info@heated.cz</a>
                </div>
              </div>
              <div className="footer-cell-map">
                <img
                  className="footer-map"
                  src={config.paths.icons + `blank-map-${i18n.language}.svg`}
                  alt="Mapa kde působíme"
                />
              </div>
            </div>
          </div>
          <div className="footer-cell">
            <div className="footer-cell-loga-grid">
              <h4 className="footer-cell-heading">EFEKTIVNÍ DŮM S.R.O.</h4>
              {/* <div className="footer-cell-loga-column"> */}
              <a href="https://muj-ed.cz/" className="footer-logo-link" target="_blank">
                <img
                  className="footer-logo-fusion"
                  src={config.paths.brands + "logo_EF2.png"}
                  alt="Efektivní dům s.r.o."
                />
              </a>
              {/* </div> */}
              <div className="footer-reseni-popis" dangerouslySetInnerHTML={{ __html: i18n.t('footer.about') }}></div>
              {/* <div className="footer-mcore"> */}
              <a
                href="http://mcore.cz"
                target="_blank"
                className="footer-logo-link"
              >
                <img
                  className="footer-logo-mcore"
                  src={config.paths.brands + "logo_MCore.png"}
                  alt="MCore systémy"
                />
              </a>
              {/* </div> */}

              {/* <div className="footer-loxone"> */}

              {/* </div> */}
              <span className="footer-logo-popis">
                {i18n.t('footer.mcore')}{" "}
                <b>
                  <a href="http://mcore.cz" target="_blank" className="">
                    {i18n.t('general.here')}
                  </a>
                </b>
              </span>
            </div>
          </div>
        </div>
        <div className="footer-copyright">
          © Copyright 2013-{new Date().getFullYear()} Efektivní dům s.r.o. {i18n.t('footer.allRightReserved')} | <Link
            to={config.pages.terms_conditions.url}
            className="footer-link"
            target="_blank" rel="noopener noreferrer"
        >
          {i18n.t('terms_conditions.title')}
        </Link>
          <div className="footer-to-top" onClick={this.toTop}>
            <img
              className="footer-to-top-link"
              src={config.paths.icons + "ic_arrow_up_white.png"}
              alt="Nahoru"
            />
          </div>
        </div>
      </div>
    );
  }
}

export { Footer };
