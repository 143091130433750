import React from 'react';
import PropTypes from 'prop-types';

function AnimationAboutContainer({children, width})
{
    return (
        <div className={[
            'AnimationAboutContainer',
            width === 'sheet' ? 'AnimationAboutContainer--sheet' : 'AnimationAboutContainer--header',
        ].filter((el) => el).join(' ')}>{children}</div>
    );
}

AnimationAboutContainer.propTypes = {
    children: PropTypes.node.isRequired,
    width: PropTypes.oneOf(['sheet', 'header']),
};

AnimationAboutContainer.defaultProps = {
    width: 'sheet',
};

export default AnimationAboutContainer;
