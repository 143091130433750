import * as React from "react";
import config from "../../../../config/config.json";

class Loadingdata extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
      if (this.props.loading === true) {
        return (
            <div className="loading-data" style={{display: 'grid', justifyItems: 'center'}}>
                <img
                    src={config.paths.brands + "logo_ch.svg"}
                    alt={this.props.label || 'Načítám data'}
                    style={{marginBottom: "1em"}}
                  />
                <span style={{display: "block", textAlign: 'center'}}>{this.props.label || 'Načítám data'}</span>
            </div>
          );
      }

    return null;
  }
}

export default Loadingdata;
