import React from 'react';

import AnimationAboutNumber from './AnimationAboutNumber';
import AnimationAboutSheet from './AnimationAboutSheet';
import AnimationAboutContainer from './AnimationAboutContainer';
import AnimationAboutHeaderSplit from './AnimationAboutHeaderSplit';
import AnimationAboutHeaderTitle from './AnimationAboutHeaderTitle';
import AnimationAboutHeaderText from './AnimationAboutHeaderText';
import AnimationAboutTile from './AnimationAboutTile';
import AnimationAboutSheetBackground from './AnimationAboutSheetBackground';
import AnimationAboutSlider from './AnimationAboutSlider';
import AnimationAboutSimpleCarousel from './AnimationAboutSimpleCarousel';

import config from '../../../../config/config.json';

import i18n from '../i18n';

function AnimationAboutSection1()
{
    return (
        <section className="AnimationAboutSection AnimationAboutSection1">
            <AnimationAboutNumber>01</AnimationAboutNumber>
            <AnimationAboutSheet kartoteka="tmava" theme="dark">
                <AnimationAboutContainer width="header">
                    <AnimationAboutHeaderSplit id="gap-554">
                        <div>
                            <AnimationAboutHeaderTitle>{i18n.t('about.section01.title')}</AnimationAboutHeaderTitle>
                            <AnimationAboutHeaderText>
                                <span dangerouslySetInnerHTML={{ __html: i18n.t('about.section01.text') }}></span>
                            </AnimationAboutHeaderText>
                        </div>
                        <div>
                            <div
                                className="AnimationAbout--83"
                                style={{ fontWeight: 500, fontSize: '1.01562vw', lineHeight: '2.1875vw', marginBottom: '1.30208vw' }}
                            >
                                {i18n.t('about.section01.chartDesc')}
                            </div>
                            <img src={config.paths.about + "SVG_S01_" + i18n.language + ".svg"} alt={i18n.t('about.section01.chartDesc')} />
                        </div>
                    </AnimationAboutHeaderSplit>
                </AnimationAboutContainer>
            </AnimationAboutSheet>
            <AnimationAboutSheet kartoteka="bila" kartotekaPushed noPaddingBottom theme="light">
                <AnimationAboutContainer>
                    <AnimationAboutTile>
                        <div className="AnimationAboutTile__text-content AnimationAbout--69">
                            <h4 className="AnimationAboutTile__title AnimationAbout--black">{i18n.t('about.section01.box1.title')}</h4>
                            <div className="AnimationAbout--line-medium" dangerouslySetInnerHTML={{ __html: i18n.t('about.section01.text') }}></div>
                            <div>{i18n.t('about.section01.box1.note')}</div>
                            <div className="AnimationAboutTile__triples">
                                <div className="AnimationAboutTile__triple AnimationAbout--83">
                                    <div className="AnimationAboutTile__triple__title">{i18n.t('about.section01.valueTitle1')}</div>
                                    <div className="AnimationAboutTile__triple__content">≤12%</div>
                                </div>
                                <div className="AnimationAboutTile__triple AnimationAbout--orange">
                                    <div className="AnimationAboutTile__triple__title">
                                        Heated.
                                    </div>
                                    <div className="AnimationAboutTile__triple__content">≤90%</div>
                                </div>
                                <div className="AnimationAboutTile__triple AnimationAbout--83">
                                    <div className="AnimationAboutTile__triple__title">{i18n.t('about.section01.valueTitle2')}</div>
                                    <div className="AnimationAboutTile__triple__content">≤16%</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <img src={config.paths.about + 'podlaha.png'} alt="Podlaha HeatFlow" />
                        </div>
                    </AnimationAboutTile>
                    <AnimationAboutSlider
                        count={2}
                        render={({ current, prev, next }) => {
                            return (
                                <AnimationAboutTile reversed>
                                    <div>
                                        <AnimationAboutSimpleCarousel animated current={current} prev={prev} next={next}>
                                            <img
                                                src={config.paths.about + 'heatflow_vs_teplovod_' + i18n.language + '.png'}
                                                alt="Heatflow vs. teplovod"
                                            />
                                            <img src={config.paths.about + 'heatflow_vs_rohoz.png'} alt="Heatflow vs. rohoz" />
                                        </AnimationAboutSimpleCarousel>
                                    </div>
                                    <div className="AnimationAboutTile__text-content AnimationAbout--69">
                                        <h4 className="AnimationAboutTile__title AnimationAbout--black">{i18n.t('about.section01.box2.title')}</h4>
                                        <div
                                            className="AnimationAbout--line-medium"
                                            dangerouslySetInnerHTML={{ __html: i18n.t('about.section01.box2.text') }}
                                        ></div>
                                        <div dangerouslySetInnerHTML={{ __html: i18n.t('about.section01.box2.note') }}></div>
                                        <div className="AnimationAboutTile__triples">
                                            <div
                                                className={[
                                                    'AnimationAboutTile__triple',
                                                    current === 1 && 'AnimationAbout--danger',
                                                    current !== 1 && 'AnimationAbout--83',
                                                ]
                                                    .filter((el) => el)
                                                    .join(' ')}
                                            >
                                                <div className="AnimationAboutTile__triple__title">{i18n.t('about.section01.valueTitle1')}</div>
                                                <div className="AnimationAboutTile__triple__content">140 min</div>
                                            </div>
                                            <div className="AnimationAboutTile__triple AnimationAbout--orange">
                                                <div className="AnimationAboutTile__triple__title">
                                                    Heated.
                                                </div>
                                                <div className="AnimationAboutTile__triple__content">28 min</div>
                                            </div>
                                            <div
                                                className={[
                                                    'AnimationAboutTile__triple',
                                                    current === 0 && 'AnimationAbout--danger',
                                                    current !== 0 && 'AnimationAbout--83',
                                                ]
                                                    .filter((el) => el)
                                                    .join(' ')}
                                            >
                                                <div className="AnimationAboutTile__triple__title">{i18n.t('about.section01.valueTitle2')}</div>
                                                <div className="AnimationAboutTile__triple__content">176 min</div>
                                            </div>
                                        </div>
                                    </div>
                                </AnimationAboutTile>
                            );
                        }}
                    />
                    <AnimationAboutSlider
                        initial={1}
                        count={2}
                        render={({ current, next, prev }) => {
                            return (
                                <AnimationAboutTile>
                                    <div className="AnimationAboutTile__text-content AnimationAbout--69">
                                        <h4 className="AnimationAboutTile__title AnimationAbout--black">{i18n.t('about.section01.box3.title')}</h4>
                                        <div
                                            className="AnimationAbout--line-medium"
                                            dangerouslySetInnerHTML={{ __html: i18n.t('about.section01.box3.text') }}
                                        ></div>
                                        <div>{i18n.t('about.section01.box3.note')}</div>
                                        <div className="AnimationAboutTile__triples">
                                            <div
                                                className={[
                                                    'AnimationAboutTile__triple',
                                                    current === 1 && 'AnimationAbout--danger',
                                                    current !== 1 && 'AnimationAbout--83',
                                                ]
                                                    .filter((el) => el)
                                                    .join(' ')}
                                            >
                                                <div className="AnimationAboutTile__triple__title">{i18n.t('about.section01.valueTitle1')}</div>
                                                <div className="AnimationAboutTile__triple__content">± 1,8°C</div>
                                            </div>
                                            <div className="AnimationAboutTile__triple AnimationAbout--orange">
                                                <div className="AnimationAboutTile__triple__title">
                                                    Heated.
                                                </div>
                                                <div className="AnimationAboutTile__triple__content">± 0,2°C</div>
                                            </div>
                                            <div
                                                className={[
                                                    'AnimationAboutTile__triple',
                                                    current === 0 && 'AnimationAbout--danger',
                                                    current !== 0 && 'AnimationAbout--83',
                                                ]
                                                    .filter((el) => el)
                                                    .join(' ')}
                                            >
                                                <div className="AnimationAboutTile__triple__title">{i18n.t('about.section01.valueTitle2')}</div>
                                                <div className="AnimationAboutTile__triple__content">± 3,5°C</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <AnimationAboutSimpleCarousel animated current={current} next={next} prev={prev}>
                                            <img src={config.paths.about + "heatflow_vs_teplovod_" + i18n.language + ".png"} alt="Heatflow vs. teplovod" />
                                            <img src={config.paths.about + "img-3_" + i18n.language + ".png"} alt="Heatflow vs. rohoz" />
                                        </AnimationAboutSimpleCarousel>
                                    </div>
                                </AnimationAboutTile>
                            );
                        }}
                    />
                </AnimationAboutContainer>
                <AnimationAboutSheetBackground image={config.paths.about + 'obyvak_sepia.png'} />
            </AnimationAboutSheet>
        </section>
    );
}

export default AnimationAboutSection1;
