import React, {useCallback} from 'react';

import AnimationAboutContainer from './AnimationAboutContainer';

import config from '../../../../config/config.json';

import i18n from '../i18n';

function AnimationAboutHeader()
{
    const scrollDown = useCallback(() => {
        const el = document.querySelector('.AnimationAboutSection1');

        if (el) {
            el.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
        }
    }, []);

    return (
        <div className="AnimationAboutHeader" style={{backgroundImage: `url(${config.paths.about + 'img-header.png'})`}}>
            <div className="AnimationAboutHeader__background">
                <AnimationAboutContainer width="header">
                    <div className="AnimationAboutHeader__content">
                        <div>
                            <div className="AnimationAboutBreadcrumbs">
                                <div className="AnimationAboutBreadcrumbs__breadcrumb">
                                    <strong>
                                        Heated.
                                    </strong>
                                </div>
                                <div className="AnimationAboutBreadcrumbs__breadcrumb">
                                    <h1>
                                        {i18n.t('about.title')}
                                    </h1>
                                </div>
                            </div>
                            <h2 className="AnimationAboutHeader__title AnimationAbout--white">
                                <span style={{color: '#ff591f'}} className="AnimationAbout--semi">{i18n.t('about.header.title')}{' '}</span>
                                <span>Heated.</span>
                            </h2>
                            <div className="AnimationAboutHeader__graph">
                                <span className="AnimationAboutHeader__graph__over AnimationAbout--b3">{i18n.t('about.header.itemTitle1')}</span>
                                <span className="AnimationAboutHeader__graph__over AnimationAbout--b3">{i18n.t('about.header.itemTitle2')}</span>
                                <span className="AnimationAboutHeader__graph__over AnimationAbout--b3">{i18n.t('about.header.itemTitle3')}</span>
                                <img className="AnimationAboutHeader__graph__image" style={{maxWidth: '100%', maxHeight: '100%'}} src={config.paths.about + 'img-trikolora.png'} alt="Grafický oddělovač" />
                                <div className="AnimationAboutHeader__graph__under AnimationAbout--white">
                                    <div className="AnimationAbout--semi">
                                        {i18n.t('about.header.itemValue1')}
                                    </div>
                                    <div>
                                        {i18n.t('about.header.itemText1')}
                                    </div>
                                </div>
                                <div className="AnimationAboutHeader__graph__under AnimationAbout--white">
                                    <div className="AnimationAbout--semi">
                                        {i18n.t('about.header.itemValue2')}
                                    </div>
                                    <div>
                                        {i18n.t('about.header.itemText2')}
                                    </div>
                                </div>
                                <div className="AnimationAboutHeader__graph__under AnimationAbout--white">
                                    <div className="AnimationAbout--semi">
                                        3000+
                                    </div>
                                    <div>
                                        {i18n.t('about.header.itemText3')}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <img role="button" tabIndex="-1" onClick={scrollDown} onKeyDown={scrollDown} className="AnimationAboutHeader__scroller" style={{maxWidth: '100%', maxHeight: '100%', width: '1vw'}} src={config.paths.about + 'arrow_down.png'} alt="Sjeďte dolů" />
                            </div>
                        </div>
                    </div>
                </AnimationAboutContainer>
            </div>
        </div>
    );
}

export default AnimationAboutHeader;
