export default {
    translation: {
        general: {
            hideCs: '',
            currency: 'Eur',
            yourMessage: 'Vaša správa',
            haveProject: 'Mám projekt',
            haveProjectNoDocumentation: 'Mám projekt, ale podklady nemám pri sebe',
            noProject: 'Nemám projekt',
            completeProjectDoc: 'Kompletná projektová dokumentácia',
            edit: 'Upraviť',
            close: 'Zavrieť',
            toHide: 'Skryť',
            skip: 'Preskočiť',
            back: 'Späť',
            age: 'rokov',
            showAnotherPros: 'Zobraziť ďalšie výhody',
            showMoreParameters: 'Zobraziť ďalšie parametry',
            showMore: 'Zobraziť viac',
            sending: 'Odosielam...',
            sended: 'Odoslané',
            toSend: 'Odoslať',
            sendingFailed: 'Odoslanie sa nepodarilo.',
            sendingForm: 'Odosielam formulár',
            backToTop: 'Späť na úvod',
            intro: 'Úvod',
            heatComparison: 'Porovnanie kúrenia',
            further: 'Ďalej',
            whatsHeatflow: 'čo je',
            interestInOffer: '<span style="font-weight: 300">mám záujem o</span> <br />cenovú ponuku',
            playAgain: 'Prehrať znovu',
            here: 'tu',
            remove: 'Odobrať',
            max5files: 'Max. 5 súborov, každý 10MB.',
            params: 'Parametre',
            posts: 'Články',
            service: 'Služba',
            prezentation: 'Prezentácia',
            aboutProduct: 'O produkte',
            priceCalc: 'Cenová kalkulácia',
            freePriceCalc: 'Cenová kalkulácia zdarma',
            freeOffer: 'Cenová ponuka <br />Heated. zdarma',
            freeOfferText: 'Naše špecializované projekčné oddelenie vypracuje cenovú ponuku na mieru vášho domu vrátane odhadu ročnej spotreby.',
            moreAboutService: 'Viac o službe',
            contractDesc: 'Popis zákazky',
            floorComposition: 'Skladba podlahy',
            hotWaterDistr: 'Teplovodné rozvody',
            realizationDesc: 'Popis realizácie',
            monitorConsump: 'Monitorovaná spotreba',
            compareHeat: 'Porovnanie kúrenia',
            loadingFiles: 'Nahrávame Vaše podklady.',
            howHeats: 'Ako hreje',
            filesTooLarge: 'Vami priložené súbory sú príliš veľké.',
            takeCareASAP: 'Vašu žiadosť vybavíme v čo najkratšej možnej dobe.',
            contactUsViaEmail: 'Prosíme, kontaktujte nás pomocou e-mailu, ktorý nájdete v zápätí webu.',
            emailForAnswer: 'E-MAIL PRE ZASLANIE ODPOVEDE',
            infoModalHeading: 'UPOZORNENIE',
            questionSentThankYou: 'Správa odoslaná, ďakujeme!',
            privaciPolicyAgreement: 'Súhlas so spracovaním osobných údajov',
            selectionFits: 'Vášmu výberu zodpovedá',
            selectionDoesntFit: 'Vášmu výberu nezodpovedá',
            sandingPrivaciPolicyAgreement: 'Odoslaním súhlasíte so spracovaním osobných údajov.',
            sendingAgree1: 'Odoslaním súhlasíte so',
            sendingAgree2: 'spracovaním osobných údajov',
            uploadNote: '<b>Pretiahnite váš projekt</b><br />alebo kliknite pre výber projektu.<br/><div class="legend">.PDF, .JPG, .PNG, .DOC, .XLS, .DWG<br/>Max. 5 souborů, každý 10MB.</div>',
            gdprContent: `
            <p>
                <b>Týmto dávam</b> spoločnosti <b>Efektivní dům s.r.o.</b>, IČO: 27855252, so sídlom: Hlučínská 1177, Přívoz, 702 00 Ostrava, zapísaná v obchodnom registri u Krajského súdu v Ostrave, sp. zn. C 31815 (ďalej len „<b>Správca</b>“) <b>súhlas</b> so spracovaním osobných údajov v rozsahu:
            </p>
            <ul class="gdpr-list dotted">
                <li class="gdpr-list-item">meno a priezvisko a titul</li>
                <li class="gdpr-list-item">mesto</li>
                <li class="gdpr-list-item">e-mailová adresa</li>
                <li class="gdpr-list-item">telefónne číslo</li>
            </ul>
            <p>
                (ďalej len „<b>Osobné údaje</b>“).
            </p>
            <p>Osobné údaje budú použité za účelom spracovania ponuky produktov podľa kritérií zadaných subjektom údajov, pre kontaktovanie subjektu údajov e-mailom či telefonicky a k ďalšiemu jednaniu o produktoch a ďalej k zasielaniu informácií o produktoch, službách, noviniek a aktualít týkajúcich sa odboru stavebníctva a technológií, tj. newslettery.</p>
            <p>
                Ďalej beriem na vedomie, že tento súhlas dávam slobodne a dobrovoľne a môžem ho kedykoľvek odvolať formou písomnej informácie zaslanej na adresu Správcu alebo na e-mailovú adresu <a href="mailto:info@heated.cz">info@heated.cz</a>, a to i pred uplynutím doby, na ktorú som súhlas dal/a. Odvolaním tohto súhlasu nie je dotknutá zákonnosť spracovania osobných údajov v dobe pred odvolaním súhlasu. Viac informácií o spracovaní osobných údajov je uvedenených v informačnom
                dokumente, ktorý je k dispozícii na webovej adrese www.heated.cz. V prípade otázok týkajúcich sa spracovania mojich osobných údajov sa môžem obrátiť na správcu osobných údajov, a to prostredníctvom e-mailovej adresy <a href="mailto:info@heated.cz">info@heated.cz</a>.
            </p>`,
            infoModalContent: `
           <p>
                Počas 10 rokov pôsobenia na trhu sme vlastnými silami z produktu Heatflow vybudovali úspešnú značku, ako ju dnes poznáte. Ako sme už predtým informovali, na základe nezhôd, sme sa 1.4.2022 rozhodli ukončiť spoluprácu so slovenským distribútorom.
            </p>
            <p>
                Keďže robíme 70% celosvetového odberu a máme za sebou viac ako 3000 realizácií, nebol pre nás problém dohodnúť sa priamo s výrobcom v Južnej Kórei. To sa pochopiteľne nepáči nášmu bývalému distribútorovi, ktorý prostredníctvom našich bývalých zamestnancov šíri ohováranie a klamlivé tvrdenia v snahe poškodiť našu firmu. Na základe nekalého konania našej konkurencie sme sa mimo právne kroky, rozhodli, že nebudeme značku Heatflow ďalej propagovať a rozvíjať. Uvádzame na trh tento výrobok pod vlastnou značkou. Samotná technológia aj výrobný proces zostanú rovnaké ako Heatflow, už však pod našim vlastným názvom <b>Carbon Heated</b>.
            </p>
            <p>
                Z týchto dôvodov sme od 1.11.22 zastavili všetku propagáciu produktu Heatflow a sústredíme sa na produkt Carbon Heated. a projekt <a href="https://muj-ed.cz/" target="_blank">Efektívny dom</a>, kde nájdete viac ako 70 technológií od viac ako 20 výrobcov, pod jednou strechou.
            </p>
            <p><b>Čo tvrdí náš bývalý distribútor cez svojich prostredníkov a aká je skutočnosť:</b></p>
            <p>
                Že predávame falzifikáty<br />  
                Ide o produkt z rovnakej továrne, od rovnakého výrobcu, z rovnakej výrobnej linky s absolútne rovnakými vlastnosťami a rovnakým výrobným certifikátom.
            </p>
            <p>
                Že sú naše produkty nebezpečné<br />
                Náš produkt prešiel všetkými testami, navyše sme si pred uvedením na trh nechali v Českej republike urobiť certifikáciu zdravotnej nezávadnosti.
            </p>
            <p>
                Že nemáme potrebné certifikácie<br />  
                Certifikácia výrobku sú dodávané výrobcom (zhodný u oboch produktov)
            </p>
            <p>
                Že je v našich produktoch menej uhlíka a budú menej kúriť<br />            
                Keďže sa jedná o zhodné produkty, je pochopiteľne rovnaká aj výhrevnosť, typ použitého uhlíka aj hmotnosť výrobku. Za týmto účelom sme nechali urobiť nezávislé meranie ČVUT, ktoré naše tvrdenia o zhodnosti vlastností potvrdzujú.
            </p>
            <p>
                Že fólie nemontujeme správne<br />  
                Všetky montáže vykonávame podľa platných legislatívnych noriem pre SR, náš certifikovaný montážny postup vznikol za účasti TIČR.
            </p>
            <p>
                Že sme dlžníci a krachujeme<br />  
                V roku 2022 naša spoločnosť eviduje rekordný nárast (300%), blížime sa k hranici 100 zamestnancov a aktuálne naberáme ľudí pre viac ako 25 pozícií.
            </p>
            <p>
                Že sme sa 1.6.2022 premenovali, pretože máme problémy<br />  
                Prechod na novú identitu sme plánovali cez 2 roky. Vzhľadom na široké spektrum produktov a technológií, ktoré máme aktuálne v ponuke, sme aj s ohľadom na pôsobnosť výhradne na slovenskom trhu chceli názov a identitu, ktorá náš koncept lepšie vyzdvihne. Navyše, mimo názvu, sme nezmenili vôbec nič, ponechali sme všetko – adresu, IČO, vlastnícku štruktúru aj konateľa.
            </p>
            <p>Vykurovacia fólia, ktorú dodávame, má úplne rovnaké vlastnosti ako výrobok vykurovacia fólia Heatflow.<br />
                <ul>
                    <li>Je od rovnakého výrobcu</li>
                    <li>Z rovnakej továrne</li>
                    <li>Má rovnaké certifikácie</li>
                    <li>Rovnakú hmotnosť</li>
                    <li>Rovnakú životnosť</li>
                    <li>Certifikát zdravotnej nezávadnosti</li>
                    <li>Bolo vykonané nezávislé meranie ČVUT (ktoré tieto fakty potvrdzuje)</li>
                </ul>
            </p>
            <p>
                Na všetky naše realizácie (od roku 2012 viac ako 3000 realizácií) samozrejme ďalej držíme záruku a pre našich bývalých súčasných ani budúcich zákazníkov sa nič nemení.í.
            </p>
            <p>
                Tím Carbon Heated.
            </p>
            `,
            fullname: 'Meno a priezvisko',
            city: 'Mesto',
            phone: 'Telefón',
            phoneShortcut: 'Tel',
            note: 'Poznámka',
            totalArea: 'Celková plocha',
            requiredField: 'Tento údaj je povinný',
            wrongFormate: 'Nesprávny formát',
            projectPhase: 'Fáza projektu',
            projectPhaseAndDocuments: 'Fáza projektu a podklady',
            additionalOptions: 'Doplňujúce možnosti',
            priceComplete: 'Chcem naceniť <b>kompletnú skladbu podlahy</b>',
            priceInteligent: 'Chcem naceniť <b>rekuperáciu</b>',
            priceSolar: 'Chcem naceniť <b>solárnu elektráreň (FVE)</b>',
            projectVisual: 'Vizualizácia projektu',
            customerFeedback: 'Spätná väzba zákazníka',
            customerFeedbackBr: 'Spätná väzba <br />zákazníka',
            realization: 'Realizácie',
            selectedProjects: 'vybraných projektov',
            floorArea: 'Podl. plocha',
            totalFloorArea: 'Celková podlahová plocha',
            floorIsolation: 'Izolácia v podlahe',
            floor: 'podlaha',
            heatLosses: 'Tepelná strata',
            costsPerMonth: 'Náklady / Mesiac',
            partOfDelivery: 'Súčasť nášho dodania',
            partOfDeliveryHeating: 'vykurovanie',
            moreAboutReference: 'Viac o referencií',
            showProject: 'Zobraziť projekt',
            homeCount: 'Počet domov',
            loadMore: 'Načítať viac',
            readMore: 'Viac',
            open: 'Otvoriť',
            totalRealizeArea: 'Celková zrealizovaná plocha',
            weRecommend: 'Odporúčame',
            weDontRecommend: 'Neodporúčame',
            recommendThick: 'Odporúčame hrúbku',
            goodIsolationLowPrice: 'Dobré izolačné vlastnosti za nízku cenu',
            greaterDemandHeight: 'Väčšie nároky na výšku skladby',
            beterIsolationLowerDemand: 'Lepšie izolačné vlastnosti než EPS, menšie nároky na výšku skladby',
            betterIsolationEPS: 'Lepšie izolačné vlastnosti než biely EPS',
            mezzanineInsulation: 'Táto varianta je vhodná len ako izolácia do medziposchodia',
            keepsFloorWarmer: 'Dlhšie udrží teplú podlahu',
            lowerHeatCapacity: 'Menšia absorbčná schopnosť tepla než anhydrit (dlhšie akumuluje teplo)',
            heigherHeatCapacity: 'Väčšia absorbčná schopnosť tepla než cementový poter, kratšia doba zrenia',
            lowerInertiaConcreat: 'Menšia zotrvačnosť udržania tepla než pri cementovom potere',
            lowerInertiaFry: 'Menšia zotrvačnosť udržania tepla než pri liatych poteroch',
            dryProcess: 'Suchý proces (bez doby zrenia), nízke nároky na výšku skladby podlahy',
            heighVariability: 'Veľká variabilita vzhľadu',
            industrialSpaces: 'Hodí sa predovšetkým do industriálnych priestorov (miestnosť pôsobí studene)',
            heigSelectionLowPrice: 'Veľký výber, nízka cena',
            lowServiceLife: 'Krátká životnosť',
            softLayer: 'Mäkká nášľapná vrstva',
            heighSelectionHeighCapacity: 'Veľký výber, veľká absorbčná schopnosť tepla',
            heighPricePavement: 'Pri kvalitnej dlažbe vysoká cena, krehkosť',
            heatMaterialServiceLife: 'Dlhšie udrží teplú podlahu, veľký výber materiálov, dlhá životnosť',
            lowCapacityHeighPrice: 'Malá absorbčná schopnosť tepla, pri kvalitných materiáloch vyššia cena',

            pros: 'Výhody',
            cons: 'Nevýhody',
            heigherPrice: 'Vyššia cena',

            spreadingLayer: 'Roznášacia vrstva',
            finalLayer: 'Finálna vrstva',
            conclusion: 'Zhrnutie',
            evaluation: 'Vyhodnotenie',

            wrongBrowserVideo: 'Váš prehliadač nepodporuje video formát. Odporúčame upgradovať na novšiu verziu.',

            showOtherProps: 'Zobraziť ďalšie vlastnosti',
            hideProps: 'skryť vlastnosti',

            weWillNeed: 'Ešte budeme potrebovať',

            countHouse: 'dom',
            countHouseMultiple: 'domov',
            countNoHouse: 'žiadny dom',

            countWoodenHouse: 'drevostavba',
            countWoodenHouseMultiple: 'drevostavieb',
            countNoWoodenHouse: 'žiadna drevostavba',

            countBrickHouse: 'murovaný dom',
            countBrickHouseMultiple: 'murovaných domov',
            countNoBrickHouse: 'žiadny murovaný dom',

            countReconstruction: 'rekonštrukcia',
            countReconstructionMultiple: 'rekonštrukciou',
            countNoReconstruction: 'žiadna rekonštrukcia',

            validationMessage: 'Vyplňte všetky povinné polia',

            projectWarningCosts: '*Mesačné náklady vychádzajú z ceny energie v čase realizácie',

            regions: {
                title: 'Kraj',
                praha: 'Hlavné mesto Praha',
                stredocesky: 'Stredočeský kraj',
                jihocesky: 'Juhočeský kraj',
                plzensky: 'Plzenský kraj',
                karlovarsky: 'Karlovarský kraj',
                ustecky: 'Úsťanský kraj',
                liberecky: 'Liberecký kraj',
                kralovehradecky: 'Královohradecký kraj',
                pardubicky: 'Pardubický kraj',
                vysocina: 'Kraj Vysočina',
                jihomoravsky: 'Juhomoravský kraj',
                olomoucky: 'Olomoucký kraj',
                zlinsky: 'Zlínsky kraj',
                moravskoslezky: 'Moravsko-sliezsky kraj',
            }
        },
        home: {
            header: "<b>Koľko ma bude stáť kúrenie</b><br />so skladbou Heated.?",
            headerText1: 'Zostavením skladby podlahy zistíte',
            headerText2: 'obstarávacie náklady',
            headerText3: 'a zároveň Vám vypočítame',
            headerText4: 'koľko vás bude stáť kúrenie',
            headerButton: 'Zostaviť skladbu',
            turnkeyHouses: 'Domy na <br />kľúč',
            turnkeyHousesNoBr: 'Domy na kľúč',
            devCompanies: 'Develop. / stav. <br />firmy',
            specialists: 'Špecialisti',
            floorIsolation: 'Izolácia podlahy',
            floorIsolatonBr: 'izolácia <br /> podlahy',
            startPrezentation: 'Spustiť prezentáciu',
            prezentationTitle: `<b>Za 10 rokov pôsobenia</b>
                <br />
                cez 3 000 realizácií vykurovanie`,
            skipVideo: 'Preskočiť video',
            configurator: 'Konfigurátor',
            materialSelection: 'Voľba materiálu',
            whitePolystyrene: 'Biely polystyrén',
            grayPolystyrene: 'Šedý polystyrén',
            cementScreed: 'Cementový <br /> poter',
            pir: 'Pir',
            wool: 'Vata',
            anhydrit: 'Anhydrit',
            gypsumFiberBoards: 'Sadrokartónové <br /> dosky',
            polishedConcrete: 'Leštený <br /> betón',
            laminatedFloor: 'Laminátová <br /> podlaha',
            thickness: 'Hrúbka',
            pvc: 'PVC',
            pavement: 'Dlažba',
            solidWood: 'Drevo masív',
            cementScreedNoBr: 'Cementový poter',
            gypsumFiberBoardsNoBr: 'Sadrovláknité dosky',
            polishedConcreteNoBr: 'Leštený betón',
            laminatedFloorNoBr: 'laminátová podlaha',
            isolationFloorLayer: 'Izolačná vrstva podlahy',
            totalAreaBuilding: 'Celková plocha vašej stavby',
            heightOfLayers: 'Výška vašej skladby',
            floorSpreadingLayer: 'Roznášacia vrstva podlahy',
            floorFinalLayer: 'Finálna vrstva podlahy',
            comparison: {
                breadcrumb: 'Porovnanie kúrení',
                title: '<b>porovnanie Heated.</b> <br />s inými systémami',
                item1: 'vyššia rýchlosť <br />nahriatia',
                item2: 'vykurovacia <br />plocha',
                item3: 'náklady na <br />servis',
            },
            energyFusion: {
                breadcrumb: 'Efektívny dom',
                title: '<b>inteligentné</b><br /> riešenie',
                text: 'Pozrite sa aj na naše ďalšie produkty v podobe automatizácie domácnosti a fotovoltaiky s batériovým úložiskom',
                label: 'Nastavte plochu vášho domu',
                avgSavings: 'priemerná <b>ročná úspora</b>',
                box1: {
                    title: 'fotovoltaika <br />s úložiskom',
                    text: 'Spojením najkvalitnejších komponentov technológie fotovoltaickej elektrárne, batériového úložiska a ich regulácie vznikol systém, ktorý dokáže maximálne využívať všetku vyrobenú energiu.'
                },
                box2: {
                    title: 'automatizácia <br /> domácnosti',
                    text: 'Loxone zjednodušuje ovládanie a inteligentne automatizuje projekty rôznych typov: od malých systémov pre inteligentné domácnosti (smart home) až po rozsiahle komerčné nehnuteľnosti.'
                },
            }
        },
        about: {
            title: 'Čo je Heated.',
            title2: 'O spoločnosti',
            header: {
                title: 'O produkte',
                itemTitle1: 'Pokrytie vykurovacieho prvku',
                itemTitle2: 'Náklady',
                itemTitle3: 'Počet realizácií',
                itemText1: 'úžitkovej plochy podlahy',
                itemText2: 'na servis a údržbu',
                itemText3: 'od roku 2014',
                itemValue1: 'až 90%',
                itemValue2: '0 €'
            },
            section01: {
                title: 'Nadštandardný komfort',
                text: '<span class="AnimationAbout--orange">Využitím technológie Heated.</span><span class="AnimationAbout--a1"> sa celá podlaha vášho domu premení na ideálny zdroj tepla.</span>',
                chartDesc: 'Porovnanie doby nahriatia o 1°C',
                valueTitle1: 'Elektrické rohože',
                valueTitle2: 'Teplovodné kúrenie',
                box1: {
                    title: 'Rovnomerný zdroj tepla',
                    text: 'Plocha topného systému je určujúcim faktorom efektivity vykurovania v novostavbách. Heated. využíva celoplošnú technológiu a vykurovací prvok tak pokrýva až 90% podlahovej plochy.',
                    note: 'Porovnanie pokrytia podlahy vykurovacou plochou systému',
                },
                box2: {
                    title: 'Rýchla zmena teploty',
                    text: 'Uhlíková technológia Heated. dokáže okamžite zvýšiť teplotu svojho povrchu a nahreje tak skladbu podlahy, ktorá sála teplo ďalej do miestnosti.',
                    note: 'Porovnanie rýchlosti nahriatia interiéru <strong class="AnimationAbout--black">z 19°C na 23°C',
                },
                box3: {
                    title: 'Ideálna klíma',
                    text: 'Technológia rovnomernej distribúcie tepla v celom priestore vytvára v novostavbách dokonalú teplotnú klímu',
                    note: 'Odchýlka meraných teplôt pri regulácii systému',
                }
            },
            section02: {
                title: 'Zabudnite na kúrenie',
                text: 'Vykurovacia sezóna trvá 270 dní v roku. To znamená, že väčšinu doby využívame kúrenie, ktoré priamo ovplyvňuje vnútornú klímu vo vašom dome.',
                chartTitle: 'Porovnanie kolísania teploty v priebehu dňa (regulácia nastavená na 23°C)',
                chartNote: 'Orientačné hodnoty zo vzorových domov',
                subsection01: {
                    title: 'Ako funguje Heated.',
                    box01: {
                        title: 'Okamžité zvýšenie povrchovej teploty',
                        text: 'Systém Heated. okamžite zvyšuje svoju teplotu v celej svojej ploche, ktorá kopíruje úžitkovú plochu podlahy.',
                        note: 'Teplota podlahy s Heated.',
                    },
                    box02: {
                        title: 'Akumulácia podlahovej vrstvy',
                        text: 'Rovnomerne nahriata podlahová vrstva je komfortným zdrojom sálania tepla do priestoru.',
                        note: 'Teplota podlahy s Heated.',
                    },
                    box03: {
                        title: 'Rovnomerná teplota v priestore',
                        text: 'Celý priestor si udržuje stálu teplotu a ideálne tak ohrieva vzduch',
                        note: 'Teplota vzduchu s Heated.',
                    },
                    box04: {
                        title: 'Príjemné vnútorné klíma',
                        text: 'Rovnomerne ohriaty vzduch a stála ideálna teplota podlahy tvorí príjemnú vnútornú klímu. Bez prehrievania vzduchu, bez kolísania teploty.',
                        note: 'Porovnanie rozmedzia teplôt s Heated.',
                    },
                },
                subsection02: {
                    title: 'Porovnanie prenosu tepla pri bežných systémoch',
                    valueTitle1: 'Teplota podlahy',
                    valueTitle2: 'Teplota vzduchu',
                    box01: {
                        title: 'Teplovodné kúrenie',
                        text: 'Teplovodné kúrenie je tvorené sieťou vodných trubiek s malou vykurovacou plochou a dlhým obehom. Distribúcia tepla do priestoru trpí nežiadúcou zotrvačnosťou a nie je rovnomerná, čo najmä v novostavbách spôsobuje kolísanie teplôt podlahy i vzduchu.',
                        note: 'Porovnanie rozmedzí teplôt s teplovodným systémom',
                    },
                    box02: {
                        title: 'Elektrické rohože',
                        text: 'Vykurovací prvok má veľmi malú plochu s minimálnym priemerom s vysokou povrchovou teplotou, čo môže mať za následok degradáciu materiálov podlahovej skladby. Podlaha je nárazovo prehrievaná alebo naopak trpí nedostatočnou výhrevnosťou.',
                        note: 'Porovnanie rozmedzia teplôt s elektrickou rohožou',
                    },
                },
                subsection03: {
                    title: 'Porovnanie nahriatia a udržania teploty v priebehu 24 hodín',
                    text1: 'Maximálna vykurovacia plocha, okamžitá reakcia a presná regulácia tvorí spoločne systém, ktorý ideálne reaguje na potreby domu a udržuje stálu ideálnu teplotu priestoru bez zásahu používateľa.',
                    text2: 'Teplovodný systém zložito obieha celým domom s rozdielnymi teplotami pri vstupe a výstupe, čo má v nízkoenergetickom priestore za následok kolísanie teploty podlahy i vzduchu',
                    text3: 'Vykurovací prvok v podobe odporového vodiča s veľmi tenkým priemerom. Dosahuje velmi vysokých povrchových teplôt a krátkodobo prehrieva podlahovú skladbu. Vplyvom malej vykurovacej plochy dochádza k zložitej regulácii.',
                    valueTitle1: 'Udržanie teploty',
                    valueTitle2: 'Kolísanie teploty',
                }
            },
            section03: {
                title: 'Optimálny vplyv na okolné materiály',
                text: 'Vlastnosti vykurovacieho systému zásadne ovplyvňujú životnosť a prípadnú degradáciu roznášacej vrstvy i finálnych krytín.',
                months: 'mes.',
                subsection01: {
                    title: 'Vplyv na skladbu podlahy',
                    text: 'Pre ideálnu životnosť jednotlivých častí podlahovej skladby je vyžadovaná šetrná prevádzka bez zásadných teplotných výkyvov. Liate potery i väčšina typov finálnych krytín majú stanovené maximálne limity teplotného zaťaženia. V prípade porušovania týchto limitov potom postupne dochádza k degradácii vlastností, prenosu tepla a najmä vzhľadu.'
                },
                subsection02: {
                    title: 'Prevádzkové teploty podlahových systémov',
                    text: 'Vlastnosti systému Heated. sú optimálne pre liate potery i väčšinu finálnych krytín. Vplyvom maximálnej topnej plochy systém pracuje s nižšou prevádzkovou povrchovou teplotou.'
                },
                subsection03: {
                    title: 'Pozitívny vplyv na konštrukciu domu',
                    text: 'Vo väčšine novostavieb dochádza k mokrým procesom, ktoré spôsobia navlhnutie konštrukcie. Vplyvom extrémnych izolačných vlastností stavby potom dochádza k veľmi zložitému zbavovaniu sa vnútornej vlhkosti, čo prináša množstvo nevýhod. Systém Heated. má vďaka rovnomernej akumulácií pozitívny vplyv na zásadné skrátenie procesu vysúšania.',
                    note: 'Ako dlho trvá proces odvlhčenia konštrukcie budovy?',
                    valueTitle1: 'Elektrické rohože',
                    valueTitle2: 'Teplovodné kúrenie',
                },
            },
            section04: {
                title: 'Nároky technológie',
                text: 'Systém Heated. neobsahuje žiadne mechanické komponenty vyžadujúce údržbu a neobmedzuje dispozície vašej stavby technickou miestnosťou.',
                subsection01: {
                    title: 'Bezúdržbová technológia',
                    text: 'Systém neobsahuje žiadne mechanické súčiastky. Vďaka tomu nevyžaduje žiadny servis ani údržbu. Systém Heated. sa skladá z uhlíkového vykurovacieho prvku a rozvodu napájania.'
                },
                subsection02: {
                    title: 'Servisné náklady',
                    titleNote: 'v horizonte 15 rokov',
                    options: ['Heated.', 'Teplovodné kúrenie', 'Elektrické rohože'],
                    label: 'Pravidelná ročná údržba',
                    box1: {
                        text: 'So systémom Heated. nie sú žiadne náklady na servis ani údržbu. Nečakajú vás tak žiadne ďalšie nečakané výdaje.',
                        column1: {
                            title: '',
                            value: '0 €',
                            note: 'Systém je zároveň zdrojom tepla a nemá externý zdroj vyžadujúci pravidelnú údržbu.',
                            subtitle: ''
                        },
                        column2: {
                            label: 'Priemerné ročné náklady na servis',
                            title: '',
                            value: '0 €',
                            note: '',
                            subtitle: ''
                        },
                    },
                    box2: {
                        text: 'Náklady na pravidelnú údržbu zdroja, náklady na čistenie rozvodov a veľmi vysoká pravdepodobnosť neočakávaných nákladov v podobe výmeny zdroja.',
                        column1: {
                            title: 'Tep. rozvody + plynový kotol',
                            value: '80 €',
                            note: 'Servisné náklady / výmena komponentov',
                            subtitle: 'Výmena kotla'
                        },
                        column2: {
                            label: 'Pravidelná ročná údržba',
                            title: 'Tep. rozvody + tepelné čerpadlo',
                            value: '120 €',
                            note: 'Servisné náklady / výmena komponentov',
                            subtitle: 'Výmena kompresora'
                        },
                    },
                    box3: {
                        text: 'Veľmi vysoká pravdepodobnosť degradácie finálnych krytín násobne skracuje ich životnosť. ',
                        column1: {
                            title: '',
                            value: '0 €',
                            note: 'Systém je zároveň zdrojom tepla a nemá externý zdroj vyžadujúci údržbu.',
                            subtitle: ''
                        },
                        column2: {
                            label: 'Priemerné ročné náklady na servis',
                            title: '',
                            value: '0 €*',
                            note: '*Riziko vzniku ďalších nákladov vplyvom degradácie skladby podlahy teplotnými rozdielmi.',
                            subtitle: ''
                        },
                    },
                },
                subsection03: {
                    title: 'Priestor pre život',
                    text: 'Bez nárokov na technickú miestnosť. Priemerná cena zastavanej plochy je 1400+ €. Priemerná veľkosť technickej miestnosti je 4 m2. Jedná sa tak o úsporu 6000+ €, ktorá sa dá využiť lepšie než ako zázemie pre zdroj tepla.'
                },
            },
            section05: {
                title: 'Spätná väzba klientov',
                text1: 'Vybrané',
                text2: 'so systémom.',
                linkText: 'skúsenosti našich zákazníkov',
                sideNote: 'Realizácií kúrenia',
                showMore: 'Zobraziť referencie',
            },
            section06: {
                title: 'Časté otázky',
                text: 'Výber najčastejšie kladených otázok k systému Heated.',
            },
            section07: {
                text: 'Nahliadnite do procesu rozhodovania rodiny, ktorá si prešla tým, čím si pravdepodobne práve prechádzate i vy. Od počiatočných informácií až po výber dodávateľa.',
            },
            section08: {
                title: 'Porovnanie systémov vykurovania',
                text: 'Porovnanie vlastností Heated. s bežnými systémami vykurovania.',
                rohoz: [
                    [
                        ['60-90%', 'Vysoké pokrytie úžitkovej plochy podlahy'],
                        ['Pokrytie podlahy', 'Plocha podlahy pokrytá vykurovacím prvkom systému'],
                        ['6-12%', 'Malé pokrytie úžitkovej plochy podlahy'],
                    ],
                    [
                        ['10 min', 'Roznášacia vrstva je zahriata rýchlo a celoplošne'],
                        ['Rýchlosť nahriatia (1°C)', 'Čas za ktorý sa ohreje vzduch v miestnosti o 1°C'],
                        ['30 min', 'Pomalé nahriatie spôsobené malým pokrytím vykurovacieho prvku'],
                    ],
                    [
                        ['NIE', 'Neobsahuje súčiastky náchylné na poškodenie'],
                        ['Poruchové súčiastky', 'Súčiastky náchylné na poškodenie vyžadujúce údržbu a servis'],
                        ['NIE', 'Neobsahuje súčiastky náchylné na poškodenie'],
                    ],
                    [
                        ['520 €', 'Výkon systému nie je nijak ovplyvnený vonkajšou teplotou'],
                        ['Prevádzkové náklady', 'Kalkulované pre stavbu 140 m2 s tepelnou stratou 5,6 kW za 15 rokov'],
                        ['880 €', 'Počítané s produktom bežnej kvality'],
                    ],
                    [
                        ['0 € / rok', 'Jedná sa o bezúdržbový systém'],
                        ['Náklady na údržbu', 'Spôsobené opotrebovaním mechanických súčiastok a ďaľších komponentov'],
                        ['0 € / rok', 'Jedná sa o bezúdržbový systém'],
                    ],
                    [
                        ['0 € / rok', 'Jedná sa o systém so životnosťou cez 30 rokov bez straty účinnosti'],
                        ['Náklady na servis', 'ročný priemer za servis, repasáciu a výmeny v horizonte 15 rokov'],
                        ['0 € / rok', 'Samotný systém nie je náchylný na poruchy'],
                    ],
                    [
                        ['NIE', 'Bez zásahu do fasády'],
                        ['Zásah do fasády', 'narušenie vzhľadu stavby'],
                        ['NIE', 'Bez zásahu do fasády'],
                    ],
                    [
                        ['NIE', 'Možnosť iného využitia priestoru - napr. druhá kúpeľňa'],
                        ['Technická miestnosť / obmedzenie dispozície', 'Požiadavka technológie na technickú miestnosť obmedzujúcu využiteľný priestor v interiéri'],
                        ['NIE', 'systém bez technickej miestnosti'],
                    ],
                    [
                        ['NIE', 'Vykurovací prvok nepresahuje teploty poškodzujúce vrstvu podlahy'],
                        ['Prehrievanie podlahy', 'Zahrievanie roznášacej a finálnej vrstvy podlahy nad teploty povolené výrobcami'],
                        ['ÁNO', 'Vykurovací prvok presahuje teploty povolené výrobcami podláh'],
                    ],
                    [
                        ['NIE', 'Vykurovací prvok nepresahuje teploty poškodzujúce vrstvy podlahy'],
                        ['Degradácia vrstiev podlahy', 'Poškodzovanie roznášacej a finálnej vrstvy podlahy vplyvom zahrievania nad výrobcami povolené teploty'],
                        ['ÁNO', 'Vykurovací prvok presahuje teploty povolené výrobcami podláh'],
                    ],
                    [
                        ['NIE', 'Rovnomerné nahriatie konštrukcie vplyvom celoplošnosti a vlnovej dĺžky'],
                        ['Rozdielne teploty v konštrukcii stavby', 'Rozdiel teplôt v konštrukcii vedie k vlhnutiu a znamená riziko degradácie stavby'],
                        ['ÁNO', 'Systém nezahrieva rovnomerne celú konštrukciu'],
                    ],
                    [
                        ['NIE', 'Systém predchádza vlhnutiu rovnomerným nahriatím konštrukcie'],
                        ['Vlhkosť v konštrukcii', 'Vznik vlhkosti vedúci k degradácii konštrukcie, vzniku baktérií a pliesní'],
                        ['ÁNO', 'Nerovnomerné zahriatie vedie k vlhnutiu konštrukcie'],
                    ],
                    [
                        ['NIE', 'Konštantná teplota vďaka celoplošnosti a umiestnenia senzorov'],
                        ['Rozdiel teplôt v interiéri', 'Rozdielne teploty podlahy a vzduchu'],
                        ['ÁNO', 'Intervaly prehrievania a chladnutia'],
                    ],
                    [
                        ['NIE', 'Vplyvom veľkého pokrytia podlahy nedochádza k rozdielom teplôt'],
                        ['Rozdiel teplôt na podlahe', 'Teplé a chladné miesta na podlahe'],
                        ['ÁNO', 'Vplyvom nízkeho pokrytia podlahy'],
                    ],
                    [
                        ['NIE', ''],
                        ['Hlučnosť systému', 'Systém s hlučným zdrojom'],
                        ['NIE', ''],
                    ],
                    [
                        ['NIE', 'Systém neprehrieva podlahu a predchádza vysúšaniu vzduchu'],
                        ['Vysúšanie vzduchu', 'Vysúšanie vzduchu je spôsobené prehriatím podlahy'],
                        ['ÁNO', 'Systém prehrieva podlahu a vysúša tak vzduch'],
                    ],
                    [
                        ['ÁNO', 'Systém neprehrieva podlahu a predchádza víreniu prachu'],
                        ['Vhodnosť pre alergikov (vírivosť prachu)', 'Vplyv technológie na vírivosť prachu a následnú dráždivosť pre alergikov'],
                        ['NIE', 'Systém prehrieva podlahu za účelom nahriatia vzduchu a spôsobuje tak vírenie prachu'],
                    ],
                    [
                        ['NIE', 'Vplyvom rovnomerného nahriatia konštrukcie zabraňuje vzniku baktérií a pliesní'],
                        ['Vznik baktérií a pliesní', 'Vznik baktérií a pliesní vplyvom vlhkosti v konštrukcii'],
                        ['ÁNO', 'Vplyvom nerovnomerného nahriatia konštrukcie a vlhnutím'],
                    ],
                    [
                        ['ÁNO', 'Bez komplikácií'],
                        ['Možnosť inteligentnej regulácie', 'Možnosť napojenia na systém smart home'],
                        ['ÁNO', 'Bez komplikácií'],
                    ],
                    [
                        ['ÁNO', 'Bez komplikácií'],
                        ['Možnosť regulovateľných okruhov', 'Možnosť nastavenia samostatne regulovateľných okruhov'],
                        ['ÁNO', 'Bez komplikácií'],
                    ],
                    [
                        ['ÁNO', 'Moderná technológia pripravená pre využitie s FVE'],
                        ['Možnosť napojenia FVE', 'Prepojenie technológie s fotovoltaickou elektrárňou (FVE)'],
                        ['ÁNO', 'V závislosti na prevedení a prepojení dodávateľov rohoží s FVE je systém schopný využiť energiu z vlastnej výroby'],
                    ],
                    [
                        ['ÁNO', 'Bez komplikácií'],
                        ['Možnosť stropného vykurovania', 'Možnosť inštalácie technológie ako stropné vykurovanie'],
                        ['NIE', 'Nie je možné'],
                    ],
                    [
                        ['NIE', 'Systém sám vodu neohrieva. Vo vývoji'],
                        ['Ohrev teplej vody', 'Schopnosť systému ohrievať teplú vodu pre domácnosť'],
                        ['NIE', 'Systém sám vodu neohrieva'],
                    ],
                ],
                voda: [
                    [
                        ['60-90%', 'Vysoké pokrytie úžitkovej plochy podlahy'],
                        ['Pokrytie podlahy', 'Plocha podlahy pokrytá vykurovacím prvkom systému'],
                        ['10-16%', 'Malé pokrytie úžitkovej plochy podlahy'],
                    ],
                    [
                        ['10 min', 'Roznášacia vrstva je zahrievaná rýchlo a celoplošne'],
                        ['Rýchlosť náhrevu (1°C)', 'Čas za ktorý sa ohreje vzduch v miestnosti o 1°C'],
                        ['75 min', 'Pomalý náhrev spôsobený malým pokrytím vykurovacieho prvku'],
                    ],
                    [
                        ['NIE', 'Neobsahuje súčiastky náchylné na poškodenie'],
                        ['Poruchové súčiastky', 'Súčiastky náchylné na poškodenie, vyžadujúce údržbu a servis'],
                        ['ÁNO', 'Obsahuje súčiastky náchylné na poškodenie'],
                    ],
                    [
                        ['520 €', 'Výkon systému nie je nijak ovplyvnený vonkajšou teplotou'],
                        ['Prevádzkové náklady', 'Kalkulácia pre stavbu 140 m2 s tepelnou stratou 5,6 kW na 15 rokov'],
                        ['400 €', '(vzduch-voda), počítané s teplotami pod bodom mrazu'],
                    ],
                    [
                        ['0 € / rok', 'Jedná sa o bezúdržbový systém'],
                        ['Náklady na údržbu', 'Spôsobené opotrebovaním mechanických súčiastok a ďalších komponentov'],
                        ['48 € / rok', 'Priemerné ročné náklady'],
                    ],
                    [
                        ['0 € / rok', 'Jedná sa o systém so životnosťou vyše 30 rokov bez straty účinnosti'],
                        ['Náklady na servis', 'Ročný priemer za servis, repasáciu a výmeny v horizonte 15 rokov'],
                        ['204 € / rok', 'Priemerné ročné náklady'],
                    ],
                    [
                        ['NIE', 'Bez zásahu do fasády'],
                        ['Zásah do fasády', 'Narušenie vzhľadu stavby'],
                        ['ÁNO', 'Vonkajšia jednotka narušuje fasádu a môže byť zdrojom hluku'],
                    ],
                    [
                        ['NIE', 'Možnosť iného využitia priestoru - napr. druhá kúpeľňa'],
                        ['Technická miestnosť / obmedzenie dispozícií', 'Požiadavka technológie na technickú miestnosť obmedzujúcu využiteľný priestor v interiéri'],
                        ['ÁNO', 'Systém vyžaduje technickú miestnosť'],
                    ],
                    [
                        ['NIE', 'Vykurovací prvok nepresahuje teploty poškodzujúce vrstvy podlahy'],
                        ['Prehrievanie podlahy', 'Zahrievanie roznášacej a finálnej vrstvy podlahy nad teploty povolené výrobcami'],
                        ['NIE', 'Vykurovací prvok nepresahuje teploty poškodzujúce vrstvy podlahy'],
                    ],
                    [
                        ['NIE', 'Vykurovací prvok nepresahuje teploty poškodzujúce vrstvy podlahy'],
                        ['Degradácia vrstiev podlahy', 'Poškodzovanie roznášacej a finálnej vrstvy podlahy vplyvom zahrievania nad výrobcami povolené teploty'],
                        ['NIE', 'Vykurovací prvok nepresahuje teploty poškodzujúce vrstvy podlahy'],
                    ],
                    [
                        ['NIE', 'Rovnomerné nahriatie konštrukcie vplyvom celoplošnosti a vlnovej dĺžky'],
                        ['Rozdielne teploty v konštrukcii stavby', 'Rozdiel teplôt v konštrukcii vedie k vlhnutiu a znamená riziko degradácie stavby'],
                        ['ÁNO', 'Systém nenahrieva rovnomerne celú konštrukciu'],
                    ],
                    [
                        ['NIE', 'Systém predchádza vlhnutiu rovnomerným nahriatím konštrukcie'],
                        ['Vlhkosť v konštrukcii', 'Vznik vlhkosti vedúci k degradácii konštrukcie, vzniku baktérií a pliesní'],
                        ['ÁNO', 'Nerovnomerné nahriatie vedie k vlhnutiu konštrukcie'],
                    ],
                    [
                        ['NIE', 'Konštantná teplota vplyvom celoplošnosti a umiestnenia senzorov'],
                        ['Rozdiel teplôt v interiéri', 'Rozdielne teploty podlahy a vzduchu'],
                        ['ÁNO', 'Požadovaná teplota vzduchu a podlahy len 4 z 24 hodín'],
                    ],
                    [
                        ['NIE', 'Vplyvom veľkého pokrytia podlahy nedochádza k rozdielom teplôt'],
                        ['Rozdiel teploty na podlahe', 'Teplé a chladné miesta na podlahe'],
                        ['ÁNO', 'Rozdielne teploty na podlahe vplyvom malého pokrytia'],
                    ],
                    [
                        ['NIE', ''],
                        ['Hlučnosť systému', 'Systém s hlučným zdrojom'],
                        ['ÁNO', ''],
                    ],
                    [
                        ['NIE', 'Systém neprehrieva podlahu a predchádza vysúšaniu vzduchu'],
                        ['Vysúšanie vzduchu', 'Vysúšanie vzduchu je spôsobené prehriatím podlahy'],
                        ['ÁNO', 'Systém prehrieva podlahu a vysušuje tak vzduch'],
                    ],
                    [
                        ['ÁNO', 'Systém neprehrieva podlahu a predchádza víreniu prachu'],
                        ['Vhodnosť pre alergikov (vírivosť prachu)', 'Vplyv technológie na vírivosť prachu a následná dráždivosť pre alergikov'],
                        ['NIE', 'Systém prehrieva podlahu za účelom nahriatia vzduchu a spôsobuje tak vírenie prachu'],
                    ],
                    [
                        ['NIE', 'Vplyvom rovnomerného nahriatia konštrukcie zamedzuje vzniku baktérií a pliesní'],
                        ['Vznik baktérií a pliesní', 'Vznik baktérií a pliesní vplyvom vlhkosti v konštrukcií'],
                        ['ÁNO', 'Vplyvom nerovnomerného nahriatia konštrukcie a vlhnutia'],
                    ],
                    [
                        ['ÁNO', 'Bez komplikácií'],
                        ['Možnosť inteligentnej regulácie', 'Možnosť napojenia na systém smart home'],
                        ['ÁNO', 'Pre plnohodnotné využitie zvyšuje vstupnú investíciu smart home i rozvodov'],
                    ],
                    [
                        ['ÁNO', 'Bez komplikácií'],
                        ['Možnosť regulovateľných okruhov', 'Možnosť nastavenia samostatne regulovateľných okruhov'],
                        ['ÁNO', 'Zložité. Spojenie s výrazným nárastom vstupných investícií na rozvody hadice'],
                    ],
                    [
                        ['ÁNO', 'Moderná technológia pripravená pre využitie s FVE'],
                        ['Možnosť napojenia FVE', 'Prepojenie technológie s fotovoltaickou elektrárňou (FVE)'],
                        ['KOMPLIKOVANÉ', 'Náročné napojenie'],
                    ],
                    [
                        ['ÁNO', 'Bez komplikácií'],
                        ['Možnosť stropného vykurovania', 'Možnosť inštalácie technológie ako stropné vykurovanie'],
                        ['NIE', 'Nie je možné'],
                    ],
                    [
                        ['NIE', 'Systém sám vodu neohrieva Vo vývoji'],
                        ['Ohrev teplej vody', 'Schopnosť systému ohrievať teplú vodu pre domácnosť'],
                        ['ÁNO', 'Zaisťuje ohrev. V prípade poruchy systému je domácnosť bez teplej vody'],
                    ],
                ],
                plyn: [
                    [
                        ['60-90%', 'Vysoké pokrytie úžitkovej plochy podlahy'],
                        ['Pokrytie podlahy', 'Plocha podlahy pokrytá topným prvkom systému'],
                        ['10-16%', 'Malé pokrytie úžitkovej plochy podlahy'],
                    ],
                    [
                        ['10 min', 'Roznášacia vrstva je zahrievaná rýchlo a plošne'],
                        ['Rýchlosť náhrevu (1°C)', 'Čas, za ktorý sa ohreje vzduch v miestnosti o 1°C'],
                        ['75 min', 'Pomalý náhrev spôsobený malým pokrytím vykurovacieho prvku'],
                    ],
                    [
                        ['NIE', 'Neobsahuje súčiastky náchylné na poškodenie'],
                        ['Poruchové súčiastky', 'Súčiastky náchylné na poškodenie, vyžadujúce údržbu a servis'],
                        ['ÁNO', 'Obsahuje súčiastky náchylné na poškodenie'],
                    ],
                    [
                        ['520 €', 'Výkon systému nie je nijak ovplyvnený vonkajšou teplotou'],
                        ['Prevádzkové náklady', 'Kalkulácia pre stavbu 140 m2 s tepelnou stratou 5,6 kW za 15 rokov'],
                        ['560 €', 'Počítané s produktom bežnej kvality'],
                    ],
                    [
                        ['0 € / rok', 'Jedná sa o bezúdržbový systém'],
                        ['Náklady na údržbu', 'Spôsobené opotrebovaním mechanických súčiastok a ďalších komponentov'],
                        ['40 € / rok', 'Priemerné ročné náklady'],
                    ],
                    [
                        ['0 € / rok', 'Jedná sa o systém so životnosťou cez 30 rokov bez straty účinnosti'],
                        ['Náklady na servis', 'Ročný priemer za servis, repasáciu a výmeny v horizonte 15 rokov'],
                        ['112 € / rok', 'Priemerné ročné náklady'],
                    ],
                    [
                        ['NIE', 'Bez zásahu do fasády'],
                        ['Zásah do fasády', 'Narušenie vzhľadu stavby'],
                        ['NIE', 'Bez zásahu do fasády'],
                    ],
                    [
                        ['NIE', 'Možnosť iného využitia priestoru - napr. druhá kúpeľňa'],
                        ['Technická miestnosť / obmedzenie dispozícií', 'Požiadavka technológie na technickú miestnosť obmedzujúca využiteľný priestor v interiéri'],
                        ['ÁNO', 'Systém vyžaduje technickú miestnosť'],
                    ],
                    [
                        ['NIE', 'Vykurovací prvok nepresahuje teploty poškodzujúce vrstvy podlahy'],
                        ['Prehrievanie podlahy', 'Zahrievanie roznášacej a finálnej vrstvy podlahy nad teploty povolené výrobcami'],
                        ['NIE', 'Vykurovací prvok nepresahuje teploty poškodzujúce vrstvy podlahy'],
                    ],
                    [
                        ['NIE', 'Vykurovací prvok nepresahuje teploty poškodzujúce vrstvy podlahy'],
                        ['Degradácia vrstiev podlahy', 'Poškodzovanie roznášacej a finálnej vrstvy podlahy vplyvom zahrievania nad výrobcami povolené teploty'],
                        ['NIE', 'Topný prvok nepresahuje teploty poškodzujúce vrstvy podlahy'],
                    ],
                    [
                        ['NIE', 'Rovnomerné prehriatie konštrukcie vplyvom celoplošnosti a vlnovej dĺžky'],
                        ['Rozdielne teploty v konštrukcii stavby', 'Rozdiel teplôt v konštrukcii vedie k vlhnutiu a znamená riziko degradácie stavby'],
                        ['ÁNO', 'Systém neprehrieva rovnomerne celú konštrukciu'],
                    ],
                    [
                        ['NIE', 'Systém predchádza vlhnutiu rovnomerným nahriatím konštrukcie'],
                        ['Vlhkosť v konštrukcii', 'Vznik vlhkosti vedúci k degradácii konštrukcie, vzniku baktérií a pliesní'],
                        ['ÁNO', 'Nerovnomerné nahriatie vedie k vlhnutiu konštrukcie'],
                    ],
                    [
                        ['NIE', 'Konštantná teplota vplyvom celoplošnosti a umiestnenia senzorov'],
                        ['Rozdiel teplôt v interiéri', 'Rozdielne teploty podlahy a vzduchu'],
                        ['ÁNO', 'Požadovaná teplota vzduchu a podlahy len 4 z 24 hodín'],
                    ],
                    [
                        ['NIE', 'Vplyvom veľkého pokrytia podlahy nedochádza k rozdielom teplôt'],
                        ['Rozdiel teploty na podlahe', 'Teplé a studené miesta na podlahe'],
                        ['ÁNO', 'Rozdielne teploty na podlahe vplyvom malého pokrytia'],
                    ],
                    [
                        ['NIE', ''],
                        ['Hlučnost systému', 'Systém s hlučným zdrojom'],
                        ['NIE', ''],
                    ],
                    [
                        ['NIE', 'Systém neprehrieva podlahu a predchádza vysúšaniu vzduchu'],
                        ['Vysúšanie vzduchu', 'Vysušovanie vzduchu je spôsobené prehriatím podlahy'],
                        ['ÁNO', 'Systém prehrieva podlahu a vysušuje tak vzduch'],
                    ],
                    [
                        ['ÁNO', 'Systém neprehrieva podlahu a predchádza víreniu prachu'],
                        ['Vhodnosť pre alergikov (vírivosť prachu)', 'Vplyv technológie na vírivosť prachu a následná dráždivosť pre alergikov'],
                        ['NIE', 'Systém prehrieva podlahu za účelom nahriatia vzduchu a spôsobuje tak vírenie prachu'],
                    ],
                    [
                        ['NIE', 'Vplyvom rovnomerného nahriatia konštrukcie predchádza vzniku baktérií a pliesní'],
                        ['Vznik baktérií a pliesní', 'Vznik baktérií a pliesní vplyvom vlhkosti v konštrukcii'],
                        ['ÁNO', 'Vplyvom nerovnomerného nahriatia konštrukcie a vlhnutím'],
                    ],
                    [
                        ['ÁNO', 'Bez komplikácií'],
                        ['Možnosť inteligentnej regulácie', 'Možnosť napojenia na systém smart home'],
                        ['ÁNO', 'Pre plnohodnotné využitie zvyšuje vstupnú investíciu smart home i rozvodov'],
                    ],
                    [
                        ['ÁNO', 'Bez komplikácií'],
                        ['Možnosť regulovateľných okruhov', 'Možnosť nastavenia samostatne regulovateľných okruhov'],
                        ['ÁNO', 'Zložité. Spojenie s výrazným nárastom vstupnej investície na rozvody hadice'],
                    ],
                    [
                        ['ÁNO', 'Moderná technológia pripravená pre využitie s FVE'],
                        ['Možnosť napojenia FVE', 'Prepojenie technológie s fotovoltaickou elektrárňou (FVE)'],
                        ['NIE', 'Napojenie nie je možné'],
                    ],
                    [
                        ['ÁNO', 'Bez komplikácií'],
                        ['Možnosť stropného vykurovania', 'Možnosť inštalácie technológie ako stropné vykurovanie'],
                        ['NIE', 'Nie je možné'],
                    ],
                    [
                        ['NIE', 'Systém sám vodu neohrieva Vo vývoji'],
                        ['Ohrev teplej vody', 'Schopnosť systému ohrievať teplú vodu pre domácnosť'],
                        ['ÁNO', 'Zaisťuje ohrev. V prípade poruchy systému je domácnosť bez teplej vody'],
                    ]
                ]
            }
        },
        oldAbout: {
            title: 'Čo pre vás znamená<br /><strong>teplotná pohoda</strong>?',
            box1: {
                title: 'Ideálna teplota',
                text: 'Aká izbová teplota<br/>Vám vyhovuje?'
            },
            box2: {
                title: 'Počasie',
                text: 'Za akého počasia sa doma<br />doma cítite najlepšie?'
            },
            box3: {
                title: 'Denná doba',
                text: 'O koľkej doma<br/>najčastejšie relaxujete?'
            },
            claim1: 'A teraz <strong>si predstavte</strong> miesto...',
            claim2: 'kde cítite <strong>dokonalé pohodlie...</strong>',
            claim3: 'a nie je vám teplo <strong>ani zima</strong>.',
            claim4: 'Zabudnite na kúrenie',
            claim5: 'poznajte princíp Heated...',
            section01: {
                title: 'dokonalý <br /> komfort',
                text: 'V interiéri je presne udržovaná stála ideálna teplota podlahy i vzduchu nezávisle na vonkajších podmienkach. Systém tvorí dokonalú vnútornú klímu bez nutnosti zásahu užívateľa.',
                button: 'Graf nahriatia',
                text1: 'Plocha vykurovacieho systému, teda časť, ktorá reálne nahrieva podlahu, je rozhodujúcim faktorom prenosu tepla do priestoru. Vďaka veľkému pokrytiu vykurovacieho prvku sa znižuje potreba na jeho povrchovú teplotu, čo ovplyvňuje celkový spôsob prevádzky. Celoplošný systém sa zapína na veľmi krátke vykurovacie intervaly, ktorými efektívne udržuje stálu ideálnu teplotu podlahy aj priestoru.',
                text2: 'Systém s reálne malou vykurovacou plochou, ktorá spočíva v tenkom priereze rúrky, pre svoju prevádzku nevyhnutne vyžaduje vyššiu povrchovú teplotu, ktorá je navyše kvôli dĺžke obehu rozdielna. V budovách s nízkou energetickou náročnosťou má táto vlastnosť za následok pomalý nábeh teplôt, veľmi zložitú reguláciu, nežiaducu zotrvačnosť a prevádzku v podobe striedania fáz ohrevu a chladnutia. Z tohto dôvodu potom v priebehu dňa kolíšu teploty podlahy i vzduchu.'
            },
            section02: {
                title: 'Roznášacia<br /> vrstva',
                text: 'Využiteľnosť so všetkými bežnými typmi akumulačných vrstiev. Heated. je, ako jediný systém svojho druhu, oficiálne zahrnutý v certifikovaných skladbách podlahy hlavných výrobcov akumulačných vrstiev na trhu. Systém je inštalovaný pod akumulačnú vrstvu anhydritového / cementového poteru alebo sadrokartónovej dosky Fermacell, a ideálne tak využíva jej akumulačné vlastnosti.',
                button: 'Certifikované skladby',
                title1: 'certifikované <br /> skladby',
                text1: 'Využiteľnosť so všetkými bežnými typmi akumulačných vrstiev. Heated. je, ako jediný systém svojho druhu, oficiálne zahrnutý v certifikovaných skladbách podlahy hlavných výrobcov akumulačných vrstiev na trhu. Systém je inštalovaný pod akumulačnú vrstvu anhydritového / cementového poteru alebo sadrokartónovej dosky Fermacell, a ideálne tak využíva jej akumulačné vlastnosti.',
                button1: 'katalóg Heated.',
                button2: 'katalóg Heated.',
            },
            section03: {
                title: 'Celoplošné <br /> pokrytie',
                text: 'Vykurovací prvok pokrýva okolo 90% reálne využívanej podlahovej plochy. Veľkosť vykurovacej plochy je v dnešných moderných budovách kľúčovým parametrom pre efektívny prenos tepla do priestoru.',
                button: 'Infrasnímka',
            },
            section04: {
                title: 'bezporuchový <br /> systém',
                text: 'Vykurovací systém Heated. neobsahuje žiadne komponenty náchylné na poškodenie ani údržbu a nevyžaduje tak žiadny servis. Životnosť systému prekonáva bežnú životnosť podlahy.',
                button: 'Porovnanie nákladov',
            },
            section05: {
                title: 'Ďalšie výhody',
                box1: {
                    title: 'Vysoká rýchlosť<br /> nahriatia',
                    text: 'Technológia Heated. je zložená z uhlíkových vlákien, ktoré majú výbornú tepelnú vodivosť a zaisťujú okamžitý nárast teploty na svojom povrchu.'
                },
                box2: {
                    title: 'Bez teplotných rozdielov<br />v konštrukcii',
                    text: 'Systém rovnomerne akumuluje celú obálku budovy a zabraňuje tak tvorbe vlhkosti a s ňou spojenému vzniku prostredia pre baktérie a plesne.'
                },
                box3: {
                    title: 'Rýchla a presná <br /> regulácia',
                    text: 'Systém Heated. vyniká rýchlym a dokonale presným ovládaním teploty podlahy i vzduchu.'
                },
                box4: {
                    title: 'Nízke prevádzkové <br /> náklady',
                    text: 'Vďaka ideálnemu prenosu tepla do priestoru vykazuje systém veľmi nízke prevádzkové náklady.'
                }
            },
            section06: {
                title: 'Porovnanie nahriatia',
                box: {
                    title: 'porovnanie nahriatia',
                    itemTitle: 'tepelné <br /> čerpadlo',
                    label1: 'teplota vzduchu',
                    label2: 'Dĺžka nahriatia',
                    label3: 'T. čerpadlo',
                    label4: 'teplota podlahy',
                    label5: 'doba <br /> nahriatie',
                    label6: 'vykurovacia <br /> plocha',
                    label7: 'Požadovaná <br /> teplota (24h)',
                }
            },
            section07: {
                title: 'POROVNANIE NÁKLADOV',
                subtitle: 'obstarávacie – servisné – prevádzkové',
                label3: 'Dĺžka porovnania'
            }
        },

        mobilePage: {
            responseTitle: 'Žiadosť bola odoslaná',
            section01: {
                title: 'vlastnosti <br/><b>Heated.</b>',
                text: 'Vplyvom rýchleho vývoja stavebných materiálov a legislatívnych požiadaviek sa vlastnosti budov v posledných rokoch výrazne zmenili. Novostavby dnes vykazujú veľmi špecifické nároky na technológie, ktoré je nevyhnutné voliť priamo v súvislosti s vlastnosťami daného typu stavby. Sú to totiž práve technológie, najmä vykurovanie, ktoré vytvárajú kľúčový rozdiel v kvalite a komforte života vovnútri budovy.<br/><br/> Vlastnosti technológie Heated. sú navrhnuté presne pre využitie v moderných domoch a dokonalo spĺňajú všetky ich potreby.<br/><br/>Zabudnite na kúrenie – spoznajte Heated.',
                title01: 'Tvorí stálu tepelnú pohodu',
                text01: 'V interiéri je presne udržiavaná stála ideálna teplota podlahy i vzduchu nezávisle na vonkajších podmienkach. Systém tvorí dokonalú vnútornú klímu bez nutnosti zásahu používateľa.',
                title02: 'Pokrýva celú plochu podlahy',
                text02: 'Vykurovací prvok pokrýva až 90 % reálne využívanej podlahovej plochy. Veľkosť vykurovacej plochy je kľúčovým parametrom pre efektívny prenos tepla do priestoru v dnešných moderných budovách.',
                title03: 'Inštalácia pod roznášaciu vrstvu',
                text03: 'Systém je inštalovaný pod roznášaciu vrstvu anhydritového/cementového poteru alebo sádrokartónových dosiek Fermacell a ideálne tak využíva jej akumulačné vlastnosti.',
                title04: 'Bezúdržbový a bezporuchový systém',
                text04: 'Vykurovací systém Heated. neobsahuje žiadne komponenty náchylné na poškodenie ani údržbu a nevyžaduje tak žiadny servis. Životnosť systému prekonáva bežnú životnosť podlahy samotnej.',
                title05: 'Nízke náklady',
                text05: 'Pri využití systému Heated. v moderných budovách je jeho veľkou výhodou veľmi nízky priemer celkových nákladov. Vďaka vysokej rýchlosti ohrevu, maximálnej topnej ploche a ideálnemu prenosu tepla do priestoru vykazuje systém nízke prevádzkové náklady, ktoré potom v súčte s obstarávacími nákladmi a nulovými servisnými nákladmi vytvára veľmi nízky priemer celkových relevantných nákladov na vykurovanie.',
                title06: 'Rýchlosť nahriatia',
                text06: 'Technológia Heated. je zložená z uhlíkových vlákien, ktoré majú výbornú tepelnú vodivosť a zaisťujú okamžitý nárast teploty na svojom povrchu. V kombinácií s ostatnými vlastnosťami potom v prípade potreby pri prevádzke dochádza k veľmi rýchlemu nábehu teploty, ktorý je tak využívaný pre krátky a efektívny vykurovací interval.',
                title07: 'Presná regulácia',
                text07: 'Veľmi dôležitou vlastnosťou kúrenia pri využití v moderných budovách je jeho regulovateľnosť. Systém Heated. vyniká dokonale presným ovládaním, ktoré zabraňuje nežiaducej pomalej reakcii na zmenu a zbytočnej teplotnej zotrvačnost. Systém je riadený pomocou podlahových i vzdušných čidiel, ktoré zaisťujú presnú reguláciu.',
                title08: 'Neprehrieva vzduch',
                text08: 'Vďaka kombinácii všetkých vlastností technológie Heated. nedochádza pri (bežnej) konštantnej prevádzke k tvorbe rozdielnych teplôt povrchu podlahy medzi dobou, kedy je systém zapnutý a kedy nie. Vďaka tomu tak nevzniká nežiadúce prehrievanie vzduchu a nadmerné vírenie prachu.',
                title09: 'Vhodná pre skladbu podlahy',
                text09: 'Technológia Heated. je vhodná pre využitie so všetkými finálnymi krytinami, ktoré výrobca určil pre možnosť aplikácie na podlahové kúrenie. Maximálne teploty vykurovacieho prvku Heated. sú obmedzené tak, aby nedochádzalo k degradácii roznášacej ani finálnej vrstvy ako pri bežných elektrických podlahových systémch.',
                title10: 'Využitie s FVE alebo iným zdrojom',
                text10: 'Vzhľadom k možnostiam zapojenia je systém Heated. kompatibilný s vlastným zdrojom výroby energie, ktorý má obmedzenú kapacitu tak, aby pri zapnutí nedošlo k okamžitému odberu všetkej vyrábanej alebo uloženej energie.',
                title11: 'Integrácia do Smart Home',
                text11: 'Technológiu Heated. je možné jednoducho integrovať do Smart Home systému, ktorý potom ponúkne riešenie vzdialeného ovládania, komplexnú automatizáciu i individuálne nastavenie odberu energie – vhodné práve pre využitie napr. s domácou fotovoltaickou elektrárňou.',
                title12: 'Bez hluku a náročnosti na priestor',
                text12: 'Kompletná technológia je zabudovaná v podlahe, nemá tak žiadny nárok na vlastný priestor v interiéri a v budove vzniká miesto pre vhodnejšie využitie zastavanej plochy. Systém samozrejme neobsahuje žiadnu vonkajšiu jednotku a nenarušuje tak dizajn fasády, a neobťažuje hlukom.',
                title13: 'Certifikované skladby',
                text13: 'Systém Heated. ako jediný svojho druhu je oficiálne zaradený v certifikovaných skladbách podlahy liatych poterov Cemex i suchej skladby Fermacell.',
                title14: 'Zabraňuje vlhnutiu konštrukcie',
                text14: 'Systém rovnomerne akumuluje celú obálku budovy a zabraňuje tak tvorbe vlhkosti a s ňou spojenému vzniku prostredia pre baktérie a pliesne.',
            },
            section02: {
                title: '<b>Porovnanie</b><br />kúrenia',
                title01: 'Vyššia vykurovacia plocha.',
                text01: 'Systém Heated. sa vyznačuje mnohonásobne vyššou vykurovacou plochou. Vďaka tomu dosiahnete veľmi rýchlo požadovanú teplotu v miestnosti.',
                title02: '<b>Úspora na vstupnej investícii</b> a porovnanie nákladov.',
                text02: 'Kvalita tepelného čerpadla sa priamo odvíja od jeho ceny. Rovnako tak rozvody a možnosť regulovať každú miestnosť zvlášť. Pri systéme Heated. nepremýšľate nad variantou, jedná sa vždy o bezporuchový a bezúdržbový produkt bez ďalších nákladov.',
                note: 'Porovnanie Heated. s ďalšími systémami vykurovania nájdete na počítačovej verzii našeho webu.'
            },
            section03: {
                title: '<b>Realizácie</b>',
                text: 'Od roku 2014 sme zrealizovali...',
                subtext01: 'podlahových plôch',
                subtext02: 'rodinných domov',
                subtext03: 'developerských projektov',
            },
            section04: {
                title: '<b>Cenová ponuka</b><br />Heated.',
                text: 'Naše špecializované projekčné oddelenie spracuje cenovú ponuku na mieru vášho domu vrátane odhadu ročnej spotreby.',
            },
        },
        priceOffer: {
            title: 'Cenová ponuka',
            header: 'Cenová ponuka <b>Heated. zdarma</b>',
            calculationRequest: 'Žiadosť o cenovú kalkuláciu zdarma'
        },
        caseStudy: {
            title_01: 'Naša cesta',
            title_02: 'k Heated.',
            section01: {
                title: '"<span class="orange">Rozhodli sme sa kúpiť dom</span> v menšom developerskom projekte, kde náš sused v rovnakom dome kúri tepelným čerpadlom."',
                text: `"S manželom sme žili cez 10 rokov v centre mesta a pred 2 rokmi sa nám narodilo dieťa, čo bol pre nás impulz odsťahovať sa mimo mesto.<br/><br/>
                Rozhodli sme sa pre menší developerský projekt. Sedem totožných domov, z toho tri už obývané a štyri v procese prípravy."`
            },
            section02: {
                title: 'Prečo ste začali zvažovať zmenu projektu?',
                text: `"Vlastne sme na začiatku nad žiadnou zmenou neuvažovali, v projekte bolo tepelné čerpadlo. Pri pravidelnej návšteve stavby sme si všimli hluk z vedľajšieho domu. Bolo nám povedané, že je to takto normálne, že sa jedná o kompresor čerpadla. <span class="orange">Vraveli sme si, že musí existovať elegantnejšie riešenie</span>, a tak začala naša zvedavosť ohľadom druhov kúrenia."`
            },
            section03: {
                title: 'Aké otázky boli pre vás zásadné pri voľbe technológie vykurovania?',
                text: `"Trvalo nám než sme si uvedomili, že systém vykurovania je jednou z hlavných technológií v dome. Jedná sa o vybavenie, ktoré človek zdanlivo nevidí, ale využíva ho väčšinu roku. Ak je teda nevyhovujúce, môže to byť zásadný problém. Nehľadiac na fakt, že do stavby vložíte celoživotné úspory a na veľkú časť života sa zadĺžite. <b>Chceli sme teda byť v každom ohľade spokojní a robiť čo najmenej kompromisov.</b> Preto sme vedľa obstarávacích nákladov zohľadňovali množstvo ďalších faktorov."`,
                tableTitle1: 'Radiátory',
                tableTitle2: 'Odporové káble',
                tableTitle3: 'Tepelné čerpadlo',
                question1: 'Chcete mať nízke prevádzkové náklady?',
                question2: 'Chcete mať bezporuchovú technológiu bez servisu?',
                question3: 'Chcete mať rýchlo požadovanú teplotu?',
                question4: 'Chcete mať doma komfort bez rozdielu teplôt?',
                question5: 'Nechcete mať v dome technickú miestnosť a hlučnú technológiu?',
            },
            section04: {
                title: 'Ktoré technológie ste v priebehu zvažovali?',
                text: `"Musím povedať, že to bolo od začiatku veľmi matúce. Každý nám odporúčal inú technológiu, väčšinou s nejasnými alebo neúplnými argumentami. Najprv sme hľadali čerpadlo, ktoré nebude robiť hluk. Postupom času sme tepelné čerpadlo vylúčili ako nenávratnú investíciu s kopou starostí. Stavebná firma nám odporučila odporové káble, čo sa nám páčilo do doby výpočtu nákladov a predovšetkým upozornenia dodávateľov podlahy na vysokú teplotu a možné poškodenie podlahy. Na internete sme narazili na systém Heated., ktorý sa nám páčil v každom zmienenom ohľade."`
            },
            section05: {
                title: 'Prvotná schôdzka ohľadom Heated.',
                text: `"Po úvodnom telefonáte sme navštívili pobočku so showroomom. Na schôdzke sme podrobne prebrali jednotlivé aspekty všetkých druhov kúrenia a porovnali ich s Heated. Do tej doby sa vždy hovorilo iba o jednom produkte. Prehľad informácií na nás urobil dojem a pochopili sme, že pre kompletné informácie je dobré zájsť za špecializovanou firmou na kúrenie. Konkrétne v tejto spoločnosti to bolo rádovo tisíce projektov ročne."`,
                column1: {
                    title: 'Architekt / Projektant',
                    text: 'Architekti a projektanti sú braní ako autorita pri návrhu domu. Parametre domu sa však s legislatívou veľmi rychlo menia a s ňou taktiež požiadavky na technológie vykurovania.',
                    claim: '1-50 projektov ročne'
                },
                column2: {
                    title: 'Stavebné firmy',
                    text: 'Stavebné firmy majú prehľad o najnovších materiáloch a dokážu vám poradiť s parametrami domu. Technológie, ako je kúrenie, majú však na tieto parametre veľký vplyv a je potreba ich dokonale poznať.',
                    claim: '20-50 projektov ročne'
                },
                column3: {
                    title: 'Špecialista na vykurovanie Heated.',
                    text: 'Našich 7 špecializovaných oddelení každoročne posúdi tisíce stavieb. Poznáme tak všetky úskalia moderných novostavieb, dokážeme tak navrhnúť kúrenie na mieru vašich potrieb.',
                    claim: '1000+ projektov ročne'
                },
            },
            section06: {
                title: 'Bol pre vás výber dodávateľa rovnako dôležitý?',
                text: `"Až na osobnej schôdzke vo firme Efektivní dům sme sa prvýkrát stretli s tým, že niekto dokázal zodpovedať všetky naše otázky. Tým spadli všetky naše obavy a <span class="orange">v tú chvíľu sme vedeli, že môžeme venovať pozornosť samotnému systému.</span>"`
            },
            section07: {
                title: 'Čo pre vás bol ten rozhodujúci argument pre voľbu Heated.?',
                text: `"Asi to nebol jeden konkrétny argument, ale spojenie všetkých faktorov. Čím viac sme toho o rôznych druhoch vykurovania vedeli, tým sme boli viac bezradní. <b>Keď sa nad tým spätne zamyslím, najviac nás presvedčila osobná schôdzka.</b> Samozrejme nás zaujímali taktiež financie. Prevádzkové náklady vychádzali u tepelného čerpadla o niečo lepšie, vstupná investícia a servisné náklady však z tepelného čerpadla urobili nevratnú investíciu. Naopak pri elektrických káblov boli nižšie obstarávacie náklady, ale 2x vyššie prevádzkové náklady. <b>Heated. je zlatá stredná cesta s nespočtom výhod.</b>"`,
                box01: {
                    title: 'Bezporuchový <br />systém',
                    text: '"Bezporuchovosť nám prišla ako hlavná výhoda. V priebehu rokov nevznikajú žiadne ďalšie náklady na servis ani údržbu. Výrobca garantuje životnosť cez 30 rokov bez straty účinnosti výkonu. Firma Efektivní dům poskytuje záruku 10-15 rokov."'
                },
                box02: {
                    title: 'Najvýhodnejšie <br />v súčte nákladov',
                    text: '"Pri porovnaní všetkých nákladov (vstupné, prevádzkové, servisné) poráža Heated. v priebehu 15 rokov všetky konkurenčné systémy. Znamená to teda, že najneskôr od polovice hypotéky oproti ostatným systémom šetríte."'
                },
                box03: {
                    title: 'Komfortná teplota <br />a vnútorná klíma',
                    text: '"Celoplošný systém zaručuje rýchly nábeh požadovanej teploty ako na podlahe, tak v miestnosti. Nedochádza tak k prehrievaniu podlahy ani vzduchu."'
                },
                box04: {
                    title: 'Bez hlučného zdroja <br />a technickej miestnosti',
                    text: '"V pôvodnom projekte zaberala technická miestnosť 8 m2. Dispozične sme zmenili technickú miestnosť na druhú kúpeľňu v izbe pre hostí."'
                },
                box05: {
                    title: 'Celoplošnosť',
                    text: 'Vykurovací prvok pokrýva viac než 90 % reálne využívanej podlahovej plochy. Veľkosť vykurovacej plochy je kľúčovým parametrom pre efektívny prenos tepla do priestoru v dnešných moderných budovách.'
                },
                box06: {
                    title: 'Rýchlosť náhrevu',
                    text: 'Technológia Heated. je zložená z uhlíkových vlákien, ktoré majú výbornú tepelnú vodivosť a zaisťujú okamžitý nárast teploty na svojom povrchu. V kombinácii s ostatnými vlastnosťami potom v prípade potreby počas prevádzky dochádza k veľmi rychlému nábehu teploty, ktorý je tak využívaný pre krátky a efektívny vyhrievaci interval.'
                },
                box07: {
                    title: 'Akumulácia podlahy',
                    text: 'Systém je inštalovaný pod roznášaciu vrstvu anhydritového/cementového poteru alebo sádrokartónové dosky Fermacell a ideálne tak využíva jej akumulačné vlastnosti.'
                },
                box08: {
                    title: 'Presná regulacácia',
                    text: 'Veľmi dôležitou vlastnosťou kúrenia pri využití v moderných budovách je jeho regulovateľnosť. Systém Heated. vyniká dokonale presným ovládaním, ktoré zabraňuje nežiadúcej pomalej reakcii na zmenu a zbytočnej teplotnej zotrvačnosti. Systém je ovládaný pomocou podlahových i vzdušných čidiel, ktoré zaisťujú dokonale presnú reguláciu.'
                },
                box09: {
                    title: 'Vhodné pre alergikov',
                    text: 'Vďaka kombinácii všetkých vlastností technológie Heated. nedochádza pri (bežnej) konštantnej prevádzke k tvorbe rozdielnych teplôt povrchu podlahy medzi dobou, kedy je systém zapnutý a kedy nie. Vďaka tomu tak nevzniká nežiadúce prehrievanie vzduchu a nadmerné vírenie prachu.'
                },
                box10: {
                    title: 'Vhodné pre skladbu podlahy',
                    text: 'Technológia Heated. je vhodná pre využitie so všetkými finálnymi krytinami, ktoré výrobca obecne určil pre možnosť aplikácie na podlahové vykurovanie. Maximálne teploty vykurovacieho prvku Heated. sú obmedzené tak, aby nedochádzalo k degradácii roznášacej ani finálnej vrstvy ako pri bežných elektrických podlahových systémoch.'
                },
                box11: {
                    title: 'Zabraňuje vlhnutiu konštrukcie a vzniku baktérií a pliesní',
                    text: 'Systém rovnomerne akumuluje celú obálku budovy a zabraňuje tak tvorbe vlhkosti a s ňou spojenému vzniku prostredia pre baktérie a pliesne.'
                },
            },
            section08: {
                title: 'Ako prebiehali prípravy na realizáciu a ako na to reagovala stavebná firma?',
                text: '"To vlastne ani nevieme. Všetky podklady sme dali <span class="orange">firme a Efektivní dům naviazala kontakt so všetkými účastníkmi stavby.</span> Vlastne boli jediní, s kým sme nemuseli byť denne na telefóne a neustále o niečom rozhodovať."'
            },
            section09: {
                client: '<b>Rodina Petríková</b><br /> z pohľadu klienta',
                heatlow: '<b>Pavel Dědouch</b><br /> odborník na kúrenie Heated.',
                title01: 'Ako prebiehala zmena projektu na systém Heated.?',
                textClient01: '"Zmeny sme sa trochu obávali, pretože sa jednalo o systém, ktorý nám developer v projekte neponúkal. Heat Energy však boli vďaka svojmu technickému a projekčnému oddeleniu schopní vyriešiť všetky aspekty priamo s architektom a stavebnou firmou."',
                textHeatflow01: '"Vďaka špecializovanému oddeleniu a skvele informovaným obchodne-technickým zástupcom nie je zmena projektu žiadny problém. Svedčí o tom naša spolupráca so stovkami architektov a projektantov po celej Českej a Slovenskej republike. Vo väčšine prípadov stačí jedna osobná schôdzka, kedy sú účastníci stavby zoznámeni s naším systémom a všetko ostatné už doriešia jednotlivé špecializované oddelenia."',
                title02: 'Ako dlhý proces bola realizácia Heated.?',
                textClient02: '"Realizácia kúrenia Heated. prebehla bez akýchkoľvek problémov. Obávali sme sa zdĺhavej koordinácie so stavebnou firmou, architektom a elektrikárom, ale tím Heated. sa postaral, aby bolo všetko pripravené. Inštalácia systému zabrala len jeden deň. O nič sme sa nemuseli starať."',
                textHeatflow02: '"V procese návrhu máme vlastné oddelenie špecializovaných projektantov, ktorí navrhnú riešenie na mieru klienta. Nasledovne pre elektrikára na stavbe vygenerujeme pripravenosť elektra, vďaka čomu jednoducho pripraví všetko potrebné pred inštaláciou Heated. Disponujeme taktiež oddelením prípravy, ktoré sa stará o to, aby v deň montáže bolo všetko pripravené a predanie stavby sa zbytočne nepredlžovalo. Inštalácia Heated. v dome do 150 m2 trvá len jeden deň."',
            },
            section10: {
                title: 'Ako sa žije v dome s celoplošným systémom vykurovania Heated.?',
                text: 'Väčšina našich zákazníkov si myslí, že sa jedná o nevyskúšanú novinku na trhu. <span class="orange">Opak je pravdou, na trhu sme už 11. rokom a za tú dobu sme zrealizovali kúrenie do viac než 3000 stavieb.</span> Sme schopní určiť, ako sa bude vaša stavba správať a vďaka tomu pomerne presne odhadnúť prevádzkové náklady. Odhad je súčasťou každej cenovej kalkulácie.',
                client: 'Rodina Petríková',
                box01: {
                    title: 'Aké máte skúsenosti z vašej prvej zimy?',
                    text: '"Samozrejmě sme boli na prvé vyúčtovanie veľmi zvedaví – už kvôli tomu, že sme dostali predbežný odhad spotreby a prišiel nám pomerne nízky. <span class="orange">Musíme však povedať, že odhad bol takmer presný</span> (boli sme upozornení, že náklady na kúrenie môžu byť počas prvej sezóny cca o 20 % vyššie z dôvodu zvýšeného množstva vody v konštrukcii)."'
                },
                box02: {
                    title: 'Hovorili ste o komfortnej teplote a vnútornej klíme',
                    text: '"Áno, spätne to považujem za najväčšie prekvapenie, na ktoré sme pri výbere nebrali taký ohľad. Bolo nám povedané, že komfort je hlavnou prednosťou, ale ako si niečo také predstaviť? Keby som to mala popísať, <span class="orange">povedala by som, že človeku nie je ani horúco, ani zima, jednoducho na kúrenie vôbec nemyslíte.</span> Je to veľmi ťažko popísateľný pocit, ktorý s predchádzajúcimi skúsenosťami nie je možné vôbec porovnávať."'
                },
                box03: {
                    title: 'Keby ste mali teraz porovnať kúrenie so susedom, čo by ste povedali?',
                    text: '"Sused sa neustále oháňal koeficientom účinnosti. Keď však prišla zima, mal porovnateľné náklady. <span class="orange">Na návšteve sme okamžite poznali veľký teplotný rozdiel na podlahe a vo vzduchu.</span> Nehľadiac na to, že v určitých miestach mal na podlahe studené miesta. Celkovo sme radi, že sme nesúhlasili s prvým návrhom len preto, že to susedia majú tiež."'
                },
            },
            section11: {
                title: '<span class="sluzba-expertise-link">Nerobte kompromisy</span> - rovnako ako my',
                subtitle: 'Naše produkty i služba sú postavené na vašej <span class="sluzba-expertise-link">maximálnej spokojnosti</span>'
            }
        },
        reference: {
            title: 'Referencie',
            count: 'Počet referencií',
            similar: 'Podobné referencie',
            caseStudy: 'Prípadová štúdia',
            caseStudyBr: 'Prípadová <br/>štúdia',
            caseStudyText: `Nahliadnite do procesu rozhodovania rodiny, ktorá si prešla tým, čím si pravdepodobne práve prechádzate i vy. Od počiatočného zberu informácií až po výber dodávateľa.`,
            caseStudyComparison: 'Prípadová štúdia - porovnanie',
            interestingProjectsBr: 'Zaujímavé <br /> projekty',
            interestingProjects: 'Zaujímavé projekty',
            portfolioShowcaseInterestingProjects: 'Ukážka z portfólia realizácií zaujímavých projektov.',
            housesBr: 'Rodinné <br /> domy',
            houses: 'Rodinné domy',
            housesTextP1: 'Podlahové vykurovanie ',
            housesTextP2: ', sme realizovali po dobu 10 rokov. Za ten čas sme zvládli nainštalovať vykurovanie do viac ako 3000 rodinných domov. Od roku 2022 sme sa s výrobcom v Južnej Kórei dohodli, že nám túto fóliu vyrobia pod vlastnou značkou. Ide o produkt so zhodnou certifikáciou a vlastnosťami. Vďaka tomu nie sme závislí na distribútorovi a môžeme kontrolovať kvalitu a predovšetkým rýchlosť dodania tovaru. <b>Tak vznikol produkt Carbon Heated</b>.',
            portfolioShowcaseHouses: 'Ukážka z portfólia realizácií rodinných domov.',
            mayInterestYou: 'Mohlo by vás tiež zaujímať',
            ourRealization: 'Naše <b>realizácie</b>',
            familyHouses: {
                type: {
                    title: 'Typ stavby',
                    option1: 'Na type nezáleží',
                    option2: 'Murovaný dom',
                    option3: 'Drevostavba',
                    option4: 'Rekonštrukcia',
                },
                area: {
                    title: 'Úžitková plocha stavby',
                    option1: 'Na ploche nezáleží',
                    option2: 'Do 100 m²',
                    option3: '101-150 m²',
                    option4: 'Nad 150 m²',
                },
                costs: {
                    title: 'Mesačné náklady na kúrenie',
                    option1: 'Na nákladoch nezáleží',
                    option2: 'Do 40 €',
                    option3: 'Do 80 €',
                    option4: 'Do 120 €',
                    option5: 'Nad 120 €',
                }
            }
        },
        partners: {
            title: 'Partneri',
            weCooperate: 'S kým <b>spolupracujeme</b>',
            architects: 'Architekti'
        },
        faq: {
            titleShort: 'FAQ',
            title: 'Často kladené otázky',
            formTitle: 'Máte inú otázku? Napíšte nám.',
            write: 'Napísať správu',
            noResult: '<b>Nenašli ste odpoveď na vašu otázku?</b> Kontaktujte náš tím.',
            note: 'Poznámka: odoslaný email pôjde na email: oravec@h-e.cz'
        },
        terms_conditions: {
            title: 'Obchodné podmienky',
            content: '<p><strong>Informačná povinnosť o mimosúdnom riešení spotrebiteľských sporov</strong> <br /> Podľa ust. § 14 zákona č. 634/1992 Zb. O ochrane spotrebiteľa, Vás informujeme, že subjektom mimosúdneho riešenia spotrebiteľských sporov (tj. Podľa ust. § 20d zákona č. 634/1992 Zb. O ochrane spotrebiteľa spor z kúpnej zmluvy alebo zo zmluvy o poskytovaní služieb) je Slovenská obchodná inšpekcia (ČOI), internetová adresa subjektu: www.coi.cz. Bližšie informácie nájdete aj na internetových stránkach http://adr.coi.cz. </p>'
        },
        contact: {
            title: 'Kontakty',
            largeTitle: '<b> zvoľte váš <br />kraj</b> pre zobrazenie kontaktov',
            id: 'IČ',
            vid: 'DIČ',
            headquarter: 'Sídlo',
            companyHeadQuarter: 'Sídlo spoločnosti',
            email: 'info@h-e.cz',
            address: 'Dlouhá 733/29, 110 00 Praha 1',
            contact1: {
                text: 'Generálny dodávateľ systému Heated. do ČR'
            },
            contact2: {
                title: 'Slovenská republika',
                title2: 'Zastúpenie pre Slovenskú republiku',
                text: 'Pôsobíme po celej Českej a Slovenskej republike',
                opening: '9-17 hod.',
                openingDays: 'Po-Pia'
            },
            contact3: {
                title: 'Česká republika',
                title2: 'Zastúpenie pre Českú republika',
                subtitle: 'Pobočka a showroom',
                opening: '9-17 hod.',
                openingDays: 'Po-Pia'
            },
        },
        download: {
            breadcrumb: 'Na stiahnutie',
            title: 'Dokumenty <b>na stiahnutie</b>',
            toDownload: 'Stiahnuť',
            item01: 'Technický list',
            item02: 'Návod termostatu NTC',
        },
        comparison: {
            titleShort: 'Porovnanie',
            title01: 'Elektrická rohož',
            title02: 'Tepelné čerpadlo',
            title02Long: 'Teplovodné rozvody + tepelné čerpadlo',
            title03: 'Vodné vykurovanie (plynový kotol)',
            title03Tiny: 'Kotol',
            title03Short: 'Plynový kotol',
            title03Long: 'Teplovodné rozvody + kotol (el./plyn)',
            textTitle01: 'Nižšie vstupné náklady',
            textTitle02: 'Nižšia spotreba',
            textTitle03: 'Topná plocha',
            textTitle04: 'Bez rozdielu teplôt',
            textTitle05: 'Rýchlosť náhrevu',
            textTitle06: 'Životnosť technológie',
            texts: [
                'Pri vstupnej investícii je potrebné zvážiť viacero faktorov, a to predovšetkým náklady na servis, údržbu a prípadnú výmenu komponentov v priebehu rokov. Heated. je úplne bezporuchový systém, nevyžaduje v priebehu rokov žiadne ďalšie investície.',
                'Energetická hospodárnosť budov sa neustále znižuje, znamená to, že rozdiel nákladov jednotlivých systémov sa bude v priebehu rokov zmenšovať.',
                'Vykurovacia plocha je jednou z najdôležitejších vlastností pre rýchle a presné docielenie požadovanej teploty.',
                'Heated. udržuje rovnakú teplotu na podlahe, vo vzduchu aj v konštrukcii, zabraňuje tak prekurovaniu vzduchu a kondenzácii vody.',
                'So systémom Heated. dosiahnete požadovanú teplotu behom niekoľkých minút. Bežné systémy vykurovania sú mnohonásobne pomalšie.',
                'Životnosť systému Heated. je vyše 30 rokov BEZ STRATY ÚČINNOSTI. Znamená to, že prevyšuje životnosť podláh samotných.'
            ],
            tableTitle: 'Podrobný rozpis',
            tableSection01: {
                title: 'Vlastnosti systému',
                row01: {
                    text1: 'Vysoké pokrytie úžitkovej plochy podlahy',
                    title2: 'Pokrytie podlahy',
                    text2: 'Plocha podlahy pokrytá vykurovacím prvkom systému',
                    text3: 'Malé pokrytie úžitkovej plochy podlahy'
                },
                row02: {
                    text1: 'Roznášacia vrstva je zahrievaná rýchlo a celoplošne',
                    title2: 'Rýchlosť náhrevu',
                    text2: 'Čas, za ktorý sa ohreje vzduch v miestnosti o 1°C',
                    text3: 'Pomalý náhrev spôsobený malým pokrytím vykurovacieho prvku'
                },
                row03: {
                    title1: 'NIE',
                    text1: 'Neobsahuje súčiastky náchylné na poškodenie',
                    title2: 'Poruchové súčiastky',
                    text2: 'Súčiastky náchylné na poškodenie, vyžadujúce údržbu a servis',
                    title3: 'NIE',
                    title3_01: 'ÁNO',
                    text3: 'Neobsahuje súčiastky náchylné na poškodenie',
                    text3_01: 'Obsahuje súčiastky náchylné na poškodenie'
                }
            },
            tableSection02: {
                title: 'Náklady na servis a údržbu',
                row01: {
                    title1: '520 €',
                    text1: 'Výkon systému nie je nijak ovplyvnený vonkajšou teplotou',
                    title2: 'Prevádzkové náklady',
                    text2: 'Kalkulácie pre stavbu 140 m<sup>2</sup> s tepelnou stratou 5,6 kW za 15 let',
                    title3_01: '880 €',
                    title3_02: '400 €',
                    title3_03: '560 €',
                    text3_01: 'Počítané s produktom bežnej kvality',
                    text3_02: '(vzduch-voda), počítané s teplotami pod bodom mrazu'
                },
                row02: {
                    title1: '0 € / rok',
                    text1: 'Jedná sa o bezúdržbový systém',
                    title2: 'Náklady na údržbu',
                    text2: 'Spôsobené opotrebovaním mechanických súčiastok a ďalších komponentov',
                    title3_01: '0 € / rok',
                    title3_02: '48 € / rok',
                    title3_03: '40 € / rok',
                    text3_01: 'Jedná sa o bezúdržbový systém',
                    text3_02: 'Priemerné ročné náklady'
                },
                row03: {
                    title1: '0 € / rok',
                    text1: 'Jedná sa o systém so životnosťou cez 30 rokov bez straty účinnosti',
                    title2: 'Náklady na servis',
                    text2: 'Ročný priemer za servis, repasáciu a výmeny v horizonte 15 rokov',
                    title3_01: '0 € / rok',
                    title3_02: '204 € / rok',
                    title3_03: '112 € / rok',
                    text3_01: 'Systém nie je náchylný na poruchy',
                    text3_02: 'Priemerné ročné náklady'
                },
            },
            tableSection03: {
                title: 'Nároky technológie',
                row01: {
                    title1: 'NIE',
                    text1: 'Bez zásahu do fasády',
                    title2: 'Zásah do fasády',
                    text2: 'Narušenie vzhľadu stavby',
                    title3_01: 'NIE',
                    title3_02: 'ÁNO',
                    text3_01: 'Bez zásahu do fasády',
                    text3_02: 'Vonkajšia jednotka narušuje fasádu a môže byť zdrojom hluku'
                },
                row02: {
                    title1: 'NIE',
                    text1: 'Možnosť iného využitia priestoru - napr. druhá kúpeľňa',
                    title2: 'Technická miestnosť / obmedzenie dispozícií',
                    text2: 'Požiadavka technológie na technickú miestnosť obmedzujúcu využiteľný priestor v interiéri',
                    title3_01: 'NIE',
                    title3_02: 'ÁNO',
                    text3_01: 'Systém bez technickej miestnosti',
                    text3_02: 'Systém vyžaduje technickú miestnosť'
                },
            },
            tableSection04: {
                title: 'Vplyv technológie na materiály',
                row01: {
                    title1: 'NIE',
                    text1: 'Vykurovací prvok nepresahuje teploty poškodzujúce vrstvy podlahy',
                    title2: 'Prehrievanie podlahy',
                    text2: 'Zahrievanie roznášacej a finálnej vrstvy podlahy nad teploty povolené výrobcami',
                    title3_01: 'ÁNO',
                    title3_02: 'NIE',
                    text3_01: 'Vykurovací prvok presahuje teploty povolené výrobcami podlahy',
                    text3_02: 'Vykurovací prvok nepresahuje teploty poškodzujúce vrstvy podlahy'
                },
                row02: {
                    title1: 'NIE',
                    text1: 'Vykurovací prvok nepresahuje teploty poškodzujúce vrstvy podlahy',
                    title2: 'Degradácia vrstiev podlahy',
                    text2: 'Poškodzovanie roznášacej a finálnej vrstvy podlahy vplyvom zahrievania nad výrobcami povolené teploty',
                    title3_01: 'ÁNO',
                    title3_02: 'NIE',
                    text3_01: 'Vykurovací prvok presahuje teploty povolené výrobcami podlahy',
                    text3_02: 'Vykurovací prvok nepresahuje teploty poškodzujúce vrstvy podlahy'
                },
                row03: {
                    title1: 'NIE',
                    text1: 'Rovnomerné nahriatie konštrukcie vplyvom celoplošnosti a vlnovej dĺžky',
                    title2: 'Rozdielne teploty v konštrukcii stavby',
                    text2: 'Rozdiel teplôť v konštrukcii vedie k vlhnutiu a znamená riziko degradácie stavby',
                    title3: 'ÁNO',
                    text3: 'Systém nenahrieva rovnomerne celú konštrukciu',
                },
                row04: {
                    title1: 'NIE',
                    text1: 'Systém predchádza vlhnutiu rovnomerným nahriatím konštrukcie',
                    title2: 'Vlhkosť v konštrukcii',
                    text2: 'Vznik vlhkosti vedúci k degradácii konštrukcie, vzniku baktérií a pliesní',
                    title3: 'ÁNO',
                    text3: 'Nerovnomerné nahriatie vedie k vlhnutiu konštrukcie',
                },
            },
            tableSection05: {
                title: 'Ovplyvnenie komfortu domácnosti',
                row01: {
                    title1: 'NIE',
                    text1: 'Konštantná teplota vplyvom celoplošnosti a umiestnenia senzorov',
                    title2: 'Rozdiel teplôt v interiéri',
                    text2: 'Rozdielne teploty podlahy a vzduchu',
                    title3: 'ÁNO',
                    text3_01: 'Intervaly prehrievania a vychladnutia',
                    text3_02: 'Požadovaná teplota vzduchu a podlahy len 4 z 24 hodín'
                },
                row02: {
                    title1: 'NIE',
                    text1: 'Vplyvom veľkého pokrytia podlahy nedochádza k rozdielom teplôt',
                    title2: 'Rozdiel teplôt na podlahe',
                    text2: 'Teplé a studené miesta na podlahe',
                    title3: 'ÁNO',
                    text3_01: 'Vplyvom nízkeho pokrytia podlahy',
                    text3_02: 'Rozdielne teploty na podlahe vplyvom malého pokrytia'
                },
                row03: {
                    title1: 'NIE',
                    title2: 'Hlučnosť systému',
                    text2: 'Systém s hlučným zdrojom',
                    title3_01: 'NIE',
                    title3_02: 'ÁNO',
                },
            },
            tableSection06: {
                title: 'Vplyv na zdravie',
                row01: {
                    title1: 'NIE',
                    text1: 'Systém neprehrieva podlahu a predchádza vysúšaniu vzduchu',
                    title2: 'Vysúšanie vzduchu',
                    text2: 'Vysúšanie vzduchu je spôsobené prehriatím podlahy',
                    title3: 'ÁNO',
                    text3: 'Systém prehieva podlahu a vysúša tak vzduch',
                },
                row02: {
                    title1: 'ÁNO',
                    text1: 'Systém neprehrieva podlahu a predchádza víreniu prachu',
                    title2: 'Vhodnosť pre alergikov (vírivosť prachu)',
                    text2: 'Vplyv technológie na vírivosť prachu a následnú dráždivosť pre alergikov',
                    title3: 'NIE',
                    text3: 'Systém prehrieva podlahu za účelom nahriatia vzduchu a spôsobuje tak vírenie prachu',
                },
                row03: {
                    title1: 'NIE',
                    text1: 'Vplyvom rovnomerného nahriatia konštrukcie zamedzuje vzniku baktérií a pliesní',
                    title2: 'Vznik baktérií a pliesní',
                    text2: 'Vznik baktérií a pliesní vplyvom vlhkosti v konštrukcii',
                    title3: 'ÁNO',
                    text3: 'Vplyvom nerovnomerného nahriatia konštrukcie a vlhnutia',
                },
            },
            tableSection07: {
                title: 'Ďalšie možnosti technológie',
                row01: {
                    title1: 'ÁNO',
                    text1: 'Bez komplikácií',
                    title2: 'Možnosť inteligentnej regulácie',
                    text2: 'Možnosť napojenia na systém smart home',
                    title3: 'ÁNO',
                    text3_01: 'Bez komplikácií',
                    text3_02: 'Pre plnohodnotné využitie zvyšuje vstupnú investíciu smart home i rozvodov',
                },
                row02: {
                    title1: 'ÁNO',
                    text1: 'Bez komplikácií',
                    title2: 'Možnosť regulovateľných okruhov',
                    text2: 'Možnosť nastavenia samostatne regulovateľných okruhov',
                    title3: 'ÁNO',
                    text3_01: 'Bez komplikácií',
                    text3_02: 'Zložité. Spojené s výrazným nárastom vstupných investícií na rozvody hadice',
                },
                row03: {
                    title1: 'ÁNO',
                    text1: 'Moderná technológia pripravená pre využitie s FVE',
                    title2: 'Možnosť napojenia FVE',
                    text2: 'Prepojenie technológie s fotovoltaickou elektrárňou (FVE)',
                    title3_01: 'ÁNO',
                    title3_02: 'KOMPLIKOVANÉ',
                    title3_03: 'NIE',
                    text3_01: 'V závislosti na prevedení a prepojení dodávateľov rohoží a FVE je systém schopný využiť energiu z vlastnej výroby',
                    text3_02: 'Náročné napojenie',
                    text3_03: 'Napojenie nie je možné'
                },
                row04: {
                    title1: 'ÁNO',
                    text1: 'Bez komplikácií',
                    title2: 'Možnosť stropného vykurovania',
                    text2: 'Možnosť inštalácie technológie ako stropné kúrenie',
                    title3: 'NIE',
                    text3: 'Nie je možné',
                },
                row05: {
                    title1: 'NIE',
                    text1: 'Systém sám vodu neohrieva Vo vývoji',
                    title2: 'Ohrev teplej vody',
                    text2: 'Schopnosť systému ohrievať teplú vodu pre domácnosť',
                    title3_01: 'NIE',
                    title3_02: 'ÁNO',
                    text3_01: 'Systém sám vodu neohrieva',
                    text3_02: 'Zaisťuje ohrev. V prípade poruchy systému je domácnosť bez teplej vody',
                },
            },
        },
        assessment: {
            title: 'Posúdenie projektu',
            freeAsessment: 'Posúdenie projektu zdarma',
            freeAsessmentBr: 'Posúdenie projektu<br /> zdarma',
            freeAsessmentText: 'Posúdenie súčasného kúrenia <br /> vo vašom projekte.',
            wantTo: 'Chcem <b>posúdiť projekt</b>'
        },
        audit: {
            title: 'Vypracovanie PENB',
            titleBr: 'Vypracovanie <br />PENB',
            titleText: 'Vypracovanie energetického <br />štítku pre váš projekt.',
            errorMessage: 'Pre nezáväzné nacenenie projektu zašlite kompletnú projektovú dokumentáciu. Bude vás kontaktovať naše projekčné oddelenie.'
        },
        mobileMenu: {

        },
        modelHouse: {
            title: 'Vzorový dom',
            titleBr: 'Vzorový <br />dom',
            headerTitle: 'SMART HOME s Heated.',
            headerSubtitle: '<b>Heated.</b> miesto<br /> tepelného čerpadla.',
            section01: {
                title: 'Argumenty pre zmemu',
                text: '“V tejto stavbe malo byť pôvodne kvalitné tepelné čerpadlo. Po zvážení všetkých parametrov však nedávalo tepelné čerpadlo zmysel. Ušetrili sme tak 50% na vstupných nákladoch, <span> za ktoré sme automatizovali celý dom.</span>”',
                item01: 'Celoplošný systém vykurovania',
                item02: 'Bez hlučného zdroja',
                item03: 'Nižšie obstarávacie náklady',
                item04: 'Rýchla a presná regulácia',
                item05: 'Výhodnejšie v celkovom súčte nákladov',
                item06: 'Bezporuchový systém',
                item07: 'Technológia pre nízkoenergetické stavby',
                item08: 'Bez technickej miestnosti',
            },
            section02: {
                title: '<b>Celoplošnosť</b><br />a druhá kúpeľňa',
                text: '"Ak budeme počítať, že 1m2 domu nás vyjde na 1000 €, technická miestnosť by stála 8400 €. "<B> My sme tento rovnaký priestor využili pre druhú kúpeľňu.</b>”',
                text2: '"Tepelné čerpadlo sa oplatí do starších stavieb alebo rekonštrukcií. Pri dnešných novostavbách s nízkou tepelnou stratou <b> je potreba dávkovať teplo rýchlo a celoplošne.</b>”'
            },
            section03: {
                title: '<b>ÚSPORA NA <br/>VSTUPNEJ INVESTÍCII<br/></b>A POROVNANIE NÁKLADOV',
                text: '“Kvalita tepelného čerpadla sa priamo odvíja od jeho ceny. Rovnako tak rozvody a možnosť regulovať každú miestnosť samostatne. Pri systéme Heated. nepremýšľate nad variantou, <b>jedná sa vždy o bezporuchový a bezúdržbový produkt bez ďalších nákladov.</b>”',
                note: 'Počítané kvalitné TČ (vzduch-voda), rozvody v systémovej doske, ohrev teplej vody.'
            },
            section04: {
                title: 'KOMPLEXNÉ RIEŠENIE Efektivní dům',
                subtitle: 'Do domu sme dodali taktiež...',
                title2: 'KOMPLEXNÁ SKLADBA PODLAHY',
                text: 'Súčasťou nášho portfólia je kompletná dodávka podlahovej skladby – <b>položenie izolácie - Heated. - liateho poteru.</b>',
                button: 'Naceniť skladbu podlahy'
            },
            section05: {
                title: 'AUTOMATIZÁCIA DOMÁCNOSTI',
                text: 'Predstavte si domácnosť, ktorá <b> pozná vaše potreby a návyky. </ B> Loxone vám <b> každodenne zjednodušuje život </ b> automatickými prvkami ako je riadenie svetla, žalúzií, osvetlenia, zabezpečenia a ďalších prvkov. <B> Všetko riadite pohodlne </ b> zo svojho telefónu alebo pomocou inteligentných senzorov a spínačov rozmiestnených všade v dome.'
            },
            section06: {
                title: 'FVE S BATÉRIOVÝM ÚLOŽISKOM',
                text: 'Vďaka kombinácii systému Loxone a batériového úložiska je systém MCore schopný <b>efektívne využiť všetku vyrobenú energiu.</b> Návratnosť systému sa tak zásadne skracuje.'
            },
            section07: {
                title: 'TENTO DOM NÁJDETE AJ V ŠTVORDIELNOM PROGRAME O BÝVANÍ',
                item1: 'POROVNANIE - Heated.  A TEPELNÉ ČERPADLO',
                item2: 'LOXONE - AUTOMATIZÁCIA DOMÁCNOSTI',
                item3: 'Heated. - SLUŽBA NA MIERU',
                item4: 'AKÉ KÚRENIE ZVOLIŤ DO NOVOSTAVBY',
            },
            freeCalculation: 'KALKULÁCIA ZDARMA'
        },
        service: {
            header: {
                title: '<span class="orange">Unikátna služba</span><br/> na mieru vášho projektu',
                box1: 'Počet realizácií',
                box2: 'Počet špecializovaných oddelení',
                box3: 'Počet členov tímu',
            },
            section01: {
                title: 'Obchodne-technické oddelenie',
                text: 'Náš tím obchodne-technických poradcov pokrýva celú Slovenskú a Českú republiku a klientom poskytuje maximálny servis vo fázi výberu technológie, zameraný hlavne na poradenstvo a prezentáciu produktu.',
                image1: 'technický poradca',
                image2: 'schôdzok ročne',
            },
            section02: {
                title: 'Čo všetko má obchodné oddelenie na starosti?',
                text: 'Snažíme sa pôsobiť profesionálne v každom ohľade. Či už idú naši obchodne-technickí zástupci za vami na schôdzku alebo napríklad na kontrolu stavby.'
            },
            section03: {
                title: 'Návrh projektu',
                text: 'Špecializovaný tím projekcie má na starosti dimenzácie optimálneho výkonu, návrhy vykurovacích okruhov a ideálne skladby podlahy i všetky projekčné výstupy vrátane možnosti tvorby PENB.',
                subtext: 'Vypracovanie energetického štítku pre váš projekt',
                image1: 'vysokoškolský projektant',
                image2: 'spracovaných projektov',
            },
            section04: {
                title: 'Ako prebieha návrh projektu?',
                text: 'Naše špecializované oddelenia máme na jednom mieste. Vďaka tomu máme prehľad o všetkom, čo se deje a môžeme tak udržať vysoký štandard poskytovaných služieb.'
            },
            section05: {
                title: 'Príprava realizácie',
                text: 'Príprava má za úlohu kompletnú organizáciu pred i po realizačnej fázi inštalácie. Kontroluje stavebnú pripravenosť, poskytuje poradenstvo a koordinuje ostatné remeslá na stavbe tak, aby montáž i nasledovné uvedenie do prevádzky prebehlo bez starostí klienta.',
                image1: 'technik prípravár',
                image2: 'realizácií',
            },
            section06: {
                title: 'Čo zaisťuje prípravné oddelenie?',
                box1: {
                    title: 'I. Komunikácia s architektmi',
                    text: 'Ak nie je architekt oboznámený s naším systémom, môže si vyžiadať schôdzku s obchodne-technickým oddelením. Nasledovne všetko ohľadom podkladov komunikuje oddelenie prípravy.'
                },
                box2: {
                    title: 'II. Komunikácia so stav. firmou',
                    text: 'Oddelenie komunikuje so všetkými predchádzajúcimi a nadväzujúcimi remeslami. Eliminujeme tak časté chyby vznikajúce nedôsledným odovzdaním diela z jednej firmy na druhú.'
                },
                box3: {
                    title: 'III. Inštalácia elektra',
                    text: 'Ak to nie je súčasťou našej dodávky, vygenerujeme pre vášho elektrikára prehľadné inštrukcie ako postupovať, aby bolo pripravené všetko podľa potrieb pre nasledujúcu inštaláciu Heated.'
                },
            },
            section07: {
                title: 'Montáž systému',
                text: 'Celá montáž je vykonávaná výhradne naším vlastným skúseným montážnym tímom. Vďaka dlhoročným skúsenostiam a kvalite našich zamestnancov potom pri montáži nedochádza ku komplikáciám. Väčšinu zákaziek tak zvládneme realizovať za jediný deň.',
                image1: 'technik Heated.',
                image2: 'zrealizovaných podlahových plôch',
            },
            section08: {
                title: 'Montáž celoplošného systému Heated.',
                box1: {
                    title: 'I. Kročejová izolácia',
                    text: 'Kladieme dôraz na kvalitu a efektivitu kompletnej systémovej skladby. Preto používame iba tie najkvalitnejšie osvedčené materiály.'
                },
                box2: {
                    title: 'II. Systém Heated.',
                    text: 'Heated. inštalujeme v celej ploche miestnosti tak, aby pokrýval celú plochu. Neinštalujeme pod nemenné dispozície ako je napr. zabudovaná skriňa.'
                },
                box3: {
                    title: 'III. Ochranná fólia',
                    text: 'Súčasťou každej inštalácie je bezpečnostná PET fólia, ktorá oddeluje systém Heated. od liateho poteru alebo sádrokartónových dosiek.'
                },
            },
            quote01: 'Už od roku 2012 rozvíjame spôsoby, ako <span class = "h-black"> našim klientom zjednodušovať proces stavby. </ Span> Súčasne každý deň premýšľame ako zlepšiť vašu životnú úroveň <span class = "h-black"> vďaka najmodernejším technológiám.</span>',
            section09: {
                title: 'Komplexná dodávka podlahy',
                text: 'Súčasťou nášho portfólia je kompletná dodávka podlahovej skladby – položenie systému, izolácie a liaty poter. Vďaka tomu nedochádza k prípadným komplikáciám pri odovzdávaní stavby medzi rôznymi subjektami a celkovú zodpovednosť za podlahu nesie naša spoločnosť.',
                note1: 'položenie izolácie',
                note2: 'inštalácia Heated.',
                note3: 'roznášacia vrstva',
                image1: 'podlahový technik',
                image2: 'komplexná dodávka',
                cta: 'CENOVÁ KALKULÁCIA ZDARMA'
            },
            section10: {
                title: 'Prečo zvoliť komplexnú dodávku od Heated.'
            },
            section11: {
                title: 'Chytrá domácnosť LOXONE',
                text: 'Súčasťou nášho portfólia je automatizácia domácnosti v tom pravom slova zmysle. Rakúska firma Loxone verí – rovnako ako my – v kvalitný produkt s radou inovatívnych riešení. Vďaka neúnavnej práci sa z nás v roku 2019 stal platinový partner.',
                image1: 'technik Loxone',
                image2: 'realizácií Loxone',
                cta: 'CENOVÁ KALKULÁCIA ZDARMA',
                cta2: 'viac o Loxone'
            },
            section12: {
                title: 'Články týkajúce sa Loxone',
                item1: '<b>Technológie -</b> Špecializované projekčné oddelenie sa zaoberá detailným návrhom a dimenzáciou vykurovacieho systému pre každý vykurovací okruh samostatne. Súčasťou nášho portfólia je i vyhotovenie PENB.',
                item2: '<b>Bezpečie -</b> Špecializované projekčné oddelenie sa zaoberá detailným návrhom a dimenzáciou vykurovacieho systému pre každý vykurovací okruh samostatne. Súčasťou nášho portfólia je i vyhotovenie PENB.',
                item3: '<b>Komfort -</b> Špecializované projekčné oddelenie sa zaoberá detailným návrhom a dimenzáciou vykurovacieho systému pre každý vykurovací okruh samostatne. Súčasťou nášho portfólia je i vyhotovenie PENB.',
            },
            section13: {
                title: 'Fotovoltaika MCore',
                text: 'V roku 2019 sme na žiadosť našich klientov vyvinuli fotovoltaický systém, ktorý skvelo zapadá do nášho portfólia. Spojením najkvalitnejších komponentov technológie fotovoltaickej elektrárne a batériového úložiska vznikol systém, ktorý dokáže maximálne využívať všetku vyrobenú energiu.',
                image1: 'technik MCore',
                image2: 'realizácií MCore',
                cta: 'CENOVÁ KALKULÁCIA ZDARMA',
                cta2: 'viac o MCore'
            },
            section14:{
                title: 'Články týkajúce sa MCore',
                item1: '<b>Konfigurovateľné riešenie pre rodinné domy -</b> Špecializované projekčné oddelenie sa zaoberá detailným návrhom a dimenzáciou vykurovacieho systému pre každý vykurovací okruh samostatne. Súčasťou nášho porfólia je i vyhotovenie PENB.',
                item2: '<b>Konfigurovateľné riešenie pre rodinné domy -</b> Špecializované projekčné oddelenie sa zaoberá detailným návrhom a dimenzáciou vykurovacieho systému pre každý vykurovací okruh samostatne. Súčasťou nášho porfólia je i vyhotovenie PENB.',
                item3: '<b>Konfigurovateľné riešenie pre rodinné domy -</b> Špecializované projekčné oddelenie sa zaoberá detailným návrhom a dimenzáciou vykurovacieho systému pre každý vykurovací okruh samostatne. Súčasťou nášho porfólia je i vyhotovenie PENB.',
            },
            section15:{
                title: '<span class="sluzba-expertise-link">Nerobte kompromisy</span> - rovnako ako my',
                subtitle: 'Naše produkty i služba sú postavené na vašej <span class="sluzba-expertise-link">maximálnej spokojnosti</span>'
            },
        },
        footer: {
            title1: 'Viac informácií',
            title2: 'Pôsobíme po celej Slovenskej a Českej republike',
            about: 'Od roku 2012 dodávame technológie s jasným cieľom - poskytnúť maximálny komfort bývania a úsporu energie. Zabudnite na kúrenie - <b> spoznajte Heated.</b>',
            mcore: 'Fotovoltaika s batériovým úložiskom navrhnutá tak, aby váš dom využíval maximálny potenciál vyrobenej energie',
            lxn: 'Inteligencia do vášho nového domu',
            allRightReserved: 'Všetky práva vyhradené.',
            contact1: 'Česká republika',
            contact2: 'Slovenská republika'
        },
        gdpr: {
            title: 'Zásady ochrany osobných údajov',
            titleShort: 'Ochrana súkromia',
            titleShortcuts: 'GDPR',
            content: `
                <p>
               Tieto Zásady ochrany osobných údajov sme vyhotovili s cieľom
               zoznámiť Vás s tým, ako zhromažďujeme, spracovávame, používame a
               chránime osobné údaje. Pri zabezpečení ochrany súkromia a osobných
               údajov postupujeme <b> výhradne v súlade s platnou legislatívou </ b>
               , tj. s Nariadením Európskeho parlamentu a Rady (EÚ) 2016/679 z
               27. apríla 2016 o ochrane fyzických osôb pri
               spracovaní osobných údajov a o voľnom pohybe týchto údajov a o
               zrušení smernice 95/46 / ES (všeobecné nariadenie o ochrane osobných
               údajov) (ďalej len "Nariadenie") a ďalšími právnymi predpismi túto
               oblasť upravujúcu.
            </p>
            <h3 class="gdpr-heading">
              1. Kto je správcom osobných údajov a aké sú kategórie subjektov
               osobných údajov, ktorých osobné údaje spracovávame?
            </h3>

            <h4 class="gdpr-subheading">Správca osobných údajov:</h4>
            <ul class="gdpr-list">
              <li class="gdpr-list-item">
                Efektivní dům s.r.o., IČO: 27855252, so sídlom: Hlučínská 1177,
                Přívoz, 702 00 Ostrava, zapísaná v obchodnom registri
                Krajského súdu v Ostrave, sp. zn. C 31815.
              </li>
            </ul>

            <h4 class="gdpr-subheading">Kategórie subjektov údajov:</h4>
            <ul class="gdpr-list">
              <li class="gdpr-list-item">
                zákazníci Správcu, tj. osoby, ktoré so Správcom uzavreli zmluvu
                o poskytnutí služieb či tovaru;
              </li>
              <li class="gdpr-list-item">
                zamestnanci Správcu, uchádzači o zamestnanie;
              </li>
              <li class="gdpr-list-item">
                dodávatelia služieb a osoby činné pre Správcu na základe iného
                zmluvného vzťahu.
              </li>
            </ul>
            <h3 class="gdpr-heading"></h3>

            <ul class="gdpr-list">
              <li class="gdpr-list-item"></li>
              <li class="gdpr-list-item"></li>
              <li class="gdpr-list-item"></li>
            </ul>
            <h3 class="gdpr-heading">
              2. Aké osobné údaje o subjektoch spracovávame?
            </h3>
            <p>
               O zákazníkoch Správcu spracovávame tieto osobné údaje: osobné
               údaje, ktoré slúžia na jednoznačnú identifikáciu dotknutej osoby, tj.
               meno, priezvisko, dátum narodenia, adresa trvalého pobytu; v prípade
               že je zákazník fyzickou osobou podnikajúcou podľa osobitných právnych
               predpisov, spracovávame ďalej i jeho obchodnú firmu, IČO a DIČ. Ďalej
               na účely komunikácie so zákazníkom pri odoberaní tovaru, poťažmo
               pri vybavovaní objednávky spracovávame jeho kontaktné údaje, ako
               je telefónne číslo a e-mailová adresa. Za účelom platobným ďalej
               môžeme spracovávať číslo bankového účtu zákazníka.
            </p>
            <p>
               O ostatných subjektoch spracovávame tieto osobné údaje:
               identifikačné údaje - osobné údaje, ktoré slúžia k jednoznačnej
               identifikácii subjektu údajov, tj. meno, priezvisko, titul, dátum
               narodenia, adresa trvalého pobytu; u dodávateľa - fyzické osoby
               podnikateľov tiež obchodná firma, IČO a DIČ. Ďalej spracovávame
               kontaktné údaje - osobné údaje, ktoré umožňujú kontakt so
               subjektom údajov v rámci plnenia zmluvných povinností (kontaktná
               adresa, emailová adresa, číslo telefónu, popr. platobné údaje -
               číslo bankového účtu).
            </p>
            <p>
               Ďalšie osobné údaje (nad rámec vyššie uvedeného), môžeme spracovávať
               <B> so súhlasom </ b> subjektu údajov za účelom napr. marketingu.
            </p>
            <h3 class="gdpr-heading">
              3. Z akých zdrojov osobné údaje získavame?
            </h3>

            <p>
               Osobné údaje získavame priamo od dotknutej osoby, v rámci uzatváraní
               zmluvy o poskytovaní služieb a ďalej pri rokovaní o uzavretí iného
               zmluvného vzťahu (so zamestnancom, pri uzatváraní zmluvy s
               dodávateľom apod.), príp. od tretích osôb v súlade s Nariadením, a
               to predovšetkým z verejných zdrojov pri rešpektovaní účelu spracovania
               osobných údajov.
            </p>
            <h3 class="gdpr-heading">
              4. Akým spôsobom a po akú dobu osobné údaje spracovávame?
            </h3>

            <p>
              Spracovanie osobných údajov vykonáva Správca. Spracovanie prebieha v
              prevádze Správcu, Správcou poverenými zamestnancami, prípadne
              spracovateľmi. K spracovaniu osobných údajov dochádza prostredníctvom
              výpočtovej techniky, prípadne aj manuálnym spôsobom v prípade
              osobných údajov v listinnej podobe, a to za dodržiavania všetkých
              bezpečnostných zásad pre správu a spracovanie osobných údajov. Pri
              spracovaní osobných údajov nedochádza k žiadnemu automatizovanému
              rozhodovaniu (teda bez ingerencie človeka), vrátane profilovania.
              Osobné údaje sú po celú dobu chránené pred neoprávneným
              zásahom, stratou, zničením alebo ich zneužitím. Všetky osoby,
              ktoré prichádzajú s údajmi do kontaktu, sú viazané povinnosťou
              mlčanlivosti, najmä naši zamestnanci a spracovatelia.
            </p>
            <p>
               Osobné údaje uchovávame po dobu plnenia zmluvného vzťahu a ďalej po
               dobu nevyhnutne potrebnú na zabezpečenie všetkých práv a povinností
               plynúcich zo záväzkového vzťahu, tj. po dobu existencie možných
               nárokov vyplývajúcich z uzatvorenia zmluvy (zákonne premlčacej doby) a
               ďalej po dobu, ktorú sme povinní ich uchovávať podľa všeobecne
               záväzných právnych predpisov.{" "}
            </p>
            <h3 class="gdpr-heading">
               5. Na základe akého právneho titulu a za akým účelom
               spracovávame osobné údaje?
            </h3>

            <p>
               Osobné údaje zákazníkov sú spracovávané za účelom plnenia zmluvy
               o poskytovaní služieb či tovaru, podľa čl. 6 ods. 1 písm. b)
               Nariadenie.
            </p>
            <p>
               V rámci rokovaní o uzavretí zmluvy alebo na plnenie zmluvy s
               ďalšími subjektami údajov (zamestnanci, dodávatelia) sú osobné údaje
               spracovávané v súlade s čl. 6 ods. 1 písm. b {")"} Nariadenie, teda
               za účelom plnenia tejto zmluvy.{" "}
            </p>
            <p>
               Osobné údaje môžu byť ďalej spracovávané za účelom ochrany
               oprávnených záujmov Správca v súlade s čl. 6 ods. 1 písm. f {")"}
               Nariadenia, a to najmä vymáhanie prípadných vzniknutých pohľadávok zo
               zmlúv uzatvorených s dotknutou osobou. Ďalej môžu byť osobné údaje
               dotknutých osôb spracovávané na využitie priameho marketingu (tj. k
               zasielaniu ponúk o produktoch, informácií o nových produktoch), a
               to na základe súhlasu dotknutej osoby podľa čl. 6 ods. 1 písm. a
               {")"}
               Nariadenie.
            </p>
            <p>
               Osobné údaje sú spracovávané zo strany Správcu taktiež za účelom
               plnenia právnych povinností v súlade s čl. 6 ods. 1, písm. c {")"}
               Nariadenie, napr. k štátnym orgánom (napr. Správcom dane pre výkon
               správy daní, súdom, exekútorom, notárom), pre plnenie zákonných
               záväzkov vyplývajúcich z osobitných predpisov.
            </p>
            <h3 class="gdpr-heading">
               6. Aké má subjekt údajov zákonné práva pri spracovaní osobných
               údajov?
            </h3>

            <h4 class="gdpr-subheading">
              Právo na prístup k osobným údajom:
            </h4>
            <p>
               Subjekt údajov má právo získať od Správcu potvrdenie, či spracováva
               jeho osobné údaje, a ak je to tak, má právo získať prístup k
               týmto osobným údajom a k informáciám uvedeným v čl. 15 Nariadenie.
            </p>
            <h4 class="gdpr-subheading">
              Právo na opravu alebo vymazanie, prípadne obmedzenie spracovania:{" "}
            </h4>
            <p>
               Subjekt údajov má právo (v prípadoch ustanovených Nariadením) požiadať
               Správcu o opravu alebo doplnenie nesprávnych, resp. neúplných
               osobných údajov, požiadať o vymazanie osobných údajov, ak odpadol alebo
               nie je daný dôvod na ich spracovanie, prípadne požiadať o obmedzenie
               spracovania osobných údajov v súvislosti s riešením okolností
               spracovania osobných údajov zo strany Správcu.
            </p>
            <h4 class="gdpr-subheading">Právo vzniesť námietku: </h4>
            <p>
               Subjekt údajov má z dôvodov týkajúcich sa jeho konkrétnej situácie
               právo kedykoľvek vzniesť u Správcu námietku proti spracovaniu jeho
               osobných údajov spracovaných na účelmi oprávnených záujmov Správcu
               alebo iných osôb (podľa Nariadenia); oprávnenými záujmami podľa Nariadenia
               môžu byť najmä prípady ochrany práv a právnych nárokov Správcu.
            </p>
            <h4 class="gdpr-subheading">Právo na prenositeľnosť údajov: </h4>
            <p>
               Subjekt údajov má (za podmienok stanovených v nariadení) právo získať
               svoje osobné údaje od Správcu a odovzdať ich inému správcovi osobných
               údajov.
            </p>
            <h4 class="gdpr-subheading">
              Právo podať sťažnosť u dozorného úradu:{" "}
            </h4>
            <p>
               Subjekt údajov má právo podať sťažnosť u dozorného úradu, pokiaľ sa
               domnieva, že spracovaním jeho osobných údajov bolo porušené
               Nariadenie. Týmto dozorným úradom je Úrad na ochranu osobných
               údajov so sídlom v Prahe.
            </p>
            <p>
              <b>
                 Ak je právo spracovávať osobné údaje založené na udelení
                 súhlasu na spracovanie osobných údajov, môže tento súhlas
                 subjekt údajov kedykoľvek odvolať;
               </ B> {""}
               jedná sa napr. o marketingový súhlas. Odvolaním tohto súhlasu
               však nie je dotknutá zákonnosť spracovania vychádzajúca zo súhlasu,
               ktorý bol daný pred jeho odvolaním. {""}
            </p>
            <p>
               K realizácii všetkých svojich práv môže subjekt údajov využiť kontaktné
               údaje uvedené nižšie v bode 7 týchto Zásad. Správca bez zbytočného
               odkladu informuje subjekt údajov o spracovaní jeho žiadosti a o
               prijatých opatreniach (Nariadenie k tomu Správcovi dáva lehotu 1 mesiaca
               od prijatia žiadosti).
            </p>
            <h3 class="gdpr-heading">
               7. Kde môže uplatniť subjekt údajov svoje práva či prípadné námietky
               na spracovanie osobných údajov?
            </h3>
            <p>
               Uplatniť prípadné námietky proti spracovaniu osobných údajov, odvolať
               udelený súhlas či zmeniť jeho rozsah, či uplatniť akékoľvek svoje
               právo môže subjekt údajov realizovať niektorým z týchto spôsobov:
            </p>
            <ul class="gdpr-list">
              <li class="gdpr-list-item">
                písemně na adrese Hlučínská 1177, Přívoz, 702 00 Ostrava
              </li>
              <li class="gdpr-list-item">
                e-mailovou správou na info@heated.cz
              </li>
            </ul>
            <p>
               Týmito vyššie uvedenými cestami môže subjekt údajov Správcu
               kontaktovať s akoukoľvek žiadosťou alebo otázkou týkajúcou sa
               spracovania osobných údajov.
            </p>
            <h3 class="gdpr-heading">8. Komu poskytujeme osobné údaje?</h3>
            <p>
               Osobné údaje sú spracovávané prostredníctvom zamestnancov Správcu
               alebo Správcom poverených spracovateľov na základe príslušnej zmluvy
               v súlade s čl. 28 Nariadenia. Vo všetkých prípadoch však Správca dbá
               na to, aby boli zachované všetky povinnosti, ktoré Správcovi a jeho
               spracovateľom vyplývajú z platnej legislatívy a pri odovzdaní dbá,
               aby nedošlo k ohrozeniu bezpečnosti odovzdaných osobných údajov alebo k
               ich zneužitiu.
            </p>
            <p>Spracovatelia/príjemci osobných údajov sú najmä:</p>
            <ul class="gdpr-list">
              <li class="gdpr-list-item detached">
                 štátne orgány a ďalšie inštitúcie v rámci zákonných povinností,
                 najmä orgány štátnej správy, súdy, správca dane, orgány činné
                 v trestnom konaní, orgány sociálneho zabezpečenia, exekútori,
                 notári, Česká asociácia poisťovní, poisťovne;
              </li>
              <li class="gdpr-list-item detached">
                 externé subjekty, ktoré poskytujú Správcovi služby, jedná sa
                 najmä o: <br />
                <ul class="gdpr-list">
                  <li class="gdpr-list-item">
                    subjekt spravujúci informačný systém Správcu
                  </li>
                  <li class="gdpr-list-item">
                    externí účtovníci, daňoví poradci, auditori
                  </li>
                  <li class="gdpr-list-item">
                     subjekt poskytujúci služby v oblasti stráženia areálu Správcu
                     na adrese sídla Správcu
                  </li>
                  <li class="gdpr-list-item">prepravci, dopravci</li>
                  <li class="gdpr-list-item">
                    subjekty poskytujúce služby v oblasti bezpečnosti a ochrany
                    zdravia pri práci, požiarne ochrany
                  </li>
                  <li class="gdpr-list-item">
                    subjekty poskytujúce služby v oblasti školenia, vzdelávania
                  </li>
                </ul>
              </li>
              <li class="gdpr-list-item detached">
                 s predchádzajúcim súhlasom alebo na príkaz subjektu údajov môžu byť
                 osobné údaje poskytnuté ďalším subjektom;
              </li>
            </ul>
            <h3 class="gdpr-heading">
              9. Čo znamenajú pojmy obsiahnuté v týchto Zásadách?
            </h3>
            <p>
              <b>Osobný údaj</b> - akákoľvek informácia týkajúca sa určeného alebo
               určiteľného subjektu údajov; subjekt údajov sa považuje za určený
               alebo určiteľný, pokiaľ možno subjekt údajov priamo alebo nepriamo
               identifikovať;
            </p>
            <p>
              <b>Subjekt údajov</b> – fyzická osoba, ktorej osobné údaje sú
              Správcom spracovávané;
            </p>
            <p>
              <b>Správca</b> – subjekt, ktorý určuje účel a prostriedky
              spracovania osobných údajov, vykonáva spracovanie a zodpovedá zaň;
            </p>
            <p>
              <b>Spracovateľ</b> – každý subjekt, ktorý na základe osobitného
               zákona alebo poverenia správcom spracováva osobné údaje;
            </p>
            <p>
              <b>Príjemca</b> – každý subjekt, ktorému sú osobné údaje
              sprístupnené;
            </p>
            <p>
              <b>Spracovanie</b> - akákoľvek operácia alebo sústava operácií, ktoré
               správca alebo spracovateľ systematicky vykonávajú s osobnými údajmi,
               a to automatizovane alebo inými prostriedkami; najmä sa jedná o
               zhromažďovanie, ukladanie na nosiče informácií, sprístupňovanie,
               úprava alebo pozmeňovanie, vyhľadávanie, používanie, odovzdávanie,
               šírenie, zverejňovanie, uchovávanie, výmena, triedenie alebo
               kombinovanie, blokovanie, likvidácia.
            </p>
            <p>
              <b>
                 Tieto Zásady ochrany osobných údajov sú platné a účinné ku dňu
                 25.5.2018. Aktuálne platné znenie týchto zásad je uverejnené na
                 našich webových stránkach www.heated.sk alebo je k dispozícii v
                 sídle našej spoločnosti na adrese Hlučínská 1177, Přívoz, 702 00
                 Ostrava.
              </b>
            </p>
            `
        }
    }
}
