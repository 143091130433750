import * as React from "react";
import config from "../../../../config/config.json";
import { Menu } from "../components/Menu";
import { Link } from "react-router-dom";
import {
  getReferences,
  getNextReferences,
  getConfig,
  getHighlightedReferences
} from "../api.js";
import { getPageSEO } from "../functions";
import LoadingData from '../components/LoadingData';

import i18n from '../i18n';

class DatabazeRealizaci extends React.Component {
  constructor(props) {
    super(props);

    this.pageSEO = getPageSEO();

    document.title = this.pageSEO.title;
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", this.pageSEO.keywords);
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", this.pageSEO.description);

    document.querySelector('meta[name=\"title\"]').setAttribute('content', this.pageSEO.metaTitle);

    this.state = {
      data: [],
      more_data: [],
      config_area: 0,
      config_buildings: 0,
      page: 1,
      load_more_option: { display: "block" }
    };
    this.loadMore = this.loadMore.bind(this);
    this.setData = this.setData.bind(this);
    this.setNextData = this.setNextData.bind(this);
    this.setConfig = this.setConfig.bind(this);
  }

  componentDidMount() {
    this.setState({
      load_more_option: { display: "none" }
    }, () => {
      getReferences.request("Zpětná vazba zákazníka", this.setData);
      getConfig.request(this.setConfig);
    });
  }

  componentWillUnmount() {
    if (getReferences.running) {
      getReferences.cancel();
    }

    if (getNextReferences.running) {
      getNextReferences.cancel();
    }
  }

  setData(response) {
    const zajimave_domy = response.data.filter(reference => {
      if (reference.category == "Zpětná vazba zákazníka") {
        return reference;
      }
    });

    if (zajimave_domy.length < 9) {
      this.setState({
        load_more_option: {
          display: "none"
        }
      });
    }
    if (response.links.next) {
      this.setState({ next_link: response.links.next });
    }

    this.setState({
      data: zajimave_domy
    });

    // if (response.links.next) {
    //   this.setState({ next_link: response.links.next });
    // }

    // // var desc = response.data[3].description.split(/\R/);

    // // split(/[\r\n]+/)

    // const zajimave_domy = response.data.filter(reference => {
    //   if (reference.category == "Zpětná vazba zákazníka") {
    //     return reference;
    //   }
    // });

    // this.setState({ data: zajimave_domy });
  }

  setConfig(response) {
    this.setState({
      config_area: response.data.area_sum,
      config_buildings: response.data.building_sum
    });
  }
  setNextData(response) {
    const zajimave_domy = response.data.filter(reference => {
      if (reference.category == "Zpětná vazba zákazníka") {
        return reference;
      }
    });

    if (zajimave_domy.length < 9) {
      this.setState({
        load_more_option: {
          display: "none"
        }
      });
    }
    if (response.links.next) {
      this.setState({ next_link: response.links.next });
    }
    var temp_arr = this.state.data;
    temp_arr.push.apply(temp_arr, zajimave_domy);
    this.setState({
      data: temp_arr
    });
  }
  loadMore() {
    var current_page = this.state.page;
    if (this.state.data.length > (current_page + 1) * 8) {
      var temp_arr = this.state.more_data;
      temp_arr.push.apply(
        temp_arr,
        this.state.data.slice(current_page * 8, (current_page + 1) * 8)
      );
      this.setState({
        more_data: temp_arr,
        page: current_page + 1
      });
    } else {
      var temp_arr = this.state.more_data;
      temp_arr.push.apply(
        temp_arr,
        this.state.data.slice(current_page * 8, this.state.data.length)
      );
      this.setState({
        more_data: temp_arr,
        load_more_option: { display: "none" }
      });
    }
    if (
      this.state.next_link != "" &&
      this.state.next_link &&
      this.state.data.length % 20 == 0 &&
      current_page % 2 == 1
    ) {
      getNextReferences.request(
        "Zpětná vazba zákazníka",
        this.state.next_link,
        this.setNextData
      );
    }
  }

  // loadMore() {
  //   var current_page = this.state.page;
  //   if (this.state.data.length > 8 + current_page * 8) {
  //     var temp_arr = this.state.more_data;
  //     temp_arr.push.apply(
  //       temp_arr,
  //       this.state.data.slice(8 + (current_page - 1) * 8, 8 + current_page * 8)
  //     );
  //     this.setState({
  //       more_data: temp_arr,
  //       page: current_page + 1
  //     });
  //   } else {
  //     var temp_arr = this.state.more_data;
  //     temp_arr.push.apply(
  //       temp_arr,
  //       this.state.data.slice(
  //         8 + (current_page - 1) * 8,
  //         this.state.data.length
  //       )
  //     );
  //     this.setState({
  //       more_data: temp_arr,
  //       load_more_option: { display: "none" }
  //     });
  //   }
  //   if (
  //     this.state.next_link != "" &&
  //     this.state.next_link &&
  //     this.state.data.length % 20 == 0 &&
  //     current_page % 2 == 0
  //   ) {
  //     getNextReferences.request(
  //       "Zpětná vazba zákazníka",
  //       this.state.next_link,
  //       this.setNextData
  //     );
  //   }
  // }

  render() {
    return (
      <div className="main-container" id="main-container-reference">
        <div className="projekty-container">
          <h1 className="projekty-nadpis">{i18n.t('general.customerFeedback')}</h1>
          <div className="projekty-stats">
            <div className="projekty-route">
              <b>Heated. &gt; </b>{i18n.t('reference.title')}
            </div>
            <div className="projetky-stats-right">
              <div className="projekty-pocet">
                {i18n.t('general.homeCount')}
                <br />
                <span>
                  {this.state.config_buildings
                    ? this.state.config_buildings
                    : "-"}
                </span>
              </div>
              <div className="projekty-plocha">
                {i18n.t('general.totalFloorArea')}
                <br />
                <span>
                  {this.state.config_area ? this.state.config_area : "-"}{" "}
                  <small>m</small>
                  <sup>2</sup>
                </span>
              </div>
            </div>
          </div>
          <div className="projekty-list">
            {this.state.data.map((value, index) => {
              var image_path = value.main_image;
              if (!image_path) {
                image_path =
                  config.paths.images + "img_zajimave_projekty_1.jpg";
              }
              if (index <= 7) {
                return (
                  <div key={index} className="projekty-one-cont">
                    <img className="projekt-bg-image" src={image_path} alt="" />
                    <Link
                      to={{
                        pathname: config.pages.ref_databaze_show.url.replace(':id', value.slug),
                      }}
                      className="projekt-link"
                    >
                      <div className="arrow-wrapper">
                        <div className="projekt-vice-info">
                          <span>{i18n.t('general.moreAboutReference')}</span>
                          <img
                            className="projekt-vice-arrow"
                            src={
                              config.paths.icons + "ic_arrow_right_orange.png"
                            }
                            alt={i18n.t('general.showProject')}
                          />
                        </div>
                        <div className="projekt-vice-info">
                          <img
                            className="projekt-vice-arrow"
                            src={
                              config.paths.icons + "ic_arrow_right_white.png"
                            }
                            alt={i18n.t('general.showProject')}
                          />
                        </div>
                      </div>
                      <div className="projekt-bottom">
                        <div className="projekt-name">
                          <span className="projekt-name-orange">
                            {value.name ? value.name : "-"}
                          </span>
                          <br />
                          <span className="projekt-name-location">
                            {value.subtitle ? value.subtitle : "-"}
                            {/* <b>Developerský</b> projekt */}
                            {/* <b>Křivoklátsko,</b> Střední Čechy */}
                          </span>
                        </div>
                        <div className="projekt-data">
                          <div className="projekt-data-block">
                          {/*  <span className="projekt-data-block-heading">*/}
                          {/*    {i18n.t('general.realization')}*/}
                          {/*  </span>*/}
                          {/*  <br />*/}
                          {/*  <span className="projekt-data-block-content">*/}
                          {/*    <b>*/}
                          {/*      {value.realization_date*/}
                          {/*        ? value.realization_date.substring(0, 4)*/}
                          {/*        : "-"}*/}
                          {/*    </b>*/}
                          {/*  </span>*/}
                          </div>
                          <div className="projekt-data-block">
                            <span className="projekt-data-block-heading">
                              {i18n.t('general.floorArea')}
                            </span>
                            <br />
                            <span className="projekt-data-block-content">
                              <b>
                                {value.floor_area ? value.floor_area : "-"}{" "}
                              </b>
                              m<sup>2</sup>
                            </span>
                          </div>
                          <div className="projekt-data-block">
                            <span className="projekt-data-block-heading">
                              {i18n.t('general.costsPerMonth')}
                            </span>
                            <br />
                            <span className="projekt-data-block-content">
                              <b>
                                {value.expenses_per_month
                                  ? value.expenses_per_month
                                  : "-"}
                              </b>{" "}
                              {i18n.t('general.currency')}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              }
            })}
            {this.state.more_data.map((value, index) => {
              var image_path = value.main_image;
              if (!image_path) {
                image_path =
                  config.paths.images + "img_zajimave_projekty_1.jpg";
              }
              if (index <= this.state.page * 7) {
                return (
                  <div key={index} className="projekty-one-cont">
                    <img className="projekt-bg-image" src={image_path} alt="" />
                    <Link
                      to={{
                        pathname: config.pages.ref_databaze_show.url.replace(':id', value.slug),
                      }}
                      className="projekt-link"
                    >
                      <div className="arrow-wrapper">
                        <div className="projekt-vice-info">
                          <span>{i18n.t('general.moreAboutReference')}</span>
                          <img
                            className="projekt-vice-arrow"
                            src={
                              config.paths.icons + "ic_arrow_right_orange.png"
                            }
                            alt={i18n.t('general.showProject')}
                          />
                        </div>
                        <div className="projekt-vice-info">
                          <img
                            className="projekt-vice-arrow"
                            src={
                              config.paths.icons + "ic_arrow_right_white.png"
                            }
                            alt={i18n.t('general.showProject')}
                          />
                        </div>
                      </div>
                      <div className="projekt-bottom">
                        <div className="projekt-name">
                          <span className="projekt-name-orange">
                            {value.name ? value.name : "-"}
                          </span>
                          <br />
                          <span className="projekt-name-location">
                            {value.subtitle ? value.subtitle : "-"}
                            {/* <b>Developerský</b> projekt */}
                            {/* <b>Křivoklátsko,</b> Střední Čechy */}
                          </span>
                        </div>
                        <div className="projekt-data">
                          {/*<div className="projekt-data-block">*/}
                          {/*  <span className="projekt-data-block-heading">*/}
                          {/*    {i18n.t('general.realization')}*/}
                          {/*  </span>*/}
                          {/*  <br />*/}
                          {/*  <span className="projekt-data-block-content">*/}
                          {/*    <b>*/}
                          {/*      {value.realization_date*/}
                          {/*        ? value.realization_date.substring(0, 4)*/}
                          {/*        : "-"}*/}
                          {/*    </b>*/}
                          {/*  </span>*/}
                          {/*</div>*/}
                          <div className="projekt-data-block">
                            <span className="projekt-data-block-heading">
                              {i18n.t('general.floorArea')}
                            </span>
                            <br />
                            <span className="projekt-data-block-content">
                              <b>
                                {value.floor_area ? value.floor_area : "-"}{" "}
                              </b>
                              m<sup>2</sup>
                            </span>
                          </div>
                          <div className="projekt-data-block">
                            <span className="projekt-data-block-heading">
                              {i18n.t('general.costsPerMonth')}
                            </span>
                            <br />
                            <span className="projekt-data-block-content">
                              <b>
                                {value.expenses_per_month
                                  ? value.expenses_per_month
                                  : "-"}
                              </b>{" "}
                              {i18n.t('general.currency')}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              }
            })}
            {/* {this.state.more_data.length > 0 ? ( */}
            <div
              className="projekty-nacist-vice"
              style={this.state.load_more_option}
              onClick={this.loadMore}
            >
              {i18n.t('general.loadMore')}
            </div>
            <LoadingData label={"Načítám realizace"} loading={getReferences.running || getNextReferences.running}/>
          </div>
        </div>
      </div>
    );
  }
}

export default DatabazeRealizaci;
