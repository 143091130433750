import React from 'react';
import PropTypes from 'prop-types';

function AnimationAboutSheetBackground({image, children, gradientDark}) {
    return (
        <div style={{backgroundImage: `url(${image})`}} className="AnimationAboutSheetBackground">
            <div className={[
                "AnimationAboutSheetBackground__image",
                gradientDark && "AnimationAboutSheetBackground__image--dark",
            ].filter((el) => el).join(' ')}>
                {children}
            </div>
        </div>
    );
}

AnimationAboutSheetBackground.propTypes = {
    image: PropTypes.string.isRequired,
};

export default AnimationAboutSheetBackground;
