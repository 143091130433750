import * as React from "react";
import config from "../../../../config/config.json";
import { Link } from "react-router-dom";
import { HeatflowNavigation } from "../components/HeatflowNavigation";
import CountUp, { startAnimation } from "react-countup";
import AnimationAboutOld from "../components/AnimationAboutOld.jsx";
import smoothScrollIntoView from "smooth-scroll-into-view-if-needed";
import { getConfig } from "../api.js";
import { numberWithSpaces } from "../functions.js";
import { getPageSEO } from "../functions";

import i18n from '../i18n';

class Heatflow extends React.Component {
  constructor(props) {
    super(props);

    this.pageSEO = getPageSEO();

    document.title = this.pageSEO.title;
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", this.pageSEO.keywords);
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", this.pageSEO.description);

    document.querySelector('meta[name=\"title\"]').setAttribute('content', this.pageSEO.metaTitle);

    this.state = {
      selected_section: "section_1",
      next_section: "section_21",
      last_animation_section: "section_1",
      scroll_display: { display: "block" },
      slider_value: 120,
      shift_left: "",
      didViewCountUp: false,
      fadein: false,
      config_area: 0,
      config_buildings: 0,
      shift_right: "",
      navigation: { opacity: 0 },
      nav_2: "hidden",
      nav_3: "hidden",
      nav_4: "hidden",
      animation_section: "1",
      enable_touch: false,
    };

    this.sliderHandle = this.sliderHandle.bind(this);
    this.scrollToSection = this.scrollToSection.bind(this);
    this.shiftLeft = this.shiftLeft.bind(this);
    this.shiftRight = this.shiftRight.bind(this);
    // this.handleNavigation = this.handleNavigation.bind(this);
    this.animationLoaded = this.animationLoaded.bind(this);
    this.onScroll = this.onScroll.bind(this);
    this.sideNavigation = this.sideNavigation.bind(this);
    this.setConfig = this.setConfig.bind(this);
    this.animationScroll = this.animationScroll.bind(this);
    this.onTouch = this.onTouch.bind(this);

    this.section1 = React.createRef();
    this.section2 = React.createRef();
    this.section3 = React.createRef();
    this.section4 = React.createRef();
    this.section5 = React.createRef();
    this.section6 = React.createRef();
    this.section7 = React.createRef();

    this.enableTouch = this.enableTouch.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll, { passive: true });
    getConfig.request(this.setConfig);
    // document.body.scrollTop = 0;

    window.addEventListener("touchmove", this.onTouch, {passive: false});
  }
  componentDidUpdate() {
    // document.body.scrollTop = 0;
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);

    if (!this.state.enable_touch) {
      window.removeEventListener("touchmove", this.onTouch);
    }
  }

  onTouch(e) {
    e.preventDefault();
  }

  enableTouch() {
    this.setState({
      enable_touch: true,
    });

    window.removeEventListener("touchmove", this.onTouch);
  }

  sliderHandle(value) {
    this.setState({ slider_value: value });
  }
  onScroll() {
    var breakpoint = parseInt(
      this.section7.current.getBoundingClientRect().height / 2
    );
    var top_sec_1 = this.section1.current.getBoundingClientRect().top;
    // var top_sec_2 = this.section2.current.getBoundingClientRect().top;
    // var top_sec_3 = this.section3.current.getBoundingClientRect().top;
    // var top_sec_4 = this.section4.current.getBoundingClientRect().top;
    // var top_sec_5 = this.section5.current.getBoundingClientRect().top;
    var top_sec_6 = this.section6.current.getBoundingClientRect().top;
    var top_sec_7 = this.section7.current.getBoundingClientRect().top;
   // console.log('PROOPS', this.state.selected_section);

    if(this.state.selected_section == "section_7"){
      this.setState({
        fadein: true,
        didViewCountUp: true,
      })
    }

    if (
      top_sec_1 > -breakpoint &&
      top_sec_1 < breakpoint &&
      this.state.selected_section != "section_1"
    ) {
      // this.setState({
      //   selected_section: "section_1",
      //   next_section: "section_21",
      //   scroll_display: { display: "block" },
      //   navigation: { display: "block", opacity: 1 }
      // });
      if (this.state.next_section == "section_7") {
        var sec = this.state.last_animation_section;
        this.setState({
          selected_section: sec,
          next_section: "section_21",
          scroll_display: { display: "block" },
          navigation: { display: "block", opacity: 1 }
        });
      }
    }
    // else if (
    //   top_sec_2 > -breakpoint &&
    //   top_sec_2 < breakpoint &&
    //   this.state.selected_section != "section_2"
    // ) {
    //   this.setState({
    //     selected_section: "section_2",
    //     next_section: "section_3"
    //   });
    // } else if (
    //   top_sec_3 > -breakpoint &&
    //   top_sec_3 < breakpoint &&
    //   this.state.selected_section != "section_3"
    // ) {
    //   this.setState({
    //     selected_section: "section_3",
    //     next_section: "section_4"
    //   });
    // } else if (
    //   top_sec_4 > -breakpoint &&
    //   top_sec_4 < breakpoint &&
    //   this.state.selected_section != "section_4"
    // ) {
    //   this.setState({
    //     selected_section: "section_4",
    //     next_section: "section_5"
    //   });
    // } else if (
    //   top_sec_5 > -breakpoint &&
    //   top_sec_5 < breakpoint &&
    //   this.state.selected_section != "section_5"
    // ) {
    //   this.setState({
    //     selected_section: "section_5",
    //     next_section: "section_6"
    //   });
    // }
    else if (
      top_sec_6 > -breakpoint &&
      top_sec_6 < breakpoint &&
      this.state.selected_section != "section_6"
    ) {
      this.setState({
        selected_section: "section_6",
        next_section: "section_7",
        scroll_display: { display: "block" },
        navigation: { display: "block", opacity: 1 }
      });
    } else if (
      top_sec_7 > -breakpoint &&
      top_sec_7 < breakpoint &&
      this.state.selected_section != "section_7"
    ) {
      this.setState({
        selected_section: "section_7",
        didViewCountUp: true,
        scroll_display: { display: "none" }
      });
    } else {
    }
    if (top_sec_7 < 0) {
      this.setState({
        navigation: { display: "none", opacity: 0 }
      });
    }

    // if (
    //   top_sec_7 > -breakpoint &&
    //   top_sec_7 < breakpoint &&
    //   this.state.selected_section != "section_7"
    // ) {
    //   this.setState({
    //     selected_section: "section_7",
    //     // next_section: "section_2",
    //     didViewCountUp: true
    //   });
    // }
  }

  setConfig(response) {
    this.setState({
      config_area: response.data.area_sum,
      config_buildings: response.data.building_sum
    });
  }
  animationScroll(section) {
    smoothScrollIntoView(this.section1.current, {
      behavior: "smooth",
      block: "start",
      duration: 1000
    });
    console.log("animage: " + section);
    if (section == "section_1_link") {
      this.setState({
        animation_section: "1",
        selected_section: "section_1",
        last_animation_section: "section_1",
        next_section: "section_21"
      });
    } else if (section == "section_21_link") {
      this.setState({
        animation_section: "21",
        selected_section: "section_21",
        last_animation_section: "section_21",
        next_section: "section_22"
      });
    } else if (section == "section_22_link") {
      this.setState({
        animation_section: "22",
        selected_section: "section_22",
        last_animation_section: "section_22",
        next_section: "section_23"
      });
    } else if (section == "section_23_link") {
      this.setState({
        animation_section: "23",
        selected_section: "section_23",
        last_animation_section: "section_23",
        next_section: "section_24"
      });
    } else if (section == "section_24_link") {
      this.setState({
        animation_section: "24",
        selected_section: "section_24",
        last_animation_section: "section_24",
        next_section: "section_3"
      });
    } else if (section == "section_3_link") {
      this.setState({
        animation_section: "3",
        selected_section: "section_3",
        last_animation_section: "section_3",
        next_section: "section_4"
      });
    } else if (section == "section_4_link") {
      this.setState({
        animation_section: "4",
        selected_section: "section_4",
        last_animation_section: "section_4",
        next_section: "section_5"
      });
    } else if (section == "section_5_link") {
      this.setState({
        animation_section: "5",
        selected_section: "section_5",
        last_animation_section: "section_5",
        next_section: "section_6"
      });
    } else {
    }
  }

  scrollToSection(section_selected) {
    var id_of_section = "";
    if (section_selected == "next") {
      id_of_section = this.state.next_section;
    } else {
      id_of_section = section_selected.substring(
        0,
        section_selected.length - 5
      );
    }
    this.setState({ selected_section: id_of_section });

    if (id_of_section == "section_1") {
      smoothScrollIntoView(this.section1.current, {
        behavior: "smooth",
        block: "end",
        duration: 1000
      });
      // this.section1.current.scrollIntoView({
      //   behavior: "smooth",
      //   block: "end"
      // });
    }
    // else if (id_of_section == "section_2") {
    //   this.section2.current.scrollIntoView({
    //     behavior: "smooth",
    //     block: "end"
    //   });
    // } else if (id_of_section == "section_3") {
    //   this.section3.current.scrollIntoView({
    //     behavior: "smooth",
    //     block: "end"
    //   });
    // } else if (id_of_section == "section_4") {
    //   this.section4.current.scrollIntoView({
    //     behavior: "smooth",
    //     block: "end"
    //   });
    // } else if (id_of_section == "section_5") {
    //   this.section5.current.scrollIntoView({
    //     behavior: "smooth",
    //     block: "end"
    //   });
    // }
    else if (id_of_section == "section_6") {
      smoothScrollIntoView(this.section6.current, {
        behavior: "smooth",
        block: "end",
        duration: 1000
      });
      // this.section6.current.scrollIntoView({
      //   behavior: "smooth",
      //   block: "end"
      // });
    } else {
      smoothScrollIntoView(this.section7.current, {
        behavior: "smooth",
        block: "end",
        duration: 1000
      });
      // this.section7.current.scrollIntoView({
      //   behavior: "smooth",
      //   block: "end"
      // });
    }
  }

  shiftLeft() {
    if (this.state.shift_left == "") {
      this.setState({ shift_left: "open-left" });
    } else {
      this.setState({ shift_left: "" });
    }
  }

  shiftRight() {
    if (this.state.shift_right == "") {
      this.setState({ shift_right: "open-right" });
    } else {
      this.setState({ shift_right: "" });
    }
  }
  // handleNavigation(after) {
  //   var next = this.state.next_section;
  //   this.setState({ selected_section: next, next_section: after });
  // }
  sideNavigation(stav) {
    console.log('stav', stav);
    if (stav == 1) {
      this.setState({
        selected_section: "section_1",
        last_animation_section: "section_1",
        nextSection: "section_21"
      });
    } else if (stav == 2) {
      this.setState({
        selected_section: "section_21",
        last_animation_section: "section_21",
        nextSection: "section_22"
      });
    } else if (stav == 3) {
      this.setState({
        selected_section: "section_22",
        last_animation_section: "section_22",
        nextSection: "section_23"
      });
    } else if (stav == 4) {
      this.setState({
        selected_section: "section_23",
        last_animation_section: "section_23",
        nextSection: "section_24"
      });
    } else if (stav == 5) {
      this.setState({
        selected_section: "section_24",
        last_animation_section: "section_24",
        nextSection: "section_3"
      });
    } else if (stav == 6) {
      this.setState({
        selected_section: "section_3",
        last_animation_section: "section_3",
        nextSection: "section_4"
      });
    } else if (stav == 7) {
      this.setState({
        selected_section: "section_4",
        last_animation_section: "section_4",
        nextSection: "section_5"
      });
    } else if (stav == 8) {
      document.body.style.overflowY = "scroll";
      this.setState({
        selected_section: "section_5",
        last_animation_section: "section_5",
        nextSection: "section_6"
      });
    } else if (stav == 9) {
      document.body.style.overflowY = "scroll";
      this.setState({
        selected_section: "section_6",
        nextSection: "section_7"
      });
    } else {
      document.body.style.overflowY = "scroll";
      this.setState({ selected_section: "section_7" });
    }
  }
  animationLoaded() {
    this.setState({ navigation: { opacity: 1 } });
  }

  render() {
    // HEAD ENERGY FUSION BACKGROUNDS

    var ef_left_bg_path =
      config.paths.backgrounds + "img_homepage_background_ef_left.png";

    var ef_left_bg = {
      background: "url(" + ef_left_bg_path + ")",
      backgroundPosition: "0 0",
      backgroundSizie: "auto"
    };

    var ef_right_bg_path =
      config.paths.backgrounds + "img_homepage_background_ef_right.png";

    var ef_right_bg = {
      background: "url(" + ef_right_bg_path + ")",
      backgroundPosition: "100% 0",
      backgroundSize: "auto"
    };

    var ef_bg = {
      background: "url(" + ef_left_bg_path + "), url(" + ef_right_bg_path + ")",
      backgroundPosition: "0% 0%, 100% 0%",
      backgroundRepeat: "no-repeat",
      backgroundSize: "65% auto, 60% auto"
    };

    // var slider_handle = config.paths.icons + "ic_handle_bluegreen_gradient.png";
    // var slider_head = config.paths.backgrounds + "img_slider_bg_active.png";
    // var slider_tail = config.paths.backgrounds + "img_slider_bg.png";

    return (

      <div
        className="main-container-heatflow"
        ref={this.section1}
        style={{ paddingTop: "9vh" }}
      >
        {" "}
        {/* dočasný inline-style */}
        <div className="main-desktop">
          { this.state.selected_section != "section_1" ?
          <HeatflowNavigation
            onNavigationClick={this.scrollToSection}
            selected_section={this.state.selected_section}
            style={this.state.navigation}
            section2_nav_2={this.state.nav_2}
            section2_nav_3={this.state.nav_3}
            section2_nav_4={this.state.nav_4}
            onAnimationNavigationClick={this.animationScroll}
            // onSectionChange={this.handleNavigation}
          />
          : []}
          {/* <div
						className="scroll-cont"
						// onClick={() => {
						// 	  this.scrollToSection("next");
						// }}
						style={this.state.scroll_display}
					>
						<img
							className="scroll-icon"
							src={config.paths.icons + "ic_scroll_grey.png"}
							alt="Pokračovat"
						/>
					</div> */}

          <div className="page-section">
            <AnimationAboutOld
              onSectionChange={this.sideNavigation}
              nextSection={this.scrollToSection}
              onLoad={this.animationLoaded}
              mouse={this.state.scroll_display}
              secondSection={this.sideNavigation}
              changeSection={this.state.animation_section}
              parentCallback={this.enableTouch}
            />
          </div>

          <div className="page-section" ref={this.section6} id="section_6">
            <div className="section-content">
              <div className="section-text">
                <h2 className="section-heading">
                  <b>{i18n.t('reference.caseStudy')}</b>
                </h2>
                <div className="section-info">
                  {i18n.t('reference.caseStudyText')}
                </div>
                <div className="section-link-container">
                  <Link
                    className="section-link"
                    to={config.pages.pripadova_studie.url}
                  >
                    {i18n.t('reference.caseStudy')}
                  </Link>
                </div>
              </div>
              <img
                className="section-side-graph"
                src={config.paths.images + "img_heatflow_studie_" + i18n.language + ".svg"}
                alt={i18n.t('reference.caseStudyComparison')}
              />
            </div>
          </div>
          <div className="page-section" ref={this.section7} id="section_7">
            <div className="section-content">
              <div className="section-left">
                <Link
                  to={config.pages.ref_projekty.url}
                  className="section-side-link"
                >
                  <h3 className="section-side-heading" dangerouslySetInnerHTML={{ __html: i18n.t('reference.interestingProjectsBr') }}></h3>
                  <div className="section-side-text">
                    {i18n.t('reference.portfolioShowcaseInterestingProjects')}
                  </div>
                  <div className="section-side-arrow" />
                </Link>
              </div>
              <div className="section-right">
                <Link
                  to={config.pages.ref_domy.url}
                  className="section-side-link"
                >
                  <h3 className="section-side-heading" dangerouslySetInnerHTML={{ __html: i18n.t('reference.housesBr') }}></h3>
                  <div className="section-side-text">
                    {i18n.t('reference.portfolioShowcaseHouses')}
                  </div>
                  <div className="section-side-arrow" />
                </Link>
              </div>
              <div className="section-middle">
                <span className="section-route">
                  {i18n.t('reference.title')}
                </span>
                <h2 className="section-heading" dangerouslySetInnerHTML={{ __html: i18n.t('reference.ourRealization') }}></h2>
                <span className="section-data">{i18n.t('reference.count')}</span>
                <h2 className="section-data-value">
                    <div
                        className={
                          "fade-show " + (this.state.didViewCountUp ? "show" : "")
                        }
                    >
                      <b>
                        {<CountUp
                            start={0}
                            end={
                              this.state.didViewCountUp
                                  ? 3203
                                  : 0
                            }
                            duration={1.5}
                            separator=" "
                            useGrouping={true}
                            ref={countUp => {
                              this.myCountUp = countUp;
                            }}
                        />}
                    {/*{numberWithSpaces(this.state.config_buildings)}*/}
                  </b>
                    </div>

                </h2>
                <span className="section-data">
                  {i18n.t('general.totalRealizeArea')}
                </span>
                <h2 className="section-data-value">
                  <div
                      className={
                        "fade-show " + (this.state.didViewCountUp ? "show" : "")
                      }
                  >
                      <b>
                        {<CountUp
                            start={0}
                            end={
                              this.state.didViewCountUp ? 353499 : 0
                            }
                            duration={1.5}
                            separator=" "
                            useGrouping={true}
                            ref={countUp => {
                              this.myCountUp = countUp;
                            }}
                        />}
                    {/*{numberWithSpaces(this.state.config_area)}*/}
                  </b>
                  {" "}
                  m<sup>2</sup>
                    </div>

                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Heatflow;
