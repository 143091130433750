import React, {useState, useCallback} from 'react';

import AnimationAboutContainer from './AnimationAboutContainer';

import config from '../../../../config/config.json';

import i18n from '../i18n';

function AnimationAboutSheet({kartoteka, kartotekaPushed, theme, children, noPaddingBottom, noPaddingTop, smallPaddingBottom, collapsible, title, initialyCollapsed})
{
    const [collapsed, setCollapsed] = useState(initialyCollapsed == undefined ? false : initialyCollapsed);

    const toggle = useCallback(() => {
        if (!collapsible) {
            return;
        }

        setCollapsed((state) => !state);
    }, [setCollapsed, collapsible]);

    return (
        <div className={[
            'AnimationAboutSheet',
            theme === 'light' ? 'AnimationAboutSheet--light' : theme === 'dark' ? 'AnimationAboutSheet--dark' : (theme === 'gray' ? 'AnimationAboutSheet--gray' : (theme === 'gray--gradient' ? 'AnimationAboutSheet--gray--gradient' : 'AnimationAboutSheet--gray--light')),
            noPaddingBottom && 'AnimationAboutSheet--no-padding-bottom',
            smallPaddingBottom && 'AnimationAboutSheet--small-padding-bottom',
            noPaddingTop && 'AnimationAboutSheet--no-padding-top',
            collapsed && 'AnimationAboutSheet--collapsed',
            collapsible && 'AnimationAboutSheet--collapsible',
        ].filter((el) => el).join(' ')}>
            {kartoteka !== undefined && <img src={config.paths.about + 'kartoteka_' + kartoteka + '.png'} alt="Kartotéka" className={[
                "AnimationAboutSheet__kartoteka",
                kartotekaPushed && "AnimationAboutSheet__kartoteka--pushed",
            ].filter((el) => el).join(' ')} />}
            {kartoteka !== undefined && <div className="AnimationAboutSheet__kartoteka-fill" />}
            {kartoteka !== undefined && <div className="AnimationAboutSheet__kartoteka-fill-bot" />}
            {(title || collapsible) && (
                <AnimationAboutContainer>
                    <div role="button" tabIndex="-1" onClick={toggle} onKeyDown={toggle} className="AnimationAboutSheet__header">
                            <div className="AnimationAboutSheet__title">
                                {title}
                            </div>
                            {collapsible && (
                                <div className={[
                                    "AnimationAboutSheet__collapse",
                                ].filter((el) => el).join(' ')}>
                                    <img style={{width: '2.70833vw'}} src={config.paths.about + 'collapse.png'} alt={ i18n.t('general.toHide') } />
                                </div>
                            )}
                    </div>
                </AnimationAboutContainer>
            )}
            <div className="AnimationAboutSheet__content">
                {children}
            </div>
        </div>
    );
}

export default AnimationAboutSheet;
