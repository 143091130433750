import config from "../../../config/config";

const localConsoleLog = (title, log) => {
    if (document.location.hostname === 'localhost') {
        console.log('/// ' + title + ' ///');
        if (log !== undefined) {
            console.log(log);
        }
        console.log('------------------------------------');
    }
};

const cloneObject = (object) => {
    return JSON.parse(
        JSON.stringify(
            object
        )
    );
};

const parseNumber = (value) => {

    let temp = '';
    let result;

    for (let i = 0; i < value.length; i++) {
        if (value.charAt(i).match(/^[0-9]+$/) !== null) {
            temp = temp + value.charAt(i);
        }
    }

    if (temp.length < 1) {
        result = 0;
    } else {
        result = parseInt(temp, 10);
    }

    return result;

};

const parseNumberStringify = (value) => {

    let temp = '';
    let result = '';

    for (let i = 0; i < value.length; i++) {
        if (value.charAt(i).match(/^[0-9]+$/) !== null) {
            temp = temp + value.charAt(i);
        }
    }

    if (temp.length < 1) {
        result = '';
    } else {
        result = parseInt(temp, 10).toString();
    }

    return result;

};

const parseBoolean = (value) => {

    let result = false;

    if (value === true || value === 'true' || value === 1 || value === '1') {
        result = true;
    }

    return result;

};

const getHTMLContent = (html) => {
    return { __html: html };
};

const numberWithSpaces = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

const getPageSEO = () => {

    const pages = config.pages;
    const keys = Object.keys(config.pages);
    const pathName = document.location.pathname;

    let result = {
        title: '',
        keywords: '',
        description: ''
    };

    for (let i = 0; i < keys.length; i++) {

        if (pages[keys[i]].url === pathName || pages[keys[i]].url + '/' === pathName) {
            result = {
                title: pages[keys[i]].title ? pages[keys[i]].title : '',
                keywords: pages[keys[i]].keywords ? pages[keys[i]].keywords : '',
                description: pages[keys[i]].description ? pages[keys[i]].description : ''
            };
            break;
        }

    }

    return result;

}

export {
    localConsoleLog,
    cloneObject,
    parseNumber,
    parseNumberStringify,
    parseBoolean,
    getHTMLContent,
    numberWithSpaces,
    getPageSEO
};