import * as React from "react";
import config from "../../../../config/config.json";
import { Menu } from "../components/Menu";
import { Link } from "react-router-dom";
import Slider, { createSliderWithTooltip } from "rc-slider";
import "../../../../node_modules/rc-slider/assets/index.css";
import { FormItemUpload } from "../components/FormItemUpload.jsx";
import { postOffers } from "../api.js";
import { getPageSEO } from "../functions";

import i18n from '../i18n';

class Konfigurator extends React.Component {
  constructor(props) {
    super(props);

    this.pageSEO = getPageSEO();

    document.title = this.pageSEO.title;
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", this.pageSEO.keywords);
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", this.pageSEO.description);

    document.querySelector('meta[name=\"title\"]').setAttribute('content', this.pageSEO.metaTitle);

    this.state = {
      selected_section: "section_1",
      next_section: "section_2",
      can_continue: false,
      steps: true,
      scroll_display: { display: "none", opacity: 0 },
      slider_value: 120,
      conf_slider_value: 200,
      conf_slider_value_roznos: 50,
      conf_slider_value_final: 5,
      conf_slider_value_total: 30,
      shift_left: "",
      shift_right: "",
      area_value: 120,
      vyska: 22,
      conf_slider: 0,
      config_area: 0,
      config_buildings: 0,
      furthers_conf_slider: 0,
      conf: 1,
      max_conf: 1,
      izolace: 0,
      roznos: 0,
      final: 3,
      izolace_hover: 0,
      roznos_hover: 0,
      final_hover: 3,
      prev_conf: 1,
      slider_disabled: true,
      spol_logos: null,
      video_loaded: false,
      intro_playing: false,
      show_play_btn: false,
      porovnani: false,
      intelligent_solution_right: true,
      intelligent_solution_left: true,
      sent: false,
      name: "",
      city: "",
      email: "@",
      phase: "",
      sources: "",
      submitted: "",
      valid_email: "",
      valid_phone: "",
      file_input: { display: "none" },
      slider_visibility: { visibility: "hidden" },
      select_empty: "",
      formItems: {
        podklady: {
          value: "",
          error: ""
        }
      },
      spolupracujeme: "",
      duration: 24,
      video_muted: "ic_sound_off",
      submit_title: "",
      video_ended: false,
      video_clicked: false,
      show_controls: false,
      loader_loaded: false,
      hp_loaded: false,
      disable_scroll: true,
      missing_files: false,
      showLoader: false,
      error_message: i18n.t('general.weWillNeed'),
      scaleThumb: "scale-thumb",
      form_response: i18n.t('general.takeCareASAP')
    };
    this.sliderHandle = this.sliderHandle.bind(this);
    this.areaHandle = this.areaHandle.bind(this);
    this.changeFormUploadItem = this.changeFormUploadItem.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.startConf = this.startConf.bind(this);
    this.confSliderHandle = this.confSliderHandle.bind(this);
    this.confSliderHandleRoznos = this.confSliderHandleRoznos.bind(this);
    this.confSliderHandleFinal = this.confSliderHandleFinal.bind(this);
    this.confSliderHandleTotal = this.confSliderHandleTotal.bind(this);
    this.izolaceClick = this.izolaceClick.bind(this);
    this.roznosClick = this.roznosClick.bind(this);
    this.finalClick = this.finalClick.bind(this);
    this.isMacintosh = this.isMacintosh.bind(this);

    this.finalOn = this.finalOn.bind(this);
    this.finalOut = this.finalOut.bind(this);

    this.izolaceOn = this.izolaceOn.bind(this);
    this.izolaceOut = this.izolaceOut.bind(this);

    this.roznosOn = this.roznosOn.bind(this);
    this.roznosOut = this.roznosOut.bind(this);

    this.stepBack = this.stepBack.bind(this);
    this.toStep = this.toStep.bind(this);
    this.markAcquired = this.markAcquired.bind(this);
    this.duringStep = this.duringStep.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.posted = this.posted.bind(this);

    this.preventEnter = this.preventEnter.bind(this);

    this.section2 = React.createRef();

    this.isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function(p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(
        !window["safari"] ||
          (typeof safari !== "undefined" && safari.pushNotification)
      );

    // if (this.state.can_continue && this.state.steps) {
    //   if (this.isMacintosh()) {
    //     WheelReact.config({
    //       up: () => {
    //         this.scrollToSection("next");
    //       },
    //       down: () => {
    //         this.scrollToSection("prev");
    //       }
    //     });
    //   } else {
    //     WheelReact.config({
    //       down: () => {
    //         this.scrollToSection("next");
    //       },
    //       up: () => {
    //         this.scrollToSection("prev");
    //       }
    //     });
    //   }
    // }

    this.izolace_names = [i18n.t('home.whitePolystyrene'), i18n.t('home.pir'), i18n.t('home.wool'), i18n.t('home.grayPolystyrene')];
    this.roznos_names = [i18n.t('home.cementScreedNoBr'), i18n.t('home.anhydrit'), i18n.t('home.gypsumFiberBoardsNoBr')];
    this.final_names = [
        i18n.t('home.polishedConcreteNoBr'),
        i18n.t('home.laminatedFloorNoBr'),
        i18n.t('home.pvc'),
        i18n.t('home.pavement'),
        i18n.t('home.solidWood'),
    ];

    this.izolace_tloustka_values = [
      [50, 300, 10],
      [30, 200, 10],
      [20, 100, 10],
      [50, 250, 10]
    ];
    this.roznos_tloustka_values = [[50, 80, 5], [40, 60, 5], [25, 50, 25]];
    this.final_tloustka_values = [
      [5, 10, 1],
      [5, 10, 1],
      [5, 10, 1],
      [5, 10, 1],
      [5, 20, 1]
    ];

    this.izolace_tloustka_recommended = [200, 130, 50, 150];
    this.roznos_tloustka_recommended = [50, 45, 25];
    this.final_tloustka_recommended = [5, 5, 5, 5, 10];

    this.izolace_desc = [
      <div className="section-info">
        <div className="section-line">
          <img
            className="conf-icon"
            src={config.paths.icons + "ic_conf_info.svg"}
            alt={i18n.t('general.weRecommend')}
          />{" "}
          {i18n.t('general.recommendThick')}{" "}
          <span className="orange">
            {this.izolace_tloustka_recommended[0]} mm
          </span>
        </div>
        <div className="section-line">
          <img
            className="conf-icon"
            src={config.paths.icons + "ic_conf_plus.svg"}
            alt={i18n.t('general.pros')}
          />{" "}
          {i18n.t('general.goodIsolationLowPrice')}
        </div>
        <div className="section-line">
          <img
            className="conf-icon"
            src={config.paths.icons + "ic_conf_minus.svg"}
            alt={i18n.t('general.cons')}
          />{" "}
          {i18n.t('general.greaterDemandHeight')}
        </div>
      </div>,
      <div className="section-info">
        <div className="section-line">
          <img
            className="conf-icon"
            src={config.paths.icons + "ic_conf_info.svg"}
            alt={i18n.t('general.weRecommend')}
          />{" "}
          {i18n.t('general.recommendThick')}{" "}
          <span className="orange">
            {this.izolace_tloustka_recommended[1]} mm
          </span>
        </div>
        <div className="section-line">
          <img
            className="conf-icon"
            src={config.paths.icons + "ic_conf_plus.svg"}
            alt={i18n.t('general.pros')}
          />{" "}
          {i18n.t('general.beterIsolationLowerDemand')}
        </div>
        <div className="section-line">
          <img
            className="conf-icon"
            src={config.paths.icons + "ic_conf_minus.svg"}
            alt={i18n.t('general.cons')}
          />{" "}
          {i18n.t('general.heigherPrice')}
        </div>
      </div>,
      <div className="section-info">
        <div className="section-line">
          <img
            className="conf-icon"
            src={config.paths.icons + "ic_conf_info.svg"}
            alt={i18n.t('general.weRecommend')}
          />{" "}
          {i18n.t('general.mezzanineInsulation')}
        </div>

        <div className="section-line">
          <img
            className="conf-icon"
            src={config.paths.icons + "ic_conf_minus.svg"}
            alt={i18n.t('general.cons')}
          />{" "}
          {i18n.t('general.weDontRecommend')}
        </div>
      </div>,
      <div className="section-info">
        <div className="section-line">
          <img
            className="conf-icon"
            src={config.paths.icons + "ic_conf_info.svg"}
            alt={i18n.t('general.weRecommend')}
          />{" "}
          {i18n.t('general.recommendThick')}{" "}
          <span className="orange">
            {this.izolace_tloustka_recommended[3]} mm
          </span>
        </div>
        <div className="section-line">
          <img
            className="conf-icon"
            src={config.paths.icons + "ic_conf_plus.svg"}
            alt={i18n.t('general.pros')}
          />{" "}
          {i18n.t('general.betterIsolationEPS')}
        </div>
        <div className="section-line">
          <img
            className="conf-icon"
            src={config.paths.icons + "ic_conf_minus.svg"}
            alt={i18n.t('general.cons')}
          />{" "}
          {i18n.t('general.heigherPrice')}
        </div>
      </div>
    ];
    this.izolace_tloustka = [true, true, true, true];

    this.roznos_desc = [
      <div className="section-info">
        <div className="section-line">
          <img
            className="conf-icon"
            src={config.paths.icons + "ic_conf_info.svg"}
            alt={i18n.t('general.weRecommend')}
          />{" "}
          {i18n.t('general.recommendThick')}{" "}
          <span className="orange">
            {this.roznos_tloustka_recommended[0]} mm
          </span>
        </div>
        <div className="section-line">
          <img
            className="conf-icon"
            src={config.paths.icons + "ic_conf_plus.svg"}
            alt={i18n.t('general.pros')}
          />{" "}
          {i18n.t('general.keepsFloorWarmer')}
        </div>
        <div className="section-line">
          <img
            className="conf-icon"
            src={config.paths.icons + "ic_conf_minus.svg"}
            alt={i18n.t('general.cons')}
          />{" "}
          {i18n.t('general.lowerHeatCapacity')}
        </div>
      </div>,
      <div className="section-info">
        <div className="section-line">
          <img
            className="conf-icon"
            src={config.paths.icons + "ic_conf_info.svg"}
            alt={i18n.t('general.weRecommend')}
          />{" "}
          {i18n.t('general.recommendThick')}{" "}
          <span className="orange">
            {this.roznos_tloustka_recommended[1]} mm
          </span>
        </div>
        <div className="section-line">
          <img
            className="conf-icon"
            src={config.paths.icons + "ic_conf_plus.svg"}
            alt={i18n.t('general.pros')}
          />{" "}
          {i18n.t('general.heigherHeatCapacity')}
        </div>
        <div className="section-line">
          <img
            className="conf-icon"
            src={config.paths.icons + "ic_conf_minus.svg"}
            alt={i18n.t('general.cons')}
          />{" "}
          {i18n.t('general.lowerInertiaConcreat')}
        </div>
      </div>,
      <div className="section-info">
        <div className="section-line">
          <img
            className="conf-icon"
            src={config.paths.icons + "ic_conf_info.svg"}
            alt={i18n.t('general.weRecommend')}
          />{" "}
          {i18n.t('general.recommendThick')}{" "}
          <span className="orange">
            min. {this.roznos_tloustka_recommended[2]} mm
          </span>
        </div>
        <div className="section-line">
          <img
            className="conf-icon"
            src={config.paths.icons + "ic_conf_plus.svg"}
            alt={i18n.t('general.pros')}
          />{" "}
          {i18n.t('general.dryProcess')}
        </div>
        <div className="section-line">
          <img
            className="conf-icon"
            src={config.paths.icons + "ic_conf_minus.svg"}
            alt={i18n.t('general.cons')}
          />{" "}
          {i18n.t('general.lowerInertiaFry')}
        </div>
      </div>
    ];
    this.roznos_tloustka = [true, true, true];

    this.final_desc = [
      <div className="section-info">
        <div className="section-line">
          <img
            className="conf-icon"
            src={config.paths.icons + "ic_conf_plus.svg"}
            alt={i18n.t('general.pros')}
          />{" "}
          {i18n.t('general.heighVariability')}
        </div>
        <div className="section-line">
          <img
            className="conf-icon"
            src={config.paths.icons + "ic_conf_minus.svg"}
            alt={i18n.t('general.cons')}
          />{" "}
          {i18n.t('general.industrialSpaces')}
        </div>
      </div>,
      <div className="section-info">
        <div className="section-line">
          <img
            className="conf-icon"
            src={config.paths.icons + "ic_conf_plus.svg"}
            alt={i18n.t('general.pros')}
          />{" "}
          {i18n.t('general.heigSelectionLowPrice')}
        </div>
        <div className="section-line">
          <img
            className="conf-icon"
            src={config.paths.icons + "ic_conf_minus.svg"}
            alt={i18n.t('general.cons')}
          />{" "}
          {i18n.t('general.lowServiceLife')}
        </div>
      </div>,
      <div className="section-info">
        <div className="section-line">
          <img
            className="conf-icon"
            src={config.paths.icons + "ic_conf_plus.svg"}
            alt={i18n.t('general.pros')}
          />{" "}
          {i18n.t('general.heigSelectionLowPrice')}
        </div>
        <div className="section-line">
          <img
            className="conf-icon"
            src={config.paths.icons + "ic_conf_minus.svg"}
            alt={i18n.t('general.cons')}
          />{" "}
          {i18n.t('general.softLayer')}
        </div>
      </div>,
      <div className="section-info">
        <div className="section-line">
          <img
            className="conf-icon"
            src={config.paths.icons + "ic_conf_plus.svg"}
            alt={i18n.t('general.pros')}
          />{" "}
          {i18n.t('general.heighSelectionHeighCapacity')}
        </div>
        <div className="section-line">
          <img
            className="conf-icon"
            src={config.paths.icons + "ic_conf_minus.svg"}
            alt={i18n.t('general.cons')}
          />{" "}
          {i18n.t('general.heighPricePavement')}
        </div>
      </div>,
      <div className="section-info">
        <div className="section-line">
          <img
            className="conf-icon"
            src={config.paths.icons + "ic_conf_plus.svg"}
            alt={i18n.t('general.pros')}
          />{" "}
          {i18n.t('general.heatMaterialServiceLife')}
        </div>
        <div className="section-line">
          <img
            className="conf-icon"
            src={config.paths.icons + "ic_conf_minus.svg"}
            alt={i18n.t('general.cons')}
          />{" "}
          {i18n.t('general.lowCapacityHeighPrice')}
        </div>
      </div>
    ];
    this.final_tloustka = [true, true, true, true, true];
    this.body = null;
    this.marks = {
      0: (
        <div className="conf-slider-label conf-slider-1" dangerouslySetInnerHTML={{ __html: i18n.t('home.floorIsolatonBr') }}></div>
      ),
      25: (
        <div className="conf-slider-label conf-slider-2">{i18n.t('general.spreadingLayer')}</div>
      ),
      50: <div className="conf-slider-label conf-slider-3">{i18n.t('general.finalLayer')}</div>,
      75: <div className="conf-slider-label conf-slider-4">{i18n.t('general.conclusion')}</div>,
      100: <div className="conf-slider-label conf-slider-5">{i18n.t('general.evaluation')}</div>
    };

    this.scrolling = true;
  }

  componentDidMount() {
    this.body = document.querySelector("body");

    window.addEventListener("keydown", this.preventEnter);

    this.setState({ showLoader: true });
  }
  preventEnter(e) {
    if (e.keyCode == 13) {
      e.preventDefault();
      return false;
    }
  }

  componentWillUnmount() {
    document.body.style.overflowY = "scroll";
  }
  confSliderHandle(value) {
    this.setState({ conf_slider_value: value });
  }
  confSliderHandleRoznos(value) {
    this.setState({ conf_slider_value_roznos: value });
  }
  confSliderHandleFinal(value) {
    this.setState({ conf_slider_value_final: value });
  }
  confSliderHandleTotal(value) {
    this.setState({ conf_slider_value_total: value });
  }

  isMacintosh() {
    return navigator.platform.indexOf("Mac") > -1;
  }

  changeFormUploadItem(name, file) {
    if (name == "delete") {
      var temp = this.state.sources;
      temp.splice(file, 1);

      this.setState({
        sources: temp,
        formItems: {
          podklady: {
            value: temp,
            error: ""
          }
        }
      });
    } else {
      if (this.state[name]) {
        var temp = this.state[name];
        temp.push(file);

        this.setState({
          [name]: temp,
          formItems: {
            podklady: {
              value: temp,
              error: ""
            }
          }
        });
      } else {
        this.setState({
          [name]: [file],
          formItems: {
            podklady: {
              value: [file],
              error: ""
            }
          }
        });
      }
    }
  }

  areaHandle(value) {
    this.setState({ area_value: value });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (target.type == "select-one" && value == "Mám projekt") {
      this.setState({
        file_input: { display: "block" }
      });
    }

    if (target.type == "select-one" && value != "Mám projekt") {
      this.setState({
        file_input: { display: "none" }
      });
    }

    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value) && name == "email") {
      this.setState({ valid_email: "" });
    }

    if (i18n.language === 'cs') {
      if (value && /(\+\d{3})?\d{9}/.test(value) && name == "city") {
        this.setState({ valid_phone: "" });
      }
    } else {
      if (value && /(\+\d{3})?\d{10}/.test(value) && name == "city") {
        this.setState({ valid_phone: "" });
      }
    }

    this.setState({
      [name]: value
    });
  }
  handleSubmit(e) {
    e.preventDefault();

    const data = {
      type: "Konfigurátor podlah",
      name: this.state.name,
      phone_number: this.state.city,
      email: this.state.email,
      area: this.state.area_value,
      stage: this.state.phase,
      sources: this.state.sources,
      floor_config: [
        {
          name: this.izolace_names[this.state.izolace],
          height: this.state.conf_slider_value
        },
        {
          name: this.roznos_names[this.state.roznos],
          height: this.state.conf_slider_value_roznos
        },
        {
          name: this.final_names[this.state.final],
          height: this.state.conf_slider_value_final
        }
      ]
    };

    let failed = false;

    if (this.state.phase == "Mám projekt" && this.state.sources == "") {
      this.setState({ missing_files: true });

      failed = true;
    }

    if (this.state.city !== '') {

      if (i18n.language === 'cs') {
        if (! /(\+\d{3})?\d{9}/.test(this.state.city)) {
          this.setState({ valid_phone: "not_valid" });

          failed = true;
        }
      } else {
        if (! /(\+\d{3})?\d{10}/.test(this.state.city)) {
          this.setState({ valid_phone: "not_valid" });

          failed = true;
        }
      }

    }

    if (! /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email)) {
      this.setState({ valid_email: "not_valid" });

      failed = true;
    }



    if (
      false === failed &&
      this.state.name != "" &&
      this.state.email != "" &&
      this.state.area_value != ""
    ) {
      postOffers.request(data, this.posted);
      const step = 7;
      this.setState({
        conf: step,
        slider_disabled: true,
        submit_title: i18n.t('general.sending'),
        form_response: i18n.t('general.loadingFiles')
      });
      setTimeout(() => {
        this.setState({
          prev_conf: step,
          sent: true
        });
      }, 500);
    } else {
      this.setState({ submitted: true });
    }
  }
  posted(response) {
    if (response == "Too large") {
      this.setState({
        title: "",
        conf: 6,
        error_message: i18n.t('general.filesTooLarge')
      });
      setTimeout(() => {
        this.setState({
          prev_conf: 6
        });
      }, 500);
      return;
    }
    if (response.status == 200 || response.status == 201) {
      this.setState({
        submit_title: i18n.t('general.sended'),
        form_response: i18n.t('general.takeCareASAP')
      });

      window.dataLayer.push({
        'event': 'lead',
        'formLocation': 'skladba-konfigurator',
      });

      setTimeout(() => {
        this.setState({
          scroll_display: { display: "block", opacity: 1 }
        });
      }, 500);
    } else if (response.status == 429) {
      this.setState({
        title: i18n.t('general.sendingFailed')
      });
    } else {
    }
  }

  sliderHandle(value) {
    this.setState({ slider_value: value });
  }
  shiftLeft() {
        if (this.state.shift_left == "") {
            this.setState({
                shift_left: 'open-left',
                intelligent_solution_left: false,
                shift_right: '',
                intelligent_solution_right: true,
            });
        } else {
            this.setState({ shift_left: "", intelligent_solution_left: true });
        }
    }
    shiftRight() {
        if (this.state.shift_right == "") {
            this.setState({
                shift_right: 'open-right',
                intelligent_solution_right: false,
                shift_left: '',
                intelligent_solution_left: true,
            });
        } else {
            this.setState({ shift_right: "", intelligent_solution_right: true });
        }
    }
  startConf() {
    // const step = this.state.conf;
    // this.setState({conf: step+1})
    // setTimeout(()=>{
    //   this.setState({prev_conf: step+1})
    // },500)
    this.setState({
      slider_disabled: false,
      conf_slider: 0,
      conf: 2,
      max_conf: 2,
      scroll_display: { display: "none" },
      slider_visibility: { visibility: "visible" }
    });
    setTimeout(() => {
      this.setState({ prev_conf: 2 });
    }, 500);
  }
  stepBack() {
    const step = this.state.conf;
    if (step != 1) {
      this.setState({ conf: step - 1 });
      setTimeout(() => {
        this.setState({ prev_conf: step - 1 });
      }, 500);
    }
    this.markAcquired();
  }
  duringStep(new_step) {
    if (
      new_step <= this.state.furthers_conf_slider + 25 ||
      this.state.furthers_conf_slider == 75
    ) {
      this.setState({ conf_slider: new_step });
    }
    this.markAcquired();
  }
  toStep(new_step) {
    const val = this.state.conf_slider;
    var temp = new_step;
    if (
      new_step != 0 ||
      new_step != 25 ||
      new_step != 50 ||
      new_step != 75 ||
      new_step != 100
    ) {
      if (new_step < 12) {
        temp = 0;
      } else if (new_step >= 12 && new_step < 37) {
        temp = 25;
      } else if (new_step >= 37 && new_step < 62) {
        temp = 50;
      } else if (new_step >= 62 && new_step < 77) {
        temp = 75;
      }
      // else if (new_step>=77) {
      //   temp = 100;
      // }
      else {
        temp = 100;
      }
    }

    if (
      !(temp > val + 26) ||
      this.state.furthers_conf_slider >= temp ||
      this.state.furthers_conf_slider == 75
    ) {
      if (this.state.furthers_conf_slider <= temp) {
        this.setState({ furthers_conf_slider: temp });
      }
      this.setState({ conf_slider: temp });
      const step = parseInt(temp / 25) + 2;
      this.setState({ conf: step, max_conf: Math.max(step, this.state.max_conf) });
      this.markAcquired();
      setTimeout(() => {
        this.setState({ prev_conf: step });
      }, 500);
    }
  }

  markAcquired()
  {
    const el = document.querySelector('#progress-slider');

    let dots = el.querySelectorAll('.rc-slider-dot');

    Array.prototype.slice.call(dots).slice(0, Math.max(this.state.max_conf - 2, 0)).forEach((el) => {
      el.classList.add("rc-slider-dot-active");
    });

    let labels = el.querySelectorAll('.rc-slider-mark-text');

    Array.prototype.slice.call(labels).slice(0, Math.max(this.state.max_conf - 2, 0)).forEach((el) => {
      el.classList.add("rc-slider-mark-text-active");
    });
  }

  izolaceClick(e) {
    if (this.state.izolace != e.currentTarget.getAttribute("data-num")) {
      var num = e.currentTarget.getAttribute("data-num");
      this.setState({
        izolace: num,
        conf_slider_value: this.izolace_tloustka_recommended[num]
      });
    }
  }
  roznosClick(e) {
    if (this.state.roznos != e.currentTarget.getAttribute("data-num")) {
      var num = e.currentTarget.getAttribute("data-num");
      this.setState({
        roznos: num,
        conf_slider_value_roznos: this.roznos_tloustka_recommended[num]
      });
    }
  }
  finalClick(e) {
    if (this.state.final != e.currentTarget.getAttribute("data-num")) {
      var num = e.currentTarget.getAttribute("data-num");
      this.setState({
        final: num,
        conf_slider_value_final: this.final_tloustka_recommended[num]
      });
    }
  }

  izolaceOn(e) {
    this.setState({ izolace_hover: e.currentTarget.getAttribute("data-num") });
  }
  izolaceOut(e) {
    this.setState({ izolace_hover: this.state.izolace });
  }
  roznosOn(e) {
    this.setState({ roznos_hover: e.currentTarget.getAttribute("data-num") });
  }
  roznosOut(e) {
    this.setState({ roznos_hover: this.state.roznos });
  }
  finalOn(e) {
    this.setState({ final_hover: e.currentTarget.getAttribute("data-num") });
  }
  finalOut(e) {
    this.setState({ final_hover: this.state.final });
  }

  render() {
    // HEAD ENERGY FUSION BACKGROUNDS

    return (
      <div className="main-container">
        <div className="main-desktop">
          <div
            className="page-section separate"
            ref={this.section2}
            id="section_2"
          >
            <div className="section-content">
              <img
                className="conf-shadow"
                src={config.paths.images + "img_conf_shadow.png"}
                alt={i18n.t('home.headerButton')}
              />
              <img
                className="conf-odlesk"
                src={config.paths.images + "img_conf_odlesk.png"}
                alt={i18n.t('home.headerButton')}
              />
              <div className="section-conf-shadow"></div>
              <div className="section-left">
                {/* INTRO */}

                <div
                  className={
                    "conf-slide conf-1 " +
                    (this.state.conf == 1 ? "active" : "")
                  }
                >
                  <span className="section-route">
                    <b>Heated. &gt;</b> {i18n.t('home.configurator')}
                  </span>
                  <h2 className="section-heading" dangerouslySetInnerHTML={{ __html: i18n.t('home.header') }}></h2>
                  <div className="section-start-text">
                    {i18n.t('home.headerText1')}{" "}
                    <span className="conf-start-link" onClick={this.startConf}>
                      {i18n.t('home.headerText2')}
                    </span>
                    <br />{i18n.t('home.headerText3')},{" "}
                    <span className="conf-start-link" onClick={this.startConf}>
                      {i18n.t('home.headerText4')}
                    </span>
                    .
                  </div>

                  <div className="section-btn" onClick={this.startConf}>
                    {i18n.t('home.headerButton')}
                  </div>
                </div>

                {/* IZOLACE */}

                <div
                  className={
                    "conf-slide conf-2 " +
                    (this.state.conf == 2 && this.state.prev_conf == 2
                      ? "active"
                      : "")
                  }
                >
                  <h2 className="section-heading">
                    <b>{i18n.t('home.floorIsolation')}</b>
                  </h2>
                  <div className="section-prop-name">{i18n.t('home.materialSelection')}</div>
                  <div className="selection-cont">
                    <div className="option">
                      <div
                        onMouseEnter={this.izolaceOn}
                        onMouseLeave={this.izolaceOut}
                        className={
                          "option-wheel b_styr " +
                          (this.state.izolace == 0 ? "selected" : "")
                        }
                        onClick={this.izolaceClick}
                        data-num="0"
                      >
                        <div className="option-image" />
                        <div className="option-inside">
                          <div className="option-i">i</div>
                        </div>
                      </div>
                      <div className="option-title">{i18n.t('home.whitePolystyrene')}</div>
                    </div>
                    <div className="option">
                      <div
                        onMouseEnter={this.izolaceOn}
                        onMouseLeave={this.izolaceOut}
                        className={
                          "option-wheel pir " +
                          (this.state.izolace == 1 ? "selected" : "")
                        }
                        onClick={this.izolaceClick}
                        data-num="1"
                      >
                        <div className="option-image" />
                        <div className="option-inside">
                          <div className="option-i">i</div>
                        </div>
                      </div>
                      <div className="option-title">{i18n.t('home.pir')}</div>
                    </div>
                    <div className="option">
                      <div
                        onMouseEnter={this.izolaceOn}
                        onMouseLeave={this.izolaceOut}
                        className={
                          "option-wheel vata " +
                          (this.state.izolace == 2 ? "selected" : "")
                        }
                        onClick={this.izolaceClick}
                        data-num="2"
                      >
                        <div className="option-image" />
                        <div className="option-inside">
                          <div className="option-i">i</div>
                        </div>
                      </div>
                      <div className="option-title">{i18n.t('home.wool')}</div>
                    </div>
                    <div className="option">
                      <div
                        onMouseEnter={this.izolaceOn}
                        onMouseLeave={this.izolaceOut}
                        className={
                          "option-wheel s_styr " +
                          (this.state.izolace == 3 ? "selected" : "")
                        }
                        onClick={this.izolaceClick}
                        data-num="3"
                      >
                        <div className="option-image" />
                        <div className="option-inside">
                          <div className="option-i">i</div>
                        </div>
                      </div>
                      <div className="option-title">{i18n.t('home.grayPolystyrene')}</div>
                    </div>
                  </div>
                  <div
                    className={
                      "slider-tloustka " +
                      (this.izolace_tloustka[this.state.izolace]
                        ? "active"
                        : "")
                    }
                  >
                    <div className="tloustka-legend-cont">
                      <span className="tloustka-form-label">{i18n.t('home.thickness')}</span>
                      <span className="tloustka-form-area">
                        <b>{this.state.conf_slider_value}</b> mm
                      </span>
                    </div>
                    <div className="tloustka-slider-cont">
                      <Slider
                        id="tloustka_slider"
                        className="tloustka-slider-range"
                        defaultValue={this.state.conf_slider_value}
                        value={this.state.conf_slider_value}
                        min={
                          this.izolace_tloustka_values[this.state.izolace][0]
                        }
                        max={
                          this.izolace_tloustka_values[this.state.izolace][1]
                        }
                        step={
                          this.izolace_tloustka_values[this.state.izolace][2]
                        }
                        onChange={this.confSliderHandle}
                        trackStyle={{
                          backgroundImage:
                            "url('/assets/images/icons/ic_mobile_slider_bg_active.png')",
                          height: 24
                        }}
                        handleStyle={{
                          borderColor: "blue",
                          height: 44,
                          width: 26,
                          marginLeft: -9,
                          marginTop: -9,
                          backgroundImage:
                            "url('/assets/images/icons/ic_handle_orange.png')"
                        }}
                        railStyle={{
                          backgroundImage:
                            "url('/assets/images/icons/ic_mobile_slider_bg.png')",
                          height: 24
                        }}
                      />
                    </div>
                  </div>

                  {this.izolace_desc[this.state.izolace_hover]}
                </div>

                {/* ROZNÁŠECÍ VRSTVA */}

                <div
                  className={
                    "conf-slide conf-3 " +
                    (this.state.conf == 3 && this.state.prev_conf == 3
                      ? "active"
                      : "")
                  }
                >
                  <h2 className="section-heading">
                    <b>{i18n.t('general.spreadingLayer')}</b>
                  </h2>
                  <div className="section-prop-name">{i18n.t('home.materialSelection')}</div>
                  <div className="selection-cont">
                    <div className="option">
                      <div
                        onMouseEnter={this.roznosOn}
                        onMouseLeave={this.roznosOut}
                        className={
                          "option-wheel beton " +
                          (this.state.roznos == 0 ? "selected" : "")
                        }
                        onClick={this.roznosClick}
                        data-num="0"
                      >
                        <div className="option-image" />
                        <div className="option-inside">
                          <div className="option-i">i</div>
                        </div>
                      </div>
                      <div className="option-title" dangerouslySetInnerHTML={{ __html: i18n.t('home.cementScreed') }}></div>
                    </div>
                    <div className="option">
                      <div
                        onMouseEnter={this.roznosOn}
                        onMouseLeave={this.roznosOut}
                        className={
                          "option-wheel anhydrit " +
                          (this.state.roznos == 1 ? "selected" : "")
                        }
                        onClick={this.roznosClick}
                        data-num="1"
                      >
                        <div className="option-image" />
                        <div className="option-inside">
                          <div className="option-i">i</div>
                        </div>
                      </div>
                      <div className="option-title">{i18n.t('home.anhydrit')}</div>
                    </div>
                    <div className="option">
                      <div
                        onMouseEnter={this.roznosOn}
                        onMouseLeave={this.roznosOut}
                        className={
                          "option-wheel deska " +
                          (this.state.roznos == 2 ? "selected" : "")
                        }
                        onClick={this.roznosClick}
                        data-num="2"
                      >
                        <div className="option-image" />
                        <div className="option-inside">
                          <div className="option-i">i</div>
                        </div>
                      </div>
                      <div className="option-title" dangerouslySetInnerHTML={{ __html: i18n.t('home.gypsumFiberBoards') }}></div>
                    </div>
                  </div>
                  <div
                    className={
                      "slider-tloustka " +
                      (this.roznos_tloustka[this.state.roznos] ? "active" : "")
                    }
                  >
                    <div className="tloustka-legend-cont">
                      <span className="tloustka-form-label">{i18n.t('home.thickness')}</span>
                      <span className="tloustka-form-area">
                        <b>{this.state.conf_slider_value_roznos}</b> mm
                      </span>
                    </div>
                    <div className="tloustka-slider-cont">
                      <Slider
                        id="tloustka_slider"
                        className="tloustka-slider-range"
                        defaultValue={this.state.conf_slider_value_roznos}
                        value={this.state.conf_slider_value_roznos}
                        min={this.roznos_tloustka_values[this.state.roznos][0]}
                        max={this.roznos_tloustka_values[this.state.roznos][1]}
                        step={this.roznos_tloustka_values[this.state.roznos][2]}
                        onChange={this.confSliderHandleRoznos}
                        trackStyle={{
                          backgroundImage:
                            "url('/assets/images/icons/ic_mobile_slider_bg_active.png')",
                          height: 24
                        }}
                        handleStyle={{
                          borderColor: "blue",
                          height: 44,
                          width: 26,
                          marginLeft: -9,
                          marginTop: -9,
                          backgroundImage:
                            "url('/assets/images/icons/ic_handle_orange.png')"
                        }}
                        railStyle={{
                          backgroundImage:
                            "url('/assets/images/icons/ic_mobile_slider_bg.png')",
                          height: 24
                        }}
                      />
                    </div>
                  </div>

                  {this.roznos_desc[this.state.roznos_hover]}
                </div>

                {/* FINÁLNÍ VRSTVA */}

                <div
                  className={
                    "conf-slide conf-4 " +
                    (this.state.conf == 4 && this.state.prev_conf == 4
                      ? "active"
                      : "")
                  }
                >
                  <h2 className="section-heading">
                    <b>{i18n.t('general.finalLayer')}</b>
                  </h2>
                  <div className="section-prop-name">{i18n.t('home.materialSelection')}</div>
                  <div className="selection-cont">
                    <div className="option">
                      <div
                        onMouseEnter={this.finalOn}
                        onMouseLeave={this.finalOut}
                        className={
                          "option-wheel beton " +
                          (this.state.final == 0 ? "selected" : "")
                        }
                        onClick={this.finalClick}
                        data-num="0"
                      >
                        <div className="option-image" />
                        <div className="option-inside">
                          <div className="option-i">i</div>
                        </div>
                      </div>
                      <div className="option-title" dangerouslySetInnerHTML={{ __html: i18n.t('home.polishedConcrete') }}></div>
                    </div>
                    <div className="option">
                      <div
                        onMouseEnter={this.finalOn}
                        onMouseLeave={this.finalOut}
                        className={
                          "option-wheel laminat " +
                          (this.state.final == 1 ? "selected" : "")
                        }
                        onClick={this.finalClick}
                        data-num="1"
                      >
                        <div className="option-image" />
                        <div className="option-inside">
                          <div className="option-i">i</div>
                        </div>
                      </div>
                      <div className="option-title" dangerouslySetInnerHTML={{ __html: i18n.t('home.laminatedFloor') }}></div>
                    </div>
                    <div className="option">
                      <div
                        onMouseEnter={this.finalOn}
                        onMouseLeave={this.finalOut}
                        className={
                          "option-wheel lino " +
                          (this.state.final == 2 ? "selected" : "")
                        }
                        onClick={this.finalClick}
                        data-num="2"
                      >
                        <div className="option-image" />
                        <div className="option-inside">
                          <div className="option-i">i</div>
                        </div>
                      </div>
                      <div className="option-title">{i18n.t('home.pvc')}</div>
                    </div>
                    <div className="option">
                      <div
                        onMouseEnter={this.finalOn}
                        onMouseLeave={this.finalOut}
                        className={
                          "option-wheel dlazba " +
                          (this.state.final == 3 ? "selected" : "")
                        }
                        onClick={this.finalClick}
                        data-num="3"
                      >
                        <div className="option-image" />
                        <div className="option-inside">
                          <div className="option-i">i</div>
                        </div>
                      </div>
                      <div className="option-title">{i18n.t('home.pavement')}</div>
                    </div>
                    <div className="option">
                      <div
                        onMouseEnter={this.finalOn}
                        onMouseLeave={this.finalOut}
                        className={
                          "option-wheel drevo " +
                          (this.state.final == 4 ? "selected" : "")
                        }
                        onClick={this.finalClick}
                        data-num="4"
                      >
                        <div className="option-image" />
                        <div className="option-inside">
                          <div className="option-i">i</div>
                        </div>
                      </div>
                      <div className="option-title">{i18n.t('home.solidWood')}</div>
                    </div>
                  </div>
                  <div
                    className={
                      "slider-tloustka " +
                      (this.final_tloustka[this.state.final] ? "active" : "")
                    }
                  >
                    <div className="tloustka-legend-cont">
                      <span className="tloustka-form-label">{i18n.t('home.thickness')}</span>
                      <span className="tloustka-form-area">
                        <b>{this.state.conf_slider_value_final}</b> mm
                      </span>
                    </div>
                    <div className="tloustka-slider-cont">
                      <Slider
                        id="tloustka_slider"
                        className="tloustka-slider-range"
                        defaultValue={this.state.conf_slider_value_final}
                        value={this.state.conf_slider_value_final}
                        min={this.final_tloustka_values[this.state.final][0]}
                        max={this.final_tloustka_values[this.state.final][1]}
                        step={this.final_tloustka_values[this.state.final][2]}
                        onChange={this.confSliderHandleFinal}
                        trackStyle={{
                          backgroundImage:
                            "url('/assets/images/icons/ic_mobile_slider_bg_active.png')",
                          height: 24
                        }}
                        handleStyle={{
                          borderColor: "blue",
                          height: 44,
                          width: 26,
                          marginLeft: -9,
                          marginTop: -9,
                          backgroundImage:
                            "url('/assets/images/icons/ic_handle_orange.png')"
                        }}
                        railStyle={{
                          backgroundImage:
                            "url('/assets/images/icons/ic_mobile_slider_bg.png')",
                          height: 24
                        }}
                      />
                    </div>
                  </div>
                  {/* <div className="section-text">
                    <b>{i18n.t('general.recommendThick')} </b>
                    <span className="orange">30 mm</span>.
                  </div> */}

                  {this.final_desc[this.state.final_hover]}
                </div>

                {/* SHRNUTÍ */}

                <div
                  className={
                    "conf-slide conf-5 " +
                    (this.state.conf == 5 && this.state.prev_conf == 5
                      ? "active"
                      : "")
                  }
                >
                  <h2 className="section-heading">
                    <b>{i18n.t('general.conclusion')}</b>
                  </h2>

                  <div className="shrnuti-cont">
                    <div className="shrnuti-row">
                      <div className="shrnuti-wheel">
                        <div
                          className="shrnuti-image"
                          style={{
                            backgroundImage:
                              "url('/assets/images/img/img_conf_l2_" +
                              this.state.izolace +
                              "_icon.png')"
                          }}
                        />
                      </div>
                      <div className="shrnuti-slider-cont">
                        <div className="shrnuti-slider-text">
                          <div className="shrnuti-slider-left">
                            <span className="shrnuti-type">
                              {i18n.t('home.isolationFloorLayer')}
                            </span>
                            <br />
                            <span className="shrnuti-type-name">
                              {this.izolace_names[this.state.izolace]}
                            </span>
                          </div>
                          <div className="shrnuti-slider-right">
                            <b>{this.state.conf_slider_value}</b> mm
                          </div>
                        </div>
                        <Slider
                          id="shrnuti-izolace"
                          className="shrnuti-slider-range"
                          defaultValue={this.state.conf_slider_value}
                          value={this.state.conf_slider_value}
                          min={
                            this.izolace_tloustka_values[this.state.izolace][0]
                          }
                          max={
                            this.izolace_tloustka_values[this.state.izolace][1]
                          }
                          step={
                            this.izolace_tloustka_values[this.state.izolace][2]
                          }
                          disabled={true}
                          onChange={this.confSliderHandleRoznos}
                          trackStyle={{
                            backgroundImage:
                              "url('/assets/images/icons/ic_mobile_slider_bg_active.png')",
                            height: 24
                          }}
                          handleStyle={{
                            borderColor: "blue",
                            height: 44,
                            width: 26,
                            marginLeft: -9,
                            marginTop: -9,
                            opacity: 0,
                            backgroundImage:
                              "url('/assets/images/icons/ic_handle_orange.png')"
                          }}
                          railStyle={{
                            backgroundImage:
                              "url('/assets/images/icons/ic_mobile_slider_bg.png')",
                            height: 24
                          }}
                        />
                      </div>
                      <div
                        className="shrnuti-slider-upravit"
                        onClick={() => this.toStep(0)}
                      >
                        {i18n.t('general.edit')}
                      </div>
                    </div>

                    <div className="shrnuti-row">
                      <div className="shrnuti-wheel">
                        <div
                          className="shrnuti-image"
                          style={{
                            backgroundImage:
                              "url('/assets/images/img/img_conf_l3_" +
                              this.state.roznos +
                              "_icon.png')"
                          }}
                        />
                      </div>
                      <div className="shrnuti-slider-cont">
                        <div className="shrnuti-slider-text">
                          <div className="shrnuti-slider-left">
                            <span className="shrnuti-type">
                              {i18n.t('home.floorSpreadingLayer')}
                            </span>
                            <br />
                            <span className="shrnuti-type-name">
                              {this.roznos_names[this.state.roznos]}
                            </span>
                          </div>
                          <div className="shrnuti-slider-right">
                            <b>{this.state.conf_slider_value_roznos}</b> mm
                          </div>
                        </div>
                        <Slider
                          id="shrnuti-izolace"
                          className="shrnuti-slider-range"
                          defaultValue={this.state.conf_slider_value_roznos}
                          value={this.state.conf_slider_value_roznos}
                          min={
                            this.roznos_tloustka_values[this.state.roznos][0]
                          }
                          max={
                            this.roznos_tloustka_values[this.state.roznos][1]
                          }
                          step={
                            this.roznos_tloustka_values[this.state.roznos][2]
                          }
                          disabled={true}
                          onChange={this.confSliderHandleRoznos}
                          trackStyle={{
                            backgroundImage:
                              "url('/assets/images/icons/ic_mobile_slider_bg_active.png')",
                            height: 24
                          }}
                          handleStyle={{
                            borderColor: "blue",
                            height: 44,
                            width: 26,
                            marginLeft: -9,
                            marginTop: -9,
                            opacity: 0,
                            backgroundImage:
                              "url('/assets/images/icons/ic_handle_orange.png')"
                          }}
                          railStyle={{
                            backgroundImage:
                              "url('/assets/images/icons/ic_mobile_slider_bg.png')",
                            height: 24
                          }}
                        />
                      </div>
                      <div
                        className="shrnuti-slider-upravit"
                        onClick={() => this.toStep(25)}
                      >
                        {i18n.t('general.edit')}
                      </div>
                    </div>

                    <div className="shrnuti-row">
                      <div className="shrnuti-wheel">
                        <div
                          className="shrnuti-image"
                          style={{
                            backgroundImage:
                              "url('/assets/images/img/img_conf_l4_" +
                              this.state.final +
                              "_icon.png')"
                          }}
                        />
                      </div>
                      <div className="shrnuti-slider-cont">
                        <div className="shrnuti-slider-text">
                          <div className="shrnuti-slider-left">
                            <span className="shrnuti-type">
                              {i18n.t('home.floorFinalLayer')}
                            </span>
                            <br />
                            <span className="shrnuti-type-name">
                              {this.final_names[this.state.final]}
                            </span>
                          </div>
                          <div className="shrnuti-slider-right">
                            <b>{this.state.conf_slider_value_final}</b> mm
                          </div>
                        </div>
                        <Slider
                          id="shrnuti-izolace"
                          className="shrnuti-slider-range"
                          defaultValue={this.state.conf_slider_value_final}
                          value={this.state.conf_slider_value_final}
                          min={this.final_tloustka_values[this.state.final][0]}
                          max={this.final_tloustka_values[this.state.final][1]}
                          step={this.final_tloustka_values[this.state.final][2]}
                          disabled={true}
                          onChange={this.confSliderHandleRoznos}
                          trackStyle={{
                            backgroundImage:
                              "url('/assets/images/icons/ic_mobile_slider_bg_active.png')",
                            height: 24
                          }}
                          handleStyle={{
                            borderColor: "blue",
                            height: 44,
                            width: 26,
                            marginLeft: -9,
                            marginTop: -9,
                            opacity: 0,
                            backgroundImage:
                              "url('/assets/images/icons/ic_handle_orange.png')"
                          }}
                          railStyle={{
                            backgroundImage:
                              "url('/assets/images/icons/ic_mobile_slider_bg.png')",
                            height: 24
                          }}
                        />
                      </div>
                      <div
                        className="shrnuti-slider-upravit"
                        onClick={() => this.toStep(50)}
                        // style={{ visibility: "hidden" }}
                      >
                        {i18n.t('general.edit')}
                      </div>
                    </div>
                  </div>
                </div>

                {/* VYHODNOCENÍ */}

                <div
                  className={
                    "conf-slide conf-6 " +
                    (this.state.conf == 6 && this.state.prev_conf == 6
                      ? "active"
                      : "")
                  }
                >
                  <h2
                    className="section-heading"
                    style={this.state.sent ? { fontSize: "32px" } : {}}
                  >
                    {this.state.error_message}
                  </h2>
                  <div className="form-container">
                    <form
                      action=""
                      className={
                        "form-nabidka " +
                        (this.state.submitted ? "submitted" : "") +
                        " " +
                        this.state.valid_email + " " + this.state.valid_phone
                      }
                      onSubmit={this.handleSubmit}
                    >
                      <div className="form-row form-row--konfigurator--first">
                        <div className="nabidka-slider">
                          <div className="nabidka-legend-cont">
                            <span className="nabidka-form-label">
                               {i18n.t('home.totalAreaBuilding')} °
                            </span>
                            <span className="nabidka-form-area">
                              <b>{this.state.area_value}</b> m<sup>2</sup>
                            </span>
                          </div>
                          <div className="nabidka-slider-cont">
                            <Slider
                              id="slider_7"
                              className="nabidka-slider-range"
                              defaultValue={this.state.area_value}
                              min={1}
                              max={500}
                              step={1}
                              onChange={this.areaHandle}
                              trackStyle={{
                                backgroundImage:
                                  "url('/assets/images/icons/ic_mobile_slider_bg_active.png')",
                                height: 24
                              }}
                              handleStyle={{
                                borderColor: "blue",
                                height: 44,
                                width: 26,
                                marginLeft: -9,
                                marginTop: -9,
                                backgroundImage:
                                  "url('/assets/images/icons/ic_handle_orange.png')"
                              }}
                              railStyle={{
                                backgroundImage:
                                  "url('/assets/images/icons/ic_mobile_slider_bg.png')",
                                height: 24
                              }}
                            />
                          </div>
                        </div>
                        <div className="form-field one-col">
                          <select
                            name="phase"
                            className={this.state.select_empty}
                            id="form_faze_nabidka"
                            onChange={this.handleChange}
                            value={this.state.phase}
                          >
                            <option value="" defaultValue>
                              {i18n.t('general.projectPhase')}
                            </option>
                            <option value="Nemám projekt">{i18n.t('general.noProject')}</option>
                            <option value="Mám projekt">{i18n.t('general.haveProject')}</option>
                            <option value="Mám projekt, ale nemám ho u sebe">
                              {i18n.t('general.haveProjectNoDocumentation')}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div className="form-row three-col form-row--konfigurator--second">
                        <div className="form-field">
                          <input
                            type="text"
                            name="name"
                            id="form_name_nabidka"
                            required
                            onChange={this.handleChange}
                            value={this.state.name}
                          />
                          <label htmlFor="form_name_nabidka">
                            {i18n.t('general.fullname')} °
                          </label>
                          <i className="form-field-check"></i>
                          <span
                            className="required"
                            style={
                              this.state.name != "" ? { display: "none" } : {}
                            }
                          >
                            {i18n.t('general.requiredField')}
                          </span>
                        </div>
                        <div className="form-field">
                          {i18n.language === 'cs' ? <>
                          <input
                            type="text"
                            name="city"
                            id="form_city_nabidka"
                            onChange={this.handleChange}
                            value={this.state.city}
                            pattern="(\+\d{3})?\d{9}"
                          />
                          <label htmlFor="form_city_nabidka">{i18n.t('general.phone')}</label>
                          <i className="form-field-check"></i>
                          {this.state.city != "" &&
                          !/(\+\d{3})?\d{9}/.test(this.state.city) ? (
                            <span className="required">
                              {i18n.t('general.wrongFormate')}
                            </span>
                          ) : (
                            ""
                          )}
                          </> : <>
                          <input
                            type="text"
                            name="city"
                            id="form_city_nabidka"
                            onChange={this.handleChange}
                            value={this.state.city}
                            pattern="(\+\d{3})?\d{10}"
                          />
                          <label htmlFor="form_city_nabidka">{i18n.t('general.phone')}</label>
                          <i className="form-field-check"></i>
                          {this.state.city != "" &&
                          !/(\+\d{3})?\d{10}/.test(this.state.city) ? (
                            <span className="required">
                              {i18n.t('general.wrongFormate')}
                            </span>
                          ) : (
                            ""
                          )}
                          </>}

                          {/*
                          <span
                            className="required"
                            style={
                              this.state.city != "" ? { display: "none" } : {}
                            }
                          >
                            {i18n.t('general.requiredField')}
                          </span>
                          */}
                        </div>
                        <div className="form-field">
                          <input
                            type="email"
                            name="email"
                            id="form_email_nabidka"
                            className={
                              "email " +
                              (this.state.email != "" ? "not-empty" : "")
                            }
                            required
                            onChange={this.handleChange}
                            value={this.state.email}
                          />
                          <label htmlFor="form_email_nabidka">email °</label>
                          <i className="form-field-check"></i>
                          <span
                            className="required"
                            style={
                              this.state.email != "" ? { display: "none" } : {}
                            }
                          >
                            {i18n.t('general.requiredField')}
                          </span>
                          {this.state.email != "" &&
                          !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email) ? (
                            <span className="required">
                              {i18n.t('general.wrongFormate')}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="form-row">
                        <div
                          className={
                            "form-field files " +
                            (this.state.missing_files ? "missing" : "")
                          }
                          style={this.state.file_input}
                        >
                          <FormItemUpload
                            {...{
                              text:
                                i18n.t('general.uploadNote'),
                              dataFormItem: "sources",
                              value: this.state.formItems.podklady.value,
                              error: this.state.formItems.podklady.error,
                              actions: {
                                changeFormUploadItem: this.changeFormUploadItem
                              }
                            }}
                          />
                        </div>
                        <div className="form-field">
                          <button type="submit" onClick={this.handleSubmit}>
                            {i18n.t('general.toSend')}
                          </button>
                        </div>
                      </div>

                      {/* <div className="form-bottom">
                        <div className="form-field">
                          <button type="submit" onClick={this.handleSubmit}>
                            {i18n.t('general.toSend')}
                          </button>
                        </div>
                      </div> */}
                    </form>
                  </div>
                </div>

                {/* ODESLÁNO */}

                <div
                  className={
                    "conf-slide conf-7 " +
                    (this.state.conf == 7 && this.state.prev_conf == 7
                      ? "active"
                      : "")
                  }
                >
                  <h2 className="section-heading">
                    {this.state.submit_title == i18n.t('general.sended') ? (
                      <img
                        className="odeslano-icon"
                        src={config.paths.icons + "ic_checked_orange.png"}
                        alt={i18n.t('general.sended')}
                      />
                    ) : (
                      ""
                    )}

                    <b>{this.state.submit_title}</b>
                  </h2>
                  <div className="odeslano-text">
                    {this.state.form_response}
                  </div>
                </div>
              </div>

              <div
                className={
                  "section-config-container " +
                  (this.state.conf > 1 ? "active" : "") +
                  " " +
                  (this.state.conf == 2 && this.state.prev_conf == 2
                    ? "sec2"
                    : "") +
                  " " +
                  (this.state.conf == 3 ? "sec3" : "") +
                  " " +
                  (this.state.conf == 4 ? "sec4" : "") +
                  " " +
                  (this.state.conf == 5 ? "sec5" : "") +
                  " " +
                  (this.state.conf == 6 ? "sec6" : "") +
                  " " +
                  (this.state.conf == 7 ? "sec7" : "")
                }
              >
                <img
                  className="layer section-config-vrsta-0"
                  src={config.paths.images + "img_conf_l1.png"}
                  alt="Základní vrstva"
                />
                <img
                  className="layer section-config-vrsta-1"
                  src={
                    config.paths.images +
                    "img_conf_l2_" +
                    this.state.izolace +
                    ".png"
                  }
                  alt="Izolace"
                />
                <img
                  className="layer section-config-vrsta-2"
                  src={config.paths.images + "img_conf_l3.png"}
                  alt="Heatflow"
                />
                <img
                  className="layer section-config-vrsta-3"
                  src={
                    config.paths.images +
                    "img_conf_l3_" +
                    this.state.roznos +
                    ".png"
                  }
                  alt="Roznášecí vrstva"
                />
                <img
                  className="layer section-config-vrsta-4"
                  src={
                    config.paths.images +
                    "img_conf_l4_" +
                    this.state.final +
                    ".png"
                  }
                  alt="Finální vrstva"
                />
              </div>

              <div
                id={"progress-slider"}
                className={
                  "section-slider-cont " +
                  (this.state.slider_disabled ? "" : "active")
                }
                style={this.state.slider_visibility}
              >
                <div className={"conf-slider-cont " + this.state.scaleThumb}>
                  <Slider
                    handleStyle={{
                      borderColor: "transparent",
                      height: 44,
                      width: 44,
                      marginLeft: -12,
                      marginTop: -10,
                      backgroundImage:
                        "url('/assets/images/icons/circle-handler-primary.png')"
                    }}
                    min={0}
                    marks={this.marks}
                    value={this.state.conf_slider}
                    max={100}
                    step={1}
                    onChange={this.duringStep}
                    onAfterChange={this.toStep}
                    defaultValue={0}
                  />
                </div>
                <div className="conf-height-cont">
                  <div className="height-number">
                    {Math.round(
                      (this.state.conf_slider_value +
                        this.state.conf_slider_value_roznos +
                        this.state.conf_slider_value_final) /
                        10
                    )}
                  </div>
                  cm
                  <br />
                   {i18n.t('home.heightOfLayers')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Konfigurator;
