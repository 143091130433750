import * as React from "react";
import config from "../../../../config/config.json";
import { Menu } from "../components/Menu";
import { Link } from "react-router-dom";
import Slider, { createSliderWithTooltip } from "rc-slider";
import "../../../../node_modules/rc-slider/assets/index.css";
import { postOffers } from "../api.js";
import { FormItemUpload } from "../components/FormItemUpload.jsx";
import { localConsoleLog } from "../functions.js";
import smoothScrollIntoView from "smooth-scroll-into-view-if-needed";
import { getPageSEO } from "../functions";

import i18n from '../i18n';
import Loader from "../components/Loader";

class Sluzba extends React.Component {
  constructor(props) {
    super(props);

    this.pageSEO = getPageSEO();

    document.title = this.pageSEO.title;
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", this.pageSEO.keywords);
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", this.pageSEO.description);

    document.querySelector('meta[name=\"title\"]').setAttribute('content', this.pageSEO.metaTitle);

    this.state = {
      slider_value: 120,
      name: "",
      city: "",
      tel: "",
      email: "",
      note: "",
      phase: "",
      sources: "",
      fve: false,
      inteligent_control: false,
      floor_structure: false,
      clicked: false,
      submitted: false,
      valid_email: "",
      file_input: { display: "none" },
      select_empty: "",
      formItems: {
        podklady: {
          value: "",
          error: ""
        }
      },
      sent: false,
      error_message: "",

      missing_files: false,
      intro_loaded: false,

      loxone_clanky: false,
      loxone_clanky_hiding: false,

      mcore_clanky: false,
      mcore_clanky_hiding: false,

      why_heatflow_clanky: false,
      why_heatflow_clanky_hiding: false,

      montaz_clanky: false,
      montaz_clanky_hiding: false,

      priprava_clanky: false,
      priprava_clanky_hiding: false,

      navrh_clanky: false,
      navrh_clanky_hiding: false,

      poradce_clanky: false,
      poradce_clanky_hiding: false,

      mapa_ended: false,
      projekt02_ended: false,
      projekt03_ended: false,
      projekt04_ended: false,

      mapa_played: false,
      projekt02_played: false,
      projekt03_played: false,
      projekt04_played: false,
      dodavka_animation_played: false,

      dodavka_animation_state: 0,

      loaded: 0,
      loader_loaded: false,
      showLoader: true,
      loadingStatus: 0,

      gdpr_modal: false
    };
    this.mapa = React.createRef();
    this.projekt02 = React.createRef();
    this.projekt03 = React.createRef();
    this.projekt04 = React.createRef();
    this.dodavka_section = React.createRef();

    this.formular = React.createRef();

    this.sliderHandle = this.sliderHandle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.posted = this.posted.bind(this);
    this.onScroll = this.onScroll.bind(this);
    this.playDodavkaAnimation = this.playDodavkaAnimation.bind(this);
    this.checkNumber = this.checkNumber.bind(this);
    this.expandSection = this.expandSection.bind(this);
    this.showAgain = this.showAgain.bind(this);
    this.changeFormUploadItem = this.changeFormUploadItem.bind(this);

    this.playProjekt02 = this.playProjekt02.bind(this);
    this.playProjekt03 = this.playProjekt03.bind(this);
    this.playProjekt04 = this.playProjekt04.bind(this);
    this.playMapa = this.playMapa.bind(this);

    this.repeatVideo = this.repeatVideo.bind(this);
    this.loaderLoaded = this.loaderLoaded.bind(this);
    this.resourceLoaded = this.resourceLoaded.bind(this);

    this.openPrivacy = this.openPrivacy.bind(this);
    this.modalClicked = this.modalClicked.bind(this);

    this.scrollToMapa = this.scrollToMapa.bind(this);
    this.preventEnter = this.preventEnter.bind(this);
    this.scrollToFormular = this.scrollToFormular.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll, { passive: true });
    window.addEventListener("keydown", this.preventEnter);
    document.body.style.overflowY = "hidden";

    // var load = 0;
    // this.loader = setInterval(() => {
    //   this.setState({
    //     loadingStatus: Math.round((load / 13) * 100)
    //   });
    //   load = load + 1;
    //   if (load == 13) {
    //     this.setState({ showLoader: false });
    //     setTimeout(() => {
    //       document.body.style.overflowY = "auto";
    //       this.setState({ intro_loaded: true });
    //     }, 500);
    //   }
    // }, 150);

    // setTimeout(() => {
    //   clearInterval(this.loader);
    // }, 2500);

    var mapa = document.getElementById("mapa");
    mapa.currentTime = 2;

    var vide02 = document.getElementById("projekt02");
    vide02.currentTime = 2;

    var vide03 = document.getElementById("projekt03");
    vide03.currentTime = 11;

    var vide04 = document.getElementById("projekt04");
    vide04.currentTime = 1;

    this.expandSection("why_heatflow");
    this.expandSection("montaz");
    this.expandSection("priprava");
    this.expandSection("navrh");
    this.expandSection("poradce");
    this.expandSection("mapa");

    let modals = Array.prototype.slice.call(document.querySelectorAll('.gdpr-modal'));

    modals.forEach(element => {
      document.body.appendChild(element);
    });

    // setTimeout(() => {
    //   this.setState({ intro_loaded: true });
    // }, 1000);
  }

  componentWillUnmount() {
    document.body.style.overflowY = "auto";
    window.removeEventListener("scroll", this.onScroll);
  }
  preventEnter(e) {
    if (e.keyCode == 13) {
      e.preventDefault();
      return false;
    }
  }
  modalClicked(e) {
    if (e.target.dataset.name == "modal") {
      this.openPrivacy();
    }
  }
  openPrivacy() {
    if (this.state.gdpr_modal) {
      document.body.style.overflowY = "scroll";
      this.setState({
        gdpr_modal: false
      });
    } else {
      document.body.style.overflowY = "hidden";
      this.setState({
        gdpr_modal: true
      });
    }
  }
  resourceLoaded() {
    var temp = this.state.loaded;

    this.setState({
      loaded: temp + 1,
      loadingStatus: Math.round(((temp + 1) / 4) * 100)
    });
    if (temp == 3) {
      this.setState({ showLoader: false });
      document.body.style.overflowY = "scroll";

      setTimeout(() => {
        this.setState({ intro_loaded: true });
      }, 500);
    }
  }
  scrollToMapa() {
    smoothScrollIntoView(this.mapa.current, {
      behavior: "smooth",
      block: "start",
      duration: 1000
    });
  }

  scrollToFormular() {
    smoothScrollIntoView(this.formular.current, {
      behavior: "smooth",
      block: "start",
      duration: 1000
    });
  }

  onScroll(e) {
    var vh = window.innerHeight;
    var dodavka_pos = this.dodavka_section.current.getBoundingClientRect().top;

    var mapa_pos = this.mapa.current.getBoundingClientRect().top;
    var projekt02_pos = this.projekt02.current.getBoundingClientRect().top;
    var projekt03_pos = this.projekt03.current.getBoundingClientRect().top;
    var projekt04_pos = this.projekt04.current.getBoundingClientRect().top;

    if (dodavka_pos < vh * 0.6 && !this.state.dodavka_animation_played) {
      this.setState({ dodavka_animation_played: true });
      this.playDodavkaAnimation();
    }

    if (mapa_pos < vh * 0.6 && !this.state.mapa_played) {
      this.setState({ mapa_played: true });
      this.playMapa();
    }

    if (projekt02_pos < vh * 0.6 && !this.state.projekt02_played) {
      this.setState({ projekt02_played: true });
      this.playProjekt02(2, 9);
    }

    if (projekt03_pos < vh * 0.6 && !this.state.projekt03_played) {
      this.setState({ projekt03_played: true });
      this.playProjekt03(11, 18);
    }

    if (projekt04_pos < vh * 0.6 && !this.state.projekt04_played) {
      this.setState({ projekt04_played: true });
      this.playProjekt04(1, 6);
    }
  }

  playDodavkaAnimation() {
    this.refs.dodavka.play();
    setTimeout(() => {
      var state = this.state.dodavka_animation_state;
      this.setState({ dodavka_animation_state: state + 1 });
    }, 4000);
    setTimeout(() => {
      var state = this.state.dodavka_animation_state;
      this.setState({ dodavka_animation_state: state + 1 });
    }, 7000);
    setTimeout(() => {
      var state = this.state.dodavka_animation_state;
      this.setState({ dodavka_animation_state: state + 1 });
    }, 10000);
  }

  playProjekt02(startTime, endTime) {
    var videoplayer = document.getElementById("projekt02"); //get your videoplayer

    videoplayer.currentTime = startTime; //not sure if player seeks to seconds or milliseconds
    videoplayer.play();

    //call function to stop player after given intervall
    var stopVideoAfter = (endTime - startTime) * 1000; //* 1000, because Timer is in ms
    setTimeout(() => {
      videoplayer.pause();
      this.setState({ projekt02_ended: true });
    }, stopVideoAfter);
  }

  playProjekt03(startTime, endTime) {
    var videoplayer = document.getElementById("projekt03"); //get your videoplayer

    videoplayer.currentTime = startTime; //not sure if player seeks to seconds or milliseconds
    videoplayer.play();

    //call function to stop player after given intervall
    var stopVideoAfter = (endTime - startTime) * 1000; //* 1000, because Timer is in ms
    setTimeout(() => {
      videoplayer.pause();
      this.setState({ projekt03_ended: true });
    }, stopVideoAfter);
  }

  playProjekt04(startTime, endTime) {
    var videoplayer = document.getElementById("projekt04"); //get your videoplayer

    videoplayer.currentTime = startTime; //not sure if player seeks to seconds or milliseconds
    videoplayer.play();

    //call function to stop player after given intervall
    var stopVideoAfter = (endTime - startTime) * 1000; //* 1000, because Timer is in ms
    setTimeout(() => {
      videoplayer.pause();
      this.setState({ projekt04_ended: true });
    }, stopVideoAfter);
  }
  playMapa() {
    var videoplayer = document.getElementById("mapa"); //get your videoplayer

    videoplayer.currentTime = 2; //not sure if player seeks to seconds or milliseconds
    videoplayer.play();

    //call function to stop player after given intervall

    setTimeout(() => {
      videoplayer.pause();
      this.setState({ mapa_ended: true });
    }, 6000);
  }
  repeatVideo(projekt) {
    if (projekt == "mapa") {
      this.setState({ projekt04_ended: false });
      this.playMapa();
    }
    if (projekt == "projekt02") {
      this.setState({ projekt02_ended: false });
      this.playProjekt02(2, 9);
    }
    if (projekt == "projekt03") {
      this.setState({ projekt03_ended: false });
      this.playProjekt03(1, 6);
    }
    if (projekt == "projekt04") {
      this.setState({ projekt04_ended: false });
      this.playProjekt04(18, 28);
    }
  }

  expandSection(section) {
    if (section == "loxone") {
      if (this.state.loxone_clanky) {
        this.setState({ loxone_clanky_hiding: true });
        setTimeout(() => {
          this.setState({ loxone_clanky: false, loxone_clanky_hiding: false });
        }, 700);
      } else {
        this.setState({ loxone_clanky: true });
      }
    } else if (section == "mcore") {
      if (this.state.mcore_clanky) {
        this.setState({ mcore_clanky_hiding: true });
        setTimeout(() => {
          this.setState({ mcore_clanky: false, mcore_clanky_hiding: false });
        }, 700);
      } else {
        this.setState({ mcore_clanky: true });
      }
    } else if (section == "why_heatflow") {
      if (this.state.why_heatflow_clanky) {
        this.setState({ why_heatflow_clanky_hiding: true });
        setTimeout(() => {
          this.setState({
            why_heatflow_clanky: false,
            why_heatflow_clanky_hiding: false
          });
        }, 700);
      } else {
        this.setState({ why_heatflow_clanky: true });
      }
    } else if (section == "montaz") {
      if (this.state.montaz_clanky) {
        this.setState({ montaz_clanky_hiding: true });
        setTimeout(() => {
          this.setState({
            montaz_clanky: false,
            montaz_clanky_hiding: false
          });
        }, 700);
      } else {
        this.setState({ montaz_clanky: true });
      }
    } else if (section == "priprava") {
      if (this.state.priprava_clanky) {
        this.setState({ priprava_clanky_hiding: true });
        setTimeout(() => {
          this.setState({
            priprava_clanky: false,
            priprava_clanky_hiding: false
          });
        }, 700);
      } else {
        this.setState({ priprava_clanky: true });
      }
    } else if (section == "navrh") {
      if (this.state.navrh_clanky) {
        this.setState({ navrh_clanky_hiding: true });
        setTimeout(() => {
          this.setState({
            navrh_clanky: false,
            navrh_clanky_hiding: false
          });
        }, 700);
      } else {
        this.setState({ navrh_clanky: true });
      }
    } else if (section == "poradce") {
      if (this.state.poradce_clanky) {
        this.setState({ poradce_clanky_hiding: true });
        setTimeout(() => {
          this.setState({
            poradce_clanky: false,
            poradce_clanky_hiding: false
          });
        }, 700);
      } else {
        this.setState({ poradce_clanky: true });
      }
    } else if (section == "mapa") {
      if (this.state.mapa_clanky) {
        this.setState({ mapa_clanky_hiding: true });
        setTimeout(() => {
          this.setState({
            mapa_clanky: false,
            mapa_clanky_hiding: false
          });
        }, 700);
      } else {
        this.setState({ mapa_clanky: true });
      }
    } else {
    }
  }

  sliderHandle(value) {
    this.setState({ slider_value: value });
  }
  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (target.type == "select-one" && value == "Mám projekt") {
      this.setState({
        file_input: { display: "block" }
      });
    }
    if (target.type == "select-one" && value != "Mám projekt") {
      this.setState({
        file_input: { display: "none" }
      });
    }
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value) && name == "email") {
      this.setState({ valid_email: "" });
    }

    this.setState({
      [name]: value
    });
  }
  handleSubmit(e) {
    e.preventDefault();

    const data = {
      type: "Cenová nabídka",
      origin: "Služba",
      name: this.state.name,
      city: this.state.city,
      phone_number: this.state.tel,
      email: this.state.email,
      note: this.state.note,
      area: this.state.slider_value,
      stage: this.state.phase,
      intelligent_controll: this.state.inteligent_control,
      floor_structure: this.state.floor_structure,
      fve: this.state.fve,
      sources: this.state.sources
    };

    if (this.state.phase == "Mám projekt" && this.state.sources == "") {
      this.setState({ submitted: true, missing_files: true });
      return false;
    }

    if (
      this.state.name != "" &&
      this.state.city != "" &&
      this.state.tel != "" &&
      this.state.email != "" &&
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email) &&
      this.state.slider_value != ""
      // this.state.phase != ""
    ) {
      if (!this.state.clicked) {
        this.setState({
          clicked: true,
          sent: true,
          title: i18n.t('general.sending'),
          form_response: i18n.t('general.loadingFiles')
        });
        postOffers.request(data, this.posted);
      }
    } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email)) {
      this.setState({ valid_email: "not_valid", submitted: true });
    }
    // else if (this.state.phase == "") {
    //   this.setState({ select_empty: "empty" });
    // }
    else {
      this.setState({ submitted: true });
    }
  }
  posted(response) {
    if (response == "Too large") {
      this.setState({
        title: "",
        error_message: i18n.t('general.filesTooLarge'),
        clicked: false,
        sent: false
      });
      return;
    }
    if (response.status == 200 || response.status == 201) {
      this.setState({
        title: i18n.t('general.sended'),
        slider_value: 120,
        name: "",
        city: "",
        tel: "",
        email: "",
        note: "",
        phase: "",
        sources: "",
        form_response: i18n.t('general.takeCareASAP'),
        fve: false,
        inteligent_control: false,
        floor_structure: false,
        submitted: true,
        sent: true
      });

      window.dataLayer.push({
        'event': 'lead',
        'formLocation': 'sluzba',
      });
    } else {
      this.setState({
        title: i18n.t('general.sendingFailed'),
        form_response:
          i18n.t('general.contactUsViaEmail'),
        clicked: false
      });
    }
    localConsoleLog(response);
  }
  showAgain() {
    this.setState({ submitted: false, clicked: false });
  }
  changeFormUploadItem(name, file) {
    if (name == "delete") {
      var temp = this.state.sources;
      temp.splice(file, 1);

      this.setState({
        sources: temp,
        formItems: {
          podklady: {
            value: temp,
            error: ""
          }
        }
      });
    } else {
      this.setState({ missing_files: false });
      if (this.state[name]) {
        var temp = this.state[name];
        temp.push(file);

        this.setState({
          [name]: temp,
          formItems: {
            podklady: {
              value: temp,
              error: ""
            }
          }
        });
      } else {
        this.setState({
          [name]: [file],
          formItems: {
            podklady: {
              value: [file],
              error: ""
            }
          }
        });
      }
    }
  }
  checkNumber(e) {
    let key = Number(e.key);
    if (isNaN(key) || e.key === null) {
      e.preventDefault();
    }
  }
  loaderLoaded(e) {
    this.setState({ loader_loaded: true });
  }

  render() {
    var slider_handle = config.paths.icons + "ic_handle_orange.png";
    var slider_head = config.paths.icons + "ic_mobile_slider_bg_active.png";
    var slider_tail = config.paths.icons + "ic_mobile_slider_bg.png";

    return (
      <div className="main-container" id="main-container-sluzba">
        <img
          className="control-load"
          src={config.paths.backgrounds + "texture-loader-transparent.svg"}
          alt=""
          onLoad={this.loaderLoaded}
          style={{ display: "none" }}
        />
        <Loader 
          showLoader={this.state.showLoader}
          loadingStatus={this.state.loadingStatus}
          loader_loaded={this.state.loader_loaded}
        />
    
        <div
          className={
            "sluzba-section sluzba-intro " +
            (this.state.intro_loaded ? "loaded" : "")
          }
        >
          <div className="sluzba-intro-bg"></div>
          {/* <img
            className="sluzba-intro-img"
            src={config.paths.backgrounds + "img_sluzba_intro_bg.png"}
            alt="MCore"
          /> */}
          <div className="intro-text">
            <div className="intro-heading" dangerouslySetInnerHTML={{ __html: i18n.t('service.header.title') }}></div>
            <div className="intro-info">
              <div className="intro-info-top">
                <div className="intro-info-block">{i18n.t('service.header.box1')}</div>
                <div className="intro-info-block">
                  {i18n.t('service.header.box2')}
                </div>
                <div className="intro-info-block">{i18n.t('service.header.box3')}</div>
                <div className="clear"></div>
              </div>
              <img
                className="intro-info-img"
                src={config.paths.images + "img_sluzba_intro_line.png"}
                alt={i18n.t('general.further')}
              />
              <div className="intro-info-bottom orange">
                <div className="intro-info-block">3000+</div>
                <div className="intro-info-block">7+</div>
                <div className="intro-info-block">50+</div>
                <div className="clear"></div>
              </div>
            </div>

            <img
              className="sluzba-scroll-down h-arrow-down"
              src={config.paths.icons + "ic_arrow_down_white.png"}
              alt={i18n.t('general.further')}
              onClick={this.scrollToMapa}
            />
          </div>
        </div>

        <div className="sluzba-section sluzba-video mapa-section">
          <div className="image-bg"></div>
          <div className="video-controls">
            <div
              className={
                "animation-control " + (this.state.mapa_ended ? "show" : "")
              }
              onClick={() => {
                this.repeatVideo("mapa");
              }}
            >
              <img
                className="animation-control-icon"
                src={config.paths.icons + "ic_sluzba_repeat.png"}
                alt={i18n.t('general.playAgain')}
              />
              <div className="animation-control-text">{i18n.t('general.playAgain')}</div>
            </div>
            <div className="part-number orange">01</div>
          </div>
          <div className="sluzba-video-inner mapa-video">
            <video
              className="projekt-video-04"
              muted
              // onCanPlayThrough={this.videoLoaded}
              // onLoadedMetadata={this.resourceLoaded}
              // onClick={this.videoPlay}
              ref={this.mapa}
              id="mapa"
              loop={false}
            >
              <source
                src={config.paths.videos + "sluzba_mapa.mp4"}
                // src={intro}
                type="video/mp4"
              />
              {i18n.t('reference.wrongBrowserVideo')}
            </video>
          </div>
        </div>

        <div className="sluzba-section sluzba-prubeh">
          <div className="image-bg"></div>
          <div className="color-bg"></div>
          <div className="section-content">
            <div className="section-content-wrapper">
              <div className="section-block-left">
                <div className="section-heading">
                {i18n.t('service.section01.title')}
                </div>
                <div className="section-prubeh-divider">
                  ------------------------------------
                </div>
                <div className="section-prubeh-desc">
                {i18n.t('service.section01.text')}
                </div>
              </div>

              <div className="section-block-right">
                <div className="section-block-el">
                  <img
                    className="section-block-people"
                    src={config.paths.icons + "ic_sluzba_montaz_people.png"}
                    alt="Náročnost"
                  />
                  <img
                    className="section-block-line"
                    src={config.paths.icons + "ic_sluzba_white_line.png"}
                    alt=""
                  />
                  <div className="section-block-el-desc">{i18n.t('service.section01.image1')}</div>
                </div>
                <div className="section-block-el">
                  <div className="section-block-el-number orange">3000+</div>
                  <img
                    className="section-block-line"
                    src={config.paths.icons + "ic_sluzba_orange_line.png"}
                    alt=""
                  />
                  <div className="section-block-el-desc">{i18n.t('service.section01.image2')}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sluzba-section sluzba-gray-section">
          <div className="gray-bg"></div>
          <div className="section-content">
            <div
              className={
                "sluzba-expandable " + (this.state.mapa_clanky ? "opened" : "")
              }
              onClick={() => {
                this.expandSection("mapa");
              }}
            >
              <div className="expandable-heading">
                <span>{i18n.t('service.section02.title')}</span>
              </div>
              <img
                className="expandable-control"
                src={config.paths.icons + "ic_sluzba_open.png"}
                alt={i18n.t('general.open')}
              />
              <div className="clear"></div>
            </div>
            <div
              className={
                "sluzba-clanky-container full-images " +
                (this.state.mapa_clanky ? "opening" : "") +
                " " +
                (this.state.mapa_clanky_hiding ? "hiding" : "")
              }
              // style={{ display: "none" }}
            >
              <img
                className="sluzba-clanek-full-img"
                src={config.paths.images + "img_sluzba_obchodne_technologicke_" + i18n.language + ".svg"}
                alt="Jak probíhá návrh projektu?"
              />

              <img
                className="sluzba-clanek-full-img h-indented"
                src={config.paths.images + "img_sluzba_auta.png"}
                alt="Vysokoškolský projektant"
              />
              <div className="sluzba-clanek-full-block">
              {i18n.t('service.section02.text')}
              </div>
            </div>
          </div>
        </div>

        <div className="sluzba-section sluzba-video">
          <div className="image-bg"></div>

          <div className="video-controls">
            <div
              className={
                "animation-control " +
                (this.state.projekt02_ended ? "show" : "")
              }
              onClick={() => {
                this.repeatVideo("projekt02");
              }}
            >
              <img
                className="animation-control-icon"
                src={config.paths.icons + "ic_sluzba_repeat.png"}
                alt={i18n.t('general.playAgain')}
              />
              <div className="animation-control-text">{i18n.t('general.playAgain')}</div>
            </div>
            <div className="part-number orange">02</div>
          </div>
          <div className="sluzba-video-inner">
            <video
              className="projekt-video-04 plan"
              muted
              // onCanPlayThrough={this.videoLoaded}
              // onLoadedMetadata={this.videoLoaded}
              // onClick={this.videoPlay}
              ref={this.projekt02}
              id="projekt02"
              loop={false}
            >
              <source
                src={config.paths.videos + "sluzba_projekt.mp4"}
                // src={intro}
                type="video/mp4"
              />
              {i18n.t('reference.wrongBrowserVideo')}
            </video>
          </div>
        </div>

        <div className="sluzba-section sluzba-prubeh">
          <div className="image-bg"></div>
          <div className="color-bg"></div>
          <div className="section-content">
            <div className="section-content-wrapper">
              <div className="section-block-left">
                <div className="section-heading">{i18n.t('service.section03.title')}</div>
                <div className="section-prubeh-divider">
                  ------------------------------------
                </div>
                <div className="section-prubeh-desc">
                {i18n.t('service.section03.text')}<sup>*</sup><br/><br/>
                  <div className="footnote">
                    <sup>*</sup>{i18n.t('service.section03.subtext')} <Link to={config.pages.tepelny_audit.url}>({i18n.t('general.here')})</Link>
                  </div>
                </div>
              </div>

              <div className="section-block-right">
                <div className="section-block-el">
                  <img
                    className="section-block-people"
                    src={config.paths.icons + "ic_sluzba_navrh_people.png"}
                    alt="Náročnost"
                  />
                  <img
                    className="section-block-line"
                    src={config.paths.icons + "ic_sluzba_white_line.png"}
                    alt=""
                  />
                  <div className="section-block-el-desc">
                    {i18n.t('service.section03.image1')}
                  </div>
                </div>
                <div className="section-block-el">
                  <div className="section-block-el-number orange">4500+</div>
                  <img
                    className="section-block-line"
                    src={config.paths.icons + "ic_sluzba_orange_line.png"}
                    alt=""
                  />
                  <div className="section-block-el-desc">
                    {i18n.t('service.section03.image2')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sluzba-section sluzba-gray-section">
          <div className="gray-bg"></div>
          <div className="section-content">
            <div
              className={
                "sluzba-expandable " + (this.state.navrh_clanky ? "opened" : "")
              }
              onClick={() => {
                this.expandSection("navrh");
              }}
            >
              <div className="expandable-heading">
                <span>{i18n.t('service.section04.title')}</span>
              </div>
              <img
                className="expandable-control"
                src={config.paths.icons + "ic_sluzba_open.png"}
                alt={i18n.t('general.open')}
              />
              <div className="clear"></div>
            </div>
            <div
              className={
                "sluzba-clanky-container full-images " +
                (this.state.navrh_clanky ? "opening" : "") +
                " " +
                (this.state.navrh_clanky_hiding ? "hiding" : "")
              }
              // style={{ display: "none" }}
            >
              <img
                className="sluzba-clanek-full-img"
                src={config.paths.images + "img_sluzba_navrh_projektu_" + i18n.language + ".svg"}
                alt="Jak probíhá návrh projektu?"
              />

              <img
                className="sluzba-clanek-full-img h-indented"
                src={config.paths.images + "img_sluzba_prubeh_projektu.png"}
                alt="Vysokoškolský projektant"
              />
              <div className="sluzba-clanek-full-block">
              {i18n.t('service.section04.text')}
              </div>
            </div>
          </div>
        </div>

        <div className="sluzba-section sluzba-video">
          <div className="image-bg"></div>

          <div className="video-controls">
            <div
              className={
                "animation-control " +
                (this.state.projekt03_ended ? "show" : "")
              }
              onClick={() => {
                this.repeatVideo("projekt03");
              }}
            >
              <img
                className="animation-control-icon"
                src={config.paths.icons + "ic_sluzba_repeat.png"}
                alt={i18n.t('general.playAgain')}
              />
              <div className="animation-control-text">{i18n.t('general.playAgain')}</div>
            </div>
            <div className="part-number orange">03</div>
          </div>
          <div className="sluzba-video-inner">
            <video
              className="projekt-video-04 house"
              muted
              // onCanPlayThrough={this.videoLoaded}
              // onLoadedMetadata={this.videoLoaded}
              // onClick={this.videoPlay}
              ref={this.projekt03}
              id="projekt03"
              loop={false}
            >
              <source
                src={config.paths.videos + "sluzba_projekt.mp4"}
                // src={intro}
                type="video/mp4"
              />
              {i18n.t('reference.wrongBrowserVideo')}
            </video>
          </div>
        </div>

        <div className="sluzba-section sluzba-prubeh">
          <div className="image-bg"></div>
          <div className="color-bg"></div>
          <div className="section-content">
            <div className="section-content-wrapper">
              <div className="section-block-left">
                <div className="section-heading">{i18n.t('service.section05.title')}</div>
                <div className="section-prubeh-divider">
                  ------------------------------------
                </div>
                <div className="section-prubeh-desc">
                {i18n.t('service.section05.text')}
                </div>
              </div>

              <div className="section-block-right">
                <div className="section-block-el">
                  <img
                    className="section-block-people"
                    src={config.paths.images + "img_sluzba_dodavka_people.png"}
                    alt="Náročnost"
                  />
                  <img
                    className="section-block-line"
                    src={config.paths.icons + "ic_sluzba_white_line.png"}
                    alt=""
                  />
                  <div className="section-block-el-desc">{i18n.t('service.section05.image1')}</div>
                </div>
                <div className="section-block-el">
                  <div className="section-block-el-number orange">3000+</div>
                  <img
                    className="section-block-line"
                    src={config.paths.icons + "ic_sluzba_orange_line.png"}
                    alt=""
                  />
                  <div className="section-block-el-desc">{i18n.t('service.section05.image2')}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sluzba-section sluzba-gray-section">
          <div className="gray-bg"></div>
          <div className="section-content">
            <div
              className={
                "sluzba-expandable " +
                (this.state.priprava_clanky ? "opened" : "")
              }
              onClick={() => {
                this.expandSection("priprava");
              }}
            >
              <div className="expandable-heading">
                <span>{i18n.t('service.section06.title')}</span>
              </div>
              <img
                className="expandable-control"
                src={config.paths.icons + "ic_sluzba_open.png"}
                alt={i18n.t('general.open')}
              />
              <div className="clear"></div>
            </div>
            <div
              className={
                "sluzba-clanky-container " +
                (this.state.priprava_clanky ? "opening" : "") +
                " " +
                (this.state.priprava_clanky_hiding ? "hiding" : "")
              }
              // style={{ display: "none" }}
            >
              <div className="sluzba-clanek">
                <img
                  className="sluzba-clanek-img"
                  src={config.paths.images + "img_sluzba_priprava1.png"}
                  alt="Příprava"
                />
                <div className="sluzba-clanek-caption">
                  <h3 className="sluzba-clanek-title">{i18n.t('service.section06.box1.title')}</h3>
                  <img
                    className="sluzba-clanek-divider"
                    src={config.paths.icons + "ic_sluzba_line_montaz1.png"}
                    alt="Příprava"
                  />
                  <div className="sluzba-clanek-desc">
                  {i18n.t('service.section06.box1.text')}
                  </div>
                </div>
              </div>
              <div className="sluzba-clanek">
                <img
                  className="sluzba-clanek-img"
                  src={config.paths.images + "img_sluzba_priprava2.png"}
                  alt="Příprava"
                />
                <div className="sluzba-clanek-caption">
                  <h3 className="sluzba-clanek-title">{i18n.t('service.section06.box2.title')}</h3>
                  <img
                    className="sluzba-clanek-divider"
                    src={config.paths.icons + "ic_sluzba_line_montaz2.png"}
                    alt="Příprava"
                  />
                  <div className="sluzba-clanek-desc">
                  {i18n.t('service.section06.box2.text')}
                  </div>
                </div>
              </div>
              <div className="sluzba-clanek">
                <img
                  className="sluzba-clanek-img"
                  src={config.paths.images + "img_sluzba_priprava3.png"}
                  alt="Příprava"
                />
                <div className="sluzba-clanek-caption">
                  <h3 className="sluzba-clanek-title">
                    {i18n.t('service.section06.box3.title')}
                  </h3>
                  <img
                    className="sluzba-clanek-divider"
                    src={config.paths.icons + "ic_sluzba_line_montaz3.png"}
                    alt="Příprava"
                  />
                  <div className="sluzba-clanek-desc">
                  {i18n.t('service.section06.box3.text')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sluzba-section sluzba-video">
          <div className="image-bg"></div>

          <div className="video-controls">
            <div
              className={
                "animation-control " +
                (this.state.projekt04_ended ? "show" : "")
              }
              onClick={() => {
                this.repeatVideo("projekt04");
              }}
            >
              <img
                className="animation-control-icon"
                src={config.paths.icons + "ic_sluzba_repeat.png"}
                alt={i18n.t('general.playAgain')}
              />
              <div className="animation-control-text">{i18n.t('general.playAgain')}</div>
            </div>
            <div className="part-number orange">04</div>
          </div>
          <div className="sluzba-video-inner">
            <video
              className="projekt-video-04 house"
              muted
              // onCanPlayThrough={this.videoLoaded}
              // onLoadedMetadata={this.videoLoaded}
              // onClick={this.videoPlay}
              ref={this.projekt04}
              id="projekt04"
              loop={false}
            >
              <source
                src={config.paths.videos + "sluzba_projekt_04.mp4"}
                // src={intro}
                type="video/mp4"
              />
              {i18n.t('reference.wrongBrowserVideo')}
            </video>
          </div>
        </div>

        <div className="sluzba-section sluzba-prubeh">
          <div className="image-bg"></div>
          <div className="color-bg"></div>
          <div className="section-content">
            <div className="section-content-wrapper">
              <div className="section-block-left">
                <div className="section-heading">{i18n.t('service.section07.title')}</div>
                <div className="section-prubeh-divider">
                  ------------------------------------
                </div>
                <div className="section-prubeh-desc">
                {i18n.t('service.section07.text')}
                </div>
              </div>

              <div className="section-block-right">
                <div className="section-block-el">
                  <img
                    className="section-block-people"
                    src={config.paths.icons + "ic_sluzba_montaz_people.png"}
                    alt="Náročnost"
                  />
                  <img
                    className="section-block-line"
                    src={config.paths.icons + "ic_sluzba_white_line.png"}
                    alt=""
                  />
                  <div className="section-block-el-desc">{i18n.t('service.section07.image1')}</div>
                </div>
                <div className="section-block-el">
                  <div className="section-block-el-number orange" style={{"whiteSpace": "nowrap"}}>213 752 m<sup>2</sup></div>
                  <img
                    className="section-block-line"
                    src={config.paths.icons + "ic_sluzba_orange_line.png"}
                    alt=""
                  />
                  <div className="section-block-el-desc">
                    {i18n.t('service.section07.image2')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sluzba-section sluzba-gray-section section-bigger-padding">
          <div className="gray-bg"></div>
          <div className="section-content">
            <div
              className={
                "sluzba-expandable " +
                (this.state.montaz_clanky ? "opened" : "")
              }
              onClick={() => {
                this.expandSection("montaz");
              }}
            >
              <div className="expandable-heading">
                <span>{i18n.t('service.section08.title')}</span>
              </div>
              <img
                className="expandable-control"
                src={config.paths.icons + "ic_sluzba_open.png"}
                alt={i18n.t('general.open')}
              />
              <div className="clear"></div>
            </div>
            <div
              className={
                "sluzba-clanky-container " +
                (this.state.montaz_clanky ? "opening" : "") +
                " " +
                (this.state.montaz_clanky_hiding ? "hiding" : "")
              }
              // style={{ display: "none" }}
            >
              <div className="sluzba-clanek">
                <img
                  className="sluzba-clanek-img"
                  src={config.paths.images + "img_sluzba_montaz1.png"}
                  alt="Loxone"
                />
                <div className="sluzba-clanek-caption">
                  <h3 className="sluzba-clanek-title">{i18n.t('service.section08.box1.title')}</h3>
                  <img
                    className="sluzba-clanek-divider"
                    src={config.paths.icons + "ic_sluzba_line_montaz1.png"}
                    alt="Loxone"
                  />
                  <div className="sluzba-clanek-desc">
                  {i18n.t('service.section08.box1.text')}
                  </div>
                </div>
              </div>
              <div className="sluzba-clanek">
                <img
                  className="sluzba-clanek-img"
                  src={config.paths.images + "img_sluzba_montaz2.png"}
                  alt="Loxone"
                />
                <div className="sluzba-clanek-caption">
                  <h3 className="sluzba-clanek-title">
                    {i18n.t('service.section08.box2.title')}
                  </h3>
                  <img
                    className="sluzba-clanek-divider"
                    src={config.paths.icons + "ic_sluzba_line_montaz2.png"}
                    alt="Loxone"
                  />
                  <div className="sluzba-clanek-desc">
                  {i18n.t('service.section08.box2.text')}
                  </div>
                </div>
              </div>
              <div className="sluzba-clanek">
                <img
                  className="sluzba-clanek-img"
                  src={config.paths.images + "img_sluzba_montaz3.png"}
                  alt="Loxone"
                />
                <div className="sluzba-clanek-caption">
                  <h3 className="sluzba-clanek-title">{i18n.t('service.section08.box3.title')}</h3>
                  <img
                    className="sluzba-clanek-divider"
                    src={config.paths.icons + "ic_sluzba_line_montaz3.png"}
                    alt="Loxone"
                  />
                  <div className="sluzba-clanek-desc">
                  {i18n.t('service.section08.box3.text')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sluzba-section sluzba-interim-section">
          <div className="wave-bg"></div>
          <div className="image-bg"></div>
          <div className="color-bg"></div>
          <div className="section-content">
            <div className="interim-text" dangerouslySetInnerHTML={{ __html: i18n.t('service.quote01') }}></div>
            <div className="part-number black">05</div>
          </div>
        </div>

        <div
          className="sluzba-section sluzba-dodavka"
          ref={this.dodavka_section}
        >
          <div className="image-bg"></div>
          <div className="color-bg"></div>
          <div className="section-text">
            <div className="dodavka-animation">
              {/* <video
                className="dodavka-video"
                muted
                ref="dodavka"
                loop={false}
              >
                <source
                  src={config.paths.videos + "sluzba_dodavka.mp4"}
                  // src={intro}
                  type="video/mp4"
                />
                {i18n.t('general.wrongBrowserVideo')}
              </video> */}
              <img className="dodavka-img" src={config.paths.images + "dodavka-img.jpeg"} draggable="false" alt="dodavka-img" />
              <div className="dodavka-animation-desc">
                <div
                  className={
                    "animation-desc-el presenting" +
                    (this.state.dodavka_animation_state >= 1
                      ? "presenting"
                      : "")
                  }
                >
                  <div className="desc-el-line"></div>
                  <div className="desc-el-text">{i18n.t('service.section09.note1')}</div>
                </div>
                <div
                  className={
                    "animation-desc-el presenting" +
                    (this.state.dodavka_animation_state >= 2
                      ? "presenting"
                      : "")
                  }
                >
                  <div className="desc-el-line"></div>
                  <div className="desc-el-text">{i18n.t('service.section09.note2')}</div>
                </div>
                <div
                  className={
                    "animation-desc-el presenting" +
                    (this.state.dodavka_animation_state >= 3
                      ? "presenting"
                      : "")
                  }
                >
                  <div className="desc-el-line"></div>
                  <div className="desc-el-text">{i18n.t('service.section09.note3')}</div>
                </div>
              </div>
            </div>
            <div className="section-heading">{i18n.t('service.section09.title')}</div>
            <div className="section-info">
              <div className="section-info-top">
                <div className="section-info-block">
                  <img
                    className="section-info-block-img"
                    src={config.paths.images + "img_sluzba_dodavka_people.png"}
                    alt="Náročnost"
                  />
                </div>
                <div className="section-info-block orange">500+</div>
                <div className="clear"></div>
              </div>
              <img
                className="section-info-img"
                src={config.paths.images + "img_sluzba_dodavka_line.png"}
                alt=""
              />
              <div className="section-info-bottom orange">
                <div className="section-info-block">{i18n.t('service.section09.image1')}</div>
                <div className="section-info-block">{i18n.t('service.section09.image2')}</div>
                <div className="clear"></div>
              </div>
              <div className="section-info-paragraph">
              {i18n.t('service.section09.text')}
              </div>
              <div className="section-info-links">
                <div className="section-info-quote" onClick={this.scrollToFormular}>
                  <div className="section-info-quote-text">{i18n.t('service.section09.cta')}</div>{" "}
                  <img
                    className="section-info-quote-arrow h-arrow-down-black"
                    src={config.paths.icons + "ic_arrow_down_white.png"}
                    alt={i18n.t('general.further')}
                  />
                </div>
                {/*
                <div className="section-info-more">
                  <div className="section-info-quote-text">
                    více o komplexní dodávce
                  </div>{" "}
                  <img
                    className="section-info-quote-arrow h-arrow-right-black"
                    src={config.paths.icons + "ic_arrow_right_white.png"}
                    alt={i18n.t('general.further')}
                  />
                </div>
                */}
              </div>
            </div>
          </div>
        </div>

        <div className="sluzba-section sluzba-gray-section">
          <div className="gray-bg"></div>
          <div className="number-container">
            <div className="part-number black">06</div>
          </div>
          <div className="section-content">
            <div
              className={
                "sluzba-expandable " +
                (this.state.why_heatflow_clanky ? "opened" : "")
              }
              onClick={() => {
                this.expandSection("why_heatflow");
              }}
            >
              <div className="expandable-heading">
                <span>{i18n.t('service.section10.title')}</span>
                <span>?</span>
              </div>
              <img
                className="expandable-control"
                src={config.paths.icons + "ic_sluzba_open.png"}
                alt={i18n.t('general.open')}
              />
              <div className="clear"></div>
            </div>
            <div
              className={
                "sluzba-clanky-container " +
                (this.state.why_heatflow_clanky ? "opening" : "") +
                " " +
                (this.state.why_heatflow_clanky_hiding ? "hiding" : "")
              }
              // style={{ display: "none" }}
            >
              <img
                className="sluzba-clanek-full-img"
                src={config.paths.images + "img_sluzba_why_heatflow_" + i18n.language + ".svg"}
                alt="Proč heatflow"
              />
            </div>
          </div>
        </div>

        <div className="sluzba-section sluzba-loxone">
          <div className="section-text">
            <div className="section-heading">
              {i18n.t('service.section11.title')}
            </div>
            <div className="section-info">
              <div className="section-info-top">
                <div className="section-info-block">
                  <img
                    className="section-info-block-img"
                    src={config.paths.images + "img_sluzba_mcore_people.png"}
                    alt="Náročnost"
                  />
                </div>
                <div className="section-info-block h-loxone-green">500+</div>
                <div className="clear"></div>
              </div>
              <img
                className="section-info-img"
                src={config.paths.images + "img_sluzba_loxone_line.png"}
                alt={i18n.t('general.further')}
              />
              <div className="section-info-bottom orange">
                <div className="section-info-block">{i18n.t('service.section11.image1')}</div>
                <div className="section-info-block">{i18n.t('service.section11.image2')}</div>
                <div className="clear"></div>
              </div>
              <div className="section-info-paragraph">
              {i18n.t('service.section11.text')}
              </div>
              <div className="section-info-links">
                <div className="section-info-quote" onClick={this.scrollToFormular}>
                  <div className="section-info-quote-text">{i18n.t('service.section11.cta')}</div>{" "}
                  <img
                    className="section-info-quote-arrow h-arrow-down-black"
                    src={config.paths.icons + "ic_sluzba_arrow_down_black.png"}
                    alt={i18n.t('general.further')}
                  />
                </div>
                <div className="section-info-more">
                  <div className="section-info-quote-text"><a href="http://www.lxn.cz" target="_blank">{i18n.t('service.section11.cta2')}</a></div>{" "}
                  <img
                    className="section-info-quote-arrow h-arrow-right-black"
                    src={config.paths.icons + "ic_sluzba_arrow_right_black.png"}
                    alt={i18n.t('general.further')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sluzba-section sluzba-white-section">
          <div className="number-container">
            <div className="part-number black">07</div>
          </div>
          <div className="section-content">
            <div
              className={
                "sluzba-expandable--lowered sluzba-expandable " +
                (this.state.loxone_clanky ? "opened" : "")
              }
              /*
              onClick={() => {
                this.expandSection("loxone");
              }}
              */
            >
              <div className="expandable-heading">
                <span>{i18n.t('service.section12.title')}</span>
              </div>
              <img
                className="expandable-control"
                src={config.paths.icons + "ic_sluzba_open.png"}
                alt={i18n.t('general.open')}
              />
              <div className="clear"></div>
            </div>
            <div
              className={
                "sluzba-clanky-container " +
                (this.state.loxone_clanky ? "opening" : "") +
                " " +
                (this.state.loxone_clanky_hiding ? "hiding" : "")
              }
              // style={{ display: "none" }}
            >
              <div className="sluzba-clanek">
                <img
                  className="sluzba-clanek-img"
                  src={config.paths.images + "img_sluzba_loxone1.png"}
                  alt="Loxone"
                />
                <div className="sluzba-clanek-caption" dangerouslySetInnerHTML={{ __html: i18n.t('service.section12.item1') }}></div>

                <div className="sluzba-clanek-vice">
                  {i18n.t('general.readMore')}
                  <img
                    className="sluzba-clanek-vice-arrow"
                    src={config.paths.icons + "ic_arrow_right_black.png"}
                    alt={i18n.t('general.readMore')}
                  />
                </div>
              </div>
              <div className="sluzba-clanek">
                <img
                  className="sluzba-clanek-img"
                  src={config.paths.images + "img_sluzba_loxone2.png"}
                  alt="Loxone"
                />
                <div className="sluzba-clanek-caption" dangerouslySetInnerHTML={{ __html: i18n.t('service.section12.item2') }}></div>

                <div className="sluzba-clanek-vice">
                  {i18n.t('general.readMore')}
                  <img
                    className="sluzba-clanek-vice-arrow"
                    src={config.paths.icons + "ic_arrow_right_black.png"}
                    alt={i18n.t('general.readMore')}
                  />
                </div>
              </div>
              <div className="sluzba-clanek">
                <img
                  className="sluzba-clanek-img"
                  src={config.paths.images + "img_sluzba_loxone3.png"}
                  alt="Loxone"
                />
                <div className="sluzba-clanek-caption" dangerouslySetInnerHTML={{ __html: i18n.t('service.section12.item3') }}></div>

                <div className="sluzba-clanek-vice">
                  {i18n.t('general.readMore')}
                  <img
                    className="sluzba-clanek-vice-arrow"
                    src={config.paths.icons + "ic_arrow_right_black.png"}
                    alt={i18n.t('general.readMore')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sluzba-section sluzba-mcore">
          <div className="section-text">
            <div className="section-heading">
              {i18n.t('service.section13.title')}<sup>TM</sup>
            </div>
            <div className="section-info">
              <div className="section-info-top">
                <div className="section-info-block">
                  <img
                    className="section-info-block-img"
                    src={config.paths.images + "img_sluzba_mcore_people-new.png"}
                    alt="Náročnost"
                  />
                </div>
                <div className="section-info-block h-mcore-blue">1000+</div>
                <div className="clear"></div>
              </div>
              <img
                className="section-info-img"
                src={config.paths.images + "img_sluzba_mcore_line.png"}
                alt={i18n.t('general.further')}
              />
              <div className="section-info-bottom orange">
                <div className="section-info-block">
                  {i18n.t('service.section13.image1')}<sup>TM</sup>
                </div>
                <div className="section-info-block">
                  {i18n.t('service.section13.image2')}<sup>TM</sup>
                </div>
                <div className="clear"></div>
              </div>
              <div className="section-info-paragraph">
              {i18n.t('service.section13.text')}
              </div>
              <div className="section-info-links">
                <div className="section-info-quote" onClick={this.scrollToFormular}>
                  <div className="section-info-quote-text">{i18n.t('service.section13.cta')}</div>{" "}
                  <img
                    className="section-info-quote-arrow h-arrow-down-black"
                    src={config.paths.icons + "ic_sluzba_arrow_down_black.png"}
                    alt={i18n.t('general.further')}
                  />
                </div>
                <div className="section-info-more">
                  <div className="section-info-quote-text"><a href="http://www.mcore.cz" target="_blank">{i18n.t('service.section13.cta2')}</a></div>{" "}
                  <img
                    className="section-info-quote-arrow h-arrow-right-black"
                    src={config.paths.icons + "ic_sluzba_arrow_right_black.png"}
                    alt={i18n.t('general.further')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sluzba-section sluzba-white-section sluzba-last-white">
          <div className="section-content">
            <div
              className={
                "sluzba-expandable--lowered sluzba-expandable " + (this.state.mcore_clanky ? "opened" : "")
              }
              /*
              onClick={() => {
                this.expandSection("mcore");
              }}
              */
            >
              <div className="expandable-heading">
                <span>{i18n.t('service.section14.title')}</span>
              </div>
              <img
                className="expandable-control"
                src={config.paths.icons + "ic_sluzba_open.png"}
                alt={i18n.t('general.open')}
              />
              <div className="clear"></div>
            </div>
            <div
              className={
                "sluzba-clanky-container " +
                (this.state.mcore_clanky ? "opening" : "") +
                " " +
                (this.state.mcore_clanky_hiding ? "hiding" : "")
              }
              // style={{ display: "none" }}
            >
              <div className="sluzba-clanek">
                <img
                  className="sluzba-clanek-img"
                  src={config.paths.images + "img_sluzba_mcore1.png"}
                  alt="MCore"
                />
                <div className="sluzba-clanek-caption" dangerouslySetInnerHTML={{ __html: i18n.t('service.section14.item1') }}></div>

                <div className="sluzba-clanek-vice">
                  {i18n.t('general.readMore')}
                  <img
                    className="sluzba-clanek-vice-arrow"
                    src={config.paths.icons + "ic_arrow_right_black.png"}
                    alt={i18n.t('general.readMore')}
                  />
                </div>
              </div>
              <div className="sluzba-clanek">
                <img
                  className="sluzba-clanek-img"
                  src={config.paths.images + "img_sluzba_mcore2.png"}
                  alt="MCore"
                />
                <div className="sluzba-clanek-caption" dangerouslySetInnerHTML={{ __html: i18n.t('service.section14.item2') }}></div>

                <div className="sluzba-clanek-vice">
                  {i18n.t('general.readMore')}
                  <img
                    className="sluzba-clanek-vice-arrow"
                    src={config.paths.icons + "ic_arrow_right_black.png"}
                    alt={i18n.t('general.readMore')}
                  />
                </div>
              </div>
              <div className="sluzba-clanek">
                <img
                  className="sluzba-clanek-img"
                  src={config.paths.images + "img_sluzba_mcore3.png"}
                  alt="MCore"
                />
                <div className="sluzba-clanek-caption" dangerouslySetInnerHTML={{ __html: i18n.t('service.section14.item3') }}></div>

                <div className="sluzba-clanek-vice">
                  {i18n.t('general.readMore')}
                  <img
                    className="sluzba-clanek-vice-arrow"
                    src={config.paths.icons + "ic_arrow_right_black.png"}
                    alt={i18n.t('general.readMore')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sluzba-section sluzba-expertise" style={{ zIndex: '401' }}>
          <img
            className="sluzba-expertise-img"
            src={config.paths.backgrounds + "img_sluzba_footer_bg.png"}
            alt="Expertise"
          />
          <div className="sluzba-expertise-text">
            <h2 dangerouslySetInnerHTML={{ __html: i18n.t('service.section15.title') }}></h2>
            <h4 dangerouslySetInnerHTML={{ __html: i18n.t('service.section15.subtitle') }}></h4>
          </div>
        </div>

        <div className="sluzba-section sluzba-kontakt" ref={this.formular}>
          <div className="form-container">
            {this.state.sent ? (
              <div className="form-sent">
                <div className="sent-wrapper">
                  <h2 className="section-heading">
                    {this.state.title == i18n.t('general.sended') ? (
                      <img
                        className="odeslano-icon"
                        src={config.paths.icons + "ic_checked_orange.png"}
                        alt={i18n.t('general.sended')}
                      />
                    ) : (
                      ""
                    )}
                    <b>{this.state.title}</b>
                  </h2>
                  <div className="odeslano-text">
                    {this.state.form_response}
                  </div>{" "}
                </div>
              </div>
            ) : (
              <div className="form-container">
                <div className="error_message">{this.state.error_message}</div>
                <form
                  action=""
                  className={
                    "form-sluzba-nabidka " +
                    this.state.valid_email +
                    " " +
                    (this.state.sent ? "sent" : "") +
                    " " +
                    (this.state.submitted ? "submitted" : "")
                  }
                  onSubmit={this.handleSubmit}
                >
                  <div className="form-side">
                    <div className="form-row two-col">
                      <div className="form-field">
                        <input
                          type="text"
                          name="name"
                          id="form_name_sluzba_nabidka"
                          required
                          onChange={this.handleChange}
                          value={this.state.name}
                        />
                        <label htmlFor="form_name_sluzba_nabidka">
                          {i18n.t('general.fullname')} °
                        </label>
                        <i className="form-field-check"></i>
                        <span
                          className="required"
                          style={
                            this.state.name != "" ? { display: "none" } : {}
                          }
                        >
                          {i18n.t('general.requiredField')}
                        </span>
                      </div>
                      <div className="form-field">
                        <input
                          type="text"
                          name="city"
                          id="form_city_sluzba_nabidka"
                          required
                          onChange={this.handleChange}
                          value={this.state.city}
                        />
                        <label htmlFor="form_city_sluzba_nabidka">
                          {i18n.t('general.city')} °
                        </label>
                        <i className="form-field-check"></i>
                        <span
                          className="required"
                          style={
                            this.state.city != "" ? { display: "none" } : {}
                          }
                        >
                          {i18n.t('general.requiredField')}
                        </span>
                      </div>
                    </div>
                    <div className="form-row two-col">
                      <div className="form-field">
                        <input
                          type="text"
                          name="tel"
                          id="form_tel"
                          pattern={`[0-9a-zA-Z]{${i18n.language === 'cs' ? '9,9' : '10,10'}}`}
                          onKeyPress={this.checkNumber}
                          required
                          onChange={this.handleChange}
                          value={this.state.tel}
                        />
                        <label htmlFor="form_tel">{i18n.t('general.phone')} °</label>
                        <i className="form-field-check"></i>
                        <span
                          className="required"
                          style={
                            this.state.tel != "" ? { display: "none" } : {}
                          }
                        >
                          {i18n.t('general.requiredField')}
                        </span>
                      </div>
                      <div className="form-field">
                        <input
                          type="email"
                          name="email"
                          id="form_email_sluzba_nabidka"
                          className={
                            "email " +
                            (this.state.email != "" ? "not-empty" : "")
                          }
                          required
                          onChange={this.handleChange}
                          value={this.state.email}
                        />
                        <label htmlFor="form_email_sluzba_nabidka">
                          email °
                        </label>
                        <i className="form-field-check"></i>
                        {this.state.submitted &&
                        !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email) ? (
                          <span className="required required_email">
                            {i18n.t('general.wrongFormate')}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="form-row  one-col">
                      <div className="form-field">
                        {/* <input
                      type="text"
                      name="note"
                      id="form_note_sluzba_nabidka"
                      onChange={this.handleChange}
                      value={this.state.note}
                    /> */}
                        <textarea
                          // type="text"
                          name="note"
                          id="form_note_sluzba_nabidka"
                          onChange={this.handleChange}
                          value={this.state.note}
                          className={this.state.note != "" ? "filled" : ""}
                        ></textarea>
                        <label htmlFor="form_note_sluzba_nabidka">
                          {i18n.t('general.note')}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-side">
                    <div className="sluzba-nabidka-slider">
                      <div className="sluzba-nabidka-legend-cont">
                        <span className="sluzba-nabidka-form-label">
                          {i18n.t('general.totalArea')} °
                        </span>
                        <span className="sluzba-nabidka-form-area">
                          <b>{this.state.slider_value}</b> m<sup>2</sup>
                        </span>
                      </div>
                      <div className="sluzba-nabidka-slider-cont">
                        <Slider
                          id="slider_7"
                          className="sluzba-nabidka-slider-range"
                          defaultValue={this.state.slider_value}
                          min={1}
                          max={500}
                          step={1}
                          onChange={this.sliderHandle}
                          trackStyle={{
                            backgroundImage: "url('" + slider_head + "')",
                            height: 24
                          }}
                          handleStyle={{
                            borderColor: "blue",
                            height: 44,
                            width: 26,
                            marginLeft: -9,
                            marginTop: -9,
                            backgroundImage: "url('" + slider_handle + "')"
                          }}
                          railStyle={{
                            backgroundImage: "url('" + slider_tail + "')",
                            height: 24
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-row  one-col">
                      <div className="form-field">
                        <select
                          name="phase"
                          className={this.state.select_empty}
                          id="form_faze_sluzba_nabidka"
                          onChange={this.handleChange}
                          value={this.state.phase}
                        >
                          <option value="" defaultValue>
                            {i18n.t('general.projectPhaseAndDocuments')}
                          </option>
                          <option value="Nemám projekt">{i18n.t('general.noProject')}</option>
                          <option value="Mám projekt">{i18n.t('general.haveProject')}</option>
                          <option value="Mám projekt, ale nemám ho u sebe">
                            {i18n.t('general.haveProjectNoDocumentation')}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div
                      className="form-row  one-col"
                      style={this.state.file_input}
                    >
                      <div
                        className={
                          "form-field files " +
                          (this.state.missing_files ? "missing" : "")
                        }
                      >
                        <FormItemUpload
                          {...{
                            text:
                              i18n.t('general.uploadNote'),
                            dataFormItem: "sources",
                            value: this.state.formItems.podklady.value,
                            error: this.state.formItems.podklady.error,
                            actions: {
                              changeFormUploadItem: this.changeFormUploadItem
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-additional">
                    <span className="form-additional-heading">
                      {i18n.t('general.additionalOptions')}
                    </span>
                    <div className="form-field">
                      <div className="form-checkbox">
                        <span dangerouslySetInnerHTML={{ __html: i18n.t('general.priceComplete') }}></span>
                        <input
                          type="checkbox"
                          name="floor_structure"
                          id="ovladani_skladba"
                          checked={this.state.floor_structure}
                          onChange={this.handleChange}
                        />
                        <label htmlFor="ovladani_skladba" />
                      </div>
                      <div className="form-checkbox">
                        <span dangerouslySetInnerHTML={{ __html: i18n.t('general.priceInteligent') }}></span>
                        <input
                          type="checkbox"
                          name="inteligent_control"
                          id="ovladani_sluzba_nabidkaX"
                          checked={this.state.inteligent_control}
                          onChange={this.handleChange}
                        />
                        <label htmlFor="ovladani_sluzba_nabidkaX" />
                      </div>
                      <div className="form-checkbox">
                        <span dangerouslySetInnerHTML={{ __html: i18n.t('general.priceSolar') }}></span>
                        <input
                          type="checkbox"
                          name="fve"
                          id="solar_sluzba_nabidkaX"
                          checked={this.state.fve}
                          onChange={this.handleChange}
                        />
                        <label htmlFor="solar_sluzba_nabidkaX" />
                      </div>
                    </div>
                  </div>
                  <div className="form-bottom">
                    <div className="form-field">
                      <button type="submit" onClick={this.handleSubmit}>
                        {i18n.t('general.toSend')}
                      </button>
                      <span className="form-submit-note">
                        {i18n.t('general.sendingAgree1')}{" "}
                        <span
                          className="privacy-link"
                          onClick={this.openPrivacy}
                        >
                          {i18n.t('general.sendingAgree2')}
                        </span>
                        .
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
        <div
            className="gdpr-modal"
            onClick={this.modalClicked}
            data-name="modal"
            style={{
              display: this.state.gdpr_modal ? "block" : "none"
            }}
          >
            <div className="gdpr-cont">
              <div className="gdpr-close" onClick={this.openPrivacy}>
                <img
                  className=""
                  src={config.paths.icons + "ic_close_grey.png"}
                  alt=""
                />
              </div>
              <div className="gdpr-wrapper">
                <h1 className="gdpr-nadpis">
                  {i18n.t('general.privaciPolicyAgreement')}
                </h1>
                <div className="gdpr-content" dangerouslySetInnerHTML={{ __html: i18n.t('general.gdprContent') }}></div>
              </div>
            </div>
          </div>
        <img
          style={{ display: "none" }}
          src={config.paths.backgrounds + "img_sluzba_intro_bg.png"}
          onLoad={this.resourceLoaded}
        />
        <img
          style={{ display: "none" }}
          src={config.paths.backgrounds + "img_sluzba_mcore_bg.png"}
          onLoad={this.resourceLoaded}
        />
        <img
          style={{ display: "none" }}
          src={config.paths.backgrounds + "img_sluzba_loxone_bg.png"}
          onLoad={this.resourceLoaded}
        />
        <img
          style={{ display: "none" }}
          src={config.paths.backgrounds + "img_sluzba_sede_bg.png"}
          onLoad={this.resourceLoaded}
        />
      </div>
    );
  }
}

export default Sluzba;
