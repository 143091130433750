import React from 'react';
import {Link} from 'react-router-dom';

import AnimationAboutNumber from './AnimationAboutNumber';
import AnimationAboutSheet from './AnimationAboutSheet';
import AnimationAboutContainer from './AnimationAboutContainer';
import AnimationAboutHeaderSplit from './AnimationAboutHeaderSplit';
import AnimationAboutHeaderTitle from './AnimationAboutHeaderTitle';
import AnimationAboutHeaderText from './AnimationAboutHeaderText';
import AnimationAboutTile from './AnimationAboutTile';
import AnimationAboutSheetBackground from './AnimationAboutSheetBackground';
import AnimationAboutSlider from './AnimationAboutSlider';
import AnimationAboutSimpleCarousel from './AnimationAboutSimpleCarousel';
import HeatflowButton from './HeatflowButton';

import config from '../../../../config/config.json';

import i18n from '../i18n';

function AnimationAboutSection7()
{
    return (
        <section className="AnimationAboutSection AnimationAboutSection7" style={{background: 'white'}}>
            <AnimationAboutNumber>07</AnimationAboutNumber>
            <img className="AnimationAboutSection7__img" src={config.paths.about + 'img-19.png'} alt={i18n.t('reference.caseStudy')} role="layout" />
            <AnimationAboutSheet noPaddingBottom noPaddingTop>
                <div className="AnimationAboutSection7__grid">
                    <div>
                        <div className="AnimationAboutSection7__grid__title">
                            {i18n.t('reference.caseStudy')}
                        </div>
                        <div className="AnimationAboutSection7__grid__content">
                            {i18n.t('about.section07.text')}
                        </div>
                        <div className="AnimationAboutSection7__grid__button">
                            <Link to={config.pages.pripadova_studie.url}>
                                <HeatflowButton theme="gradient">
                                    {i18n.t('reference.caseStudy')}
                                </HeatflowButton>
                            </Link>
                        </div>
                    </div>
                    <div>
                        <img style={{width: '100%', height: '100%'}} src={config.paths.images + "img_heatflow_studie_" + i18n.language + ".svg"} alt="Porovnání Heatflow vs. Tepelné čerpadlo" />
                    </div>
                </div>
            </AnimationAboutSheet>
        </section>
    );
}

export default AnimationAboutSection7;
